/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

// @ts-nocheck

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Upload: { input: any; output: any }
}

/** A Field Group registered by ACF */
export type AcfFieldGroup = {
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
}

/** The ActionMonitorAction type */
export type ActionMonitorAction = ContentNode &
  DatabaseIdentifier &
  Node &
  NodeWithContentEditor &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'ActionMonitorAction'
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    actionMonitorActionId: Scalars['Int']['output']
    /** The type of action (CREATE, UPDATE, DELETE) */
    actionType?: Maybe<Scalars['String']['output']>
    /** The content of the post. */
    content?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the action_monitor object. */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /** Connection between the ActionMonitorAction type and the ActionMonitorAction type */
    preview?: Maybe<ActionMonitorActionToPreviewConnectionEdge>
    /** The preview data of the post that triggered this action. */
    previewData?: Maybe<GatsbyPreviewData>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** The global relay ID of the post that triggered this action */
    referencedNodeGlobalRelayID?: Maybe<Scalars['String']['output']>
    /** The post ID of the post that triggered this action */
    referencedNodeID?: Maybe<Scalars['String']['output']>
    /** The WPGraphQL plural name of the referenced post */
    referencedNodePluralName?: Maybe<Scalars['String']['output']>
    /** The WPGraphQL single name of the referenced post */
    referencedNodeSingularName?: Maybe<Scalars['String']['output']>
    /** The post status of the post that triggered this action */
    referencedNodeStatus?: Maybe<Scalars['String']['output']>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The ActionMonitorAction type */
export type ActionMonitorActionContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The ActionMonitorAction type */
export type ActionMonitorActionEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The ActionMonitorAction type */
export type ActionMonitorActionEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The ActionMonitorAction type */
export type ActionMonitorActionTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ActionMonitorActionIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Connection between the ActionMonitorAction type and the ActionMonitorAction type */
export type ActionMonitorActionToPreviewConnectionEdge = {
  __typename?: 'ActionMonitorActionToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<ActionMonitorAction>
}

/** The active integration */
export type ActiveIntegration = {
  __typename?: 'ActiveIntegration'
  /** The WordPress (i.e. destination) data type. */
  destinationType?: Maybe<IntegrationWpDataTypeEnum>
  /** The source data type */
  sourceType?: Maybe<IntegrationSourceDataTypeEnum>
  /** The fields sourced from the Integration. */
  sourcedFields?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

/** A Gravity Forms   field. */
export type AddressField = FormField & {
  __typename?: 'AddressField'
  /** Address Field Specific Translations */
  addressTranslations?: Maybe<Array<Maybe<AddressTranlation>>>
  /** Determines the type of address to be displayed. */
  addressType?: Maybe<AddressFieldTypeEnum>
  /** Address field value. */
  addressValues?: Maybe<AddressFieldValue>
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** The field id of the field being used as the copy source. */
  copyValuesOptionFieldId?: Maybe<Scalars['Int']['output']>
  /** The label that appears next to the copy values option when the form is displayed. The default value is \“Same as previous\”. */
  copyValuesOptionLabel?: Maybe<Scalars['String']['output']>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the country that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;INTERATIONAL&quot;. */
  defaultCountry?: Maybe<AddressFieldCountryEnum>
  /** Contains the province that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;CANADA&quot;. */
  defaultProvince?: Maybe<Scalars['String']['output']>
  /** Contains the state that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;US&quot;. */
  defaultState?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** An array of the available properties for each input of the address field. */
  inputs?: Maybe<Array<Maybe<AddressInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Indicates whether the copy values option can be used. This option allows users to skip filling out the field and use the same values as another. For example, if the mailing and billing address are the same. */
  shouldCopyValuesOption?: Maybe<Scalars['Boolean']['output']>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Countries supported by Gravity Forms Address Field. */
export enum AddressFieldCountryEnum {
  /** Andorra . */
  Ad = 'AD',
  /** United Arab Emirates . */
  Ae = 'AE',
  /** Afghanistan . */
  Af = 'AF',
  /** Antigua and Barbuda . */
  Ag = 'AG',
  /** Anguilla . */
  Ai = 'AI',
  /** Albania . */
  Al = 'AL',
  /** Armenia . */
  Am = 'AM',
  /** Angola . */
  Ao = 'AO',
  /** Antarctica . */
  Aq = 'AQ',
  /** Argentina . */
  Ar = 'AR',
  /** American Samoa . */
  As = 'AS',
  /** Austria . */
  At = 'AT',
  /** Australia . */
  Au = 'AU',
  /** Aruba . */
  Aw = 'AW',
  /** Åland Islands . */
  Ax = 'AX',
  /** Azerbaijan . */
  Az = 'AZ',
  /** Bosnia and Herzegovina . */
  Ba = 'BA',
  /** Barbados . */
  Bb = 'BB',
  /** Bangladesh . */
  Bd = 'BD',
  /** Belgium . */
  Be = 'BE',
  /** Burkina Faso . */
  Bf = 'BF',
  /** Bulgaria . */
  Bg = 'BG',
  /** Bahrain . */
  Bh = 'BH',
  /** Burundi . */
  Bi = 'BI',
  /** Benin . */
  Bj = 'BJ',
  /** Saint Barthélemy . */
  Bl = 'BL',
  /** Bermuda . */
  Bm = 'BM',
  /** Brunei Darussalam . */
  Bn = 'BN',
  /** Bolivia . */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba . */
  Bq = 'BQ',
  /** Brazil . */
  Br = 'BR',
  /** Bahamas . */
  Bs = 'BS',
  /** Bhutan . */
  Bt = 'BT',
  /** Bouvet Island . */
  Bv = 'BV',
  /** Botswana . */
  Bw = 'BW',
  /** Belarus . */
  By = 'BY',
  /** Belize . */
  Bz = 'BZ',
  /** Canada . */
  Ca = 'CA',
  /** Cocos Islands . */
  Cc = 'CC',
  /** Congo, Democratic Republic of the . */
  Cd = 'CD',
  /** Central African Republic . */
  Cf = 'CF',
  /** Congo . */
  Cg = 'CG',
  /** Switzerland . */
  Ch = 'CH',
  /** Côte d'Ivoire . */
  Ci = 'CI',
  /** Cook Islands . */
  Ck = 'CK',
  /** Chile . */
  Cl = 'CL',
  /** Cameroon . */
  Cm = 'CM',
  /** China . */
  Cn = 'CN',
  /** Colombia . */
  Co = 'CO',
  /** Costa Rica . */
  Cr = 'CR',
  /** Cuba . */
  Cu = 'CU',
  /** Cabo Verde . */
  Cv = 'CV',
  /** Curaçao . */
  Cw = 'CW',
  /** Christmas Island . */
  Cx = 'CX',
  /** Cyprus . */
  Cy = 'CY',
  /** Czechia . */
  Cz = 'CZ',
  /** Germany . */
  De = 'DE',
  /** Djibouti . */
  Dj = 'DJ',
  /** Denmark . */
  Dk = 'DK',
  /** Dominica . */
  Dm = 'DM',
  /** Dominican Republic . */
  Do = 'DO',
  /** Algeria . */
  Dz = 'DZ',
  /** Ecuador . */
  Ec = 'EC',
  /** Estonia . */
  Ee = 'EE',
  /** Egypt . */
  Eg = 'EG',
  /** Western Sahara . */
  Eh = 'EH',
  /** Eritrea . */
  Er = 'ER',
  /** Spain . */
  Es = 'ES',
  /** Ethiopia . */
  Et = 'ET',
  /** Finland . */
  Fi = 'FI',
  /** Fiji . */
  Fj = 'FJ',
  /** Falkland Islands . */
  Fk = 'FK',
  /** Micronesia . */
  Fm = 'FM',
  /** Faroe Islands . */
  Fo = 'FO',
  /** France . */
  Fr = 'FR',
  /** Gabon . */
  Ga = 'GA',
  /** United Kingdom . */
  Gb = 'GB',
  /** Grenada . */
  Gd = 'GD',
  /** Georgia . */
  Ge = 'GE',
  /** French Guiana . */
  Gf = 'GF',
  /** Guernsey . */
  Gg = 'GG',
  /** Ghana . */
  Gh = 'GH',
  /** Gibraltar . */
  Gi = 'GI',
  /** Greenland . */
  Gl = 'GL',
  /** Gambia . */
  Gm = 'GM',
  /** Guinea . */
  Gn = 'GN',
  /** Guadeloupe . */
  Gp = 'GP',
  /** Equatorial Guinea . */
  Gq = 'GQ',
  /** Greece . */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands . */
  Gs = 'GS',
  /** Guatemala . */
  Gt = 'GT',
  /** Guam . */
  Gu = 'GU',
  /** Guinea-Bissau . */
  Gw = 'GW',
  /** Guyana . */
  Gy = 'GY',
  /** Hong Kong . */
  Hk = 'HK',
  /** Heard Island and McDonald Islands . */
  Hm = 'HM',
  /** Honduras . */
  Hn = 'HN',
  /** Croatia . */
  Hr = 'HR',
  /** Haiti . */
  Ht = 'HT',
  /** Hungary . */
  Hu = 'HU',
  /** Indonesia . */
  Id = 'ID',
  /** Ireland . */
  Ie = 'IE',
  /** Israel . */
  Il = 'IL',
  /** Isle of Man . */
  Im = 'IM',
  /** India . */
  In = 'IN',
  /** British Indian Ocean Territory . */
  Io = 'IO',
  /** Iraq . */
  Iq = 'IQ',
  /** Iran . */
  Ir = 'IR',
  /** Iceland . */
  Is = 'IS',
  /** Italy . */
  It = 'IT',
  /** Jersey . */
  Je = 'JE',
  /** Jamaica . */
  Jm = 'JM',
  /** Jordan . */
  Jo = 'JO',
  /** Japan . */
  Jp = 'JP',
  /** Kenya . */
  Ke = 'KE',
  /** Kyrgyzstan . */
  Kg = 'KG',
  /** Cambodia . */
  Kh = 'KH',
  /** Kiribati . */
  Ki = 'KI',
  /** Comoros . */
  Km = 'KM',
  /** Saint Kitts and Nevis . */
  Kn = 'KN',
  /** Korea, Democratic People's Republic of . */
  Kp = 'KP',
  /** Korea, Republic of . */
  Kr = 'KR',
  /** Kuwait . */
  Kw = 'KW',
  /** Cayman Islands . */
  Ky = 'KY',
  /** Kazakhstan . */
  Kz = 'KZ',
  /** Lao People's Democratic Republic . */
  La = 'LA',
  /** Lebanon . */
  Lb = 'LB',
  /** Saint Lucia . */
  Lc = 'LC',
  /** Liechtenstein . */
  Li = 'LI',
  /** Sri Lanka . */
  Lk = 'LK',
  /** Liberia . */
  Lr = 'LR',
  /** Lesotho . */
  Ls = 'LS',
  /** Lithuania . */
  Lt = 'LT',
  /** Luxembourg . */
  Lu = 'LU',
  /** Latvia . */
  Lv = 'LV',
  /** Libya . */
  Ly = 'LY',
  /** Morocco . */
  Ma = 'MA',
  /** Monaco . */
  Mc = 'MC',
  /** Moldova . */
  Md = 'MD',
  /** Montenegro . */
  Me = 'ME',
  /** Saint Martin . */
  Mf = 'MF',
  /** Madagascar . */
  Mg = 'MG',
  /** Marshall Islands . */
  Mh = 'MH',
  /** North Macedonia . */
  Mk = 'MK',
  /** Mali . */
  Ml = 'ML',
  /** Myanmar . */
  Mm = 'MM',
  /** Mongolia . */
  Mn = 'MN',
  /** Macao . */
  Mo = 'MO',
  /** Northern Mariana Islands . */
  Mp = 'MP',
  /** Martinique . */
  Mq = 'MQ',
  /** Mauritania . */
  Mr = 'MR',
  /** Montserrat . */
  Ms = 'MS',
  /** Malta . */
  Mt = 'MT',
  /** Mauritius . */
  Mu = 'MU',
  /** Maldives . */
  Mv = 'MV',
  /** Malawi . */
  Mw = 'MW',
  /** Mexico . */
  Mx = 'MX',
  /** Malaysia . */
  My = 'MY',
  /** Mozambique . */
  Mz = 'MZ',
  /** Namibia . */
  Na = 'NA',
  /** New Caledonia . */
  Nc = 'NC',
  /** Niger . */
  Ne = 'NE',
  /** Norfolk Island . */
  Nf = 'NF',
  /** Nigeria . */
  Ng = 'NG',
  /** Nicaragua . */
  Ni = 'NI',
  /** Netherlands . */
  Nl = 'NL',
  /** Norway . */
  No = 'NO',
  /** Nepal . */
  Np = 'NP',
  /** Nauru . */
  Nr = 'NR',
  /** Niue . */
  Nu = 'NU',
  /** New Zealand . */
  Nz = 'NZ',
  /** Oman . */
  Om = 'OM',
  /** Panama . */
  Pa = 'PA',
  /** Peru . */
  Pe = 'PE',
  /** French Polynesia . */
  Pf = 'PF',
  /** Papua New Guinea . */
  Pg = 'PG',
  /** Philippines . */
  Ph = 'PH',
  /** Pakistan . */
  Pk = 'PK',
  /** Poland . */
  Pl = 'PL',
  /** Saint Pierre and Miquelon . */
  Pm = 'PM',
  /** Pitcairn . */
  Pn = 'PN',
  /** Puerto Rico . */
  Pr = 'PR',
  /** Palestine, State of . */
  Ps = 'PS',
  /** Portugal . */
  Pt = 'PT',
  /** Palau . */
  Pw = 'PW',
  /** Paraguay . */
  Py = 'PY',
  /** Qatar . */
  Qa = 'QA',
  /** Réunion . */
  Re = 'RE',
  /** Romania . */
  Ro = 'RO',
  /** Serbia . */
  Rs = 'RS',
  /** Russian Federation . */
  Ru = 'RU',
  /** Rwanda . */
  Rw = 'RW',
  /** Saudi Arabia . */
  Sa = 'SA',
  /** Solomon Islands . */
  Sb = 'SB',
  /** Seychelles . */
  Sc = 'SC',
  /** Sudan . */
  Sd = 'SD',
  /** Sweden . */
  Se = 'SE',
  /** Singapore . */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha . */
  Sh = 'SH',
  /** Slovenia . */
  Si = 'SI',
  /** Svalbard and Jan Mayen . */
  Sj = 'SJ',
  /** Slovakia . */
  Sk = 'SK',
  /** Sierra Leone . */
  Sl = 'SL',
  /** San Marino . */
  Sm = 'SM',
  /** Senegal . */
  Sn = 'SN',
  /** Somalia . */
  So = 'SO',
  /** Suriname . */
  Sr = 'SR',
  /** South Sudan . */
  Ss = 'SS',
  /** Sao Tome and Principe . */
  St = 'ST',
  /** El Salvador . */
  Sv = 'SV',
  /** Sint Maarten . */
  Sx = 'SX',
  /** Syria Arab Republic . */
  Sy = 'SY',
  /** Eswatini . */
  Sz = 'SZ',
  /** Turks and Caicos Islands . */
  Tc = 'TC',
  /** Chad . */
  Td = 'TD',
  /** French Southern Territories . */
  Tf = 'TF',
  /** Togo . */
  Tg = 'TG',
  /** Thailand . */
  Th = 'TH',
  /** Tajikistan . */
  Tj = 'TJ',
  /** Tokelau . */
  Tk = 'TK',
  /** Timor-Leste . */
  Tl = 'TL',
  /** Turkmenistan . */
  Tm = 'TM',
  /** Tunisia . */
  Tn = 'TN',
  /** Tonga . */
  To = 'TO',
  /** Türkiye . */
  Tr = 'TR',
  /** Trinidad and Tobago . */
  Tt = 'TT',
  /** Tuvalu . */
  Tv = 'TV',
  /** Taiwan . */
  Tw = 'TW',
  /** Tanzania, the United Republic of . */
  Tz = 'TZ',
  /** Ukraine . */
  Ua = 'UA',
  /** Uganda . */
  Ug = 'UG',
  /** US Minor Outlying Islands . */
  Um = 'UM',
  /** United States . */
  Us = 'US',
  /** Uruguay . */
  Uy = 'UY',
  /** Uzbekistan . */
  Uz = 'UZ',
  /** Holy See . */
  Va = 'VA',
  /** Saint Vincent and the Grenadines . */
  Vc = 'VC',
  /** Venezuela . */
  Ve = 'VE',
  /** Virgin Islands, British . */
  Vg = 'VG',
  /** Virgin Islands, U.S. . */
  Vi = 'VI',
  /** Viet Nam . */
  Vn = 'VN',
  /** Vanuatu . */
  Vu = 'VU',
  /** Wallis and Futuna . */
  Wf = 'WF',
  /** Samoa . */
  Ws = 'WS',
  /** Yemen . */
  Ye = 'YE',
  /** Mayotte . */
  Yt = 'YT',
  /** South Africa . */
  Za = 'ZA',
  /** Zambia . */
  Zm = 'ZM',
  /** Zimbabwe . */
  Zw = 'ZW',
}

/** Input fields for Address FormField. */
export type AddressFieldInput = {
  /** Address city. */
  city?: InputMaybe<Scalars['String']['input']>
  /** Address country. */
  country?: InputMaybe<AddressFieldCountryEnum>
  /** Address line two. */
  lineTwo?: InputMaybe<Scalars['String']['input']>
  /** Address state/region/province name. */
  state?: InputMaybe<Scalars['String']['input']>
  /** Street address. */
  street?: InputMaybe<Scalars['String']['input']>
  /** Address zip code. */
  zip?: InputMaybe<Scalars['String']['input']>
}

/** Determines the type of address to be displayed. */
export enum AddressFieldTypeEnum {
  /** Canada address type. */
  Canada = 'CANADA',
  /** International address type. */
  International = 'INTERNATIONAL',
  /** United States address type. */
  Us = 'US',
}

/** The individual properties for each element of the address value field. */
export type AddressFieldValue = {
  __typename?: 'AddressFieldValue'
  /** Address city. */
  city?: Maybe<Scalars['String']['output']>
  /** Address country. */
  country?: Maybe<AddressFieldCountryEnum>
  /** Address line two. */
  lineTwo?: Maybe<Scalars['String']['output']>
  /** Address state/region/province name. */
  state?: Maybe<Scalars['String']['output']>
  /** Street address. */
  street?: Maybe<Scalars['String']['output']>
  /** Address zip code. */
  zip?: Maybe<Scalars['String']['output']>
}

/** Address input values. */
export type AddressInputProperty = {
  __typename?: 'AddressInputProperty'
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Whether or not this field should be hidden. */
  isHidden?: Maybe<Scalars['Boolean']['output']>
  /** Key used to identify this input. */
  key?: Maybe<Scalars['String']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** Gravity Forms translation settings */
export type AddressTranlation = {
  __typename?: 'AddressTranlation'
  /** Address label translation */
  address?: Maybe<Scalars['String']['output']>
  /** Address2 label translation */
  address2?: Maybe<Scalars['String']['output']>
  /** City label translation */
  city?: Maybe<Scalars['String']['output']>
  /** Country label translation */
  country?: Maybe<Scalars['String']['output']>
  /** Description translation of the field */
  description?: Maybe<Scalars['String']['output']>
  /** Label Translation of the field */
  label?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
  /** State label translation */
  state?: Maybe<Scalars['String']['output']>
  /** Zip label translation */
  zip?: Maybe<Scalars['String']['output']>
}

/** The AM or PM cycle in a 12-hour clock. */
export enum AmPmEnum {
  /** AM. The first 12-hour cycle of the day. */
  Am = 'AM',
  /** PM. The second 12-hour cycle of the day. */
  Pm = 'PM',
}

/** Avatars are profile images for users. WordPress by default uses the Gravatar service to host and fetch avatars from. */
export type Avatar = {
  __typename?: 'Avatar'
  /** URL for the default image or a default type. Accepts &#039;404&#039; (return a 404 instead of a default image), &#039;retro&#039; (8bit), &#039;monsterid&#039; (monster), &#039;wavatar&#039; (cartoon face), &#039;indenticon&#039; (the &#039;quilt&#039;), &#039;mystery&#039;, &#039;mm&#039;, or &#039;mysteryman&#039; (The Oyster Man), &#039;blank&#039; (transparent GIF), or &#039;gravatar_default&#039; (the Gravatar logo). */
  default?: Maybe<Scalars['String']['output']>
  /** HTML attributes to insert in the IMG element. Is not sanitized. */
  extraAttr?: Maybe<Scalars['String']['output']>
  /** Whether to always show the default image, never the Gravatar. */
  forceDefault?: Maybe<Scalars['Boolean']['output']>
  /** Whether the avatar was successfully found. */
  foundAvatar?: Maybe<Scalars['Boolean']['output']>
  /** Height of the avatar image. */
  height?: Maybe<Scalars['Int']['output']>
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>
  /** What rating to display avatars up to. Accepts &#039;G&#039;, &#039;PG&#039;, &#039;R&#039;, &#039;X&#039;, and are judged in that order. */
  rating?: Maybe<Scalars['String']['output']>
  /** Type of url scheme to use. Typically HTTP vs. HTTPS. */
  scheme?: Maybe<Scalars['String']['output']>
  /** The size of the avatar in pixels. A value of 96 will match a 96px x 96px gravatar image. */
  size?: Maybe<Scalars['Int']['output']>
  /** URL for the gravatar image source. */
  url?: Maybe<Scalars['String']['output']>
  /** Width of the avatar image. */
  width?: Maybe<Scalars['Int']['output']>
}

/** What rating to display avatars up to. Accepts 'G', 'PG', 'R', 'X', and are judged in that order. Default is the value of the 'avatar_rating' option */
export enum AvatarRatingEnum {
  /** Indicates a G level avatar rating level. */
  G = 'G',
  /** Indicates a PG level avatar rating level. */
  Pg = 'PG',
  /** Indicates an R level avatar rating level. */
  R = 'R',
  /** Indicates an X level avatar rating level. */
  X = 'X',
}

/** Gravity Forms translation settings */
export type BasicTranslation = {
  __typename?: 'BasicTranslation'
  /** Description translation of the field */
  description?: Maybe<Scalars['String']['output']>
  /** Label Translation of the field */
  label?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type CaptchaField = FormField & {
  __typename?: 'CaptchaField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** The language used when the captcha is displayed. This property is available when the captchaType is “captcha”, the default. The possible values are the language codes used by WordPress. */
  captchaBadgePosition?: Maybe<CaptchaFieldBadgePositionEnum>
  /** The language used when the captcha is displayed. This property is available when the captchaType is “captcha”, the default. The possible values are the language codes used by WordPress. */
  captchaLanguage?: Maybe<Scalars['String']['output']>
  /** Determines the theme to be used for the reCAPTCHA field. Only applicable to the recaptcha captcha type. */
  captchaTheme?: Maybe<CaptchaFieldThemeEnum>
  /** Determines the type of CAPTCHA field to be used. */
  captchaType?: Maybe<CaptchaFieldTypeEnum>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** Determines the image’s background color, in HEX format (i.e. #CCCCCC). Only applicable to simple_captcha and math captcha types. */
  simpleCaptchaBackgroundColor?: Maybe<Scalars['String']['output']>
  /** Determines the image’s font color, in HEX format (i.e. #CCCCCC). Only applicable to simple_captcha and math captcha types. */
  simpleCaptchaFontColor?: Maybe<Scalars['String']['output']>
  /** Determines the CAPTCHA image size. Only applicable to simple_captcha and math captcha types. */
  simpleCaptchaSize?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** The position to place the (invisible) reCaptcha badge. */
export enum CaptchaFieldBadgePositionEnum {
  /** Bottom-left position. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Bottom-right position. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Inline position. */
  Inline = 'INLINE',
}

/** The theme to be used for the reCAPTCHA field. */
export enum CaptchaFieldThemeEnum {
  /** Dark reCAPTCHA theme. */
  Dark = 'DARK',
  /** Light reCAPTCHA theme. */
  Light = 'LIGHT',
}

/** Type of CAPTCHA field to be used. */
export enum CaptchaFieldTypeEnum {
  /** Math CAPTCHA type. */
  Math = 'MATH',
  /** reCAPTCHA type. */
  Recaptcha = 'RECAPTCHA',
  /** Simple CAPTCHA type. */
  Simple = 'SIMPLE',
}

/** Details to be displayed on list cards */
export type CardDetails = {
  __typename?: 'CardDetails'
  /** Supporting detail number 1 */
  detail1?: Maybe<CardDetailsDetail>
  /** Supporting detail number 2 */
  detail2?: Maybe<CardDetailsDetail>
  /** Supporting detail number 3 */
  detail3?: Maybe<CardDetailsDetail>
  /** Heading */
  heading?: Maybe<Scalars['String']['output']>
  /** Subheading */
  subheading?: Maybe<Scalars['String']['output']>
}

/** Card Display Details individual detail */
export type CardDetailsDetail = {
  __typename?: 'CardDetailsDetail'
  /** Label of the Detail */
  label?: Maybe<Scalars['String']['output']>
  /** Value of the Detail */
  value?: Maybe<Scalars['String']['output']>
}

/** Gravity Forms card display settings */
export type CardDisplaySettings = {
  __typename?: 'CardDisplaySettings'
  /** Field ID to use for the first detail. */
  detail1FieldId?: Maybe<Scalars['String']['output']>
  /** Field ID to use for the second detail. */
  detail2FieldId?: Maybe<Scalars['String']['output']>
  /** Field ID to use for the third detail. */
  detail3FieldId?: Maybe<Scalars['String']['output']>
  /** Field ID to use for the heading. */
  headingFieldId?: Maybe<Scalars['String']['output']>
  /** Field ID to use for subheading. */
  subheadingFieldId?: Maybe<Scalars['String']['output']>
}

/** Card Display Detail Label Translations */
export type CardDisplayTranslation = {
  __typename?: 'CardDisplayTranslation'
  /** Detail 1 Translated Label */
  detail1?: Maybe<Scalars['String']['output']>
  /** Detail 2 Translated Label */
  detail2?: Maybe<Scalars['String']['output']>
  /** Detail 3 Translated Label */
  detail3?: Maybe<Scalars['String']['output']>
}

/** The category type */
export type Category = DatabaseIdentifier &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Category'
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<CategoryToAncestorsCategoryConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    categoryId?: Maybe<Scalars['Int']['output']>
    /** Connection between the category type and its children categories. */
    children?: Maybe<CategoryToCategoryConnection>
    /** Connection between the Category type and the ContentNode type */
    contentNodes?: Maybe<CategoryToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** List available translations for this post */
    language?: Maybe<Language>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** Connection between the category type and its parent category. */
    parent?: Maybe<CategoryToParentCategoryConnectionEdge>
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Category type and the post type */
    posts?: Maybe<CategoryToPostConnection>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Category type and the Taxonomy type */
    taxonomy?: Maybe<CategoryToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** Get specific translation version of this object */
    translation?: Maybe<Category>
    /** List all translated versions of this term */
    translations?: Maybe<Array<Maybe<Category>>>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The category type */
export type CategoryAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The category type */
export type CategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CategoryToCategoryConnectionWhereArgs>
}

/** The category type */
export type CategoryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CategoryToContentNodeConnectionWhereArgs>
}

/** The category type */
export type CategoryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The category type */
export type CategoryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The category type */
export type CategoryPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CategoryToPostConnectionWhereArgs>
}

/** The category type */
export type CategoryTranslationArgs = {
  language: LanguageCodeEnum
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CategoryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the Category type and the category type */
export type CategoryToAncestorsCategoryConnection = {
  __typename?: 'CategoryToAncestorsCategoryConnection'
  /** Edges for the CategoryToAncestorsCategoryConnection connection */
  edges?: Maybe<Array<Maybe<CategoryToAncestorsCategoryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Category>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CategoryToAncestorsCategoryConnectionEdge = {
  __typename?: 'CategoryToAncestorsCategoryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Category>
}

/** Connection between the Category type and the category type */
export type CategoryToCategoryConnection = {
  __typename?: 'CategoryToCategoryConnection'
  /** Edges for the CategoryToCategoryConnection connection */
  edges?: Maybe<Array<Maybe<CategoryToCategoryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Category>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CategoryToCategoryConnectionEdge = {
  __typename?: 'CategoryToCategoryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Category>
}

/** Arguments for filtering the CategoryToCategoryConnection connection */
export type CategoryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Category type and the ContentNode type */
export type CategoryToContentNodeConnection = {
  __typename?: 'CategoryToContentNodeConnection'
  /** Edges for the CategoryToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<CategoryToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CategoryToContentNodeConnectionEdge = {
  __typename?: 'CategoryToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the CategoryToContentNodeConnection connection */
export type CategoryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfCategoryEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CategoryToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<CategoryToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CategoryToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<CategoryToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<CategoryToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CategoryToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CategoryToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<CategoryToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum CategoryToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CategoryToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<CategoryToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<CategoryToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type CategoryToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<CategoryToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CategoryToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CategoryToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Category type and the category type */
export type CategoryToParentCategoryConnectionEdge = {
  __typename?: 'CategoryToParentCategoryConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Category>
}

/** Connection between the Category type and the post type */
export type CategoryToPostConnection = {
  __typename?: 'CategoryToPostConnection'
  /** Edges for the CategoryToPostConnection connection */
  edges?: Maybe<Array<Maybe<CategoryToPostConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CategoryToPostConnectionEdge = {
  __typename?: 'CategoryToPostConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Post>
}

/** Arguments for filtering the CategoryToPostConnection connection */
export type CategoryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CategoryToPostConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  taxQuery?: InputMaybe<CategoryToPostConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CategoryToPostConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<CategoryToPostConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<CategoryToPostConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CategoryToPostConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CategoryToPostConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<CategoryToPostConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum CategoryToPostConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CategoryToPostConnectionWhereArgsTaxArray = {
  field?: InputMaybe<CategoryToPostConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<CategoryToPostConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type CategoryToPostConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<CategoryToPostConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CategoryToPostConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CategoryToPostConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Category type and the Taxonomy type */
export type CategoryToTaxonomyConnectionEdge = {
  __typename?: 'CategoryToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** The Certification type */
export type Certification = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithContentEditor &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'Certification'
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Certification Details&quot; was set to Show in GraphQL. */
    certificationDetails?: Maybe<Certification_Certificationdetails>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    certificationId: Scalars['Int']['output']
    /** Connection between the Certification type and the User type */
    certificationUserConnection?: Maybe<CertificationToUserConnection>
    /** Completion date of the certification */
    completedDate?: Maybe<Scalars['String']['output']>
    /** The content of the post. */
    content?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** Expiration date of the certificaiton */
    expirationDate?: Maybe<Scalars['String']['output']>
    /** Connection between the Certification type and the MediaItem type */
    fileAttachments?: Maybe<CertificationToMediaItemConnection>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the certification object. */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /** Connection between the Certification type and the Certification type */
    preview?: Maybe<CertificationToPreviewConnectionEdge>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Certification type and the Provider type */
    providers?: Maybe<CertificationToProviderConnection>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** Connection between the Certification type and the TermNode type */
    terms?: Maybe<CertificationToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** Connection between the Certification type and the TrainingType type */
    trainingTypes?: Maybe<CertificationToTrainingTypeConnection>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Certification type */
export type CertificationCertificationUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Certification type */
export type CertificationContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The Certification type */
export type CertificationEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Certification type */
export type CertificationEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Certification type */
export type CertificationFileAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Certification type */
export type CertificationProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CertificationToProviderConnectionWhereArgs>
}

/** The Certification type */
export type CertificationTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CertificationToTermNodeConnectionWhereArgs>
}

/** The Certification type */
export type CertificationTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The Certification type */
export type CertificationTrainingTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CertificationToTrainingTypeConnectionWhereArgs>
}

/** The configured warnings for certificate expirations. */
export type CertificationExpiryWarningRule = {
  __typename?: 'CertificationExpiryWarningRule'
  /** The number of days before a certificate expires to send the alert. */
  alertWindow?: Maybe<Scalars['Int']['output']>
  /** Connection between the CertificationExpiryWarningRule type and the Company type */
  companies?: Maybe<CertificationExpiryWarningRuleToCompanyConnection>
  /** The unique identifier of the rule. */
  databaseId?: Maybe<Scalars['Int']['output']>
  /** The date the rule was created. */
  dateCreated?: Maybe<Scalars['String']['output']>
  /** The date the rule was last modified. */
  dateModified?: Maybe<Scalars['String']['output']>
  /** The external contacts to send the alert to. */
  externalContacts?: Maybe<Array<Maybe<ExternalContact>>>
  /** Connection between the CertificationExpiryWarningRule type and the User type */
  internalContacts?: Maybe<CertificationExpiryWarningRuleToUserConnection>
  /** Connection between the CertificationExpiryWarningRule type and the User type */
  lastEditedBy?: Maybe<CertificationExpiryWarningRuleToLastEditedByConnectionEdge>
}

/** The configured warnings for certificate expirations. */
export type CertificationExpiryWarningRuleCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The configured warnings for certificate expirations. */
export type CertificationExpiryWarningRuleInternalContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Connection between the CertificationExpiryWarningRule type and the Company type */
export type CertificationExpiryWarningRuleToCompanyConnection = {
  __typename?: 'CertificationExpiryWarningRuleToCompanyConnection'
  /** Edges for the CertificationExpiryWarningRuleToCompanyConnection connection */
  edges?: Maybe<
    Array<Maybe<CertificationExpiryWarningRuleToCompanyConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Company>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CertificationExpiryWarningRuleToCompanyConnectionEdge = {
  __typename?: 'CertificationExpiryWarningRuleToCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Company>
}

/** Connection between the CertificationExpiryWarningRule type and the User type */
export type CertificationExpiryWarningRuleToLastEditedByConnectionEdge = {
  __typename?: 'CertificationExpiryWarningRuleToLastEditedByConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<User>
}

/** Connection between the CertificationExpiryWarningRule type and the User type */
export type CertificationExpiryWarningRuleToUserConnection = {
  __typename?: 'CertificationExpiryWarningRuleToUserConnection'
  /** Edges for the CertificationExpiryWarningRuleToUserConnection connection */
  edges?: Maybe<
    Array<Maybe<CertificationExpiryWarningRuleToUserConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<User>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CertificationExpiryWarningRuleToUserConnectionEdge = {
  __typename?: 'CertificationExpiryWarningRuleToUserConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<User>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CertificationIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the Certification to Providers */
export type CertificationProvidersInput = {
  /** If true, this will append the Provider to existing related Providers. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CertificationProvidersNodeInput>>>
}

/** List of Providers to connect the Certification to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CertificationProvidersNodeInput = {
  /** The description of the Provider. This field is used to set a description of the Provider if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Provider. If present, this will be used to connect to the Certification. If no existing Provider exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Provider. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Provider. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Connection between the Certification type and the MediaItem type */
export type CertificationToMediaItemConnection = {
  __typename?: 'CertificationToMediaItemConnection'
  /** Edges for the CertificationToMediaItemConnection connection */
  edges?: Maybe<Array<Maybe<CertificationToMediaItemConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MediaItem>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CertificationToMediaItemConnectionEdge = {
  __typename?: 'CertificationToMediaItemConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<MediaItem>
}

/** Connection between the Certification type and the Certification type */
export type CertificationToPreviewConnectionEdge = {
  __typename?: 'CertificationToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Certification>
}

/** Connection between the Certification type and the Provider type */
export type CertificationToProviderConnection = {
  __typename?: 'CertificationToProviderConnection'
  /** Edges for the CertificationToProviderConnection connection */
  edges?: Maybe<Array<Maybe<CertificationToProviderConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Provider>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CertificationToProviderConnectionEdge = {
  __typename?: 'CertificationToProviderConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Provider>
}

/** Arguments for filtering the CertificationToProviderConnection connection */
export type CertificationToProviderConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Certification type and the TermNode type */
export type CertificationToTermNodeConnection = {
  __typename?: 'CertificationToTermNodeConnection'
  /** Edges for the CertificationToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<CertificationToTermNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CertificationToTermNodeConnectionEdge = {
  __typename?: 'CertificationToTermNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TermNode>
}

/** Arguments for filtering the CertificationToTermNodeConnection connection */
export type CertificationToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Certification type and the TrainingType type */
export type CertificationToTrainingTypeConnection = {
  __typename?: 'CertificationToTrainingTypeConnection'
  /** Edges for the CertificationToTrainingTypeConnection connection */
  edges?: Maybe<Array<Maybe<CertificationToTrainingTypeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TrainingType>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CertificationToTrainingTypeConnectionEdge = {
  __typename?: 'CertificationToTrainingTypeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TrainingType>
}

/** Arguments for filtering the CertificationToTrainingTypeConnection connection */
export type CertificationToTrainingTypeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Certification type and the User type */
export type CertificationToUserConnection = {
  __typename?: 'CertificationToUserConnection'
  /** Edges for the CertificationToUserConnection connection */
  edges?: Maybe<Array<Maybe<CertificationToUserConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<User>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CertificationToUserConnectionEdge = {
  __typename?: 'CertificationToUserConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<User>
}

/** Set relationships between the Certification to TrainingTypes */
export type CertificationTrainingTypesInput = {
  /** If true, this will append the TrainingType to existing related TrainingTypes. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CertificationTrainingTypesNodeInput>>>
}

/** List of TrainingTypes to connect the Certification to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CertificationTrainingTypesNodeInput = {
  /** The description of the TrainingType. This field is used to set a description of the TrainingType if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the TrainingType. If present, this will be used to connect to the Certification. If no existing TrainingType exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the TrainingType. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the TrainingType. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Field Group */
export type Certification_Certificationdetails = AcfFieldGroup & {
  __typename?: 'Certification_Certificationdetails'
  completedDate?: Maybe<Scalars['String']['output']>
  expirationDate?: Maybe<Scalars['String']['output']>
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type CheckboxField = FormField & {
  __typename?: 'CheckboxField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Checkbox field value. */
  checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
  /** The available choices for the checkbox field. */
  choices?: Maybe<Array<Maybe<CheckboxFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** Whether the \&quot;select all\&quot; choice should be displayed. */
  hasSelectAll?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** An array of the available properties for each input of the checkbox field. */
  inputs?: Maybe<Array<Maybe<CheckboxInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Checkbox choice values. */
export type CheckboxFieldChoice = {
  __typename?: 'CheckboxFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** Input fields for a single checkbox. */
export type CheckboxFieldInput = {
  /** Input ID. */
  inputId?: InputMaybe<Scalars['Float']['input']>
  /** Input value. */
  value?: InputMaybe<Scalars['String']['input']>
}

/** The individual properties for each element of the Checkbox value field. */
export type CheckboxFieldValue = {
  __typename?: 'CheckboxFieldValue'
  /** Input ID. */
  inputId?: Maybe<Scalars['Float']['output']>
  /** Input text. */
  text?: Maybe<Scalars['String']['output']>
  /** Input value. */
  value?: Maybe<Scalars['String']['output']>
}

/** Checkbox input values. */
export type CheckboxInputProperty = {
  __typename?: 'CheckboxInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** Gravity Forms translation settings */
export type ChoiceTranslation = {
  __typename?: 'ChoiceTranslation'
  /** Text Translation of the Choice */
  choices?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Description translation of the field */
  description?: Maybe<Scalars['String']['output']>
  /** Label Translation of the field */
  label?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type ComboSignatureField = FormField & {
  __typename?: 'ComboSignatureField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Color to be used for the background of the signature area. Can be any valid CSS color value. */
  backgroundColor?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Color to be used for the border around the signature area. Can be any valid CSS color value. */
  borderColor?: Maybe<Scalars['String']['output']>
  /** Border style to be used around the signature area. */
  borderStyle?: Maybe<SignatureFieldBorderStyleEnum>
  /** Width of the border around the signature area. */
  borderWidth?: Maybe<SignatureFieldBorderWidthEnum>
  /** Width of the signature field in pixels. */
  boxWidth?: Maybe<Scalars['Int']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
  /** The available choices for the combo_signature field. */
  choices?: Maybe<Array<Maybe<ComboSignatureFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The maximum number of signatures required by the field. */
  maxSignatures?: Maybe<Scalars['Int']['output']>
  /** The minimum number of signatures required by the field. */
  minSignatures?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** Color of the pen to be used for the signature. Can be any valid CSS color value. */
  penColor?: Maybe<Scalars['String']['output']>
  /** Size of the pen cursor. */
  penSize?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Combo signature field values. */
  signatureValues?: Maybe<Array<Maybe<ComboSignatureFieldValue>>>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Combo_signature choice values. */
export type ComboSignatureFieldChoice = {
  __typename?: 'ComboSignatureFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** Value for a single input within a ComboSignature field. */
export type ComboSignatureFieldValue = {
  __typename?: 'ComboSignatureFieldValue'
  /** The url to the signature file. */
  signature?: Maybe<Scalars['String']['output']>
  /** The user */
  user?: Maybe<User>
  /** The User ID. */
  userId?: Maybe<Scalars['Int']['output']>
}

/** Input fields for a Combo Signature. */
export type ComboSignatureInput = {
  /** The url to the signature file. */
  signature?: InputMaybe<Scalars['String']['input']>
  /** The User ID. */
  userId?: InputMaybe<Scalars['Int']['input']>
}

/** A Comment object */
export type Comment = DatabaseIdentifier &
  Node & {
    __typename?: 'Comment'
    /** User agent used to post the comment. This field is equivalent to WP_Comment-&gt;comment_agent and the value matching the &quot;comment_agent&quot; column in SQL. */
    agent?: Maybe<Scalars['String']['output']>
    /** The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL. */
    approved?: Maybe<Scalars['Boolean']['output']>
    /** The author of the comment */
    author?: Maybe<CommentToCommenterConnectionEdge>
    /** IP address for the author. This field is equivalent to WP_Comment-&gt;comment_author_IP and the value matching the &quot;comment_author_IP&quot; column in SQL. */
    authorIp?: Maybe<Scalars['String']['output']>
    /**
     * ID for the comment, unique among comments.
     * @deprecated Deprecated in favor of databaseId
     */
    commentId?: Maybe<Scalars['Int']['output']>
    /** Connection between the Comment type and the ContentNode type */
    commentedOn?: Maybe<CommentToContentNodeConnectionEdge>
    /** Content of the comment. This field is equivalent to WP_Comment-&gt;comment_content and the value matching the &quot;comment_content&quot; column in SQL. */
    content?: Maybe<Scalars['String']['output']>
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** Date the comment was posted in local time. This field is equivalent to WP_Comment-&gt;date and the value matching the &quot;date&quot; column in SQL. */
    date?: Maybe<Scalars['String']['output']>
    /** Date the comment was posted in GMT. This field is equivalent to WP_Comment-&gt;date_gmt and the value matching the &quot;date_gmt&quot; column in SQL. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier for the comment object */
    id: Scalars['ID']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Karma value for the comment. This field is equivalent to WP_Comment-&gt;comment_karma and the value matching the &quot;comment_karma&quot; column in SQL. */
    karma?: Maybe<Scalars['Int']['output']>
    /** Connection between the Comment type and the Comment type */
    parent?: Maybe<CommentToParentCommentConnectionEdge>
    /** The database id of the parent comment node or null if it is the root comment */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the parent comment node. */
    parentId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Comment type and the Comment type */
    replies?: Maybe<CommentToCommentConnection>
    /** Type of comment. This field is equivalent to WP_Comment-&gt;comment_type and the value matching the &quot;comment_type&quot; column in SQL. */
    type?: Maybe<Scalars['String']['output']>
  }

/** A Comment object */
export type CommentContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** A Comment object */
export type CommentParentArgs = {
  where?: InputMaybe<CommentToParentCommentConnectionWhereArgs>
}

/** A Comment object */
export type CommentRepliesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CommentToCommentConnectionWhereArgs>
}

/** A Comment Author object */
export type CommentAuthor = Commenter &
  Node & {
    __typename?: 'CommentAuthor'
    /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
    avatar?: Maybe<Avatar>
    /** Identifies the primary key from the database. */
    databaseId: Scalars['Int']['output']
    /** The email for the comment author */
    email?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier for the comment author object */
    id: Scalars['ID']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** The name for the comment author. */
    name?: Maybe<Scalars['String']['output']>
    /** The url the comment author. */
    url?: Maybe<Scalars['String']['output']>
  }

/** A Comment Author object */
export type CommentAuthorAvatarArgs = {
  forceDefault?: InputMaybe<Scalars['Boolean']['input']>
  rating?: InputMaybe<AvatarRatingEnum>
  size?: InputMaybe<Scalars['Int']['input']>
}

/** The Type of Identifier used to fetch a single comment node. Default is "ID". To be used along with the "id" field. */
export enum CommentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
}

/** Connection between the Comment type and the Comment type */
export type CommentToCommentConnection = {
  __typename?: 'CommentToCommentConnection'
  /** Edges for the CommentToCommentConnection connection */
  edges?: Maybe<Array<Maybe<CommentToCommentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Comment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CommentToCommentConnectionEdge = {
  __typename?: 'CommentToCommentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Comment>
}

/** Arguments for filtering the CommentToCommentConnection connection */
export type CommentToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>
}

/** Connection between the Comment type and the Commenter type */
export type CommentToCommenterConnectionEdge = {
  __typename?: 'CommentToCommenterConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Commenter>
}

/** Connection between the Comment type and the ContentNode type */
export type CommentToContentNodeConnectionEdge = {
  __typename?: 'CommentToContentNodeConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<ContentNode>
}

/** Connection between the Comment type and the Comment type */
export type CommentToParentCommentConnectionEdge = {
  __typename?: 'CommentToParentCommentConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Comment>
}

/** Arguments for filtering the CommentToParentCommentConnection connection */
export type CommentToParentCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>
}

/** The author of a comment */
export type Commenter = {
  /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
  avatar?: Maybe<Avatar>
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int']['output']
  /** The email address of the author of a comment. */
  email?: Maybe<Scalars['String']['output']>
  /** The globally unique identifier for the comment author. */
  id: Scalars['ID']['output']
  /** Whether the author information is considered restricted. (not fully public) */
  isRestricted?: Maybe<Scalars['Boolean']['output']>
  /** The name of the author of a comment. */
  name?: Maybe<Scalars['String']['output']>
  /** The url of the author of a comment. */
  url?: Maybe<Scalars['String']['output']>
}

/** Options for ordering the connection */
export enum CommentsConnectionOrderbyEnum {
  /** Order by browser user agent of the commenter. */
  CommentAgent = 'COMMENT_AGENT',
  /** Order by true/false approval of the comment. */
  CommentApproved = 'COMMENT_APPROVED',
  /** Order by name of the comment author. */
  CommentAuthor = 'COMMENT_AUTHOR',
  /** Order by e-mail of the comment author. */
  CommentAuthorEmail = 'COMMENT_AUTHOR_EMAIL',
  /** Order by IP address of the comment author. */
  CommentAuthorIp = 'COMMENT_AUTHOR_IP',
  /** Order by URL address of the comment author. */
  CommentAuthorUrl = 'COMMENT_AUTHOR_URL',
  /** Order by the comment contents. */
  CommentContent = 'COMMENT_CONTENT',
  /** Order by date/time timestamp of the comment. */
  CommentDate = 'COMMENT_DATE',
  /** Order by GMT timezone date/time timestamp of the comment. */
  CommentDateGmt = 'COMMENT_DATE_GMT',
  /** Order by the globally unique identifier for the comment object */
  CommentId = 'COMMENT_ID',
  /** Order by the array list of comment IDs listed in the where clause. */
  CommentIn = 'COMMENT_IN',
  /** Order by the comment karma score. */
  CommentKarma = 'COMMENT_KARMA',
  /** Order by the comment parent ID. */
  CommentParent = 'COMMENT_PARENT',
  /** Order by the post object ID. */
  CommentPostId = 'COMMENT_POST_ID',
  /** Order by the the type of comment, such as 'comment', 'pingback', or 'trackback'. */
  CommentType = 'COMMENT_TYPE',
  /** Order by the user ID. */
  UserId = 'USER_ID',
}

/** The Company type */
export type Company = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Company'
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    companyId?: Maybe<Scalars['Int']['output']>
    /** Connection between the Company type and the ContentNode type */
    contentNodes?: Maybe<CompanyToContentNodeConnection>
    /** Connection between the Company type and the CorrectiveAction type */
    correctiveActions?: Maybe<CompanyToCorrectiveActionConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the Company type and the Document type */
    documents?: Maybe<CompanyToDocumentConnection>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** Connection between the Company type and the Equipment type */
    equipment?: Maybe<CompanyToEquipmentConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Connection between the Company type and the IncidentReport type */
    incidentReports?: Maybe<CompanyToIncidentReportConnection>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Connection between the Company type and the Lesson type */
    lessons?: Maybe<CompanyToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** Connection between the Company type and the Project type */
    projects?: Maybe<CompanyToProjectConnection>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Company type and the Taxonomy type */
    taxonomy?: Maybe<CompanyToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** Connection between the Company type and the ToolboxTalk type */
    toolboxTalks?: Maybe<CompanyToToolboxTalkConnection>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Company type */
export type CompanyContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CompanyToContentNodeConnectionWhereArgs>
}

/** The Company type */
export type CompanyCorrectiveActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CompanyToCorrectiveActionConnectionWhereArgs>
}

/** The Company type */
export type CompanyDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CompanyToDocumentConnectionWhereArgs>
}

/** The Company type */
export type CompanyEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Company type */
export type CompanyEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Company type */
export type CompanyEquipmentArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CompanyToEquipmentConnectionWhereArgs>
}

/** The Company type */
export type CompanyIncidentReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CompanyToIncidentReportConnectionWhereArgs>
}

/** The Company type */
export type CompanyLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CompanyToLessonConnectionWhereArgs>
}

/** The Company type */
export type CompanyProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CompanyToProjectConnectionWhereArgs>
}

/** The Company type */
export type CompanyToolboxTalksArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CompanyToToolboxTalkConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CompanyIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the Company type and the ContentNode type */
export type CompanyToContentNodeConnection = {
  __typename?: 'CompanyToContentNodeConnection'
  /** Edges for the CompanyToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<CompanyToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CompanyToContentNodeConnectionEdge = {
  __typename?: 'CompanyToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the CompanyToContentNodeConnection connection */
export type CompanyToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfCompanyEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CompanyToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<CompanyToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CompanyToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<CompanyToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<CompanyToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CompanyToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CompanyToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<CompanyToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum CompanyToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CompanyToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<CompanyToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<CompanyToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type CompanyToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<CompanyToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CompanyToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CompanyToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Company type and the CorrectiveAction type */
export type CompanyToCorrectiveActionConnection = {
  __typename?: 'CompanyToCorrectiveActionConnection'
  /** Edges for the CompanyToCorrectiveActionConnection connection */
  edges?: Maybe<Array<Maybe<CompanyToCorrectiveActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CompanyToCorrectiveActionConnectionEdge = {
  __typename?: 'CompanyToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Arguments for filtering the CompanyToCorrectiveActionConnection connection */
export type CompanyToCorrectiveActionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CompanyToCorrectiveActionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<CompanyToCorrectiveActionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CompanyToCorrectiveActionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<CompanyToCorrectiveActionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<CompanyToCorrectiveActionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CompanyToCorrectiveActionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CompanyToCorrectiveActionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<CompanyToCorrectiveActionConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum CompanyToCorrectiveActionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CompanyToCorrectiveActionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<CompanyToCorrectiveActionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<CompanyToCorrectiveActionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type CompanyToCorrectiveActionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<CompanyToCorrectiveActionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CompanyToCorrectiveActionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CompanyToCorrectiveActionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Company type and the Document type */
export type CompanyToDocumentConnection = {
  __typename?: 'CompanyToDocumentConnection'
  /** Edges for the CompanyToDocumentConnection connection */
  edges?: Maybe<Array<Maybe<CompanyToDocumentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Document>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CompanyToDocumentConnectionEdge = {
  __typename?: 'CompanyToDocumentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Document>
}

/** Arguments for filtering the CompanyToDocumentConnection connection */
export type CompanyToDocumentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CompanyToDocumentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<CompanyToDocumentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CompanyToDocumentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<CompanyToDocumentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<CompanyToDocumentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CompanyToDocumentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CompanyToDocumentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<CompanyToDocumentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum CompanyToDocumentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CompanyToDocumentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<CompanyToDocumentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<CompanyToDocumentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type CompanyToDocumentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<CompanyToDocumentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CompanyToDocumentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CompanyToDocumentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Company type and the Equipment type */
export type CompanyToEquipmentConnection = {
  __typename?: 'CompanyToEquipmentConnection'
  /** Edges for the CompanyToEquipmentConnection connection */
  edges?: Maybe<Array<Maybe<CompanyToEquipmentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CompanyToEquipmentConnectionEdge = {
  __typename?: 'CompanyToEquipmentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Arguments for filtering the CompanyToEquipmentConnection connection */
export type CompanyToEquipmentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CompanyToEquipmentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<CompanyToEquipmentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CompanyToEquipmentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<CompanyToEquipmentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<CompanyToEquipmentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CompanyToEquipmentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CompanyToEquipmentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<CompanyToEquipmentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum CompanyToEquipmentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CompanyToEquipmentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<CompanyToEquipmentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<CompanyToEquipmentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type CompanyToEquipmentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<CompanyToEquipmentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CompanyToEquipmentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CompanyToEquipmentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Company type and the IncidentReport type */
export type CompanyToIncidentReportConnection = {
  __typename?: 'CompanyToIncidentReportConnection'
  /** Edges for the CompanyToIncidentReportConnection connection */
  edges?: Maybe<Array<Maybe<CompanyToIncidentReportConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReport>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CompanyToIncidentReportConnectionEdge = {
  __typename?: 'CompanyToIncidentReportConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReport>
}

/** Arguments for filtering the CompanyToIncidentReportConnection connection */
export type CompanyToIncidentReportConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CompanyToIncidentReportConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<CompanyToIncidentReportConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CompanyToIncidentReportConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<CompanyToIncidentReportConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<CompanyToIncidentReportConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CompanyToIncidentReportConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CompanyToIncidentReportConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<CompanyToIncidentReportConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum CompanyToIncidentReportConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CompanyToIncidentReportConnectionWhereArgsTaxArray = {
  field?: InputMaybe<CompanyToIncidentReportConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<CompanyToIncidentReportConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type CompanyToIncidentReportConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<CompanyToIncidentReportConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CompanyToIncidentReportConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CompanyToIncidentReportConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Company type and the Lesson type */
export type CompanyToLessonConnection = {
  __typename?: 'CompanyToLessonConnection'
  /** Edges for the CompanyToLessonConnection connection */
  edges?: Maybe<Array<Maybe<CompanyToLessonConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Lesson>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CompanyToLessonConnectionEdge = {
  __typename?: 'CompanyToLessonConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Lesson>
}

/** Arguments for filtering the CompanyToLessonConnection connection */
export type CompanyToLessonConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CompanyToLessonConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<CompanyToLessonConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CompanyToLessonConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<CompanyToLessonConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<CompanyToLessonConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CompanyToLessonConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CompanyToLessonConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<CompanyToLessonConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum CompanyToLessonConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CompanyToLessonConnectionWhereArgsTaxArray = {
  field?: InputMaybe<CompanyToLessonConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<CompanyToLessonConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type CompanyToLessonConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<CompanyToLessonConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CompanyToLessonConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CompanyToLessonConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Company type and the Project type */
export type CompanyToProjectConnection = {
  __typename?: 'CompanyToProjectConnection'
  /** Edges for the CompanyToProjectConnection connection */
  edges?: Maybe<Array<Maybe<CompanyToProjectConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CompanyToProjectConnectionEdge = {
  __typename?: 'CompanyToProjectConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Arguments for filtering the CompanyToProjectConnection connection */
export type CompanyToProjectConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CompanyToProjectConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<CompanyToProjectConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CompanyToProjectConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<CompanyToProjectConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<CompanyToProjectConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CompanyToProjectConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CompanyToProjectConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<CompanyToProjectConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum CompanyToProjectConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CompanyToProjectConnectionWhereArgsTaxArray = {
  field?: InputMaybe<CompanyToProjectConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<CompanyToProjectConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type CompanyToProjectConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<CompanyToProjectConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CompanyToProjectConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CompanyToProjectConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Company type and the Taxonomy type */
export type CompanyToTaxonomyConnectionEdge = {
  __typename?: 'CompanyToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Connection between the Company type and the ToolboxTalk type */
export type CompanyToToolboxTalkConnection = {
  __typename?: 'CompanyToToolboxTalkConnection'
  /** Edges for the CompanyToToolboxTalkConnection connection */
  edges?: Maybe<Array<Maybe<CompanyToToolboxTalkConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ToolboxTalk>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CompanyToToolboxTalkConnectionEdge = {
  __typename?: 'CompanyToToolboxTalkConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ToolboxTalk>
}

/** Arguments for filtering the CompanyToToolboxTalkConnection connection */
export type CompanyToToolboxTalkConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CompanyToToolboxTalkConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<CompanyToToolboxTalkConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CompanyToToolboxTalkConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<CompanyToToolboxTalkConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<CompanyToToolboxTalkConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CompanyToToolboxTalkConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CompanyToToolboxTalkConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<CompanyToToolboxTalkConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum CompanyToToolboxTalkConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CompanyToToolboxTalkConnectionWhereArgsTaxArray = {
  field?: InputMaybe<CompanyToToolboxTalkConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<CompanyToToolboxTalkConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type CompanyToToolboxTalkConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<CompanyToToolboxTalkConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CompanyToToolboxTalkConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CompanyToToolboxTalkConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Compliance details for form submissions. */
export type ComplianceDetails = {
  __typename?: 'ComplianceDetails'
  /** The number of entries expected during the given reporting period. */
  actualCount?: Maybe<Scalars['Int']['output']>
  /** The number of entries expected during the given reporting period. */
  expectedCount?: Maybe<Scalars['Int']['output']>
  /** Connection between the ComplianceDetails type and the UserComplianceDetails type */
  userDetails?: Maybe<ComplianceDetailsToUserComplianceDetailsConnection>
}

/** Compliance details for form submissions. */
export type ComplianceDetailsUserDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ComplianceDetailsToUserComplianceDetailsConnectionWhereArgs>
}

/** Connection between the ComplianceDetails type and the UserComplianceDetails type */
export type ComplianceDetailsToUserComplianceDetailsConnection = {
  __typename?: 'ComplianceDetailsToUserComplianceDetailsConnection'
  /** Edges for the ComplianceDetailsToUserComplianceDetailsConnection connection */
  edges?: Maybe<
    Array<Maybe<ComplianceDetailsToUserComplianceDetailsConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserComplianceDetails>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ComplianceDetailsToUserComplianceDetailsConnectionEdge = {
  __typename?: 'ComplianceDetailsToUserComplianceDetailsConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserComplianceDetails>
}

/** Arguments for filtering the ComplianceDetailsToUserComplianceDetailsConnection connection */
export type ComplianceDetailsToUserComplianceDetailsConnectionWhereArgs = {
  /** Filter by submission count. */
  filterby?: InputMaybe<Array<InputMaybe<UserComplainceFilterInput>>>
  /** Sort order */
  order?: InputMaybe<OrderEnum>
  /** Order by */
  orderby?: InputMaybe<UserComplianceOrderByEnum>
}

/** Gravity Forms conditional logic. */
export type ConditionalLogic = {
  __typename?: 'ConditionalLogic'
  /** The type of action the conditional logic will perform. */
  actionType?: Maybe<ConditionalLogicActionTypeEnum>
  /** Determines how to the rules should be evaluated. */
  logicType?: Maybe<ConditionalLogicLogicTypeEnum>
  /** Conditional logic rules. */
  rules?: Maybe<Array<Maybe<ConditionalLogicRule>>>
}

/** The type of action the conditional logic will perform. */
export enum ConditionalLogicActionTypeEnum {
  /** Text button (default). */
  Hide = 'HIDE',
  /** Image button. */
  Show = 'SHOW',
}

/** Determines how to the rules should be evaluated. */
export enum ConditionalLogicLogicTypeEnum {
  /** Evaulate all logic rules. */
  All = 'ALL',
  /** Evaluate any logic rule. */
  Any = 'ANY',
}

/** Gravity Forms conditional logic rule. */
export type ConditionalLogicRule = {
  __typename?: 'ConditionalLogicRule'
  /** Target field Id. Field that will have it’s value compared with the value property to determine if this rule is a match. */
  fieldId?: Maybe<Scalars['Float']['output']>
  /** Operator to be used when evaluating this rule. */
  operator?: Maybe<FormRuleOperatorEnum>
  /** The value to compare with field specified by fieldId. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type ConsentField = FormField & {
  __typename?: 'ConsentField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Text of the consent checkbox. */
  checkboxLabel?: Maybe<Scalars['String']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** Consent Field Specific Translations */
  consentTranslations?: Maybe<Array<Maybe<ConsentTranslation>>>
  /** Consent field value. This is `true` when consent is given, `false` when it is not. */
  consentValue?: Maybe<Scalars['Boolean']['output']>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Gravity Forms translation settings */
export type ConsentTranslation = {
  __typename?: 'ConsentTranslation'
  /** Text Translation of the chechbox label */
  checkboxLabel?: Maybe<Scalars['String']['output']>
  /** Description translation of the field */
  description?: Maybe<Scalars['String']['output']>
  /** Label Translation of the field */
  label?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
}

/** Nodes used to manage content */
export type ContentNode = {
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String']['output']
  /** The ID of the node in the database. */
  databaseId: Scalars['Int']['output']
  /** Post publishing date. */
  date?: Maybe<Scalars['String']['output']>
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']['output']>
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']['output']>
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']['output']>
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']['output']>
  /** The unique resource identifier path */
  id: Scalars['ID']['output']
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output']
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']['output']>
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output']
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
  /** The permalink of the post */
  link?: Maybe<Scalars['String']['output']>
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']['output']>
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']['output']>
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']['output']>
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']['output']>
  /** The current status of the object */
  status?: Maybe<Scalars['String']['output']>
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>
}

/** Nodes used to manage content */
export type ContentNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Nodes used to manage content */
export type ContentNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ContentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Connection between the ContentNode type and the ContentType type */
export type ContentNodeToContentTypeConnectionEdge = {
  __typename?: 'ContentNodeToContentTypeConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<ContentType>
}

/** Connection between the ContentNode type and the User type */
export type ContentNodeToEditLastConnectionEdge = {
  __typename?: 'ContentNodeToEditLastConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<User>
}

/** Connection between the ContentNode type and the User type */
export type ContentNodeToEditLockConnectionEdge = {
  __typename?: 'ContentNodeToEditLockConnectionEdge'
  /** The timestamp for when the node was last edited */
  lockTimestamp?: Maybe<Scalars['String']['output']>
  /** The node of the connection, without the edges */
  node?: Maybe<User>
}

/** Connection between the ContentNode type and the EnqueuedScript type */
export type ContentNodeToEnqueuedScriptConnection = {
  __typename?: 'ContentNodeToEnqueuedScriptConnection'
  /** Edges for the ContentNodeToEnqueuedScriptConnection connection */
  edges?: Maybe<Array<Maybe<ContentNodeToEnqueuedScriptConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ContentNodeToEnqueuedScriptConnectionEdge = {
  __typename?: 'ContentNodeToEnqueuedScriptConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedScript>
}

/** Connection between the ContentNode type and the EnqueuedStylesheet type */
export type ContentNodeToEnqueuedStylesheetConnection = {
  __typename?: 'ContentNodeToEnqueuedStylesheetConnection'
  /** Edges for the ContentNodeToEnqueuedStylesheetConnection connection */
  edges?: Maybe<Array<Maybe<ContentNodeToEnqueuedStylesheetConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ContentNodeToEnqueuedStylesheetConnectionEdge = {
  __typename?: 'ContentNodeToEnqueuedStylesheetConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedStylesheet>
}

/** A union of Content Node Types that support revisions */
export type ContentRevisionUnion = Page | Post

/** The template assigned to a node of content */
export type ContentTemplate = {
  /** The name of the template */
  templateName?: Maybe<Scalars['String']['output']>
}

/** An Post Type object */
export type ContentType = Node &
  UniformResourceIdentifiable & {
    __typename?: 'ContentType'
    /** The url path of the first page of the archive page for this content type. */
    archivePath?: Maybe<Scalars['String']['output']>
    /** Whether this content type should can be exported. */
    canExport?: Maybe<Scalars['Boolean']['output']>
    /** Connection between the ContentType type and the Taxonomy type */
    connectedTaxonomies?: Maybe<ContentTypeToTaxonomyConnection>
    /** Connection between the ContentType type and the ContentNode type */
    contentNodes?: Maybe<ContentTypeToContentNodeConnection>
    /** Whether content of this type should be deleted when the author of it is deleted from the system. */
    deleteWithUser?: Maybe<Scalars['Boolean']['output']>
    /** Description of the content type. */
    description?: Maybe<Scalars['String']['output']>
    /** Whether to exclude nodes of this content type from front end search results. */
    excludeFromSearch?: Maybe<Scalars['Boolean']['output']>
    /** The plural name of the content type within the GraphQL Schema. */
    graphqlPluralName?: Maybe<Scalars['String']['output']>
    /** The singular name of the content type within the GraphQL Schema. */
    graphqlSingleName?: Maybe<Scalars['String']['output']>
    /** Whether this content type should have archives. Content archives are generated by type and by date. */
    hasArchive?: Maybe<Scalars['Boolean']['output']>
    /** Whether the content type is hierarchical, for example pages. */
    hierarchical?: Maybe<Scalars['Boolean']['output']>
    /** The globally unique identifier of the post-type object. */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether this page is set to the static front page. */
    isFrontPage: Scalars['Boolean']['output']
    /** Whether this page is set to the blog posts page. */
    isPostsPage: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Display name of the content type. */
    label?: Maybe<Scalars['String']['output']>
    /** Details about the content type labels. */
    labels?: Maybe<PostTypeLabelDetails>
    /** The name of the icon file to display as a menu icon. */
    menuIcon?: Maybe<Scalars['String']['output']>
    /** The position of this post type in the menu. Only applies if show_in_menu is true. */
    menuPosition?: Maybe<Scalars['Int']['output']>
    /** The internal name of the post type. This should not be used for display purposes. */
    name?: Maybe<Scalars['String']['output']>
    /** Whether a content type is intended for use publicly either via the admin interface or by front-end users. While the default settings of exclude_from_search, publicly_queryable, show_ui, and show_in_nav_menus are inherited from public, each does not rely on this relationship and controls a very specific intention. */
    public?: Maybe<Scalars['Boolean']['output']>
    /** Whether queries can be performed on the front end for the content type as part of parse_request(). */
    publiclyQueryable?: Maybe<Scalars['Boolean']['output']>
    /** Name of content type to display in REST API &quot;wp/v2&quot; namespace. */
    restBase?: Maybe<Scalars['String']['output']>
    /** The REST Controller class assigned to handling this content type. */
    restControllerClass?: Maybe<Scalars['String']['output']>
    /** Makes this content type available via the admin bar. */
    showInAdminBar?: Maybe<Scalars['Boolean']['output']>
    /** Whether to add the content type to the GraphQL Schema. */
    showInGraphql?: Maybe<Scalars['Boolean']['output']>
    /** Where to show the content type in the admin menu. To work, $show_ui must be true. If true, the post type is shown in its own top level menu. If false, no menu is shown. If a string of an existing top level menu (eg. &quot;tools.php&quot; or &quot;edit.php?post_type=page&quot;), the post type will be placed as a sub-menu of that. */
    showInMenu?: Maybe<Scalars['Boolean']['output']>
    /** Makes this content type available for selection in navigation menus. */
    showInNavMenus?: Maybe<Scalars['Boolean']['output']>
    /** Whether the content type is associated with a route under the the REST API &quot;wp/v2&quot; namespace. */
    showInRest?: Maybe<Scalars['Boolean']['output']>
    /** Whether to generate and allow a UI for managing this content type in the admin. */
    showUi?: Maybe<Scalars['Boolean']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** An Post Type object */
export type ContentTypeConnectedTaxonomiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** An Post Type object */
export type ContentTypeContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgs>
}

/** Allowed Content Types */
export enum ContentTypeEnum {
  /** The Type of Content object */
  ActionMonitor = 'ACTION_MONITOR',
  /** The Type of Content object */
  Attachment = 'ATTACHMENT',
  /** The Type of Content object */
  Certification = 'CERTIFICATION',
  /** The Type of Content object */
  CorrectiveAction = 'CORRECTIVE_ACTION',
  /** The Type of Content object */
  Document = 'DOCUMENT',
  /** The Type of Content object */
  Equipment = 'EQUIPMENT',
  /** The Type of Content object */
  IncidentReport = 'INCIDENT_REPORT',
  /** The Type of Content object */
  Lesson = 'LESSON',
  /** The Type of Content object */
  Page = 'PAGE',
  /** The Type of Content object */
  Photo = 'PHOTO',
  /** The Type of Content object */
  Post = 'POST',
  /** The Type of Content object */
  Project = 'PROJECT',
  /** The Type of Content object */
  ToolboxTalk = 'TOOLBOX_TALK',
}

/** The Type of Identifier used to fetch a single Content Type node. To be used along with the "id" field. Default is "ID". */
export enum ContentTypeIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the content type. */
  Name = 'NAME',
}

/** Connection between the ContentType type and the ContentNode type */
export type ContentTypeToContentNodeConnection = {
  __typename?: 'ContentTypeToContentNodeConnection'
  /** Edges for the ContentTypeToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<ContentTypeToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ContentTypeToContentNodeConnectionEdge = {
  __typename?: 'ContentTypeToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the ContentTypeToContentNodeConnection connection */
export type ContentTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ContentTypeToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ContentTypeToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ContentTypeToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<ContentTypeToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ContentTypeToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ContentTypeToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ContentTypeToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<ContentTypeToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ContentTypeToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ContentTypeToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the ContentType type and the Taxonomy type */
export type ContentTypeToTaxonomyConnection = {
  __typename?: 'ContentTypeToTaxonomyConnection'
  /** Edges for the ContentTypeToTaxonomyConnection connection */
  edges?: Maybe<Array<Maybe<ContentTypeToTaxonomyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Taxonomy>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ContentTypeToTaxonomyConnectionEdge = {
  __typename?: 'ContentTypeToTaxonomyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Taxonomy>
}

/** Allowed Content Types of the Category taxonomy. */
export enum ContentTypesOfCategoryEnum {
  /** The Type of Content object */
  Post = 'POST',
}

/** Allowed Content Types of the Company taxonomy. */
export enum ContentTypesOfCompanyEnum {
  /** The Type of Content object */
  CorrectiveAction = 'CORRECTIVE_ACTION',
  /** The Type of Content object */
  Document = 'DOCUMENT',
  /** The Type of Content object */
  Equipment = 'EQUIPMENT',
  /** The Type of Content object */
  IncidentReport = 'INCIDENT_REPORT',
  /** The Type of Content object */
  Lesson = 'LESSON',
  /** The Type of Content object */
  Project = 'PROJECT',
  /** The Type of Content object */
  ToolboxTalk = 'TOOLBOX_TALK',
}

/** Allowed Content Types of the CorrectiveActionStatus taxonomy. */
export enum ContentTypesOfCorrectiveActionStatusEnum {
  /** The Type of Content object */
  CorrectiveAction = 'CORRECTIVE_ACTION',
}

/** Allowed Content Types of the DeficiencyType taxonomy. */
export enum ContentTypesOfDeficiencyTypeEnum {
  /** The Type of Content object */
  CorrectiveAction = 'CORRECTIVE_ACTION',
}

/** Allowed Content Types of the Division taxonomy. */
export enum ContentTypesOfDivisionEnum {
  /** The Type of Content object */
  CorrectiveAction = 'CORRECTIVE_ACTION',
  /** The Type of Content object */
  Document = 'DOCUMENT',
  /** The Type of Content object */
  Equipment = 'EQUIPMENT',
  /** The Type of Content object */
  IncidentReport = 'INCIDENT_REPORT',
  /** The Type of Content object */
  Lesson = 'LESSON',
  /** The Type of Content object */
  Project = 'PROJECT',
  /** The Type of Content object */
  ToolboxTalk = 'TOOLBOX_TALK',
}

/** Allowed Content Types of the DocumentTag taxonomy. */
export enum ContentTypesOfDocumentTagEnum {
  /** The Type of Content object */
  Document = 'DOCUMENT',
}

/** Allowed Content Types of the EntryId taxonomy. */
export enum ContentTypesOfEntryIdEnum {
  /** The Type of Content object */
  CorrectiveAction = 'CORRECTIVE_ACTION',
  /** The Type of Content object */
  Photo = 'PHOTO',
}

/** Allowed Content Types of the EquipmentStatus taxonomy. */
export enum ContentTypesOfEquipmentStatusEnum {
  /** The Type of Content object */
  Equipment = 'EQUIPMENT',
}

/** Allowed Content Types of the EquipmentTag taxonomy. */
export enum ContentTypesOfEquipmentTagEnum {
  /** The Type of Content object */
  Equipment = 'EQUIPMENT',
}

/** Allowed Content Types of the FieldId taxonomy. */
export enum ContentTypesOfFieldIdEnum {
  /** The Type of Content object */
  CorrectiveAction = 'CORRECTIVE_ACTION',
  /** The Type of Content object */
  Photo = 'PHOTO',
}

/** Allowed Content Types of the FormId taxonomy. */
export enum ContentTypesOfFormIdEnum {
  /** The Type of Content object */
  CorrectiveAction = 'CORRECTIVE_ACTION',
  /** The Type of Content object */
  Photo = 'PHOTO',
}

/** Allowed Content Types of the IncidentReportClassification taxonomy. */
export enum ContentTypesOfIncidentReportClassificationEnum {
  /** The Type of Content object */
  IncidentReport = 'INCIDENT_REPORT',
}

/** Allowed Content Types of the IncidentReportStatus taxonomy. */
export enum ContentTypesOfIncidentReportStatusEnum {
  /** The Type of Content object */
  IncidentReport = 'INCIDENT_REPORT',
}

/** Allowed Content Types of the IncidentType taxonomy. */
export enum ContentTypesOfIncidentTypeEnum {
  /** The Type of Content object */
  IncidentReport = 'INCIDENT_REPORT',
}

/** Allowed Content Types of the InjuryArea taxonomy. */
export enum ContentTypesOfInjuryAreaEnum {
  /** The Type of Content object */
  IncidentReport = 'INCIDENT_REPORT',
}

/** Allowed Content Types of the InspectionInterval taxonomy. */
export enum ContentTypesOfInspectionIntervalEnum {
  /** The Type of Content object */
  Equipment = 'EQUIPMENT',
}

/** Allowed Content Types of the LessonCategory taxonomy. */
export enum ContentTypesOfLessonCategoryEnum {
  /** The Type of Content object */
  Lesson = 'LESSON',
}

/** Allowed Content Types of the LessonDifficulty taxonomy. */
export enum ContentTypesOfLessonDifficultyEnum {
  /** The Type of Content object */
  Lesson = 'LESSON',
}

/** Allowed Content Types of the Manufacturer taxonomy. */
export enum ContentTypesOfManufacturerEnum {
  /** The Type of Content object */
  Equipment = 'EQUIPMENT',
}

/** Allowed Content Types of the PostFormat taxonomy. */
export enum ContentTypesOfPostFormatEnum {
  /** The Type of Content object */
  Post = 'POST',
}

/** Allowed Content Types of the ProjectStatus taxonomy. */
export enum ContentTypesOfProjectStatusEnum {
  /** The Type of Content object */
  Project = 'PROJECT',
}

/** Allowed Content Types of the ProjectType taxonomy. */
export enum ContentTypesOfProjectTypeEnum {
  /** The Type of Content object */
  Project = 'PROJECT',
}

/** Allowed Content Types of the Provider taxonomy. */
export enum ContentTypesOfProviderEnum {
  /** The Type of Content object */
  Certification = 'CERTIFICATION',
  /** The Type of Content object */
  Lesson = 'LESSON',
}

/** Allowed Content Types of the RootCause taxonomy. */
export enum ContentTypesOfRootCauseEnum {
  /** The Type of Content object */
  IncidentReport = 'INCIDENT_REPORT',
}

/** Allowed Content Types of the Severity taxonomy. */
export enum ContentTypesOfSeverityEnum {
  /** The Type of Content object */
  CorrectiveAction = 'CORRECTIVE_ACTION',
}

/** Allowed Content Types of the Tag taxonomy. */
export enum ContentTypesOfTagEnum {
  /** The Type of Content object */
  Post = 'POST',
}

/** Allowed Content Types of the ToolboxTalkCategory taxonomy. */
export enum ContentTypesOfToolboxTalkCategoryEnum {
  /** The Type of Content object */
  ToolboxTalk = 'TOOLBOX_TALK',
}

/** Allowed Content Types of the TrainingType taxonomy. */
export enum ContentTypesOfTrainingTypeEnum {
  /** The Type of Content object */
  Certification = 'CERTIFICATION',
  /** The Type of Content object */
  Lesson = 'LESSON',
}

/** The CorrectiveAction type */
export type CorrectiveAction = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'CorrectiveAction'
    /** Action taken to correct the deficiency */
    actionTaken?: Maybe<Scalars['String']['output']>
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** Connection between the CorrectiveAction type and the Company type */
    companies?: Maybe<CorrectiveActionToCompanyConnection>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** Connection between the CorrectiveAction type and the User type */
    correctiveActionAssignedWorkersConnection?: Maybe<CorrectiveActionToUserConnection>
    /** Connection between the CorrectiveAction type and the User type */
    correctiveActionDeficiencyOwnersConnection?: Maybe<CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    correctiveActionId: Scalars['Int']['output']
    /** Connection between the CorrectiveAction type and the Photo type */
    correctiveActionPhotoConnection?: Maybe<CorrectiveActionToPhotoConnection>
    /** Connection between the CorrectiveAction type and the CorrectiveActionStatus type */
    correctiveActionStatuses?: Maybe<CorrectiveActionToCorrectiveActionStatusConnection>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** Date completed */
    dateCompleted?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** Field labels of those GF form field which triggered the follow up form */
    deficiencyFieldLabels?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Connection between the CorrectiveAction type and the DeficiencyType type */
    deficiencyTypes?: Maybe<CorrectiveActionToDeficiencyTypeConnection>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** Connection between the CorrectiveAction type and the Division type */
    divisions?: Maybe<CorrectiveActionToDivisionConnection>
    /** Due date */
    dueDate?: Maybe<Scalars['String']['output']>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** The Gravity Forms Entry associated with this Corrective Action. */
    entry?: Maybe<GfEntry>
    /** Connection between the CorrectiveAction type and the EntryId type */
    entryIds?: Maybe<CorrectiveActionToEntryIdConnection>
    /** Connection between the CorrectiveAction type and the FieldId type */
    fieldIds?: Maybe<CorrectiveActionToFieldIdConnection>
    /** The Gravity Forms Form associated with this Corrective Action. */
    form?: Maybe<GfForm>
    /** Connection between the CorrectiveAction type and the FormId type */
    formIds?: Maybe<CorrectiveActionToFormIdConnection>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the corrective_action object. */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /** Connection between the CorrectiveAction type and the MediaItem type */
    pdf?: Maybe<CorrectiveActionToMediaItemConnectionEdge>
    /** Connection between the CorrectiveAction type and the CorrectiveAction type */
    preview?: Maybe<CorrectiveActionToPreviewConnectionEdge>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** Connection between the CorrectiveAction type and the Project type */
    projectCorrectiveActionConnection?: Maybe<CorrectiveActionToProjectConnection>
    /** Connection between the CorrectiveAction type and the Severity type */
    severities?: Maybe<CorrectiveActionToSeverityConnection>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** Connection between the CorrectiveAction type and the TermNode type */
    terms?: Maybe<CorrectiveActionToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The CorrectiveAction type */
export type CorrectiveActionCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CorrectiveActionToCompanyConnectionWhereArgs>
}

/** The CorrectiveAction type */
export type CorrectiveActionCorrectiveActionAssignedWorkersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The CorrectiveAction type */
export type CorrectiveActionCorrectiveActionDeficiencyOwnersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The CorrectiveAction type */
export type CorrectiveActionCorrectiveActionPhotoConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The CorrectiveAction type */
export type CorrectiveActionCorrectiveActionStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CorrectiveActionToCorrectiveActionStatusConnectionWhereArgs>
}

/** The CorrectiveAction type */
export type CorrectiveActionDeficiencyTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CorrectiveActionToDeficiencyTypeConnectionWhereArgs>
}

/** The CorrectiveAction type */
export type CorrectiveActionDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CorrectiveActionToDivisionConnectionWhereArgs>
}

/** The CorrectiveAction type */
export type CorrectiveActionEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The CorrectiveAction type */
export type CorrectiveActionEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The CorrectiveAction type */
export type CorrectiveActionEntryIdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CorrectiveActionToEntryIdConnectionWhereArgs>
}

/** The CorrectiveAction type */
export type CorrectiveActionFieldIdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CorrectiveActionToFieldIdConnectionWhereArgs>
}

/** The CorrectiveAction type */
export type CorrectiveActionFormIdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CorrectiveActionToFormIdConnectionWhereArgs>
}

/** The CorrectiveAction type */
export type CorrectiveActionProjectCorrectiveActionConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The CorrectiveAction type */
export type CorrectiveActionSeveritiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CorrectiveActionToSeverityConnectionWhereArgs>
}

/** The CorrectiveAction type */
export type CorrectiveActionTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CorrectiveActionToTermNodeConnectionWhereArgs>
}

/** The CorrectiveAction type */
export type CorrectiveActionTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** Set relationships between the CorrectiveAction to Companies */
export type CorrectiveActionCompaniesInput = {
  /** If true, this will append the Company to existing related Companies. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CorrectiveActionCompaniesNodeInput>>>
}

/** List of Companies to connect the CorrectiveAction to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CorrectiveActionCompaniesNodeInput = {
  /** The description of the Company. This field is used to set a description of the Company if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Company. If present, this will be used to connect to the CorrectiveAction. If no existing Company exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Company. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Company. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the CorrectiveAction to CorrectiveActionStatuses */
export type CorrectiveActionCorrectiveActionStatusesInput = {
  /** If true, this will append the CorrectiveActionStatus to existing related CorrectiveActionStatuses. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<
    Array<InputMaybe<CorrectiveActionCorrectiveActionStatusesNodeInput>>
  >
}

/** List of CorrectiveActionStatuses to connect the CorrectiveAction to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CorrectiveActionCorrectiveActionStatusesNodeInput = {
  /** The description of the CorrectiveActionStatus. This field is used to set a description of the CorrectiveActionStatus if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the CorrectiveActionStatus. If present, this will be used to connect to the CorrectiveAction. If no existing CorrectiveActionStatus exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the CorrectiveActionStatus. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the CorrectiveActionStatus. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the CorrectiveAction to DeficiencyTypes */
export type CorrectiveActionDeficiencyTypesInput = {
  /** If true, this will append the DeficiencyType to existing related DeficiencyTypes. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<
    Array<InputMaybe<CorrectiveActionDeficiencyTypesNodeInput>>
  >
}

/** List of DeficiencyTypes to connect the CorrectiveAction to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CorrectiveActionDeficiencyTypesNodeInput = {
  /** The description of the DeficiencyType. This field is used to set a description of the DeficiencyType if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the DeficiencyType. If present, this will be used to connect to the CorrectiveAction. If no existing DeficiencyType exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the DeficiencyType. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the DeficiencyType. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the CorrectiveAction to Divisions */
export type CorrectiveActionDivisionsInput = {
  /** If true, this will append the Division to existing related Divisions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CorrectiveActionDivisionsNodeInput>>>
}

/** List of Divisions to connect the CorrectiveAction to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CorrectiveActionDivisionsNodeInput = {
  /** The description of the Division. This field is used to set a description of the Division if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Division. If present, this will be used to connect to the CorrectiveAction. If no existing Division exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Division. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Division. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the CorrectiveAction to EntryIds */
export type CorrectiveActionEntryIdsInput = {
  /** If true, this will append the EntryId to existing related EntryIds. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CorrectiveActionEntryIdsNodeInput>>>
}

/** List of EntryIds to connect the CorrectiveAction to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CorrectiveActionEntryIdsNodeInput = {
  /** The description of the EntryId. This field is used to set a description of the EntryId if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the EntryId. If present, this will be used to connect to the CorrectiveAction. If no existing EntryId exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the EntryId. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the EntryId. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the CorrectiveAction to FieldIds */
export type CorrectiveActionFieldIdsInput = {
  /** If true, this will append the FieldId to existing related FieldIds. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CorrectiveActionFieldIdsNodeInput>>>
}

/** List of FieldIds to connect the CorrectiveAction to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CorrectiveActionFieldIdsNodeInput = {
  /** The description of the FieldId. This field is used to set a description of the FieldId if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the FieldId. If present, this will be used to connect to the CorrectiveAction. If no existing FieldId exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the FieldId. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the FieldId. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the CorrectiveAction to FormIds */
export type CorrectiveActionFormIdsInput = {
  /** If true, this will append the FormId to existing related FormIds. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CorrectiveActionFormIdsNodeInput>>>
}

/** List of FormIds to connect the CorrectiveAction to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CorrectiveActionFormIdsNodeInput = {
  /** The description of the FormId. This field is used to set a description of the FormId if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the FormId. If present, this will be used to connect to the CorrectiveAction. If no existing FormId exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the FormId. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the FormId. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CorrectiveActionIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Data to populate Corrective Actions with when this field is deficient. */
export type CorrectiveActionSettings = {
  __typename?: 'CorrectiveActionSettings'
  /** Corrective action taken to correct the deficiency */
  actionTaken?: Maybe<Scalars['String']['output']>
  /** Deficiency text */
  deficiency?: Maybe<Scalars['String']['output']>
  /** Deficiency Type taxonomy terms */
  deficiencyType?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Severity */
  severity?: Maybe<Scalars['String']['output']>
}

/** Set relationships between the CorrectiveAction to Severities */
export type CorrectiveActionSeveritiesInput = {
  /** If true, this will append the Severity to existing related Severities. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CorrectiveActionSeveritiesNodeInput>>>
}

/** List of Severities to connect the CorrectiveAction to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CorrectiveActionSeveritiesNodeInput = {
  /** The description of the Severity. This field is used to set a description of the Severity if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Severity. If present, this will be used to connect to the CorrectiveAction. If no existing Severity exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Severity. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Severity. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The CorrectiveActionStatus type */
export type CorrectiveActionStatus = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'CorrectiveActionStatus'
    /** Connection between the CorrectiveActionStatus type and the ContentNode type */
    contentNodes?: Maybe<CorrectiveActionStatusToContentNodeConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    correctiveActionStatusId?: Maybe<Scalars['Int']['output']>
    /** Connection between the CorrectiveActionStatus type and the CorrectiveAction type */
    correctiveActions?: Maybe<CorrectiveActionStatusToCorrectiveActionConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the CorrectiveActionStatus type and the Taxonomy type */
    taxonomy?: Maybe<CorrectiveActionStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The CorrectiveActionStatus type */
export type CorrectiveActionStatusContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CorrectiveActionStatusToContentNodeConnectionWhereArgs>
}

/** The CorrectiveActionStatus type */
export type CorrectiveActionStatusCorrectiveActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<CorrectiveActionStatusToCorrectiveActionConnectionWhereArgs>
}

/** The CorrectiveActionStatus type */
export type CorrectiveActionStatusEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The CorrectiveActionStatus type */
export type CorrectiveActionStatusEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Corrective Action Status Options */
export enum CorrectiveActionStatusEnum {
  /** Used when the Corrective Action has been assigned workers. */
  Assigned = 'assigned',
  /** Used when the Corrective Action has been complete. */
  Completed = 'completed',
  /** Used when the Corrective Action has been created without assigned workers. */
  Open = 'open',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CorrectiveActionStatusIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the CorrectiveActionStatus type and the ContentNode type */
export type CorrectiveActionStatusToContentNodeConnection = {
  __typename?: 'CorrectiveActionStatusToContentNodeConnection'
  /** Edges for the CorrectiveActionStatusToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionStatusToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionStatusToContentNodeConnectionEdge = {
  __typename?: 'CorrectiveActionStatusToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the CorrectiveActionStatusToContentNodeConnection connection */
export type CorrectiveActionStatusToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfCorrectiveActionStatusEnum>>
  >
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CorrectiveActionStatusToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<CorrectiveActionStatusToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CorrectiveActionStatusToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<CorrectiveActionStatusToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<CorrectiveActionStatusToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CorrectiveActionStatusToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CorrectiveActionStatusToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<
      InputMaybe<CorrectiveActionStatusToContentNodeConnectionWhereArgsMetaArray>
    >
  >
  relation?: InputMaybe<RelationEnum>
}

export enum CorrectiveActionStatusToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CorrectiveActionStatusToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<CorrectiveActionStatusToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<CorrectiveActionStatusToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type CorrectiveActionStatusToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<
      InputMaybe<CorrectiveActionStatusToContentNodeConnectionWhereArgsTaxArray>
    >
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CorrectiveActionStatusToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CorrectiveActionStatusToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the CorrectiveActionStatus type and the CorrectiveAction type */
export type CorrectiveActionStatusToCorrectiveActionConnection = {
  __typename?: 'CorrectiveActionStatusToCorrectiveActionConnection'
  /** Edges for the CorrectiveActionStatusToCorrectiveActionConnection connection */
  edges?: Maybe<
    Array<Maybe<CorrectiveActionStatusToCorrectiveActionConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionStatusToCorrectiveActionConnectionEdge = {
  __typename?: 'CorrectiveActionStatusToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Arguments for filtering the CorrectiveActionStatusToCorrectiveActionConnection connection */
export type CorrectiveActionStatusToCorrectiveActionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsMetaArray =
  {
    /** Custom field value */
    compare?: InputMaybe<CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsMetaCompareEnum>
    /** Custom field key */
    key?: InputMaybe<Scalars['String']['input']>
    /** Custom field value */
    type?: InputMaybe<CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsMetaTypeEnum>
    /** Custom field value */
    value?: InputMaybe<Scalars['String']['input']>
    /** Custom field values */
    values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  }

export enum CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsMetaQuery =
  {
    metaArray?: InputMaybe<
      Array<
        InputMaybe<CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsMetaArray>
      >
    >
    relation?: InputMaybe<RelationEnum>
  }

export enum CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsTaxArray =
  {
    field?: InputMaybe<CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsTaxQueryField>
    /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
    includeChildren?: InputMaybe<Scalars['Boolean']['input']>
    operator?: InputMaybe<CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsTaxQueryOperator>
    taxonomy?: InputMaybe<TaxonomyEnum>
    /** A list of term slugs */
    terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  }

/** Query objects based on taxonomy parameters */
export type CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsTaxQuery =
  {
    relation?: InputMaybe<RelationEnum>
    taxArray?: InputMaybe<
      Array<
        InputMaybe<CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsTaxArray>
      >
    >
  }

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum CorrectiveActionStatusToCorrectiveActionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the CorrectiveActionStatus type and the Taxonomy type */
export type CorrectiveActionStatusToTaxonomyConnectionEdge = {
  __typename?: 'CorrectiveActionStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Connection between the CorrectiveAction type and the Company type */
export type CorrectiveActionToCompanyConnection = {
  __typename?: 'CorrectiveActionToCompanyConnection'
  /** Edges for the CorrectiveActionToCompanyConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionToCompanyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Company>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToCompanyConnectionEdge = {
  __typename?: 'CorrectiveActionToCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Company>
}

/** Arguments for filtering the CorrectiveActionToCompanyConnection connection */
export type CorrectiveActionToCompanyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the CorrectiveAction type and the User type */
export type CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection =
  {
    __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection'
    /** Edges for the CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection connection */
    edges?: Maybe<
      Array<
        Maybe<CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge>
      >
    >
    /** The nodes of the connection, without the edges */
    nodes?: Maybe<Array<Maybe<User>>>
    /** Information about pagination in a connection. */
    pageInfo?: Maybe<WpPageInfo>
  }

/** An edge in a connection */
export type CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge =
  {
    __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>
    /** The item at the end of the edge */
    node?: Maybe<User>
  }

/** Connection between the CorrectiveAction type and the CorrectiveActionStatus type */
export type CorrectiveActionToCorrectiveActionStatusConnection = {
  __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
  /** Edges for the CorrectiveActionToCorrectiveActionStatusConnection connection */
  edges?: Maybe<
    Array<Maybe<CorrectiveActionToCorrectiveActionStatusConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveActionStatus>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToCorrectiveActionStatusConnectionEdge = {
  __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveActionStatus>
}

/** Arguments for filtering the CorrectiveActionToCorrectiveActionStatusConnection connection */
export type CorrectiveActionToCorrectiveActionStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the CorrectiveAction type and the DeficiencyType type */
export type CorrectiveActionToDeficiencyTypeConnection = {
  __typename?: 'CorrectiveActionToDeficiencyTypeConnection'
  /** Edges for the CorrectiveActionToDeficiencyTypeConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionToDeficiencyTypeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<DeficiencyType>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToDeficiencyTypeConnectionEdge = {
  __typename?: 'CorrectiveActionToDeficiencyTypeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<DeficiencyType>
}

/** Arguments for filtering the CorrectiveActionToDeficiencyTypeConnection connection */
export type CorrectiveActionToDeficiencyTypeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the CorrectiveAction type and the Division type */
export type CorrectiveActionToDivisionConnection = {
  __typename?: 'CorrectiveActionToDivisionConnection'
  /** Edges for the CorrectiveActionToDivisionConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionToDivisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Division>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToDivisionConnectionEdge = {
  __typename?: 'CorrectiveActionToDivisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Division>
}

/** Arguments for filtering the CorrectiveActionToDivisionConnection connection */
export type CorrectiveActionToDivisionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the CorrectiveAction type and the EntryId type */
export type CorrectiveActionToEntryIdConnection = {
  __typename?: 'CorrectiveActionToEntryIdConnection'
  /** Edges for the CorrectiveActionToEntryIdConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionToEntryIdConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EntryId>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToEntryIdConnectionEdge = {
  __typename?: 'CorrectiveActionToEntryIdConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EntryId>
}

/** Arguments for filtering the CorrectiveActionToEntryIdConnection connection */
export type CorrectiveActionToEntryIdConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the CorrectiveAction type and the FieldId type */
export type CorrectiveActionToFieldIdConnection = {
  __typename?: 'CorrectiveActionToFieldIdConnection'
  /** Edges for the CorrectiveActionToFieldIdConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionToFieldIdConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<FieldId>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToFieldIdConnectionEdge = {
  __typename?: 'CorrectiveActionToFieldIdConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<FieldId>
}

/** Arguments for filtering the CorrectiveActionToFieldIdConnection connection */
export type CorrectiveActionToFieldIdConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the CorrectiveAction type and the FormId type */
export type CorrectiveActionToFormIdConnection = {
  __typename?: 'CorrectiveActionToFormIdConnection'
  /** Edges for the CorrectiveActionToFormIdConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionToFormIdConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<FormId>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToFormIdConnectionEdge = {
  __typename?: 'CorrectiveActionToFormIdConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<FormId>
}

/** Arguments for filtering the CorrectiveActionToFormIdConnection connection */
export type CorrectiveActionToFormIdConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the CorrectiveAction type and the MediaItem type */
export type CorrectiveActionToMediaItemConnectionEdge = {
  __typename?: 'CorrectiveActionToMediaItemConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<MediaItem>
}

/** Connection between the CorrectiveAction type and the Photo type */
export type CorrectiveActionToPhotoConnection = {
  __typename?: 'CorrectiveActionToPhotoConnection'
  /** Edges for the CorrectiveActionToPhotoConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionToPhotoConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Photo>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToPhotoConnectionEdge = {
  __typename?: 'CorrectiveActionToPhotoConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Photo>
}

/** Connection between the CorrectiveAction type and the CorrectiveAction type */
export type CorrectiveActionToPreviewConnectionEdge = {
  __typename?: 'CorrectiveActionToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<CorrectiveAction>
}

/** Connection between the CorrectiveAction type and the Project type */
export type CorrectiveActionToProjectConnection = {
  __typename?: 'CorrectiveActionToProjectConnection'
  /** Edges for the CorrectiveActionToProjectConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionToProjectConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToProjectConnectionEdge = {
  __typename?: 'CorrectiveActionToProjectConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Connection between the CorrectiveAction type and the Severity type */
export type CorrectiveActionToSeverityConnection = {
  __typename?: 'CorrectiveActionToSeverityConnection'
  /** Edges for the CorrectiveActionToSeverityConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionToSeverityConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Severity>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToSeverityConnectionEdge = {
  __typename?: 'CorrectiveActionToSeverityConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Severity>
}

/** Arguments for filtering the CorrectiveActionToSeverityConnection connection */
export type CorrectiveActionToSeverityConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the CorrectiveAction type and the TermNode type */
export type CorrectiveActionToTermNodeConnection = {
  __typename?: 'CorrectiveActionToTermNodeConnection'
  /** Edges for the CorrectiveActionToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionToTermNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToTermNodeConnectionEdge = {
  __typename?: 'CorrectiveActionToTermNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TermNode>
}

/** Arguments for filtering the CorrectiveActionToTermNodeConnection connection */
export type CorrectiveActionToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the CorrectiveAction type and the User type */
export type CorrectiveActionToUserConnection = {
  __typename?: 'CorrectiveActionToUserConnection'
  /** Edges for the CorrectiveActionToUserConnection connection */
  edges?: Maybe<Array<Maybe<CorrectiveActionToUserConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<User>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type CorrectiveActionToUserConnectionEdge = {
  __typename?: 'CorrectiveActionToUserConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<User>
}

/** Input for the createActionMonitorAction mutation */
export type CreateActionMonitorActionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createActionMonitorAction mutation */
export type CreateActionMonitorActionPayload = {
  __typename?: 'CreateActionMonitorActionPayload'
  /** The Post object mutation type. */
  actionMonitorAction?: Maybe<ActionMonitorAction>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
}

/** Input for the createCategory mutation */
export type CreateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the category object */
  description?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  /** The name of the category object to mutate */
  name: Scalars['String']['input']
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createCategory mutation */
export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload'
  /** The created category */
  category?: Maybe<Category>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
}

/** Input for the createCertificationExpiryWarning mutation */
export type CreateCertificationExpiryWarningInput = {
  /** The number of days before the expiry date to send the warning. */
  alertWindow?: InputMaybe<Scalars['Int']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The slugs of the companies to include in the expiry warning. */
  companies?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The external contacts to notify. */
  externalContacts?: InputMaybe<Array<InputMaybe<ExternalContactInput>>>
  /** The database IDs of the Users to contact. */
  internalContacts?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

/** The payload for the createCertificationExpiryWarning mutation */
export type CreateCertificationExpiryWarningPayload = {
  __typename?: 'CreateCertificationExpiryWarningPayload'
  /** The created certification expiry warning rule. */
  certificationExpiryWarningRule?: Maybe<CertificationExpiryWarningRule>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
}

/** Input for the createCertification mutation */
export type CreateCertificationInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Completion date in yyyy-mm-dd format. */
  completedDate: Scalars['String']['input']
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Expiration date in yyyy-mm-dd format. */
  expirationDate?: InputMaybe<Scalars['String']['input']>
  /** Wallet Card photos or other attachments to the certification. */
  fileAttachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<Array<InputMaybe<PostToPostConnections>>>
  /** Set connections between the Certification and Providers */
  providers?: InputMaybe<CertificationProvidersInput>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Certification and TrainingTypes */
  trainingTypes?: InputMaybe<CertificationTrainingTypesInput>
}

/** The payload for the createCertification mutation */
export type CreateCertificationPayload = {
  __typename?: 'CreateCertificationPayload'
  /** The Post object mutation type. */
  certification?: Maybe<Certification>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
}

/** Input for the createComment mutation */
export type CreateCommentInput = {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars['String']['input']>
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars['String']['input']>
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars['String']['input']>
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars['Int']['input']>
  /** Content of the comment. */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Type of comment. */
  type?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createComment mutation */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The comment that was created */
  comment?: Maybe<Comment>
  /** Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache */
  success?: Maybe<Scalars['Boolean']['output']>
}

/** Input for the createCompany mutation */
export type CreateCompanyInput = {
  /** The slug that the company will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the company object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the company object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createCompany mutation */
export type CreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created company */
  company?: Maybe<Company>
}

/** Input for the createCorrectiveAction mutation */
export type CreateCorrectiveActionInput = {
  /** Corrective action taken. Which is the deficiency the worker needs to fix. */
  actionTaken?: InputMaybe<Scalars['String']['input']>
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the CorrectiveAction and Companies */
  companies?: InputMaybe<CorrectiveActionCompaniesInput>
  /** DO NOT USE. Need to have so the CS status Enum gets to graphql */
  correctiveActionStatus?: InputMaybe<CorrectiveActionStatusEnum>
  /** Set connections between the CorrectiveAction and CorrectiveActionStatuses */
  correctiveActionStatuses?: InputMaybe<CorrectiveActionCorrectiveActionStatusesInput>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Date completed in yyyy-mm-dd format. */
  dateCompleted?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the CorrectiveAction and DeficiencyTypes */
  deficiencyTypes?: InputMaybe<CorrectiveActionDeficiencyTypesInput>
  /** Set connections between the CorrectiveAction and Divisions */
  divisions?: InputMaybe<CorrectiveActionDivisionsInput>
  /** Due date in yyyy-mm-dd format. */
  dueDate?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the CorrectiveAction and EntryIds */
  entryIds?: InputMaybe<CorrectiveActionEntryIdsInput>
  /** Set connections between the CorrectiveAction and FieldIds */
  fieldIds?: InputMaybe<CorrectiveActionFieldIdsInput>
  /** Set connections between the CorrectiveAction and FormIds */
  formIds?: InputMaybe<CorrectiveActionFormIdsInput>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<Array<InputMaybe<PostToPostConnections>>>
  /** Set connections between the CorrectiveAction and Severities */
  severities?: InputMaybe<CorrectiveActionSeveritiesInput>
  /** DO NOT USE. Need to have so the Enum gets to graphql */
  severity?: InputMaybe<SeverityEnum>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createCorrectiveAction mutation */
export type CreateCorrectiveActionPayload = {
  __typename?: 'CreateCorrectiveActionPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  correctiveAction?: Maybe<CorrectiveAction>
}

/** Input for the createCorrectiveActionPdfs mutation */
export type CreateCorrectiveActionPdfsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** A list of databaseIds for the corrective action to generate pdfs. */
  databaseIds: Array<InputMaybe<Scalars['ID']['input']>>
}

/** The payload for the createCorrectiveActionPdfs mutation */
export type CreateCorrectiveActionPdfsPayload = {
  __typename?: 'CreateCorrectiveActionPdfsPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** A list of generated pdfs. */
  pdfs: Array<Maybe<Pdf>>
}

/** Input for the createCorrectiveActionStatus mutation */
export type CreateCorrectiveActionStatusInput = {
  /** The slug that the corrective_action_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the corrective_action_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the corrective_action_status object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createCorrectiveActionStatus mutation */
export type CreateCorrectiveActionStatusPayload = {
  __typename?: 'CreateCorrectiveActionStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created corrective_action_status */
  correctiveActionStatus?: Maybe<CorrectiveActionStatus>
}

/** Input for the createDeficiencyType mutation */
export type CreateDeficiencyTypeInput = {
  /** The slug that the deficiency_type will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the deficiency_type object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the deficiency_type object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createDeficiencyType mutation */
export type CreateDeficiencyTypePayload = {
  __typename?: 'CreateDeficiencyTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created deficiency_type */
  deficiencyType?: Maybe<DeficiencyType>
}

/** Input for the createDivision mutation */
export type CreateDivisionInput = {
  /** The slug that the division will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the division object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the division object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createDivision mutation */
export type CreateDivisionPayload = {
  __typename?: 'CreateDivisionPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created division */
  division?: Maybe<Division>
}

/** Input for the createDocument mutation */
export type CreateDocumentInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Document and Companies */
  companies?: InputMaybe<DocumentCompaniesInput>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Document and Divisions */
  divisions?: InputMaybe<DocumentDivisionsInput>
  /** Set connections between the Document and DocumentTags */
  documentTags?: InputMaybe<DocumentDocumentTagsInput>
  /** Equipment CPT post_ids */
  equipmentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** The file upload of the new Document */
  fileAttachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<Array<InputMaybe<PostToPostConnections>>>
  /** Project CPT post_ids */
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createDocument mutation */
export type CreateDocumentPayload = {
  __typename?: 'CreateDocumentPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  document?: Maybe<Document>
}

/** Input for the createDocumentTag mutation */
export type CreateDocumentTagInput = {
  /** The slug that the document_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the document_tag object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the document_tag object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createDocumentTag mutation */
export type CreateDocumentTagPayload = {
  __typename?: 'CreateDocumentTagPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created document_tag */
  documentTag?: Maybe<DocumentTag>
}

/** Input for the createEntryId mutation */
export type CreateEntryIdInput = {
  /** The slug that the entry_id will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the entry_id object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the entry_id object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createEntryId mutation */
export type CreateEntryIdPayload = {
  __typename?: 'CreateEntryIdPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created entry_id */
  entryId?: Maybe<EntryId>
}

/** Input for the createEquipment mutation */
export type CreateEquipmentInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Equipment and Companies */
  companies?: InputMaybe<EquipmentCompaniesInput>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Equipment and Divisions */
  divisions?: InputMaybe<EquipmentDivisionsInput>
  /** Assigned document databaseIds */
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** Assigned equipment (truck) databaseIds */
  equipmentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** Set connections between the Equipment and EquipmentStatuses */
  equipmentStatuses?: InputMaybe<EquipmentEquipmentStatusesInput>
  /** Set connections between the Equipment and EquipmentTags */
  equipmentTags?: InputMaybe<EquipmentEquipmentTagsInput>
  /** Any photos of the piece of equipment. */
  fileAttachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
  /** In service date in yyyy-mm-dd format. */
  inServiceDate?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Equipment and InspectionIntervals */
  inspectionIntervals?: InputMaybe<EquipmentInspectionIntervalsInput>
  /** Last inspection date in yyyy-mm-dd format. */
  lastInspectionDate?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Equipment and Manufacturers */
  manufacturers?: InputMaybe<EquipmentManufacturersInput>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** Equipment model number */
  modelNumber?: InputMaybe<Scalars['String']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<Array<InputMaybe<PostToPostConnections>>>
  /** Assigned to project databaseId */
  projectId?: InputMaybe<Scalars['Int']['input']>
  /** Equipment serial number */
  serialNumber?: InputMaybe<Scalars['String']['input']>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** Assigned to worker databaseId */
  userId?: InputMaybe<Scalars['Int']['input']>
}

/** The payload for the createEquipment mutation */
export type CreateEquipmentPayload = {
  __typename?: 'CreateEquipmentPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  equipment?: Maybe<Equipment>
}

/** Input for the createEquipmentStatus mutation */
export type CreateEquipmentStatusInput = {
  /** The slug that the equipment_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the equipment_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the equipment_status object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createEquipmentStatus mutation */
export type CreateEquipmentStatusPayload = {
  __typename?: 'CreateEquipmentStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created equipment_status */
  equipmentStatus?: Maybe<EquipmentStatus>
}

/** Input for the createEquipmentTag mutation */
export type CreateEquipmentTagInput = {
  /** The slug that the equipment_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the equipment_tag object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the equipment_tag object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createEquipmentTag mutation */
export type CreateEquipmentTagPayload = {
  __typename?: 'CreateEquipmentTagPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created equipment_tag */
  equipmentTag?: Maybe<EquipmentTag>
}

/** Input for the createFieldId mutation */
export type CreateFieldIdInput = {
  /** The slug that the field_id will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the field_id object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the field_id object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createFieldId mutation */
export type CreateFieldIdPayload = {
  __typename?: 'CreateFieldIdPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created field_id */
  fieldId?: Maybe<FieldId>
}

/** Input for the createFormId mutation */
export type CreateFormIdInput = {
  /** The slug that the form_id will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the form_id object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the form_id object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createFormId mutation */
export type CreateFormIdPayload = {
  __typename?: 'CreateFormIdPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created form_id */
  formId?: Maybe<FormId>
}

/** Input for the createIncidentReportClassification mutation */
export type CreateIncidentReportClassificationInput = {
  /** The slug that the ir_classification will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the ir_classification object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the ir_classification object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createIncidentReportClassification mutation */
export type CreateIncidentReportClassificationPayload = {
  __typename?: 'CreateIncidentReportClassificationPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created ir_classification */
  incidentReportClassification?: Maybe<IncidentReportClassification>
}

/** Input for the createIncidentReport mutation */
export type CreateIncidentReportInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the IncidentReport and Companies */
  companies?: InputMaybe<IncidentReportCompaniesInput>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Days away from work */
  daysAwayWork?: InputMaybe<Scalars['Int']['input']>
  /** Days on Job Transfer / restriction */
  daysJobTransfer?: InputMaybe<Scalars['Int']['input']>
  /** (Initial Report) - Incident Report description */
  description?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the IncidentReport and Divisions */
  divisions?: InputMaybe<IncidentReportDivisionsInput>
  /** Employee Address */
  employeeAddress?: InputMaybe<Scalars['String']['input']>
  /** Date of Birth */
  employeeBirthDate?: InputMaybe<Scalars['String']['input']>
  /** Date of Death */
  employeeDeathDate?: InputMaybe<Scalars['String']['input']>
  /** Employee Gender */
  employeeGender?: InputMaybe<Scalars['String']['input']>
  /** Date Hired */
  employeeHireDate?: InputMaybe<Scalars['String']['input']>
  /** Worker involved job title */
  employeeJobTitle?: InputMaybe<Scalars['String']['input']>
  /** Time Employee began work on day of Incident */
  employeeStartTime?: InputMaybe<Scalars['String']['input']>
  /** Media library stored items attached to this CPT */
  fileAttachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
  /** Did the worker receive emergency treatment */
  hasEmergencyTreatment?: InputMaybe<Scalars['Boolean']['input']>
  /** Did the worker stay in the hospital overnight */
  hasHospitalOvernight?: InputMaybe<Scalars['Boolean']['input']>
  /** Description of the hazard */
  hazardDescription?: InputMaybe<Scalars['String']['input']>
  /** Address of the hospital */
  hospitalAddress?: InputMaybe<Scalars['String']['input']>
  /** Name of the hospital */
  hospitalName?: InputMaybe<Scalars['String']['input']>
  /** (Initial Report) - Date Incident happened */
  incidentDate?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the IncidentReport and IncidentReportClassifications */
  incidentReportClassifications?: InputMaybe<IncidentReportIncidentReportClassificationsInput>
  /** Set connections between the IncidentReport and IncidentReportStatuses */
  incidentReportStatuses?: InputMaybe<IncidentReportIncidentReportStatusesInput>
  /** Time of Incident */
  incidentTime?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the IncidentReport and IncidentTypes */
  incidentTypes?: InputMaybe<IncidentReportIncidentTypesInput>
  /** Set connections between the IncidentReport and InjuryAreas */
  injuryAreas?: InputMaybe<IncidentReportInjuryAreasInput>
  /** Cause of the injury */
  injuryCause?: InputMaybe<Scalars['String']['input']>
  /** Description of the injury */
  injuryDescription?: InputMaybe<Scalars['String']['input']>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Name of the physician */
  physicianName?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<Array<InputMaybe<PostToPostConnections>>>
  /** Prior actions taken before the incident */
  priorActions?: InputMaybe<Scalars['String']['input']>
  /** Project Address or Non project location */
  projectAddress?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the IncidentReport and RootCauses */
  rootCauses?: InputMaybe<IncidentReportRootCausesInput>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** The witnesses to the incident */
  witnesses?: InputMaybe<Array<InputMaybe<IncidentReportWitnessInput>>>
}

/** The payload for the createIncidentReport mutation */
export type CreateIncidentReportPayload = {
  __typename?: 'CreateIncidentReportPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  incidentReport?: Maybe<IncidentReport>
}

/** Input for the createIncidentReportStatus mutation */
export type CreateIncidentReportStatusInput = {
  /** The slug that the ir_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the ir_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the ir_status object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createIncidentReportStatus mutation */
export type CreateIncidentReportStatusPayload = {
  __typename?: 'CreateIncidentReportStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created ir_status */
  incidentReportStatus?: Maybe<IncidentReportStatus>
}

/** Input for the createIncidentType mutation */
export type CreateIncidentTypeInput = {
  /** The slug that the ir_type will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the ir_type object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the ir_type object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createIncidentType mutation */
export type CreateIncidentTypePayload = {
  __typename?: 'CreateIncidentTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created ir_type */
  incidentType?: Maybe<IncidentType>
}

/** Input for the createInjuryArea mutation */
export type CreateInjuryAreaInput = {
  /** The slug that the ir_injury_area will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the ir_injury_area object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the ir_injury_area object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createInjuryArea mutation */
export type CreateInjuryAreaPayload = {
  __typename?: 'CreateInjuryAreaPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created ir_injury_area */
  injuryArea?: Maybe<InjuryArea>
}

/** Input for the createInspectionInterval mutation */
export type CreateInspectionIntervalInput = {
  /** The slug that the inspection_interval will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the inspection_interval object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the inspection_interval object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createInspectionInterval mutation */
export type CreateInspectionIntervalPayload = {
  __typename?: 'CreateInspectionIntervalPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created inspection_interval */
  inspectionInterval?: Maybe<InspectionInterval>
}

/** Input for the createLessonCategory mutation */
export type CreateLessonCategoryInput = {
  /** The slug that the lesson_categories will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the lesson_categories object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the lesson_categories object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createLessonCategory mutation */
export type CreateLessonCategoryPayload = {
  __typename?: 'CreateLessonCategoryPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created lesson_categories */
  lessonCategory?: Maybe<LessonCategory>
}

/** Input for the createLessonDifficulty mutation */
export type CreateLessonDifficultyInput = {
  /** The slug that the lesson_difficulty will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the lesson_difficulty object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the lesson_difficulty object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createLessonDifficulty mutation */
export type CreateLessonDifficultyPayload = {
  __typename?: 'CreateLessonDifficultyPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created lesson_difficulty */
  lessonDifficulty?: Maybe<LessonDifficulty>
}

/** Input for the createLesson mutation */
export type CreateLessonInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Lesson and Companies */
  companies?: InputMaybe<LessonCompaniesInput>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Lesson and Divisions */
  divisions?: InputMaybe<LessonDivisionsInput>
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Lesson and lessonCategories */
  lessonCategories?: InputMaybe<LessonLessonCategoriesInput>
  /** Set connections between the Lesson and lessonDifficulties */
  lessonDifficulties?: InputMaybe<LessonLessonDifficultiesInput>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<Array<InputMaybe<PostToPostConnections>>>
  /** Set connections between the Lesson and Providers */
  providers?: InputMaybe<LessonProvidersInput>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Lesson and TrainingTypes */
  trainingTypes?: InputMaybe<LessonTrainingTypesInput>
}

/** The payload for the createLesson mutation */
export type CreateLessonPayload = {
  __typename?: 'CreateLessonPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  lesson?: Maybe<Lesson>
}

/** Input for the createManufacturer mutation */
export type CreateManufacturerInput = {
  /** The slug that the manufacturer will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the manufacturer object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the manufacturer object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createManufacturer mutation */
export type CreateManufacturerPayload = {
  __typename?: 'CreateManufacturerPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created manufacturer */
  manufacturer?: Maybe<Manufacturer>
}

/** Input for the createMediaItem mutation */
export type CreateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars['String']['input']>
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars['String']['input']>
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars['String']['input']>
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars['String']['input']>
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars['String']['input']>
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars['String']['input']>
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>
  language?: InputMaybe<LanguageCodeEnum>
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars['String']['input']>
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createMediaItem mutation */
export type CreateMediaItemPayload = {
  __typename?: 'CreateMediaItemPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The MediaItem object mutation type. */
  mediaItem?: Maybe<MediaItem>
}

/** Input for the createPage mutation */
export type CreatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']['input']>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createPage mutation */
export type CreatePagePayload = {
  __typename?: 'CreatePagePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  page?: Maybe<Page>
}

/** Input for the createPhoto mutation */
export type CreatePhotoInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Photo and EntryIds */
  entryIds?: InputMaybe<PhotoEntryIdsInput>
  /** Set connections between the Photo and FieldIds */
  fieldIds?: InputMaybe<PhotoFieldIdsInput>
  /** Set connections between the Photo and FormIds */
  formIds?: InputMaybe<PhotoFormIdsInput>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<Array<InputMaybe<PostToPostConnections>>>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createPhoto mutation */
export type CreatePhotoPayload = {
  __typename?: 'CreatePhotoPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  photo?: Maybe<Photo>
}

/** Input for the createPostFormat mutation */
export type CreatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the post_format object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the post_format object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createPostFormat mutation */
export type CreatePostFormatPayload = {
  __typename?: 'CreatePostFormatPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created post_format */
  postFormat?: Maybe<PostFormat>
}

/** Input for the createPost mutation */
export type CreatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']['input']>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars['String']['input']>
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** The payload for the createPost mutation */
export type CreatePostPayload = {
  __typename?: 'CreatePostPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  post?: Maybe<Post>
}

/** Input for the createProject mutation */
export type CreateProjectInput = {
  /** Street address */
  address?: InputMaybe<Scalars['String']['input']>
  /** Address, line two */
  address2?: InputMaybe<Scalars['String']['input']>
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** City */
  city?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Project and Companies */
  companies?: InputMaybe<ProjectCompaniesInput>
  /** Country */
  country?: InputMaybe<Scalars['String']['input']>
  /** Name of the customer */
  customer?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Project and Divisions */
  divisions?: InputMaybe<ProjectDivisionsInput>
  /** End date in yyyy-mm-dd format. */
  endDate?: InputMaybe<Scalars['String']['input']>
  /** Hospital Street address */
  hospitalAddress?: InputMaybe<Scalars['String']['input']>
  /** Hospital City */
  hospitalCity?: InputMaybe<Scalars['String']['input']>
  /** Hospital Name */
  hospitalName?: InputMaybe<Scalars['String']['input']>
  /** Hospital State */
  hospitalState?: InputMaybe<Scalars['String']['input']>
  /** Hospital ZIP code */
  hospitalZip?: InputMaybe<Scalars['String']['input']>
  /** Key personnel user IDs */
  keyPersonnel?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** Project number */
  number?: InputMaybe<Scalars['String']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<Array<InputMaybe<PostToPostConnections>>>
  /** Set connections between the Project and ProjectStatuses */
  projectStatuses?: InputMaybe<ProjectProjectStatusesInput>
  /** Set connections between the Project and ProjectTypes */
  projectTypes?: InputMaybe<ProjectProjectTypesInput>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** Start date in yyyy-mm-dd format. */
  startDate?: InputMaybe<Scalars['String']['input']>
  /** State or province */
  state?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** ZIP code / postal code */
  zip?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createProject mutation */
export type CreateProjectPayload = {
  __typename?: 'CreateProjectPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  project?: Maybe<Project>
}

/** Input for the createProjectStatus mutation */
export type CreateProjectStatusInput = {
  /** The slug that the project_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the project_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the project_status object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createProjectStatus mutation */
export type CreateProjectStatusPayload = {
  __typename?: 'CreateProjectStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created project_status */
  projectStatus?: Maybe<ProjectStatus>
}

/** Input for the createProjectType mutation */
export type CreateProjectTypeInput = {
  /** The slug that the project_type will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the project_type object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the project_type object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createProjectType mutation */
export type CreateProjectTypePayload = {
  __typename?: 'CreateProjectTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created project_type */
  projectType?: Maybe<ProjectType>
}

/** Input for the createProvider mutation */
export type CreateProviderInput = {
  /** The slug that the provider_tax will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the provider_tax object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the provider_tax object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createProvider mutation */
export type CreateProviderPayload = {
  __typename?: 'CreateProviderPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created provider_tax */
  provider?: Maybe<Provider>
}

/** Input for the createRootCause mutation */
export type CreateRootCauseInput = {
  /** The slug that the ir_root_cause will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the ir_root_cause object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the ir_root_cause object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createRootCause mutation */
export type CreateRootCausePayload = {
  __typename?: 'CreateRootCausePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created ir_root_cause */
  rootCause?: Maybe<RootCause>
}

/** Input for the createSeverity mutation */
export type CreateSeverityInput = {
  /** The slug that the severity will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the severity object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the severity object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createSeverity mutation */
export type CreateSeverityPayload = {
  __typename?: 'CreateSeverityPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created severity */
  severity?: Maybe<Severity>
}

/** Input for the createTag mutation */
export type CreateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  /** The name of the post_tag object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createTag mutation */
export type CreateTagPayload = {
  __typename?: 'CreateTagPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created post_tag */
  tag?: Maybe<Tag>
}

/** Input for the createToolboxTalkCategory mutation */
export type CreateToolboxTalkCategoryInput = {
  /** The slug that the toolbox_talk_category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the toolbox_talk_category object */
  description?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  /** The name of the toolbox_talk_category object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createToolboxTalkCategory mutation */
export type CreateToolboxTalkCategoryPayload = {
  __typename?: 'CreateToolboxTalkCategoryPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created toolbox_talk_category */
  toolboxTalkCategory?: Maybe<ToolboxTalkCategory>
}

/** Input for the createToolboxTalk mutation */
export type CreateToolboxTalkInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the ToolboxTalk and Companies */
  companies?: InputMaybe<ToolboxTalkCompaniesInput>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the ToolboxTalk and Divisions */
  divisions?: InputMaybe<ToolboxTalkDivisionsInput>
  /** The file uploads for the Toolbox Talk */
  fileAttachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
  language?: InputMaybe<LanguageCodeEnum>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the ToolboxTalk and ToolboxTalkCategories */
  toolboxTalkCategories?: InputMaybe<ToolboxTalkToolboxTalkCategoriesInput>
  /** An array of Toolbox Talk Post DatabaseIds to connect a language group with the created / updated post */
  translationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

/** The payload for the createToolboxTalk mutation */
export type CreateToolboxTalkPayload = {
  __typename?: 'CreateToolboxTalkPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  toolboxTalk?: Maybe<ToolboxTalk>
}

/** Input for the createTrainingType mutation */
export type CreateTrainingTypeInput = {
  /** The slug that the training_type will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the training_type object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the training_type object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createTrainingType mutation */
export type CreateTrainingTypePayload = {
  __typename?: 'CreateTrainingTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created training_type */
  trainingType?: Maybe<TrainingType>
}

/** Input for the createUserCompany mutation */
export type CreateUserCompanyInput = {
  /** The slug that the user_company will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the user_company object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the user_company object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createUserCompany mutation */
export type CreateUserCompanyPayload = {
  __typename?: 'CreateUserCompanyPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created user_company */
  userCompany?: Maybe<UserCompany>
}

/** Input for the createUserDivision mutation */
export type CreateUserDivisionInput = {
  /** The slug that the user_division will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the user_division object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the user_division object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createUserDivision mutation */
export type CreateUserDivisionPayload = {
  __typename?: 'CreateUserDivisionPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created user_division */
  userDivision?: Maybe<UserDivision>
}

/** Input for the createUser mutation */
export type CreateUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Companies taxonomy. Will accept the name or slug of the taxonomy. */
  companies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']['input']>
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']['input']>
  /** Divisions Taxonomy. Will accept the name or slug of the taxonomy. */
  divisions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']['input']>
  emergencyContactName?: InputMaybe<Scalars['String']['input']>
  emergencyContactNumber?: InputMaybe<Scalars['String']['input']>
  emergencyContactRelationship?: InputMaybe<Scalars['String']['input']>
  /** Employee ID number. */
  employeeId?: InputMaybe<Scalars['String']['input']>
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']['input']>
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']['input']>
  /** User's preferred language. */
  language?: InputMaybe<UserLanguageEnum>
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']['input']>
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']['input']>
  loginCountIncrease?: InputMaybe<Scalars['Boolean']['input']>
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']['input']>
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']['input']>
  /** Offline Mode Setting. When active, upon user login the frontend application will sync the selected data to be saved on the user's device. */
  offlineMode?: InputMaybe<Scalars['Boolean']['input']>
  /** List of strings which represent the data collections to save to the users's device when offlineMode is active. */
  offlineModeData?: InputMaybe<
    Array<InputMaybe<OfflineModeDataCollectionNameEnum>>
  >
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<Array<InputMaybe<PostToPostConnections>>>
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']['input']>
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']['input']>
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sdsDownloadCountIncrease?: InputMaybe<Scalars['Boolean']['input']>
  /** Status. */
  status?: InputMaybe<UserStatusEnum>
  /** A string that contains the user's username for logging in. */
  username: Scalars['String']['input']
  /** Worker Vaccine Status. */
  vaccineStatus?: InputMaybe<UserVaccineStatusEnum>
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']['input']>
}

/** Input for the createUserLanguage mutation */
export type CreateUserLanguageInput = {
  /** The slug that the userLanguage will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the userLanguage object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the userLanguage object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createUserLanguage mutation */
export type CreateUserLanguagePayload = {
  __typename?: 'CreateUserLanguagePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created userLanguage */
  userLanguage?: Maybe<UserLanguage>
}

/** The payload for the createUser mutation */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The User object mutation type. */
  user?: Maybe<User>
}

/** Input for the createUserStatus mutation */
export type CreateUserStatusInput = {
  /** The slug that the user_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the user_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the user_status object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createUserStatus mutation */
export type CreateUserStatusPayload = {
  __typename?: 'CreateUserStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created user_status */
  userStatus?: Maybe<UserStatus>
}

/** Input for the createUserVaccineStatus mutation */
export type CreateUserVaccineStatusInput = {
  /** The slug that the user_vaccine_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the user_vaccine_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the user_vaccine_status object to mutate */
  name: Scalars['String']['input']
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the createUserVaccineStatus mutation */
export type CreateUserVaccineStatusPayload = {
  __typename?: 'CreateUserVaccineStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created user_vaccine_status */
  userVaccineStatus?: Maybe<UserVaccineStatus>
}

/** Object that can be identified with a Database ID */
export type DatabaseIdentifier = {
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output']
}

/** A Gravity Forms   field. */
export type DateField = FormField & {
  __typename?: 'DateField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines how the date field displays it’s calendar icon. */
  calendarIconType?: Maybe<FormFieldCalendarIconTypeEnum>
  /** Contains the URL to the custom calendar icon. Only applicable when calendarIconType is set to custom. */
  calendarIconUrl?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Determines how the date is displayed. */
  dateFormat?: Maybe<DateFieldFormatEnum>
  /** The type of date field to display. */
  dateType?: Maybe<DateFieldTypeEnum>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** An array of the available properties for each input of the date field. */
  inputs?: Maybe<Array<Maybe<DateInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** How the DateField date is displayed. */
export enum DateFieldFormatEnum {
  /** dd/mm/yyyy format. */
  Dmy = 'DMY',
  /** dd-mm-yyyy format. */
  DmyDash = 'DMY_DASH',
  /** dd.mm.yyyy format. */
  DmyDot = 'DMY_DOT',
  /** mm/dd/yyyy format. */
  Mdy = 'MDY',
  /** yyyy/mm/dd format. */
  YmdDash = 'YMD_DASH',
  /** yyyy.mm.dd format. */
  YmdDot = 'YMD_DOT',
  /** yyyy/mm/dd format. */
  YmdSlash = 'YMD_SLASH',
}

/** Type of date field to display. */
export enum DateFieldTypeEnum {
  /** A date dropdown. */
  Dropdown = 'DROPDOWN',
  /** A simple date field. */
  Field = 'FIELD',
  /** A datepicker. */
  Picker = 'PICKER',
}

/** Date values */
export type DateInput = {
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars['Int']['input']>
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars['Int']['input']>
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars['Int']['input']>
}

/** Date input values. */
export type DateInputProperty = {
  __typename?: 'DateInputProperty'
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** Filter the connection based on input */
export type DateQueryInput = {
  /** Nodes should be returned after this date */
  after?: InputMaybe<DateInput>
  /** Nodes should be returned before this date */
  before?: InputMaybe<DateInput>
  /** Column to query against */
  column?: InputMaybe<PostObjectsConnectionDateColumnEnum>
  /** For after/before, whether exact value should be matched or not */
  compare?: InputMaybe<Scalars['String']['input']>
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars['Int']['input']>
  /** Hour (from 0 to 23) */
  hour?: InputMaybe<Scalars['Int']['input']>
  /** For after/before, whether exact value should be matched or not */
  inclusive?: InputMaybe<Scalars['Boolean']['input']>
  /** Minute (from 0 to 59) */
  minute?: InputMaybe<Scalars['Int']['input']>
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars['Int']['input']>
  /** OR or AND, how the sub-arrays should be compared */
  relation?: InputMaybe<RelationEnum>
  /** Second (0 to 59) */
  second?: InputMaybe<Scalars['Int']['input']>
  /** Week of the year (from 0 to 53) */
  week?: InputMaybe<Scalars['Int']['input']>
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars['Int']['input']>
}

/** The template assigned to the node */
export type DefaultTemplate = ContentTemplate & {
  __typename?: 'DefaultTemplate'
  /** The name of the template */
  templateName?: Maybe<Scalars['String']['output']>
}

/** The DeficiencyType type */
export type DeficiencyType = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'DeficiencyType'
    /** Connection between the DeficiencyType type and the ContentNode type */
    contentNodes?: Maybe<DeficiencyTypeToContentNodeConnection>
    /** Connection between the DeficiencyType type and the CorrectiveAction type */
    correctiveActions?: Maybe<DeficiencyTypeToCorrectiveActionConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    deficiencyTypeId?: Maybe<Scalars['Int']['output']>
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the DeficiencyType type and the Taxonomy type */
    taxonomy?: Maybe<DeficiencyTypeToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The DeficiencyType type */
export type DeficiencyTypeContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DeficiencyTypeToContentNodeConnectionWhereArgs>
}

/** The DeficiencyType type */
export type DeficiencyTypeCorrectiveActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DeficiencyTypeToCorrectiveActionConnectionWhereArgs>
}

/** The DeficiencyType type */
export type DeficiencyTypeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The DeficiencyType type */
export type DeficiencyTypeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum DeficiencyTypeIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the DeficiencyType type and the ContentNode type */
export type DeficiencyTypeToContentNodeConnection = {
  __typename?: 'DeficiencyTypeToContentNodeConnection'
  /** Edges for the DeficiencyTypeToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<DeficiencyTypeToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DeficiencyTypeToContentNodeConnectionEdge = {
  __typename?: 'DeficiencyTypeToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the DeficiencyTypeToContentNodeConnection connection */
export type DeficiencyTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfDeficiencyTypeEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DeficiencyTypeToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DeficiencyTypeToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DeficiencyTypeToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DeficiencyTypeToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DeficiencyTypeToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DeficiencyTypeToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DeficiencyTypeToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<DeficiencyTypeToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DeficiencyTypeToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DeficiencyTypeToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DeficiencyTypeToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DeficiencyTypeToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DeficiencyTypeToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<DeficiencyTypeToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DeficiencyTypeToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DeficiencyTypeToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the DeficiencyType type and the CorrectiveAction type */
export type DeficiencyTypeToCorrectiveActionConnection = {
  __typename?: 'DeficiencyTypeToCorrectiveActionConnection'
  /** Edges for the DeficiencyTypeToCorrectiveActionConnection connection */
  edges?: Maybe<Array<Maybe<DeficiencyTypeToCorrectiveActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DeficiencyTypeToCorrectiveActionConnectionEdge = {
  __typename?: 'DeficiencyTypeToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Arguments for filtering the DeficiencyTypeToCorrectiveActionConnection connection */
export type DeficiencyTypeToCorrectiveActionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DeficiencyTypeToCorrectiveActionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DeficiencyTypeToCorrectiveActionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DeficiencyTypeToCorrectiveActionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DeficiencyTypeToCorrectiveActionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DeficiencyTypeToCorrectiveActionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DeficiencyTypeToCorrectiveActionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DeficiencyTypeToCorrectiveActionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<
      InputMaybe<DeficiencyTypeToCorrectiveActionConnectionWhereArgsMetaArray>
    >
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DeficiencyTypeToCorrectiveActionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DeficiencyTypeToCorrectiveActionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DeficiencyTypeToCorrectiveActionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DeficiencyTypeToCorrectiveActionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DeficiencyTypeToCorrectiveActionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<
      InputMaybe<DeficiencyTypeToCorrectiveActionConnectionWhereArgsTaxArray>
    >
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DeficiencyTypeToCorrectiveActionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DeficiencyTypeToCorrectiveActionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the DeficiencyType type and the Taxonomy type */
export type DeficiencyTypeToTaxonomyConnectionEdge = {
  __typename?: 'DeficiencyTypeToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Input for the deleteActionMonitorAction mutation */
export type DeleteActionMonitorActionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the ActionMonitorAction to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteActionMonitorAction mutation */
export type DeleteActionMonitorActionPayload = {
  __typename?: 'DeleteActionMonitorActionPayload'
  /** The object before it was deleted */
  actionMonitorAction?: Maybe<ActionMonitorAction>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
}

/** Input for the deleteCategory mutation */
export type DeleteCategoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the category to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteCategory mutation */
export type DeleteCategoryPayload = {
  __typename?: 'DeleteCategoryPayload'
  /** The deteted term object */
  category?: Maybe<Category>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
}

/** Input for the deleteCertificationExpiryWarning mutation */
export type DeleteCertificationExpiryWarningInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The database ID of the certification expiry warning rule to update. */
  id: Scalars['ID']['input']
}

/** The payload for the deleteCertificationExpiryWarning mutation */
export type DeleteCertificationExpiryWarningPayload = {
  __typename?: 'DeleteCertificationExpiryWarningPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created certification expiry warning rule. */
  deletedRule?: Maybe<CertificationExpiryWarningRule>
  /** Whether the deletion was successful. */
  success?: Maybe<Scalars['Boolean']['output']>
}

/** Input for the deleteCertification mutation */
export type DeleteCertificationInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the Certification to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteCertification mutation */
export type DeleteCertificationPayload = {
  __typename?: 'DeleteCertificationPayload'
  /** The object before it was deleted */
  certification?: Maybe<Certification>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
}

/** Input for the deleteComment mutation */
export type DeleteCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the comment should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The deleted comment ID */
  id: Scalars['ID']['input']
}

/** The payload for the deleteComment mutation */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The deleted comment object */
  comment?: Maybe<Comment>
  /** The deleted comment ID */
  deletedId?: Maybe<Scalars['ID']['output']>
}

/** Input for the deleteCompany mutation */
export type DeleteCompanyInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Company to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteCompany mutation */
export type DeleteCompanyPayload = {
  __typename?: 'DeleteCompanyPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The deteted term object */
  company?: Maybe<Company>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
}

/** Input for the deleteCorrectiveAction mutation */
export type DeleteCorrectiveActionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the CorrectiveAction to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteCorrectiveAction mutation */
export type DeleteCorrectiveActionPayload = {
  __typename?: 'DeleteCorrectiveActionPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The object before it was deleted */
  correctiveAction?: Maybe<CorrectiveAction>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
}

/** Input for the deleteCorrectiveActionStatus mutation */
export type DeleteCorrectiveActionStatusInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the CorrectiveActionStatus to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteCorrectiveActionStatus mutation */
export type DeleteCorrectiveActionStatusPayload = {
  __typename?: 'DeleteCorrectiveActionStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The deteted term object */
  correctiveActionStatus?: Maybe<CorrectiveActionStatus>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
}

/** Input for the deleteDeficiencyType mutation */
export type DeleteDeficiencyTypeInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the DeficiencyType to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteDeficiencyType mutation */
export type DeleteDeficiencyTypePayload = {
  __typename?: 'DeleteDeficiencyTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The deteted term object */
  deficiencyType?: Maybe<DeficiencyType>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
}

/** Input for the deleteDivision mutation */
export type DeleteDivisionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Division to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteDivision mutation */
export type DeleteDivisionPayload = {
  __typename?: 'DeleteDivisionPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  division?: Maybe<Division>
}

/** Input for the deleteDocument mutation */
export type DeleteDocumentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the Document to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteDocument mutation */
export type DeleteDocumentPayload = {
  __typename?: 'DeleteDocumentPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The object before it was deleted */
  document?: Maybe<Document>
}

/** Input for the deleteDocumentTag mutation */
export type DeleteDocumentTagInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the DocumentTag to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteDocumentTag mutation */
export type DeleteDocumentTagPayload = {
  __typename?: 'DeleteDocumentTagPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  documentTag?: Maybe<DocumentTag>
}

/** Input for the deleteEntryId mutation */
export type DeleteEntryIdInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the EntryId to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteEntryId mutation */
export type DeleteEntryIdPayload = {
  __typename?: 'DeleteEntryIdPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  entryId?: Maybe<EntryId>
}

/** Input for the deleteEquipment mutation */
export type DeleteEquipmentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the Equipment to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteEquipment mutation */
export type DeleteEquipmentPayload = {
  __typename?: 'DeleteEquipmentPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The object before it was deleted */
  equipment?: Maybe<Equipment>
}

/** Input for the deleteEquipmentStatus mutation */
export type DeleteEquipmentStatusInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the EquipmentStatus to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteEquipmentStatus mutation */
export type DeleteEquipmentStatusPayload = {
  __typename?: 'DeleteEquipmentStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  equipmentStatus?: Maybe<EquipmentStatus>
}

/** Input for the deleteEquipmentTag mutation */
export type DeleteEquipmentTagInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the EquipmentTag to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteEquipmentTag mutation */
export type DeleteEquipmentTagPayload = {
  __typename?: 'DeleteEquipmentTagPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  equipmentTag?: Maybe<EquipmentTag>
}

/** Input for the deleteFieldId mutation */
export type DeleteFieldIdInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the FieldId to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteFieldId mutation */
export type DeleteFieldIdPayload = {
  __typename?: 'DeleteFieldIdPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  fieldId?: Maybe<FieldId>
}

/** Input for the deleteFormId mutation */
export type DeleteFormIdInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the FormId to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteFormId mutation */
export type DeleteFormIdPayload = {
  __typename?: 'DeleteFormIdPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  formId?: Maybe<FormId>
}

/** Input for the deleteGfDraftEntry mutation */
export type DeleteGfDraftEntryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Either the global ID of the draft entry, or its resume token. */
  id: Scalars['ID']['input']
  /** The ID type for the draft entry. Defaults to `ID` . */
  idType?: InputMaybe<DraftEntryIdTypeEnum>
}

/** The payload for the deleteGfDraftEntry mutation */
export type DeleteGfDraftEntryPayload = {
  __typename?: 'DeleteGfDraftEntryPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The global ID of the draft entry that was deleted. */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The draft entry object before it was deleted. */
  draftEntry?: Maybe<GfDraftEntry>
}

/** Input for the deleteGfEntry mutation */
export type DeleteGfEntryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the entry should be force deleted instead of being moved to the trash. */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** ID of the entry to delete, either a global or database ID. */
  id: Scalars['ID']['input']
}

/** The payload for the deleteGfEntry mutation */
export type DeleteGfEntryPayload = {
  __typename?: 'DeleteGfEntryPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The global ID of the draft entry that was deleted. */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The entry object before it was deleted. */
  entry?: Maybe<GfSubmittedEntry>
}

/** Input for the deleteIncidentReportClassification mutation */
export type DeleteIncidentReportClassificationInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the IncidentReportClassification to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteIncidentReportClassification mutation */
export type DeleteIncidentReportClassificationPayload = {
  __typename?: 'DeleteIncidentReportClassificationPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  incidentReportClassification?: Maybe<IncidentReportClassification>
}

/** Input for the deleteIncidentReport mutation */
export type DeleteIncidentReportInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the IncidentReport to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteIncidentReport mutation */
export type DeleteIncidentReportPayload = {
  __typename?: 'DeleteIncidentReportPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The object before it was deleted */
  incidentReport?: Maybe<IncidentReport>
}

/** Input for the deleteIncidentReportStatus mutation */
export type DeleteIncidentReportStatusInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the IncidentReportStatus to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteIncidentReportStatus mutation */
export type DeleteIncidentReportStatusPayload = {
  __typename?: 'DeleteIncidentReportStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  incidentReportStatus?: Maybe<IncidentReportStatus>
}

/** Input for the deleteIncidentType mutation */
export type DeleteIncidentTypeInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the IncidentType to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteIncidentType mutation */
export type DeleteIncidentTypePayload = {
  __typename?: 'DeleteIncidentTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  incidentType?: Maybe<IncidentType>
}

/** Input for the deleteInjuryArea mutation */
export type DeleteInjuryAreaInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the InjuryArea to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteInjuryArea mutation */
export type DeleteInjuryAreaPayload = {
  __typename?: 'DeleteInjuryAreaPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  injuryArea?: Maybe<InjuryArea>
}

/** Input for the deleteInspectionInterval mutation */
export type DeleteInspectionIntervalInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the InspectionInterval to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteInspectionInterval mutation */
export type DeleteInspectionIntervalPayload = {
  __typename?: 'DeleteInspectionIntervalPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  inspectionInterval?: Maybe<InspectionInterval>
}

/** Input for the deleteLessonCategory mutation */
export type DeleteLessonCategoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the lessonCategory to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteLessonCategory mutation */
export type DeleteLessonCategoryPayload = {
  __typename?: 'DeleteLessonCategoryPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  lessonCategory?: Maybe<LessonCategory>
}

/** Input for the deleteLessonDifficulty mutation */
export type DeleteLessonDifficultyInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the lessonDifficulty to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteLessonDifficulty mutation */
export type DeleteLessonDifficultyPayload = {
  __typename?: 'DeleteLessonDifficultyPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  lessonDifficulty?: Maybe<LessonDifficulty>
}

/** Input for the deleteLesson mutation */
export type DeleteLessonInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the Lesson to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteLesson mutation */
export type DeleteLessonPayload = {
  __typename?: 'DeleteLessonPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The object before it was deleted */
  lesson?: Maybe<Lesson>
}

/** Input for the deleteManufacturer mutation */
export type DeleteManufacturerInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Manufacturer to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteManufacturer mutation */
export type DeleteManufacturerPayload = {
  __typename?: 'DeleteManufacturerPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  manufacturer?: Maybe<Manufacturer>
}

/** Input for the deleteMediaItem mutation */
export type DeleteMediaItemInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the mediaItem should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the mediaItem to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteMediaItem mutation */
export type DeleteMediaItemPayload = {
  __typename?: 'DeleteMediaItemPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted mediaItem */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The mediaItem before it was deleted */
  mediaItem?: Maybe<MediaItem>
}

/** Input for the deletePage mutation */
export type DeletePageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the page to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deletePage mutation */
export type DeletePagePayload = {
  __typename?: 'DeletePagePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The object before it was deleted */
  page?: Maybe<Page>
}

/** Input for the deletePhoto mutation */
export type DeletePhotoInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the Photo to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deletePhoto mutation */
export type DeletePhotoPayload = {
  __typename?: 'DeletePhotoPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The object before it was deleted */
  photo?: Maybe<Photo>
}

/** Input for the deletePostFormat mutation */
export type DeletePostFormatInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the postFormat to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deletePostFormat mutation */
export type DeletePostFormatPayload = {
  __typename?: 'DeletePostFormatPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  postFormat?: Maybe<PostFormat>
}

/** Input for the deletePost mutation */
export type DeletePostInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the post to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deletePost mutation */
export type DeletePostPayload = {
  __typename?: 'DeletePostPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The object before it was deleted */
  post?: Maybe<Post>
}

/** Input for the deleteProject mutation */
export type DeleteProjectInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the Project to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteProject mutation */
export type DeleteProjectPayload = {
  __typename?: 'DeleteProjectPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The object before it was deleted */
  project?: Maybe<Project>
}

/** Input for the deleteProjectStatus mutation */
export type DeleteProjectStatusInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the ProjectStatus to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteProjectStatus mutation */
export type DeleteProjectStatusPayload = {
  __typename?: 'DeleteProjectStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  projectStatus?: Maybe<ProjectStatus>
}

/** Input for the deleteProjectType mutation */
export type DeleteProjectTypeInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the ProjectType to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteProjectType mutation */
export type DeleteProjectTypePayload = {
  __typename?: 'DeleteProjectTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  projectType?: Maybe<ProjectType>
}

/** Input for the deleteProvider mutation */
export type DeleteProviderInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Provider to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteProvider mutation */
export type DeleteProviderPayload = {
  __typename?: 'DeleteProviderPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  provider?: Maybe<Provider>
}

/** Input for the deleteRootCause mutation */
export type DeleteRootCauseInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the RootCause to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteRootCause mutation */
export type DeleteRootCausePayload = {
  __typename?: 'DeleteRootCausePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  rootCause?: Maybe<RootCause>
}

/** Input for the deleteSeverity mutation */
export type DeleteSeverityInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Severity to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteSeverity mutation */
export type DeleteSeverityPayload = {
  __typename?: 'DeleteSeverityPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  severity?: Maybe<Severity>
}

/** Input for the deleteTag mutation */
export type DeleteTagInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the tag to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteTag mutation */
export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  tag?: Maybe<Tag>
}

/** Input for the deleteToolboxTalkCategory mutation */
export type DeleteToolboxTalkCategoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the ToolboxTalkCategory to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteToolboxTalkCategory mutation */
export type DeleteToolboxTalkCategoryPayload = {
  __typename?: 'DeleteToolboxTalkCategoryPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  toolboxTalkCategory?: Maybe<ToolboxTalkCategory>
}

/** Input for the deleteToolboxTalk mutation */
export type DeleteToolboxTalkInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>
  /** The ID of the ToolboxTalk to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteToolboxTalk mutation */
export type DeleteToolboxTalkPayload = {
  __typename?: 'DeleteToolboxTalkPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The object before it was deleted */
  toolboxTalk?: Maybe<ToolboxTalk>
}

/** Input for the deleteTrainingType mutation */
export type DeleteTrainingTypeInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the TrainingType to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteTrainingType mutation */
export type DeleteTrainingTypePayload = {
  __typename?: 'DeleteTrainingTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  trainingType?: Maybe<TrainingType>
}

/** Input for the deleteUserCompany mutation */
export type DeleteUserCompanyInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the UserCompany to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteUserCompany mutation */
export type DeleteUserCompanyPayload = {
  __typename?: 'DeleteUserCompanyPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  userCompany?: Maybe<UserCompany>
}

/** Input for the deleteUserDivision mutation */
export type DeleteUserDivisionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the UserDivision to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteUserDivision mutation */
export type DeleteUserDivisionPayload = {
  __typename?: 'DeleteUserDivisionPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  userDivision?: Maybe<UserDivision>
}

/** Input for the deleteUser mutation */
export type DeleteUserInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the user you want to delete */
  id: Scalars['ID']['input']
  /** Reassign posts and links to new User ID. */
  reassignId?: InputMaybe<Scalars['ID']['input']>
}

/** Input for the deleteUserLanguage mutation */
export type DeleteUserLanguageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the UserLanguage to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteUserLanguage mutation */
export type DeleteUserLanguagePayload = {
  __typename?: 'DeleteUserLanguagePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  userLanguage?: Maybe<UserLanguage>
}

/** The payload for the deleteUser mutation */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the user that you just deleted */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deleted user object */
  user?: Maybe<User>
}

/** Input for the deleteUserStatus mutation */
export type DeleteUserStatusInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the UserStatus to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteUserStatus mutation */
export type DeleteUserStatusPayload = {
  __typename?: 'DeleteUserStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  userStatus?: Maybe<UserStatus>
}

/** Input for the deleteUserVaccineStatus mutation */
export type DeleteUserVaccineStatusInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the UserVaccineStatus to delete */
  id: Scalars['ID']['input']
}

/** The payload for the deleteUserVaccineStatus mutation */
export type DeleteUserVaccineStatusPayload = {
  __typename?: 'DeleteUserVaccineStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>
  /** The deteted term object */
  userVaccineStatus?: Maybe<UserVaccineStatus>
}

/** The discussion setting type */
export type DiscussionSettings = {
  __typename?: 'DiscussionSettings'
  /** Allow people to submit comments on new posts. */
  defaultCommentStatus?: Maybe<Scalars['String']['output']>
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  defaultPingStatus?: Maybe<Scalars['String']['output']>
}

/** The Division type */
export type Division = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Division'
    /** Connection between the Division type and the ContentNode type */
    contentNodes?: Maybe<DivisionToContentNodeConnection>
    /** Connection between the Division type and the CorrectiveAction type */
    correctiveActions?: Maybe<DivisionToCorrectiveActionConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    divisionId?: Maybe<Scalars['Int']['output']>
    /** Connection between the Division type and the Document type */
    documents?: Maybe<DivisionToDocumentConnection>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** Connection between the Division type and the Equipment type */
    equipment?: Maybe<DivisionToEquipmentConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Connection between the Division type and the IncidentReport type */
    incidentReports?: Maybe<DivisionToIncidentReportConnection>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Connection between the Division type and the Lesson type */
    lessons?: Maybe<DivisionToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** Connection between the Division type and the Project type */
    projects?: Maybe<DivisionToProjectConnection>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Division type and the Taxonomy type */
    taxonomy?: Maybe<DivisionToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** Connection between the Division type and the ToolboxTalk type */
    toolboxTalks?: Maybe<DivisionToToolboxTalkConnection>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Division type */
export type DivisionContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DivisionToContentNodeConnectionWhereArgs>
}

/** The Division type */
export type DivisionCorrectiveActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DivisionToCorrectiveActionConnectionWhereArgs>
}

/** The Division type */
export type DivisionDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DivisionToDocumentConnectionWhereArgs>
}

/** The Division type */
export type DivisionEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Division type */
export type DivisionEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Division type */
export type DivisionEquipmentArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DivisionToEquipmentConnectionWhereArgs>
}

/** The Division type */
export type DivisionIncidentReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DivisionToIncidentReportConnectionWhereArgs>
}

/** The Division type */
export type DivisionLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DivisionToLessonConnectionWhereArgs>
}

/** The Division type */
export type DivisionProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DivisionToProjectConnectionWhereArgs>
}

/** The Division type */
export type DivisionToolboxTalksArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DivisionToToolboxTalkConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum DivisionIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the Division type and the ContentNode type */
export type DivisionToContentNodeConnection = {
  __typename?: 'DivisionToContentNodeConnection'
  /** Edges for the DivisionToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<DivisionToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DivisionToContentNodeConnectionEdge = {
  __typename?: 'DivisionToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the DivisionToContentNodeConnection connection */
export type DivisionToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfDivisionEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DivisionToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DivisionToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DivisionToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DivisionToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DivisionToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DivisionToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DivisionToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<DivisionToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DivisionToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DivisionToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DivisionToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DivisionToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DivisionToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<DivisionToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DivisionToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DivisionToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Division type and the CorrectiveAction type */
export type DivisionToCorrectiveActionConnection = {
  __typename?: 'DivisionToCorrectiveActionConnection'
  /** Edges for the DivisionToCorrectiveActionConnection connection */
  edges?: Maybe<Array<Maybe<DivisionToCorrectiveActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DivisionToCorrectiveActionConnectionEdge = {
  __typename?: 'DivisionToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Arguments for filtering the DivisionToCorrectiveActionConnection connection */
export type DivisionToCorrectiveActionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DivisionToCorrectiveActionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DivisionToCorrectiveActionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DivisionToCorrectiveActionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DivisionToCorrectiveActionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DivisionToCorrectiveActionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DivisionToCorrectiveActionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DivisionToCorrectiveActionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<DivisionToCorrectiveActionConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DivisionToCorrectiveActionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DivisionToCorrectiveActionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DivisionToCorrectiveActionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DivisionToCorrectiveActionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DivisionToCorrectiveActionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<DivisionToCorrectiveActionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DivisionToCorrectiveActionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DivisionToCorrectiveActionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Division type and the Document type */
export type DivisionToDocumentConnection = {
  __typename?: 'DivisionToDocumentConnection'
  /** Edges for the DivisionToDocumentConnection connection */
  edges?: Maybe<Array<Maybe<DivisionToDocumentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Document>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DivisionToDocumentConnectionEdge = {
  __typename?: 'DivisionToDocumentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Document>
}

/** Arguments for filtering the DivisionToDocumentConnection connection */
export type DivisionToDocumentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DivisionToDocumentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DivisionToDocumentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DivisionToDocumentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DivisionToDocumentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DivisionToDocumentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DivisionToDocumentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DivisionToDocumentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<DivisionToDocumentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DivisionToDocumentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DivisionToDocumentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DivisionToDocumentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DivisionToDocumentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DivisionToDocumentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<DivisionToDocumentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DivisionToDocumentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DivisionToDocumentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Division type and the Equipment type */
export type DivisionToEquipmentConnection = {
  __typename?: 'DivisionToEquipmentConnection'
  /** Edges for the DivisionToEquipmentConnection connection */
  edges?: Maybe<Array<Maybe<DivisionToEquipmentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DivisionToEquipmentConnectionEdge = {
  __typename?: 'DivisionToEquipmentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Arguments for filtering the DivisionToEquipmentConnection connection */
export type DivisionToEquipmentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DivisionToEquipmentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DivisionToEquipmentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DivisionToEquipmentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DivisionToEquipmentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DivisionToEquipmentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DivisionToEquipmentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DivisionToEquipmentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<DivisionToEquipmentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DivisionToEquipmentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DivisionToEquipmentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DivisionToEquipmentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DivisionToEquipmentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DivisionToEquipmentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<DivisionToEquipmentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DivisionToEquipmentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DivisionToEquipmentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Division type and the IncidentReport type */
export type DivisionToIncidentReportConnection = {
  __typename?: 'DivisionToIncidentReportConnection'
  /** Edges for the DivisionToIncidentReportConnection connection */
  edges?: Maybe<Array<Maybe<DivisionToIncidentReportConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReport>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DivisionToIncidentReportConnectionEdge = {
  __typename?: 'DivisionToIncidentReportConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReport>
}

/** Arguments for filtering the DivisionToIncidentReportConnection connection */
export type DivisionToIncidentReportConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DivisionToIncidentReportConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DivisionToIncidentReportConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DivisionToIncidentReportConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DivisionToIncidentReportConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DivisionToIncidentReportConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DivisionToIncidentReportConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DivisionToIncidentReportConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<DivisionToIncidentReportConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DivisionToIncidentReportConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DivisionToIncidentReportConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DivisionToIncidentReportConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DivisionToIncidentReportConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DivisionToIncidentReportConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<DivisionToIncidentReportConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DivisionToIncidentReportConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DivisionToIncidentReportConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Division type and the Lesson type */
export type DivisionToLessonConnection = {
  __typename?: 'DivisionToLessonConnection'
  /** Edges for the DivisionToLessonConnection connection */
  edges?: Maybe<Array<Maybe<DivisionToLessonConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Lesson>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DivisionToLessonConnectionEdge = {
  __typename?: 'DivisionToLessonConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Lesson>
}

/** Arguments for filtering the DivisionToLessonConnection connection */
export type DivisionToLessonConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DivisionToLessonConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DivisionToLessonConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DivisionToLessonConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DivisionToLessonConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DivisionToLessonConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DivisionToLessonConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DivisionToLessonConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<DivisionToLessonConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DivisionToLessonConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DivisionToLessonConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DivisionToLessonConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DivisionToLessonConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DivisionToLessonConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<DivisionToLessonConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DivisionToLessonConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DivisionToLessonConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Division type and the Project type */
export type DivisionToProjectConnection = {
  __typename?: 'DivisionToProjectConnection'
  /** Edges for the DivisionToProjectConnection connection */
  edges?: Maybe<Array<Maybe<DivisionToProjectConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DivisionToProjectConnectionEdge = {
  __typename?: 'DivisionToProjectConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Arguments for filtering the DivisionToProjectConnection connection */
export type DivisionToProjectConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DivisionToProjectConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DivisionToProjectConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DivisionToProjectConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DivisionToProjectConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DivisionToProjectConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DivisionToProjectConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DivisionToProjectConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<DivisionToProjectConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DivisionToProjectConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DivisionToProjectConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DivisionToProjectConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DivisionToProjectConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DivisionToProjectConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<DivisionToProjectConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DivisionToProjectConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DivisionToProjectConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Division type and the Taxonomy type */
export type DivisionToTaxonomyConnectionEdge = {
  __typename?: 'DivisionToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Connection between the Division type and the ToolboxTalk type */
export type DivisionToToolboxTalkConnection = {
  __typename?: 'DivisionToToolboxTalkConnection'
  /** Edges for the DivisionToToolboxTalkConnection connection */
  edges?: Maybe<Array<Maybe<DivisionToToolboxTalkConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ToolboxTalk>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DivisionToToolboxTalkConnectionEdge = {
  __typename?: 'DivisionToToolboxTalkConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ToolboxTalk>
}

/** Arguments for filtering the DivisionToToolboxTalkConnection connection */
export type DivisionToToolboxTalkConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DivisionToToolboxTalkConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DivisionToToolboxTalkConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DivisionToToolboxTalkConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DivisionToToolboxTalkConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DivisionToToolboxTalkConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DivisionToToolboxTalkConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DivisionToToolboxTalkConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<DivisionToToolboxTalkConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DivisionToToolboxTalkConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DivisionToToolboxTalkConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DivisionToToolboxTalkConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DivisionToToolboxTalkConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DivisionToToolboxTalkConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<DivisionToToolboxTalkConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DivisionToToolboxTalkConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DivisionToToolboxTalkConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** The Document type */
export type Document = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithContentEditor &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'Document'
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Document type and the Company type */
    companies?: Maybe<DocumentToCompanyConnection>
    /** The content of the post. */
    content?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** Connection between the Document type and the Division type */
    divisions?: Maybe<DocumentToDivisionConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    documentId: Scalars['Int']['output']
    /** Connection between the Document type and the DocumentTag type */
    documentTags?: Maybe<DocumentToDocumentTagConnection>
    /** Connection between the Document type and the Project type */
    documentToProjectConnection?: Maybe<DocumentToProjectConnection>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** Connection between the Document type and the Equipment type */
    equipmentToDocumentConnection?: Maybe<DocumentToEquipmentConnection>
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Document type and the MediaItem type */
    fileAttachments?: Maybe<DocumentToMediaItemConnection>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the document object. */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** Connection between the Document type and the Lesson type */
    lessonDocumentConnectionConnection?: Maybe<DocumentToLessonConnection>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /** Connection between the Document type and the Document type */
    preview?: Maybe<DocumentToPreviewConnectionEdge>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** Connection between the Document type and the TermNode type */
    terms?: Maybe<DocumentToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Document type */
export type DocumentCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DocumentToCompanyConnectionWhereArgs>
}

/** The Document type */
export type DocumentContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The Document type */
export type DocumentDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DocumentToDivisionConnectionWhereArgs>
}

/** The Document type */
export type DocumentDocumentTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DocumentToDocumentTagConnectionWhereArgs>
}

/** The Document type */
export type DocumentDocumentToProjectConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Document type */
export type DocumentEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Document type */
export type DocumentEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Document type */
export type DocumentEquipmentToDocumentConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Document type */
export type DocumentFileAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Document type */
export type DocumentLessonDocumentConnectionConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Document type */
export type DocumentTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DocumentToTermNodeConnectionWhereArgs>
}

/** The Document type */
export type DocumentTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** Set relationships between the Document to Companies */
export type DocumentCompaniesInput = {
  /** If true, this will append the Company to existing related Companies. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<DocumentCompaniesNodeInput>>>
}

/** List of Companies to connect the Document to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type DocumentCompaniesNodeInput = {
  /** The description of the Company. This field is used to set a description of the Company if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Company. If present, this will be used to connect to the Document. If no existing Company exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Company. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Company. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the Document to Divisions */
export type DocumentDivisionsInput = {
  /** If true, this will append the Division to existing related Divisions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<DocumentDivisionsNodeInput>>>
}

/** List of Divisions to connect the Document to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type DocumentDivisionsNodeInput = {
  /** The description of the Division. This field is used to set a description of the Division if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Division. If present, this will be used to connect to the Document. If no existing Division exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Division. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Division. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the Document to DocumentTags */
export type DocumentDocumentTagsInput = {
  /** If true, this will append the DocumentTag to existing related DocumentTags. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<DocumentDocumentTagsNodeInput>>>
}

/** List of DocumentTags to connect the Document to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type DocumentDocumentTagsNodeInput = {
  /** The description of the DocumentTag. This field is used to set a description of the DocumentTag if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the DocumentTag. If present, this will be used to connect to the Document. If no existing DocumentTag exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the DocumentTag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the DocumentTag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum DocumentIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** The DocumentTag type */
export type DocumentTag = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'DocumentTag'
    /** Connection between the DocumentTag type and the ContentNode type */
    contentNodes?: Maybe<DocumentTagToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    documentTagId?: Maybe<Scalars['Int']['output']>
    /** Connection between the DocumentTag type and the Document type */
    documents?: Maybe<DocumentTagToDocumentConnection>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the DocumentTag type and the Taxonomy type */
    taxonomy?: Maybe<DocumentTagToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The DocumentTag type */
export type DocumentTagContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DocumentTagToContentNodeConnectionWhereArgs>
}

/** The DocumentTag type */
export type DocumentTagDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DocumentTagToDocumentConnectionWhereArgs>
}

/** The DocumentTag type */
export type DocumentTagEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The DocumentTag type */
export type DocumentTagEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum DocumentTagIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the DocumentTag type and the ContentNode type */
export type DocumentTagToContentNodeConnection = {
  __typename?: 'DocumentTagToContentNodeConnection'
  /** Edges for the DocumentTagToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<DocumentTagToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DocumentTagToContentNodeConnectionEdge = {
  __typename?: 'DocumentTagToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the DocumentTagToContentNodeConnection connection */
export type DocumentTagToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfDocumentTagEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DocumentTagToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DocumentTagToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DocumentTagToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DocumentTagToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DocumentTagToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DocumentTagToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DocumentTagToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<DocumentTagToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DocumentTagToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DocumentTagToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DocumentTagToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DocumentTagToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DocumentTagToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<DocumentTagToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DocumentTagToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DocumentTagToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the DocumentTag type and the Document type */
export type DocumentTagToDocumentConnection = {
  __typename?: 'DocumentTagToDocumentConnection'
  /** Edges for the DocumentTagToDocumentConnection connection */
  edges?: Maybe<Array<Maybe<DocumentTagToDocumentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Document>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DocumentTagToDocumentConnectionEdge = {
  __typename?: 'DocumentTagToDocumentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Document>
}

/** Arguments for filtering the DocumentTagToDocumentConnection connection */
export type DocumentTagToDocumentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<DocumentTagToDocumentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<DocumentTagToDocumentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DocumentTagToDocumentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<DocumentTagToDocumentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<DocumentTagToDocumentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum DocumentTagToDocumentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type DocumentTagToDocumentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<DocumentTagToDocumentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum DocumentTagToDocumentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type DocumentTagToDocumentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<DocumentTagToDocumentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<DocumentTagToDocumentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type DocumentTagToDocumentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<DocumentTagToDocumentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum DocumentTagToDocumentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum DocumentTagToDocumentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the DocumentTag type and the Taxonomy type */
export type DocumentTagToTaxonomyConnectionEdge = {
  __typename?: 'DocumentTagToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Connection between the Document type and the Company type */
export type DocumentToCompanyConnection = {
  __typename?: 'DocumentToCompanyConnection'
  /** Edges for the DocumentToCompanyConnection connection */
  edges?: Maybe<Array<Maybe<DocumentToCompanyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Company>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DocumentToCompanyConnectionEdge = {
  __typename?: 'DocumentToCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Company>
}

/** Arguments for filtering the DocumentToCompanyConnection connection */
export type DocumentToCompanyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Document type and the Division type */
export type DocumentToDivisionConnection = {
  __typename?: 'DocumentToDivisionConnection'
  /** Edges for the DocumentToDivisionConnection connection */
  edges?: Maybe<Array<Maybe<DocumentToDivisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Division>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DocumentToDivisionConnectionEdge = {
  __typename?: 'DocumentToDivisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Division>
}

/** Arguments for filtering the DocumentToDivisionConnection connection */
export type DocumentToDivisionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Document type and the DocumentTag type */
export type DocumentToDocumentTagConnection = {
  __typename?: 'DocumentToDocumentTagConnection'
  /** Edges for the DocumentToDocumentTagConnection connection */
  edges?: Maybe<Array<Maybe<DocumentToDocumentTagConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<DocumentTag>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DocumentToDocumentTagConnectionEdge = {
  __typename?: 'DocumentToDocumentTagConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<DocumentTag>
}

/** Arguments for filtering the DocumentToDocumentTagConnection connection */
export type DocumentToDocumentTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Document type and the Equipment type */
export type DocumentToEquipmentConnection = {
  __typename?: 'DocumentToEquipmentConnection'
  /** Edges for the DocumentToEquipmentConnection connection */
  edges?: Maybe<Array<Maybe<DocumentToEquipmentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DocumentToEquipmentConnectionEdge = {
  __typename?: 'DocumentToEquipmentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Connection between the Document type and the Lesson type */
export type DocumentToLessonConnection = {
  __typename?: 'DocumentToLessonConnection'
  /** Edges for the DocumentToLessonConnection connection */
  edges?: Maybe<Array<Maybe<DocumentToLessonConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Lesson>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DocumentToLessonConnectionEdge = {
  __typename?: 'DocumentToLessonConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Lesson>
}

/** Connection between the Document type and the MediaItem type */
export type DocumentToMediaItemConnection = {
  __typename?: 'DocumentToMediaItemConnection'
  /** Edges for the DocumentToMediaItemConnection connection */
  edges?: Maybe<Array<Maybe<DocumentToMediaItemConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MediaItem>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DocumentToMediaItemConnectionEdge = {
  __typename?: 'DocumentToMediaItemConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<MediaItem>
}

/** Connection between the Document type and the Document type */
export type DocumentToPreviewConnectionEdge = {
  __typename?: 'DocumentToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Document>
}

/** Connection between the Document type and the Project type */
export type DocumentToProjectConnection = {
  __typename?: 'DocumentToProjectConnection'
  /** Edges for the DocumentToProjectConnection connection */
  edges?: Maybe<Array<Maybe<DocumentToProjectConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DocumentToProjectConnectionEdge = {
  __typename?: 'DocumentToProjectConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Connection between the Document type and the TermNode type */
export type DocumentToTermNodeConnection = {
  __typename?: 'DocumentToTermNodeConnection'
  /** Edges for the DocumentToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<DocumentToTermNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type DocumentToTermNodeConnectionEdge = {
  __typename?: 'DocumentToTermNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TermNode>
}

/** Arguments for filtering the DocumentToTermNodeConnection connection */
export type DocumentToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** The Type of Identifier used to fetch a single resource. */
export enum DraftEntryIdTypeEnum {
  /** Unique global ID for the object. */
  Id = 'ID',
  /** The resume token assigned by Gravity Forms. Used by draft entries. */
  ResumeToken = 'RESUME_TOKEN',
}

/** A Gravity Forms   field. */
export type EmailField = FormField & {
  __typename?: 'EmailField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Determines whether the Confirm Email field is active. */
  hasEmailConfirmation?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** An array of the available properties for each input of the email field. */
  inputs?: Maybe<Array<Maybe<EmailInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Input fields for email field. */
export type EmailFieldInput = {
  /** Email confirmation input value. Only used when email confirmation is enabled. */
  confirmationValue?: InputMaybe<Scalars['String']['input']>
  /** Email input value. */
  value?: InputMaybe<Scalars['String']['input']>
}

/** Email input values. */
export type EmailInputProperty = {
  __typename?: 'EmailInputProperty'
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** Asset enqueued by the CMS */
export type EnqueuedAsset = {
  /** @todo */
  args?: Maybe<Scalars['Boolean']['output']>
  /** Dependencies needed to use this asset */
  dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>
  /** Extra information needed for the script */
  extra?: Maybe<Scalars['String']['output']>
  /** The handle of the enqueued asset */
  handle?: Maybe<Scalars['String']['output']>
  /** The ID of the enqueued asset */
  id: Scalars['ID']['output']
  /** The source of the asset */
  src?: Maybe<Scalars['String']['output']>
  /** The version of the enqueued asset */
  version?: Maybe<Scalars['String']['output']>
}

/** Script enqueued by the CMS */
export type EnqueuedScript = EnqueuedAsset &
  Node & {
    __typename?: 'EnqueuedScript'
    /** @todo */
    args?: Maybe<Scalars['Boolean']['output']>
    /** Dependencies needed to use this asset */
    dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>
    /** Extra information needed for the script */
    extra?: Maybe<Scalars['String']['output']>
    /** The handle of the enqueued asset */
    handle?: Maybe<Scalars['String']['output']>
    /** The ID of the enqueued asset */
    id: Scalars['ID']['output']
    /** The source of the asset */
    src?: Maybe<Scalars['String']['output']>
    /** The version of the enqueued asset */
    version?: Maybe<Scalars['String']['output']>
  }

/** Stylesheet enqueued by the CMS */
export type EnqueuedStylesheet = EnqueuedAsset &
  Node & {
    __typename?: 'EnqueuedStylesheet'
    /** @todo */
    args?: Maybe<Scalars['Boolean']['output']>
    /** Dependencies needed to use this asset */
    dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>
    /** Extra information needed for the script */
    extra?: Maybe<Scalars['String']['output']>
    /** The handle of the enqueued asset */
    handle?: Maybe<Scalars['String']['output']>
    /** The ID of the enqueued asset */
    id: Scalars['ID']['output']
    /** The source of the asset */
    src?: Maybe<Scalars['String']['output']>
    /** The version of the enqueued asset */
    version?: Maybe<Scalars['String']['output']>
  }

/** Options for ordering the connection. */
export type EntriesConnectionOrderbyInput = {
  /** The field name used to sort the results. */
  field?: InputMaybe<Scalars['String']['input']>
  /** Whether the sorting field's values are numeric. */
  isNumeric?: InputMaybe<Scalars['Boolean']['input']>
  /** The cardinality of the order of the connection. */
  order?: InputMaybe<OrderEnum>
}

/** Date Filters input fields for Entries queries. */
export type EntriesDateFiltersInput = {
  /** End date in Y-m-d H:i:s format. */
  endDate?: InputMaybe<Scalars['String']['input']>
  /** Start date in Y-m-d H:i:s format. */
  startDate?: InputMaybe<Scalars['String']['input']>
}

/** Field Filters input fields for Entries queries. */
export type EntriesFieldFiltersInput = {
  /** The field value(s) to filter by. Must be boolean values. If using this field, do not also use stringValues, intValues or floatValues. */
  boolValues?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>
  /** The field value(s) to filter by. Must be float values. If using this field, do not also use stringValues, intValues or boolValues. */
  floatValues?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  /** The field value(s) to filter by. Must be integer values. If using this field, do not also use stringValues, floatValues or boolValues. */
  intValues?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** Optional. The entry meta key to filter by. You can use the ID of the form field, or the names of any of the columns in Gravity Form's database table for entries, such as "date_created", "is_read, "created_by", etc. If omitted, the value will be checked against all meta keys. . */
  key?: InputMaybe<Scalars['String']['input']>
  /** The operator to use for filtering. */
  operator?: InputMaybe<FieldFiltersOperatorEnum>
  /** The field value(s) to filter by. Must be string values. If using this field, do not also use intValues, floatValues or boolValues. */
  stringValues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** The EntryId type */
export type EntryId = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'EntryId'
    /** Connection between the EntryId type and the ContentNode type */
    contentNodes?: Maybe<EntryIdToContentNodeConnection>
    /** Connection between the EntryId type and the CorrectiveAction type */
    correctiveActions?: Maybe<EntryIdToCorrectiveActionConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    entryIdId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** Connection between the EntryId type and the Photo type */
    photos?: Maybe<EntryIdToPhotoConnection>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the EntryId type and the Taxonomy type */
    taxonomy?: Maybe<EntryIdToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The EntryId type */
export type EntryIdContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EntryIdToContentNodeConnectionWhereArgs>
}

/** The EntryId type */
export type EntryIdCorrectiveActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EntryIdToCorrectiveActionConnectionWhereArgs>
}

/** The EntryId type */
export type EntryIdEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The EntryId type */
export type EntryIdEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The EntryId type */
export type EntryIdPhotosArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EntryIdToPhotoConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum EntryIdIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the EntryId type and the ContentNode type */
export type EntryIdToContentNodeConnection = {
  __typename?: 'EntryIdToContentNodeConnection'
  /** Edges for the EntryIdToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<EntryIdToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EntryIdToContentNodeConnectionEdge = {
  __typename?: 'EntryIdToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the EntryIdToContentNodeConnection connection */
export type EntryIdToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfEntryIdEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<EntryIdToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<EntryIdToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EntryIdToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<EntryIdToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<EntryIdToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum EntryIdToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type EntryIdToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<EntryIdToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum EntryIdToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type EntryIdToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<EntryIdToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<EntryIdToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type EntryIdToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<EntryIdToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum EntryIdToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum EntryIdToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the EntryId type and the CorrectiveAction type */
export type EntryIdToCorrectiveActionConnection = {
  __typename?: 'EntryIdToCorrectiveActionConnection'
  /** Edges for the EntryIdToCorrectiveActionConnection connection */
  edges?: Maybe<Array<Maybe<EntryIdToCorrectiveActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EntryIdToCorrectiveActionConnectionEdge = {
  __typename?: 'EntryIdToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Arguments for filtering the EntryIdToCorrectiveActionConnection connection */
export type EntryIdToCorrectiveActionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<EntryIdToCorrectiveActionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<EntryIdToCorrectiveActionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EntryIdToCorrectiveActionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<EntryIdToCorrectiveActionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<EntryIdToCorrectiveActionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum EntryIdToCorrectiveActionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type EntryIdToCorrectiveActionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<EntryIdToCorrectiveActionConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum EntryIdToCorrectiveActionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type EntryIdToCorrectiveActionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<EntryIdToCorrectiveActionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<EntryIdToCorrectiveActionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type EntryIdToCorrectiveActionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<EntryIdToCorrectiveActionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum EntryIdToCorrectiveActionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum EntryIdToCorrectiveActionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the EntryId type and the Photo type */
export type EntryIdToPhotoConnection = {
  __typename?: 'EntryIdToPhotoConnection'
  /** Edges for the EntryIdToPhotoConnection connection */
  edges?: Maybe<Array<Maybe<EntryIdToPhotoConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Photo>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EntryIdToPhotoConnectionEdge = {
  __typename?: 'EntryIdToPhotoConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Photo>
}

/** Arguments for filtering the EntryIdToPhotoConnection connection */
export type EntryIdToPhotoConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<EntryIdToPhotoConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<EntryIdToPhotoConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EntryIdToPhotoConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<EntryIdToPhotoConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<EntryIdToPhotoConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum EntryIdToPhotoConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type EntryIdToPhotoConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<EntryIdToPhotoConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum EntryIdToPhotoConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type EntryIdToPhotoConnectionWhereArgsTaxArray = {
  field?: InputMaybe<EntryIdToPhotoConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<EntryIdToPhotoConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type EntryIdToPhotoConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<EntryIdToPhotoConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum EntryIdToPhotoConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum EntryIdToPhotoConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the EntryId type and the Taxonomy type */
export type EntryIdToTaxonomyConnectionEdge = {
  __typename?: 'EntryIdToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** The Type of Identifier used to fetch a single resource. */
export enum EntryIdTypeEnum {
  /** The database ID assigned by Gravity Forms. Used by submitted entries. */
  DatabaseId = 'DATABASE_ID',
  /** Unique global ID for the object. */
  Id = 'ID',
  /** The resume token assigned by Gravity Forms. Used by draft entries. */
  ResumeToken = 'RESUME_TOKEN',
}

/** The quiz results for the entry. Requires Gravity Forms Quiz to be enabled. */
export type EntryQuizResults = {
  __typename?: 'EntryQuizResults'
  /** The quiz score as a letter grade. */
  grade?: Maybe<Scalars['String']['output']>
  /** Whether the quiz score meets the assigned passing threshold. */
  isPassingScore?: Maybe<Scalars['Boolean']['output']>
  /** The quiz score as a percent. */
  percent?: Maybe<Scalars['Int']['output']>
  /** The raw quiz score. */
  score?: Maybe<Scalars['Int']['output']>
}

/** Status of entries to get. Default is ACTIVE. */
export enum EntryStatusEnum {
  /** Active entries (default). */
  Active = 'ACTIVE',
  /** All entries. */
  All = 'ALL',
  /** Spam entries. */
  Spam = 'SPAM',
  /** Entries in the trash. */
  Trash = 'TRASH',
}

/** The type of Gravity Forms entry. */
export enum EntryTypeEnum {
  /** A Gravity Forms draft entry. */
  Draft = 'DRAFT',
  /** A Gravity Forms partial entry. */
  Partial = 'PARTIAL',
  /** A submitted Gravity Forms entry. */
  Submitted = 'SUBMITTED',
}

/** The Equipment type */
export type Equipment = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'Equipment'
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Equipment type and the Company type */
    companies?: Maybe<EquipmentToCompanyConnection>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** Connection between the Equipment type and the Division type */
    divisions?: Maybe<EquipmentToDivisionConnection>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** Connection between the Equipment type and the User type */
    equipmentAssignedWorkerConnection?: Maybe<EquipmentToUserConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    equipmentId: Scalars['Int']['output']
    /** Connection between the Equipment type and the EquipmentStatus type */
    equipmentStatuses?: Maybe<EquipmentToEquipmentStatusConnection>
    /** Connection between the Equipment type and the EquipmentTag type */
    equipmentTags?: Maybe<EquipmentToEquipmentTagConnection>
    /** Connection between the Equipment type and the Document type */
    equipmentToDocumentConnection?: Maybe<EquipmentToDocumentConnection>
    /** Connection between the Equipment type and the Equipment type */
    equipmentToEquipmentConnection?: Maybe<EquipmentToEquipmentConnection>
    /** Connection between the Equipment type and the Project type */
    equipmentToProjectConnection?: Maybe<EquipmentToProjectConnection>
    /** Connection between the Equipment type and the MediaItem type */
    fileAttachments?: Maybe<EquipmentToMediaItemConnection>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the equipment object. */
    id: Scalars['ID']['output']
    /** In service Date */
    inServiceDate?: Maybe<Scalars['String']['output']>
    /** Connection between the Equipment type and the InspectionInterval type */
    inspectionIntervals?: Maybe<EquipmentToInspectionIntervalConnection>
    /** Integration source */
    integrationSource?: Maybe<Scalars['String']['output']>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** Last inspection date */
    lastInspectedDate?: Maybe<Scalars['String']['output']>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** Connection between the Equipment type and the Manufacturer type */
    manufacturers?: Maybe<EquipmentToManufacturerConnection>
    /** Model number */
    modelNumber?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /** Next inspection date based. */
    nextInspectionDate?: Maybe<Scalars['String']['output']>
    /** Connection between the Equipment type and the Equipment type */
    preview?: Maybe<EquipmentToPreviewConnectionEdge>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** Serial number */
    serialNumber?: Maybe<Scalars['String']['output']>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** Connection between the Equipment type and the TermNode type */
    terms?: Maybe<EquipmentToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Equipment type */
export type EquipmentCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EquipmentToCompanyConnectionWhereArgs>
}

/** The Equipment type */
export type EquipmentDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EquipmentToDivisionConnectionWhereArgs>
}

/** The Equipment type */
export type EquipmentEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Equipment type */
export type EquipmentEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Equipment type */
export type EquipmentEquipmentAssignedWorkerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Equipment type */
export type EquipmentEquipmentStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EquipmentToEquipmentStatusConnectionWhereArgs>
}

/** The Equipment type */
export type EquipmentEquipmentTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EquipmentToEquipmentTagConnectionWhereArgs>
}

/** The Equipment type */
export type EquipmentEquipmentToDocumentConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Equipment type */
export type EquipmentEquipmentToEquipmentConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Equipment type */
export type EquipmentEquipmentToProjectConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Equipment type */
export type EquipmentFileAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Equipment type */
export type EquipmentInspectionIntervalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EquipmentToInspectionIntervalConnectionWhereArgs>
}

/** The Equipment type */
export type EquipmentManufacturersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EquipmentToManufacturerConnectionWhereArgs>
}

/** The Equipment type */
export type EquipmentTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EquipmentToTermNodeConnectionWhereArgs>
}

/** The Equipment type */
export type EquipmentTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** Set relationships between the Equipment to Companies */
export type EquipmentCompaniesInput = {
  /** If true, this will append the Company to existing related Companies. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<EquipmentCompaniesNodeInput>>>
}

/** List of Companies to connect the Equipment to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type EquipmentCompaniesNodeInput = {
  /** The description of the Company. This field is used to set a description of the Company if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Company. If present, this will be used to connect to the Equipment. If no existing Company exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Company. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Company. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the Equipment to Divisions */
export type EquipmentDivisionsInput = {
  /** If true, this will append the Division to existing related Divisions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<EquipmentDivisionsNodeInput>>>
}

/** List of Divisions to connect the Equipment to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type EquipmentDivisionsNodeInput = {
  /** The description of the Division. This field is used to set a description of the Division if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Division. If present, this will be used to connect to the Equipment. If no existing Division exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Division. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Division. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the Equipment to EquipmentStatuses */
export type EquipmentEquipmentStatusesInput = {
  /** If true, this will append the EquipmentStatus to existing related EquipmentStatuses. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<EquipmentEquipmentStatusesNodeInput>>>
}

/** List of EquipmentStatuses to connect the Equipment to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type EquipmentEquipmentStatusesNodeInput = {
  /** The description of the EquipmentStatus. This field is used to set a description of the EquipmentStatus if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the EquipmentStatus. If present, this will be used to connect to the Equipment. If no existing EquipmentStatus exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the EquipmentStatus. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the EquipmentStatus. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the Equipment to EquipmentTags */
export type EquipmentEquipmentTagsInput = {
  /** If true, this will append the EquipmentTag to existing related EquipmentTags. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<EquipmentEquipmentTagsNodeInput>>>
}

/** List of EquipmentTags to connect the Equipment to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type EquipmentEquipmentTagsNodeInput = {
  /** The description of the EquipmentTag. This field is used to set a description of the EquipmentTag if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the EquipmentTag. If present, this will be used to connect to the Equipment. If no existing EquipmentTag exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the EquipmentTag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the EquipmentTag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum EquipmentIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the Equipment to InspectionIntervals */
export type EquipmentInspectionIntervalsInput = {
  /** If true, this will append the InspectionInterval to existing related InspectionIntervals. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<EquipmentInspectionIntervalsNodeInput>>>
}

/** List of InspectionIntervals to connect the Equipment to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type EquipmentInspectionIntervalsNodeInput = {
  /** The description of the InspectionInterval. This field is used to set a description of the InspectionInterval if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the InspectionInterval. If present, this will be used to connect to the Equipment. If no existing InspectionInterval exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the InspectionInterval. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the InspectionInterval. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the Equipment to Manufacturers */
export type EquipmentManufacturersInput = {
  /** If true, this will append the Manufacturer to existing related Manufacturers. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<EquipmentManufacturersNodeInput>>>
}

/** List of Manufacturers to connect the Equipment to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type EquipmentManufacturersNodeInput = {
  /** The description of the Manufacturer. This field is used to set a description of the Manufacturer if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Manufacturer. If present, this will be used to connect to the Equipment. If no existing Manufacturer exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Manufacturer. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Manufacturer. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The EquipmentStatus type */
export type EquipmentStatus = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'EquipmentStatus'
    /** Connection between the EquipmentStatus type and the ContentNode type */
    contentNodes?: Maybe<EquipmentStatusToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** Connection between the EquipmentStatus type and the Equipment type */
    equipment?: Maybe<EquipmentStatusToEquipmentConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    equipmentStatusId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the EquipmentStatus type and the Taxonomy type */
    taxonomy?: Maybe<EquipmentStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The EquipmentStatus type */
export type EquipmentStatusContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EquipmentStatusToContentNodeConnectionWhereArgs>
}

/** The EquipmentStatus type */
export type EquipmentStatusEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The EquipmentStatus type */
export type EquipmentStatusEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The EquipmentStatus type */
export type EquipmentStatusEquipmentArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EquipmentStatusToEquipmentConnectionWhereArgs>
}

/** Equipment Status Options */
export enum EquipmentStatusEnum {
  /** Used when the Equipment is being used. */
  InService = 'in_service',
  /** When the equipment is not in use. */
  NotInService = 'not_in_service',
  /** When the equipment inspection is overdue. */
  OverdueInspection = 'overdue_inspection',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum EquipmentStatusIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the EquipmentStatus type and the ContentNode type */
export type EquipmentStatusToContentNodeConnection = {
  __typename?: 'EquipmentStatusToContentNodeConnection'
  /** Edges for the EquipmentStatusToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentStatusToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentStatusToContentNodeConnectionEdge = {
  __typename?: 'EquipmentStatusToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the EquipmentStatusToContentNodeConnection connection */
export type EquipmentStatusToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfEquipmentStatusEnum>>
  >
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<EquipmentStatusToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<EquipmentStatusToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EquipmentStatusToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<EquipmentStatusToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<EquipmentStatusToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum EquipmentStatusToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type EquipmentStatusToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<EquipmentStatusToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum EquipmentStatusToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type EquipmentStatusToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<EquipmentStatusToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<EquipmentStatusToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type EquipmentStatusToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<EquipmentStatusToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum EquipmentStatusToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum EquipmentStatusToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the EquipmentStatus type and the Equipment type */
export type EquipmentStatusToEquipmentConnection = {
  __typename?: 'EquipmentStatusToEquipmentConnection'
  /** Edges for the EquipmentStatusToEquipmentConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentStatusToEquipmentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentStatusToEquipmentConnectionEdge = {
  __typename?: 'EquipmentStatusToEquipmentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Arguments for filtering the EquipmentStatusToEquipmentConnection connection */
export type EquipmentStatusToEquipmentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<EquipmentStatusToEquipmentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<EquipmentStatusToEquipmentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EquipmentStatusToEquipmentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<EquipmentStatusToEquipmentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<EquipmentStatusToEquipmentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum EquipmentStatusToEquipmentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type EquipmentStatusToEquipmentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<EquipmentStatusToEquipmentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum EquipmentStatusToEquipmentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type EquipmentStatusToEquipmentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<EquipmentStatusToEquipmentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<EquipmentStatusToEquipmentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type EquipmentStatusToEquipmentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<EquipmentStatusToEquipmentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum EquipmentStatusToEquipmentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum EquipmentStatusToEquipmentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the EquipmentStatus type and the Taxonomy type */
export type EquipmentStatusToTaxonomyConnectionEdge = {
  __typename?: 'EquipmentStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** The EquipmentTag type */
export type EquipmentTag = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'EquipmentTag'
    /** Connection between the EquipmentTag type and the ContentNode type */
    contentNodes?: Maybe<EquipmentTagToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** Connection between the EquipmentTag type and the Equipment type */
    equipment?: Maybe<EquipmentTagToEquipmentConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    equipmentTagId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the EquipmentTag type and the Taxonomy type */
    taxonomy?: Maybe<EquipmentTagToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The EquipmentTag type */
export type EquipmentTagContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EquipmentTagToContentNodeConnectionWhereArgs>
}

/** The EquipmentTag type */
export type EquipmentTagEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The EquipmentTag type */
export type EquipmentTagEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The EquipmentTag type */
export type EquipmentTagEquipmentArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EquipmentTagToEquipmentConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum EquipmentTagIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the EquipmentTag type and the ContentNode type */
export type EquipmentTagToContentNodeConnection = {
  __typename?: 'EquipmentTagToContentNodeConnection'
  /** Edges for the EquipmentTagToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentTagToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentTagToContentNodeConnectionEdge = {
  __typename?: 'EquipmentTagToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the EquipmentTagToContentNodeConnection connection */
export type EquipmentTagToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfEquipmentTagEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<EquipmentTagToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<EquipmentTagToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EquipmentTagToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<EquipmentTagToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<EquipmentTagToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum EquipmentTagToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type EquipmentTagToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<EquipmentTagToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum EquipmentTagToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type EquipmentTagToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<EquipmentTagToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<EquipmentTagToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type EquipmentTagToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<EquipmentTagToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum EquipmentTagToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum EquipmentTagToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the EquipmentTag type and the Equipment type */
export type EquipmentTagToEquipmentConnection = {
  __typename?: 'EquipmentTagToEquipmentConnection'
  /** Edges for the EquipmentTagToEquipmentConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentTagToEquipmentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentTagToEquipmentConnectionEdge = {
  __typename?: 'EquipmentTagToEquipmentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Arguments for filtering the EquipmentTagToEquipmentConnection connection */
export type EquipmentTagToEquipmentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<EquipmentTagToEquipmentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<EquipmentTagToEquipmentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EquipmentTagToEquipmentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<EquipmentTagToEquipmentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<EquipmentTagToEquipmentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum EquipmentTagToEquipmentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type EquipmentTagToEquipmentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<EquipmentTagToEquipmentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum EquipmentTagToEquipmentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type EquipmentTagToEquipmentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<EquipmentTagToEquipmentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<EquipmentTagToEquipmentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type EquipmentTagToEquipmentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<EquipmentTagToEquipmentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum EquipmentTagToEquipmentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum EquipmentTagToEquipmentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the EquipmentTag type and the Taxonomy type */
export type EquipmentTagToTaxonomyConnectionEdge = {
  __typename?: 'EquipmentTagToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Connection between the Equipment type and the Company type */
export type EquipmentToCompanyConnection = {
  __typename?: 'EquipmentToCompanyConnection'
  /** Edges for the EquipmentToCompanyConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToCompanyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Company>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToCompanyConnectionEdge = {
  __typename?: 'EquipmentToCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Company>
}

/** Arguments for filtering the EquipmentToCompanyConnection connection */
export type EquipmentToCompanyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Equipment type and the Division type */
export type EquipmentToDivisionConnection = {
  __typename?: 'EquipmentToDivisionConnection'
  /** Edges for the EquipmentToDivisionConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToDivisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Division>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToDivisionConnectionEdge = {
  __typename?: 'EquipmentToDivisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Division>
}

/** Arguments for filtering the EquipmentToDivisionConnection connection */
export type EquipmentToDivisionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Equipment type and the Document type */
export type EquipmentToDocumentConnection = {
  __typename?: 'EquipmentToDocumentConnection'
  /** Edges for the EquipmentToDocumentConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToDocumentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Document>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToDocumentConnectionEdge = {
  __typename?: 'EquipmentToDocumentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Document>
}

/** Connection between the Equipment type and the Equipment type */
export type EquipmentToEquipmentConnection = {
  __typename?: 'EquipmentToEquipmentConnection'
  /** Edges for the EquipmentToEquipmentConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToEquipmentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToEquipmentConnectionEdge = {
  __typename?: 'EquipmentToEquipmentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Connection between the Equipment type and the EquipmentStatus type */
export type EquipmentToEquipmentStatusConnection = {
  __typename?: 'EquipmentToEquipmentStatusConnection'
  /** Edges for the EquipmentToEquipmentStatusConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToEquipmentStatusConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EquipmentStatus>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToEquipmentStatusConnectionEdge = {
  __typename?: 'EquipmentToEquipmentStatusConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EquipmentStatus>
}

/** Arguments for filtering the EquipmentToEquipmentStatusConnection connection */
export type EquipmentToEquipmentStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Equipment type and the EquipmentTag type */
export type EquipmentToEquipmentTagConnection = {
  __typename?: 'EquipmentToEquipmentTagConnection'
  /** Edges for the EquipmentToEquipmentTagConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToEquipmentTagConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EquipmentTag>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToEquipmentTagConnectionEdge = {
  __typename?: 'EquipmentToEquipmentTagConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EquipmentTag>
}

/** Arguments for filtering the EquipmentToEquipmentTagConnection connection */
export type EquipmentToEquipmentTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Equipment type and the InspectionInterval type */
export type EquipmentToInspectionIntervalConnection = {
  __typename?: 'EquipmentToInspectionIntervalConnection'
  /** Edges for the EquipmentToInspectionIntervalConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToInspectionIntervalConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<InspectionInterval>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToInspectionIntervalConnectionEdge = {
  __typename?: 'EquipmentToInspectionIntervalConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<InspectionInterval>
}

/** Arguments for filtering the EquipmentToInspectionIntervalConnection connection */
export type EquipmentToInspectionIntervalConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Equipment type and the Manufacturer type */
export type EquipmentToManufacturerConnection = {
  __typename?: 'EquipmentToManufacturerConnection'
  /** Edges for the EquipmentToManufacturerConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToManufacturerConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Manufacturer>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToManufacturerConnectionEdge = {
  __typename?: 'EquipmentToManufacturerConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Manufacturer>
}

/** Arguments for filtering the EquipmentToManufacturerConnection connection */
export type EquipmentToManufacturerConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Equipment type and the MediaItem type */
export type EquipmentToMediaItemConnection = {
  __typename?: 'EquipmentToMediaItemConnection'
  /** Edges for the EquipmentToMediaItemConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToMediaItemConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MediaItem>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToMediaItemConnectionEdge = {
  __typename?: 'EquipmentToMediaItemConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<MediaItem>
}

/** Connection between the Equipment type and the Equipment type */
export type EquipmentToPreviewConnectionEdge = {
  __typename?: 'EquipmentToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Equipment>
}

/** Connection between the Equipment type and the Project type */
export type EquipmentToProjectConnection = {
  __typename?: 'EquipmentToProjectConnection'
  /** Edges for the EquipmentToProjectConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToProjectConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToProjectConnectionEdge = {
  __typename?: 'EquipmentToProjectConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Connection between the Equipment type and the TermNode type */
export type EquipmentToTermNodeConnection = {
  __typename?: 'EquipmentToTermNodeConnection'
  /** Edges for the EquipmentToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToTermNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToTermNodeConnectionEdge = {
  __typename?: 'EquipmentToTermNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TermNode>
}

/** Arguments for filtering the EquipmentToTermNodeConnection connection */
export type EquipmentToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Equipment type and the User type */
export type EquipmentToUserConnection = {
  __typename?: 'EquipmentToUserConnection'
  /** Edges for the EquipmentToUserConnection connection */
  edges?: Maybe<Array<Maybe<EquipmentToUserConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<User>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type EquipmentToUserConnectionEdge = {
  __typename?: 'EquipmentToUserConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<User>
}

/** External contact data */
export type ExternalContact = {
  __typename?: 'ExternalContact'
  /** The contact email */
  email?: Maybe<Scalars['String']['output']>
  /** The contact name */
  name?: Maybe<Scalars['String']['output']>
}

/** An external contact. */
export type ExternalContactInput = {
  /** The contact email. */
  email?: InputMaybe<Scalars['String']['input']>
  /** The contact name. */
  name?: InputMaybe<Scalars['String']['input']>
}

/** Field error. */
export type FieldError = {
  __typename?: 'FieldError'
  /** The field with the associated error message. */
  id?: Maybe<Scalars['Float']['output']>
  /** Error message. */
  message?: Maybe<Scalars['String']['output']>
}

/** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
export enum FieldFiltersModeEnum {
  /** All field filters (default). */
  All = 'ALL',
  /** Any field filters. */
  Any = 'ANY',
}

/** The operator to use for filtering. */
export enum FieldFiltersOperatorEnum {
  /** Find field values that contain the passed value. Only one value may be passed when using this operator. SQL Equivalent: `LIKE %value%`. */
  Contains = 'CONTAINS',
  /** Default. Find field values that are equal to one of the values in the passed array. Default. */
  In = 'IN',
  /** Find field values that are an exact match for the passed value. Only one value may be passed when using this operator. SQL Equivalent: `=`. */
  Is = 'IS',
  /** Find field values that are NOT an exact match for the passed value. Only one value may be passed when using this operator. SQL Equivalent: `NOT`. */
  IsNot = 'IS_NOT',
  /** Find field values that are an exact match for the passed value. SQL wildcards are supported. Only one value may be passed when using this operator. SQL Equivalent: `LIKE`. */
  Like = 'LIKE',
  /** Find field values that do NOT match those in the values array. */
  NotIn = 'NOT_IN',
}

/** The FieldId type */
export type FieldId = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'FieldId'
    /** Connection between the FieldId type and the ContentNode type */
    contentNodes?: Maybe<FieldIdToContentNodeConnection>
    /** Connection between the FieldId type and the CorrectiveAction type */
    correctiveActions?: Maybe<FieldIdToCorrectiveActionConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    fieldIdId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** Connection between the FieldId type and the Photo type */
    photos?: Maybe<FieldIdToPhotoConnection>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the FieldId type and the Taxonomy type */
    taxonomy?: Maybe<FieldIdToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The FieldId type */
export type FieldIdContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FieldIdToContentNodeConnectionWhereArgs>
}

/** The FieldId type */
export type FieldIdCorrectiveActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FieldIdToCorrectiveActionConnectionWhereArgs>
}

/** The FieldId type */
export type FieldIdEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The FieldId type */
export type FieldIdEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The FieldId type */
export type FieldIdPhotosArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FieldIdToPhotoConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum FieldIdIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the FieldId type and the ContentNode type */
export type FieldIdToContentNodeConnection = {
  __typename?: 'FieldIdToContentNodeConnection'
  /** Edges for the FieldIdToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<FieldIdToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type FieldIdToContentNodeConnectionEdge = {
  __typename?: 'FieldIdToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the FieldIdToContentNodeConnection connection */
export type FieldIdToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfFieldIdEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<FieldIdToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<FieldIdToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type FieldIdToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<FieldIdToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<FieldIdToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum FieldIdToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type FieldIdToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<FieldIdToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum FieldIdToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type FieldIdToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<FieldIdToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<FieldIdToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type FieldIdToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<FieldIdToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum FieldIdToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum FieldIdToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the FieldId type and the CorrectiveAction type */
export type FieldIdToCorrectiveActionConnection = {
  __typename?: 'FieldIdToCorrectiveActionConnection'
  /** Edges for the FieldIdToCorrectiveActionConnection connection */
  edges?: Maybe<Array<Maybe<FieldIdToCorrectiveActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type FieldIdToCorrectiveActionConnectionEdge = {
  __typename?: 'FieldIdToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Arguments for filtering the FieldIdToCorrectiveActionConnection connection */
export type FieldIdToCorrectiveActionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<FieldIdToCorrectiveActionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<FieldIdToCorrectiveActionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type FieldIdToCorrectiveActionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<FieldIdToCorrectiveActionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<FieldIdToCorrectiveActionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum FieldIdToCorrectiveActionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type FieldIdToCorrectiveActionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<FieldIdToCorrectiveActionConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum FieldIdToCorrectiveActionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type FieldIdToCorrectiveActionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<FieldIdToCorrectiveActionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<FieldIdToCorrectiveActionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type FieldIdToCorrectiveActionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<FieldIdToCorrectiveActionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum FieldIdToCorrectiveActionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum FieldIdToCorrectiveActionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the FieldId type and the Photo type */
export type FieldIdToPhotoConnection = {
  __typename?: 'FieldIdToPhotoConnection'
  /** Edges for the FieldIdToPhotoConnection connection */
  edges?: Maybe<Array<Maybe<FieldIdToPhotoConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Photo>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type FieldIdToPhotoConnectionEdge = {
  __typename?: 'FieldIdToPhotoConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Photo>
}

/** Arguments for filtering the FieldIdToPhotoConnection connection */
export type FieldIdToPhotoConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<FieldIdToPhotoConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<FieldIdToPhotoConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type FieldIdToPhotoConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<FieldIdToPhotoConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<FieldIdToPhotoConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum FieldIdToPhotoConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type FieldIdToPhotoConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<FieldIdToPhotoConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum FieldIdToPhotoConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type FieldIdToPhotoConnectionWhereArgsTaxArray = {
  field?: InputMaybe<FieldIdToPhotoConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<FieldIdToPhotoConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type FieldIdToPhotoConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<FieldIdToPhotoConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum FieldIdToPhotoConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum FieldIdToPhotoConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the FieldId type and the Taxonomy type */
export type FieldIdToTaxonomyConnectionEdge = {
  __typename?: 'FieldIdToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** A Gravity Forms   field. */
export type FileUploadField = FormField & {
  __typename?: 'FileUploadField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** A comma-delimited list of the file extensions which may be uploaded. */
  allowedExtensions?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Indicates whether multiple files may be uploaded. */
  canAcceptMultipleFiles?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** File upload value */
  fileUploadValues?: Maybe<Array<Maybe<FileUploadFieldValue>>>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The maximum size (in MB) an uploaded file may be . */
  maxFileSize?: Maybe<Scalars['Int']['output']>
  /** When the field is set to allow multiple files to be uploaded, this property is available to set a limit on how many may be uploaded. */
  maxFiles?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /**
   * An array of field values.
   * @deprecated Use `fileUploadValues` instead.
   */
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** The individual file properties from an uploaded file. */
export type FileUploadFieldValue = {
  __typename?: 'FileUploadFieldValue'
  /** The path to the parent directory of the file. */
  basePath?: Maybe<Scalars['String']['output']>
  /** The base url to the parent directory of the file. */
  baseUrl?: Maybe<Scalars['String']['output']>
  /** The filename. */
  filename?: Maybe<Scalars['String']['output']>
  /** The url to the file. */
  url?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type FollowUpField = FormField & {
  __typename?: 'FollowUpField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** Content of an HTML block field to be displayed on the form. */
  content?: Maybe<Scalars['String']['output']>
  /** Data to populate Corrective Actions with when this field is deficient */
  correctiveActionSettings?: Maybe<Array<Maybe<CorrectiveActionSettings>>>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Form to be used for follow-up. */
  followUpForm?: Maybe<GfForm>
  /** ID of the form to be used for follow-up. */
  followUpFormId?: Maybe<Scalars['Int']['output']>
  /** Indicates whether the default margins are turned on to align the HTML content with other fields. */
  hasMargins?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Gravity Forms button. */
export type FormButton = {
  __typename?: 'FormButton'
  /** Controls when the form button should be visible based on values selected on the form. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** Contains the URL for the image button. Only applicable when type is set to image. */
  imageUrl?: Maybe<Scalars['String']['output']>
  /** Contains the button text. Only applicable when type is set to text. */
  text?: Maybe<Scalars['String']['output']>
  /** Specifies the type of button to be displayed. Defaults to TEXT. */
  type?: Maybe<FormButtonTypeEnum>
}

/** Type of button to be displayed. Default is TEXT. */
export enum FormButtonTypeEnum {
  /** Image button. */
  Image = 'IMAGE',
  /** Text button (default). */
  Text = 'TEXT',
}

/** Properties for all the email notifications which exist for a form. */
export type FormConfirmation = {
  __typename?: 'FormConfirmation'
  /** Controls which form confirmation message should be displayed. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** ID. */
  id?: Maybe<Scalars['String']['output']>
  /** Whether the confirmation is active or inactive. The default confirmation is always active. */
  isActive?: Maybe<Scalars['Boolean']['output']>
  /** Whether the confirmation message should be formatted so that paragraphs are automatically added for new lines. */
  isAutoformatted?: Maybe<Scalars['Boolean']['output']>
  /** Whether this is the default confirmation. */
  isDefault?: Maybe<Scalars['Boolean']['output']>
  /** Contains the confirmation message that will be displayed. Only applicable when type is set to &quot;MESSAGE&quot;. */
  message?: Maybe<Scalars['String']['output']>
  /** The confirmation name. */
  name?: Maybe<Scalars['String']['output']>
  /** Connection between the FormConfirmation type and the Page type */
  page?: Maybe<FormConfirmationToPageConnectionEdge>
  /** Contains the Id of the WordPress page that the browser will be redirected to. Only applicable when type is set to `PAGE`. */
  pageId?: Maybe<Scalars['Int']['output']>
  /** Contains the query string to be appended to the redirection url. Only applicable when type is set to `REDIRECT`. */
  queryString?: Maybe<Scalars['String']['output']>
  /** Determines the type of confirmation to be used. */
  type?: Maybe<FormConfirmationTypeEnum>
  /** Contains the URL that the browser will be redirected to. Only applicable when type is set to `REDIRECT`. */
  url?: Maybe<Scalars['String']['output']>
}

/** Connection between the FormConfirmation type and the Page type */
export type FormConfirmationToPageConnectionEdge = {
  __typename?: 'FormConfirmationToPageConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Page>
}

/** Type of form confirmation to be used. */
export enum FormConfirmationTypeEnum {
  /** Use a confirmation "message". */
  Message = 'MESSAGE',
  /** Use a redirect to a different WordPress "page". */
  Page = 'PAGE',
  /** Use a "redirect" to a given URL. */
  Redirect = 'REDIRECT',
}

/** The policies governing which entry data to include when erasing and exporting personal data. */
export type FormDataPolicies = {
  __typename?: 'FormDataPolicies'
  /** Whether entry data from this form is included when erasing and exporting personal data. */
  canExportAndErase?: Maybe<Scalars['Boolean']['output']>
  /** The individual entry data exporting and erasing policies. */
  entryData?: Maybe<Array<Maybe<FormEntryDataPolicy>>>
  /** The database ID of the Gravity Forms field used to identify the user. */
  identificationFieldDatabaseId?: Maybe<Scalars['Int']['output']>
}

/** Determines where the field description is displayed relative to the field. */
export enum FormDescriptionPlacementEnum {
  /** The field description is displayed above the field input (i.e. immediately after the field label). */
  Above = 'ABOVE',
  /** The field description is displayed below the field input. */
  Below = 'BELOW',
}

/** The individual entry data exporting and erasing policies. */
export type FormEntryDataPolicy = {
  __typename?: 'FormEntryDataPolicy'
  /** The array key for the Gravity Forms Entry. */
  key?: Maybe<Scalars['String']['output']>
  /** Whether this field should be included when erasing personal data. */
  shouldErase?: Maybe<Scalars['Boolean']['output']>
  /** Whether this field should be included when exporting personal data. */
  shouldExport?: Maybe<Scalars['Boolean']['output']>
}

/** Gravity Forms form entry limititation details. */
export type FormEntryLimits = {
  __typename?: 'FormEntryLimits'
  /** Whether the form has a limit on the number of submissions. */
  hasLimit?: Maybe<Scalars['Boolean']['output']>
  /** Message that will be displayed when the maximum number of submissions have been reached. */
  limitReachedMessage?: Maybe<Scalars['String']['output']>
  /** The time period during which submissions are allowed. */
  limitationPeriod?: Maybe<FormLimitEntriesPeriodEnum>
  /** The number of submissions allowed. */
  maxEntries?: Maybe<Scalars['Int']['output']>
}

/** Gravity Forms field. */
export type FormField = {
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** How the date field displays its calendar icon. */
export enum FormFieldCalendarIconTypeEnum {
  /** Default calendar icon. */
  Calendar = 'CALENDAR',
  /** Custom calendar icon. */
  Custom = 'CUSTOM',
  /** No calendar icon. */
  None = 'NONE',
}

/** The form field-specifc policies for exporting and erasing personal data. */
export type FormFieldDataPolicy = {
  __typename?: 'FormFieldDataPolicy'
  /** Whether this field is used to identify the user&#039;s personal data. */
  isIdentificationField?: Maybe<Scalars['Boolean']['output']>
  /** Whether this field should be included when erasing personal data. */
  shouldErase?: Maybe<Scalars['Boolean']['output']>
  /** Whether this field should be included when exporting personal data. */
  shouldExport?: Maybe<Scalars['Boolean']['output']>
}

/** Determines where the field description is displayed relative to the field. */
export enum FormFieldDescriptionPlacementEnum {
  /** The field description is displayed above the field input (i.e. immediately after the field label). */
  Above = 'ABOVE',
  /** The field description is displayed below the field input. */
  Below = 'BELOW',
  /** The field description is inherited from the form default settings. */
  Inherit = 'INHERIT',
}

/** The field label position. Empty when using the form defaults or a value of "hidden_label". */
export enum FormFieldLabelPlacementEnum {
  /** Field label is hidden. */
  Hidden = 'HIDDEN',
  /** Field label is inherited from the form defaults. */
  Inherit = 'INHERIT',
  /** Field label is displayed beside the fields and aligned to the left. */
  Left = 'LEFT',
  /** Field label is displayed beside the fields and aligned to the right. */
  Right = 'RIGHT',
  /** Field label is displayed on top of the fields. */
  Top = 'TOP',
}

/** Type of indicator to use when field is required. */
export enum FormFieldRequiredIndicatorEnum {
  /** Asterisk (*) indicator. */
  Asterisk = 'ASTERISK',
  /** Custom indicator. */
  Custom = 'CUSTOM',
  /** Text (Required) indicator (default). */
  Text = 'TEXT',
}

/** The size of the field when displayed on the page. */
export enum FormFieldSizeEnum {
  /** Large field size. */
  Large = 'LARGE',
  /** Medium field size. */
  Medium = 'MEDIUM',
  /** Small field size. */
  Small = 'SMALL',
}

/** Determines how sub-labels are aligned. */
export enum FormFieldSubLabelPlacementEnum {
  /** The sub-label is displayed above the sub-field input (i.e. immediately after the field label). */
  Above = 'ABOVE',
  /** The sub-label is displayed below the sub-field input. */
  Below = 'BELOW',
  /** Field label is inherited from the form defaults. */
  Inherit = 'INHERIT',
}

/** Gravity Forms Field Type. */
export enum FormFieldTypeEnum {
  /** A Gravity Forms address field. */
  Address = 'ADDRESS',
  /** A Gravity Forms calculation field. */
  Calculation = 'CALCULATION',
  /** A Gravity Forms captcha field. */
  Captcha = 'CAPTCHA',
  /** A Gravity Forms checkbox field. */
  Checkbox = 'CHECKBOX',
  /** A Gravity Forms combo_signature field. */
  ComboSignature = 'COMBO_SIGNATURE',
  /** A Gravity Forms consent field. */
  Consent = 'CONSENT',
  /** A Gravity Forms date field. */
  Date = 'DATE',
  /** A Gravity Forms email field. */
  Email = 'EMAIL',
  /** A Gravity Forms fileupload field. */
  Fileupload = 'FILEUPLOAD',
  /** A Gravity Forms followup field. */
  Followup = 'FOLLOWUP',
  /** A Gravity Forms hidden field. */
  Hidden = 'HIDDEN',
  /** A Gravity Forms hiddenproduct field. */
  Hiddenproduct = 'HIDDENPRODUCT',
  /** A Gravity Forms html field. */
  Html = 'HTML',
  /** A Gravity Forms list field. */
  List = 'LIST',
  /** A Gravity Forms multiselect field. */
  Multiselect = 'MULTISELECT',
  /** A Gravity Forms name field. */
  Name = 'NAME',
  /** A Gravity Forms number field. */
  Number = 'NUMBER',
  /** A Gravity Forms page field. */
  Page = 'PAGE',
  /** A Gravity Forms password field. */
  Password = 'PASSWORD',
  /** A Gravity Forms phone field. */
  Phone = 'PHONE',
  /** A Gravity Forms photo field. */
  Photo = 'PHOTO',
  /** A Gravity Forms post_category field. */
  PostCategory = 'POST_CATEGORY',
  /** A Gravity Forms post_content field. */
  PostContent = 'POST_CONTENT',
  /** A Gravity Forms post_custom_field field. */
  PostCustomField = 'POST_CUSTOM_FIELD',
  /** A Gravity Forms post_excerpt field. */
  PostExcerpt = 'POST_EXCERPT',
  /** A Gravity Forms post_image field. */
  PostImage = 'POST_IMAGE',
  /** A Gravity Forms post_tags field. */
  PostTags = 'POST_TAGS',
  /** A Gravity Forms post_title field. */
  PostTitle = 'POST_TITLE',
  /** A Gravity Forms quiz field. */
  Quiz = 'QUIZ',
  /** A Gravity Forms radio field. */
  Radio = 'RADIO',
  /** A Gravity Forms remember_me field. */
  RememberMe = 'REMEMBER_ME',
  /** A Gravity Forms section field. */
  Section = 'SECTION',
  /** A Gravity Forms select field. */
  Select = 'SELECT',
  /** A Gravity Forms signature field. */
  Signature = 'SIGNATURE',
  /** A Gravity Forms singleproduct field. */
  Singleproduct = 'SINGLEPRODUCT',
  /** A Gravity Forms singleshipping field. */
  Singleshipping = 'SINGLESHIPPING',
  /** A Gravity Forms submit field. */
  Submit = 'SUBMIT',
  /** A Gravity Forms text field. */
  Text = 'TEXT',
  /** A Gravity Forms textarea field. */
  Textarea = 'TEXTAREA',
  /** A Gravity Forms time field. */
  Time = 'TIME',
  /** A Gravity Forms username field. */
  Username = 'USERNAME',
  /** A Gravity Forms website field. */
  Website = 'WEBSITE',
}

/** Field values input. Includes a field id, and a valid value Input. */
export type FormFieldValuesInput = {
  /** The form field values for Address fields. */
  addressValues?: InputMaybe<AddressFieldInput>
  /** The form field values for Checkbox fields. */
  checkboxValues?: InputMaybe<Array<InputMaybe<CheckboxFieldInput>>>
  /** The form field values for Email fields. */
  emailValues?: InputMaybe<EmailFieldInput>
  /** The form field values for file upload fields. */
  fileUploadValues?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
  /** The field id. */
  id: Scalars['Int']['input']
  /** The form field values for post image fields. */
  imageValues?: InputMaybe<ImageInput>
  /** The form field values for List fields. */
  listValues?: InputMaybe<Array<InputMaybe<ListFieldInput>>>
  /** The form field values for Name fields. */
  nameValues?: InputMaybe<NameFieldInput>
  /** The form field values for Photo fields. */
  photoValues?: InputMaybe<Array<InputMaybe<PhotoValuesInput>>>
  /** The form field values for Combo Signature fields. */
  signatureValues?: InputMaybe<Array<InputMaybe<ComboSignatureInput>>>
  /** The form field values for basic fields. */
  value?: InputMaybe<Scalars['String']['input']>
  /** The form field values for fields that accept multiple string values. Used by MultiSelect, Post Category, Post Custom, and Post Tags fields. */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Field visibility. */
export enum FormFieldVisibilityEnum {
  /** The field is for "administrative" use. */
  Administrative = 'ADMINISTRATIVE',
  /** The field is "hidden". */
  Hidden = 'HIDDEN',
  /** The field is "visible". */
  Visible = 'VISIBLE',
}

/** Form Group fields */
export type FormGroup = {
  __typename?: 'FormGroup'
  /** Form Group Header */
  header?: Maybe<Scalars['String']['output']>
  /** Header ordering */
  order?: Maybe<Scalars['Int']['output']>
  translations?: Maybe<Array<Maybe<FormGroupTranslation>>>
}

/** Form Group Translation fields */
export type FormGroupTranslation = {
  __typename?: 'FormGroupTranslation'
  code?: Maybe<LanguageCodeEnum>
  header?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
  slug?: Maybe<Scalars['String']['output']>
}

/** The FormId type */
export type FormId = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'FormId'
    /** Connection between the FormId type and the ContentNode type */
    contentNodes?: Maybe<FormIdToContentNodeConnection>
    /** Connection between the FormId type and the CorrectiveAction type */
    correctiveActions?: Maybe<FormIdToCorrectiveActionConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    formIdId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** Connection between the FormId type and the Photo type */
    photos?: Maybe<FormIdToPhotoConnection>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the FormId type and the Taxonomy type */
    taxonomy?: Maybe<FormIdToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The FormId type */
export type FormIdContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FormIdToContentNodeConnectionWhereArgs>
}

/** The FormId type */
export type FormIdCorrectiveActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FormIdToCorrectiveActionConnectionWhereArgs>
}

/** The FormId type */
export type FormIdEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The FormId type */
export type FormIdEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The FormId type */
export type FormIdPhotosArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FormIdToPhotoConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum FormIdIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the FormId type and the ContentNode type */
export type FormIdToContentNodeConnection = {
  __typename?: 'FormIdToContentNodeConnection'
  /** Edges for the FormIdToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<FormIdToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type FormIdToContentNodeConnectionEdge = {
  __typename?: 'FormIdToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the FormIdToContentNodeConnection connection */
export type FormIdToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfFormIdEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<FormIdToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<FormIdToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type FormIdToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<FormIdToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<FormIdToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum FormIdToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type FormIdToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<FormIdToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum FormIdToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type FormIdToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<FormIdToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<FormIdToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type FormIdToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<FormIdToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum FormIdToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum FormIdToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the FormId type and the CorrectiveAction type */
export type FormIdToCorrectiveActionConnection = {
  __typename?: 'FormIdToCorrectiveActionConnection'
  /** Edges for the FormIdToCorrectiveActionConnection connection */
  edges?: Maybe<Array<Maybe<FormIdToCorrectiveActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type FormIdToCorrectiveActionConnectionEdge = {
  __typename?: 'FormIdToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Arguments for filtering the FormIdToCorrectiveActionConnection connection */
export type FormIdToCorrectiveActionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<FormIdToCorrectiveActionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<FormIdToCorrectiveActionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type FormIdToCorrectiveActionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<FormIdToCorrectiveActionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<FormIdToCorrectiveActionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum FormIdToCorrectiveActionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type FormIdToCorrectiveActionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<FormIdToCorrectiveActionConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum FormIdToCorrectiveActionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type FormIdToCorrectiveActionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<FormIdToCorrectiveActionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<FormIdToCorrectiveActionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type FormIdToCorrectiveActionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<FormIdToCorrectiveActionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum FormIdToCorrectiveActionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum FormIdToCorrectiveActionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the FormId type and the Photo type */
export type FormIdToPhotoConnection = {
  __typename?: 'FormIdToPhotoConnection'
  /** Edges for the FormIdToPhotoConnection connection */
  edges?: Maybe<Array<Maybe<FormIdToPhotoConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Photo>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type FormIdToPhotoConnectionEdge = {
  __typename?: 'FormIdToPhotoConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Photo>
}

/** Arguments for filtering the FormIdToPhotoConnection connection */
export type FormIdToPhotoConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<FormIdToPhotoConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<FormIdToPhotoConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type FormIdToPhotoConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<FormIdToPhotoConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<FormIdToPhotoConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum FormIdToPhotoConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type FormIdToPhotoConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<FormIdToPhotoConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum FormIdToPhotoConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type FormIdToPhotoConnectionWhereArgsTaxArray = {
  field?: InputMaybe<FormIdToPhotoConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<FormIdToPhotoConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type FormIdToPhotoConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<FormIdToPhotoConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum FormIdToPhotoConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum FormIdToPhotoConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the FormId type and the Taxonomy type */
export type FormIdToTaxonomyConnectionEdge = {
  __typename?: 'FormIdToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** The Type of Identifier used to fetch a single resource. */
export enum FormIdTypeEnum {
  /** The database ID assigned by Gravity Forms. */
  DatabaseId = 'DATABASE_ID',
  /** Unique global ID for the object. */
  Id = 'ID',
}

/** Determines where the field labels should be placed in relation to the field. */
export enum FormLabelPlacementEnum {
  /** Field labels are displayed beside the fields and aligned to the left. */
  Left = 'LEFT',
  /** Field labels are displayed beside the fields and aligned to the right. */
  Right = 'RIGHT',
  /** Field labels are displayed on top of the fields. */
  Top = 'TOP',
}

/** Gravity Forms button. */
export type FormLastPageButton = {
  __typename?: 'FormLastPageButton'
  /** Contains the URL for the image button. Only applicable when type is set to image. */
  imageUrl?: Maybe<Scalars['String']['output']>
  /** Contains the button text. Only applicable when type is set to text. */
  text?: Maybe<Scalars['String']['output']>
  /** Specifies the type of button to be displayed. Defaults to TEXT. */
  type?: Maybe<FormButtonTypeEnum>
}

/** When limitEntries is set to 1, this property specifies the time period during which submissions are allowed. */
export enum FormLimitEntriesPeriodEnum {
  /** Limit entries by "day". */
  Day = 'DAY',
  /** Limit entries by "month". */
  Month = 'MONTH',
  /** Limit entries by "week". */
  Week = 'WEEK',
  /** Limit entries by "year". */
  Year = 'YEAR',
}

/** Gravity Forms form login requirements data. */
export type FormLogin = {
  __typename?: 'FormLogin'
  /** Whether the form is configured to be displayed only to logged in users. */
  isLoginRequired?: Maybe<Scalars['Boolean']['output']>
  /** When `isLoginRequired` is set to true, this controls the message displayed when non-logged in user tries to access the form. */
  loginRequiredMessage?: Maybe<Scalars['String']['output']>
}

/** Properties for all the email notifications which exist for a form. */
export type FormNotification = {
  __typename?: 'FormNotification'
  /** The email or merge tags to be used as the email bcc address. */
  bcc?: Maybe<Scalars['String']['output']>
  /** An associative array containing the conditional logic rules. See the Conditional Logic Object for more details. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** The notification event. Default is form_submission. */
  event?: Maybe<Scalars['String']['output']>
  /** The email or merge tag to be used as the email from address. */
  from?: Maybe<Scalars['String']['output']>
  /** The text or merge tag to be used as the email from name. */
  fromName?: Maybe<Scalars['String']['output']>
  /** The notification ID. A 13 character unique ID. */
  id?: Maybe<Scalars['String']['output']>
  /** Is the notification active or inactive. The default is true (active). */
  isActive?: Maybe<Scalars['Boolean']['output']>
  /** Whether the email message should be formatted so that paragraphs are automatically added for new lines. */
  isAutoformatted?: Maybe<Scalars['Boolean']['output']>
  /** The email body/content. Merge tags supported. */
  message?: Maybe<Scalars['String']['output']>
  /** The notification name. */
  name?: Maybe<Scalars['String']['output']>
  /** The email or merge tags to be used as the email reply to address. */
  replyTo?: Maybe<Scalars['String']['output']>
  /** Routing rules. */
  routing?: Maybe<Array<Maybe<FormNotificationRouting>>>
  /** The name of the service to be used when sending this notification. Default is wordpress. */
  service?: Maybe<Scalars['String']['output']>
  /** Determines if files uploaded on the form should be included when the notification is sent. */
  shouldSendAttachments?: Maybe<Scalars['Boolean']['output']>
  /** The email subject line. Merge tags supported. */
  subject?: Maybe<Scalars['String']['output']>
  /** The ID of an email field, an email address or merge tag to be used as the email to address. */
  to?: Maybe<Scalars['String']['output']>
  /** Identifies what to use for the notification &quot;to&quot;. */
  toType?: Maybe<FormNotificationToTypeEnum>
}

/** Properties for all the email notifications which exist for a form. */
export type FormNotificationRouting = {
  __typename?: 'FormNotificationRouting'
  /** The email or merge tag to be used as the email To address if this rule is a match. */
  email?: Maybe<Scalars['String']['output']>
  /** Target field ID. The field that will have it’s value compared with the value property to determine if this rule is a match. */
  fieldId?: Maybe<Scalars['Int']['output']>
  /** Operator to be used when evaluating this rule. */
  operator?: Maybe<FormRuleOperatorEnum>
  /** The value to compare with the field specified by fieldId. */
  value?: Maybe<Scalars['String']['output']>
}

/** What to use for the notification "to". */
export enum FormNotificationToTypeEnum {
  /** Email address. */
  Email = 'EMAIL',
  /** Form field. */
  Field = 'FIELD',
  /** Hidden. */
  Hidden = 'HIDDEN',
  /** Routing using conditional rules. */
  Routing = 'ROUTING',
}

/** Style of progress bar. */
export enum FormPageProgressStyleEnum {
  /** Blue progress bar style. */
  Blue = 'BLUE',
  /** Custom progress bar style. */
  Custom = 'CUSTOM',
  /** Green progress bar style. */
  Green = 'GREEN',
  /** Grey progress bar style. */
  Grey = 'GREY',
  /** Orange progress bar style. */
  Orange = 'ORANGE',
  /** Red progress bar style. */
  Red = 'RED',
}

/** Type of page progress indicator to be displayed. */
export enum FormPageProgressTypeEnum {
  /** Don't show a page progress indicator. */
  None = 'NONE',
  /** Show page progress indicator as a percentage. */
  Percentage = 'PERCENTAGE',
  /** Show page progress indicator as steps. */
  Steps = 'STEPS',
}

/** Gravity Forms form pagination data. */
export type FormPagination = {
  __typename?: 'FormPagination'
  /** Progress bar background color. Can be any CSS color value. Only applies when `style` is set to &quot;CUSTOM&quot;. */
  backgroundColor?: Maybe<Scalars['String']['output']>
  /** Progress bar text color. Can be any CSS color value. Only applies when `style` is set to &quot;CUSTOM&quot;. */
  color?: Maybe<Scalars['String']['output']>
  /** Whether the confirmation bar should be displayed with the confirmation text. */
  hasProgressbarOnConfirmation?: Maybe<Scalars['Boolean']['output']>
  /** Last page button data. */
  lastPageButton?: Maybe<FormLastPageButton>
  /** Names of the form&#039;s pages. */
  pageNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The confirmation text to display once the end of the progress bar has been reached. Only applies when `hasProgressbarOnConfirmation` is set to true. */
  progressbarCompletionText?: Maybe<Scalars['String']['output']>
  /** Style of progress bar. */
  style?: Maybe<FormPageProgressStyleEnum>
  /** Type of progress indicator. */
  type?: Maybe<FormPageProgressTypeEnum>
}

/** Gravity Forms form Personal Data settings. */
export type FormPersonalData = {
  __typename?: 'FormPersonalData'
  /** The policies governing which entry data to include when erasing and exporting personal data. */
  dataPolicies?: Maybe<FormDataPolicies>
  /** The number of days to retain entries. `null` if `retentionPolicy` is set to `RETAIN` entries indefinitely. */
  daysToRetain?: Maybe<Scalars['Int']['output']>
  /** The policy for retaining old entry data. */
  retentionPolicy?: Maybe<FormRetentionPolicyEnum>
  /** Whether the IP address should be saved to the form submission. */
  shouldSaveIP?: Maybe<Scalars['Boolean']['output']>
}

/** Gravity Forms form entry limititation details. */
export type FormPostCreation = {
  __typename?: 'FormPostCreation'
  /** When `useCurrentUserAsAuthor` is `false`, this contains the User object for the author. */
  author?: Maybe<User>
  /** When `useCurrentUserAsAuthor` is `false`, this property contains the user database that will be used as the Post author. */
  authorDatabaseId?: Maybe<Scalars['Int']['output']>
  /** When `useCurrentUserAsAuthor` is `false`, this property contains the user ID that will be used as the Post author. */
  authorId?: Maybe<Scalars['ID']['output']>
  /** Form forms with Post fields, but without a Post Category field, this property contains the default category database ID the post will be associated with when created. */
  categoryDatabaseId?: Maybe<Scalars['Int']['output']>
  /** Template to be used when creating the post content. Field variables (i.e. {Name:3} ) can be added to the template to insert user submitted values into the post content. Only applicable when `hasContentTemplate` is `true`. */
  contentTemplate?: Maybe<Scalars['String']['output']>
  /** Determines the format that the Post should be created with. */
  format?: Maybe<PostFormatTypeEnum>
  /** Determines if the post template functionality is enabled. When enabled, the post content will be created based on the template specified by `contentTemplate`. */
  hasContentTemplate?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the post title template functionality is enabled. When enabled, the post title will be created based on the template specified by `titleTemplate`. */
  hasTitleTemplate?: Maybe<Scalars['Boolean']['output']>
  /** For forms with Post fields, this determines if the post should be created using the current logged in user as the author. */
  shouldUseCurrentUserAsAuthor?: Maybe<Scalars['Boolean']['output']>
  /** For forms with Post fields, determines the status that the Post should be created with. */
  status?: Maybe<Scalars['String']['output']>
  /** Template to be used when creating the post title. Field variables (i.e. {Name:3} ) can be added to the template to insert user submitted values into the post title. Only applicable when `hasTitleTemplate` is `true`. */
  titleTemplate?: Maybe<Scalars['String']['output']>
}

/** Quiz-specific settings that will affect ALL Quiz fields in the form. */
export type FormQuiz = {
  __typename?: 'FormQuiz'
  /** The message to display if the quiz grade is below the Pass Percentage. Only used if grading is set to `PASSFAIL`. */
  failConfirmation?: Maybe<FormQuizConfirmation>
  /** The letter grades to be assigned based on the percentage score achieved. Only used if `grading` is set to `LETTER`. */
  grades?: Maybe<Array<Maybe<FormQuizGrades>>>
  /** The quiz grading type. Defaults to `NONE`. */
  gradingType?: Maybe<QuizFieldGradingTypeEnum>
  /** Display correct or incorrect indicator and explanation (if any) immediately after answer selection. This setting only applies to radio button quiz fields and it is intended for training applications and trivial quizzes. It should not be considered a secure option for critical testing requirements. */
  hasInstantFeedback?: Maybe<Scalars['Boolean']['output']>
  /** Whether to display a confirmation message upon submission of the quiz form. Only used if `grading` is set to `LETTER`. */
  hasLetterConfirmationMessage?: Maybe<Scalars['Boolean']['output']>
  /** Whether to display a confirmation message upon submission of the quiz form. Only used if grading is set to `PASSFAIL`. */
  hasPassFailConfirmationMessage?: Maybe<Scalars['Boolean']['output']>
  /** Randomize the order of the quiz fields on this form each time the form is loaded. */
  isShuffleFieldsEnabled?: Maybe<Scalars['Boolean']['output']>
  /** The confirmation message to display if `grading` is set to `LETTER`. */
  letterConfirmation?: Maybe<FormQuizConfirmation>
  /** The maximum score for this form. */
  maxScore?: Maybe<Scalars['Float']['output']>
  /** The message to display if the quiz grade is above or equal to the Pass Percentage. Only used if grading is set to `PASSFAIL`. */
  passConfirmation?: Maybe<FormQuizConfirmation>
  /** The percentage score the user must equal or exceed to be considered to have &#039;passed.&#039; Only used if `grading` is set to `PASSFAIL`. */
  passPercent?: Maybe<Scalars['Int']['output']>
}

/** The Quiz Confirmation message data. */
export type FormQuizConfirmation = {
  __typename?: 'FormQuizConfirmation'
  /** Whether autoformatting is enabled for the confirmation message. */
  isAutoformatted?: Maybe<Scalars['Boolean']['output']>
  /** The message to display. */
  message?: Maybe<Scalars['String']['output']>
}

/** The letter grades to be assigned based on the percentage score achieved. Only used if `grading` is set to `LETTER`. */
export type FormQuizGrades = {
  __typename?: 'FormQuizGrades'
  /** The grade label. */
  text?: Maybe<Scalars['String']['output']>
  /** The minimum percentage score required to achieve this grade. */
  value?: Maybe<Scalars['Int']['output']>
}

/** The Personal Data retention policy. */
export enum FormRetentionPolicyEnum {
  /** Entries will be deleted automatically after a specified number of days. */
  Delete = 'DELETE',
  /** Entries will be retain indefinitely. */
  Retain = 'RETAIN',
  /** Entries will be trashed automatically after a specified number of days. */
  Trash = 'TRASH',
}

/** Operator to be used when evaluating logic rules. */
export enum FormRuleOperatorEnum {
  /** Evaluates values that CONTAIN the comparison value. */
  Contains = 'CONTAINS',
  /** Evaluates values that END with the comparison value. */
  EndsWith = 'ENDS_WITH',
  /** Evaluates values that are GREATER than the comparison value. */
  GreaterThan = 'GREATER_THAN',
  /** Evaluates values that match the comparison value. */
  Is = 'IS',
  /** Evaluates values that do NOT match the comparison value. */
  IsNot = 'IS_NOT',
  /** Evaluates values that are LESS than the comparison value. */
  LessThan = 'LESS_THAN',
  /** Evaluates values that START with the comparison value. */
  StartsWith = 'STARTS_WITH',
}

/** Gravity Forms form Save and Continue data. */
export type FormSaveAndContinue = {
  __typename?: 'FormSaveAndContinue'
  /** Contains the save button text. */
  buttonText?: Maybe<Scalars['String']['output']>
  /** Whether the Save And Continue feature is enabled. */
  hasSaveAndContinue?: Maybe<Scalars['Boolean']['output']>
}

/** Gravity Forms form scheduling data. */
export type FormSchedule = {
  __typename?: 'FormSchedule'
  /** Message to be displayed when form is no longer available. */
  closedMessage?: Maybe<Scalars['String']['output']>
  /** The Date/time details when the form will become inactive. */
  endDetails?: Maybe<FormScheduleDetails>
  /** Specifies if this form is scheduled to be displayed only during a certain configured date/time. */
  hasSchedule?: Maybe<Scalars['Boolean']['output']>
  /** Message to be displayed when form is not yet available. */
  pendingMessage?: Maybe<Scalars['String']['output']>
  /** The Date/time details when the form will become active/visible. */
  startDetails?: Maybe<FormScheduleDetails>
}

/** Gravity Forms form scheduling data. */
export type FormScheduleDetails = {
  __typename?: 'FormScheduleDetails'
  /** Whether the date is in the AM or PM of a 12-hour clock. */
  amPm?: Maybe<AmPmEnum>
  /** The schedule date in local time. */
  date?: Maybe<Scalars['String']['output']>
  /** The schedule date in GMT. */
  dateGmt?: Maybe<Scalars['String']['output']>
  /** The hour (1-12). */
  hour?: Maybe<Scalars['Int']['output']>
  /** The minute. */
  minute?: Maybe<Scalars['Int']['output']>
}

/** Status of forms to get. Default is ACTIVE. */
export enum FormStatusEnum {
  /** Active forms (default). */
  Active = 'ACTIVE',
  /** Inactive forms. */
  Inactive = 'INACTIVE',
  /** Inactive forms in the trash. */
  InactiveTrashed = 'INACTIVE_TRASHED',
  /** Active forms in the trash. */
  Trashed = 'TRASHED',
}

/** Determines how sub-labels are aligned. */
export enum FormSubLabelPlacementEnum {
  /** The sub-label is displayed above the sub-field input (i.e. immediately after the field label). */
  Above = 'ABOVE',
  /** The sub-label is displayed below the sub-field input. */
  Below = 'BELOW',
}

/** Gravity Forms submit button. */
export type FormSubmitButton = {
  __typename?: 'FormSubmitButton'
  /** Controls when the form button should be visible based on values selected on the form. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** Contains the URL for the image button. Only applicable when type is set to image. */
  imageUrl?: Maybe<Scalars['String']['output']>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Where the submit button should be located. */
  location?: Maybe<FormSubmitButtonLocationEnum>
  /** Contains the button text. Only applicable when type is set to text. */
  text?: Maybe<Scalars['String']['output']>
  /** Specifies the type of button to be displayed. Defaults to TEXT. */
  type?: Maybe<FormButtonTypeEnum>
  /** The width of the submit button element. */
  width?: Maybe<FormSubmitButtonWidthEnum>
}

/** Where the submit button should be located. */
export enum FormSubmitButtonLocationEnum {
  /** The submit button will be placed in a new row after all fields of the form. */
  Bottom = 'BOTTOM',
  /** The submit button will be placed on the last row of the form where it will fill the remaining space left by field columns. */
  Inline = 'INLINE',
}

/** Submit button width. */
export enum FormSubmitButtonWidthEnum {
  /** The width is set to match that of the button text. */
  Auto = 'AUTO',
  /** The width is set to fill 100% of the container. */
  Full = 'FULL',
}

/** Gravity Forms translation settings */
export type FormTranslation = {
  __typename?: 'FormTranslation'
  /** Is this an active translation to make form pages for. */
  active?: Maybe<Scalars['Boolean']['output']>
  /** Entries List page Card Display Detail Labels */
  cardDisplay?: Maybe<CardDisplayTranslation>
  /** Clear button text */
  clearButtonText?: Maybe<Scalars['String']['output']>
  /** Form Description */
  description?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Next button text */
  nextButtonText?: Maybe<Scalars['String']['output']>
  /** Previous button text */
  previousButtonText?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
  /** Submit button text */
  submitButtonText?: Maybe<Scalars['String']['output']>
  /** Form Title */
  title?: Maybe<Scalars['String']['output']>
}

/** Options for ordering the connection. */
export type FormsConnectionOrderbyInput = {
  /** The field name used to sort the results. */
  field?: InputMaybe<Scalars['String']['input']>
  /** The cardinality of the order of the connection. */
  order?: InputMaybe<OrderEnum>
}

/** Gatsby Preview webhook data. */
export type GatsbyPreviewData = {
  __typename?: 'GatsbyPreviewData'
  /** The Relay id of the previewed node. */
  id?: Maybe<Scalars['ID']['output']>
  /** Wether or not the preview is a draft. */
  isDraft?: Maybe<Scalars['Boolean']['output']>
  /** A list of manifest ID&#039;s a preview action has seen during it&#039;s lifetime. */
  manifestIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The modified time of the previewed node. */
  modified?: Maybe<Scalars['String']['output']>
  /** The WordPress database ID of the preview. If this is a draft it will potentially return 0, if it&#039;s a revision of a post, it will return the ID of the original post that this is a revision of. */
  parentDatabaseId?: Maybe<Scalars['Int']['output']>
  /** The WordPress database ID of the preview. Could be a revision or draft ID. */
  previewDatabaseId?: Maybe<Scalars['Int']['output']>
  /** The WP url at the time of the preview. */
  remoteUrl?: Maybe<Scalars['String']['output']>
  /** The GraphQL single field name for the type of the preview. */
  singleName?: Maybe<Scalars['String']['output']>
  /** The database ID of the user who made the original preview. */
  userDatabaseId?: Maybe<Scalars['Int']['output']>
}

/** The general setting type */
export type GeneralSettings = {
  __typename?: 'GeneralSettings'
  /** A date format for all date strings. */
  dateFormat?: Maybe<Scalars['String']['output']>
  /** Site tagline. */
  description?: Maybe<Scalars['String']['output']>
  /** WordPress locale code. */
  language?: Maybe<Scalars['String']['output']>
  /** A day number of the week that the week should start on. */
  startOfWeek?: Maybe<Scalars['Int']['output']>
  /** A time format for all time strings. */
  timeFormat?: Maybe<Scalars['String']['output']>
  /** A city in the same timezone as you. */
  timezone?: Maybe<Scalars['String']['output']>
  /** Site title. */
  title?: Maybe<Scalars['String']['output']>
  /** Site URL. */
  url?: Maybe<Scalars['String']['output']>
}

/** Image geolocation values. */
export type Geolocation = {
  __typename?: 'Geolocation'
  /** Latitude coordinate. */
  lat?: Maybe<Scalars['Float']['output']>
  /** Longitude coordinate. */
  lng?: Maybe<Scalars['Float']['output']>
}

/** Currencies supported by Gravity Forms. */
export enum GfCurrencyEnum {
  /** Australian Dollar . */
  Aud = 'AUD',
  /** Brazilian Real . */
  Brl = 'BRL',
  /** Canadian Dollar . */
  Cad = 'CAD',
  /** Swiss Franc . */
  Chf = 'CHF',
  /** Czech Koruna . */
  Czk = 'CZK',
  /** Danish Krone . */
  Dkk = 'DKK',
  /** Euro . */
  Eur = 'EUR',
  /** Pound Sterling . */
  Gbp = 'GBP',
  /** Hong Kong Dollar . */
  Hkd = 'HKD',
  /** Hungarian Forint . */
  Huf = 'HUF',
  /** Israeli New Sheqel . */
  Ils = 'ILS',
  /** Japanese Yen . */
  Jpy = 'JPY',
  /** Mexican Peso . */
  Mxn = 'MXN',
  /** Malaysian Ringgit . */
  Myr = 'MYR',
  /** Norwegian Krone . */
  Nok = 'NOK',
  /** New Zealand Dollar . */
  Nzd = 'NZD',
  /** Philippine Peso . */
  Php = 'PHP',
  /** Polish Zloty . */
  Pln = 'PLN',
  /** Russian Ruble . */
  Rub = 'RUB',
  /** Swedish Krona . */
  Sek = 'SEK',
  /** Singapore Dollar . */
  Sgd = 'SGD',
  /** Thai Baht . */
  Thb = 'THB',
  /** Taiwan New Dollar . */
  Twd = 'TWD',
  /** U.S. Dollar . */
  Usd = 'USD',
  /** South African Rand . */
  Zar = 'ZAR',
}

/** A Gravity Forms draft entry. */
export type GfDraftEntry = GfEntry &
  Node &
  NodeWithForm & {
    __typename?: 'GfDraftEntry'
    /** Details to be displayed on list cards */
    cardDetails?: Maybe<CardDetails>
    /** Connection between the GfEntry type and the Company type */
    companies?: Maybe<GfEntryToCompanyConnection>
    /** The user who created the entry. */
    createdBy?: Maybe<User>
    /** Database ID of the user that submitted of the form if a logged in user submitted the form. */
    createdByDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Global ID of the user that submitted of the form if a logged in user submitted the form. */
    createdById?: Maybe<Scalars['ID']['output']>
    /** The date and time that the entry was created in local time. */
    dateCreated?: Maybe<Scalars['String']['output']>
    /** The date and time that the entry was created in GMT. */
    dateCreatedGmt?: Maybe<Scalars['String']['output']>
    /** The date and time that the entry was created in local time. */
    dateUpdated?: Maybe<Scalars['String']['output']>
    /** The date and time that the entry was updated in GMT. */
    dateUpdatedGmt?: Maybe<Scalars['String']['output']>
    /** Connection between the GfEntry type and the Division type */
    divisions?: Maybe<GfEntryToDivisionConnection>
    /** The form object of the node. */
    form?: Maybe<GfForm>
    /** The database identifier of the form of the node. */
    formDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Connection between the GfEntry type and the FormField type */
    formFields?: Maybe<GfEntryToFormFieldConnection>
    /** The globally unique identifier of the form of the node. */
    formId?: Maybe<Scalars['ID']['output']>
    /** List of Gravity Pdf Template name and ids, which are used in the creation of pdfs. */
    gravityPdfTemplates?: Maybe<Array<Maybe<GravityPdfEntryTemplate>>>
    /** The globally unique ID for the object */
    id: Scalars['ID']['output']
    /** Client IP of user who submitted the form. */
    ip?: Maybe<Scalars['String']['output']>
    /** Whether the entry is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>
    /** Whether the entry has been submitted. */
    isSubmitted?: Maybe<Scalars['Boolean']['output']>
    /** Connection between the GfEntry type and the Project type */
    project?: Maybe<GfEntryToProjectConnectionEdge>
    /** The resume token. Only applies to draft entries. */
    resumeToken?: Maybe<Scalars['String']['output']>
    /** Source URL of page that contained the form when it was submitted. */
    sourceUrl?: Maybe<Scalars['String']['output']>
    /** Provides the name and version of both the browser and operating system from which the entry was submitted. */
    userAgent?: Maybe<Scalars['String']['output']>
  }

/** A Gravity Forms draft entry. */
export type GfDraftEntryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A Gravity Forms draft entry. */
export type GfDraftEntryDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A Gravity Forms draft entry. */
export type GfDraftEntryFormFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<GfEntryToFormFieldConnectionWhereArgs>
}

/** Gravity Forms entry interface. */
export type GfEntry = {
  /** Details to be displayed on list cards */
  cardDetails?: Maybe<CardDetails>
  /** Connection between the GfEntry type and the Company type */
  companies?: Maybe<GfEntryToCompanyConnection>
  /** The user who created the entry. */
  createdBy?: Maybe<User>
  /** Database ID of the user that submitted of the form if a logged in user submitted the form. */
  createdByDatabaseId?: Maybe<Scalars['Int']['output']>
  /** Global ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: Maybe<Scalars['ID']['output']>
  /** The date and time that the entry was created in local time. */
  dateCreated?: Maybe<Scalars['String']['output']>
  /** The date and time that the entry was created in GMT. */
  dateCreatedGmt?: Maybe<Scalars['String']['output']>
  /** The date and time that the entry was created in local time. */
  dateUpdated?: Maybe<Scalars['String']['output']>
  /** The date and time that the entry was updated in GMT. */
  dateUpdatedGmt?: Maybe<Scalars['String']['output']>
  /** Connection between the GfEntry type and the Division type */
  divisions?: Maybe<GfEntryToDivisionConnection>
  /** The form object of the node. */
  form?: Maybe<GfForm>
  /** The database identifier of the form of the node. */
  formDatabaseId?: Maybe<Scalars['Int']['output']>
  /** Connection between the GfEntry type and the FormField type */
  formFields?: Maybe<GfEntryToFormFieldConnection>
  /** The globally unique identifier of the form of the node. */
  formId?: Maybe<Scalars['ID']['output']>
  /** List of Gravity Pdf Template name and ids, which are used in the creation of pdfs. */
  gravityPdfTemplates?: Maybe<Array<Maybe<GravityPdfEntryTemplate>>>
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
  /** Client IP of user who submitted the form. */
  ip?: Maybe<Scalars['String']['output']>
  /** Whether the entry is a draft. */
  isDraft?: Maybe<Scalars['Boolean']['output']>
  /** Whether the entry has been submitted. */
  isSubmitted?: Maybe<Scalars['Boolean']['output']>
  /** Connection between the GfEntry type and the Project type */
  project?: Maybe<GfEntryToProjectConnectionEdge>
  /** Source URL of page that contained the form when it was submitted. */
  sourceUrl?: Maybe<Scalars['String']['output']>
  /** Provides the name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: Maybe<Scalars['String']['output']>
}

/** Gravity Forms entry interface. */
export type GfEntryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Gravity Forms entry interface. */
export type GfEntryDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Gravity Forms entry interface. */
export type GfEntryFormFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<GfEntryToFormFieldConnectionWhereArgs>
}

/** Connection between the GfEntry type and the Company type */
export type GfEntryToCompanyConnection = {
  __typename?: 'GfEntryToCompanyConnection'
  /** Edges for the GfEntryToCompanyConnection connection */
  edges?: Maybe<Array<Maybe<GfEntryToCompanyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Company>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type GfEntryToCompanyConnectionEdge = {
  __typename?: 'GfEntryToCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Company>
}

/** Connection between the GfEntry type and the Division type */
export type GfEntryToDivisionConnection = {
  __typename?: 'GfEntryToDivisionConnection'
  /** Edges for the GfEntryToDivisionConnection connection */
  edges?: Maybe<Array<Maybe<GfEntryToDivisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Division>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type GfEntryToDivisionConnectionEdge = {
  __typename?: 'GfEntryToDivisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Division>
}

/** Connection between the GfEntry type and the FormField type */
export type GfEntryToFormFieldConnection = {
  __typename?: 'GfEntryToFormFieldConnection'
  /** Edges for the GfEntryToFormFieldConnection connection */
  edges?: Maybe<Array<Maybe<GfEntryToFormFieldConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<FormField>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type GfEntryToFormFieldConnectionEdge = {
  __typename?: 'GfEntryToFormFieldConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<FormField>
}

/** Arguments for filtering the GfEntryToFormFieldConnection connection */
export type GfEntryToFormFieldConnectionWhereArgs = {
  /** Array of form field adminLabels to return. */
  adminLabels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of Gravity Forms Field types to return. */
  fieldTypes?: InputMaybe<Array<InputMaybe<FormFieldTypeEnum>>>
  /** Array of form field IDs to return. */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The form page number to return. */
  pageNumber?: InputMaybe<Scalars['Int']['input']>
}

/** Connection between the GfEntry type and the Project type */
export type GfEntryToProjectConnectionEdge = {
  __typename?: 'GfEntryToProjectConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Project>
}

/** Gravity Forms form. */
export type GfForm = DatabaseIdentifier &
  Node & {
    __typename?: 'GfForm'
    /**
     * Contains the form button settings such as the button text or image button source.
     * @deprecated Use `submitButton` field instead
     */
    button?: Maybe<FormSubmitButton>
    /** Card display settings. */
    cardDisplaySettings?: Maybe<CardDisplaySettings>
    /** Contains the form confirmation settings such as confirmation text or redirect URL. */
    confirmations?: Maybe<Array<Maybe<FormConfirmation>>>
    /** String containing the custom CSS classes to be added to the &lt;form&gt; tag. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** The custom text to use to indicate a field is required. */
    customRequiredIndicator?: Maybe<Scalars['String']['output']>
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** The date and time that the entry was created in local time. */
    dateCreated?: Maybe<Scalars['String']['output']>
    /** The date and time that the entry was created in GMT. */
    dateCreatedGmt?: Maybe<Scalars['String']['output']>
    /** Form description. */
    description?: Maybe<Scalars['String']['output']>
    /** Determines if the field description is displayed above the field input (i.e. immediately after the field label) or below the field input. */
    descriptionPlacement?: Maybe<FormDescriptionPlacementEnum>
    /** Disable entry editing for this form */
    disableEditing?: Maybe<Scalars['Boolean']['output']>
    /** Connection between the GfForm type and the GfEntry type */
    entries?: Maybe<GfFormToGfEntryConnection>
    /** The entry limit settings. */
    entryLimits?: Maybe<FormEntryLimits>
    /** CSS class for the first page. */
    firstPageCssClass?: Maybe<Scalars['String']['output']>
    /** Connection between the GfForm type and the FormField type */
    formFields?: Maybe<GfFormToFormFieldConnection>
    /**
     * Form ID.
     * @deprecated Deprecated in favor of the databaseId field.
     */
    formId?: Maybe<Scalars['Int']['output']>
    /** Form grouping header shown in the form drop down. */
    group?: Maybe<Scalars['String']['output']>
    /** When enabled, conditional logic hide/show operation will be performed with a jQuery slide animation. Only applicable to forms with conditional logic. */
    hasConditionalLogicAnimation?: Maybe<Scalars['Boolean']['output']>
    /** Specifies if the form has the Honeypot spam-protection feature. */
    hasHoneypot?: Maybe<Scalars['Boolean']['output']>
    /** If enabled, will show a summary that lists form validation errors at the top of the form when a user attempts a failed submission. */
    hasValidationSummary?: Maybe<Scalars['Boolean']['output']>
    /** Whether the form should be hidden in frontend navigation controls. */
    hideInNav?: Maybe<Scalars['Boolean']['output']>
    /** Whether the Jump to Section Button should be hidden on the frontend for this form. */
    hideJumpToSection?: Maybe<Scalars['Boolean']['output']>
    /** The globally unique ID for the object */
    id: Scalars['ID']['output']
    /** Determines whether the form is active. */
    isActive?: Maybe<Scalars['Boolean']['output']>
    /** Is this a corrective action form */
    isCAForm?: Maybe<Scalars['Boolean']['output']>
    /** Is the Primary form to be first in dropdown selection. */
    isPrimary?: Maybe<Scalars['Boolean']['output']>
    /** Is this a quiz form */
    isQuizForm?: Maybe<Scalars['Boolean']['output']>
    /** Determines whether the form is in the trash. */
    isTrash?: Maybe<Scalars['Boolean']['output']>
    /** Determines where the field labels should be placed in relation to the field. */
    labelPlacement?: Maybe<FormLabelPlacementEnum>
    /**
     * Last page button data.
     * @deprecated Use `pagination.lastPageButton` instead
     */
    lastPageButton?: Maybe<FormLastPageButton>
    /** Login requirements data. */
    login?: Maybe<FormLogin>
    /** The Gravity Forms markup version. */
    markupVersion?: Maybe<Scalars['Int']['output']>
    /** The ID to assign to the next field that is added to the form. */
    nextFieldId?: Maybe<Scalars['Int']['output']>
    /** The properties for all the email notifications which exist for a form. */
    notifications?: Maybe<Array<Maybe<FormNotification>>>
    /** Pagination data. */
    pagination?: Maybe<FormPagination>
    /** Personal data settings. */
    personalData?: Maybe<FormPersonalData>
    /** Post creation data. */
    postCreation?: Maybe<FormPostCreation>
    /** The type of post to be automatically */
    postTypeToCreate?: Maybe<Scalars['String']['output']>
    /** Quiz-specific settings that will affect ALL Quiz fields in the form. Requires Gravity Forms Quiz addon. */
    quiz?: Maybe<FormQuiz>
    /** If true, form will reload upon successful submission instead of redirect to respective list page */
    reloadOnSubmit?: Maybe<Scalars['Boolean']['output']>
    /** Type of indicator to use when field is required. */
    requiredIndicator?: Maybe<FormFieldRequiredIndicatorEnum>
    /** List of the roles to restrict the form entry viewing to only those entries submitted / created by the current user */
    restrictEntriesToUserCreated?: Maybe<
      Array<Maybe<Scalars['String']['output']>>
    >
    /** List of the roles which should be restricted from form submission */
    restrictFormSubmissionByRole?: Maybe<
      Array<Maybe<Scalars['String']['output']>>
    >
    /** \&quot;Save and Continue\&quot; data. */
    saveAndContinue?: Maybe<FormSaveAndContinue>
    /** Form scheduling data. */
    scheduling?: Maybe<FormSchedule>
    /** The URL slug for the form. */
    slug?: Maybe<Scalars['String']['output']>
    /** How sub-labels are aligned. */
    subLabelPlacement?: Maybe<FormSubLabelPlacementEnum>
    /** Expose Submission Rules */
    submissionRules?: Maybe<Array<Maybe<SubmissionRule>>>
    /** Contains the form button settings such as the button text or image button source. */
    submitButton?: Maybe<FormSubmitButton>
    /** Form title. */
    title?: Maybe<Scalars['String']['output']>
    /** Form Level Translated values */
    translations?: Maybe<Array<Maybe<FormTranslation>>>
    /** The version of Gravity Forms used to create this form. */
    version?: Maybe<Scalars['String']['output']>
  }

/** Gravity Forms form. */
export type GfFormEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<GfFormToGfEntryConnectionWhereArgs>
}

/** Gravity Forms form. */
export type GfFormFormFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<GfFormToFormFieldConnectionWhereArgs>
}

/** Connection between the GfForm type and the FormField type */
export type GfFormToFormFieldConnection = {
  __typename?: 'GfFormToFormFieldConnection'
  /** Edges for the GfFormToFormFieldConnection connection */
  edges?: Maybe<Array<Maybe<GfFormToFormFieldConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<FormField>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type GfFormToFormFieldConnectionEdge = {
  __typename?: 'GfFormToFormFieldConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<FormField>
}

/** Arguments for filtering the GfFormToFormFieldConnection connection */
export type GfFormToFormFieldConnectionWhereArgs = {
  /** Array of form field adminLabels to return. */
  adminLabels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of Gravity Forms Field types to return. */
  fieldTypes?: InputMaybe<Array<InputMaybe<FormFieldTypeEnum>>>
  /** Array of form field IDs to return. */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The form page number to return. */
  pageNumber?: InputMaybe<Scalars['Int']['input']>
}

/** Connection between the GfForm type and the GfEntry type */
export type GfFormToGfEntryConnection = {
  __typename?: 'GfFormToGfEntryConnection'
  /** The number of (filtered) entries submitted to the form. */
  count?: Maybe<Scalars['Int']['output']>
  /** Edges for the GfFormToGfEntryConnection connection */
  edges?: Maybe<Array<Maybe<GfFormToGfEntryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<GfEntry>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
  /** The quiz results for the given form. */
  quizResults?: Maybe<GfQuizResults>
}

/** An edge in a connection */
export type GfFormToGfEntryConnectionEdge = {
  __typename?: 'GfFormToGfEntryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<GfEntry>
}

/** Arguments for filtering the GfFormToGfEntryConnection connection */
export type GfFormToGfEntryConnectionWhereArgs = {
  /** Date filters to apply. */
  dateFilters?: InputMaybe<EntriesDateFiltersInput>
  /** Field-specific filters to apply. */
  fieldFilters?: InputMaybe<Array<InputMaybe<EntriesFieldFiltersInput>>>
  /** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
  fieldFiltersMode?: InputMaybe<FieldFiltersModeEnum>
  /** How to sort the entries. */
  orderby?: InputMaybe<EntriesConnectionOrderbyInput>
  /** Entry status. Default is "ACTIVE". */
  status?: InputMaybe<EntryStatusEnum>
}

/** Gravity Forms Logging Settings. */
export type GfLogger = {
  __typename?: 'GfLogger'
  /** Whether the logger is enabled. */
  isEnabled?: Maybe<Scalars['Boolean']['output']>
  /** The name of the Gravity Forms logger. */
  name?: Maybe<Scalars['String']['output']>
}

/** The quiz results for all entries. */
export type GfQuizResults = {
  __typename?: 'GfQuizResults'
  /** Average percentage score as calculated across all entries received. */
  averagePercentage?: Maybe<Scalars['Float']['output']>
  /** Average score as calculated across all entries received. */
  averageScore?: Maybe<Scalars['Float']['output']>
  /** Quantity of all the entries received for this quiz. */
  entryCount?: Maybe<Scalars['Int']['output']>
  /** A list of fields and frequency of each answer provided. */
  fieldCounts?: Maybe<Array<Maybe<GfQuizResultsFieldCount>>>
  /** If using letter grades, will show the frequency of each letter grade across all entries received. */
  gradeCounts?: Maybe<Array<Maybe<GfQuizResultsGradeCount>>>
  /** The pass-fail rate for all the entries received for this quiz. */
  passRate?: Maybe<Scalars['Float']['output']>
  /** Displays a frequency bar chart showing the spread of each quiz score. */
  scoreCounts?: Maybe<Array<Maybe<GfQuizResultsScoreCount>>>
  /** The total sum of all entry scores. Useful for calculating custom result statistics. */
  sum?: Maybe<Scalars['Float']['output']>
}

/** The response counts for individual quiz fields. */
export type GfQuizResultsChoiceCount = {
  __typename?: 'GfQuizResultsChoiceCount'
  /** The number of entries with this choice provided. */
  count?: Maybe<Scalars['Int']['output']>
  /** The choice text. */
  text?: Maybe<Scalars['String']['output']>
  /** The internal value used to represent the quiz choice. */
  value?: Maybe<Scalars['String']['output']>
}

/** The quiz results summary for an individual quiz field. */
export type GfQuizResultsFieldCount = {
  __typename?: 'GfQuizResultsFieldCount'
  /** A list of the individual responses and their counts. */
  choiceCounts?: Maybe<Array<Maybe<GfQuizResultsChoiceCount>>>
  /** The number of correct responses across all entries received. */
  correctCount?: Maybe<Scalars['Int']['output']>
  /** The quiz field ID. */
  fieldId?: Maybe<Scalars['Int']['output']>
  /** Connection between the GfQuizResultsFieldCount type and the QuizField type */
  formField?: Maybe<GfQuizResultsFieldCountToQuizFieldConnectionEdge>
  /** The number of incorrect responses across all entries received. */
  incorrectCount?: Maybe<Scalars['Int']['output']>
}

/** Connection between the GfQuizResultsFieldCount type and the QuizField type */
export type GfQuizResultsFieldCountToQuizFieldConnectionEdge = {
  __typename?: 'GfQuizResultsFieldCountToQuizFieldConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<QuizField>
}

/** A quiz Grade and its count. */
export type GfQuizResultsGradeCount = {
  __typename?: 'GfQuizResultsGradeCount'
  /** The number of entries that received this grade across all entries received. */
  count?: Maybe<Scalars['Int']['output']>
  /** The quiz grade. */
  grade?: Maybe<Scalars['String']['output']>
}

/** A quiz score and its count. */
export type GfQuizResultsScoreCount = {
  __typename?: 'GfQuizResultsScoreCount'
  /** The number of entries that received this score across all entries received. */
  count?: Maybe<Scalars['Int']['output']>
  /** The quiz score. */
  score?: Maybe<Scalars['Float']['output']>
}

/** Gravity Forms Settings. */
export type GfSettings = {
  __typename?: 'GfSettings'
  /** The default currency for your forms. Used for product, credit card, and other fields. */
  currency?: Maybe<GfCurrencyEnum>
  /** Whether Gravity Forms to download and install bug fixes and security updates automatically in the background. Requires a valid license key. */
  hasBackgroundUpdates?: Maybe<Scalars['Boolean']['output']>
  /** Whether to output Gravity Forms&#039; default CSS. */
  hasDefaultCss?: Maybe<Scalars['Boolean']['output']>
  /** Whether to display the forms menu in the WordPress top toolbar. The forms menu will display the ten forms recently opened in the form editor. */
  hasToolbar?: Maybe<Scalars['Boolean']['output']>
  /** Whether the server-generated form markup uses HTML5. */
  isHtml5Enabled?: Maybe<Scalars['Boolean']['output']>
  /** Enable to prevent extraneous scripts and styles from being printed on a Gravity Forms admin pages, reducing conflicts with other plugins and themes. */
  isNoConflictModeEnabled?: Maybe<Scalars['Boolean']['output']>
  /** Logging settings. */
  logging?: Maybe<GfSettingsLogging>
  /** Recaptcha settings. */
  recaptcha?: Maybe<GfSettingsRecaptcha>
}

/** Gravity Forms Logging Settings. */
export type GfSettingsLogging = {
  __typename?: 'GfSettingsLogging'
  /** Whether Gravity Forms internal logging is enabled. Logging allows you to easily debug the inner workings of Gravity Forms to solve any possible issues. */
  isLoggingEnabled?: Maybe<Scalars['Boolean']['output']>
  /** A list of registered Gravity Forms loggers and their configurations. */
  loggers?: Maybe<Array<Maybe<GfLogger>>>
}

/** Gravity Forms reCAPTCHA Settings. */
export type GfSettingsRecaptcha = {
  __typename?: 'GfSettingsRecaptcha'
  /** The public reCAPTCHA site key. */
  publicKey?: Maybe<Scalars['String']['output']>
  /** The type of of reCAPTCHA v2 to be used */
  type?: Maybe<RecaptchaTypeEnum>
}

/** A Gravity Forms submitted entry. */
export type GfSubmittedEntry = DatabaseIdentifier &
  GfEntry &
  Node &
  NodeWithForm & {
    __typename?: 'GfSubmittedEntry'
    /** Details to be displayed on list cards */
    cardDetails?: Maybe<CardDetails>
    /** Connection between the GfEntry type and the Company type */
    companies?: Maybe<GfEntryToCompanyConnection>
    /** The user who created the entry. */
    createdBy?: Maybe<User>
    /** Database ID of the user that submitted of the form if a logged in user submitted the form. */
    createdByDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Global ID of the user that submitted of the form if a logged in user submitted the form. */
    createdById?: Maybe<Scalars['ID']['output']>
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** The date and time that the entry was created in local time. */
    dateCreated?: Maybe<Scalars['String']['output']>
    /** The date and time that the entry was created in GMT. */
    dateCreatedGmt?: Maybe<Scalars['String']['output']>
    /** The date and time that the entry was created in local time. */
    dateUpdated?: Maybe<Scalars['String']['output']>
    /** The date and time that the entry was updated in GMT. */
    dateUpdatedGmt?: Maybe<Scalars['String']['output']>
    /** Connection between the GfEntry type and the Division type */
    divisions?: Maybe<GfEntryToDivisionConnection>
    /**
     * The entry ID. Returns null for draft entries.
     * @deprecated Deprecated in favor of the databaseId field.
     */
    entryId?: Maybe<Scalars['Int']['output']>
    /** The form object of the node. */
    form?: Maybe<GfForm>
    /** The database identifier of the form of the node. */
    formDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Connection between the GfEntry type and the FormField type */
    formFields?: Maybe<GfEntryToFormFieldConnection>
    /** The globally unique identifier of the form of the node. */
    formId?: Maybe<Scalars['ID']['output']>
    /** List of Gravity Pdf Template name and ids, which are used in the creation of pdfs. */
    gravityPdfTemplates?: Maybe<Array<Maybe<GravityPdfEntryTemplate>>>
    /** The globally unique ID for the object */
    id: Scalars['ID']['output']
    /** Client IP of user who submitted the form. */
    ip?: Maybe<Scalars['String']['output']>
    /** Whether the entry is a draft. */
    isDraft?: Maybe<Scalars['Boolean']['output']>
    /** Whether the entry has been read. */
    isRead?: Maybe<Scalars['Boolean']['output']>
    /** Indicates if the entry has been starred (i.e marked with a star). */
    isStarred?: Maybe<Scalars['Boolean']['output']>
    /** Whether the entry has been submitted. */
    isSubmitted?: Maybe<Scalars['Boolean']['output']>
    /** For forms with Post fields, this is the post object that was created. */
    post?: Maybe<Post>
    /** For forms with Post fields, this property contains the Id of the Post that was created. */
    postDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Connection between the GfEntry type and the Project type */
    project?: Maybe<GfEntryToProjectConnectionEdge>
    /** The quiz results for the entry. Requires Gravity Forms Quiz to be enabled. */
    quizResults?: Maybe<EntryQuizResults>
    /** Source URL of page that contained the form when it was submitted. */
    sourceUrl?: Maybe<Scalars['String']['output']>
    /** The current status of the entry. */
    status?: Maybe<EntryStatusEnum>
    /** Provides the name and version of both the browser and operating system from which the entry was submitted. */
    userAgent?: Maybe<Scalars['String']['output']>
  }

/** A Gravity Forms submitted entry. */
export type GfSubmittedEntryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A Gravity Forms submitted entry. */
export type GfSubmittedEntryDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A Gravity Forms submitted entry. */
export type GfSubmittedEntryFormFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<GfEntryToFormFieldConnectionWhereArgs>
}

/** Input for the gravityPdfCreateEntryPdf mutation */
export type GravityPdfCreateEntryPdfInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** EntryId to return the pdf urls for each pdfs available */
  entryId: Scalars['Int']['input']
  /** ID of the PDF template to use. */
  templateId: Scalars['ID']['input']
}

/** The payload for the gravityPdfCreateEntryPdf mutation */
export type GravityPdfCreateEntryPdfPayload = {
  __typename?: 'GravityPdfCreateEntryPdfPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The generated gravity pdf urls */
  pdfUrl?: Maybe<Scalars['String']['output']>
}

/** Input for the gravityPdfCreateEntryPdfs mutation */
export type GravityPdfCreateEntryPdfsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** List of entryIds to return the pdf urls for */
  entryIds?: InputMaybe<Array<Scalars['Int']['input']>>
  /** ID of the PDF template to use. */
  templateId: Scalars['ID']['input']
}

/** The payload for the gravityPdfCreateEntryPdfs mutation */
export type GravityPdfCreateEntryPdfsPayload = {
  __typename?: 'GravityPdfCreateEntryPdfsPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The generated gravity pdf urls */
  pdfUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

/** Contains the Name and Id of the Gravity Pdf Templates available on a GF entry. */
export type GravityPdfEntryTemplate = {
  __typename?: 'GravityPdfEntryTemplate'
  /** ID of the GravityPdf Template */
  id?: Maybe<Scalars['ID']['output']>
  /** Name of the GravityPdf Template */
  name?: Maybe<Scalars['String']['output']>
}

/** Gravity Forms card display settings */
export type HarnessSettings = {
  __typename?: 'HarnessSettings'
  /** Connection between the HarnessSettings type and the MediaItem type */
  clientLogo?: Maybe<HarnessSettingsToMediaItemConnectionEdge>
  /** Boolean to require employee Id on worker forms and allow worker upload */
  employeeIdRequired?: Maybe<Scalars['Boolean']['output']>
  /** Boolean to ETS vax stuff on frontend */
  etsSupport?: Maybe<Scalars['Boolean']['output']>
  /** Form Groups in the correct order */
  formGroups?: Maybe<Array<Maybe<FormGroup>>>
  /** Hide Companies entirely */
  hideCompany?: Maybe<Scalars['Boolean']['output']>
  /** Hide Division entirely */
  hideDivision?: Maybe<Scalars['Boolean']['output']>
  /** List of modules of the application to hide on the frontend */
  moduleHideList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Boolean to require project number on project forms and allow project upload */
  projectNumberRequired?: Maybe<Scalars['Boolean']['output']>
  /** List of SDS Manufacturers to exclude from the SDS search options. */
  sdsManufacturerHideList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Whether to show U.S. States or Canadian Provinces in non-Gravity Forms forms on the frontend */
  stateType?: Maybe<Scalars['String']['output']>
}

/** Connection between the HarnessSettings type and the MediaItem type */
export type HarnessSettingsToMediaItemConnectionEdge = {
  __typename?: 'HarnessSettingsToMediaItemConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<MediaItem>
}

/** A Gravity Forms   field. */
export type HiddenField = FormField & {
  __typename?: 'HiddenField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNode = {
  /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>
  /** Connection between the HierarchicalContentNode type and the ContentNode type */
  children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>
  /** The parent of the node. The parent object can be of various types */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']['output']>
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']['output']>
}

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>
}

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>
}

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToContentNodeAncestorsConnection = {
  __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnection'
  /** Edges for the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
  edges?: Maybe<
    Array<Maybe<HierarchicalContentNodeToContentNodeAncestorsConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionEdge = {
  __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsMetaArray =
  {
    /** Custom field value */
    compare?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsMetaCompareEnum>
    /** Custom field key */
    key?: InputMaybe<Scalars['String']['input']>
    /** Custom field value */
    type?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsMetaTypeEnum>
    /** Custom field value */
    value?: InputMaybe<Scalars['String']['input']>
    /** Custom field values */
    values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  }

export enum HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsMetaQuery =
  {
    metaArray?: InputMaybe<
      Array<
        InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsMetaArray>
      >
    >
    relation?: InputMaybe<RelationEnum>
  }

export enum HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxArray =
  {
    field?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryField>
    /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
    includeChildren?: InputMaybe<Scalars['Boolean']['input']>
    operator?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryOperator>
    taxonomy?: InputMaybe<TaxonomyEnum>
    /** A list of term slugs */
    terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  }

/** Query objects based on taxonomy parameters */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQuery =
  {
    relation?: InputMaybe<RelationEnum>
    taxArray?: InputMaybe<
      Array<
        InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxArray>
      >
    >
  }

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToContentNodeChildrenConnection = {
  __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnection'
  /** Edges for the HierarchicalContentNodeToContentNodeChildrenConnection connection */
  edges?: Maybe<
    Array<Maybe<HierarchicalContentNodeToContentNodeChildrenConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionEdge = {
  __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the HierarchicalContentNodeToContentNodeChildrenConnection connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsMetaArray =
  {
    /** Custom field value */
    compare?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsMetaCompareEnum>
    /** Custom field key */
    key?: InputMaybe<Scalars['String']['input']>
    /** Custom field value */
    type?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsMetaTypeEnum>
    /** Custom field value */
    value?: InputMaybe<Scalars['String']['input']>
    /** Custom field values */
    values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  }

export enum HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsMetaQuery =
  {
    metaArray?: InputMaybe<
      Array<
        InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsMetaArray>
      >
    >
    relation?: InputMaybe<RelationEnum>
  }

export enum HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxArray =
  {
    field?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryField>
    /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
    includeChildren?: InputMaybe<Scalars['Boolean']['input']>
    operator?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryOperator>
    taxonomy?: InputMaybe<TaxonomyEnum>
    /** A list of term slugs */
    terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  }

/** Query objects based on taxonomy parameters */
export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQuery =
  {
    relation?: InputMaybe<RelationEnum>
    taxArray?: InputMaybe<
      Array<
        InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxArray>
      >
    >
  }

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToParentContentNodeConnectionEdge = {
  __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<ContentNode>
}

/** Term node with hierarchical (parent/child) relationships */
export type HierarchicalTermNode = {
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']['output']>
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']['output']>
}

/** A Gravity Forms   field. */
export type HtmlField = FormField & {
  __typename?: 'HtmlField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** Content of an HTML block field to be displayed on the form. */
  content?: Maybe<Scalars['String']['output']>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the default margins are turned on to align the HTML content with other fields. */
  hasMargins?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** The individual properties for each element of the PostImage value field. */
export type ImageFieldValue = {
  __typename?: 'ImageFieldValue'
  /** The image alt text. */
  altText?: Maybe<Scalars['String']['output']>
  /** The path to the parent directory of the file. */
  basePath?: Maybe<Scalars['String']['output']>
  /** The base url to the parent directory of the file. */
  baseUrl?: Maybe<Scalars['String']['output']>
  /** The image caption. */
  caption?: Maybe<Scalars['String']['output']>
  /** The image description. */
  description?: Maybe<Scalars['String']['output']>
  /** The filename. */
  filename?: Maybe<Scalars['String']['output']>
  /** The image title. */
  title?: Maybe<Scalars['String']['output']>
  /** The url to the file. */
  url?: Maybe<Scalars['String']['output']>
}

/** Input fields for a single post Image. */
export type ImageInput = {
  /** The image alt text. */
  altText?: InputMaybe<Scalars['String']['input']>
  /** The image caption. */
  caption?: InputMaybe<Scalars['String']['input']>
  /** The image description. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The image to be uploaded. */
  image: Scalars['Upload']['input']
  /** The image title. */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The IncidentReport type */
export type IncidentReport = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'IncidentReport'
    /** Added to the GraphQL Schema because the ACF Field Group &quot;2nd - Additional Information Meta&quot; was set to Show in GraphQL. */
    additionalInformation?: Maybe<IncidentReport_Additionalinformation>
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** Connection between the IncidentReport type and the Company type */
    companies?: Maybe<IncidentReportToCompanyConnection>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** Connection between the IncidentReport type and the Division type */
    divisions?: Maybe<IncidentReportToDivisionConnection>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** Connection between the IncidentReport type and the MediaItem type */
    fileAttachments?: Maybe<IncidentReportToMediaItemConnection>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the incident_report object. */
    id: Scalars['ID']['output']
    /** Connection between the IncidentReport type and the IncidentReportClassification type */
    incidentReportClassifications?: Maybe<IncidentReportToIncidentReportClassificationConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    incidentReportId: Scalars['Int']['output']
    /** Connection between the IncidentReport type and the Project type */
    incidentReportProjectConnection?: Maybe<IncidentReportToProjectConnection>
    /** Connection between the IncidentReport type and the IncidentReportStatus type */
    incidentReportStatuses?: Maybe<IncidentReportToIncidentReportStatusConnection>
    /** Connection between the IncidentReport type and the User type */
    incidentReportWorkerConnection?: Maybe<IncidentReportToUserConnection>
    /** Connection between the IncidentReport type and the IncidentType type */
    incidentTypes?: Maybe<IncidentReportToIncidentTypeConnection>
    /** Added to the GraphQL Schema because the ACF Field Group &quot;1st - Initial Report Meta&quot; was set to Show in GraphQL. */
    initialReport?: Maybe<IncidentReport_Initialreport>
    /** Connection between the IncidentReport type and the InjuryArea type */
    injuryAreas?: Maybe<IncidentReportToInjuryAreaConnection>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /** Connection between the IncidentReport type and the IncidentReport type */
    preview?: Maybe<IncidentReportToPreviewConnectionEdge>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** Connection between the IncidentReport type and the RootCause type */
    rootCauses?: Maybe<IncidentReportToRootCauseConnection>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** Connection between the IncidentReport type and the TermNode type */
    terms?: Maybe<IncidentReportToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The IncidentReport type */
export type IncidentReportCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportToCompanyConnectionWhereArgs>
}

/** The IncidentReport type */
export type IncidentReportDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportToDivisionConnectionWhereArgs>
}

/** The IncidentReport type */
export type IncidentReportEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The IncidentReport type */
export type IncidentReportEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The IncidentReport type */
export type IncidentReportFileAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The IncidentReport type */
export type IncidentReportIncidentReportClassificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportToIncidentReportClassificationConnectionWhereArgs>
}

/** The IncidentReport type */
export type IncidentReportIncidentReportProjectConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The IncidentReport type */
export type IncidentReportIncidentReportStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportToIncidentReportStatusConnectionWhereArgs>
}

/** The IncidentReport type */
export type IncidentReportIncidentReportWorkerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The IncidentReport type */
export type IncidentReportIncidentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportToIncidentTypeConnectionWhereArgs>
}

/** The IncidentReport type */
export type IncidentReportInjuryAreasArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportToInjuryAreaConnectionWhereArgs>
}

/** The IncidentReport type */
export type IncidentReportRootCausesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportToRootCauseConnectionWhereArgs>
}

/** The IncidentReport type */
export type IncidentReportTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportToTermNodeConnectionWhereArgs>
}

/** The IncidentReport type */
export type IncidentReportTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The IncidentReportClassification type */
export type IncidentReportClassification = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'IncidentReportClassification'
    /** Connection between the IncidentReportClassification type and the ContentNode type */
    contentNodes?: Maybe<IncidentReportClassificationToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    incidentReportClassificationId?: Maybe<Scalars['Int']['output']>
    /** Connection between the IncidentReportClassification type and the IncidentReport type */
    incidentReports?: Maybe<IncidentReportClassificationToIncidentReportConnection>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the IncidentReportClassification type and the Taxonomy type */
    taxonomy?: Maybe<IncidentReportClassificationToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The IncidentReportClassification type */
export type IncidentReportClassificationContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportClassificationToContentNodeConnectionWhereArgs>
}

/** The IncidentReportClassification type */
export type IncidentReportClassificationEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The IncidentReportClassification type */
export type IncidentReportClassificationEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The IncidentReportClassification type */
export type IncidentReportClassificationIncidentReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportClassificationToIncidentReportConnectionWhereArgs>
}

/** Incident Report Classification Options */
export enum IncidentReportClassificationEnum {
  /** Days away from work */
  DaysAwayFromWork = 'DAYS_AWAY_FROM_WORK',
  /** Death Classification */
  Death = 'DEATH',
  /** Days on job transfer / restriction */
  JobRestrictionOrTransfer = 'JOB_RESTRICTION_OR_TRANSFER',
  /** All other recordable incident reports */
  OtherRecordableCase = 'OTHER_RECORDABLE_CASE',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum IncidentReportClassificationIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the IncidentReportClassification type and the ContentNode type */
export type IncidentReportClassificationToContentNodeConnection = {
  __typename?: 'IncidentReportClassificationToContentNodeConnection'
  /** Edges for the IncidentReportClassificationToContentNodeConnection connection */
  edges?: Maybe<
    Array<Maybe<IncidentReportClassificationToContentNodeConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportClassificationToContentNodeConnectionEdge = {
  __typename?: 'IncidentReportClassificationToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the IncidentReportClassificationToContentNodeConnection connection */
export type IncidentReportClassificationToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfIncidentReportClassificationEnum>>
  >
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<IncidentReportClassificationToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<IncidentReportClassificationToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type IncidentReportClassificationToContentNodeConnectionWhereArgsMetaArray =
  {
    /** Custom field value */
    compare?: InputMaybe<IncidentReportClassificationToContentNodeConnectionWhereArgsMetaCompareEnum>
    /** Custom field key */
    key?: InputMaybe<Scalars['String']['input']>
    /** Custom field value */
    type?: InputMaybe<IncidentReportClassificationToContentNodeConnectionWhereArgsMetaTypeEnum>
    /** Custom field value */
    value?: InputMaybe<Scalars['String']['input']>
    /** Custom field values */
    values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  }

export enum IncidentReportClassificationToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type IncidentReportClassificationToContentNodeConnectionWhereArgsMetaQuery =
  {
    metaArray?: InputMaybe<
      Array<
        InputMaybe<IncidentReportClassificationToContentNodeConnectionWhereArgsMetaArray>
      >
    >
    relation?: InputMaybe<RelationEnum>
  }

export enum IncidentReportClassificationToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type IncidentReportClassificationToContentNodeConnectionWhereArgsTaxArray =
  {
    field?: InputMaybe<IncidentReportClassificationToContentNodeConnectionWhereArgsTaxQueryField>
    /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
    includeChildren?: InputMaybe<Scalars['Boolean']['input']>
    operator?: InputMaybe<IncidentReportClassificationToContentNodeConnectionWhereArgsTaxQueryOperator>
    taxonomy?: InputMaybe<TaxonomyEnum>
    /** A list of term slugs */
    terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  }

/** Query objects based on taxonomy parameters */
export type IncidentReportClassificationToContentNodeConnectionWhereArgsTaxQuery =
  {
    relation?: InputMaybe<RelationEnum>
    taxArray?: InputMaybe<
      Array<
        InputMaybe<IncidentReportClassificationToContentNodeConnectionWhereArgsTaxArray>
      >
    >
  }

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum IncidentReportClassificationToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum IncidentReportClassificationToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the IncidentReportClassification type and the IncidentReport type */
export type IncidentReportClassificationToIncidentReportConnection = {
  __typename?: 'IncidentReportClassificationToIncidentReportConnection'
  /** Edges for the IncidentReportClassificationToIncidentReportConnection connection */
  edges?: Maybe<
    Array<Maybe<IncidentReportClassificationToIncidentReportConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReport>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportClassificationToIncidentReportConnectionEdge = {
  __typename?: 'IncidentReportClassificationToIncidentReportConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReport>
}

/** Arguments for filtering the IncidentReportClassificationToIncidentReportConnection connection */
export type IncidentReportClassificationToIncidentReportConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<IncidentReportClassificationToIncidentReportConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<IncidentReportClassificationToIncidentReportConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type IncidentReportClassificationToIncidentReportConnectionWhereArgsMetaArray =
  {
    /** Custom field value */
    compare?: InputMaybe<IncidentReportClassificationToIncidentReportConnectionWhereArgsMetaCompareEnum>
    /** Custom field key */
    key?: InputMaybe<Scalars['String']['input']>
    /** Custom field value */
    type?: InputMaybe<IncidentReportClassificationToIncidentReportConnectionWhereArgsMetaTypeEnum>
    /** Custom field value */
    value?: InputMaybe<Scalars['String']['input']>
    /** Custom field values */
    values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  }

export enum IncidentReportClassificationToIncidentReportConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type IncidentReportClassificationToIncidentReportConnectionWhereArgsMetaQuery =
  {
    metaArray?: InputMaybe<
      Array<
        InputMaybe<IncidentReportClassificationToIncidentReportConnectionWhereArgsMetaArray>
      >
    >
    relation?: InputMaybe<RelationEnum>
  }

export enum IncidentReportClassificationToIncidentReportConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type IncidentReportClassificationToIncidentReportConnectionWhereArgsTaxArray =
  {
    field?: InputMaybe<IncidentReportClassificationToIncidentReportConnectionWhereArgsTaxQueryField>
    /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
    includeChildren?: InputMaybe<Scalars['Boolean']['input']>
    operator?: InputMaybe<IncidentReportClassificationToIncidentReportConnectionWhereArgsTaxQueryOperator>
    taxonomy?: InputMaybe<TaxonomyEnum>
    /** A list of term slugs */
    terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  }

/** Query objects based on taxonomy parameters */
export type IncidentReportClassificationToIncidentReportConnectionWhereArgsTaxQuery =
  {
    relation?: InputMaybe<RelationEnum>
    taxArray?: InputMaybe<
      Array<
        InputMaybe<IncidentReportClassificationToIncidentReportConnectionWhereArgsTaxArray>
      >
    >
  }

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum IncidentReportClassificationToIncidentReportConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum IncidentReportClassificationToIncidentReportConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the IncidentReportClassification type and the Taxonomy type */
export type IncidentReportClassificationToTaxonomyConnectionEdge = {
  __typename?: 'IncidentReportClassificationToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Set relationships between the IncidentReport to Companies */
export type IncidentReportCompaniesInput = {
  /** If true, this will append the Company to existing related Companies. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<IncidentReportCompaniesNodeInput>>>
}

/** List of Companies to connect the IncidentReport to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type IncidentReportCompaniesNodeInput = {
  /** The description of the Company. This field is used to set a description of the Company if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Company. If present, this will be used to connect to the IncidentReport. If no existing Company exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Company. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Company. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Incident report data used to complete the OSHA 300A form. */
export type IncidentReportData = {
  __typename?: 'IncidentReportData'
  /** The total number of days away from work. */
  casesWithDaysAwayFromWork?: Maybe<Scalars['Int']['output']>
  /** The total number of deaths. */
  casesWithDeaths?: Maybe<Scalars['Int']['output']>
  /** The total number of job restrictions. */
  casesWithJobRestrictions?: Maybe<Scalars['Int']['output']>
  /** The total number of days away from work. */
  daysAwayFromWork?: Maybe<Scalars['Int']['output']>
  /** The total number of job transfers or restrictions. */
  daysWithRestrictions?: Maybe<Scalars['Int']['output']>
  /** The total number of hearing loss. */
  hearingLoss?: Maybe<Scalars['Int']['output']>
  /** The total number of injuries. */
  injuries?: Maybe<Scalars['Int']['output']>
  /** The total number of other injuries. */
  otherIllnesses?: Maybe<Scalars['Int']['output']>
  /** The total number of other recordable cases. */
  otherRecordableCases?: Maybe<Scalars['Int']['output']>
  /** The total number of poisonings. */
  poisonings?: Maybe<Scalars['Int']['output']>
  /** The total number of respiratory conditions. */
  respiratoryConditions?: Maybe<Scalars['Int']['output']>
  /** The total number of skin disorders. */
  skinDisorders?: Maybe<Scalars['Int']['output']>
}

/** Set relationships between the IncidentReport to Divisions */
export type IncidentReportDivisionsInput = {
  /** If true, this will append the Division to existing related Divisions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<IncidentReportDivisionsNodeInput>>>
}

/** List of Divisions to connect the IncidentReport to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type IncidentReportDivisionsNodeInput = {
  /** The description of the Division. This field is used to set a description of the Division if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Division. If present, this will be used to connect to the IncidentReport. If no existing Division exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Division. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Division. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum IncidentReportIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the IncidentReport to IncidentReportClassifications */
export type IncidentReportIncidentReportClassificationsInput = {
  /** If true, this will append the IncidentReportClassification to existing related IncidentReportClassifications. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<
    Array<InputMaybe<IncidentReportIncidentReportClassificationsNodeInput>>
  >
}

/** List of IncidentReportClassifications to connect the IncidentReport to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type IncidentReportIncidentReportClassificationsNodeInput = {
  /** The description of the IncidentReportClassification. This field is used to set a description of the IncidentReportClassification if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the IncidentReportClassification. If present, this will be used to connect to the IncidentReport. If no existing IncidentReportClassification exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the IncidentReportClassification. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the IncidentReportClassification. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the IncidentReport to IncidentReportStatuses */
export type IncidentReportIncidentReportStatusesInput = {
  /** If true, this will append the IncidentReportStatus to existing related IncidentReportStatuses. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<
    Array<InputMaybe<IncidentReportIncidentReportStatusesNodeInput>>
  >
}

/** List of IncidentReportStatuses to connect the IncidentReport to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type IncidentReportIncidentReportStatusesNodeInput = {
  /** The description of the IncidentReportStatus. This field is used to set a description of the IncidentReportStatus if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the IncidentReportStatus. If present, this will be used to connect to the IncidentReport. If no existing IncidentReportStatus exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the IncidentReportStatus. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the IncidentReportStatus. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the IncidentReport to IncidentTypes */
export type IncidentReportIncidentTypesInput = {
  /** If true, this will append the IncidentType to existing related IncidentTypes. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<IncidentReportIncidentTypesNodeInput>>>
}

/** List of IncidentTypes to connect the IncidentReport to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type IncidentReportIncidentTypesNodeInput = {
  /** The description of the IncidentType. This field is used to set a description of the IncidentType if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the IncidentType. If present, this will be used to connect to the IncidentReport. If no existing IncidentType exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the IncidentType. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the IncidentType. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the IncidentReport to InjuryAreas */
export type IncidentReportInjuryAreasInput = {
  /** If true, this will append the InjuryArea to existing related InjuryAreas. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<IncidentReportInjuryAreasNodeInput>>>
}

/** List of InjuryAreas to connect the IncidentReport to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type IncidentReportInjuryAreasNodeInput = {
  /** The description of the InjuryArea. This field is used to set a description of the InjuryArea if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the InjuryArea. If present, this will be used to connect to the IncidentReport. If no existing InjuryArea exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the InjuryArea. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the InjuryArea. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the IncidentReport to RootCauses */
export type IncidentReportRootCausesInput = {
  /** If true, this will append the RootCause to existing related RootCauses. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<IncidentReportRootCausesNodeInput>>>
}

/** List of RootCauses to connect the IncidentReport to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type IncidentReportRootCausesNodeInput = {
  /** The description of the RootCause. This field is used to set a description of the RootCause if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the RootCause. If present, this will be used to connect to the IncidentReport. If no existing RootCause exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the RootCause. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the RootCause. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The IncidentReportStatus type */
export type IncidentReportStatus = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'IncidentReportStatus'
    /** Connection between the IncidentReportStatus type and the ContentNode type */
    contentNodes?: Maybe<IncidentReportStatusToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    incidentReportStatusId?: Maybe<Scalars['Int']['output']>
    /** Connection between the IncidentReportStatus type and the IncidentReport type */
    incidentReports?: Maybe<IncidentReportStatusToIncidentReportConnection>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the IncidentReportStatus type and the Taxonomy type */
    taxonomy?: Maybe<IncidentReportStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The IncidentReportStatus type */
export type IncidentReportStatusContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportStatusToContentNodeConnectionWhereArgs>
}

/** The IncidentReportStatus type */
export type IncidentReportStatusEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The IncidentReportStatus type */
export type IncidentReportStatusEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The IncidentReportStatus type */
export type IncidentReportStatusIncidentReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentReportStatusToIncidentReportConnectionWhereArgs>
}

/** Incident Report Status Options */
export enum IncidentReportStatusEnum {
  /** Additional information stage */
  AdditionalInformation = 'ADDITIONAL_INFORMATION',
  /** Initial Report stage */
  InitialReport = 'INITIAL_REPORT',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum IncidentReportStatusIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the IncidentReportStatus type and the ContentNode type */
export type IncidentReportStatusToContentNodeConnection = {
  __typename?: 'IncidentReportStatusToContentNodeConnection'
  /** Edges for the IncidentReportStatusToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<IncidentReportStatusToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportStatusToContentNodeConnectionEdge = {
  __typename?: 'IncidentReportStatusToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the IncidentReportStatusToContentNodeConnection connection */
export type IncidentReportStatusToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfIncidentReportStatusEnum>>
  >
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<IncidentReportStatusToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<IncidentReportStatusToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type IncidentReportStatusToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<IncidentReportStatusToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<IncidentReportStatusToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum IncidentReportStatusToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type IncidentReportStatusToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<
      InputMaybe<IncidentReportStatusToContentNodeConnectionWhereArgsMetaArray>
    >
  >
  relation?: InputMaybe<RelationEnum>
}

export enum IncidentReportStatusToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type IncidentReportStatusToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<IncidentReportStatusToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<IncidentReportStatusToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type IncidentReportStatusToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<
      InputMaybe<IncidentReportStatusToContentNodeConnectionWhereArgsTaxArray>
    >
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum IncidentReportStatusToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum IncidentReportStatusToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the IncidentReportStatus type and the IncidentReport type */
export type IncidentReportStatusToIncidentReportConnection = {
  __typename?: 'IncidentReportStatusToIncidentReportConnection'
  /** Edges for the IncidentReportStatusToIncidentReportConnection connection */
  edges?: Maybe<
    Array<Maybe<IncidentReportStatusToIncidentReportConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReport>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportStatusToIncidentReportConnectionEdge = {
  __typename?: 'IncidentReportStatusToIncidentReportConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReport>
}

/** Arguments for filtering the IncidentReportStatusToIncidentReportConnection connection */
export type IncidentReportStatusToIncidentReportConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<IncidentReportStatusToIncidentReportConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<IncidentReportStatusToIncidentReportConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type IncidentReportStatusToIncidentReportConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<IncidentReportStatusToIncidentReportConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<IncidentReportStatusToIncidentReportConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum IncidentReportStatusToIncidentReportConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type IncidentReportStatusToIncidentReportConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<
      InputMaybe<IncidentReportStatusToIncidentReportConnectionWhereArgsMetaArray>
    >
  >
  relation?: InputMaybe<RelationEnum>
}

export enum IncidentReportStatusToIncidentReportConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type IncidentReportStatusToIncidentReportConnectionWhereArgsTaxArray = {
  field?: InputMaybe<IncidentReportStatusToIncidentReportConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<IncidentReportStatusToIncidentReportConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type IncidentReportStatusToIncidentReportConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<
      InputMaybe<IncidentReportStatusToIncidentReportConnectionWhereArgsTaxArray>
    >
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum IncidentReportStatusToIncidentReportConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum IncidentReportStatusToIncidentReportConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the IncidentReportStatus type and the Taxonomy type */
export type IncidentReportStatusToTaxonomyConnectionEdge = {
  __typename?: 'IncidentReportStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Connection between the IncidentReport type and the Company type */
export type IncidentReportToCompanyConnection = {
  __typename?: 'IncidentReportToCompanyConnection'
  /** Edges for the IncidentReportToCompanyConnection connection */
  edges?: Maybe<Array<Maybe<IncidentReportToCompanyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Company>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportToCompanyConnectionEdge = {
  __typename?: 'IncidentReportToCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Company>
}

/** Arguments for filtering the IncidentReportToCompanyConnection connection */
export type IncidentReportToCompanyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the IncidentReport type and the Division type */
export type IncidentReportToDivisionConnection = {
  __typename?: 'IncidentReportToDivisionConnection'
  /** Edges for the IncidentReportToDivisionConnection connection */
  edges?: Maybe<Array<Maybe<IncidentReportToDivisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Division>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportToDivisionConnectionEdge = {
  __typename?: 'IncidentReportToDivisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Division>
}

/** Arguments for filtering the IncidentReportToDivisionConnection connection */
export type IncidentReportToDivisionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the IncidentReport type and the IncidentReportClassification type */
export type IncidentReportToIncidentReportClassificationConnection = {
  __typename?: 'IncidentReportToIncidentReportClassificationConnection'
  /** Edges for the IncidentReportToIncidentReportClassificationConnection connection */
  edges?: Maybe<
    Array<Maybe<IncidentReportToIncidentReportClassificationConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReportClassification>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportToIncidentReportClassificationConnectionEdge = {
  __typename?: 'IncidentReportToIncidentReportClassificationConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReportClassification>
}

/** Arguments for filtering the IncidentReportToIncidentReportClassificationConnection connection */
export type IncidentReportToIncidentReportClassificationConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the IncidentReport type and the IncidentReportStatus type */
export type IncidentReportToIncidentReportStatusConnection = {
  __typename?: 'IncidentReportToIncidentReportStatusConnection'
  /** Edges for the IncidentReportToIncidentReportStatusConnection connection */
  edges?: Maybe<
    Array<Maybe<IncidentReportToIncidentReportStatusConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReportStatus>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportToIncidentReportStatusConnectionEdge = {
  __typename?: 'IncidentReportToIncidentReportStatusConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReportStatus>
}

/** Arguments for filtering the IncidentReportToIncidentReportStatusConnection connection */
export type IncidentReportToIncidentReportStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the IncidentReport type and the IncidentType type */
export type IncidentReportToIncidentTypeConnection = {
  __typename?: 'IncidentReportToIncidentTypeConnection'
  /** Edges for the IncidentReportToIncidentTypeConnection connection */
  edges?: Maybe<Array<Maybe<IncidentReportToIncidentTypeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentType>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportToIncidentTypeConnectionEdge = {
  __typename?: 'IncidentReportToIncidentTypeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentType>
}

/** Arguments for filtering the IncidentReportToIncidentTypeConnection connection */
export type IncidentReportToIncidentTypeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the IncidentReport type and the InjuryArea type */
export type IncidentReportToInjuryAreaConnection = {
  __typename?: 'IncidentReportToInjuryAreaConnection'
  /** Edges for the IncidentReportToInjuryAreaConnection connection */
  edges?: Maybe<Array<Maybe<IncidentReportToInjuryAreaConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<InjuryArea>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportToInjuryAreaConnectionEdge = {
  __typename?: 'IncidentReportToInjuryAreaConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<InjuryArea>
}

/** Arguments for filtering the IncidentReportToInjuryAreaConnection connection */
export type IncidentReportToInjuryAreaConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the IncidentReport type and the MediaItem type */
export type IncidentReportToMediaItemConnection = {
  __typename?: 'IncidentReportToMediaItemConnection'
  /** Edges for the IncidentReportToMediaItemConnection connection */
  edges?: Maybe<Array<Maybe<IncidentReportToMediaItemConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MediaItem>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportToMediaItemConnectionEdge = {
  __typename?: 'IncidentReportToMediaItemConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<MediaItem>
}

/** Connection between the IncidentReport type and the IncidentReport type */
export type IncidentReportToPreviewConnectionEdge = {
  __typename?: 'IncidentReportToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<IncidentReport>
}

/** Connection between the IncidentReport type and the Project type */
export type IncidentReportToProjectConnection = {
  __typename?: 'IncidentReportToProjectConnection'
  /** Edges for the IncidentReportToProjectConnection connection */
  edges?: Maybe<Array<Maybe<IncidentReportToProjectConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportToProjectConnectionEdge = {
  __typename?: 'IncidentReportToProjectConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Connection between the IncidentReport type and the RootCause type */
export type IncidentReportToRootCauseConnection = {
  __typename?: 'IncidentReportToRootCauseConnection'
  /** Edges for the IncidentReportToRootCauseConnection connection */
  edges?: Maybe<Array<Maybe<IncidentReportToRootCauseConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<RootCause>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportToRootCauseConnectionEdge = {
  __typename?: 'IncidentReportToRootCauseConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<RootCause>
}

/** Arguments for filtering the IncidentReportToRootCauseConnection connection */
export type IncidentReportToRootCauseConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the IncidentReport type and the TermNode type */
export type IncidentReportToTermNodeConnection = {
  __typename?: 'IncidentReportToTermNodeConnection'
  /** Edges for the IncidentReportToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<IncidentReportToTermNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportToTermNodeConnectionEdge = {
  __typename?: 'IncidentReportToTermNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TermNode>
}

/** Arguments for filtering the IncidentReportToTermNodeConnection connection */
export type IncidentReportToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the IncidentReport type and the User type */
export type IncidentReportToUserConnection = {
  __typename?: 'IncidentReportToUserConnection'
  /** Edges for the IncidentReportToUserConnection connection */
  edges?: Maybe<Array<Maybe<IncidentReportToUserConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<User>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentReportToUserConnectionEdge = {
  __typename?: 'IncidentReportToUserConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<User>
}

/** A witness for the Incident Report. */
export type IncidentReportWitnessInput = {
  /** The witness name. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The wittness phone number. */
  phone?: InputMaybe<Scalars['String']['input']>
}

/** Field Group */
export type IncidentReport_Additionalinformation = AcfFieldGroup & {
  __typename?: 'IncidentReport_Additionalinformation'
  /** Available if the Classification taxonomy is set to Days Away From Work */
  daysAwayWork?: Maybe<Scalars['Float']['output']>
  /** Available if the Classification taxonomy is set to Days on Job Transfer / Restriction */
  daysJobTransfer?: Maybe<Scalars['Float']['output']>
  employeeAddress?: Maybe<Scalars['String']['output']>
  employeeBirthDate?: Maybe<Scalars['String']['output']>
  /** Will be available if the Classification taxonomy is set to Death */
  employeeDeathDate?: Maybe<Scalars['String']['output']>
  employeeGender?: Maybe<Scalars['String']['output']>
  employeeHireDate?: Maybe<Scalars['String']['output']>
  /** Time of day worker involved began work */
  employeeStartTime?: Maybe<Scalars['String']['output']>
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
  hasEmergencyTreatment?: Maybe<Scalars['Boolean']['output']>
  hasHospitalOvernight?: Maybe<Scalars['Boolean']['output']>
  /** What object / substance injured the worker */
  hazardDescription?: Maybe<Scalars['String']['output']>
  hospitalAddress?: Maybe<Scalars['String']['output']>
  hospitalName?: Maybe<Scalars['String']['output']>
  incidentDate?: Maybe<Scalars['String']['output']>
  /** Time of Incident */
  incidentTime?: Maybe<Scalars['String']['output']>
  /** What were the circumstances that caused the injury */
  injuryCause?: Maybe<Scalars['String']['output']>
  /** A full description of the injured area */
  injuryDescription?: Maybe<Scalars['String']['output']>
  physicianName?: Maybe<Scalars['String']['output']>
  /** What was the worker doing prior to the incident? */
  priorActions?: Maybe<Scalars['String']['output']>
  witnesses?: Maybe<
    Array<Maybe<IncidentReport_Additionalinformation_Witnesses>>
  >
}

/** Field Group */
export type IncidentReport_Additionalinformation_Witnesses = AcfFieldGroup & {
  __typename?: 'IncidentReport_Additionalinformation_witnesses'
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
}

/** Field Group */
export type IncidentReport_Initialreport = AcfFieldGroup & {
  __typename?: 'IncidentReport_Initialreport'
  /** Full description of incident. What happened? Contributing factors? Steps that lead to incident? */
  description?: Maybe<Scalars['String']['output']>
  /** Involved worker job title */
  employeeJobTitle?: Maybe<Scalars['String']['output']>
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
  /** Specific location on the site where the incident happened. */
  projectAddress?: Maybe<Scalars['String']['output']>
}

/** The IncidentType type */
export type IncidentType = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'IncidentType'
    /** Connection between the IncidentType type and the ContentNode type */
    contentNodes?: Maybe<IncidentTypeToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Connection between the IncidentType type and the IncidentReport type */
    incidentReports?: Maybe<IncidentTypeToIncidentReportConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    incidentTypeId?: Maybe<Scalars['Int']['output']>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the IncidentType type and the Taxonomy type */
    taxonomy?: Maybe<IncidentTypeToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The IncidentType type */
export type IncidentTypeContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentTypeToContentNodeConnectionWhereArgs>
}

/** The IncidentType type */
export type IncidentTypeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The IncidentType type */
export type IncidentTypeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The IncidentType type */
export type IncidentTypeIncidentReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<IncidentTypeToIncidentReportConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum IncidentTypeIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the IncidentType type and the ContentNode type */
export type IncidentTypeToContentNodeConnection = {
  __typename?: 'IncidentTypeToContentNodeConnection'
  /** Edges for the IncidentTypeToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<IncidentTypeToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentTypeToContentNodeConnectionEdge = {
  __typename?: 'IncidentTypeToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the IncidentTypeToContentNodeConnection connection */
export type IncidentTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfIncidentTypeEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<IncidentTypeToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<IncidentTypeToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type IncidentTypeToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<IncidentTypeToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<IncidentTypeToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum IncidentTypeToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type IncidentTypeToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<IncidentTypeToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum IncidentTypeToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type IncidentTypeToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<IncidentTypeToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<IncidentTypeToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type IncidentTypeToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<IncidentTypeToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum IncidentTypeToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum IncidentTypeToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the IncidentType type and the IncidentReport type */
export type IncidentTypeToIncidentReportConnection = {
  __typename?: 'IncidentTypeToIncidentReportConnection'
  /** Edges for the IncidentTypeToIncidentReportConnection connection */
  edges?: Maybe<Array<Maybe<IncidentTypeToIncidentReportConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReport>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type IncidentTypeToIncidentReportConnectionEdge = {
  __typename?: 'IncidentTypeToIncidentReportConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReport>
}

/** Arguments for filtering the IncidentTypeToIncidentReportConnection connection */
export type IncidentTypeToIncidentReportConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<IncidentTypeToIncidentReportConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<IncidentTypeToIncidentReportConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type IncidentTypeToIncidentReportConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<IncidentTypeToIncidentReportConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<IncidentTypeToIncidentReportConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum IncidentTypeToIncidentReportConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type IncidentTypeToIncidentReportConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<IncidentTypeToIncidentReportConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum IncidentTypeToIncidentReportConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type IncidentTypeToIncidentReportConnectionWhereArgsTaxArray = {
  field?: InputMaybe<IncidentTypeToIncidentReportConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<IncidentTypeToIncidentReportConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type IncidentTypeToIncidentReportConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<IncidentTypeToIncidentReportConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum IncidentTypeToIncidentReportConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum IncidentTypeToIncidentReportConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the IncidentType type and the Taxonomy type */
export type IncidentTypeToTaxonomyConnectionEdge = {
  __typename?: 'IncidentTypeToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** The InjuryArea type */
export type InjuryArea = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'InjuryArea'
    /** Connection between the InjuryArea type and the ContentNode type */
    contentNodes?: Maybe<InjuryAreaToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Connection between the InjuryArea type and the IncidentReport type */
    incidentReports?: Maybe<InjuryAreaToIncidentReportConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    injuryAreaId?: Maybe<Scalars['Int']['output']>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the InjuryArea type and the Taxonomy type */
    taxonomy?: Maybe<InjuryAreaToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The InjuryArea type */
export type InjuryAreaContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<InjuryAreaToContentNodeConnectionWhereArgs>
}

/** The InjuryArea type */
export type InjuryAreaEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The InjuryArea type */
export type InjuryAreaEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The InjuryArea type */
export type InjuryAreaIncidentReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<InjuryAreaToIncidentReportConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum InjuryAreaIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the InjuryArea type and the ContentNode type */
export type InjuryAreaToContentNodeConnection = {
  __typename?: 'InjuryAreaToContentNodeConnection'
  /** Edges for the InjuryAreaToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<InjuryAreaToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type InjuryAreaToContentNodeConnectionEdge = {
  __typename?: 'InjuryAreaToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the InjuryAreaToContentNodeConnection connection */
export type InjuryAreaToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfInjuryAreaEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<InjuryAreaToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<InjuryAreaToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type InjuryAreaToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<InjuryAreaToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<InjuryAreaToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum InjuryAreaToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type InjuryAreaToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<InjuryAreaToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum InjuryAreaToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type InjuryAreaToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<InjuryAreaToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<InjuryAreaToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type InjuryAreaToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<InjuryAreaToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum InjuryAreaToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum InjuryAreaToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the InjuryArea type and the IncidentReport type */
export type InjuryAreaToIncidentReportConnection = {
  __typename?: 'InjuryAreaToIncidentReportConnection'
  /** Edges for the InjuryAreaToIncidentReportConnection connection */
  edges?: Maybe<Array<Maybe<InjuryAreaToIncidentReportConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReport>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type InjuryAreaToIncidentReportConnectionEdge = {
  __typename?: 'InjuryAreaToIncidentReportConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReport>
}

/** Arguments for filtering the InjuryAreaToIncidentReportConnection connection */
export type InjuryAreaToIncidentReportConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<InjuryAreaToIncidentReportConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<InjuryAreaToIncidentReportConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type InjuryAreaToIncidentReportConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<InjuryAreaToIncidentReportConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<InjuryAreaToIncidentReportConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum InjuryAreaToIncidentReportConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type InjuryAreaToIncidentReportConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<InjuryAreaToIncidentReportConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum InjuryAreaToIncidentReportConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type InjuryAreaToIncidentReportConnectionWhereArgsTaxArray = {
  field?: InputMaybe<InjuryAreaToIncidentReportConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<InjuryAreaToIncidentReportConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type InjuryAreaToIncidentReportConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<InjuryAreaToIncidentReportConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum InjuryAreaToIncidentReportConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum InjuryAreaToIncidentReportConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the InjuryArea type and the Taxonomy type */
export type InjuryAreaToTaxonomyConnectionEdge = {
  __typename?: 'InjuryAreaToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** The InspectionInterval type */
export type InspectionInterval = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'InspectionInterval'
    /** Connection between the InspectionInterval type and the ContentNode type */
    contentNodes?: Maybe<InspectionIntervalToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** Connection between the InspectionInterval type and the Equipment type */
    equipment?: Maybe<InspectionIntervalToEquipmentConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    inspectionIntervalId?: Maybe<Scalars['Int']['output']>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the InspectionInterval type and the Taxonomy type */
    taxonomy?: Maybe<InspectionIntervalToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The InspectionInterval type */
export type InspectionIntervalContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<InspectionIntervalToContentNodeConnectionWhereArgs>
}

/** The InspectionInterval type */
export type InspectionIntervalEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The InspectionInterval type */
export type InspectionIntervalEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The InspectionInterval type */
export type InspectionIntervalEquipmentArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<InspectionIntervalToEquipmentConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum InspectionIntervalIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the InspectionInterval type and the ContentNode type */
export type InspectionIntervalToContentNodeConnection = {
  __typename?: 'InspectionIntervalToContentNodeConnection'
  /** Edges for the InspectionIntervalToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<InspectionIntervalToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type InspectionIntervalToContentNodeConnectionEdge = {
  __typename?: 'InspectionIntervalToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the InspectionIntervalToContentNodeConnection connection */
export type InspectionIntervalToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfInspectionIntervalEnum>>
  >
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<InspectionIntervalToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<InspectionIntervalToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type InspectionIntervalToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<InspectionIntervalToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<InspectionIntervalToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum InspectionIntervalToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type InspectionIntervalToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<
      InputMaybe<InspectionIntervalToContentNodeConnectionWhereArgsMetaArray>
    >
  >
  relation?: InputMaybe<RelationEnum>
}

export enum InspectionIntervalToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type InspectionIntervalToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<InspectionIntervalToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<InspectionIntervalToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type InspectionIntervalToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<
      InputMaybe<InspectionIntervalToContentNodeConnectionWhereArgsTaxArray>
    >
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum InspectionIntervalToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum InspectionIntervalToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the InspectionInterval type and the Equipment type */
export type InspectionIntervalToEquipmentConnection = {
  __typename?: 'InspectionIntervalToEquipmentConnection'
  /** Edges for the InspectionIntervalToEquipmentConnection connection */
  edges?: Maybe<Array<Maybe<InspectionIntervalToEquipmentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type InspectionIntervalToEquipmentConnectionEdge = {
  __typename?: 'InspectionIntervalToEquipmentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Arguments for filtering the InspectionIntervalToEquipmentConnection connection */
export type InspectionIntervalToEquipmentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<InspectionIntervalToEquipmentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<InspectionIntervalToEquipmentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type InspectionIntervalToEquipmentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<InspectionIntervalToEquipmentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<InspectionIntervalToEquipmentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum InspectionIntervalToEquipmentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type InspectionIntervalToEquipmentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<InspectionIntervalToEquipmentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum InspectionIntervalToEquipmentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type InspectionIntervalToEquipmentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<InspectionIntervalToEquipmentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<InspectionIntervalToEquipmentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type InspectionIntervalToEquipmentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<InspectionIntervalToEquipmentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum InspectionIntervalToEquipmentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum InspectionIntervalToEquipmentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the InspectionInterval type and the Taxonomy type */
export type InspectionIntervalToTaxonomyConnectionEdge = {
  __typename?: 'InspectionIntervalToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** The integration configuration */
export type IntegrationConfig = {
  __typename?: 'IntegrationConfig'
  /** The active integrations. */
  activeIntegrations?: Maybe<Array<Maybe<ActiveIntegration>>>
  /** Whether the integration sync is disabled on the frontend. */
  isSyncDisabled?: Maybe<Scalars['Boolean']['output']>
  /** Whether the integration sync is currently in progress. */
  isSyncInProgress?: Maybe<Scalars['Boolean']['output']>
  /** The last time the integration sync was completed. In the format of Y-m-d\TH:i:s */
  lastSyncDate?: Maybe<Scalars['String']['output']>
  /** The API data source for the integration */
  source?: Maybe<IntegrationSourceEnum>
}

/** The data type to sync via the API Integration */
export enum IntegrationSourceDataTypeEnum {
  /** Company data (mapped to the Company taxonomy). */
  Company = 'COMPANY',
  /** Employee data (mapped to the WordPress user). */
  Employee = 'EMPLOYEE',
  /** Equipment data (mapped to the WordPress Equipment taxonomy). */
  Equipment = 'EQUIPMENT',
  /** Job data (mapped to the WordPress Project taxonomy). */
  Job = 'JOB',
}

/** The integration API source */
export enum IntegrationSourceEnum {
  /** The Foundation API */
  Foundation = 'FOUNDATION',
}

/** The data type to sync via the API Integration */
export enum IntegrationWpDataTypeEnum {
  /** The `company` taxonomy. */
  Company = 'COMPANY',
  /** The `equipment` CPT. */
  Equipment = 'EQUIPMENT',
  /** The `project` CPT. */
  Project = 'PROJECT',
  /** The WP User object. */
  User = 'USER',
}

/** Language (Polylang) */
export type Language = {
  __typename?: 'Language'
  /** Language code (Polylang) */
  code?: Maybe<LanguageCodeEnum>
  /** Language term front page URL */
  homeUrl?: Maybe<Scalars['String']['output']>
  /** Language ID (Polylang) */
  id: Scalars['ID']['output']
  /** Language locale (Polylang) */
  locale?: Maybe<Scalars['String']['output']>
  /** Human readable language name (Polylang) */
  name?: Maybe<Scalars['String']['output']>
  /** Language term slug. Prefer the &quot;code&quot; field if possible (Polylang) */
  slug?: Maybe<Scalars['String']['output']>
}

/** Enum of all available language codes */
export enum LanguageCodeEnum {
  EnUs = 'EN_US',
  EsMx = 'ES_MX',
}

/** Filter item by specific language, default language or list all languages */
export enum LanguageCodeFilterEnum {
  All = 'ALL',
  Default = 'DEFAULT',
  EnUs = 'EN_US',
  EsMx = 'ES_MX',
}

/** The Lesson type */
export type Lesson = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithContentEditor &
  NodeWithExcerpt &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'Lesson'
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Lesson type and the Company type */
    companies?: Maybe<LessonToCompanyConnection>
    /** The content of the post. */
    content?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** Connection between the Lesson type and the Division type */
    divisions?: Maybe<LessonToDivisionConnection>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** The excerpt of the post. */
    excerpt?: Maybe<Scalars['String']['output']>
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the lesson object. */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** Connection between the Lesson type and the lessonCategory type */
    lessonCategories?: Maybe<LessonToLessonCategoryConnection>
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Lesson Details&quot; was set to Show in GraphQL. */
    lessonDetails?: Maybe<Lesson_Lessondetails>
    /** Connection between the Lesson type and the lessonDifficulty type */
    lessonDifficulties?: Maybe<LessonToLessonDifficultyConnection>
    /** Connection between the Lesson type and the Document type */
    lessonDocumentConnectionConnection?: Maybe<LessonToDocumentConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    lessonId: Scalars['Int']['output']
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /** Connection between the Lesson type and the Lesson type */
    preview?: Maybe<LessonToPreviewConnectionEdge>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Lesson type and the Provider type */
    providers?: Maybe<LessonToProviderConnection>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** Connection between the Lesson type and the TermNode type */
    terms?: Maybe<LessonToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** Connection between the Lesson type and the TrainingType type */
    trainingTypes?: Maybe<LessonToTrainingTypeConnection>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Lesson type */
export type LessonCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LessonToCompanyConnectionWhereArgs>
}

/** The Lesson type */
export type LessonContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The Lesson type */
export type LessonDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LessonToDivisionConnectionWhereArgs>
}

/** The Lesson type */
export type LessonEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Lesson type */
export type LessonEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Lesson type */
export type LessonExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The Lesson type */
export type LessonLessonCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LessonToLessonCategoryConnectionWhereArgs>
}

/** The Lesson type */
export type LessonLessonDifficultiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LessonToLessonDifficultyConnectionWhereArgs>
}

/** The Lesson type */
export type LessonLessonDocumentConnectionConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Lesson type */
export type LessonProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LessonToProviderConnectionWhereArgs>
}

/** The Lesson type */
export type LessonTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LessonToTermNodeConnectionWhereArgs>
}

/** The Lesson type */
export type LessonTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The Lesson type */
export type LessonTrainingTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LessonToTrainingTypeConnectionWhereArgs>
}

/** The lessonCategory type */
export type LessonCategory = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'LessonCategory'
    /** Connection between the LessonCategory type and the ContentNode type */
    contentNodes?: Maybe<LessonCategoryToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    lessonCategoryId?: Maybe<Scalars['Int']['output']>
    /** Connection between the LessonCategory type and the Lesson type */
    lessons?: Maybe<LessonCategoryToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the LessonCategory type and the Taxonomy type */
    taxonomy?: Maybe<LessonCategoryToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The lessonCategory type */
export type LessonCategoryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LessonCategoryToContentNodeConnectionWhereArgs>
}

/** The lessonCategory type */
export type LessonCategoryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The lessonCategory type */
export type LessonCategoryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The lessonCategory type */
export type LessonCategoryLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LessonCategoryToLessonConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum LessonCategoryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the LessonCategory type and the ContentNode type */
export type LessonCategoryToContentNodeConnection = {
  __typename?: 'LessonCategoryToContentNodeConnection'
  /** Edges for the LessonCategoryToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<LessonCategoryToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonCategoryToContentNodeConnectionEdge = {
  __typename?: 'LessonCategoryToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the LessonCategoryToContentNodeConnection connection */
export type LessonCategoryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfLessonCategoryEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<LessonCategoryToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<LessonCategoryToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type LessonCategoryToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<LessonCategoryToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<LessonCategoryToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum LessonCategoryToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type LessonCategoryToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<LessonCategoryToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum LessonCategoryToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type LessonCategoryToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<LessonCategoryToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<LessonCategoryToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type LessonCategoryToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<LessonCategoryToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum LessonCategoryToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum LessonCategoryToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the LessonCategory type and the Lesson type */
export type LessonCategoryToLessonConnection = {
  __typename?: 'LessonCategoryToLessonConnection'
  /** Edges for the LessonCategoryToLessonConnection connection */
  edges?: Maybe<Array<Maybe<LessonCategoryToLessonConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Lesson>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonCategoryToLessonConnectionEdge = {
  __typename?: 'LessonCategoryToLessonConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Lesson>
}

/** Arguments for filtering the LessonCategoryToLessonConnection connection */
export type LessonCategoryToLessonConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<LessonCategoryToLessonConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<LessonCategoryToLessonConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type LessonCategoryToLessonConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<LessonCategoryToLessonConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<LessonCategoryToLessonConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum LessonCategoryToLessonConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type LessonCategoryToLessonConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<LessonCategoryToLessonConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum LessonCategoryToLessonConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type LessonCategoryToLessonConnectionWhereArgsTaxArray = {
  field?: InputMaybe<LessonCategoryToLessonConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<LessonCategoryToLessonConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type LessonCategoryToLessonConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<LessonCategoryToLessonConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum LessonCategoryToLessonConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum LessonCategoryToLessonConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the LessonCategory type and the Taxonomy type */
export type LessonCategoryToTaxonomyConnectionEdge = {
  __typename?: 'LessonCategoryToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Set relationships between the Lesson to Companies */
export type LessonCompaniesInput = {
  /** If true, this will append the Company to existing related Companies. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<LessonCompaniesNodeInput>>>
}

/** List of Companies to connect the Lesson to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type LessonCompaniesNodeInput = {
  /** The description of the Company. This field is used to set a description of the Company if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Company. If present, this will be used to connect to the Lesson. If no existing Company exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Company. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Company. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The lessonDifficulty type */
export type LessonDifficulty = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'LessonDifficulty'
    /** Connection between the LessonDifficulty type and the ContentNode type */
    contentNodes?: Maybe<LessonDifficultyToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    lessonDifficultyId?: Maybe<Scalars['Int']['output']>
    /** Connection between the LessonDifficulty type and the Lesson type */
    lessons?: Maybe<LessonDifficultyToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the LessonDifficulty type and the Taxonomy type */
    taxonomy?: Maybe<LessonDifficultyToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The lessonDifficulty type */
export type LessonDifficultyContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LessonDifficultyToContentNodeConnectionWhereArgs>
}

/** The lessonDifficulty type */
export type LessonDifficultyEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The lessonDifficulty type */
export type LessonDifficultyEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The lessonDifficulty type */
export type LessonDifficultyLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LessonDifficultyToLessonConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum LessonDifficultyIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the LessonDifficulty type and the ContentNode type */
export type LessonDifficultyToContentNodeConnection = {
  __typename?: 'LessonDifficultyToContentNodeConnection'
  /** Edges for the LessonDifficultyToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<LessonDifficultyToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonDifficultyToContentNodeConnectionEdge = {
  __typename?: 'LessonDifficultyToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the LessonDifficultyToContentNodeConnection connection */
export type LessonDifficultyToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfLessonDifficultyEnum>>
  >
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<LessonDifficultyToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<LessonDifficultyToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type LessonDifficultyToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<LessonDifficultyToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<LessonDifficultyToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum LessonDifficultyToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type LessonDifficultyToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<LessonDifficultyToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum LessonDifficultyToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type LessonDifficultyToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<LessonDifficultyToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<LessonDifficultyToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type LessonDifficultyToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<LessonDifficultyToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum LessonDifficultyToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum LessonDifficultyToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the LessonDifficulty type and the Lesson type */
export type LessonDifficultyToLessonConnection = {
  __typename?: 'LessonDifficultyToLessonConnection'
  /** Edges for the LessonDifficultyToLessonConnection connection */
  edges?: Maybe<Array<Maybe<LessonDifficultyToLessonConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Lesson>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonDifficultyToLessonConnectionEdge = {
  __typename?: 'LessonDifficultyToLessonConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Lesson>
}

/** Arguments for filtering the LessonDifficultyToLessonConnection connection */
export type LessonDifficultyToLessonConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<LessonDifficultyToLessonConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<LessonDifficultyToLessonConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type LessonDifficultyToLessonConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<LessonDifficultyToLessonConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<LessonDifficultyToLessonConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum LessonDifficultyToLessonConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type LessonDifficultyToLessonConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<LessonDifficultyToLessonConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum LessonDifficultyToLessonConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type LessonDifficultyToLessonConnectionWhereArgsTaxArray = {
  field?: InputMaybe<LessonDifficultyToLessonConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<LessonDifficultyToLessonConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type LessonDifficultyToLessonConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<LessonDifficultyToLessonConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum LessonDifficultyToLessonConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum LessonDifficultyToLessonConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the LessonDifficulty type and the Taxonomy type */
export type LessonDifficultyToTaxonomyConnectionEdge = {
  __typename?: 'LessonDifficultyToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Set relationships between the Lesson to Divisions */
export type LessonDivisionsInput = {
  /** If true, this will append the Division to existing related Divisions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<LessonDivisionsNodeInput>>>
}

/** List of Divisions to connect the Lesson to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type LessonDivisionsNodeInput = {
  /** The description of the Division. This field is used to set a description of the Division if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Division. If present, this will be used to connect to the Lesson. If no existing Division exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Division. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Division. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum LessonIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the Lesson to lessonCategories */
export type LessonLessonCategoriesInput = {
  /** If true, this will append the lessonCategory to existing related lessonCategories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<LessonLessonCategoriesNodeInput>>>
}

/** List of lessonCategories to connect the Lesson to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type LessonLessonCategoriesNodeInput = {
  /** The description of the lessonCategory. This field is used to set a description of the lessonCategory if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the lessonCategory. If present, this will be used to connect to the Lesson. If no existing lessonCategory exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the lessonCategory. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the lessonCategory. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the Lesson to lessonDifficulties */
export type LessonLessonDifficultiesInput = {
  /** If true, this will append the lessonDifficulty to existing related lessonDifficulties. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<LessonLessonDifficultiesNodeInput>>>
}

/** List of lessonDifficulties to connect the Lesson to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type LessonLessonDifficultiesNodeInput = {
  /** The description of the lessonDifficulty. This field is used to set a description of the lessonDifficulty if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the lessonDifficulty. If present, this will be used to connect to the Lesson. If no existing lessonDifficulty exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the lessonDifficulty. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the lessonDifficulty. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the Lesson to Providers */
export type LessonProvidersInput = {
  /** If true, this will append the Provider to existing related Providers. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<LessonProvidersNodeInput>>>
}

/** List of Providers to connect the Lesson to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type LessonProvidersNodeInput = {
  /** The description of the Provider. This field is used to set a description of the Provider if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Provider. If present, this will be used to connect to the Lesson. If no existing Provider exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Provider. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Provider. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Connection between the Lesson type and the Company type */
export type LessonToCompanyConnection = {
  __typename?: 'LessonToCompanyConnection'
  /** Edges for the LessonToCompanyConnection connection */
  edges?: Maybe<Array<Maybe<LessonToCompanyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Company>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonToCompanyConnectionEdge = {
  __typename?: 'LessonToCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Company>
}

/** Arguments for filtering the LessonToCompanyConnection connection */
export type LessonToCompanyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Lesson type and the Division type */
export type LessonToDivisionConnection = {
  __typename?: 'LessonToDivisionConnection'
  /** Edges for the LessonToDivisionConnection connection */
  edges?: Maybe<Array<Maybe<LessonToDivisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Division>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonToDivisionConnectionEdge = {
  __typename?: 'LessonToDivisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Division>
}

/** Arguments for filtering the LessonToDivisionConnection connection */
export type LessonToDivisionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Lesson type and the Document type */
export type LessonToDocumentConnection = {
  __typename?: 'LessonToDocumentConnection'
  /** Edges for the LessonToDocumentConnection connection */
  edges?: Maybe<Array<Maybe<LessonToDocumentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Document>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonToDocumentConnectionEdge = {
  __typename?: 'LessonToDocumentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Document>
}

/** Connection between the Lesson type and the lessonCategory type */
export type LessonToLessonCategoryConnection = {
  __typename?: 'LessonToLessonCategoryConnection'
  /** Edges for the LessonToLessonCategoryConnection connection */
  edges?: Maybe<Array<Maybe<LessonToLessonCategoryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<LessonCategory>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonToLessonCategoryConnectionEdge = {
  __typename?: 'LessonToLessonCategoryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<LessonCategory>
}

/** Arguments for filtering the LessonToLessonCategoryConnection connection */
export type LessonToLessonCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Lesson type and the lessonDifficulty type */
export type LessonToLessonDifficultyConnection = {
  __typename?: 'LessonToLessonDifficultyConnection'
  /** Edges for the LessonToLessonDifficultyConnection connection */
  edges?: Maybe<Array<Maybe<LessonToLessonDifficultyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<LessonDifficulty>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonToLessonDifficultyConnectionEdge = {
  __typename?: 'LessonToLessonDifficultyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<LessonDifficulty>
}

/** Arguments for filtering the LessonToLessonDifficultyConnection connection */
export type LessonToLessonDifficultyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Lesson type and the Lesson type */
export type LessonToPreviewConnectionEdge = {
  __typename?: 'LessonToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Lesson>
}

/** Connection between the Lesson type and the Provider type */
export type LessonToProviderConnection = {
  __typename?: 'LessonToProviderConnection'
  /** Edges for the LessonToProviderConnection connection */
  edges?: Maybe<Array<Maybe<LessonToProviderConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Provider>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonToProviderConnectionEdge = {
  __typename?: 'LessonToProviderConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Provider>
}

/** Arguments for filtering the LessonToProviderConnection connection */
export type LessonToProviderConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Lesson type and the TermNode type */
export type LessonToTermNodeConnection = {
  __typename?: 'LessonToTermNodeConnection'
  /** Edges for the LessonToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<LessonToTermNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonToTermNodeConnectionEdge = {
  __typename?: 'LessonToTermNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TermNode>
}

/** Arguments for filtering the LessonToTermNodeConnection connection */
export type LessonToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Lesson type and the TrainingType type */
export type LessonToTrainingTypeConnection = {
  __typename?: 'LessonToTrainingTypeConnection'
  /** Edges for the LessonToTrainingTypeConnection connection */
  edges?: Maybe<Array<Maybe<LessonToTrainingTypeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TrainingType>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type LessonToTrainingTypeConnectionEdge = {
  __typename?: 'LessonToTrainingTypeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TrainingType>
}

/** Arguments for filtering the LessonToTrainingTypeConnection connection */
export type LessonToTrainingTypeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Set relationships between the Lesson to TrainingTypes */
export type LessonTrainingTypesInput = {
  /** If true, this will append the TrainingType to existing related TrainingTypes. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<LessonTrainingTypesNodeInput>>>
}

/** List of TrainingTypes to connect the Lesson to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type LessonTrainingTypesNodeInput = {
  /** The description of the TrainingType. This field is used to set a description of the TrainingType if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the TrainingType. If present, this will be used to connect to the Lesson. If no existing TrainingType exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the TrainingType. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the TrainingType. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Field Group */
export type Lesson_Lessondetails = AcfFieldGroup & {
  __typename?: 'Lesson_Lessondetails'
  /** Set to zero for a lesson that does not expire. */
  courseExpriyLengthInMonths?: Maybe<Scalars['Float']['output']>
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
  lessonAudioDescription?: Maybe<Scalars['String']['output']>
  lessonAudioEmbed?: Maybe<Scalars['String']['output']>
  lessonAudioTitle?: Maybe<Scalars['String']['output']>
  lessonDescription?: Maybe<Scalars['String']['output']>
  /** Gravity Forms Form quiz */
  lessonQuiz?: Maybe<GfForm>
  lessonVideoDescription?: Maybe<Scalars['String']['output']>
  lessonVideoEmbed?: Maybe<Scalars['String']['output']>
  lessonVideoTitle?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type ListField = FormField & {
  __typename?: 'ListField'
  /** The URL of the image to be used for the add row button. */
  addIconUrl?: Maybe<Scalars['String']['output']>
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
  /** The available choices for the list field. */
  choices?: Maybe<Array<Maybe<ListFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** The URL of the image to be used for the delete row button. */
  deleteIconUrl?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field should use multiple columns. Default is false. */
  hasColumns?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** List field value. */
  listValues?: Maybe<Array<Maybe<ListFieldValue>>>
  /** The maximum number of rows the user can add to the field. */
  maxRows?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** List choice values. */
export type ListFieldChoice = {
  __typename?: 'ListFieldChoice'
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** Input fields for a single List field item. */
export type ListFieldInput = {
  /** Input values for the specific listField row. */
  rowValues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** The individual properties for each element of the List value field. */
export type ListFieldValue = {
  __typename?: 'ListFieldValue'
  /** Input values. */
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

/** Input for the login mutation */
export type LoginInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The plain-text password for the user logging in. */
  password: Scalars['String']['input']
  /** The username used for login. Typically a unique or email address depending on specific configuration */
  username: Scalars['String']['input']
}

/** The payload for the login mutation */
export type LoginPayload = {
  __typename?: 'LoginPayload'
  /** JWT Token that can be used in future requests for Authentication */
  authToken?: Maybe<Scalars['String']['output']>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** A JWT token that can be used in future requests to get a refreshed jwtAuthToken. If the refresh token used in a request is revoked or otherwise invalid, a valid Auth token will NOT be issued in the response headers. */
  refreshToken?: Maybe<Scalars['String']['output']>
  /** The user that was logged in */
  user?: Maybe<User>
}

/** The Manufacturer type */
export type Manufacturer = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Manufacturer'
    /** Connection between the Manufacturer type and the ContentNode type */
    contentNodes?: Maybe<ManufacturerToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** Connection between the Manufacturer type and the Equipment type */
    equipment?: Maybe<ManufacturerToEquipmentConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    manufacturerId?: Maybe<Scalars['Int']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Manufacturer type and the Taxonomy type */
    taxonomy?: Maybe<ManufacturerToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Manufacturer type */
export type ManufacturerContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ManufacturerToContentNodeConnectionWhereArgs>
}

/** The Manufacturer type */
export type ManufacturerEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Manufacturer type */
export type ManufacturerEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Manufacturer type */
export type ManufacturerEquipmentArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ManufacturerToEquipmentConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ManufacturerIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the Manufacturer type and the ContentNode type */
export type ManufacturerToContentNodeConnection = {
  __typename?: 'ManufacturerToContentNodeConnection'
  /** Edges for the ManufacturerToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<ManufacturerToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ManufacturerToContentNodeConnectionEdge = {
  __typename?: 'ManufacturerToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the ManufacturerToContentNodeConnection connection */
export type ManufacturerToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfManufacturerEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ManufacturerToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ManufacturerToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ManufacturerToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ManufacturerToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ManufacturerToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ManufacturerToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ManufacturerToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<ManufacturerToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ManufacturerToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ManufacturerToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ManufacturerToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ManufacturerToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ManufacturerToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<ManufacturerToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ManufacturerToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ManufacturerToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Manufacturer type and the Equipment type */
export type ManufacturerToEquipmentConnection = {
  __typename?: 'ManufacturerToEquipmentConnection'
  /** Edges for the ManufacturerToEquipmentConnection connection */
  edges?: Maybe<Array<Maybe<ManufacturerToEquipmentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ManufacturerToEquipmentConnectionEdge = {
  __typename?: 'ManufacturerToEquipmentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Arguments for filtering the ManufacturerToEquipmentConnection connection */
export type ManufacturerToEquipmentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ManufacturerToEquipmentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ManufacturerToEquipmentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ManufacturerToEquipmentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ManufacturerToEquipmentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ManufacturerToEquipmentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ManufacturerToEquipmentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ManufacturerToEquipmentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<ManufacturerToEquipmentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ManufacturerToEquipmentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ManufacturerToEquipmentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ManufacturerToEquipmentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ManufacturerToEquipmentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ManufacturerToEquipmentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<ManufacturerToEquipmentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ManufacturerToEquipmentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ManufacturerToEquipmentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Manufacturer type and the Taxonomy type */
export type ManufacturerToTaxonomyConnectionEdge = {
  __typename?: 'ManufacturerToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** File details for a Media Item */
export type MediaDetails = {
  __typename?: 'MediaDetails'
  /** The filename of the mediaItem */
  file?: Maybe<Scalars['String']['output']>
  /** The height of the mediaItem */
  height?: Maybe<Scalars['Int']['output']>
  /** Meta information associated with the mediaItem */
  meta?: Maybe<MediaItemMeta>
  /** The available sizes of the mediaItem */
  sizes?: Maybe<Array<Maybe<MediaSize>>>
  /** The width of the mediaItem */
  width?: Maybe<Scalars['Int']['output']>
}

/** File details for a Media Item */
export type MediaDetailsSizesArgs = {
  exclude?: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>
  include?: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>
}

/** The mediaItem type */
export type MediaItem = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'MediaItem'
    /** Alternative text to display when resource is not displayed */
    altText?: Maybe<Scalars['String']['output']>
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** The caption for the resource */
    caption?: Maybe<Scalars['String']['output']>
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount?: Maybe<Scalars['Int']['output']>
    /** Whether the comments are open or closed for this particular post. */
    commentStatus?: Maybe<Scalars['String']['output']>
    /** Connection between the MediaItem type and the Comment type */
    comments?: Maybe<MediaItemToCommentConnection>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** Description of the image (stored as post_content) */
    description?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** The filesize in bytes of the resource */
    fileSize?: Maybe<Scalars['Int']['output']>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the attachment object. */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Polylang language */
    language?: Maybe<Language>
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** Details about the mediaItem */
    mediaDetails?: Maybe<MediaDetails>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    mediaItemId: Scalars['Int']['output']
    /** Url of the mediaItem */
    mediaItemUrl?: Maybe<Scalars['String']['output']>
    /** Type of resource */
    mediaType?: Maybe<Scalars['String']['output']>
    /** The mime type of the mediaItem */
    mimeType?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** The sizes attribute value for an image. */
    sizes?: Maybe<Scalars['String']['output']>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** Url of the mediaItem */
    sourceUrl?: Maybe<Scalars['String']['output']>
    /** The srcset attribute specifies the URL of the image to use in different situations. It is a comma separated string of urls and their widths. */
    srcSet?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** Get specific translation version of this object */
    translation?: Maybe<MediaItem>
    /** List all translated versions of this post */
    translations?: Maybe<Array<Maybe<MediaItem>>>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The mediaItem type */
export type MediaItemAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>
}

/** The mediaItem type */
export type MediaItemCaptionArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The mediaItem type */
export type MediaItemChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>
}

/** The mediaItem type */
export type MediaItemCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<MediaItemToCommentConnectionWhereArgs>
}

/** The mediaItem type */
export type MediaItemDescriptionArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The mediaItem type */
export type MediaItemEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The mediaItem type */
export type MediaItemEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The mediaItem type */
export type MediaItemFileSizeArgs = {
  size?: InputMaybe<MediaItemSizeEnum>
}

/** The mediaItem type */
export type MediaItemSizesArgs = {
  size?: InputMaybe<MediaItemSizeEnum>
}

/** The mediaItem type */
export type MediaItemSourceUrlArgs = {
  size?: InputMaybe<MediaItemSizeEnum>
}

/** The mediaItem type */
export type MediaItemSrcSetArgs = {
  size?: InputMaybe<MediaItemSizeEnum>
}

/** The mediaItem type */
export type MediaItemTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The mediaItem type */
export type MediaItemTranslationArgs = {
  language: LanguageCodeEnum
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum MediaItemIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a media item by its source url */
  SourceUrl = 'SOURCE_URL',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Meta connected to a MediaItem */
export type MediaItemMeta = {
  __typename?: 'MediaItemMeta'
  /** Aperture measurement of the media item. */
  aperture?: Maybe<Scalars['Float']['output']>
  /** Information about the camera used to create the media item. */
  camera?: Maybe<Scalars['String']['output']>
  /** The text string description associated with the media item. */
  caption?: Maybe<Scalars['String']['output']>
  /** Copyright information associated with the media item. */
  copyright?: Maybe<Scalars['String']['output']>
  /** The date/time when the media was created. */
  createdTimestamp?: Maybe<Scalars['Int']['output']>
  /** The original creator of the media item. */
  credit?: Maybe<Scalars['String']['output']>
  /** The focal length value of the media item. */
  focalLength?: Maybe<Scalars['Float']['output']>
  /** The ISO (International Organization for Standardization) value of the media item. */
  iso?: Maybe<Scalars['Int']['output']>
  /** List of keywords used to describe or identfy the media item. */
  keywords?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The vertical or horizontal aspect of the media item. */
  orientation?: Maybe<Scalars['String']['output']>
  /** The shutter speed information of the media item. */
  shutterSpeed?: Maybe<Scalars['Float']['output']>
  /** A useful title for the media item. */
  title?: Maybe<Scalars['String']['output']>
}

/** The size of the media item object. */
export enum MediaItemSizeEnum {
  /** MediaItem with the large size */
  Large = 'LARGE',
  /** MediaItem with the medium size */
  Medium = 'MEDIUM',
  /** MediaItem with the medium_large size */
  MediumLarge = 'MEDIUM_LARGE',
  /** MediaItem with the thumbnail size */
  Thumbnail = 'THUMBNAIL',
  /** MediaItem with the 1536x1536 size */
  '1536X1536' = '_1536X1536',
  /** MediaItem with the 2048x2048 size */
  '2048X2048' = '_2048X2048',
}

/** The status of the media item object. */
export enum MediaItemStatusEnum {
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the trash status */
  Trash = 'TRASH',
}

/** Connection between the MediaItem type and the Comment type */
export type MediaItemToCommentConnection = {
  __typename?: 'MediaItemToCommentConnection'
  /** Edges for the MediaItemToCommentConnection connection */
  edges?: Maybe<Array<Maybe<MediaItemToCommentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Comment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type MediaItemToCommentConnectionEdge = {
  __typename?: 'MediaItemToCommentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Comment>
}

/** Arguments for filtering the MediaItemToCommentConnection connection */
export type MediaItemToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>
}

/** Details of an available size for a media item */
export type MediaSize = {
  __typename?: 'MediaSize'
  /** The filename of the referenced size */
  file?: Maybe<Scalars['String']['output']>
  /** The filesize of the resource */
  fileSize?: Maybe<Scalars['Int']['output']>
  /** The height of the referenced size */
  height?: Maybe<Scalars['String']['output']>
  /** The mime type of the referenced size */
  mimeType?: Maybe<Scalars['String']['output']>
  /** The referenced size name */
  name?: Maybe<Scalars['String']['output']>
  /** The url of the referenced size */
  sourceUrl?: Maybe<Scalars['String']['output']>
  /** The width of the referenced size */
  width?: Maybe<Scalars['String']['output']>
}

/** Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme. */
export type Menu = DatabaseIdentifier &
  Node & {
    __typename?: 'Menu'
    /** The number of items in the menu */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** The globally unique identifier of the nav menu object. */
    id: Scalars['ID']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** The locations a menu is assigned to */
    locations?: Maybe<Array<Maybe<MenuLocationEnum>>>
    /**
     * WP ID of the nav menu.
     * @deprecated Deprecated in favor of the databaseId field
     */
    menuId?: Maybe<Scalars['Int']['output']>
    /** Connection between the Menu type and the MenuItem type */
    menuItems?: Maybe<MenuToMenuItemConnection>
    /** Display name of the menu. Equivalent to WP_Term-&gt;name. */
    name?: Maybe<Scalars['String']['output']>
    /** The url friendly name of the menu. Equivalent to WP_Term-&gt;slug */
    slug?: Maybe<Scalars['String']['output']>
  }

/** Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme. */
export type MenuMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<MenuToMenuItemConnectionWhereArgs>
}

/** Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu. */
export type MenuItem = DatabaseIdentifier &
  Node & {
    __typename?: 'MenuItem'
    /** Connection between the MenuItem type and the MenuItem type */
    childItems?: Maybe<MenuItemToMenuItemConnection>
    /** Connection from MenuItem to it&#039;s connected node */
    connectedNode?: Maybe<MenuItemToMenuItemLinkableConnectionEdge>
    /**
     * The object connected to this menu item.
     * @deprecated Deprecated in favor of the connectedNode field
     */
    connectedObject?: Maybe<MenuItemObjectUnion>
    /** Class attribute for the menu item link */
    cssClasses?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** Description of the menu item. */
    description?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the nav menu item object. */
    id: Scalars['ID']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Label or title of the menu item. */
    label?: Maybe<Scalars['String']['output']>
    /** Link relationship (XFN) of the menu item. */
    linkRelationship?: Maybe<Scalars['String']['output']>
    /** The locations the menu item&#039;s Menu is assigned to */
    locations?: Maybe<Array<Maybe<MenuLocationEnum>>>
    /** The Menu a MenuItem is part of */
    menu?: Maybe<MenuItemToMenuConnectionEdge>
    /**
     * WP ID of the menu item.
     * @deprecated Deprecated in favor of the databaseId field
     */
    menuItemId?: Maybe<Scalars['Int']['output']>
    /** Menu item order */
    order?: Maybe<Scalars['Int']['output']>
    /** The database id of the parent menu item or null if it is the root */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the parent nav menu item object. */
    parentId?: Maybe<Scalars['ID']['output']>
    /** Path for the resource. Relative path for internal resources. Absolute path for external resources. */
    path?: Maybe<Scalars['String']['output']>
    /** Target attribute for the menu item link. */
    target?: Maybe<Scalars['String']['output']>
    /** Title attribute for the menu item link */
    title?: Maybe<Scalars['String']['output']>
    /** The uri of the resource the menu item links to */
    uri?: Maybe<Scalars['String']['output']>
    /** URL or destination of the menu item. */
    url?: Maybe<Scalars['String']['output']>
  }

/** Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu. */
export type MenuItemChildItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<MenuItemToMenuItemConnectionWhereArgs>
}

/** Nodes that can be linked to as Menu Items */
export type MenuItemLinkable = {
  /** The unique resource identifier path */
  databaseId: Scalars['Int']['output']
  /** The unique resource identifier path */
  id: Scalars['ID']['output']
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuItemNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
}

/** Deprecated in favor of MenuItemLinkeable Interface */
export type MenuItemObjectUnion =
  | Category
  | Certification
  | Company
  | CorrectiveAction
  | CorrectiveActionStatus
  | DeficiencyType
  | Division
  | Document
  | DocumentTag
  | EntryId
  | Equipment
  | EquipmentStatus
  | EquipmentTag
  | FieldId
  | FormId
  | IncidentReport
  | IncidentReportClassification
  | IncidentReportStatus
  | IncidentType
  | InjuryArea
  | InspectionInterval
  | Lesson
  | LessonCategory
  | LessonDifficulty
  | Manufacturer
  | Page
  | Photo
  | Post
  | Project
  | ProjectStatus
  | ProjectType
  | Provider
  | RootCause
  | Severity
  | Tag
  | ToolboxTalk
  | ToolboxTalkCategory
  | TrainingType
  | UserCompany
  | UserDivision
  | UserLanguage
  | UserStatus
  | UserVaccineStatus

/** Connection between the MenuItem type and the Menu type */
export type MenuItemToMenuConnectionEdge = {
  __typename?: 'MenuItemToMenuConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Menu>
}

/** Connection between the MenuItem type and the MenuItem type */
export type MenuItemToMenuItemConnection = {
  __typename?: 'MenuItemToMenuItemConnection'
  /** Edges for the MenuItemToMenuItemConnection connection */
  edges?: Maybe<Array<Maybe<MenuItemToMenuItemConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MenuItem>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type MenuItemToMenuItemConnectionEdge = {
  __typename?: 'MenuItemToMenuItemConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<MenuItem>
}

/** Arguments for filtering the MenuItemToMenuItemConnection connection */
export type MenuItemToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']['input']>
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']['input']>
}

/** Connection between the MenuItem type and the MenuItemLinkable type */
export type MenuItemToMenuItemLinkableConnectionEdge = {
  __typename?: 'MenuItemToMenuItemLinkableConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<MenuItemLinkable>
}

/** Registered menu locations */
export enum MenuLocationEnum {
  /** Empty menu location */
  Empty = 'EMPTY',
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuNodeIdTypeEnum {
  /** Identify a menu node by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a menu node by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a menu node by the slug of menu location to which it is assigned */
  Location = 'LOCATION',
  /** Identify a menu node by its name */
  Name = 'NAME',
  /** Identify a menu node by its slug */
  Slug = 'SLUG',
}

/** Connection between the Menu type and the MenuItem type */
export type MenuToMenuItemConnection = {
  __typename?: 'MenuToMenuItemConnection'
  /** Edges for the MenuToMenuItemConnection connection */
  edges?: Maybe<Array<Maybe<MenuToMenuItemConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MenuItem>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type MenuToMenuItemConnectionEdge = {
  __typename?: 'MenuToMenuItemConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<MenuItem>
}

/** Arguments for filtering the MenuToMenuItemConnection connection */
export type MenuToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']['input']>
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']['input']>
}

/** The MimeType of the object */
export enum MimeTypeEnum {
  /** MimeType application/msword */
  ApplicationMsword = 'APPLICATION_MSWORD',
  /** MimeType application/pdf */
  ApplicationPdf = 'APPLICATION_PDF',
  /** MimeType application/vnd.apple.keynote */
  ApplicationVndAppleKeynote = 'APPLICATION_VND_APPLE_KEYNOTE',
  /** MimeType application/vnd.ms-excel */
  ApplicationVndMsExcel = 'APPLICATION_VND_MS_EXCEL',
  /** MimeType application/vnd.ms-excel.sheet.binary.macroEnabled.12 */
  ApplicationVndMsExcelSheetBinaryMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12',
  /** MimeType application/vnd.ms-excel.sheet.macroEnabled.12 */
  ApplicationVndMsExcelSheetMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint */
  ApplicationVndMsPowerpoint = 'APPLICATION_VND_MS_POWERPOINT',
  /** MimeType application/vnd.ms-powerpoint.presentation.macroEnabled.12 */
  ApplicationVndMsPowerpointPresentationMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint.slideshow.macroEnabled.12 */
  ApplicationVndMsPowerpointSlideshowMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12',
  /** MimeType application/vnd.ms-word.document.macroEnabled.12 */
  ApplicationVndMsWordDocumentMacroenabled_12 = 'APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12',
  /** MimeType application/vnd.oasis.opendocument.text */
  ApplicationVndOasisOpendocumentText = 'APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT',
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.presentation */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION',
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.slideshow */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlideshow = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW',
  /** MimeType application/vnd.openxmlformats-officedocument.spreadsheetml.sheet */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET',
  /** MimeType application/vnd.openxmlformats-officedocument.wordprocessingml.document */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT',
  /** MimeType audio/flac */
  AudioFlac = 'AUDIO_FLAC',
  /** MimeType audio/midi */
  AudioMidi = 'AUDIO_MIDI',
  /** MimeType audio/mpeg */
  AudioMpeg = 'AUDIO_MPEG',
  /** MimeType audio/ogg */
  AudioOgg = 'AUDIO_OGG',
  /** MimeType audio/wav */
  AudioWav = 'AUDIO_WAV',
  /** MimeType image/gif */
  ImageGif = 'IMAGE_GIF',
  /** MimeType image/jpeg */
  ImageJpeg = 'IMAGE_JPEG',
  /** MimeType image/png */
  ImagePng = 'IMAGE_PNG',
  /** MimeType text/csv */
  TextCsv = 'TEXT_CSV',
  /** MimeType video/3gpp */
  Video_3Gpp = 'VIDEO_3GPP',
  /** MimeType video/3gpp2 */
  Video_3Gpp2 = 'VIDEO_3GPP2',
  /** MimeType video/avi */
  VideoAvi = 'VIDEO_AVI',
  /** MimeType video/mp4 */
  VideoMp4 = 'VIDEO_MP4',
  /** MimeType video/mpeg */
  VideoMpeg = 'VIDEO_MPEG',
  /** MimeType video/ogg */
  VideoOgg = 'VIDEO_OGG',
  /** MimeType video/quicktime */
  VideoQuicktime = 'VIDEO_QUICKTIME',
  /** MimeType video/webm */
  VideoWebm = 'VIDEO_WEBM',
  /** MimeType video/x-flv */
  VideoXFlv = 'VIDEO_X_FLV',
}

/** A Gravity Forms   field. */
export type MultiSelectField = FormField & {
  __typename?: 'MultiSelectField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The free solo boolean to allow custom option for selected fields */
  allowCustomOption?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
  /** The available choices for the multiselect field. */
  choices?: Maybe<Array<Maybe<MultiSelectFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** An array of field values. */
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The label for the chosen value. */
  valuesText?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Multiselect choice values. */
export type MultiSelectFieldChoice = {
  __typename?: 'MultiSelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type NameField = FormField & {
  __typename?: 'NameField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** An array of the available properties for each input of the name field. */
  inputs?: Maybe<Array<Maybe<NameInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Name Field Specific Translations */
  nameTranslations?: Maybe<Array<Maybe<NameTranslation>>>
  /** Name field value. */
  nameValues?: Maybe<NameFieldValue>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Name choice values. */
export type NameFieldChoice = {
  __typename?: 'NameFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** Input fields for name field. */
export type NameFieldInput = {
  /** First name. */
  first?: InputMaybe<Scalars['String']['input']>
  /** Last name. */
  last?: InputMaybe<Scalars['String']['input']>
  /** Middle name. */
  middle?: InputMaybe<Scalars['String']['input']>
  /** Prefix, such as Mr., Mrs. etc. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** Suffix, such as Sr., Jr. etc. */
  suffix?: InputMaybe<Scalars['String']['input']>
}

/** The individual properties for each element of the Name value field. */
export type NameFieldValue = {
  __typename?: 'NameFieldValue'
  /** First name. */
  first?: Maybe<Scalars['String']['output']>
  /** Last name. */
  last?: Maybe<Scalars['String']['output']>
  /** Middle name. */
  middle?: Maybe<Scalars['String']['output']>
  /** Prefix, such as Mr., Mrs. etc. */
  prefix?: Maybe<Scalars['String']['output']>
  /** Suffix, such as Sr., Jr. etc. */
  suffix?: Maybe<Scalars['String']['output']>
}

/** Name input values. */
export type NameInputProperty = {
  __typename?: 'NameInputProperty'
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** The available choices for the name field. */
  choices?: Maybe<Array<Maybe<NameFieldChoice>>>
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Whether or not this field should be hidden. */
  isHidden?: Maybe<Scalars['Boolean']['output']>
  /** Key used to identify this input. */
  key?: Maybe<Scalars['String']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** Gravity Forms translation settings */
export type NameTranslation = {
  __typename?: 'NameTranslation'
  /** Description translation of the field */
  description?: Maybe<Scalars['String']['output']>
  /** First Name */
  first?: Maybe<Scalars['String']['output']>
  /** Label Translation of the field */
  label?: Maybe<Scalars['String']['output']>
  /** Last Name */
  last?: Maybe<Scalars['String']['output']>
  /** Middle Name */
  middle?: Maybe<Scalars['String']['output']>
  /** Language name */
  name?: Maybe<Scalars['String']['output']>
  /** Prefix Name */
  prefix?: Maybe<Scalars['String']['output']>
  /** Langauge slug used for the url of the form */
  slug?: Maybe<Scalars['String']['output']>
  /** Suffix Name */
  suffix?: Maybe<Scalars['String']['output']>
}

/** An object with an ID */
export type Node = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
}

/** A node that can have an author assigned to it */
export type NodeWithAuthor = {
  /** Connection between the NodeWithAuthor type and the User type */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>
  /** The database identifier of the author of the node */
  authorDatabaseId?: Maybe<Scalars['Int']['output']>
  /** The globally unique identifier of the author of the node */
  authorId?: Maybe<Scalars['ID']['output']>
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
}

/** Connection between the NodeWithAuthor type and the User type */
export type NodeWithAuthorToUserConnectionEdge = {
  __typename?: 'NodeWithAuthorToUserConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<User>
}

/** A node that can have comments associated with it */
export type NodeWithComments = {
  /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
  commentCount?: Maybe<Scalars['Int']['output']>
  /** Whether the comments are open or closed for this particular post. */
  commentStatus?: Maybe<Scalars['String']['output']>
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
}

/** A node that supports the content editor */
export type NodeWithContentEditor = {
  /** The content of the post. */
  content?: Maybe<Scalars['String']['output']>
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
}

/** A node that supports the content editor */
export type NodeWithContentEditorContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** A node that can have an excerpt */
export type NodeWithExcerpt = {
  /** The excerpt of the post. */
  excerpt?: Maybe<Scalars['String']['output']>
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
}

/** A node that can have an excerpt */
export type NodeWithExcerptExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** A node that can have a featured image set */
export type NodeWithFeaturedImage = {
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId?: Maybe<Scalars['ID']['output']>
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
}

/** Connection between the NodeWithFeaturedImage type and the MediaItem type */
export type NodeWithFeaturedImageToMediaItemConnectionEdge = {
  __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<MediaItem>
}

/** A node that can have a Gravity Forms form assigned to it. */
export type NodeWithForm = {
  /** The form object of the node. */
  form?: Maybe<GfForm>
  /** The database identifier of the form of the node. */
  formDatabaseId?: Maybe<Scalars['Int']['output']>
  /** The globally unique identifier of the form of the node. */
  formId?: Maybe<Scalars['ID']['output']>
}

/** A node that can have page attributes */
export type NodeWithPageAttributes = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']['output']>
}

/** A node that can have revisions */
export type NodeWithRevisions = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
  /** True if the node is a revision of another node */
  isRevision?: Maybe<Scalars['Boolean']['output']>
  /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>
}

/** Connection between the NodeWithRevisions type and the ContentNode type */
export type NodeWithRevisionsToContentNodeConnectionEdge = {
  __typename?: 'NodeWithRevisionsToContentNodeConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<ContentNode>
}

/** A node that can have a template associated with it */
export type NodeWithTemplate = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
  /** The template assigned to the node */
  template?: Maybe<ContentTemplate>
}

/** A node that NodeWith a title */
export type NodeWithTitle = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']['output']>
}

/** A node that NodeWith a title */
export type NodeWithTitleTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** A node that can have trackbacks and pingbacks */
export type NodeWithTrackbacks = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output']
  /** Whether the pings are open or closed for this particular post. */
  pingStatus?: Maybe<Scalars['String']['output']>
  /** URLs that have been pinged. */
  pinged?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** URLs queued to be pinged. */
  toPing?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

/** A Gravity Forms   field. */
export type NumberField = FormField & {
  __typename?: 'NumberField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** The formula used for the number field. */
  calculationFormula?: Maybe<Scalars['String']['output']>
  /** Specifies to how many decimal places the number should be rounded. This is available when `isCalculation` is true, but will return null if the number format is `CURRENCY` or if the calculation is set to `Do not round`. */
  calculationRounding?: Maybe<Scalars['Int']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Indicates whether the number field is a calculation. */
  isCalculation?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the format allowed for the number field. */
  numberFormat?: Maybe<NumberFieldFormatEnum>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Maximum allowed value for a number field. Values higher than the number specified by this property will cause the field to fail validation. */
  rangeMax?: Maybe<Scalars['Float']['output']>
  /** Minimum allowed value for a number field. Values lower than the number specified by this property will cause the field to fail validation. */
  rangeMin?: Maybe<Scalars['Float']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** The format allowed for the number field. . */
export enum NumberFieldFormatEnum {
  /** Currency format. */
  Currency = 'CURRENCY',
  /** Decimal-comma format (e.g. 9.999,99). */
  DecimalComma = 'DECIMAL_COMMA',
  /** Decimal-dot format (e.g. 9,999.99). */
  DecimalDot = 'DECIMAL_DOT',
}

/** OfflineModeData options that the user can select on the frontend to save name data collections to their devices. */
export enum OfflineModeDataCollectionNameEnum {
  /** Certifications CPTs. */
  Certifications = 'Certifications',
  /** Corrective Action CPTs. */
  CorrectiveActions = 'CorrectiveActions',
  /** Documents CPTs. */
  Documents = 'Documents',
  /** Equipment CPTs. */
  Equipment = 'Equipment',
  /** Lessons CPTs. */
  Lessons = 'Lessons',
  /** Photos CPTs. */
  Photos = 'Photos',
  /** Projects CPTs. */
  Projects = 'Projects',
  /** Toolbox Talks CPTs. */
  ToolboxTalks = 'ToolboxTalks',
  /** Workers CPTs. */
  Workers = 'Workers',
}

/** The cardinality of the connection order */
export enum OrderEnum {
  /** Sort the query result set in an ascending order */
  Asc = 'ASC',
  /** Sort the query result set in a descending order */
  Desc = 'DESC',
}

/** The page type */
export type Page = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithFeaturedImage &
  NodeWithPageAttributes &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'Page'
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount?: Maybe<Scalars['Int']['output']>
    /** Whether the comments are open or closed for this particular post. */
    commentStatus?: Maybe<Scalars['String']['output']>
    /** Connection between the Page type and the Comment type */
    comments?: Maybe<PageToCommentConnection>
    /** The content of the post. */
    content?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the page object. */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether this page is set to the static front page. */
    isFrontPage: Scalars['Boolean']['output']
    /** Whether this page is set to the blog posts page. */
    isPostsPage: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether this page is set to the privacy page. */
    isPrivacyPage: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** True if the node is a revision of another node */
    isRevision?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Polylang language */
    language?: Maybe<Language>
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder?: Maybe<Scalars['Int']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    pageId: Scalars['Int']['output']
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Page type and the page type */
    preview?: Maybe<PageToPreviewConnectionEdge>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
    revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>
    /** Connection between the Page type and the page type */
    revisions?: Maybe<PageToRevisionConnection>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** Get specific translation version of this object */
    translation?: Maybe<Page>
    /** List all translated versions of this post */
    translations?: Maybe<Array<Maybe<Page>>>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The page type */
export type PageAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>
}

/** The page type */
export type PageChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>
}

/** The page type */
export type PageCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PageToCommentConnectionWhereArgs>
}

/** The page type */
export type PageContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The page type */
export type PageEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The page type */
export type PageEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The page type */
export type PageRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PageToRevisionConnectionWhereArgs>
}

/** The page type */
export type PageTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The page type */
export type PageTranslationArgs = {
  language: LanguageCodeEnum
}

/** A Gravity Forms   field. */
export type PageField = FormField & {
  __typename?: 'PageField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** An array containing the the individual properties for the &quot;Next&quot; button. */
  nextButton?: Maybe<FormButton>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** An array containing the the individual properties for the &quot;Previous&quot; button. */
  previousButton?: Maybe<FormButton>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PageIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Connection between the Page type and the Comment type */
export type PageToCommentConnection = {
  __typename?: 'PageToCommentConnection'
  /** Edges for the PageToCommentConnection connection */
  edges?: Maybe<Array<Maybe<PageToCommentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Comment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PageToCommentConnectionEdge = {
  __typename?: 'PageToCommentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Comment>
}

/** Arguments for filtering the PageToCommentConnection connection */
export type PageToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>
}

/** Connection between the Page type and the page type */
export type PageToPreviewConnectionEdge = {
  __typename?: 'PageToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Page>
}

/** Connection between the Page type and the page type */
export type PageToRevisionConnection = {
  __typename?: 'PageToRevisionConnection'
  /** Edges for the PageToRevisionConnection connection */
  edges?: Maybe<Array<Maybe<PageToRevisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Page>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PageToRevisionConnectionEdge = {
  __typename?: 'PageToRevisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Page>
}

/** Arguments for filtering the PageToRevisionConnection connection */
export type PageToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<PageToRevisionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<PageToRevisionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type PageToRevisionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<PageToRevisionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<PageToRevisionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum PageToRevisionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type PageToRevisionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<PageToRevisionConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum PageToRevisionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type PageToRevisionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<PageToRevisionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<PageToRevisionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type PageToRevisionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<PageToRevisionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum PageToRevisionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum PageToRevisionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** A Gravity Forms   field. */
export type PasswordField = FormField & {
  __typename?: 'PasswordField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Indicates whether the field displays the password strength indicator. */
  hasPasswordStrengthIndicator?: Maybe<Scalars['Boolean']['output']>
  /** Whether the Password visibility toggle should be enabled for this field. */
  hasPasswordVisibilityToggle?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** An array of the available properties for each input of the password field. */
  inputs?: Maybe<Array<Maybe<PasswordInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Indicates how strong the password should be. */
  minPasswordStrength?: Maybe<PasswordFieldMinStrengthEnum>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Indicates how strong the password should be. */
export enum PasswordFieldMinStrengthEnum {
  /** The password strength must be "bad" or better. */
  Bad = 'BAD',
  /** The password strength must be "good" or better. */
  Good = 'GOOD',
  /** The password strength must be "short" or better. */
  Short = 'SHORT',
  /** The password strength must be "strong". */
  Strong = 'STRONG',
}

/** Password input values. */
export type PasswordInputProperty = {
  __typename?: 'PasswordInputProperty'
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Whether or not this field should be hidden. */
  isHidden?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** Gravity Forms Photo field. */
export type Pdf = {
  __typename?: 'Pdf'
  /** Date the pdf was created */
  dateCreated?: Maybe<Scalars['String']['output']>
  /** Url to download the pdf */
  pdfUrl?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type PhoneField = FormField & {
  __typename?: 'PhoneField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Determines the allowed format for phones. If the phone value does not conform with the specified format, the field will fail validation. */
  phoneFormat?: Maybe<PhoneFieldFormatEnum>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Tthe allowed format for phone numbers. */
export enum PhoneFieldFormatEnum {
  /** International phone number format. */
  International = 'INTERNATIONAL',
  /** Standard phone number format. */
  Standard = 'STANDARD',
}

/** The Photo type */
export type Photo = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'Photo'
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** IDs for the CorrectiveActions associated with this Photo. */
    correctiveActionIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
    /** Connection between the Photo type and the CorrectiveAction type */
    correctiveActionPhotoConnection?: Maybe<PhotoToCorrectiveActionConnection>
    /** The Corrective Action posts associated with this Photo. */
    correctiveActions?: Maybe<Array<Maybe<Post>>>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** Date the photo was taken */
    dateTaken?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** The Gravity Forms Entry associated with this Photo. */
    entry?: Maybe<GfEntry>
    /** Connection between the Photo type and the EntryId type */
    entryIds?: Maybe<PhotoToEntryIdConnection>
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Photo type and the FieldId type */
    fieldIds?: Maybe<PhotoToFieldIdConnection>
    /** Connection between the Photo type and the FormId type */
    formIds?: Maybe<PhotoToFormIdConnection>
    /** Geolocation data for the image. */
    geolocation?: Maybe<Geolocation>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the photo object. */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    photoId: Scalars['Int']['output']
    /** Connection between the Photo type and the Photo type */
    preview?: Maybe<PhotoToPreviewConnectionEdge>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Photo type and the Project type */
    projectPhotoConnection?: Maybe<PhotoToProjectConnection>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** Connection between the Photo type and the TermNode type */
    terms?: Maybe<PhotoToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Photo type */
export type PhotoCorrectiveActionPhotoConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Photo type */
export type PhotoEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Photo type */
export type PhotoEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Photo type */
export type PhotoEntryIdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PhotoToEntryIdConnectionWhereArgs>
}

/** The Photo type */
export type PhotoFieldIdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PhotoToFieldIdConnectionWhereArgs>
}

/** The Photo type */
export type PhotoFormIdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PhotoToFormIdConnectionWhereArgs>
}

/** The Photo type */
export type PhotoProjectPhotoConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Photo type */
export type PhotoTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PhotoToTermNodeConnectionWhereArgs>
}

/** The Photo type */
export type PhotoTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** Set relationships between the Photo to EntryIds */
export type PhotoEntryIdsInput = {
  /** If true, this will append the EntryId to existing related EntryIds. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PhotoEntryIdsNodeInput>>>
}

/** List of EntryIds to connect the Photo to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PhotoEntryIdsNodeInput = {
  /** The description of the EntryId. This field is used to set a description of the EntryId if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the EntryId. If present, this will be used to connect to the Photo. If no existing EntryId exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the EntryId. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the EntryId. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** A Gravity Forms   field. */
export type PhotoField = FormField & {
  __typename?: 'PhotoField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Photo posts. */
  photoValues?: Maybe<Array<Maybe<Photo>>>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Set relationships between the Photo to FieldIds */
export type PhotoFieldIdsInput = {
  /** If true, this will append the FieldId to existing related FieldIds. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PhotoFieldIdsNodeInput>>>
}

/** List of FieldIds to connect the Photo to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PhotoFieldIdsNodeInput = {
  /** The description of the FieldId. This field is used to set a description of the FieldId if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the FieldId. If present, this will be used to connect to the Photo. If no existing FieldId exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the FieldId. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the FieldId. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the Photo to FormIds */
export type PhotoFormIdsInput = {
  /** If true, this will append the FormId to existing related FormIds. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PhotoFormIdsNodeInput>>>
}

/** List of FormIds to connect the Photo to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PhotoFormIdsNodeInput = {
  /** The description of the FormId. This field is used to set a description of the FormId if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the FormId. If present, this will be used to connect to the Photo. If no existing FormId exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the FormId. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the FormId. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Image geolocation input fields. */
export type PhotoGeolocationInput = {
  /** Latitude coordinate. */
  lat?: InputMaybe<Scalars['Float']['input']>
  /** Longitude coordinate. */
  lng?: InputMaybe<Scalars['Float']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PhotoIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Connection between the Photo type and the CorrectiveAction type */
export type PhotoToCorrectiveActionConnection = {
  __typename?: 'PhotoToCorrectiveActionConnection'
  /** Edges for the PhotoToCorrectiveActionConnection connection */
  edges?: Maybe<Array<Maybe<PhotoToCorrectiveActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PhotoToCorrectiveActionConnectionEdge = {
  __typename?: 'PhotoToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Connection between the Photo type and the EntryId type */
export type PhotoToEntryIdConnection = {
  __typename?: 'PhotoToEntryIdConnection'
  /** Edges for the PhotoToEntryIdConnection connection */
  edges?: Maybe<Array<Maybe<PhotoToEntryIdConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EntryId>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PhotoToEntryIdConnectionEdge = {
  __typename?: 'PhotoToEntryIdConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EntryId>
}

/** Arguments for filtering the PhotoToEntryIdConnection connection */
export type PhotoToEntryIdConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Photo type and the FieldId type */
export type PhotoToFieldIdConnection = {
  __typename?: 'PhotoToFieldIdConnection'
  /** Edges for the PhotoToFieldIdConnection connection */
  edges?: Maybe<Array<Maybe<PhotoToFieldIdConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<FieldId>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PhotoToFieldIdConnectionEdge = {
  __typename?: 'PhotoToFieldIdConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<FieldId>
}

/** Arguments for filtering the PhotoToFieldIdConnection connection */
export type PhotoToFieldIdConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Photo type and the FormId type */
export type PhotoToFormIdConnection = {
  __typename?: 'PhotoToFormIdConnection'
  /** Edges for the PhotoToFormIdConnection connection */
  edges?: Maybe<Array<Maybe<PhotoToFormIdConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<FormId>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PhotoToFormIdConnectionEdge = {
  __typename?: 'PhotoToFormIdConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<FormId>
}

/** Arguments for filtering the PhotoToFormIdConnection connection */
export type PhotoToFormIdConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Photo type and the Photo type */
export type PhotoToPreviewConnectionEdge = {
  __typename?: 'PhotoToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Photo>
}

/** Connection between the Photo type and the Project type */
export type PhotoToProjectConnection = {
  __typename?: 'PhotoToProjectConnection'
  /** Edges for the PhotoToProjectConnection connection */
  edges?: Maybe<Array<Maybe<PhotoToProjectConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PhotoToProjectConnectionEdge = {
  __typename?: 'PhotoToProjectConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Connection between the Photo type and the TermNode type */
export type PhotoToTermNodeConnection = {
  __typename?: 'PhotoToTermNodeConnection'
  /** Edges for the PhotoToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<PhotoToTermNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PhotoToTermNodeConnectionEdge = {
  __typename?: 'PhotoToTermNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TermNode>
}

/** Arguments for filtering the PhotoToTermNodeConnection connection */
export type PhotoToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Photo values input fields. */
export type PhotoValuesInput = {
  /** The date the photo was taken in "yyyy:mm:dd hh:mm:ss" format. */
  dateTaken?: InputMaybe<Scalars['String']['input']>
  /** The filename for the photo. */
  filename?: InputMaybe<Scalars['String']['input']>
  /** Geolocation data for the image. */
  geolocation?: InputMaybe<PhotoGeolocationInput>
  /** Base-64 encoded image. */
  image?: InputMaybe<Scalars['String']['input']>
  /** Project databaseId for the CPT Project */
  projectId?: InputMaybe<Scalars['Int']['input']>
  /** User ID for the user who uploaded the photo. */
  uploadedBy?: InputMaybe<Scalars['Int']['input']>
}

/** An plugin object */
export type Plugin = Node & {
  __typename?: 'Plugin'
  /** Name of the plugin author(s), may also be a company name. */
  author?: Maybe<Scalars['String']['output']>
  /** URI for the related author(s)/company website. */
  authorUri?: Maybe<Scalars['String']['output']>
  /** Description of the plugin. */
  description?: Maybe<Scalars['String']['output']>
  /** The globally unique identifier of the plugin object. */
  id: Scalars['ID']['output']
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>
  /** Display name of the plugin. */
  name?: Maybe<Scalars['String']['output']>
  /** Plugin path. */
  path?: Maybe<Scalars['String']['output']>
  /** URI for the plugin website. This is useful for directing users for support requests etc. */
  pluginUri?: Maybe<Scalars['String']['output']>
  /** Current version of the plugin. */
  version?: Maybe<Scalars['String']['output']>
}

/** The status of the WordPress plugin. */
export enum PluginStatusEnum {
  /** The plugin is currently active. */
  Active = 'ACTIVE',
  /** The plugin is a drop-in plugin. */
  DropIn = 'DROP_IN',
  /** The plugin is currently inactive. */
  Inactive = 'INACTIVE',
  /** The plugin is a must-use plugin. */
  MustUse = 'MUST_USE',
  /** The plugin is activated on the multisite network. */
  NetworkActivated = 'NETWORK_ACTIVATED',
  /** The plugin is installed on the multisite network, but is currently inactive. */
  NetworkInactive = 'NETWORK_INACTIVE',
  /** The plugin is technically active but was paused while loading. */
  Paused = 'PAUSED',
  /** The plugin was active recently. */
  RecentlyActive = 'RECENTLY_ACTIVE',
  /** The plugin has an upgrade available. */
  Upgrade = 'UPGRADE',
}

/** The post type */
export type Post = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithExcerpt &
  NodeWithFeaturedImage &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  NodeWithTrackbacks &
  UniformResourceIdentifiable & {
    __typename?: 'Post'
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Post type and the category type */
    categories?: Maybe<PostToCategoryConnection>
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount?: Maybe<Scalars['Int']['output']>
    /** Whether the comments are open or closed for this particular post. */
    commentStatus?: Maybe<Scalars['String']['output']>
    /** Connection between the Post type and the Comment type */
    comments?: Maybe<PostToCommentConnection>
    /** The content of the post. */
    content?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** The excerpt of the post. */
    excerpt?: Maybe<Scalars['String']['output']>
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the post object. */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** True if the node is a revision of another node */
    isRevision?: Maybe<Scalars['Boolean']['output']>
    /** Whether this page is sticky */
    isSticky: Scalars['Boolean']['output']
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Polylang language */
    language?: Maybe<Language>
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /** Whether the pings are open or closed for this particular post. */
    pingStatus?: Maybe<Scalars['String']['output']>
    /** URLs that have been pinged. */
    pinged?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Connection between the Post type and the postFormat type */
    postFormats?: Maybe<PostToPostFormatConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    postId: Scalars['Int']['output']
    /** Connection between the Post type and the post type */
    preview?: Maybe<PostToPreviewConnectionEdge>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
    revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>
    /** Connection between the Post type and the post type */
    revisions?: Maybe<PostToRevisionConnection>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** Connection between the Post type and the tag type */
    tags?: Maybe<PostToTagConnection>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** Connection between the Post type and the TermNode type */
    terms?: Maybe<PostToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** URLs queued to be pinged. */
    toPing?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Get specific translation version of this object */
    translation?: Maybe<Post>
    /** List all translated versions of this post */
    translations?: Maybe<Array<Maybe<Post>>>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The post type */
export type PostCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PostToCategoryConnectionWhereArgs>
}

/** The post type */
export type PostCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PostToCommentConnectionWhereArgs>
}

/** The post type */
export type PostContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The post type */
export type PostEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The post type */
export type PostEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The post type */
export type PostExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The post type */
export type PostPostFormatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PostToPostFormatConnectionWhereArgs>
}

/** The post type */
export type PostRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PostToRevisionConnectionWhereArgs>
}

/** The post type */
export type PostTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PostToTagConnectionWhereArgs>
}

/** The post type */
export type PostTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PostToTermNodeConnectionWhereArgs>
}

/** The post type */
export type PostTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The post type */
export type PostTranslationArgs = {
  language: LanguageCodeEnum
}

/** Set relationships between the post to categories */
export type PostCategoriesInput = {
  /** If true, this will append the category to existing related categories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostCategoriesNodeInput>>>
}

/** List of categories to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostCategoriesNodeInput = {
  /** The description of the category. This field is used to set a description of the category if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the category. If present, this will be used to connect to the post. If no existing category exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the category. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the category. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** A Gravity Forms  post_category field. */
export type PostCategoryCheckboxField = FormField &
  PostCategoryField & {
    __typename?: 'PostCategoryCheckboxField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Checkbox field value. */
    checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_category field. */
    choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** The dropdown placeholder for the field. */
    dropdownPlaceholder?: Maybe<Scalars['String']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
    hasAllCategories?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Whether the \&quot;select all\&quot; choice should be displayed. */
    hasSelectAll?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_category field. */
    inputs?: Maybe<Array<Maybe<PostCategoryInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms   field. */
export type PostCategoryField = {
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
  /** The available choices for the post_category field. */
  choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** The dropdown placeholder for the field. */
  dropdownPlaceholder?: Maybe<Scalars['String']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
  hasAllCategories?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Post_category choice values. */
export type PostCategoryFieldChoice = {
  __typename?: 'PostCategoryFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** Post_category input values. */
export type PostCategoryInputProperty = {
  __typename?: 'PostCategoryInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_category field. */
export type PostCategoryMultiSelectField = FormField &
  PostCategoryField & {
    __typename?: 'PostCategoryMultiSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_category field. */
    choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** The dropdown placeholder for the field. */
    dropdownPlaceholder?: Maybe<Scalars['String']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
    hasAllCategories?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** An array of field values. */
    values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_category field. */
export type PostCategoryRadioField = FormField &
  PostCategoryField & {
    __typename?: 'PostCategoryRadioField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_category field. */
    choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** The dropdown placeholder for the field. */
    dropdownPlaceholder?: Maybe<Scalars['String']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
    hasAllCategories?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
    hasOtherChoice?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_category field. */
export type PostCategorySelectField = FormField &
  PostCategoryField & {
    __typename?: 'PostCategorySelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_category field. */
    choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** The dropdown placeholder for the field. */
    dropdownPlaceholder?: Maybe<Scalars['String']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
    hasAllCategories?: Maybe<Scalars['Boolean']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms   field. */
export type PostContentField = FormField & {
  __typename?: 'PostContentField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Indicates whether the field uses the rich text editor interface. */
  hasRichTextEditor?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** A Gravity Forms  post_custom_field field. */
export type PostCustomCheckboxField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomCheckboxField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Checkbox field value. */
    checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_custom_field field. */
    choices?: Maybe<Array<Maybe<PostCustomCheckboxFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Whether the \&quot;select all\&quot; choice should be displayed. */
    hasSelectAll?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_custom_field field. */
    inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** Post_custom_field choice values. */
export type PostCustomCheckboxFieldChoice = {
  __typename?: 'PostCustomCheckboxFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type PostCustomDateField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomDateField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines how the date field displays it’s calendar icon. */
    calendarIconType?: Maybe<FormFieldCalendarIconTypeEnum>
    /** Contains the URL to the custom calendar icon. Only applicable when calendarIconType is set to custom. */
    calendarIconUrl?: Maybe<Scalars['String']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Determines how the date is displayed. */
    dateFormat?: Maybe<DateFieldFormatEnum>
    /** The type of date field to display. */
    dateType?: Maybe<DateFieldTypeEnum>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_custom_field field. */
    inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
    subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type PostCustomEmailField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomEmailField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Determines whether the Confirm Email field is active. */
    hasEmailConfirmation?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_custom_field field. */
    inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
    subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms   field. */
export type PostCustomField = {
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** A Gravity Forms  post_custom_field field. */
export type PostCustomFileuploadField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomFileuploadField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** A comma-delimited list of the file extensions which may be uploaded. */
    allowedExtensions?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Indicates whether multiple files may be uploaded. */
    canAcceptMultipleFiles?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** File upload value */
    fileUploadValues?: Maybe<Array<Maybe<FileUploadFieldValue>>>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The maximum size (in MB) an uploaded file may be . */
    maxFileSize?: Maybe<Scalars['Int']['output']>
    /** When the field is set to allow multiple files to be uploaded, this property is available to set a limit on how many may be uploaded. */
    maxFiles?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /**
     * An array of field values.
     * @deprecated Use `fileUploadValues` instead.
     */
    values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type PostCustomHiddenField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomHiddenField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** Post_custom_field input values. */
export type PostCustomInputProperty = {
  __typename?: 'PostCustomInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type PostCustomListField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomListField'
    /** The URL of the image to be used for the add row button. */
    addIconUrl?: Maybe<Scalars['String']['output']>
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_custom_field field. */
    choices?: Maybe<Array<Maybe<PostCustomListFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** The URL of the image to be used for the delete row button. */
    deleteIconUrl?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field should use multiple columns. Default is false. */
    hasColumns?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** List field value. */
    listValues?: Maybe<Array<Maybe<ListFieldValue>>>
    /** The maximum number of rows the user can add to the field. */
    maxRows?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** Post_custom_field choice values. */
export type PostCustomListFieldChoice = {
  __typename?: 'PostCustomListFieldChoice'
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type PostCustomMultiSelectField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomMultiSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_custom_field field. */
    choices?: Maybe<Array<Maybe<PostCustomMultiSelectFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** An array of field values. */
    values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** Post_custom_field choice values. */
export type PostCustomMultiSelectFieldChoice = {
  __typename?: 'PostCustomMultiSelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type PostCustomNumberField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomNumberField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** The formula used for the number field. */
    calculationFormula?: Maybe<Scalars['String']['output']>
    /** Specifies to how many decimal places the number should be rounded. This is available when `isCalculation` is true, but will return null if the number format is `CURRENCY` or if the calculation is set to `Do not round`. */
    calculationRounding?: Maybe<Scalars['Int']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Indicates whether the number field is a calculation. */
    isCalculation?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** Specifies the format allowed for the number field. */
    numberFormat?: Maybe<NumberFieldFormatEnum>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Maximum allowed value for a number field. Values higher than the number specified by this property will cause the field to fail validation. */
    rangeMax?: Maybe<Scalars['Float']['output']>
    /** Minimum allowed value for a number field. Values lower than the number specified by this property will cause the field to fail validation. */
    rangeMin?: Maybe<Scalars['Float']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type PostCustomPhoneField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomPhoneField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Determines the allowed format for phones. If the phone value does not conform with the specified format, the field will fail validation. */
    phoneFormat?: Maybe<PhoneFieldFormatEnum>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type PostCustomRadioField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomRadioField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_custom_field field. */
    choices?: Maybe<Array<Maybe<PostCustomRadioFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
    hasOtherChoice?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** Post_custom_field choice values. */
export type PostCustomRadioFieldChoice = {
  __typename?: 'PostCustomRadioFieldChoice'
  /** Indicates the radio button item is the “Other” choice. */
  isOtherChoice?: Maybe<Scalars['Boolean']['output']>
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type PostCustomSelectField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_custom_field field. */
    choices?: Maybe<Array<Maybe<PostCustomSelectFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** Post_custom_field choice values. */
export type PostCustomSelectFieldChoice = {
  __typename?: 'PostCustomSelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_custom_field field. */
export type PostCustomTextAreaField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomTextAreaField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Indicates whether the field uses the rich text editor interface. */
    hasRichTextEditor?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
    maxLength?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type PostCustomTextField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomTextField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Whether the field has an input mask. */
    hasInputMask?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** The pattern used for the input mask. */
    inputMaskValue?: Maybe<Scalars['String']['output']>
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if a text field input tag should be created with a &quot;password&quot; type. */
    isPasswordInput?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
    maxLength?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type PostCustomTimeField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomTimeField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_custom_field field. */
    inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
    subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>
    /** Determines how the time is displayed. */
    timeFormat?: Maybe<TimeFieldFormatEnum>
    /** Time field value. */
    timeValues?: Maybe<TimeFieldValue>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms  post_custom_field field. */
export type PostCustomWebsiteField = FormField &
  PostCustomField & {
    __typename?: 'PostCustomWebsiteField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** The post meta key to which the value should be assigned. */
    postMetaFieldName?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms   field. */
export type PostExcerptField = FormField & {
  __typename?: 'PostExcerptField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** The postFormat type */
export type PostFormat = DatabaseIdentifier &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'PostFormat'
    /** Connection between the PostFormat type and the ContentNode type */
    contentNodes?: Maybe<PostFormatToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    postFormatId?: Maybe<Scalars['Int']['output']>
    /** Connection between the PostFormat type and the post type */
    posts?: Maybe<PostFormatToPostConnection>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the PostFormat type and the Taxonomy type */
    taxonomy?: Maybe<PostFormatToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The postFormat type */
export type PostFormatContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PostFormatToContentNodeConnectionWhereArgs>
}

/** The postFormat type */
export type PostFormatEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The postFormat type */
export type PostFormatEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The postFormat type */
export type PostFormatPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PostFormatToPostConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostFormatIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the PostFormat type and the ContentNode type */
export type PostFormatToContentNodeConnection = {
  __typename?: 'PostFormatToContentNodeConnection'
  /** Edges for the PostFormatToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<PostFormatToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PostFormatToContentNodeConnectionEdge = {
  __typename?: 'PostFormatToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the PostFormatToContentNodeConnection connection */
export type PostFormatToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfPostFormatEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<PostFormatToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<PostFormatToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type PostFormatToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<PostFormatToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<PostFormatToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum PostFormatToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type PostFormatToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<PostFormatToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum PostFormatToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type PostFormatToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<PostFormatToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<PostFormatToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type PostFormatToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<PostFormatToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum PostFormatToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum PostFormatToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the PostFormat type and the post type */
export type PostFormatToPostConnection = {
  __typename?: 'PostFormatToPostConnection'
  /** Edges for the PostFormatToPostConnection connection */
  edges?: Maybe<Array<Maybe<PostFormatToPostConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PostFormatToPostConnectionEdge = {
  __typename?: 'PostFormatToPostConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Post>
}

/** Arguments for filtering the PostFormatToPostConnection connection */
export type PostFormatToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<PostFormatToPostConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  taxQuery?: InputMaybe<PostFormatToPostConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type PostFormatToPostConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<PostFormatToPostConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<PostFormatToPostConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum PostFormatToPostConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type PostFormatToPostConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<PostFormatToPostConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum PostFormatToPostConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type PostFormatToPostConnectionWhereArgsTaxArray = {
  field?: InputMaybe<PostFormatToPostConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<PostFormatToPostConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type PostFormatToPostConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<PostFormatToPostConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum PostFormatToPostConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum PostFormatToPostConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the PostFormat type and the Taxonomy type */
export type PostFormatToTaxonomyConnectionEdge = {
  __typename?: 'PostFormatToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** List of possible post formats. */
export enum PostFormatTypeEnum {
  /** A standard post format. */
  Standard = 'STANDARD',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** A Gravity Forms   field. */
export type PostImageField = FormField & {
  __typename?: 'PostImageField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** A comma-delimited list of the file extensions which may be uploaded. */
  allowedExtensions?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Controls the visibility of the alt metadata for Post Image fields. */
  hasAlt?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the caption metadata for Post Image fields. */
  hasCaption?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the description metadata for Post Image fields. */
  hasDescription?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the title metadata for Post Image fields. */
  hasTitle?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Image field value. */
  imageValues?: Maybe<ImageFieldValue>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Whether the image field should be used to set the post&#039;s Featured Image */
  isFeaturedImage?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** The format of post field data. */
export enum PostObjectFieldFormatEnum {
  /** Provide the field value directly from database. Null on unauthenticated requests. */
  Raw = 'RAW',
  /** Provide the field value as rendered by WordPress. Default. */
  Rendered = 'RENDERED',
}

/** The column to use when filtering by date */
export enum PostObjectsConnectionDateColumnEnum {
  /** The date the comment was created in local time. */
  Date = 'DATE',
  /** The most recent modification date of the comment. */
  Modified = 'MODIFIED',
}

/** Field to order the connection by */
export enum PostObjectsConnectionOrderbyEnum {
  /** Order by author */
  Author = 'AUTHOR',
  /** Order by the number of comments it has acquired */
  CommentCount = 'COMMENT_COUNT',
  /** Order by publish date */
  Date = 'DATE',
  /** Preserve the ID order given in the IN array */
  In = 'IN',
  /** Order by the menu order value */
  MenuOrder = 'MENU_ORDER',
  /** Order by last modified date */
  Modified = 'MODIFIED',
  /** Preserve slug order given in the NAME_IN array */
  NameIn = 'NAME_IN',
  /** Order by parent ID */
  Parent = 'PARENT',
  /** Order by slug */
  Slug = 'SLUG',
  /** Order by title */
  Title = 'TITLE',
}

/** Options for ordering the connection */
export type PostObjectsConnectionOrderbyInput = {
  /** The field to order the connection by */
  field: PostObjectsConnectionOrderbyEnum
  /** Possible directions in which to order a list of items */
  order: OrderEnum
}

/** Set relationships between the post to postFormats */
export type PostPostFormatsInput = {
  /** If true, this will append the postFormat to existing related postFormats. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostPostFormatsNodeInput>>>
}

/** List of postFormats to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostPostFormatsNodeInput = {
  /** The description of the postFormat. This field is used to set a description of the postFormat if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the postFormat. If present, this will be used to connect to the post. If no existing postFormat exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the postFormat. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the postFormat. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The status of the object. */
export enum PostStatusEnum {
  /** Objects with the acf-disabled status */
  AcfDisabled = 'ACF_DISABLED',
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the draft status */
  Draft = 'DRAFT',
  /** Objects with the future status */
  Future = 'FUTURE',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the pending status */
  Pending = 'PENDING',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the publish status */
  Publish = 'PUBLISH',
  /** Objects with the request-completed status */
  RequestCompleted = 'REQUEST_COMPLETED',
  /** Objects with the request-confirmed status */
  RequestConfirmed = 'REQUEST_CONFIRMED',
  /** Objects with the request-failed status */
  RequestFailed = 'REQUEST_FAILED',
  /** Objects with the request-pending status */
  RequestPending = 'REQUEST_PENDING',
  /** Objects with the trash status */
  Trash = 'TRASH',
}

/** A Gravity Forms  post_tags field. */
export type PostTagsCheckboxField = FormField &
  PostTagsField & {
    __typename?: 'PostTagsCheckboxField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Checkbox field value. */
    checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_tags field. */
    choices?: Maybe<Array<Maybe<PostTagsCheckboxFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Whether the \&quot;select all\&quot; choice should be displayed. */
    hasSelectAll?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** An array of the available properties for each input of the post_tags field. */
    inputs?: Maybe<Array<Maybe<PostTagsInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** Post_tags choice values. */
export type PostTagsCheckboxFieldChoice = {
  __typename?: 'PostTagsCheckboxFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type PostTagsField = {
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Set relationships between the post to tags */
export type PostTagsInput = {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostTagsNodeInput>>>
}

/** Post_tags input values. */
export type PostTagsInputProperty = {
  __typename?: 'PostTagsInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_tags field. */
export type PostTagsMultiSelectField = FormField &
  PostTagsField & {
    __typename?: 'PostTagsMultiSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_tags field. */
    choices?: Maybe<Array<Maybe<PostTagsMultiSelectFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** An array of field values. */
    values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** Post_tags choice values. */
export type PostTagsMultiSelectFieldChoice = {
  __typename?: 'PostTagsMultiSelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** List of tags to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostTagsNodeInput = {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the tag. If present, this will be used to connect to the post. If no existing tag exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** A Gravity Forms  post_tags field. */
export type PostTagsRadioField = FormField &
  PostTagsField & {
    __typename?: 'PostTagsRadioField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_tags field. */
    choices?: Maybe<Array<Maybe<PostTagsRadioFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
    hasOtherChoice?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** Post_tags choice values. */
export type PostTagsRadioFieldChoice = {
  __typename?: 'PostTagsRadioFieldChoice'
  /** Indicates the radio button item is the “Other” choice. */
  isOtherChoice?: Maybe<Scalars['Boolean']['output']>
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_tags field. */
export type PostTagsSelectField = FormField &
  PostTagsField & {
    __typename?: 'PostTagsSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the post_tags field. */
    choices?: Maybe<Array<Maybe<PostTagsSelectFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** Post_tags choice values. */
export type PostTagsSelectFieldChoice = {
  __typename?: 'PostTagsSelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  post_tags field. */
export type PostTagsTextField = FormField &
  PostTagsField & {
    __typename?: 'PostTagsTextField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Field Level Translation on the Label */
    basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Whether the field has an input mask. */
    hasInputMask?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** The pattern used for the input mask. */
    inputMaskValue?: Maybe<Scalars['String']['output']>
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if a text field input tag should be created with a &quot;password&quot; type. */
    isPasswordInput?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
    maxLength?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms   field. */
export type PostTitleField = FormField & {
  __typename?: 'PostTitleField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Connection between the Post type and the category type */
export type PostToCategoryConnection = {
  __typename?: 'PostToCategoryConnection'
  /** Edges for the PostToCategoryConnection connection */
  edges?: Maybe<Array<Maybe<PostToCategoryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Category>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PostToCategoryConnectionEdge = {
  __typename?: 'PostToCategoryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Category>
}

/** Arguments for filtering the PostToCategoryConnection connection */
export type PostToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Post type and the Comment type */
export type PostToCommentConnection = {
  __typename?: 'PostToCommentConnection'
  /** Edges for the PostToCommentConnection connection */
  edges?: Maybe<Array<Maybe<PostToCommentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Comment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PostToCommentConnectionEdge = {
  __typename?: 'PostToCommentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Comment>
}

/** Arguments for filtering the PostToCommentConnection connection */
export type PostToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>
}

/** Post to post connection query type. */
export type PostToPostConnectionQuery = {
  /** The post to post connections and ids */
  connections?: InputMaybe<Array<InputMaybe<PostToPostConnections>>>
  /** Relation enum between the P2P connections. */
  relation?: InputMaybe<RelationEnum>
}

/** PostToPostConnections type. */
export type PostToPostConnections = {
  /** connection type. */
  connection?: InputMaybe<PostsToPostsConnectionNameEnum>
  /** connection ids. */
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

/** PostToPostConnections type. */
export type PostToPostConnectionsMutate = {
  /** append connection boolean. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** connection type. */
  connection?: InputMaybe<PostsToPostsConnectionNameEnum>
  /** connection ids. */
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

/** Connection between the Post type and the postFormat type */
export type PostToPostFormatConnection = {
  __typename?: 'PostToPostFormatConnection'
  /** Edges for the PostToPostFormatConnection connection */
  edges?: Maybe<Array<Maybe<PostToPostFormatConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<PostFormat>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PostToPostFormatConnectionEdge = {
  __typename?: 'PostToPostFormatConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<PostFormat>
}

/** Arguments for filtering the PostToPostFormatConnection connection */
export type PostToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Post type and the post type */
export type PostToPreviewConnectionEdge = {
  __typename?: 'PostToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Post>
}

/** Connection between the Post type and the post type */
export type PostToRevisionConnection = {
  __typename?: 'PostToRevisionConnection'
  /** Edges for the PostToRevisionConnection connection */
  edges?: Maybe<Array<Maybe<PostToRevisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PostToRevisionConnectionEdge = {
  __typename?: 'PostToRevisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Post>
}

/** Arguments for filtering the PostToRevisionConnection connection */
export type PostToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<PostToRevisionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  taxQuery?: InputMaybe<PostToRevisionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type PostToRevisionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<PostToRevisionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<PostToRevisionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum PostToRevisionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type PostToRevisionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<PostToRevisionConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum PostToRevisionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type PostToRevisionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<PostToRevisionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<PostToRevisionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type PostToRevisionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<PostToRevisionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum PostToRevisionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum PostToRevisionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Post type and the tag type */
export type PostToTagConnection = {
  __typename?: 'PostToTagConnection'
  /** Edges for the PostToTagConnection connection */
  edges?: Maybe<Array<Maybe<PostToTagConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Tag>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PostToTagConnectionEdge = {
  __typename?: 'PostToTagConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Tag>
}

/** Arguments for filtering the PostToTagConnection connection */
export type PostToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Post type and the TermNode type */
export type PostToTermNodeConnection = {
  __typename?: 'PostToTermNodeConnection'
  /** Edges for the PostToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<PostToTermNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type PostToTermNodeConnectionEdge = {
  __typename?: 'PostToTermNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TermNode>
}

/** Arguments for filtering the PostToTermNodeConnection connection */
export type PostToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Details for labels of the PostType */
export type PostTypeLabelDetails = {
  __typename?: 'PostTypeLabelDetails'
  /** Default is ‘Add New’ for both hierarchical and non-hierarchical types. */
  addNew?: Maybe<Scalars['String']['output']>
  /** Label for adding a new singular item. */
  addNewItem?: Maybe<Scalars['String']['output']>
  /** Label to signify all items in a submenu link. */
  allItems?: Maybe<Scalars['String']['output']>
  /** Label for archives in nav menus */
  archives?: Maybe<Scalars['String']['output']>
  /** Label for the attributes meta box. */
  attributes?: Maybe<Scalars['String']['output']>
  /** Label for editing a singular item. */
  editItem?: Maybe<Scalars['String']['output']>
  /** Label for the Featured Image meta box title. */
  featuredImage?: Maybe<Scalars['String']['output']>
  /** Label for the table views hidden heading. */
  filterItemsList?: Maybe<Scalars['String']['output']>
  /** Label for the media frame button. */
  insertIntoItem?: Maybe<Scalars['String']['output']>
  /** Label for the table hidden heading. */
  itemsList?: Maybe<Scalars['String']['output']>
  /** Label for the table pagination hidden heading. */
  itemsListNavigation?: Maybe<Scalars['String']['output']>
  /** Label for the menu name. */
  menuName?: Maybe<Scalars['String']['output']>
  /** General name for the post type, usually plural. */
  name?: Maybe<Scalars['String']['output']>
  /** Label for the new item page title. */
  newItem?: Maybe<Scalars['String']['output']>
  /** Label used when no items are found. */
  notFound?: Maybe<Scalars['String']['output']>
  /** Label used when no items are in the trash. */
  notFoundInTrash?: Maybe<Scalars['String']['output']>
  /** Label used to prefix parents of hierarchical items. */
  parentItemColon?: Maybe<Scalars['String']['output']>
  /** Label for removing the featured image. */
  removeFeaturedImage?: Maybe<Scalars['String']['output']>
  /** Label for searching plural items. */
  searchItems?: Maybe<Scalars['String']['output']>
  /** Label for setting the featured image. */
  setFeaturedImage?: Maybe<Scalars['String']['output']>
  /** Name for one object of this post type. */
  singularName?: Maybe<Scalars['String']['output']>
  /** Label for the media frame filter. */
  uploadedToThisItem?: Maybe<Scalars['String']['output']>
  /** Label in the media frame for using a featured image. */
  useFeaturedImage?: Maybe<Scalars['String']['output']>
  /** Label for viewing a singular item. */
  viewItem?: Maybe<Scalars['String']['output']>
  /** Label for viewing post type archives. */
  viewItems?: Maybe<Scalars['String']['output']>
}

/** Posts 2 Posts connection names */
export enum PostsToPostsConnectionNameEnum {
  /** The certification_user Posts2Posts connection */
  CertificationUser = 'CERTIFICATION_USER',
  /** The corrective_action_assigned_workers Posts2Posts connection */
  CorrectiveActionAssignedWorkers = 'CORRECTIVE_ACTION_ASSIGNED_WORKERS',
  /** The corrective_action_deficiency_owners Posts2Posts connection */
  CorrectiveActionDeficiencyOwners = 'CORRECTIVE_ACTION_DEFICIENCY_OWNERS',
  /** The corrective_action_photo Posts2Posts connection */
  CorrectiveActionPhoto = 'CORRECTIVE_ACTION_PHOTO',
  /** The document_to_project Posts2Posts connection */
  DocumentToProject = 'DOCUMENT_TO_PROJECT',
  /** The equipment_assigned_worker Posts2Posts connection */
  EquipmentAssignedWorker = 'EQUIPMENT_ASSIGNED_WORKER',
  /** The equipment_to_document Posts2Posts connection */
  EquipmentToDocument = 'EQUIPMENT_TO_DOCUMENT',
  /** The equipment_to_equipment Posts2Posts connection */
  EquipmentToEquipment = 'EQUIPMENT_TO_EQUIPMENT',
  /** The equipment_to_project Posts2Posts connection */
  EquipmentToProject = 'EQUIPMENT_TO_PROJECT',
  /** The incident_report_project Posts2Posts connection */
  IncidentReportProject = 'INCIDENT_REPORT_PROJECT',
  /** The incident_report_worker Posts2Posts connection */
  IncidentReportWorker = 'INCIDENT_REPORT_WORKER',
  /** The lesson_document_connection Posts2Posts connection */
  LessonDocumentConnection = 'LESSON_DOCUMENT_CONNECTION',
  /** The project_corrective_action Posts2Posts connection */
  ProjectCorrectiveAction = 'PROJECT_CORRECTIVE_ACTION',
  /** The project_key_personnel Posts2Posts connection */
  ProjectKeyPersonnel = 'PROJECT_KEY_PERSONNEL',
  /** The project_photo Posts2Posts connection */
  ProjectPhoto = 'PROJECT_PHOTO',
}

/** The Project type */
export type Project = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'Project'
    /** Street address */
    address?: Maybe<Scalars['String']['output']>
    /** Address, line two */
    address2?: Maybe<Scalars['String']['output']>
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** City */
    city?: Maybe<Scalars['String']['output']>
    /** Connection between the Project type and the Company type */
    companies?: Maybe<ProjectToCompanyConnection>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** Country */
    country?: Maybe<Scalars['String']['output']>
    /** Customer */
    customer?: Maybe<Scalars['String']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** Connection between the Project type and the Division type */
    divisions?: Maybe<ProjectToDivisionConnection>
    /** Connection between the Project type and the Document type */
    documentToProjectConnection?: Maybe<ProjectToDocumentConnection>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** End date */
    endDate?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** Connection between the Project type and the Equipment type */
    equipmentToProjectConnection?: Maybe<ProjectToEquipmentConnection>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** Hospital Street address */
    hospitalAddress?: Maybe<Scalars['String']['output']>
    /** Hospital City */
    hospitalCity?: Maybe<Scalars['String']['output']>
    /** Hospital Name */
    hospitalName?: Maybe<Scalars['String']['output']>
    /** Hospital State */
    hospitalState?: Maybe<Scalars['String']['output']>
    /** Hospital ZIP code */
    hospitalZip?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the project object. */
    id: Scalars['ID']['output']
    /** Connection between the Project type and the IncidentReport type */
    incidentReportProjectConnection?: Maybe<ProjectToIncidentReportConnection>
    /** Integration Source */
    integrationSource?: Maybe<Scalars['String']['output']>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /** Project number */
    number?: Maybe<Scalars['String']['output']>
    /** Connection between the Project type and the Project type */
    preview?: Maybe<ProjectToPreviewConnectionEdge>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** Connection between the Project type and the CorrectiveAction type */
    projectCorrectiveActionConnection?: Maybe<ProjectToCorrectiveActionConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    projectId: Scalars['Int']['output']
    /** Connection between the Project type and the User type */
    projectKeyPersonnelConnection?: Maybe<ProjectToUserConnection>
    /** Connection between the Project type and the Photo type */
    projectPhotoConnection?: Maybe<ProjectToPhotoConnection>
    /** Connection between the Project type and the ProjectStatus type */
    projectStatuses?: Maybe<ProjectToProjectStatusConnection>
    /** Connection between the Project type and the ProjectType type */
    projectTypes?: Maybe<ProjectToProjectTypeConnection>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** Start date */
    startDate?: Maybe<Scalars['String']['output']>
    /** State */
    state?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** Connection between the Project type and the TermNode type */
    terms?: Maybe<ProjectToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
    /** ZIP code */
    zip?: Maybe<Scalars['String']['output']>
  }

/** The Project type */
export type ProjectCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProjectToCompanyConnectionWhereArgs>
}

/** The Project type */
export type ProjectDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProjectToDivisionConnectionWhereArgs>
}

/** The Project type */
export type ProjectDocumentToProjectConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Project type */
export type ProjectEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Project type */
export type ProjectEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Project type */
export type ProjectEquipmentToProjectConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Project type */
export type ProjectIncidentReportProjectConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Project type */
export type ProjectProjectCorrectiveActionConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Project type */
export type ProjectProjectKeyPersonnelConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Project type */
export type ProjectProjectPhotoConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Project type */
export type ProjectProjectStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProjectToProjectStatusConnectionWhereArgs>
}

/** The Project type */
export type ProjectProjectTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProjectToProjectTypeConnectionWhereArgs>
}

/** The Project type */
export type ProjectTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProjectToTermNodeConnectionWhereArgs>
}

/** The Project type */
export type ProjectTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** Set relationships between the Project to Companies */
export type ProjectCompaniesInput = {
  /** If true, this will append the Company to existing related Companies. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ProjectCompaniesNodeInput>>>
}

/** List of Companies to connect the Project to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ProjectCompaniesNodeInput = {
  /** The description of the Company. This field is used to set a description of the Company if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Company. If present, this will be used to connect to the Project. If no existing Company exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Company. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Company. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the Project to Divisions */
export type ProjectDivisionsInput = {
  /** If true, this will append the Division to existing related Divisions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ProjectDivisionsNodeInput>>>
}

/** List of Divisions to connect the Project to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ProjectDivisionsNodeInput = {
  /** The description of the Division. This field is used to set a description of the Division if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Division. If present, this will be used to connect to the Project. If no existing Division exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Division. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Division. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ProjectIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the Project to ProjectStatuses */
export type ProjectProjectStatusesInput = {
  /** If true, this will append the ProjectStatus to existing related ProjectStatuses. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ProjectProjectStatusesNodeInput>>>
}

/** List of ProjectStatuses to connect the Project to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ProjectProjectStatusesNodeInput = {
  /** The description of the ProjectStatus. This field is used to set a description of the ProjectStatus if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the ProjectStatus. If present, this will be used to connect to the Project. If no existing ProjectStatus exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the ProjectStatus. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the ProjectStatus. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the Project to ProjectTypes */
export type ProjectProjectTypesInput = {
  /** If true, this will append the ProjectType to existing related ProjectTypes. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ProjectProjectTypesNodeInput>>>
}

/** List of ProjectTypes to connect the Project to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ProjectProjectTypesNodeInput = {
  /** The description of the ProjectType. This field is used to set a description of the ProjectType if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the ProjectType. If present, this will be used to connect to the Project. If no existing ProjectType exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the ProjectType. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the ProjectType. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The ProjectStatus type */
export type ProjectStatus = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'ProjectStatus'
    /** Connection between the ProjectStatus type and the ContentNode type */
    contentNodes?: Maybe<ProjectStatusToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    projectStatusId?: Maybe<Scalars['Int']['output']>
    /** Connection between the ProjectStatus type and the Project type */
    projects?: Maybe<ProjectStatusToProjectConnection>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the ProjectStatus type and the Taxonomy type */
    taxonomy?: Maybe<ProjectStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The ProjectStatus type */
export type ProjectStatusContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProjectStatusToContentNodeConnectionWhereArgs>
}

/** The ProjectStatus type */
export type ProjectStatusEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The ProjectStatus type */
export type ProjectStatusEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The ProjectStatus type */
export type ProjectStatusProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProjectStatusToProjectConnectionWhereArgs>
}

/** Project Status Options */
export enum ProjectStatusEnum {
  /** Used when the Project has been started. */
  Active = 'active',
  /** Used when the Project has been completed. */
  Completed = 'completed',
  /** Used when the Project has not yet been started. */
  NotStarted = 'not_started',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ProjectStatusIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the ProjectStatus type and the ContentNode type */
export type ProjectStatusToContentNodeConnection = {
  __typename?: 'ProjectStatusToContentNodeConnection'
  /** Edges for the ProjectStatusToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<ProjectStatusToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectStatusToContentNodeConnectionEdge = {
  __typename?: 'ProjectStatusToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the ProjectStatusToContentNodeConnection connection */
export type ProjectStatusToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfProjectStatusEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ProjectStatusToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ProjectStatusToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProjectStatusToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ProjectStatusToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ProjectStatusToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ProjectStatusToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ProjectStatusToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<ProjectStatusToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ProjectStatusToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ProjectStatusToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ProjectStatusToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ProjectStatusToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ProjectStatusToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<ProjectStatusToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ProjectStatusToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ProjectStatusToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the ProjectStatus type and the Project type */
export type ProjectStatusToProjectConnection = {
  __typename?: 'ProjectStatusToProjectConnection'
  /** Edges for the ProjectStatusToProjectConnection connection */
  edges?: Maybe<Array<Maybe<ProjectStatusToProjectConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectStatusToProjectConnectionEdge = {
  __typename?: 'ProjectStatusToProjectConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Arguments for filtering the ProjectStatusToProjectConnection connection */
export type ProjectStatusToProjectConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ProjectStatusToProjectConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ProjectStatusToProjectConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProjectStatusToProjectConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ProjectStatusToProjectConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ProjectStatusToProjectConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ProjectStatusToProjectConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ProjectStatusToProjectConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<ProjectStatusToProjectConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ProjectStatusToProjectConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ProjectStatusToProjectConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ProjectStatusToProjectConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ProjectStatusToProjectConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ProjectStatusToProjectConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<ProjectStatusToProjectConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ProjectStatusToProjectConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ProjectStatusToProjectConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the ProjectStatus type and the Taxonomy type */
export type ProjectStatusToTaxonomyConnectionEdge = {
  __typename?: 'ProjectStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Connection between the Project type and the Company type */
export type ProjectToCompanyConnection = {
  __typename?: 'ProjectToCompanyConnection'
  /** Edges for the ProjectToCompanyConnection connection */
  edges?: Maybe<Array<Maybe<ProjectToCompanyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Company>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectToCompanyConnectionEdge = {
  __typename?: 'ProjectToCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Company>
}

/** Arguments for filtering the ProjectToCompanyConnection connection */
export type ProjectToCompanyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Project type and the CorrectiveAction type */
export type ProjectToCorrectiveActionConnection = {
  __typename?: 'ProjectToCorrectiveActionConnection'
  /** Edges for the ProjectToCorrectiveActionConnection connection */
  edges?: Maybe<Array<Maybe<ProjectToCorrectiveActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectToCorrectiveActionConnectionEdge = {
  __typename?: 'ProjectToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Connection between the Project type and the Division type */
export type ProjectToDivisionConnection = {
  __typename?: 'ProjectToDivisionConnection'
  /** Edges for the ProjectToDivisionConnection connection */
  edges?: Maybe<Array<Maybe<ProjectToDivisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Division>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectToDivisionConnectionEdge = {
  __typename?: 'ProjectToDivisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Division>
}

/** Arguments for filtering the ProjectToDivisionConnection connection */
export type ProjectToDivisionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Project type and the Document type */
export type ProjectToDocumentConnection = {
  __typename?: 'ProjectToDocumentConnection'
  /** Edges for the ProjectToDocumentConnection connection */
  edges?: Maybe<Array<Maybe<ProjectToDocumentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Document>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectToDocumentConnectionEdge = {
  __typename?: 'ProjectToDocumentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Document>
}

/** Connection between the Project type and the Equipment type */
export type ProjectToEquipmentConnection = {
  __typename?: 'ProjectToEquipmentConnection'
  /** Edges for the ProjectToEquipmentConnection connection */
  edges?: Maybe<Array<Maybe<ProjectToEquipmentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectToEquipmentConnectionEdge = {
  __typename?: 'ProjectToEquipmentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Connection between the Project type and the IncidentReport type */
export type ProjectToIncidentReportConnection = {
  __typename?: 'ProjectToIncidentReportConnection'
  /** Edges for the ProjectToIncidentReportConnection connection */
  edges?: Maybe<Array<Maybe<ProjectToIncidentReportConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReport>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectToIncidentReportConnectionEdge = {
  __typename?: 'ProjectToIncidentReportConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReport>
}

/** Connection between the Project type and the Photo type */
export type ProjectToPhotoConnection = {
  __typename?: 'ProjectToPhotoConnection'
  /** Edges for the ProjectToPhotoConnection connection */
  edges?: Maybe<Array<Maybe<ProjectToPhotoConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Photo>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectToPhotoConnectionEdge = {
  __typename?: 'ProjectToPhotoConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Photo>
}

/** Connection between the Project type and the Project type */
export type ProjectToPreviewConnectionEdge = {
  __typename?: 'ProjectToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Project>
}

/** Connection between the Project type and the ProjectStatus type */
export type ProjectToProjectStatusConnection = {
  __typename?: 'ProjectToProjectStatusConnection'
  /** Edges for the ProjectToProjectStatusConnection connection */
  edges?: Maybe<Array<Maybe<ProjectToProjectStatusConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ProjectStatus>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectToProjectStatusConnectionEdge = {
  __typename?: 'ProjectToProjectStatusConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ProjectStatus>
}

/** Arguments for filtering the ProjectToProjectStatusConnection connection */
export type ProjectToProjectStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Project type and the ProjectType type */
export type ProjectToProjectTypeConnection = {
  __typename?: 'ProjectToProjectTypeConnection'
  /** Edges for the ProjectToProjectTypeConnection connection */
  edges?: Maybe<Array<Maybe<ProjectToProjectTypeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ProjectType>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectToProjectTypeConnectionEdge = {
  __typename?: 'ProjectToProjectTypeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ProjectType>
}

/** Arguments for filtering the ProjectToProjectTypeConnection connection */
export type ProjectToProjectTypeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Project type and the TermNode type */
export type ProjectToTermNodeConnection = {
  __typename?: 'ProjectToTermNodeConnection'
  /** Edges for the ProjectToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<ProjectToTermNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectToTermNodeConnectionEdge = {
  __typename?: 'ProjectToTermNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TermNode>
}

/** Arguments for filtering the ProjectToTermNodeConnection connection */
export type ProjectToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the Project type and the User type */
export type ProjectToUserConnection = {
  __typename?: 'ProjectToUserConnection'
  /** Edges for the ProjectToUserConnection connection */
  edges?: Maybe<Array<Maybe<ProjectToUserConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<User>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectToUserConnectionEdge = {
  __typename?: 'ProjectToUserConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<User>
}

/** The ProjectType type */
export type ProjectType = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'ProjectType'
    /** Connection between the ProjectType type and the ContentNode type */
    contentNodes?: Maybe<ProjectTypeToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    projectTypeId?: Maybe<Scalars['Int']['output']>
    /** Connection between the ProjectType type and the Project type */
    projects?: Maybe<ProjectTypeToProjectConnection>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the ProjectType type and the Taxonomy type */
    taxonomy?: Maybe<ProjectTypeToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The ProjectType type */
export type ProjectTypeContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProjectTypeToContentNodeConnectionWhereArgs>
}

/** The ProjectType type */
export type ProjectTypeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The ProjectType type */
export type ProjectTypeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The ProjectType type */
export type ProjectTypeProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProjectTypeToProjectConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ProjectTypeIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the ProjectType type and the ContentNode type */
export type ProjectTypeToContentNodeConnection = {
  __typename?: 'ProjectTypeToContentNodeConnection'
  /** Edges for the ProjectTypeToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<ProjectTypeToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectTypeToContentNodeConnectionEdge = {
  __typename?: 'ProjectTypeToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the ProjectTypeToContentNodeConnection connection */
export type ProjectTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfProjectTypeEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ProjectTypeToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ProjectTypeToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProjectTypeToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ProjectTypeToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ProjectTypeToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ProjectTypeToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ProjectTypeToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<ProjectTypeToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ProjectTypeToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ProjectTypeToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ProjectTypeToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ProjectTypeToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ProjectTypeToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<ProjectTypeToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ProjectTypeToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ProjectTypeToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the ProjectType type and the Project type */
export type ProjectTypeToProjectConnection = {
  __typename?: 'ProjectTypeToProjectConnection'
  /** Edges for the ProjectTypeToProjectConnection connection */
  edges?: Maybe<Array<Maybe<ProjectTypeToProjectConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProjectTypeToProjectConnectionEdge = {
  __typename?: 'ProjectTypeToProjectConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Arguments for filtering the ProjectTypeToProjectConnection connection */
export type ProjectTypeToProjectConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ProjectTypeToProjectConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ProjectTypeToProjectConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProjectTypeToProjectConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ProjectTypeToProjectConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ProjectTypeToProjectConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ProjectTypeToProjectConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ProjectTypeToProjectConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<ProjectTypeToProjectConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ProjectTypeToProjectConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ProjectTypeToProjectConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ProjectTypeToProjectConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ProjectTypeToProjectConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ProjectTypeToProjectConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<ProjectTypeToProjectConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ProjectTypeToProjectConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ProjectTypeToProjectConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the ProjectType type and the Taxonomy type */
export type ProjectTypeToTaxonomyConnectionEdge = {
  __typename?: 'ProjectTypeToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** The Provider type */
export type Provider = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Provider'
    /** Connection between the Provider type and the Certification type */
    certifications?: Maybe<ProviderToCertificationConnection>
    /** Connection between the Provider type and the ContentNode type */
    contentNodes?: Maybe<ProviderToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Connection between the Provider type and the Lesson type */
    lessons?: Maybe<ProviderToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    providerId?: Maybe<Scalars['Int']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Provider type and the Taxonomy type */
    taxonomy?: Maybe<ProviderToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Provider type */
export type ProviderCertificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProviderToCertificationConnectionWhereArgs>
}

/** The Provider type */
export type ProviderContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProviderToContentNodeConnectionWhereArgs>
}

/** The Provider type */
export type ProviderEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Provider type */
export type ProviderEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Provider type */
export type ProviderLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProviderToLessonConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ProviderIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the Provider type and the Certification type */
export type ProviderToCertificationConnection = {
  __typename?: 'ProviderToCertificationConnection'
  /** Edges for the ProviderToCertificationConnection connection */
  edges?: Maybe<Array<Maybe<ProviderToCertificationConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Certification>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProviderToCertificationConnectionEdge = {
  __typename?: 'ProviderToCertificationConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Certification>
}

/** Arguments for filtering the ProviderToCertificationConnection connection */
export type ProviderToCertificationConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ProviderToCertificationConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ProviderToCertificationConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProviderToCertificationConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ProviderToCertificationConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ProviderToCertificationConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ProviderToCertificationConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ProviderToCertificationConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<ProviderToCertificationConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ProviderToCertificationConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ProviderToCertificationConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ProviderToCertificationConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ProviderToCertificationConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ProviderToCertificationConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<ProviderToCertificationConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ProviderToCertificationConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ProviderToCertificationConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Provider type and the ContentNode type */
export type ProviderToContentNodeConnection = {
  __typename?: 'ProviderToContentNodeConnection'
  /** Edges for the ProviderToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<ProviderToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProviderToContentNodeConnectionEdge = {
  __typename?: 'ProviderToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the ProviderToContentNodeConnection connection */
export type ProviderToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfProviderEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ProviderToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ProviderToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProviderToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ProviderToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ProviderToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ProviderToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ProviderToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<ProviderToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ProviderToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ProviderToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ProviderToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ProviderToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ProviderToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<ProviderToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ProviderToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ProviderToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Provider type and the Lesson type */
export type ProviderToLessonConnection = {
  __typename?: 'ProviderToLessonConnection'
  /** Edges for the ProviderToLessonConnection connection */
  edges?: Maybe<Array<Maybe<ProviderToLessonConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Lesson>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ProviderToLessonConnectionEdge = {
  __typename?: 'ProviderToLessonConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Lesson>
}

/** Arguments for filtering the ProviderToLessonConnection connection */
export type ProviderToLessonConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ProviderToLessonConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ProviderToLessonConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProviderToLessonConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ProviderToLessonConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ProviderToLessonConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ProviderToLessonConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ProviderToLessonConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<ProviderToLessonConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ProviderToLessonConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ProviderToLessonConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ProviderToLessonConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ProviderToLessonConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ProviderToLessonConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<ProviderToLessonConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ProviderToLessonConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ProviderToLessonConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Provider type and the Taxonomy type */
export type ProviderToTaxonomyConnectionEdge = {
  __typename?: 'ProviderToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** A Gravity Forms  quiz field. */
export type QuizCheckboxField = FormField &
  QuizField & {
    __typename?: 'QuizCheckboxField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The explanation for the correct answer and/or incorrect answers. */
    answerExplanation?: Maybe<Scalars['String']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Checkbox field value. */
    checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the quiz field. */
    choices?: Maybe<Array<Maybe<QuizFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Whether the \&quot;select all\&quot; choice should be displayed. */
    hasSelectAll?: Maybe<Scalars['Boolean']['output']>
    /** If this setting is disabled then the response will be awarded a score of 1 if correct and 0 if incorrect. */
    hasWeightedScore?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** An array of the available properties for each input of the quiz field. */
    inputs?: Maybe<Array<Maybe<QuizInputProperty>>>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Whether to randomize the order in which the answers are displayed to the user. */
    shouldRandomizeQuizChoices?: Maybe<Scalars['Boolean']['output']>
    /** Whether to show an answer explanation. */
    shouldShowAnswerExplanation?: Maybe<Scalars['Boolean']['output']>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms   field. */
export type QuizField = {
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The explanation for the correct answer and/or incorrect answers. */
  answerExplanation?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
  /** The available choices for the quiz field. */
  choices?: Maybe<Array<Maybe<QuizFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** If this setting is disabled then the response will be awarded a score of 1 if correct and 0 if incorrect. */
  hasWeightedScore?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Whether to randomize the order in which the answers are displayed to the user. */
  shouldRandomizeQuizChoices?: Maybe<Scalars['Boolean']['output']>
  /** Whether to show an answer explanation. */
  shouldShowAnswerExplanation?: Maybe<Scalars['Boolean']['output']>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Quiz choice values. */
export type QuizFieldChoice = {
  __typename?: 'QuizFieldChoice'
  /** Indicates the choice item is the correct answer. */
  isCorrect?: Maybe<Scalars['Boolean']['output']>
  /** Indicates the radio button item is the “Other” choice. */
  isOtherChoice?: Maybe<Scalars['Boolean']['output']>
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
  /** The weighted score awarded for the choice. */
  weight?: Maybe<Scalars['Float']['output']>
}

/** Type of grading system used by Gravity Forms Quiz. Default is `NONE`. */
export enum QuizFieldGradingTypeEnum {
  /** Letter grading system. */
  Letter = 'LETTER',
  /** No grading. */
  None = 'NONE',
  /** Pass-fail grading system. */
  Passfail = 'PASSFAIL',
}

/** Quiz input values. */
export type QuizInputProperty = {
  __typename?: 'QuizInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms  quiz field. */
export type QuizRadioField = FormField &
  QuizField & {
    __typename?: 'QuizRadioField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The explanation for the correct answer and/or incorrect answers. */
    answerExplanation?: Maybe<Scalars['String']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the quiz field. */
    choices?: Maybe<Array<Maybe<QuizFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
    hasOtherChoice?: Maybe<Scalars['Boolean']['output']>
    /** If this setting is disabled then the response will be awarded a score of 1 if correct and 0 if incorrect. */
    hasWeightedScore?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Whether to randomize the order in which the answers are displayed to the user. */
    shouldRandomizeQuizChoices?: Maybe<Scalars['Boolean']['output']>
    /** Whether to show an answer explanation. */
    shouldShowAnswerExplanation?: Maybe<Scalars['Boolean']['output']>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms  quiz field. */
export type QuizSelectField = FormField &
  QuizField & {
    __typename?: 'QuizSelectField'
    /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
    adminLabel?: Maybe<Scalars['String']['output']>
    /** The explanation for the correct answer and/or incorrect answers. */
    answerExplanation?: Maybe<Scalars['String']['output']>
    /** The autocomplete attribute for the field. */
    autocompleteAttribute?: Maybe<Scalars['String']['output']>
    /** Determines if the field’s value can be pre-populated dynamically. */
    canPrepopulate?: Maybe<Scalars['Boolean']['output']>
    /** Choice text translations */
    choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
    /** The available choices for the quiz field. */
    choices?: Maybe<Array<Maybe<QuizFieldChoice>>>
    /** Controls the visibility of the field based on values selected by the user. */
    conditionalLogic?: Maybe<ConditionalLogic>
    /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
    cssClass?: Maybe<Scalars['String']['output']>
    /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
    defaultValue?: Maybe<Scalars['String']['output']>
    /** Field description. */
    description?: Maybe<Scalars['String']['output']>
    /** The placement of the field description. */
    descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
    /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
    displayOnly?: Maybe<Scalars['Boolean']['output']>
    /** Contains the message that is displayed for fields that fail validation. */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** Whether autocomplete should be enabled for this field. */
    hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
    /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
    hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
    /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
    hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
    /** If this setting is disabled then the response will be awarded a score of 1 if correct and 0 if incorrect. */
    hasWeightedScore?: Maybe<Scalars['Boolean']['output']>
    /** Field ID. */
    id: Scalars['Int']['output']
    /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
    inputName?: Maybe<Scalars['String']['output']>
    /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
    inputType?: Maybe<FormFieldTypeEnum>
    /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
    isRequired?: Maybe<Scalars['Boolean']['output']>
    /** Field label that will be displayed on the form and on the admin pages. */
    label?: Maybe<Scalars['String']['output']>
    /** The field label position. */
    labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
    /** The number of CSS grid columns the field should span. */
    layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The number of CSS grid columns the spacer field following this one should span. */
    layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
    /** The form page this field is located on. Default is 1. */
    pageNumber?: Maybe<Scalars['Int']['output']>
    /** The form field-specifc policies for exporting and erasing personal data. */
    personalData?: Maybe<FormFieldDataPolicy>
    /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
    placeholder?: Maybe<Scalars['String']['output']>
    /** Determines if the field allows duplicate submissions. */
    shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
    /** Whether to randomize the order in which the answers are displayed to the user. */
    shouldRandomizeQuizChoices?: Maybe<Scalars['Boolean']['output']>
    /** Whether to show an answer explanation. */
    shouldShowAnswerExplanation?: Maybe<Scalars['Boolean']['output']>
    /** Determines the size of the field when displayed on the page. */
    size?: Maybe<FormFieldSizeEnum>
    /** The type of field to be displayed. */
    type?: Maybe<FormFieldTypeEnum>
    /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
    value?: Maybe<Scalars['String']['output']>
    /** Field visibility. */
    visibility?: Maybe<FormFieldVisibilityEnum>
  }

/** A Gravity Forms   field. */
export type RadioField = FormField & {
  __typename?: 'RadioField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
  /** The available choices for the radio field. */
  choices?: Maybe<Array<Maybe<RadioFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
  hasOtherChoice?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** The label for the chosen value. */
  valueText?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Radio choice values. */
export type RadioFieldChoice = {
  __typename?: 'RadioFieldChoice'
  /** Indicates the radio button item is the “Other” choice. */
  isOtherChoice?: Maybe<Scalars['Boolean']['output']>
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** The reading setting type */
export type ReadingSettings = {
  __typename?: 'ReadingSettings'
  /** Blog pages show at most. */
  postsPerPage?: Maybe<Scalars['Int']['output']>
}

/** Determines which version of reCAPTCHA v2 will be used.  */
export enum RecaptchaTypeEnum {
  /** A checkbox reCAPTCHA type. */
  Checkbox = 'CHECKBOX',
  /** An invisible reCAPTCHA type. */
  Invisible = 'INVISIBLE',
}

/** Input for the refreshJwtAuthToken mutation */
export type RefreshJwtAuthTokenInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** A valid, previously issued JWT refresh token. If valid a new Auth token will be provided. If invalid, expired, revoked or otherwise invalid, a new AuthToken will not be provided. */
  jwtRefreshToken: Scalars['String']['input']
}

/** The payload for the refreshJwtAuthToken mutation */
export type RefreshJwtAuthTokenPayload = {
  __typename?: 'RefreshJwtAuthTokenPayload'
  /** JWT Token that can be used in future requests for Authentication */
  authToken?: Maybe<Scalars['String']['output']>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
}

/** Input for the registerUser mutation */
export type RegisterUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']['input']>
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']['input']>
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']['input']>
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']['input']>
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']['input']>
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']['input']>
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']['input']>
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']['input']>
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']['input']>
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']['input']>
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']['input']>
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']['input']>
  /** A string that contains the user's username. */
  username: Scalars['String']['input']
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the registerUser mutation */
export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The User object mutation type. */
  user?: Maybe<User>
}

/** The logical relation between each item in the array when there are more than one. */
export enum RelationEnum {
  /** The logical AND condition returns true if both operands are true, otherwise, it returns false. */
  And = 'AND',
  /** The logical OR condition returns false if both operands are false, otherwise, it returns true. */
  Or = 'OR',
}

/** A Gravity Forms   field. */
export type RememberMeField = FormField & {
  __typename?: 'RememberMeField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
  /** The available choices for the remember_me field. */
  choices?: Maybe<Array<Maybe<RememberMeFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** Whether the \&quot;select all\&quot; choice should be displayed. */
  hasSelectAll?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** An array of the available properties for each input of the remember_me field. */
  inputs?: Maybe<Array<Maybe<RememberMeInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Remember_me choice values. */
export type RememberMeFieldChoice = {
  __typename?: 'RememberMeFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** Remember_me input values. */
export type RememberMeInputProperty = {
  __typename?: 'RememberMeInputProperty'
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']['output']>
}

/** Input for the resetUserPassword mutation */
export type ResetUserPasswordInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Password reset key */
  key?: InputMaybe<Scalars['String']['input']>
  /** The user's login (username). */
  login?: InputMaybe<Scalars['String']['input']>
  /** The new password. */
  password?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the resetUserPassword mutation */
export type ResetUserPasswordPayload = {
  __typename?: 'ResetUserPasswordPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The User object mutation type. */
  user?: Maybe<User>
}

/** Input for the restoreComment mutation */
export type RestoreCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the comment to be restored */
  id: Scalars['ID']['input']
}

/** The payload for the restoreComment mutation */
export type RestoreCommentPayload = {
  __typename?: 'RestoreCommentPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The restored comment object */
  comment?: Maybe<Comment>
  /** The ID of the restored comment */
  restoredId?: Maybe<Scalars['ID']['output']>
}

/** Input for the ResyncIntegrationData mutation */
export type ResyncIntegrationDataInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the ResyncIntegrationData mutation */
export type ResyncIntegrationDataPayload = {
  __typename?: 'ResyncIntegrationDataPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** Whether the data sync completed successfully. */
  success?: Maybe<Scalars['Boolean']['output']>
}

/** The RootCause type */
export type RootCause = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'RootCause'
    /** Connection between the RootCause type and the ContentNode type */
    contentNodes?: Maybe<RootCauseToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Connection between the RootCause type and the IncidentReport type */
    incidentReports?: Maybe<RootCauseToIncidentReportConnection>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    rootCauseId?: Maybe<Scalars['Int']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the RootCause type and the Taxonomy type */
    taxonomy?: Maybe<RootCauseToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The RootCause type */
export type RootCauseContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootCauseToContentNodeConnectionWhereArgs>
}

/** The RootCause type */
export type RootCauseEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The RootCause type */
export type RootCauseEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The RootCause type */
export type RootCauseIncidentReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootCauseToIncidentReportConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum RootCauseIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the RootCause type and the ContentNode type */
export type RootCauseToContentNodeConnection = {
  __typename?: 'RootCauseToContentNodeConnection'
  /** Edges for the RootCauseToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<RootCauseToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootCauseToContentNodeConnectionEdge = {
  __typename?: 'RootCauseToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the RootCauseToContentNodeConnection connection */
export type RootCauseToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfRootCauseEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootCauseToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootCauseToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootCauseToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootCauseToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootCauseToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootCauseToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootCauseToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootCauseToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootCauseToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootCauseToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootCauseToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootCauseToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootCauseToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootCauseToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootCauseToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootCauseToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootCause type and the IncidentReport type */
export type RootCauseToIncidentReportConnection = {
  __typename?: 'RootCauseToIncidentReportConnection'
  /** Edges for the RootCauseToIncidentReportConnection connection */
  edges?: Maybe<Array<Maybe<RootCauseToIncidentReportConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReport>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootCauseToIncidentReportConnectionEdge = {
  __typename?: 'RootCauseToIncidentReportConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReport>
}

/** Arguments for filtering the RootCauseToIncidentReportConnection connection */
export type RootCauseToIncidentReportConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootCauseToIncidentReportConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootCauseToIncidentReportConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootCauseToIncidentReportConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootCauseToIncidentReportConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootCauseToIncidentReportConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootCauseToIncidentReportConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootCauseToIncidentReportConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootCauseToIncidentReportConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootCauseToIncidentReportConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootCauseToIncidentReportConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootCauseToIncidentReportConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootCauseToIncidentReportConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootCauseToIncidentReportConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootCauseToIncidentReportConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootCauseToIncidentReportConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootCauseToIncidentReportConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootCause type and the Taxonomy type */
export type RootCauseToTaxonomyConnectionEdge = {
  __typename?: 'RootCauseToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** The root mutation */
export type RootMutation = {
  __typename?: 'RootMutation'
  /** The payload for the createActionMonitorAction mutation */
  createActionMonitorAction?: Maybe<CreateActionMonitorActionPayload>
  /** The payload for the createCategory mutation */
  createCategory?: Maybe<CreateCategoryPayload>
  /** The payload for the createCertification mutation */
  createCertification?: Maybe<CreateCertificationPayload>
  /** The payload for the createCertificationExpiryWarning mutation */
  createCertificationExpiryWarning?: Maybe<CreateCertificationExpiryWarningPayload>
  /** The payload for the createComment mutation */
  createComment?: Maybe<CreateCommentPayload>
  /** The payload for the createCompany mutation */
  createCompany?: Maybe<CreateCompanyPayload>
  /** The payload for the createCorrectiveAction mutation */
  createCorrectiveAction?: Maybe<CreateCorrectiveActionPayload>
  /** The payload for the createCorrectiveActionPdfs mutation */
  createCorrectiveActionPdfs?: Maybe<CreateCorrectiveActionPdfsPayload>
  /** The payload for the createCorrectiveActionStatus mutation */
  createCorrectiveActionStatus?: Maybe<CreateCorrectiveActionStatusPayload>
  /** The payload for the createDeficiencyType mutation */
  createDeficiencyType?: Maybe<CreateDeficiencyTypePayload>
  /** The payload for the createDivision mutation */
  createDivision?: Maybe<CreateDivisionPayload>
  /** The payload for the createDocument mutation */
  createDocument?: Maybe<CreateDocumentPayload>
  /** The payload for the createDocumentTag mutation */
  createDocumentTag?: Maybe<CreateDocumentTagPayload>
  /** The payload for the createEntryId mutation */
  createEntryId?: Maybe<CreateEntryIdPayload>
  /** The payload for the createEquipment mutation */
  createEquipment?: Maybe<CreateEquipmentPayload>
  /** The payload for the createEquipmentStatus mutation */
  createEquipmentStatus?: Maybe<CreateEquipmentStatusPayload>
  /** The payload for the createEquipmentTag mutation */
  createEquipmentTag?: Maybe<CreateEquipmentTagPayload>
  /** The payload for the createFieldId mutation */
  createFieldId?: Maybe<CreateFieldIdPayload>
  /** The payload for the createFormId mutation */
  createFormId?: Maybe<CreateFormIdPayload>
  /** The payload for the createIncidentReport mutation */
  createIncidentReport?: Maybe<CreateIncidentReportPayload>
  /** The payload for the createIncidentReportClassification mutation */
  createIncidentReportClassification?: Maybe<CreateIncidentReportClassificationPayload>
  /** The payload for the createIncidentReportStatus mutation */
  createIncidentReportStatus?: Maybe<CreateIncidentReportStatusPayload>
  /** The payload for the createIncidentType mutation */
  createIncidentType?: Maybe<CreateIncidentTypePayload>
  /** The payload for the createInjuryArea mutation */
  createInjuryArea?: Maybe<CreateInjuryAreaPayload>
  /** The payload for the createInspectionInterval mutation */
  createInspectionInterval?: Maybe<CreateInspectionIntervalPayload>
  /** The payload for the createLesson mutation */
  createLesson?: Maybe<CreateLessonPayload>
  /** The payload for the createLessonCategory mutation */
  createLessonCategory?: Maybe<CreateLessonCategoryPayload>
  /** The payload for the createLessonDifficulty mutation */
  createLessonDifficulty?: Maybe<CreateLessonDifficultyPayload>
  /** The payload for the createManufacturer mutation */
  createManufacturer?: Maybe<CreateManufacturerPayload>
  /** The payload for the createMediaItem mutation */
  createMediaItem?: Maybe<CreateMediaItemPayload>
  /** The payload for the createPage mutation */
  createPage?: Maybe<CreatePagePayload>
  /** The payload for the createPhoto mutation */
  createPhoto?: Maybe<CreatePhotoPayload>
  /** The payload for the createPost mutation */
  createPost?: Maybe<CreatePostPayload>
  /** The payload for the createPostFormat mutation */
  createPostFormat?: Maybe<CreatePostFormatPayload>
  /** The payload for the createProject mutation */
  createProject?: Maybe<CreateProjectPayload>
  /** The payload for the createProjectStatus mutation */
  createProjectStatus?: Maybe<CreateProjectStatusPayload>
  /** The payload for the createProjectType mutation */
  createProjectType?: Maybe<CreateProjectTypePayload>
  /** The payload for the createProvider mutation */
  createProvider?: Maybe<CreateProviderPayload>
  /** The payload for the createRootCause mutation */
  createRootCause?: Maybe<CreateRootCausePayload>
  /** The payload for the createSeverity mutation */
  createSeverity?: Maybe<CreateSeverityPayload>
  /** The payload for the createTag mutation */
  createTag?: Maybe<CreateTagPayload>
  /** The payload for the createToolboxTalk mutation */
  createToolboxTalk?: Maybe<CreateToolboxTalkPayload>
  /** The payload for the createToolboxTalkCategory mutation */
  createToolboxTalkCategory?: Maybe<CreateToolboxTalkCategoryPayload>
  /** The payload for the createTrainingType mutation */
  createTrainingType?: Maybe<CreateTrainingTypePayload>
  /** The payload for the createUser mutation */
  createUser?: Maybe<CreateUserPayload>
  /** The payload for the createUserCompany mutation */
  createUserCompany?: Maybe<CreateUserCompanyPayload>
  /** The payload for the createUserDivision mutation */
  createUserDivision?: Maybe<CreateUserDivisionPayload>
  /** The payload for the createUserLanguage mutation */
  createUserLanguage?: Maybe<CreateUserLanguagePayload>
  /** The payload for the createUserStatus mutation */
  createUserStatus?: Maybe<CreateUserStatusPayload>
  /** The payload for the createUserVaccineStatus mutation */
  createUserVaccineStatus?: Maybe<CreateUserVaccineStatusPayload>
  /** The payload for the deleteActionMonitorAction mutation */
  deleteActionMonitorAction?: Maybe<DeleteActionMonitorActionPayload>
  /** The payload for the deleteCategory mutation */
  deleteCategory?: Maybe<DeleteCategoryPayload>
  /** The payload for the deleteCertification mutation */
  deleteCertification?: Maybe<DeleteCertificationPayload>
  /** The payload for the deleteCertificationExpiryWarning mutation */
  deleteCertificationExpiryWarning?: Maybe<DeleteCertificationExpiryWarningPayload>
  /** The payload for the deleteComment mutation */
  deleteComment?: Maybe<DeleteCommentPayload>
  /** The payload for the deleteCompany mutation */
  deleteCompany?: Maybe<DeleteCompanyPayload>
  /** The payload for the deleteCorrectiveAction mutation */
  deleteCorrectiveAction?: Maybe<DeleteCorrectiveActionPayload>
  /** The payload for the deleteCorrectiveActionStatus mutation */
  deleteCorrectiveActionStatus?: Maybe<DeleteCorrectiveActionStatusPayload>
  /** The payload for the deleteDeficiencyType mutation */
  deleteDeficiencyType?: Maybe<DeleteDeficiencyTypePayload>
  /** The payload for the deleteDivision mutation */
  deleteDivision?: Maybe<DeleteDivisionPayload>
  /** The payload for the deleteDocument mutation */
  deleteDocument?: Maybe<DeleteDocumentPayload>
  /** The payload for the deleteDocumentTag mutation */
  deleteDocumentTag?: Maybe<DeleteDocumentTagPayload>
  /** The payload for the deleteEntryId mutation */
  deleteEntryId?: Maybe<DeleteEntryIdPayload>
  /** The payload for the deleteEquipment mutation */
  deleteEquipment?: Maybe<DeleteEquipmentPayload>
  /** The payload for the deleteEquipmentStatus mutation */
  deleteEquipmentStatus?: Maybe<DeleteEquipmentStatusPayload>
  /** The payload for the deleteEquipmentTag mutation */
  deleteEquipmentTag?: Maybe<DeleteEquipmentTagPayload>
  /** The payload for the deleteFieldId mutation */
  deleteFieldId?: Maybe<DeleteFieldIdPayload>
  /** The payload for the deleteFormId mutation */
  deleteFormId?: Maybe<DeleteFormIdPayload>
  /** The payload for the deleteGfDraftEntry mutation */
  deleteGfDraftEntry?: Maybe<DeleteGfDraftEntryPayload>
  /** The payload for the deleteGfEntry mutation */
  deleteGfEntry?: Maybe<DeleteGfEntryPayload>
  /** The payload for the deleteIncidentReport mutation */
  deleteIncidentReport?: Maybe<DeleteIncidentReportPayload>
  /** The payload for the deleteIncidentReportClassification mutation */
  deleteIncidentReportClassification?: Maybe<DeleteIncidentReportClassificationPayload>
  /** The payload for the deleteIncidentReportStatus mutation */
  deleteIncidentReportStatus?: Maybe<DeleteIncidentReportStatusPayload>
  /** The payload for the deleteIncidentType mutation */
  deleteIncidentType?: Maybe<DeleteIncidentTypePayload>
  /** The payload for the deleteInjuryArea mutation */
  deleteInjuryArea?: Maybe<DeleteInjuryAreaPayload>
  /** The payload for the deleteInspectionInterval mutation */
  deleteInspectionInterval?: Maybe<DeleteInspectionIntervalPayload>
  /** The payload for the deleteLesson mutation */
  deleteLesson?: Maybe<DeleteLessonPayload>
  /** The payload for the deleteLessonCategory mutation */
  deleteLessonCategory?: Maybe<DeleteLessonCategoryPayload>
  /** The payload for the deleteLessonDifficulty mutation */
  deleteLessonDifficulty?: Maybe<DeleteLessonDifficultyPayload>
  /** The payload for the deleteManufacturer mutation */
  deleteManufacturer?: Maybe<DeleteManufacturerPayload>
  /** The payload for the deleteMediaItem mutation */
  deleteMediaItem?: Maybe<DeleteMediaItemPayload>
  /** The payload for the deletePage mutation */
  deletePage?: Maybe<DeletePagePayload>
  /** The payload for the deletePhoto mutation */
  deletePhoto?: Maybe<DeletePhotoPayload>
  /** The payload for the deletePost mutation */
  deletePost?: Maybe<DeletePostPayload>
  /** The payload for the deletePostFormat mutation */
  deletePostFormat?: Maybe<DeletePostFormatPayload>
  /** The payload for the deleteProject mutation */
  deleteProject?: Maybe<DeleteProjectPayload>
  /** The payload for the deleteProjectStatus mutation */
  deleteProjectStatus?: Maybe<DeleteProjectStatusPayload>
  /** The payload for the deleteProjectType mutation */
  deleteProjectType?: Maybe<DeleteProjectTypePayload>
  /** The payload for the deleteProvider mutation */
  deleteProvider?: Maybe<DeleteProviderPayload>
  /** The payload for the deleteRootCause mutation */
  deleteRootCause?: Maybe<DeleteRootCausePayload>
  /** The payload for the deleteSeverity mutation */
  deleteSeverity?: Maybe<DeleteSeverityPayload>
  /** The payload for the deleteTag mutation */
  deleteTag?: Maybe<DeleteTagPayload>
  /** The payload for the deleteToolboxTalk mutation */
  deleteToolboxTalk?: Maybe<DeleteToolboxTalkPayload>
  /** The payload for the deleteToolboxTalkCategory mutation */
  deleteToolboxTalkCategory?: Maybe<DeleteToolboxTalkCategoryPayload>
  /** The payload for the deleteTrainingType mutation */
  deleteTrainingType?: Maybe<DeleteTrainingTypePayload>
  /** The payload for the deleteUser mutation */
  deleteUser?: Maybe<DeleteUserPayload>
  /** The payload for the deleteUserCompany mutation */
  deleteUserCompany?: Maybe<DeleteUserCompanyPayload>
  /** The payload for the deleteUserDivision mutation */
  deleteUserDivision?: Maybe<DeleteUserDivisionPayload>
  /** The payload for the deleteUserLanguage mutation */
  deleteUserLanguage?: Maybe<DeleteUserLanguagePayload>
  /** The payload for the deleteUserStatus mutation */
  deleteUserStatus?: Maybe<DeleteUserStatusPayload>
  /** The payload for the deleteUserVaccineStatus mutation */
  deleteUserVaccineStatus?: Maybe<DeleteUserVaccineStatusPayload>
  /** The payload for the gravityPdfCreateEntryPdf mutation */
  gravityPdfCreateEntryPdf?: Maybe<GravityPdfCreateEntryPdfPayload>
  /** The payload for the gravityPdfCreateEntryPdfs mutation */
  gravityPdfCreateEntryPdfs?: Maybe<GravityPdfCreateEntryPdfsPayload>
  /** Increase the count. */
  increaseCount?: Maybe<Scalars['Int']['output']>
  /** The payload for the login mutation */
  login?: Maybe<LoginPayload>
  /** The payload for the refreshJwtAuthToken mutation */
  refreshJwtAuthToken?: Maybe<RefreshJwtAuthTokenPayload>
  /** The payload for the registerUser mutation */
  registerUser?: Maybe<RegisterUserPayload>
  /** The payload for the resetUserPassword mutation */
  resetUserPassword?: Maybe<ResetUserPasswordPayload>
  /** The payload for the restoreComment mutation */
  restoreComment?: Maybe<RestoreCommentPayload>
  /** The payload for the ResyncIntegrationData mutation */
  resyncIntegrationData?: Maybe<ResyncIntegrationDataPayload>
  /** The payload for the sendPasswordResetEmail mutation */
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmailPayload>
  /** The payload for the submitGfDraftEntry mutation */
  submitGfDraftEntry?: Maybe<SubmitGfDraftEntryPayload>
  /** The payload for the submitGfForm mutation */
  submitGfForm?: Maybe<SubmitGfFormPayload>
  /** Suggested username. */
  suggestedUsername?: Maybe<Scalars['String']['output']>
  /** The payload for the updateActionMonitorAction mutation */
  updateActionMonitorAction?: Maybe<UpdateActionMonitorActionPayload>
  /** The payload for the UpdateCategory mutation */
  updateCategory?: Maybe<UpdateCategoryPayload>
  /** The payload for the updateCertification mutation */
  updateCertification?: Maybe<UpdateCertificationPayload>
  /** The payload for the updateCertificationExpiryWarning mutation */
  updateCertificationExpiryWarning?: Maybe<UpdateCertificationExpiryWarningPayload>
  /** The payload for the updateComment mutation */
  updateComment?: Maybe<UpdateCommentPayload>
  /** The payload for the UpdateCompany mutation */
  updateCompany?: Maybe<UpdateCompanyPayload>
  /** The payload for the updateCorrectiveAction mutation */
  updateCorrectiveAction?: Maybe<UpdateCorrectiveActionPayload>
  /** The payload for the UpdateCorrectiveActionStatus mutation */
  updateCorrectiveActionStatus?: Maybe<UpdateCorrectiveActionStatusPayload>
  /** The payload for the UpdateDeficiencyType mutation */
  updateDeficiencyType?: Maybe<UpdateDeficiencyTypePayload>
  /** The payload for the UpdateDivision mutation */
  updateDivision?: Maybe<UpdateDivisionPayload>
  /** The payload for the updateDocument mutation */
  updateDocument?: Maybe<UpdateDocumentPayload>
  /** The payload for the UpdateDocumentTag mutation */
  updateDocumentTag?: Maybe<UpdateDocumentTagPayload>
  /** The payload for the UpdateEntryId mutation */
  updateEntryId?: Maybe<UpdateEntryIdPayload>
  /** The payload for the updateEquipment mutation */
  updateEquipment?: Maybe<UpdateEquipmentPayload>
  /** The payload for the UpdateEquipmentStatus mutation */
  updateEquipmentStatus?: Maybe<UpdateEquipmentStatusPayload>
  /** The payload for the UpdateEquipmentTag mutation */
  updateEquipmentTag?: Maybe<UpdateEquipmentTagPayload>
  /** The payload for the UpdateFieldId mutation */
  updateFieldId?: Maybe<UpdateFieldIdPayload>
  /** The payload for the UpdateFormId mutation */
  updateFormId?: Maybe<UpdateFormIdPayload>
  /** The payload for the updateGfDraftEntry mutation */
  updateGfDraftEntry?: Maybe<UpdateGfDraftEntryPayload>
  /** The payload for the updateGfEntry mutation */
  updateGfEntry?: Maybe<UpdateGfEntryPayload>
  /** The payload for the updateIncidentReport mutation */
  updateIncidentReport?: Maybe<UpdateIncidentReportPayload>
  /** The payload for the UpdateIncidentReportClassification mutation */
  updateIncidentReportClassification?: Maybe<UpdateIncidentReportClassificationPayload>
  /** The payload for the UpdateIncidentReportStatus mutation */
  updateIncidentReportStatus?: Maybe<UpdateIncidentReportStatusPayload>
  /** The payload for the UpdateIncidentType mutation */
  updateIncidentType?: Maybe<UpdateIncidentTypePayload>
  /** The payload for the UpdateInjuryArea mutation */
  updateInjuryArea?: Maybe<UpdateInjuryAreaPayload>
  /** The payload for the UpdateInspectionInterval mutation */
  updateInspectionInterval?: Maybe<UpdateInspectionIntervalPayload>
  /** The payload for the updateLesson mutation */
  updateLesson?: Maybe<UpdateLessonPayload>
  /** The payload for the UpdateLessonCategory mutation */
  updateLessonCategory?: Maybe<UpdateLessonCategoryPayload>
  /** The payload for the UpdateLessonDifficulty mutation */
  updateLessonDifficulty?: Maybe<UpdateLessonDifficultyPayload>
  /** The payload for the UpdateManufacturer mutation */
  updateManufacturer?: Maybe<UpdateManufacturerPayload>
  /** The payload for the updateMediaItem mutation */
  updateMediaItem?: Maybe<UpdateMediaItemPayload>
  /** The payload for the updatePage mutation */
  updatePage?: Maybe<UpdatePagePayload>
  /** The payload for the updatePhoto mutation */
  updatePhoto?: Maybe<UpdatePhotoPayload>
  /** The payload for the updatePost mutation */
  updatePost?: Maybe<UpdatePostPayload>
  /** The payload for the UpdatePostFormat mutation */
  updatePostFormat?: Maybe<UpdatePostFormatPayload>
  /** The payload for the updateProject mutation */
  updateProject?: Maybe<UpdateProjectPayload>
  /** The payload for the UpdateProjectStatus mutation */
  updateProjectStatus?: Maybe<UpdateProjectStatusPayload>
  /** The payload for the UpdateProjectType mutation */
  updateProjectType?: Maybe<UpdateProjectTypePayload>
  /** The payload for the UpdateProvider mutation */
  updateProvider?: Maybe<UpdateProviderPayload>
  /** The payload for the UpdateRootCause mutation */
  updateRootCause?: Maybe<UpdateRootCausePayload>
  /** The payload for the updateSettings mutation */
  updateSettings?: Maybe<UpdateSettingsPayload>
  /** The payload for the UpdateSeverity mutation */
  updateSeverity?: Maybe<UpdateSeverityPayload>
  /** The payload for the UpdateTag mutation */
  updateTag?: Maybe<UpdateTagPayload>
  /** The payload for the updateToolboxTalk mutation */
  updateToolboxTalk?: Maybe<UpdateToolboxTalkPayload>
  /** The payload for the UpdateToolboxTalkCategory mutation */
  updateToolboxTalkCategory?: Maybe<UpdateToolboxTalkCategoryPayload>
  /** The payload for the UpdateTrainingType mutation */
  updateTrainingType?: Maybe<UpdateTrainingTypePayload>
  /** The payload for the updateUser mutation */
  updateUser?: Maybe<UpdateUserPayload>
  /** The payload for the UpdateUserCompany mutation */
  updateUserCompany?: Maybe<UpdateUserCompanyPayload>
  /** The payload for the UpdateUserDivision mutation */
  updateUserDivision?: Maybe<UpdateUserDivisionPayload>
  /** The payload for the UpdateUserLanguage mutation */
  updateUserLanguage?: Maybe<UpdateUserLanguagePayload>
  /** The payload for the UpdateUserStatus mutation */
  updateUserStatus?: Maybe<UpdateUserStatusPayload>
  /** The payload for the UpdateUserVaccineStatus mutation */
  updateUserVaccineStatus?: Maybe<UpdateUserVaccineStatusPayload>
  /** The payload for the wpGatsbyRemotePreviewStatus mutation */
  wpGatsbyRemotePreviewStatus?: Maybe<WpGatsbyRemotePreviewStatusPayload>
}

/** The root mutation */
export type RootMutationCreateActionMonitorActionArgs = {
  input: CreateActionMonitorActionInput
}

/** The root mutation */
export type RootMutationCreateCategoryArgs = {
  input: CreateCategoryInput
}

/** The root mutation */
export type RootMutationCreateCertificationArgs = {
  input: CreateCertificationInput
}

/** The root mutation */
export type RootMutationCreateCertificationExpiryWarningArgs = {
  input: CreateCertificationExpiryWarningInput
}

/** The root mutation */
export type RootMutationCreateCommentArgs = {
  input: CreateCommentInput
}

/** The root mutation */
export type RootMutationCreateCompanyArgs = {
  input: CreateCompanyInput
}

/** The root mutation */
export type RootMutationCreateCorrectiveActionArgs = {
  input: CreateCorrectiveActionInput
}

/** The root mutation */
export type RootMutationCreateCorrectiveActionPdfsArgs = {
  input: CreateCorrectiveActionPdfsInput
}

/** The root mutation */
export type RootMutationCreateCorrectiveActionStatusArgs = {
  input: CreateCorrectiveActionStatusInput
}

/** The root mutation */
export type RootMutationCreateDeficiencyTypeArgs = {
  input: CreateDeficiencyTypeInput
}

/** The root mutation */
export type RootMutationCreateDivisionArgs = {
  input: CreateDivisionInput
}

/** The root mutation */
export type RootMutationCreateDocumentArgs = {
  input: CreateDocumentInput
}

/** The root mutation */
export type RootMutationCreateDocumentTagArgs = {
  input: CreateDocumentTagInput
}

/** The root mutation */
export type RootMutationCreateEntryIdArgs = {
  input: CreateEntryIdInput
}

/** The root mutation */
export type RootMutationCreateEquipmentArgs = {
  input: CreateEquipmentInput
}

/** The root mutation */
export type RootMutationCreateEquipmentStatusArgs = {
  input: CreateEquipmentStatusInput
}

/** The root mutation */
export type RootMutationCreateEquipmentTagArgs = {
  input: CreateEquipmentTagInput
}

/** The root mutation */
export type RootMutationCreateFieldIdArgs = {
  input: CreateFieldIdInput
}

/** The root mutation */
export type RootMutationCreateFormIdArgs = {
  input: CreateFormIdInput
}

/** The root mutation */
export type RootMutationCreateIncidentReportArgs = {
  input: CreateIncidentReportInput
}

/** The root mutation */
export type RootMutationCreateIncidentReportClassificationArgs = {
  input: CreateIncidentReportClassificationInput
}

/** The root mutation */
export type RootMutationCreateIncidentReportStatusArgs = {
  input: CreateIncidentReportStatusInput
}

/** The root mutation */
export type RootMutationCreateIncidentTypeArgs = {
  input: CreateIncidentTypeInput
}

/** The root mutation */
export type RootMutationCreateInjuryAreaArgs = {
  input: CreateInjuryAreaInput
}

/** The root mutation */
export type RootMutationCreateInspectionIntervalArgs = {
  input: CreateInspectionIntervalInput
}

/** The root mutation */
export type RootMutationCreateLessonArgs = {
  input: CreateLessonInput
}

/** The root mutation */
export type RootMutationCreateLessonCategoryArgs = {
  input: CreateLessonCategoryInput
}

/** The root mutation */
export type RootMutationCreateLessonDifficultyArgs = {
  input: CreateLessonDifficultyInput
}

/** The root mutation */
export type RootMutationCreateManufacturerArgs = {
  input: CreateManufacturerInput
}

/** The root mutation */
export type RootMutationCreateMediaItemArgs = {
  input: CreateMediaItemInput
}

/** The root mutation */
export type RootMutationCreatePageArgs = {
  input: CreatePageInput
}

/** The root mutation */
export type RootMutationCreatePhotoArgs = {
  input: CreatePhotoInput
}

/** The root mutation */
export type RootMutationCreatePostArgs = {
  input: CreatePostInput
}

/** The root mutation */
export type RootMutationCreatePostFormatArgs = {
  input: CreatePostFormatInput
}

/** The root mutation */
export type RootMutationCreateProjectArgs = {
  input: CreateProjectInput
}

/** The root mutation */
export type RootMutationCreateProjectStatusArgs = {
  input: CreateProjectStatusInput
}

/** The root mutation */
export type RootMutationCreateProjectTypeArgs = {
  input: CreateProjectTypeInput
}

/** The root mutation */
export type RootMutationCreateProviderArgs = {
  input: CreateProviderInput
}

/** The root mutation */
export type RootMutationCreateRootCauseArgs = {
  input: CreateRootCauseInput
}

/** The root mutation */
export type RootMutationCreateSeverityArgs = {
  input: CreateSeverityInput
}

/** The root mutation */
export type RootMutationCreateTagArgs = {
  input: CreateTagInput
}

/** The root mutation */
export type RootMutationCreateToolboxTalkArgs = {
  input: CreateToolboxTalkInput
}

/** The root mutation */
export type RootMutationCreateToolboxTalkCategoryArgs = {
  input: CreateToolboxTalkCategoryInput
}

/** The root mutation */
export type RootMutationCreateTrainingTypeArgs = {
  input: CreateTrainingTypeInput
}

/** The root mutation */
export type RootMutationCreateUserArgs = {
  input: CreateUserInput
}

/** The root mutation */
export type RootMutationCreateUserCompanyArgs = {
  input: CreateUserCompanyInput
}

/** The root mutation */
export type RootMutationCreateUserDivisionArgs = {
  input: CreateUserDivisionInput
}

/** The root mutation */
export type RootMutationCreateUserLanguageArgs = {
  input: CreateUserLanguageInput
}

/** The root mutation */
export type RootMutationCreateUserStatusArgs = {
  input: CreateUserStatusInput
}

/** The root mutation */
export type RootMutationCreateUserVaccineStatusArgs = {
  input: CreateUserVaccineStatusInput
}

/** The root mutation */
export type RootMutationDeleteActionMonitorActionArgs = {
  input: DeleteActionMonitorActionInput
}

/** The root mutation */
export type RootMutationDeleteCategoryArgs = {
  input: DeleteCategoryInput
}

/** The root mutation */
export type RootMutationDeleteCertificationArgs = {
  input: DeleteCertificationInput
}

/** The root mutation */
export type RootMutationDeleteCertificationExpiryWarningArgs = {
  input: DeleteCertificationExpiryWarningInput
}

/** The root mutation */
export type RootMutationDeleteCommentArgs = {
  input: DeleteCommentInput
}

/** The root mutation */
export type RootMutationDeleteCompanyArgs = {
  input: DeleteCompanyInput
}

/** The root mutation */
export type RootMutationDeleteCorrectiveActionArgs = {
  input: DeleteCorrectiveActionInput
}

/** The root mutation */
export type RootMutationDeleteCorrectiveActionStatusArgs = {
  input: DeleteCorrectiveActionStatusInput
}

/** The root mutation */
export type RootMutationDeleteDeficiencyTypeArgs = {
  input: DeleteDeficiencyTypeInput
}

/** The root mutation */
export type RootMutationDeleteDivisionArgs = {
  input: DeleteDivisionInput
}

/** The root mutation */
export type RootMutationDeleteDocumentArgs = {
  input: DeleteDocumentInput
}

/** The root mutation */
export type RootMutationDeleteDocumentTagArgs = {
  input: DeleteDocumentTagInput
}

/** The root mutation */
export type RootMutationDeleteEntryIdArgs = {
  input: DeleteEntryIdInput
}

/** The root mutation */
export type RootMutationDeleteEquipmentArgs = {
  input: DeleteEquipmentInput
}

/** The root mutation */
export type RootMutationDeleteEquipmentStatusArgs = {
  input: DeleteEquipmentStatusInput
}

/** The root mutation */
export type RootMutationDeleteEquipmentTagArgs = {
  input: DeleteEquipmentTagInput
}

/** The root mutation */
export type RootMutationDeleteFieldIdArgs = {
  input: DeleteFieldIdInput
}

/** The root mutation */
export type RootMutationDeleteFormIdArgs = {
  input: DeleteFormIdInput
}

/** The root mutation */
export type RootMutationDeleteGfDraftEntryArgs = {
  input: DeleteGfDraftEntryInput
}

/** The root mutation */
export type RootMutationDeleteGfEntryArgs = {
  input: DeleteGfEntryInput
}

/** The root mutation */
export type RootMutationDeleteIncidentReportArgs = {
  input: DeleteIncidentReportInput
}

/** The root mutation */
export type RootMutationDeleteIncidentReportClassificationArgs = {
  input: DeleteIncidentReportClassificationInput
}

/** The root mutation */
export type RootMutationDeleteIncidentReportStatusArgs = {
  input: DeleteIncidentReportStatusInput
}

/** The root mutation */
export type RootMutationDeleteIncidentTypeArgs = {
  input: DeleteIncidentTypeInput
}

/** The root mutation */
export type RootMutationDeleteInjuryAreaArgs = {
  input: DeleteInjuryAreaInput
}

/** The root mutation */
export type RootMutationDeleteInspectionIntervalArgs = {
  input: DeleteInspectionIntervalInput
}

/** The root mutation */
export type RootMutationDeleteLessonArgs = {
  input: DeleteLessonInput
}

/** The root mutation */
export type RootMutationDeleteLessonCategoryArgs = {
  input: DeleteLessonCategoryInput
}

/** The root mutation */
export type RootMutationDeleteLessonDifficultyArgs = {
  input: DeleteLessonDifficultyInput
}

/** The root mutation */
export type RootMutationDeleteManufacturerArgs = {
  input: DeleteManufacturerInput
}

/** The root mutation */
export type RootMutationDeleteMediaItemArgs = {
  input: DeleteMediaItemInput
}

/** The root mutation */
export type RootMutationDeletePageArgs = {
  input: DeletePageInput
}

/** The root mutation */
export type RootMutationDeletePhotoArgs = {
  input: DeletePhotoInput
}

/** The root mutation */
export type RootMutationDeletePostArgs = {
  input: DeletePostInput
}

/** The root mutation */
export type RootMutationDeletePostFormatArgs = {
  input: DeletePostFormatInput
}

/** The root mutation */
export type RootMutationDeleteProjectArgs = {
  input: DeleteProjectInput
}

/** The root mutation */
export type RootMutationDeleteProjectStatusArgs = {
  input: DeleteProjectStatusInput
}

/** The root mutation */
export type RootMutationDeleteProjectTypeArgs = {
  input: DeleteProjectTypeInput
}

/** The root mutation */
export type RootMutationDeleteProviderArgs = {
  input: DeleteProviderInput
}

/** The root mutation */
export type RootMutationDeleteRootCauseArgs = {
  input: DeleteRootCauseInput
}

/** The root mutation */
export type RootMutationDeleteSeverityArgs = {
  input: DeleteSeverityInput
}

/** The root mutation */
export type RootMutationDeleteTagArgs = {
  input: DeleteTagInput
}

/** The root mutation */
export type RootMutationDeleteToolboxTalkArgs = {
  input: DeleteToolboxTalkInput
}

/** The root mutation */
export type RootMutationDeleteToolboxTalkCategoryArgs = {
  input: DeleteToolboxTalkCategoryInput
}

/** The root mutation */
export type RootMutationDeleteTrainingTypeArgs = {
  input: DeleteTrainingTypeInput
}

/** The root mutation */
export type RootMutationDeleteUserArgs = {
  input: DeleteUserInput
}

/** The root mutation */
export type RootMutationDeleteUserCompanyArgs = {
  input: DeleteUserCompanyInput
}

/** The root mutation */
export type RootMutationDeleteUserDivisionArgs = {
  input: DeleteUserDivisionInput
}

/** The root mutation */
export type RootMutationDeleteUserLanguageArgs = {
  input: DeleteUserLanguageInput
}

/** The root mutation */
export type RootMutationDeleteUserStatusArgs = {
  input: DeleteUserStatusInput
}

/** The root mutation */
export type RootMutationDeleteUserVaccineStatusArgs = {
  input: DeleteUserVaccineStatusInput
}

/** The root mutation */
export type RootMutationGravityPdfCreateEntryPdfArgs = {
  input: GravityPdfCreateEntryPdfInput
}

/** The root mutation */
export type RootMutationGravityPdfCreateEntryPdfsArgs = {
  input: GravityPdfCreateEntryPdfsInput
}

/** The root mutation */
export type RootMutationIncreaseCountArgs = {
  count?: InputMaybe<Scalars['Int']['input']>
}

/** The root mutation */
export type RootMutationLoginArgs = {
  input: LoginInput
}

/** The root mutation */
export type RootMutationRefreshJwtAuthTokenArgs = {
  input: RefreshJwtAuthTokenInput
}

/** The root mutation */
export type RootMutationRegisterUserArgs = {
  input: RegisterUserInput
}

/** The root mutation */
export type RootMutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput
}

/** The root mutation */
export type RootMutationRestoreCommentArgs = {
  input: RestoreCommentInput
}

/** The root mutation */
export type RootMutationResyncIntegrationDataArgs = {
  input: ResyncIntegrationDataInput
}

/** The root mutation */
export type RootMutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput
}

/** The root mutation */
export type RootMutationSubmitGfDraftEntryArgs = {
  input: SubmitGfDraftEntryInput
}

/** The root mutation */
export type RootMutationSubmitGfFormArgs = {
  input: SubmitGfFormInput
}

/** The root mutation */
export type RootMutationSuggestedUsernameArgs = {
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
}

/** The root mutation */
export type RootMutationUpdateActionMonitorActionArgs = {
  input: UpdateActionMonitorActionInput
}

/** The root mutation */
export type RootMutationUpdateCategoryArgs = {
  input: UpdateCategoryInput
}

/** The root mutation */
export type RootMutationUpdateCertificationArgs = {
  input: UpdateCertificationInput
}

/** The root mutation */
export type RootMutationUpdateCertificationExpiryWarningArgs = {
  input: UpdateCertificationExpiryWarningInput
}

/** The root mutation */
export type RootMutationUpdateCommentArgs = {
  input: UpdateCommentInput
}

/** The root mutation */
export type RootMutationUpdateCompanyArgs = {
  input: UpdateCompanyInput
}

/** The root mutation */
export type RootMutationUpdateCorrectiveActionArgs = {
  input: UpdateCorrectiveActionInput
}

/** The root mutation */
export type RootMutationUpdateCorrectiveActionStatusArgs = {
  input: UpdateCorrectiveActionStatusInput
}

/** The root mutation */
export type RootMutationUpdateDeficiencyTypeArgs = {
  input: UpdateDeficiencyTypeInput
}

/** The root mutation */
export type RootMutationUpdateDivisionArgs = {
  input: UpdateDivisionInput
}

/** The root mutation */
export type RootMutationUpdateDocumentArgs = {
  input: UpdateDocumentInput
}

/** The root mutation */
export type RootMutationUpdateDocumentTagArgs = {
  input: UpdateDocumentTagInput
}

/** The root mutation */
export type RootMutationUpdateEntryIdArgs = {
  input: UpdateEntryIdInput
}

/** The root mutation */
export type RootMutationUpdateEquipmentArgs = {
  input: UpdateEquipmentInput
}

/** The root mutation */
export type RootMutationUpdateEquipmentStatusArgs = {
  input: UpdateEquipmentStatusInput
}

/** The root mutation */
export type RootMutationUpdateEquipmentTagArgs = {
  input: UpdateEquipmentTagInput
}

/** The root mutation */
export type RootMutationUpdateFieldIdArgs = {
  input: UpdateFieldIdInput
}

/** The root mutation */
export type RootMutationUpdateFormIdArgs = {
  input: UpdateFormIdInput
}

/** The root mutation */
export type RootMutationUpdateGfDraftEntryArgs = {
  input: UpdateGfDraftEntryInput
}

/** The root mutation */
export type RootMutationUpdateGfEntryArgs = {
  input: UpdateGfEntryInput
}

/** The root mutation */
export type RootMutationUpdateIncidentReportArgs = {
  input: UpdateIncidentReportInput
}

/** The root mutation */
export type RootMutationUpdateIncidentReportClassificationArgs = {
  input: UpdateIncidentReportClassificationInput
}

/** The root mutation */
export type RootMutationUpdateIncidentReportStatusArgs = {
  input: UpdateIncidentReportStatusInput
}

/** The root mutation */
export type RootMutationUpdateIncidentTypeArgs = {
  input: UpdateIncidentTypeInput
}

/** The root mutation */
export type RootMutationUpdateInjuryAreaArgs = {
  input: UpdateInjuryAreaInput
}

/** The root mutation */
export type RootMutationUpdateInspectionIntervalArgs = {
  input: UpdateInspectionIntervalInput
}

/** The root mutation */
export type RootMutationUpdateLessonArgs = {
  input: UpdateLessonInput
}

/** The root mutation */
export type RootMutationUpdateLessonCategoryArgs = {
  input: UpdateLessonCategoryInput
}

/** The root mutation */
export type RootMutationUpdateLessonDifficultyArgs = {
  input: UpdateLessonDifficultyInput
}

/** The root mutation */
export type RootMutationUpdateManufacturerArgs = {
  input: UpdateManufacturerInput
}

/** The root mutation */
export type RootMutationUpdateMediaItemArgs = {
  input: UpdateMediaItemInput
}

/** The root mutation */
export type RootMutationUpdatePageArgs = {
  input: UpdatePageInput
}

/** The root mutation */
export type RootMutationUpdatePhotoArgs = {
  input: UpdatePhotoInput
}

/** The root mutation */
export type RootMutationUpdatePostArgs = {
  input: UpdatePostInput
}

/** The root mutation */
export type RootMutationUpdatePostFormatArgs = {
  input: UpdatePostFormatInput
}

/** The root mutation */
export type RootMutationUpdateProjectArgs = {
  input: UpdateProjectInput
}

/** The root mutation */
export type RootMutationUpdateProjectStatusArgs = {
  input: UpdateProjectStatusInput
}

/** The root mutation */
export type RootMutationUpdateProjectTypeArgs = {
  input: UpdateProjectTypeInput
}

/** The root mutation */
export type RootMutationUpdateProviderArgs = {
  input: UpdateProviderInput
}

/** The root mutation */
export type RootMutationUpdateRootCauseArgs = {
  input: UpdateRootCauseInput
}

/** The root mutation */
export type RootMutationUpdateSettingsArgs = {
  input: UpdateSettingsInput
}

/** The root mutation */
export type RootMutationUpdateSeverityArgs = {
  input: UpdateSeverityInput
}

/** The root mutation */
export type RootMutationUpdateTagArgs = {
  input: UpdateTagInput
}

/** The root mutation */
export type RootMutationUpdateToolboxTalkArgs = {
  input: UpdateToolboxTalkInput
}

/** The root mutation */
export type RootMutationUpdateToolboxTalkCategoryArgs = {
  input: UpdateToolboxTalkCategoryInput
}

/** The root mutation */
export type RootMutationUpdateTrainingTypeArgs = {
  input: UpdateTrainingTypeInput
}

/** The root mutation */
export type RootMutationUpdateUserArgs = {
  input: UpdateUserInput
}

/** The root mutation */
export type RootMutationUpdateUserCompanyArgs = {
  input: UpdateUserCompanyInput
}

/** The root mutation */
export type RootMutationUpdateUserDivisionArgs = {
  input: UpdateUserDivisionInput
}

/** The root mutation */
export type RootMutationUpdateUserLanguageArgs = {
  input: UpdateUserLanguageInput
}

/** The root mutation */
export type RootMutationUpdateUserStatusArgs = {
  input: UpdateUserStatusInput
}

/** The root mutation */
export type RootMutationUpdateUserVaccineStatusArgs = {
  input: UpdateUserVaccineStatusInput
}

/** The root mutation */
export type RootMutationWpGatsbyRemotePreviewStatusArgs = {
  input: WpGatsbyRemotePreviewStatusInput
}

/** The root entry point into the Graph */
export type RootQuery = {
  __typename?: 'RootQuery'
  /** An object of the ActionMonitorAction Type. Used to keep a log of actions in WordPress for cache invalidation in gatsby-source-wordpress. */
  actionMonitorAction?: Maybe<ActionMonitorAction>
  /**
   * A ActionMonitorAction object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  actionMonitorActionBy?: Maybe<ActionMonitorAction>
  /** Connection between the RootQuery type and the ActionMonitorAction type */
  actionMonitorActions?: Maybe<RootQueryToActionMonitorActionConnection>
  /** Connection between the RootQuery type and the Equipment type */
  allEquipment?: Maybe<RootQueryToEquipmentConnection>
  /** Entry point to get all settings for the site */
  allSettings?: Maybe<Settings>
  /** Connection between the RootQuery type and the category type */
  categories?: Maybe<RootQueryToCategoryConnection>
  /** A 0bject */
  category?: Maybe<Category>
  /** An object of the Certification Type.  */
  certification?: Maybe<Certification>
  /**
   * A Certification object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  certificationBy?: Maybe<Certification>
  /** The configured warnings for certificate expirations. */
  certificationExpiryWarnings?: Maybe<
    Array<Maybe<CertificationExpiryWarningRule>>
  >
  /** Connection between the RootQuery type and the Certification type */
  certifications?: Maybe<RootQueryToCertificationConnection>
  /** Returns a Comment */
  comment?: Maybe<Comment>
  /** Connection between the RootQuery type and the Comment type */
  comments?: Maybe<RootQueryToCommentConnection>
  /** Connection between the RootQuery type and the Company type */
  companies?: Maybe<RootQueryToCompanyConnection>
  /** A 0bject */
  company?: Maybe<Company>
  /** Form compliance details. */
  complianceDetails?: Maybe<ComplianceDetails>
  /** A node used to manage content */
  contentNode?: Maybe<ContentNode>
  /** Connection between the RootQuery type and the ContentNode type */
  contentNodes?: Maybe<RootQueryToContentNodeConnection>
  /** Fetch a Content Type node by unique Identifier */
  contentType?: Maybe<ContentType>
  /** Connection between the RootQuery type and the ContentType type */
  contentTypes?: Maybe<RootQueryToContentTypeConnection>
  /** An object of the CorrectiveAction Type.  */
  correctiveAction?: Maybe<CorrectiveAction>
  /**
   * A CorrectiveAction object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  correctiveActionBy?: Maybe<CorrectiveAction>
  /** A 0bject */
  correctiveActionStatus?: Maybe<CorrectiveActionStatus>
  /** Connection between the RootQuery type and the CorrectiveActionStatus type */
  correctiveActionStatuses?: Maybe<RootQueryToCorrectiveActionStatusConnection>
  /** Connection between the RootQuery type and the CorrectiveAction type */
  correctiveActions?: Maybe<RootQueryToCorrectiveActionConnection>
  /** Get language list */
  defaultLanguage?: Maybe<Language>
  /** A 0bject */
  deficiencyType?: Maybe<DeficiencyType>
  /** Connection between the RootQuery type and the DeficiencyType type */
  deficiencyTypes?: Maybe<RootQueryToDeficiencyTypeConnection>
  /** Fields of the &#039;DiscussionSettings&#039; settings group */
  discussionSettings?: Maybe<DiscussionSettings>
  /** A 0bject */
  division?: Maybe<Division>
  /** Connection between the RootQuery type and the Division type */
  divisions?: Maybe<RootQueryToDivisionConnection>
  /** An object of the Document Type.  */
  document?: Maybe<Document>
  /**
   * A Document object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  documentBy?: Maybe<Document>
  /** A 0bject */
  documentTag?: Maybe<DocumentTag>
  /** Connection between the RootQuery type and the DocumentTag type */
  documentTags?: Maybe<RootQueryToDocumentTagConnection>
  /** Connection between the RootQuery type and the Document type */
  documents?: Maybe<RootQueryToDocumentConnection>
  /** A 0bject */
  entryId?: Maybe<EntryId>
  /** Connection between the RootQuery type and the EntryId type */
  entryIds?: Maybe<RootQueryToEntryIdConnection>
  /** An object of the Equipment Type.  */
  equipment?: Maybe<Equipment>
  /**
   * A Equipment object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  equipmentBy?: Maybe<Equipment>
  /** A 0bject */
  equipmentStatus?: Maybe<EquipmentStatus>
  /** Connection between the RootQuery type and the EquipmentStatus type */
  equipmentStatuses?: Maybe<RootQueryToEquipmentStatusConnection>
  /** A 0bject */
  equipmentTag?: Maybe<EquipmentTag>
  /** Connection between the RootQuery type and the EquipmentTag type */
  equipmentTags?: Maybe<RootQueryToEquipmentTagConnection>
  /** A 0bject */
  fieldId?: Maybe<FieldId>
  /** Connection between the RootQuery type and the FieldId type */
  fieldIds?: Maybe<RootQueryToFieldIdConnection>
  /** A 0bject */
  formId?: Maybe<FormId>
  /** Connection between the RootQuery type and the FormId type */
  formIds?: Maybe<RootQueryToFormIdConnection>
  /** Fields of the &#039;GeneralSettings&#039; settings group */
  generalSettings?: Maybe<GeneralSettings>
  /** Get a Gravity Forms entry. */
  gfDraftEntry?: Maybe<GfDraftEntry>
  /** Connection between the RootQuery type and the GfEntry type */
  gfEntries?: Maybe<RootQueryToGfEntryConnection>
  /** Get a Gravity Forms entry. */
  gfEntry?: Maybe<GfEntry>
  /** Get a Gravity Forms form. */
  gfForm?: Maybe<GfForm>
  /** Connection between the RootQuery type and the GfForm type */
  gfForms?: Maybe<RootQueryToGfFormConnection>
  /** Gravity Forms settings. */
  gfSettings?: Maybe<GfSettings>
  /** Connection between the RootQuery type and the GfSubmittedEntry type */
  gfSubmittedEntries?: Maybe<RootQueryToGfSubmittedEntryConnection>
  /** Get a Gravity Forms entry. */
  gfSubmittedEntry?: Maybe<GfSubmittedEntry>
  /** Harness client-specific settings */
  harnessSettings?: Maybe<HarnessSettings>
  /** An object of the IncidentReport Type.  */
  incidentReport?: Maybe<IncidentReport>
  /**
   * A IncidentReport object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  incidentReportBy?: Maybe<IncidentReport>
  /** A 0bject */
  incidentReportClassification?: Maybe<IncidentReportClassification>
  /** Connection between the RootQuery type and the IncidentReportClassification type */
  incidentReportClassifications?: Maybe<RootQueryToIncidentReportClassificationConnection>
  /** Incident report data used to complete the OSHA 300A form. */
  incidentReportData?: Maybe<IncidentReportData>
  /** A 0bject */
  incidentReportStatus?: Maybe<IncidentReportStatus>
  /** Connection between the RootQuery type and the IncidentReportStatus type */
  incidentReportStatuses?: Maybe<RootQueryToIncidentReportStatusConnection>
  /** Connection between the RootQuery type and the IncidentReport type */
  incidentReports?: Maybe<RootQueryToIncidentReportConnection>
  /** A 0bject */
  incidentType?: Maybe<IncidentType>
  /** Connection between the RootQuery type and the IncidentType type */
  incidentTypes?: Maybe<RootQueryToIncidentTypeConnection>
  /** A 0bject */
  injuryArea?: Maybe<InjuryArea>
  /** Connection between the RootQuery type and the InjuryArea type */
  injuryAreas?: Maybe<RootQueryToInjuryAreaConnection>
  /** A 0bject */
  inspectionInterval?: Maybe<InspectionInterval>
  /** Connection between the RootQuery type and the InspectionInterval type */
  inspectionIntervals?: Maybe<RootQueryToInspectionIntervalConnection>
  /** The integration configuration */
  integrationConfig?: Maybe<IntegrationConfig>
  /** Confirms this is a WP Gatsby site */
  isWpGatsby?: Maybe<Scalars['Boolean']['output']>
  /** List available languages */
  languages?: Maybe<Array<Maybe<Language>>>
  /** An object of the Lesson Type.  */
  lesson?: Maybe<Lesson>
  /**
   * A Lesson object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  lessonBy?: Maybe<Lesson>
  /** Connection between the RootQuery type and the lessonCategory type */
  lessonCategories?: Maybe<RootQueryToLessonCategoryConnection>
  /** A 0bject */
  lessonCategory?: Maybe<LessonCategory>
  /** Connection between the RootQuery type and the lessonDifficulty type */
  lessonDifficulties?: Maybe<RootQueryToLessonDifficultyConnection>
  /** A 0bject */
  lessonDifficulty?: Maybe<LessonDifficulty>
  /** Connection between the RootQuery type and the Lesson type */
  lessons?: Maybe<RootQueryToLessonConnection>
  /** A 0bject */
  manufacturer?: Maybe<Manufacturer>
  /** Connection between the RootQuery type and the Manufacturer type */
  manufacturers?: Maybe<RootQueryToManufacturerConnection>
  /** An object of the mediaItem Type.  */
  mediaItem?: Maybe<MediaItem>
  /**
   * A mediaItem object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  mediaItemBy?: Maybe<MediaItem>
  /** Connection between the RootQuery type and the mediaItem type */
  mediaItems?: Maybe<RootQueryToMediaItemConnection>
  /** A WordPress navigation menu */
  menu?: Maybe<Menu>
  /** A WordPress navigation menu item */
  menuItem?: Maybe<MenuItem>
  /** Connection between the RootQuery type and the MenuItem type */
  menuItems?: Maybe<RootQueryToMenuItemConnection>
  /** Connection between the RootQuery type and the Menu type */
  menus?: Maybe<RootQueryToMenuConnection>
  /** Fetches an object given its ID */
  node?: Maybe<Node>
  /** Fetches an object given its Unique Resource Identifier */
  nodeByUri?: Maybe<UniformResourceIdentifiable>
  /** An object of the page Type.  */
  page?: Maybe<Page>
  /**
   * A page object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  pageBy?: Maybe<Page>
  /** Connection between the RootQuery type and the page type */
  pages?: Maybe<RootQueryToPageConnection>
  /** An object of the Photo Type.  */
  photo?: Maybe<Photo>
  /**
   * A Photo object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  photoBy?: Maybe<Photo>
  /** Connection between the RootQuery type and the Photo type */
  photos?: Maybe<RootQueryToPhotoConnection>
  /** A WordPress plugin */
  plugin?: Maybe<Plugin>
  /** Connection between the RootQuery type and the Plugin type */
  plugins?: Maybe<RootQueryToPluginConnection>
  /** An object of the post Type.  */
  post?: Maybe<Post>
  /**
   * A post object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  postBy?: Maybe<Post>
  /** A 0bject */
  postFormat?: Maybe<PostFormat>
  /** Connection between the RootQuery type and the postFormat type */
  postFormats?: Maybe<RootQueryToPostFormatConnection>
  /** Connection between the RootQuery type and the post type */
  posts?: Maybe<RootQueryToPostConnection>
  /** An object of the Project Type.  */
  project?: Maybe<Project>
  /**
   * A Project object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  projectBy?: Maybe<Project>
  /** A 0bject */
  projectStatus?: Maybe<ProjectStatus>
  /** Connection between the RootQuery type and the ProjectStatus type */
  projectStatuses?: Maybe<RootQueryToProjectStatusConnection>
  /** A 0bject */
  projectType?: Maybe<ProjectType>
  /** Connection between the RootQuery type and the ProjectType type */
  projectTypes?: Maybe<RootQueryToProjectTypeConnection>
  /** Connection between the RootQuery type and the Project type */
  projects?: Maybe<RootQueryToProjectConnection>
  /** A 0bject */
  provider?: Maybe<Provider>
  /** Connection between the RootQuery type and the Provider type */
  providers?: Maybe<RootQueryToProviderConnection>
  /** Fields of the &#039;ReadingSettings&#039; settings group */
  readingSettings?: Maybe<ReadingSettings>
  /** Connection between the RootQuery type and the EnqueuedScript type */
  registeredScripts?: Maybe<RootQueryToEnqueuedScriptConnection>
  /** Connection between the RootQuery type and the EnqueuedStylesheet type */
  registeredStylesheets?: Maybe<RootQueryToEnqueuedStylesheetConnection>
  /** Connection between the RootQuery type and the ContentRevisionUnion type */
  revisions?: Maybe<RootQueryToContentRevisionUnionConnection>
  /** A 0bject */
  rootCause?: Maybe<RootCause>
  /** Connection between the RootQuery type and the RootCause type */
  rootCauses?: Maybe<RootQueryToRootCauseConnection>
  /** Returns an MD5 hash of the schema, useful in determining if the schema has changed. */
  schemaMd5?: Maybe<Scalars['String']['output']>
  /** Connection between the RootQuery type and the Severity type */
  severities?: Maybe<RootQueryToSeverityConnection>
  /** A 0bject */
  severity?: Maybe<Severity>
  /** A 0bject */
  tag?: Maybe<Tag>
  /** Connection between the RootQuery type and the tag type */
  tags?: Maybe<RootQueryToTagConnection>
  /** Connection between the RootQuery type and the Taxonomy type */
  taxonomies?: Maybe<RootQueryToTaxonomyConnection>
  /** Fetch a Taxonomy node by unique Identifier */
  taxonomy?: Maybe<Taxonomy>
  /** A node in a taxonomy used to group and relate content nodes */
  termNode?: Maybe<TermNode>
  /** Connection between the RootQuery type and the TermNode type */
  terms?: Maybe<RootQueryToTermNodeConnection>
  /** A Theme object */
  theme?: Maybe<Theme>
  /** Connection between the RootQuery type and the Theme type */
  themes?: Maybe<RootQueryToThemeConnection>
  /** An object of the ToolboxTalk Type.  */
  toolboxTalk?: Maybe<ToolboxTalk>
  /**
   * A ToolboxTalk object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  toolboxTalkBy?: Maybe<ToolboxTalk>
  /** Connection between the RootQuery type and the ToolboxTalkCategory type */
  toolboxTalkCategories?: Maybe<RootQueryToToolboxTalkCategoryConnection>
  /** A 0bject */
  toolboxTalkCategory?: Maybe<ToolboxTalkCategory>
  /** Connection between the RootQuery type and the ToolboxTalk type */
  toolboxTalks?: Maybe<RootQueryToToolboxTalkConnection>
  /** A 0bject */
  trainingType?: Maybe<TrainingType>
  /** Connection between the RootQuery type and the TrainingType type */
  trainingTypes?: Maybe<RootQueryToTrainingTypeConnection>
  /** Translate string using pll_translate_string() (Polylang) */
  translateString?: Maybe<Scalars['String']['output']>
  /** Returns a user */
  user?: Maybe<User>
  /** Connection between the RootQuery type and the UserCompany type */
  userCompanies?: Maybe<RootQueryToUserCompanyConnection>
  /** A 0bject */
  userCompany?: Maybe<UserCompany>
  /** A 0bject */
  userDivision?: Maybe<UserDivision>
  /** Connection between the RootQuery type and the UserDivision type */
  userDivisions?: Maybe<RootQueryToUserDivisionConnection>
  /** A 0bject */
  userLanguage?: Maybe<UserLanguage>
  /** Connection between the RootQuery type and the UserLanguage type */
  userLanguages?: Maybe<RootQueryToUserLanguageConnection>
  /** Returns a user role */
  userRole?: Maybe<UserRole>
  /** Connection between the RootQuery type and the UserRole type */
  userRoles?: Maybe<RootQueryToUserRoleConnection>
  /** A 0bject */
  userStatus?: Maybe<UserStatus>
  /** Connection between the RootQuery type and the UserStatus type */
  userStatuses?: Maybe<RootQueryToUserStatusConnection>
  /** A 0bject */
  userVaccineStatus?: Maybe<UserVaccineStatus>
  /** Connection between the RootQuery type and the UserVaccineStatus type */
  userVaccineStatuses?: Maybe<RootQueryToUserVaccineStatusConnection>
  /** Connection between the RootQuery type and the User type */
  users?: Maybe<RootQueryToUserConnection>
  /** Returns the current user */
  viewer?: Maybe<User>
  /** Information needed by gatsby-source-wordpress. */
  wpGatsby?: Maybe<WpGatsby>
  /** Information about the compatibility of the WordPress server with a provided version of gatsby-source-wordpress. */
  wpGatsbyCompatibility?: Maybe<WpGatsbyCompatibility>
  /** Fields of the &#039;WritingSettings&#039; settings group */
  writingSettings?: Maybe<WritingSettings>
}

/** The root entry point into the Graph */
export type RootQueryActionMonitorActionArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<ActionMonitorActionIdType>
}

/** The root entry point into the Graph */
export type RootQueryActionMonitorActionByArgs = {
  actionMonitorActionId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryActionMonitorActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToActionMonitorActionConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryAllEquipmentArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToEquipmentConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToCategoryConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryCategoryArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<CategoryIdType>
}

/** The root entry point into the Graph */
export type RootQueryCertificationArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<CertificationIdType>
}

/** The root entry point into the Graph */
export type RootQueryCertificationByArgs = {
  certificationId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryCertificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToCertificationConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryCommentArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<CommentNodeIdTypeEnum>
}

/** The root entry point into the Graph */
export type RootQueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToCommentConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToCompanyConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryCompanyArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<CompanyIdType>
}

/** The root entry point into the Graph */
export type RootQueryComplianceDetailsArgs = {
  companyNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  divisionNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  endDate: Scalars['String']['input']
  formId: Scalars['ID']['input']
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  role: UserRoleEnum
  startDate: Scalars['String']['input']
}

/** The root entry point into the Graph */
export type RootQueryContentNodeArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  contentType?: InputMaybe<ContentTypeEnum>
  id: Scalars['ID']['input']
  idType?: InputMaybe<ContentNodeIdTypeEnum>
}

/** The root entry point into the Graph */
export type RootQueryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToContentNodeConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryContentTypeArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<ContentTypeIdTypeEnum>
}

/** The root entry point into the Graph */
export type RootQueryContentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The root entry point into the Graph */
export type RootQueryCorrectiveActionArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<CorrectiveActionIdType>
}

/** The root entry point into the Graph */
export type RootQueryCorrectiveActionByArgs = {
  correctiveActionId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryCorrectiveActionStatusArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<CorrectiveActionStatusIdType>
}

/** The root entry point into the Graph */
export type RootQueryCorrectiveActionStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToCorrectiveActionStatusConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryCorrectiveActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToCorrectiveActionConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryDeficiencyTypeArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<DeficiencyTypeIdType>
}

/** The root entry point into the Graph */
export type RootQueryDeficiencyTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToDeficiencyTypeConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryDivisionArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<DivisionIdType>
}

/** The root entry point into the Graph */
export type RootQueryDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToDivisionConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryDocumentArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<DocumentIdType>
}

/** The root entry point into the Graph */
export type RootQueryDocumentByArgs = {
  documentId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryDocumentTagArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<DocumentTagIdType>
}

/** The root entry point into the Graph */
export type RootQueryDocumentTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToDocumentTagConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToDocumentConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryEntryIdArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<EntryIdIdType>
}

/** The root entry point into the Graph */
export type RootQueryEntryIdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToEntryIdConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryEquipmentArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<EquipmentIdType>
}

/** The root entry point into the Graph */
export type RootQueryEquipmentByArgs = {
  equipmentId?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryEquipmentStatusArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<EquipmentStatusIdType>
}

/** The root entry point into the Graph */
export type RootQueryEquipmentStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToEquipmentStatusConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryEquipmentTagArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<EquipmentTagIdType>
}

/** The root entry point into the Graph */
export type RootQueryEquipmentTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToEquipmentTagConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryFieldIdArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<FieldIdIdType>
}

/** The root entry point into the Graph */
export type RootQueryFieldIdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToFieldIdConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryFormIdArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<FormIdIdType>
}

/** The root entry point into the Graph */
export type RootQueryFormIdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToFormIdConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryGfDraftEntryArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<DraftEntryIdTypeEnum>
}

/** The root entry point into the Graph */
export type RootQueryGfEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToGfEntryConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryGfEntryArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<EntryIdTypeEnum>
}

/** The root entry point into the Graph */
export type RootQueryGfFormArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<FormIdTypeEnum>
}

/** The root entry point into the Graph */
export type RootQueryGfFormsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToGfFormConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryGfSubmittedEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToGfSubmittedEntryConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryGfSubmittedEntryArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<SubmittedEntryIdTypeEnum>
}

/** The root entry point into the Graph */
export type RootQueryIncidentReportArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<IncidentReportIdType>
}

/** The root entry point into the Graph */
export type RootQueryIncidentReportByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  incidentReportId?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryIncidentReportClassificationArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<IncidentReportClassificationIdType>
}

/** The root entry point into the Graph */
export type RootQueryIncidentReportClassificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToIncidentReportClassificationConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryIncidentReportDataArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryIncidentReportStatusArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<IncidentReportStatusIdType>
}

/** The root entry point into the Graph */
export type RootQueryIncidentReportStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToIncidentReportStatusConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryIncidentReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToIncidentReportConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryIncidentTypeArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<IncidentTypeIdType>
}

/** The root entry point into the Graph */
export type RootQueryIncidentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToIncidentTypeConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryInjuryAreaArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<InjuryAreaIdType>
}

/** The root entry point into the Graph */
export type RootQueryInjuryAreasArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToInjuryAreaConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryInspectionIntervalArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<InspectionIntervalIdType>
}

/** The root entry point into the Graph */
export type RootQueryInspectionIntervalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToInspectionIntervalConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryLessonArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<LessonIdType>
}

/** The root entry point into the Graph */
export type RootQueryLessonByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  lessonId?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryLessonCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToLessonCategoryConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryLessonCategoryArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<LessonCategoryIdType>
}

/** The root entry point into the Graph */
export type RootQueryLessonDifficultiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToLessonDifficultyConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryLessonDifficultyArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<LessonDifficultyIdType>
}

/** The root entry point into the Graph */
export type RootQueryLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToLessonConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryManufacturerArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<ManufacturerIdType>
}

/** The root entry point into the Graph */
export type RootQueryManufacturersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToManufacturerConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryMediaItemArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<MediaItemIdType>
}

/** The root entry point into the Graph */
export type RootQueryMediaItemByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  mediaItemId?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryMediaItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToMediaItemConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryMenuArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<MenuNodeIdTypeEnum>
}

/** The root entry point into the Graph */
export type RootQueryMenuItemArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<MenuItemNodeIdTypeEnum>
}

/** The root entry point into the Graph */
export type RootQueryMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToMenuItemConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryMenusArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToMenuConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryNodeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** The root entry point into the Graph */
export type RootQueryNodeByUriArgs = {
  uri: Scalars['String']['input']
}

/** The root entry point into the Graph */
export type RootQueryPageArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<PageIdType>
}

/** The root entry point into the Graph */
export type RootQueryPageByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  pageId?: InputMaybe<Scalars['Int']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToPageConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryPhotoArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<PhotoIdType>
}

/** The root entry point into the Graph */
export type RootQueryPhotoByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  photoId?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryPhotosArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToPhotoConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryPluginArgs = {
  id: Scalars['ID']['input']
}

/** The root entry point into the Graph */
export type RootQueryPluginsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToPluginConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryPostArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<PostIdType>
}

/** The root entry point into the Graph */
export type RootQueryPostByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  postId?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryPostFormatArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<PostFormatIdType>
}

/** The root entry point into the Graph */
export type RootQueryPostFormatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToPostFormatConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToPostConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryProjectArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<ProjectIdType>
}

/** The root entry point into the Graph */
export type RootQueryProjectByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  projectId?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryProjectStatusArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<ProjectStatusIdType>
}

/** The root entry point into the Graph */
export type RootQueryProjectStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToProjectStatusConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryProjectTypeArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<ProjectTypeIdType>
}

/** The root entry point into the Graph */
export type RootQueryProjectTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToProjectTypeConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToProjectConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryProviderArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<ProviderIdType>
}

/** The root entry point into the Graph */
export type RootQueryProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToProviderConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryRegisteredScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The root entry point into the Graph */
export type RootQueryRegisteredStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The root entry point into the Graph */
export type RootQueryRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToContentRevisionUnionConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryRootCauseArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<RootCauseIdType>
}

/** The root entry point into the Graph */
export type RootQueryRootCausesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToRootCauseConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQuerySeveritiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToSeverityConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQuerySeverityArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<SeverityIdType>
}

/** The root entry point into the Graph */
export type RootQueryTagArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<TagIdType>
}

/** The root entry point into the Graph */
export type RootQueryTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToTagConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryTaxonomiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The root entry point into the Graph */
export type RootQueryTaxonomyArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<TaxonomyIdTypeEnum>
}

/** The root entry point into the Graph */
export type RootQueryTermNodeArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<TermNodeIdTypeEnum>
  taxonomy?: InputMaybe<TaxonomyEnum>
}

/** The root entry point into the Graph */
export type RootQueryTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToTermNodeConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryThemeArgs = {
  id: Scalars['ID']['input']
}

/** The root entry point into the Graph */
export type RootQueryThemesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The root entry point into the Graph */
export type RootQueryToolboxTalkArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  idType?: InputMaybe<ToolboxTalkIdType>
}

/** The root entry point into the Graph */
export type RootQueryToolboxTalkByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  toolboxTalkId?: InputMaybe<Scalars['Int']['input']>
  uri?: InputMaybe<Scalars['String']['input']>
}

/** The root entry point into the Graph */
export type RootQueryToolboxTalkCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToToolboxTalkCategoryConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryToolboxTalkCategoryArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<ToolboxTalkCategoryIdType>
}

/** The root entry point into the Graph */
export type RootQueryToolboxTalksArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToToolboxTalkConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryTrainingTypeArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<TrainingTypeIdType>
}

/** The root entry point into the Graph */
export type RootQueryTrainingTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToTrainingTypeConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryTranslateStringArgs = {
  language: LanguageCodeEnum
  string: Scalars['String']['input']
}

/** The root entry point into the Graph */
export type RootQueryUserArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<UserNodeIdTypeEnum>
}

/** The root entry point into the Graph */
export type RootQueryUserCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToUserCompanyConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryUserCompanyArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<UserCompanyIdType>
}

/** The root entry point into the Graph */
export type RootQueryUserDivisionArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<UserDivisionIdType>
}

/** The root entry point into the Graph */
export type RootQueryUserDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToUserDivisionConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryUserLanguageArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<UserLanguageIdType>
}

/** The root entry point into the Graph */
export type RootQueryUserLanguagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToUserLanguageConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryUserRoleArgs = {
  id: Scalars['ID']['input']
}

/** The root entry point into the Graph */
export type RootQueryUserRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The root entry point into the Graph */
export type RootQueryUserStatusArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<UserStatusIdType>
}

/** The root entry point into the Graph */
export type RootQueryUserStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToUserStatusConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryUserVaccineStatusArgs = {
  id: Scalars['ID']['input']
  idType?: InputMaybe<UserVaccineStatusIdType>
}

/** The root entry point into the Graph */
export type RootQueryUserVaccineStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToUserVaccineStatusConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToUserConnectionWhereArgs>
}

/** The root entry point into the Graph */
export type RootQueryWpGatsbyCompatibilityArgs = {
  wpGQLVersionRange: Scalars['String']['input']
  wpGatsbyVersionRange: Scalars['String']['input']
}

/** Connection between the RootQuery type and the ActionMonitorAction type */
export type RootQueryToActionMonitorActionConnection = {
  __typename?: 'RootQueryToActionMonitorActionConnection'
  /** Edges for the RootQueryToActionMonitorActionConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToActionMonitorActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ActionMonitorAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToActionMonitorActionConnectionEdge = {
  __typename?: 'RootQueryToActionMonitorActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ActionMonitorAction>
}

/** Arguments for filtering the RootQueryToActionMonitorActionConnection connection */
export type RootQueryToActionMonitorActionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootQueryToActionMonitorActionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** List Actions of the PREVIEW stream type. */
  previewStream?: InputMaybe<Scalars['Boolean']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** List Actions performed since a timestamp. */
  sinceTimestamp?: InputMaybe<Scalars['Float']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToActionMonitorActionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToActionMonitorActionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToActionMonitorActionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToActionMonitorActionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToActionMonitorActionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToActionMonitorActionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<
      InputMaybe<RootQueryToActionMonitorActionConnectionWhereArgsMetaArray>
    >
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToActionMonitorActionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToActionMonitorActionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToActionMonitorActionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToActionMonitorActionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToActionMonitorActionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToActionMonitorActionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToActionMonitorActionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToActionMonitorActionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the category type */
export type RootQueryToCategoryConnection = {
  __typename?: 'RootQueryToCategoryConnection'
  /** Edges for the RootQueryToCategoryConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToCategoryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Category>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToCategoryConnectionEdge = {
  __typename?: 'RootQueryToCategoryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Category>
}

/** Arguments for filtering the RootQueryToCategoryConnection connection */
export type RootQueryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter by Categorys by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>
  /** Filter Categorys by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the Certification type */
export type RootQueryToCertificationConnection = {
  __typename?: 'RootQueryToCertificationConnection'
  /** Edges for the RootQueryToCertificationConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToCertificationConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Certification>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToCertificationConnectionEdge = {
  __typename?: 'RootQueryToCertificationConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Certification>
}

/** Arguments for filtering the RootQueryToCertificationConnection connection */
export type RootQueryToCertificationConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootQueryToCertificationConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnectionQuery?: InputMaybe<PostToPostConnectionQuery>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToCertificationConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToCertificationConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToCertificationConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToCertificationConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToCertificationConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToCertificationConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToCertificationConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToCertificationConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToCertificationConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToCertificationConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToCertificationConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToCertificationConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToCertificationConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToCertificationConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToCertificationConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the Comment type */
export type RootQueryToCommentConnection = {
  __typename?: 'RootQueryToCommentConnection'
  /** Edges for the RootQueryToCommentConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToCommentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Comment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToCommentConnectionEdge = {
  __typename?: 'RootQueryToCommentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Comment>
}

/** Arguments for filtering the RootQueryToCommentConnection connection */
export type RootQueryToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>
}

/** Connection between the RootQuery type and the Company type */
export type RootQueryToCompanyConnection = {
  __typename?: 'RootQueryToCompanyConnection'
  /** Edges for the RootQueryToCompanyConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToCompanyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Company>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToCompanyConnectionEdge = {
  __typename?: 'RootQueryToCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Company>
}

/** Arguments for filtering the RootQueryToCompanyConnection connection */
export type RootQueryToCompanyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the ContentNode type */
export type RootQueryToContentNodeConnection = {
  __typename?: 'RootQueryToContentNodeConnection'
  /** Edges for the RootQueryToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToContentNodeConnectionEdge = {
  __typename?: 'RootQueryToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the RootQueryToContentNodeConnection connection */
export type RootQueryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter content nodes by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>
  /** Filter content nodes by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>
  metaQuery?: InputMaybe<RootQueryToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the ContentRevisionUnion type */
export type RootQueryToContentRevisionUnionConnection = {
  __typename?: 'RootQueryToContentRevisionUnionConnection'
  /** Edges for the RootQueryToContentRevisionUnionConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToContentRevisionUnionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentRevisionUnion>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToContentRevisionUnionConnectionEdge = {
  __typename?: 'RootQueryToContentRevisionUnionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentRevisionUnion>
}

/** Arguments for filtering the RootQueryToContentRevisionUnionConnection connection */
export type RootQueryToContentRevisionUnionConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootQueryToContentRevisionUnionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToContentRevisionUnionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToContentRevisionUnionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToContentRevisionUnionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToContentRevisionUnionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToContentRevisionUnionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToContentRevisionUnionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<
      InputMaybe<RootQueryToContentRevisionUnionConnectionWhereArgsMetaArray>
    >
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToContentRevisionUnionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToContentRevisionUnionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToContentRevisionUnionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToContentRevisionUnionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToContentRevisionUnionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<
      InputMaybe<RootQueryToContentRevisionUnionConnectionWhereArgsTaxArray>
    >
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToContentRevisionUnionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToContentRevisionUnionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the ContentType type */
export type RootQueryToContentTypeConnection = {
  __typename?: 'RootQueryToContentTypeConnection'
  /** Edges for the RootQueryToContentTypeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToContentTypeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentType>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToContentTypeConnectionEdge = {
  __typename?: 'RootQueryToContentTypeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentType>
}

/** Connection between the RootQuery type and the CorrectiveAction type */
export type RootQueryToCorrectiveActionConnection = {
  __typename?: 'RootQueryToCorrectiveActionConnection'
  /** Edges for the RootQueryToCorrectiveActionConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToCorrectiveActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToCorrectiveActionConnectionEdge = {
  __typename?: 'RootQueryToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Arguments for filtering the RootQueryToCorrectiveActionConnection connection */
export type RootQueryToCorrectiveActionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootQueryToCorrectiveActionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnectionQuery?: InputMaybe<PostToPostConnectionQuery>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToCorrectiveActionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToCorrectiveActionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToCorrectiveActionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToCorrectiveActionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToCorrectiveActionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToCorrectiveActionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToCorrectiveActionConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToCorrectiveActionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToCorrectiveActionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToCorrectiveActionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToCorrectiveActionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToCorrectiveActionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToCorrectiveActionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToCorrectiveActionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToCorrectiveActionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the CorrectiveActionStatus type */
export type RootQueryToCorrectiveActionStatusConnection = {
  __typename?: 'RootQueryToCorrectiveActionStatusConnection'
  /** Edges for the RootQueryToCorrectiveActionStatusConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToCorrectiveActionStatusConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveActionStatus>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToCorrectiveActionStatusConnectionEdge = {
  __typename?: 'RootQueryToCorrectiveActionStatusConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveActionStatus>
}

/** Arguments for filtering the RootQueryToCorrectiveActionStatusConnection connection */
export type RootQueryToCorrectiveActionStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the DeficiencyType type */
export type RootQueryToDeficiencyTypeConnection = {
  __typename?: 'RootQueryToDeficiencyTypeConnection'
  /** Edges for the RootQueryToDeficiencyTypeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToDeficiencyTypeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<DeficiencyType>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToDeficiencyTypeConnectionEdge = {
  __typename?: 'RootQueryToDeficiencyTypeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<DeficiencyType>
}

/** Arguments for filtering the RootQueryToDeficiencyTypeConnection connection */
export type RootQueryToDeficiencyTypeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the Division type */
export type RootQueryToDivisionConnection = {
  __typename?: 'RootQueryToDivisionConnection'
  /** Edges for the RootQueryToDivisionConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToDivisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Division>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToDivisionConnectionEdge = {
  __typename?: 'RootQueryToDivisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Division>
}

/** Arguments for filtering the RootQueryToDivisionConnection connection */
export type RootQueryToDivisionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the Document type */
export type RootQueryToDocumentConnection = {
  __typename?: 'RootQueryToDocumentConnection'
  /** Edges for the RootQueryToDocumentConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToDocumentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Document>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToDocumentConnectionEdge = {
  __typename?: 'RootQueryToDocumentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Document>
}

/** Arguments for filtering the RootQueryToDocumentConnection connection */
export type RootQueryToDocumentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootQueryToDocumentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnectionQuery?: InputMaybe<PostToPostConnectionQuery>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToDocumentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToDocumentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToDocumentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToDocumentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToDocumentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToDocumentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToDocumentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToDocumentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToDocumentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToDocumentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToDocumentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToDocumentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToDocumentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToDocumentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToDocumentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the DocumentTag type */
export type RootQueryToDocumentTagConnection = {
  __typename?: 'RootQueryToDocumentTagConnection'
  /** Edges for the RootQueryToDocumentTagConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToDocumentTagConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<DocumentTag>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToDocumentTagConnectionEdge = {
  __typename?: 'RootQueryToDocumentTagConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<DocumentTag>
}

/** Arguments for filtering the RootQueryToDocumentTagConnection connection */
export type RootQueryToDocumentTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the EnqueuedScript type */
export type RootQueryToEnqueuedScriptConnection = {
  __typename?: 'RootQueryToEnqueuedScriptConnection'
  /** Edges for the RootQueryToEnqueuedScriptConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToEnqueuedScriptConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToEnqueuedScriptConnectionEdge = {
  __typename?: 'RootQueryToEnqueuedScriptConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedScript>
}

/** Connection between the RootQuery type and the EnqueuedStylesheet type */
export type RootQueryToEnqueuedStylesheetConnection = {
  __typename?: 'RootQueryToEnqueuedStylesheetConnection'
  /** Edges for the RootQueryToEnqueuedStylesheetConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToEnqueuedStylesheetConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToEnqueuedStylesheetConnectionEdge = {
  __typename?: 'RootQueryToEnqueuedStylesheetConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedStylesheet>
}

/** Connection between the RootQuery type and the EntryId type */
export type RootQueryToEntryIdConnection = {
  __typename?: 'RootQueryToEntryIdConnection'
  /** Edges for the RootQueryToEntryIdConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToEntryIdConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EntryId>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToEntryIdConnectionEdge = {
  __typename?: 'RootQueryToEntryIdConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EntryId>
}

/** Arguments for filtering the RootQueryToEntryIdConnection connection */
export type RootQueryToEntryIdConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the Equipment type */
export type RootQueryToEquipmentConnection = {
  __typename?: 'RootQueryToEquipmentConnection'
  /** Edges for the RootQueryToEquipmentConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToEquipmentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToEquipmentConnectionEdge = {
  __typename?: 'RootQueryToEquipmentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Arguments for filtering the RootQueryToEquipmentConnection connection */
export type RootQueryToEquipmentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootQueryToEquipmentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnectionQuery?: InputMaybe<PostToPostConnectionQuery>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToEquipmentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToEquipmentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToEquipmentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToEquipmentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToEquipmentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToEquipmentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToEquipmentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToEquipmentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToEquipmentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToEquipmentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToEquipmentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToEquipmentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToEquipmentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToEquipmentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToEquipmentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the EquipmentStatus type */
export type RootQueryToEquipmentStatusConnection = {
  __typename?: 'RootQueryToEquipmentStatusConnection'
  /** Edges for the RootQueryToEquipmentStatusConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToEquipmentStatusConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EquipmentStatus>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToEquipmentStatusConnectionEdge = {
  __typename?: 'RootQueryToEquipmentStatusConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EquipmentStatus>
}

/** Arguments for filtering the RootQueryToEquipmentStatusConnection connection */
export type RootQueryToEquipmentStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the EquipmentTag type */
export type RootQueryToEquipmentTagConnection = {
  __typename?: 'RootQueryToEquipmentTagConnection'
  /** Edges for the RootQueryToEquipmentTagConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToEquipmentTagConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EquipmentTag>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToEquipmentTagConnectionEdge = {
  __typename?: 'RootQueryToEquipmentTagConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EquipmentTag>
}

/** Arguments for filtering the RootQueryToEquipmentTagConnection connection */
export type RootQueryToEquipmentTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the FieldId type */
export type RootQueryToFieldIdConnection = {
  __typename?: 'RootQueryToFieldIdConnection'
  /** Edges for the RootQueryToFieldIdConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToFieldIdConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<FieldId>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToFieldIdConnectionEdge = {
  __typename?: 'RootQueryToFieldIdConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<FieldId>
}

/** Arguments for filtering the RootQueryToFieldIdConnection connection */
export type RootQueryToFieldIdConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the FormId type */
export type RootQueryToFormIdConnection = {
  __typename?: 'RootQueryToFormIdConnection'
  /** Edges for the RootQueryToFormIdConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToFormIdConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<FormId>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToFormIdConnectionEdge = {
  __typename?: 'RootQueryToFormIdConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<FormId>
}

/** Arguments for filtering the RootQueryToFormIdConnection connection */
export type RootQueryToFormIdConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the GfEntry type */
export type RootQueryToGfEntryConnection = {
  __typename?: 'RootQueryToGfEntryConnection'
  /** Edges for the RootQueryToGfEntryConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToGfEntryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<GfEntry>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToGfEntryConnectionEdge = {
  __typename?: 'RootQueryToGfEntryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<GfEntry>
}

/** Arguments for filtering the RootQueryToGfEntryConnection connection */
export type RootQueryToGfEntryConnectionWhereArgs = {
  /** The company names. */
  companyNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Date filters to apply. */
  dateFilters?: InputMaybe<EntriesDateFiltersInput>
  /** The division names. */
  divisionNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Entry status. Default is `SUBMITTED`. Currently no other types are supported. */
  entryType?: InputMaybe<EntryTypeEnum>
  /** Field-specific filters to apply. */
  fieldFilters?: InputMaybe<Array<InputMaybe<EntriesFieldFiltersInput>>>
  /** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
  fieldFiltersMode?: InputMaybe<FieldFiltersModeEnum>
  /** Array of form IDs to limit the entries to. Exclude this argument to query all forms. */
  formIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The form slugs. */
  formSlugs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** How to sort the entries. */
  orderby?: InputMaybe<EntriesConnectionOrderbyInput>
  /** The project IDs. */
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Entry status. Default is "ACTIVE". */
  status?: InputMaybe<EntryStatusEnum>
}

/** Connection between the RootQuery type and the GfForm type */
export type RootQueryToGfFormConnection = {
  __typename?: 'RootQueryToGfFormConnection'
  /** Edges for the RootQueryToGfFormConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToGfFormConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<GfForm>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToGfFormConnectionEdge = {
  __typename?: 'RootQueryToGfFormConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<GfForm>
}

/** Arguments for filtering the RootQueryToGfFormConnection connection */
export type RootQueryToGfFormConnectionWhereArgs = {
  /** Array of form database IDs to return. Exclude this argument to query all forms. */
  formIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The form slugs. */
  formSlugs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** How to sort the entries. */
  orderby?: InputMaybe<FormsConnectionOrderbyInput>
  /** Post Type To Create Name */
  postTypeToCreate?: InputMaybe<Scalars['String']['input']>
  /** Status of the forms to get. */
  status?: InputMaybe<FormStatusEnum>
}

/** Connection between the RootQuery type and the GfSubmittedEntry type */
export type RootQueryToGfSubmittedEntryConnection = {
  __typename?: 'RootQueryToGfSubmittedEntryConnection'
  /** Edges for the RootQueryToGfSubmittedEntryConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToGfSubmittedEntryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<GfSubmittedEntry>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToGfSubmittedEntryConnectionEdge = {
  __typename?: 'RootQueryToGfSubmittedEntryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<GfSubmittedEntry>
}

/** Arguments for filtering the RootQueryToGfSubmittedEntryConnection connection */
export type RootQueryToGfSubmittedEntryConnectionWhereArgs = {
  /** The company names. */
  companyNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Date filters to apply. */
  dateFilters?: InputMaybe<EntriesDateFiltersInput>
  /** The division names. */
  divisionNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Field-specific filters to apply. */
  fieldFilters?: InputMaybe<Array<InputMaybe<EntriesFieldFiltersInput>>>
  /** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
  fieldFiltersMode?: InputMaybe<FieldFiltersModeEnum>
  /** Array of form IDs to limit the entries to. Exclude this argument to query all forms. */
  formIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The form slugs. */
  formSlugs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** How to sort the entries. */
  orderby?: InputMaybe<EntriesConnectionOrderbyInput>
  /** The project IDs. */
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Entry status. Default is "ACTIVE". */
  status?: InputMaybe<EntryStatusEnum>
}

/** Connection between the RootQuery type and the IncidentReportClassification type */
export type RootQueryToIncidentReportClassificationConnection = {
  __typename?: 'RootQueryToIncidentReportClassificationConnection'
  /** Edges for the RootQueryToIncidentReportClassificationConnection connection */
  edges?: Maybe<
    Array<Maybe<RootQueryToIncidentReportClassificationConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReportClassification>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToIncidentReportClassificationConnectionEdge = {
  __typename?: 'RootQueryToIncidentReportClassificationConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReportClassification>
}

/** Arguments for filtering the RootQueryToIncidentReportClassificationConnection connection */
export type RootQueryToIncidentReportClassificationConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the IncidentReport type */
export type RootQueryToIncidentReportConnection = {
  __typename?: 'RootQueryToIncidentReportConnection'
  /** Edges for the RootQueryToIncidentReportConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToIncidentReportConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReport>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToIncidentReportConnectionEdge = {
  __typename?: 'RootQueryToIncidentReportConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReport>
}

/** Arguments for filtering the RootQueryToIncidentReportConnection connection */
export type RootQueryToIncidentReportConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootQueryToIncidentReportConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnectionQuery?: InputMaybe<PostToPostConnectionQuery>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToIncidentReportConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToIncidentReportConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToIncidentReportConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToIncidentReportConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToIncidentReportConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToIncidentReportConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToIncidentReportConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToIncidentReportConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToIncidentReportConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToIncidentReportConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToIncidentReportConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToIncidentReportConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToIncidentReportConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToIncidentReportConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToIncidentReportConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the IncidentReportStatus type */
export type RootQueryToIncidentReportStatusConnection = {
  __typename?: 'RootQueryToIncidentReportStatusConnection'
  /** Edges for the RootQueryToIncidentReportStatusConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToIncidentReportStatusConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReportStatus>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToIncidentReportStatusConnectionEdge = {
  __typename?: 'RootQueryToIncidentReportStatusConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReportStatus>
}

/** Arguments for filtering the RootQueryToIncidentReportStatusConnection connection */
export type RootQueryToIncidentReportStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the IncidentType type */
export type RootQueryToIncidentTypeConnection = {
  __typename?: 'RootQueryToIncidentTypeConnection'
  /** Edges for the RootQueryToIncidentTypeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToIncidentTypeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentType>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToIncidentTypeConnectionEdge = {
  __typename?: 'RootQueryToIncidentTypeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentType>
}

/** Arguments for filtering the RootQueryToIncidentTypeConnection connection */
export type RootQueryToIncidentTypeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the InjuryArea type */
export type RootQueryToInjuryAreaConnection = {
  __typename?: 'RootQueryToInjuryAreaConnection'
  /** Edges for the RootQueryToInjuryAreaConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToInjuryAreaConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<InjuryArea>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToInjuryAreaConnectionEdge = {
  __typename?: 'RootQueryToInjuryAreaConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<InjuryArea>
}

/** Arguments for filtering the RootQueryToInjuryAreaConnection connection */
export type RootQueryToInjuryAreaConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the InspectionInterval type */
export type RootQueryToInspectionIntervalConnection = {
  __typename?: 'RootQueryToInspectionIntervalConnection'
  /** Edges for the RootQueryToInspectionIntervalConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToInspectionIntervalConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<InspectionInterval>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToInspectionIntervalConnectionEdge = {
  __typename?: 'RootQueryToInspectionIntervalConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<InspectionInterval>
}

/** Arguments for filtering the RootQueryToInspectionIntervalConnection connection */
export type RootQueryToInspectionIntervalConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the lessonCategory type */
export type RootQueryToLessonCategoryConnection = {
  __typename?: 'RootQueryToLessonCategoryConnection'
  /** Edges for the RootQueryToLessonCategoryConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToLessonCategoryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<LessonCategory>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToLessonCategoryConnectionEdge = {
  __typename?: 'RootQueryToLessonCategoryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<LessonCategory>
}

/** Arguments for filtering the RootQueryToLessonCategoryConnection connection */
export type RootQueryToLessonCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the Lesson type */
export type RootQueryToLessonConnection = {
  __typename?: 'RootQueryToLessonConnection'
  /** Edges for the RootQueryToLessonConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToLessonConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Lesson>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToLessonConnectionEdge = {
  __typename?: 'RootQueryToLessonConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Lesson>
}

/** Arguments for filtering the RootQueryToLessonConnection connection */
export type RootQueryToLessonConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootQueryToLessonConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnectionQuery?: InputMaybe<PostToPostConnectionQuery>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToLessonConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToLessonConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToLessonConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToLessonConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToLessonConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToLessonConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToLessonConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToLessonConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToLessonConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToLessonConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToLessonConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToLessonConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToLessonConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToLessonConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToLessonConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the lessonDifficulty type */
export type RootQueryToLessonDifficultyConnection = {
  __typename?: 'RootQueryToLessonDifficultyConnection'
  /** Edges for the RootQueryToLessonDifficultyConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToLessonDifficultyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<LessonDifficulty>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToLessonDifficultyConnectionEdge = {
  __typename?: 'RootQueryToLessonDifficultyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<LessonDifficulty>
}

/** Arguments for filtering the RootQueryToLessonDifficultyConnection connection */
export type RootQueryToLessonDifficultyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the Manufacturer type */
export type RootQueryToManufacturerConnection = {
  __typename?: 'RootQueryToManufacturerConnection'
  /** Edges for the RootQueryToManufacturerConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToManufacturerConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Manufacturer>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToManufacturerConnectionEdge = {
  __typename?: 'RootQueryToManufacturerConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Manufacturer>
}

/** Arguments for filtering the RootQueryToManufacturerConnection connection */
export type RootQueryToManufacturerConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the mediaItem type */
export type RootQueryToMediaItemConnection = {
  __typename?: 'RootQueryToMediaItemConnection'
  /** Edges for the RootQueryToMediaItemConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToMediaItemConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MediaItem>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToMediaItemConnectionEdge = {
  __typename?: 'RootQueryToMediaItemConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<MediaItem>
}

/** Arguments for filtering the RootQueryToMediaItemConnection connection */
export type RootQueryToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter by MediaItems by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>
  /** Filter MediaItems by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>
  metaQuery?: InputMaybe<RootQueryToMediaItemConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToMediaItemConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToMediaItemConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToMediaItemConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToMediaItemConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToMediaItemConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToMediaItemConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToMediaItemConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToMediaItemConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToMediaItemConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToMediaItemConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToMediaItemConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToMediaItemConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToMediaItemConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToMediaItemConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToMediaItemConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the Menu type */
export type RootQueryToMenuConnection = {
  __typename?: 'RootQueryToMenuConnection'
  /** Edges for the RootQueryToMenuConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToMenuConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Menu>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToMenuConnectionEdge = {
  __typename?: 'RootQueryToMenuConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Menu>
}

/** Arguments for filtering the RootQueryToMenuConnection connection */
export type RootQueryToMenuConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>
  /** The slug of the menu to query items for */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Connection between the RootQuery type and the MenuItem type */
export type RootQueryToMenuItemConnection = {
  __typename?: 'RootQueryToMenuItemConnection'
  /** Edges for the RootQueryToMenuItemConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToMenuItemConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MenuItem>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToMenuItemConnectionEdge = {
  __typename?: 'RootQueryToMenuItemConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<MenuItem>
}

/** Arguments for filtering the RootQueryToMenuItemConnection connection */
export type RootQueryToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  language?: InputMaybe<LanguageCodeFilterEnum>
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']['input']>
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']['input']>
}

/** Connection between the RootQuery type and the page type */
export type RootQueryToPageConnection = {
  __typename?: 'RootQueryToPageConnection'
  /** Edges for the RootQueryToPageConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToPageConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Page>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToPageConnectionEdge = {
  __typename?: 'RootQueryToPageConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Page>
}

/** Arguments for filtering the RootQueryToPageConnection connection */
export type RootQueryToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter by Pages by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>
  /** Filter Pages by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>
  metaQuery?: InputMaybe<RootQueryToPageConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToPageConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToPageConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToPageConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToPageConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToPageConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToPageConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToPageConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToPageConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToPageConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToPageConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToPageConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToPageConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToPageConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToPageConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToPageConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the Photo type */
export type RootQueryToPhotoConnection = {
  __typename?: 'RootQueryToPhotoConnection'
  /** Edges for the RootQueryToPhotoConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToPhotoConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Photo>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToPhotoConnectionEdge = {
  __typename?: 'RootQueryToPhotoConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Photo>
}

/** Arguments for filtering the RootQueryToPhotoConnection connection */
export type RootQueryToPhotoConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootQueryToPhotoConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnectionQuery?: InputMaybe<PostToPostConnectionQuery>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToPhotoConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToPhotoConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToPhotoConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToPhotoConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToPhotoConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToPhotoConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToPhotoConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToPhotoConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToPhotoConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToPhotoConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToPhotoConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToPhotoConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToPhotoConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToPhotoConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToPhotoConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the Plugin type */
export type RootQueryToPluginConnection = {
  __typename?: 'RootQueryToPluginConnection'
  /** Edges for the RootQueryToPluginConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToPluginConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Plugin>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToPluginConnectionEdge = {
  __typename?: 'RootQueryToPluginConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Plugin>
}

/** Arguments for filtering the RootQueryToPluginConnection connection */
export type RootQueryToPluginConnectionWhereArgs = {
  /** Show plugin based on a keyword search. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve plugins where plugin status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PluginStatusEnum>>>
  /** Show plugins with a specific status. */
  status?: InputMaybe<PluginStatusEnum>
}

/** Connection between the RootQuery type and the post type */
export type RootQueryToPostConnection = {
  __typename?: 'RootQueryToPostConnection'
  /** Edges for the RootQueryToPostConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToPostConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToPostConnectionEdge = {
  __typename?: 'RootQueryToPostConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Post>
}

/** Arguments for filtering the RootQueryToPostConnection connection */
export type RootQueryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter by Posts by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>
  /** Filter Posts by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>
  metaQuery?: InputMaybe<RootQueryToPostConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  taxQuery?: InputMaybe<RootQueryToPostConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToPostConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToPostConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToPostConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToPostConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToPostConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToPostConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToPostConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToPostConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToPostConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToPostConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToPostConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToPostConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToPostConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToPostConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the postFormat type */
export type RootQueryToPostFormatConnection = {
  __typename?: 'RootQueryToPostFormatConnection'
  /** Edges for the RootQueryToPostFormatConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToPostFormatConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<PostFormat>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToPostFormatConnectionEdge = {
  __typename?: 'RootQueryToPostFormatConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<PostFormat>
}

/** Arguments for filtering the RootQueryToPostFormatConnection connection */
export type RootQueryToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the Project type */
export type RootQueryToProjectConnection = {
  __typename?: 'RootQueryToProjectConnection'
  /** Edges for the RootQueryToProjectConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToProjectConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToProjectConnectionEdge = {
  __typename?: 'RootQueryToProjectConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Arguments for filtering the RootQueryToProjectConnection connection */
export type RootQueryToProjectConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<RootQueryToProjectConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnectionQuery?: InputMaybe<PostToPostConnectionQuery>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToProjectConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToProjectConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToProjectConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToProjectConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToProjectConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToProjectConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToProjectConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToProjectConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToProjectConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToProjectConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToProjectConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToProjectConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToProjectConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToProjectConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToProjectConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the ProjectStatus type */
export type RootQueryToProjectStatusConnection = {
  __typename?: 'RootQueryToProjectStatusConnection'
  /** Edges for the RootQueryToProjectStatusConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToProjectStatusConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ProjectStatus>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToProjectStatusConnectionEdge = {
  __typename?: 'RootQueryToProjectStatusConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ProjectStatus>
}

/** Arguments for filtering the RootQueryToProjectStatusConnection connection */
export type RootQueryToProjectStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the ProjectType type */
export type RootQueryToProjectTypeConnection = {
  __typename?: 'RootQueryToProjectTypeConnection'
  /** Edges for the RootQueryToProjectTypeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToProjectTypeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ProjectType>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToProjectTypeConnectionEdge = {
  __typename?: 'RootQueryToProjectTypeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ProjectType>
}

/** Arguments for filtering the RootQueryToProjectTypeConnection connection */
export type RootQueryToProjectTypeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the Provider type */
export type RootQueryToProviderConnection = {
  __typename?: 'RootQueryToProviderConnection'
  /** Edges for the RootQueryToProviderConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToProviderConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Provider>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToProviderConnectionEdge = {
  __typename?: 'RootQueryToProviderConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Provider>
}

/** Arguments for filtering the RootQueryToProviderConnection connection */
export type RootQueryToProviderConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the RootCause type */
export type RootQueryToRootCauseConnection = {
  __typename?: 'RootQueryToRootCauseConnection'
  /** Edges for the RootQueryToRootCauseConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToRootCauseConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<RootCause>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToRootCauseConnectionEdge = {
  __typename?: 'RootQueryToRootCauseConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<RootCause>
}

/** Arguments for filtering the RootQueryToRootCauseConnection connection */
export type RootQueryToRootCauseConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the Severity type */
export type RootQueryToSeverityConnection = {
  __typename?: 'RootQueryToSeverityConnection'
  /** Edges for the RootQueryToSeverityConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToSeverityConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Severity>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToSeverityConnectionEdge = {
  __typename?: 'RootQueryToSeverityConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Severity>
}

/** Arguments for filtering the RootQueryToSeverityConnection connection */
export type RootQueryToSeverityConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the tag type */
export type RootQueryToTagConnection = {
  __typename?: 'RootQueryToTagConnection'
  /** Edges for the RootQueryToTagConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToTagConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Tag>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToTagConnectionEdge = {
  __typename?: 'RootQueryToTagConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Tag>
}

/** Arguments for filtering the RootQueryToTagConnection connection */
export type RootQueryToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter by Tags by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>
  /** Filter Tags by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the Taxonomy type */
export type RootQueryToTaxonomyConnection = {
  __typename?: 'RootQueryToTaxonomyConnection'
  /** Edges for the RootQueryToTaxonomyConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToTaxonomyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Taxonomy>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToTaxonomyConnectionEdge = {
  __typename?: 'RootQueryToTaxonomyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Taxonomy>
}

/** Connection between the RootQuery type and the TermNode type */
export type RootQueryToTermNodeConnection = {
  __typename?: 'RootQueryToTermNodeConnection'
  /** Edges for the RootQueryToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToTermNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToTermNodeConnectionEdge = {
  __typename?: 'RootQueryToTermNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TermNode>
}

/** Arguments for filtering the RootQueryToTermNodeConnection connection */
export type RootQueryToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the Theme type */
export type RootQueryToThemeConnection = {
  __typename?: 'RootQueryToThemeConnection'
  /** Edges for the RootQueryToThemeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToThemeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Theme>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToThemeConnectionEdge = {
  __typename?: 'RootQueryToThemeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Theme>
}

/** Connection between the RootQuery type and the ToolboxTalkCategory type */
export type RootQueryToToolboxTalkCategoryConnection = {
  __typename?: 'RootQueryToToolboxTalkCategoryConnection'
  /** Edges for the RootQueryToToolboxTalkCategoryConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToToolboxTalkCategoryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ToolboxTalkCategory>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToToolboxTalkCategoryConnectionEdge = {
  __typename?: 'RootQueryToToolboxTalkCategoryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ToolboxTalkCategory>
}

/** Arguments for filtering the RootQueryToToolboxTalkCategoryConnection connection */
export type RootQueryToToolboxTalkCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter by ToolboxTalkCategorys by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>
  /** Filter ToolboxTalkCategorys by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the ToolboxTalk type */
export type RootQueryToToolboxTalkConnection = {
  __typename?: 'RootQueryToToolboxTalkConnection'
  /** Edges for the RootQueryToToolboxTalkConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToToolboxTalkConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ToolboxTalk>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToToolboxTalkConnectionEdge = {
  __typename?: 'RootQueryToToolboxTalkConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ToolboxTalk>
}

/** Arguments for filtering the RootQueryToToolboxTalkConnection connection */
export type RootQueryToToolboxTalkConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter by ToolboxTalks by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>
  /** Filter ToolboxTalks by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>
  metaQuery?: InputMaybe<RootQueryToToolboxTalkConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<RootQueryToToolboxTalkConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryToToolboxTalkConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToToolboxTalkConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToToolboxTalkConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToToolboxTalkConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToToolboxTalkConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToToolboxTalkConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToToolboxTalkConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type RootQueryToToolboxTalkConnectionWhereArgsTaxArray = {
  field?: InputMaybe<RootQueryToToolboxTalkConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<RootQueryToToolboxTalkConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type RootQueryToToolboxTalkConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToToolboxTalkConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToToolboxTalkConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum RootQueryToToolboxTalkConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the RootQuery type and the TrainingType type */
export type RootQueryToTrainingTypeConnection = {
  __typename?: 'RootQueryToTrainingTypeConnection'
  /** Edges for the RootQueryToTrainingTypeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToTrainingTypeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TrainingType>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToTrainingTypeConnectionEdge = {
  __typename?: 'RootQueryToTrainingTypeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TrainingType>
}

/** Arguments for filtering the RootQueryToTrainingTypeConnection connection */
export type RootQueryToTrainingTypeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the UserCompany type */
export type RootQueryToUserCompanyConnection = {
  __typename?: 'RootQueryToUserCompanyConnection'
  /** Edges for the RootQueryToUserCompanyConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToUserCompanyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserCompany>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToUserCompanyConnectionEdge = {
  __typename?: 'RootQueryToUserCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserCompany>
}

/** Arguments for filtering the RootQueryToUserCompanyConnection connection */
export type RootQueryToUserCompanyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the User type */
export type RootQueryToUserConnection = {
  __typename?: 'RootQueryToUserConnection'
  /** Edges for the RootQueryToUserConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToUserConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<User>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToUserConnectionEdge = {
  __typename?: 'RootQueryToUserConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<User>
}

/** Arguments for filtering the RootQueryToUserConnection connection */
export type RootQueryToUserConnectionWhereArgs = {
  /** User Company taxonomy Name values */
  companies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** User Division taxonomy Name values */
  divisions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of userIds to exclude. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** Pass an array of post types to filter results to users who have published posts in those post types. */
  hasPublishedPosts?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Array of userIds to include. */
  include?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** User language  taxonomy slug value */
  language?: InputMaybe<UserLanguageEnum>
  /** The user login. */
  login?: InputMaybe<Scalars['String']['input']>
  /** An array of logins to include. Users matching one of these logins will be included in results. */
  loginIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** An array of logins to exclude. Users matching one of these logins will not be included in results. */
  loginNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  metaQuery?: InputMaybe<RootQueryToUserConnectionWhereArgsMetaQuery>
  /** The user nicename. */
  nicename?: InputMaybe<Scalars['String']['input']>
  /** An array of nicenames to include. Users matching one of these nicenames will be included in results. */
  nicenameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** An array of nicenames to exclude. Users matching one of these nicenames will not be included in results. */
  nicenameNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<UsersConnectionOrderbyInput>>>
  /** Id */
  postToPostConnectionQuery?: InputMaybe<PostToPostConnectionQuery>
  /** An array of role names that users must match to be included in results. Note that this is an inclusive list: users must match *each* role. */
  role?: InputMaybe<UserRoleEnum>
  /** An array of role names. Matched users must have at least one of these roles. */
  roleIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>
  /** An array of role names to exclude. Users matching one or more of these roles will not be included in results. */
  roleNotIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>
  /** Search keyword. Searches for possible string matches on columns. When "searchColumns" is left empty, it tries to determine which column to search in based on search string. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of column names to be searched. Accepts 'ID', 'login', 'nicename', 'email', 'url'. */
  searchColumns?: InputMaybe<Array<InputMaybe<UsersConnectionSearchColumnEnum>>>
  /** Active / InActive taxonomy on the user object. */
  userStatus?: InputMaybe<UserStatusEnum>
  /** User Vaccine status taxonomy slug value *** ETS requirement *** */
  vaccineStatuses?: InputMaybe<Array<InputMaybe<UserVaccineStatusEnum>>>
}

export type RootQueryToUserConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<RootQueryToUserConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<RootQueryToUserConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum RootQueryToUserConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type RootQueryToUserConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<RootQueryToUserConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum RootQueryToUserConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

/** Connection between the RootQuery type and the UserDivision type */
export type RootQueryToUserDivisionConnection = {
  __typename?: 'RootQueryToUserDivisionConnection'
  /** Edges for the RootQueryToUserDivisionConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToUserDivisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserDivision>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToUserDivisionConnectionEdge = {
  __typename?: 'RootQueryToUserDivisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserDivision>
}

/** Arguments for filtering the RootQueryToUserDivisionConnection connection */
export type RootQueryToUserDivisionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the UserLanguage type */
export type RootQueryToUserLanguageConnection = {
  __typename?: 'RootQueryToUserLanguageConnection'
  /** Edges for the RootQueryToUserLanguageConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToUserLanguageConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserLanguage>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToUserLanguageConnectionEdge = {
  __typename?: 'RootQueryToUserLanguageConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserLanguage>
}

/** Arguments for filtering the RootQueryToUserLanguageConnection connection */
export type RootQueryToUserLanguageConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the UserRole type */
export type RootQueryToUserRoleConnection = {
  __typename?: 'RootQueryToUserRoleConnection'
  /** Edges for the RootQueryToUserRoleConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToUserRoleConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserRole>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToUserRoleConnectionEdge = {
  __typename?: 'RootQueryToUserRoleConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserRole>
}

/** Connection between the RootQuery type and the UserStatus type */
export type RootQueryToUserStatusConnection = {
  __typename?: 'RootQueryToUserStatusConnection'
  /** Edges for the RootQueryToUserStatusConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToUserStatusConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserStatus>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToUserStatusConnectionEdge = {
  __typename?: 'RootQueryToUserStatusConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserStatus>
}

/** Arguments for filtering the RootQueryToUserStatusConnection connection */
export type RootQueryToUserStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the RootQuery type and the UserVaccineStatus type */
export type RootQueryToUserVaccineStatusConnection = {
  __typename?: 'RootQueryToUserVaccineStatusConnection'
  /** Edges for the RootQueryToUserVaccineStatusConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToUserVaccineStatusConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserVaccineStatus>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type RootQueryToUserVaccineStatusConnectionEdge = {
  __typename?: 'RootQueryToUserVaccineStatusConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserVaccineStatus>
}

/** Arguments for filtering the RootQueryToUserVaccineStatusConnection connection */
export type RootQueryToUserVaccineStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** A Gravity Forms   field. */
export type SectionField = FormField & {
  __typename?: 'SectionField'
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** A Gravity Forms   field. */
export type SelectField = FormField & {
  __typename?: 'SelectField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The free solo boolean to allow custom option for selected fields */
  allowCustomOption?: Maybe<Scalars['Boolean']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Choice text translations */
  choiceTranslations?: Maybe<Array<Maybe<ChoiceTranslation>>>
  /** The available choices for the select field. */
  choices?: Maybe<Array<Maybe<SelectFieldChoice>>>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']['output']>
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** The label for the chosen value. */
  valueText?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Select choice values. */
export type SelectFieldChoice = {
  __typename?: 'SelectFieldChoice'
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']['output']>
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']['output']>
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']['output']>
}

/** Input for the sendPasswordResetEmail mutation */
export type SendPasswordResetEmailInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** A string that contains the user's username or email address. */
  username: Scalars['String']['input']
}

/** The payload for the sendPasswordResetEmail mutation */
export type SendPasswordResetEmailPayload = {
  __typename?: 'SendPasswordResetEmailPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** Whether the mutation completed successfully. This does NOT necessarily mean that an email was sent. */
  success?: Maybe<Scalars['Boolean']['output']>
  /**
   * The user that the password reset email was sent to
   * @deprecated This field will be removed in a future version of WPGraphQL
   */
  user?: Maybe<User>
}

/** All of the registered settings */
export type Settings = {
  __typename?: 'Settings'
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultCommentStatus?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultPingStatus?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsDateFormat?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsDescription?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsLanguage?: Maybe<Scalars['String']['output']>
  /** Settings of the the integer Settings Group */
  generalSettingsStartOfWeek?: Maybe<Scalars['Int']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsTimeFormat?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsTimezone?: Maybe<Scalars['String']['output']>
  /** Settings of the the string Settings Group */
  generalSettingsTitle?: Maybe<Scalars['String']['output']>
  /** Settings of the the integer Settings Group */
  readingSettingsPostsPerPage?: Maybe<Scalars['Int']['output']>
  /** Settings of the the integer Settings Group */
  writingSettingsDefaultCategory?: Maybe<Scalars['Int']['output']>
  /** Settings of the the string Settings Group */
  writingSettingsDefaultPostFormat?: Maybe<Scalars['String']['output']>
  /** Settings of the the boolean Settings Group */
  writingSettingsUseSmilies?: Maybe<Scalars['Boolean']['output']>
}

/** The Severity type */
export type Severity = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Severity'
    /** Connection between the Severity type and the ContentNode type */
    contentNodes?: Maybe<SeverityToContentNodeConnection>
    /** Connection between the Severity type and the CorrectiveAction type */
    correctiveActions?: Maybe<SeverityToCorrectiveActionConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    severityId?: Maybe<Scalars['Int']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the Severity type and the Taxonomy type */
    taxonomy?: Maybe<SeverityToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The Severity type */
export type SeverityContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SeverityToContentNodeConnectionWhereArgs>
}

/** The Severity type */
export type SeverityCorrectiveActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SeverityToCorrectiveActionConnectionWhereArgs>
}

/** The Severity type */
export type SeverityEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Severity type */
export type SeverityEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Severity */
export enum SeverityEnum {
  /** High severity */
  High = 'high',
  /** Low severity */
  Low = 'low',
  /** Medium severity */
  Medium = 'medium',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum SeverityIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the Severity type and the ContentNode type */
export type SeverityToContentNodeConnection = {
  __typename?: 'SeverityToContentNodeConnection'
  /** Edges for the SeverityToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<SeverityToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type SeverityToContentNodeConnectionEdge = {
  __typename?: 'SeverityToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the SeverityToContentNodeConnection connection */
export type SeverityToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfSeverityEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<SeverityToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<SeverityToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SeverityToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<SeverityToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<SeverityToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum SeverityToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type SeverityToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<SeverityToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum SeverityToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type SeverityToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<SeverityToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<SeverityToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type SeverityToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<SeverityToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum SeverityToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum SeverityToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Severity type and the CorrectiveAction type */
export type SeverityToCorrectiveActionConnection = {
  __typename?: 'SeverityToCorrectiveActionConnection'
  /** Edges for the SeverityToCorrectiveActionConnection connection */
  edges?: Maybe<Array<Maybe<SeverityToCorrectiveActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type SeverityToCorrectiveActionConnectionEdge = {
  __typename?: 'SeverityToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Arguments for filtering the SeverityToCorrectiveActionConnection connection */
export type SeverityToCorrectiveActionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<SeverityToCorrectiveActionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<SeverityToCorrectiveActionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SeverityToCorrectiveActionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<SeverityToCorrectiveActionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<SeverityToCorrectiveActionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum SeverityToCorrectiveActionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type SeverityToCorrectiveActionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<SeverityToCorrectiveActionConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum SeverityToCorrectiveActionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type SeverityToCorrectiveActionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<SeverityToCorrectiveActionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<SeverityToCorrectiveActionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type SeverityToCorrectiveActionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<SeverityToCorrectiveActionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum SeverityToCorrectiveActionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum SeverityToCorrectiveActionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Severity type and the Taxonomy type */
export type SeverityToTaxonomyConnectionEdge = {
  __typename?: 'SeverityToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** A Gravity Forms   field. */
export type SignatureField = FormField & {
  __typename?: 'SignatureField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Color to be used for the background of the signature area. Can be any valid CSS color value. */
  backgroundColor?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Color to be used for the border around the signature area. Can be any valid CSS color value. */
  borderColor?: Maybe<Scalars['String']['output']>
  /** Border style to be used around the signature area. */
  borderStyle?: Maybe<SignatureFieldBorderStyleEnum>
  /** Width of the border around the signature area. */
  borderWidth?: Maybe<SignatureFieldBorderWidthEnum>
  /** Width of the signature field in pixels. */
  boxWidth?: Maybe<Scalars['Int']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** Color of the pen to be used for the signature. Can be any valid CSS color value. */
  penColor?: Maybe<Scalars['String']['output']>
  /** Size of the pen cursor. */
  penSize?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Border style to be used around the signature area. */
export enum SignatureFieldBorderStyleEnum {
  /** A "dashed" border style. */
  Dashed = 'DASHED',
  /** A "dotted" border style. */
  Dotted = 'DOTTED',
  /** A "double" border style. */
  Double = 'DOUBLE',
  /** A "dashed" border style. */
  Groove = 'GROOVE',
  /** An "inset" border style. */
  Inset = 'INSET',
  /** An "outset" border style. */
  Outset = 'OUTSET',
  /** A "ridge" border style. */
  Ridge = 'RIDGE',
  /** A "solid" border style. */
  Solid = 'SOLID',
}

/** Width of the border around the signature area. */
export enum SignatureFieldBorderWidthEnum {
  /** A large border width. */
  Large = 'LARGE',
  /** A medium border width. */
  Medium = 'MEDIUM',
  /** No border width. */
  None = 'NONE',
  /** A small border width. */
  Small = 'SMALL',
}

/** The Confirmation object returned on submission. Null if the submission was not successful. */
export type SubmissionConfirmation = {
  __typename?: 'SubmissionConfirmation'
  /** Contains the confirmation message HTML to display. Only applicable when type is set to `MESSAGE`. */
  message?: Maybe<Scalars['String']['output']>
  /** Connection between the SubmissionConfirmation type and the Page type */
  page?: Maybe<SubmissionConfirmationToPageConnectionEdge>
  /** Contains the Id of the WordPress page that the browser will be redirected to. Only applicable when type is set to `PAGE`. */
  pageId?: Maybe<Scalars['Int']['output']>
  /** Contains the query string to be appended to the redirection url. Only applicable when type is set to `REDIRECT` or `PAGE` . */
  queryString?: Maybe<Scalars['String']['output']>
  /** Determines the type of confirmation to be used. */
  type?: Maybe<SubmissionConfirmationTypeEnum>
  /** The URL the submission should redirect to. Only applicable when type is set to `REDIRECT`. */
  url?: Maybe<Scalars['String']['output']>
}

/** Connection between the SubmissionConfirmation type and the Page type */
export type SubmissionConfirmationToPageConnectionEdge = {
  __typename?: 'SubmissionConfirmationToPageConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Page>
}

/** Type of confirmation returned by the submission. */
export enum SubmissionConfirmationTypeEnum {
  /** A confirmation "message". */
  Message = 'MESSAGE',
  /** A "redirect" to a given URL. */
  Redirect = 'REDIRECT',
}

/** Gravity Forms submission rules settings */
export type SubmissionRule = {
  __typename?: 'SubmissionRule'
  /** Entry count */
  entryCount?: Maybe<Scalars['Int']['output']>
  /** Frequency */
  frequency?: Maybe<Scalars['String']['output']>
  /** Role */
  role?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type SubmitField = FormField & {
  __typename?: 'SubmitField'
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Entry meta input fields for submitting Gravity Forms forms. */
export type SubmitFormMetaInput = {
  /** ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: InputMaybe<Scalars['Int']['input']>
  /** The UTC date the entry was created, in `Y-m-d H:i:s` format. */
  dateCreatedGmt?: InputMaybe<Scalars['String']['input']>
  /** Client IP of user who submitted the form. */
  ip?: InputMaybe<Scalars['String']['input']>
  /** Used to overwrite the sourceUrl the form was submitted from. */
  sourceUrl?: InputMaybe<Scalars['String']['input']>
  /** The name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: InputMaybe<Scalars['String']['input']>
}

/** Input for the submitGfDraftEntry mutation */
export type SubmitGfDraftEntryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Either the global ID of the draft entry, or its resume token. */
  id: Scalars['ID']['input']
  /** The ID type for the draft entry. Defaults to `ID` . */
  idType?: InputMaybe<DraftEntryIdTypeEnum>
}

/** The payload for the submitGfDraftEntry mutation */
export type SubmitGfDraftEntryPayload = {
  __typename?: 'SubmitGfDraftEntryPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The form confirmation data. Null if the submission has `errors` */
  confirmation?: Maybe<SubmissionConfirmation>
  /** The entry that was created. */
  entry?: Maybe<GfSubmittedEntry>
  /** Field errors. */
  errors?: Maybe<Array<Maybe<FieldError>>>
}

/** Input for the submitGfForm mutation */
export type SubmitGfFormInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The entry meta associated with the submission. */
  entryMeta?: InputMaybe<SubmitFormMetaInput>
  /** The field ids and their values. */
  fieldValues: Array<InputMaybe<FormFieldValuesInput>>
  /** The form ID. Accepts either a global or Database ID. */
  id: Scalars['ID']['input']
  /** Set to `true` if submitting a draft entry. Defaults to `false`. */
  saveAsDraft?: InputMaybe<Scalars['Boolean']['input']>
  /** Useful for multi-page forms to indicate which page of the form was just submitted. */
  sourcePage?: InputMaybe<Scalars['Int']['input']>
  /** Useful for multi-page forms to indicate which page is to be loaded if the current page passes validation. */
  targetPage?: InputMaybe<Scalars['Int']['input']>
}

/** The payload for the submitGfForm mutation */
export type SubmitGfFormPayload = {
  __typename?: 'SubmitGfFormPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The form confirmation data. Null if the submission has `errors` */
  confirmation?: Maybe<SubmissionConfirmation>
  /** The entry that was created. */
  entry?: Maybe<GfEntry>
  /** Field errors. */
  errors?: Maybe<Array<Maybe<FieldError>>>
  /** Draft resume URL. Null if submitting an entry. If the &quot;Referer&quot; header is not included in the request, this will be an empty string. */
  resumeUrl?: Maybe<Scalars['String']['output']>
}

/** The Type of Identifier used to fetch a single resource. */
export enum SubmittedEntryIdTypeEnum {
  /** The database ID assigned by Gravity Forms. Used by submitted entries. */
  DatabaseId = 'DATABASE_ID',
  /** Unique global ID for the object. */
  Id = 'ID',
}

/** The tag type */
export type Tag = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Tag'
    /** Connection between the Tag type and the ContentNode type */
    contentNodes?: Maybe<TagToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** List available translations for this post */
    language?: Maybe<Language>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** Connection between the Tag type and the post type */
    posts?: Maybe<TagToPostConnection>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    tagId?: Maybe<Scalars['Int']['output']>
    /** Connection between the Tag type and the Taxonomy type */
    taxonomy?: Maybe<TagToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** Get specific translation version of this object */
    translation?: Maybe<Tag>
    /** List all translated versions of this term */
    translations?: Maybe<Array<Maybe<Tag>>>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The tag type */
export type TagContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TagToContentNodeConnectionWhereArgs>
}

/** The tag type */
export type TagEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The tag type */
export type TagEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The tag type */
export type TagPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TagToPostConnectionWhereArgs>
}

/** The tag type */
export type TagTranslationArgs = {
  language: LanguageCodeEnum
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TagIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the Tag type and the ContentNode type */
export type TagToContentNodeConnection = {
  __typename?: 'TagToContentNodeConnection'
  /** Edges for the TagToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<TagToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type TagToContentNodeConnectionEdge = {
  __typename?: 'TagToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the TagToContentNodeConnection connection */
export type TagToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfTagEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<TagToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<TagToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TagToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<TagToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<TagToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum TagToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type TagToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<TagToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum TagToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type TagToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<TagToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<TagToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type TagToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<TagToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum TagToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum TagToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Tag type and the post type */
export type TagToPostConnection = {
  __typename?: 'TagToPostConnection'
  /** Edges for the TagToPostConnection connection */
  edges?: Maybe<Array<Maybe<TagToPostConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type TagToPostConnectionEdge = {
  __typename?: 'TagToPostConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Post>
}

/** Arguments for filtering the TagToPostConnection connection */
export type TagToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<TagToPostConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  taxQuery?: InputMaybe<TagToPostConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TagToPostConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<TagToPostConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<TagToPostConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum TagToPostConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type TagToPostConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<TagToPostConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum TagToPostConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type TagToPostConnectionWhereArgsTaxArray = {
  field?: InputMaybe<TagToPostConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<TagToPostConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type TagToPostConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<Array<InputMaybe<TagToPostConnectionWhereArgsTaxArray>>>
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum TagToPostConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum TagToPostConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the Tag type and the Taxonomy type */
export type TagToTaxonomyConnectionEdge = {
  __typename?: 'TagToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** A taxonomy object */
export type Taxonomy = Node & {
  __typename?: 'Taxonomy'
  /** The url path of the first page of the archive page for this content type. */
  archivePath?: Maybe<Scalars['String']['output']>
  /** List of Content Types associated with the Taxonomy */
  connectedContentTypes?: Maybe<TaxonomyToContentTypeConnection>
  /** Description of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;description */
  description?: Maybe<Scalars['String']['output']>
  /** The plural name of the post type within the GraphQL Schema. */
  graphqlPluralName?: Maybe<Scalars['String']['output']>
  /** The singular name of the post type within the GraphQL Schema. */
  graphqlSingleName?: Maybe<Scalars['String']['output']>
  /** Whether the taxonomy is hierarchical */
  hierarchical?: Maybe<Scalars['Boolean']['output']>
  /** The globally unique identifier of the taxonomy object. */
  id: Scalars['ID']['output']
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>
  /** Name of the taxonomy shown in the menu. Usually plural. */
  label?: Maybe<Scalars['String']['output']>
  /** The display name of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;label */
  name?: Maybe<Scalars['String']['output']>
  /** Whether the taxonomy is publicly queryable */
  public?: Maybe<Scalars['Boolean']['output']>
  /** Name of content type to diplay in REST API &quot;wp/v2&quot; namespace. */
  restBase?: Maybe<Scalars['String']['output']>
  /** The REST Controller class assigned to handling this content type. */
  restControllerClass?: Maybe<Scalars['String']['output']>
  /** Whether to show the taxonomy as part of a tag cloud widget. This field is equivalent to WP_Taxonomy-&gt;show_tagcloud */
  showCloud?: Maybe<Scalars['Boolean']['output']>
  /** Whether to display a column for the taxonomy on its post type listing screens. */
  showInAdminColumn?: Maybe<Scalars['Boolean']['output']>
  /** Whether to add the post type to the GraphQL Schema. */
  showInGraphql?: Maybe<Scalars['Boolean']['output']>
  /** Whether to show the taxonomy in the admin menu */
  showInMenu?: Maybe<Scalars['Boolean']['output']>
  /** Whether the taxonomy is available for selection in navigation menus. */
  showInNavMenus?: Maybe<Scalars['Boolean']['output']>
  /** Whether to show the taxonomy in the quick/bulk edit panel. */
  showInQuickEdit?: Maybe<Scalars['Boolean']['output']>
  /** Whether to add the post type route in the REST API &quot;wp/v2&quot; namespace. */
  showInRest?: Maybe<Scalars['Boolean']['output']>
  /** Whether to generate and allow a UI for managing terms in this taxonomy in the admin */
  showUi?: Maybe<Scalars['Boolean']['output']>
}

/** A taxonomy object */
export type TaxonomyConnectedContentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Allowed taxonomies */
export enum TaxonomyEnum {
  /** Taxonomy enum category */
  Category = 'CATEGORY',
  /** Taxonomy enum company */
  Company = 'COMPANY',
  /** Taxonomy enum corrective_action_status */
  Correctiveactionstatus = 'CORRECTIVEACTIONSTATUS',
  /** Taxonomy enum deficiency_type */
  Deficiencytype = 'DEFICIENCYTYPE',
  /** Taxonomy enum division */
  Division = 'DIVISION',
  /** Taxonomy enum document_tag */
  Documenttag = 'DOCUMENTTAG',
  /** Taxonomy enum entry_id */
  Entryid = 'ENTRYID',
  /** Taxonomy enum equipment_status */
  Equipmentstatus = 'EQUIPMENTSTATUS',
  /** Taxonomy enum equipment_tag */
  Equipmenttag = 'EQUIPMENTTAG',
  /** Taxonomy enum field_id */
  Fieldid = 'FIELDID',
  /** Taxonomy enum form_id */
  Formid = 'FORMID',
  /** Taxonomy enum ir_classification */
  Incidentreportclassification = 'INCIDENTREPORTCLASSIFICATION',
  /** Taxonomy enum ir_status */
  Incidentreportstatus = 'INCIDENTREPORTSTATUS',
  /** Taxonomy enum ir_type */
  Incidenttype = 'INCIDENTTYPE',
  /** Taxonomy enum ir_injury_area */
  Injuryarea = 'INJURYAREA',
  /** Taxonomy enum inspection_interval */
  Inspectioninterval = 'INSPECTIONINTERVAL',
  /** Taxonomy enum lesson_categories */
  Lessoncategory = 'LESSONCATEGORY',
  /** Taxonomy enum lesson_difficulty */
  Lessondifficulty = 'LESSONDIFFICULTY',
  /** Taxonomy enum manufacturer */
  Manufacturer = 'MANUFACTURER',
  /** Taxonomy enum post_format */
  Postformat = 'POSTFORMAT',
  /** Taxonomy enum project_status */
  Projectstatus = 'PROJECTSTATUS',
  /** Taxonomy enum project_type */
  Projecttype = 'PROJECTTYPE',
  /** Taxonomy enum provider_tax */
  Provider = 'PROVIDER',
  /** Taxonomy enum ir_root_cause */
  Rootcause = 'ROOTCAUSE',
  /** Taxonomy enum severity */
  Severity = 'SEVERITY',
  /** Taxonomy enum post_tag */
  Tag = 'TAG',
  /** Taxonomy enum toolbox_talk_category */
  Toolboxtalkcategory = 'TOOLBOXTALKCATEGORY',
  /** Taxonomy enum training_type */
  Trainingtype = 'TRAININGTYPE',
  /** Taxonomy enum user_company */
  Usercompany = 'USERCOMPANY',
  /** Taxonomy enum user_division */
  Userdivision = 'USERDIVISION',
  /** Taxonomy enum userLanguage */
  Userlanguage = 'USERLANGUAGE',
  /** Taxonomy enum user_status */
  Userstatus = 'USERSTATUS',
  /** Taxonomy enum user_vaccine_status */
  Uservaccinestatus = 'USERVACCINESTATUS',
}

/** The Type of Identifier used to fetch a single Taxonomy node. To be used along with the "id" field. Default is "ID". */
export enum TaxonomyIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the taxonomy */
  Name = 'NAME',
}

/** Connection between the Taxonomy type and the ContentType type */
export type TaxonomyToContentTypeConnection = {
  __typename?: 'TaxonomyToContentTypeConnection'
  /** Edges for the TaxonomyToContentTypeConnection connection */
  edges?: Maybe<Array<Maybe<TaxonomyToContentTypeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentType>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type TaxonomyToContentTypeConnectionEdge = {
  __typename?: 'TaxonomyToContentTypeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentType>
}

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNode = {
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']['output']>
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int']['output']
  /** The description of the object */
  description?: Maybe<Scalars['String']['output']>
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
  /** The unique resource identifier path */
  id: Scalars['ID']['output']
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output']
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output']
  /** The link to the term */
  link?: Maybe<Scalars['String']['output']>
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']['output']>
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']['output']>
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']['output']>
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']['output']>
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']['output']>
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>
}

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is "ID". To be used along with the "id" field. */
export enum TermNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the TermNode type and the EnqueuedScript type */
export type TermNodeToEnqueuedScriptConnection = {
  __typename?: 'TermNodeToEnqueuedScriptConnection'
  /** Edges for the TermNodeToEnqueuedScriptConnection connection */
  edges?: Maybe<Array<Maybe<TermNodeToEnqueuedScriptConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type TermNodeToEnqueuedScriptConnectionEdge = {
  __typename?: 'TermNodeToEnqueuedScriptConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedScript>
}

/** Connection between the TermNode type and the EnqueuedStylesheet type */
export type TermNodeToEnqueuedStylesheetConnection = {
  __typename?: 'TermNodeToEnqueuedStylesheetConnection'
  /** Edges for the TermNodeToEnqueuedStylesheetConnection connection */
  edges?: Maybe<Array<Maybe<TermNodeToEnqueuedStylesheetConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type TermNodeToEnqueuedStylesheetConnectionEdge = {
  __typename?: 'TermNodeToEnqueuedStylesheetConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedStylesheet>
}

/** Options for ordering the connection by */
export enum TermObjectsConnectionOrderbyEnum {
  /** Order the connection by item count. */
  Count = 'COUNT',
  /** Order the connection by description. */
  Description = 'DESCRIPTION',
  /** Order the connection by name. */
  Name = 'NAME',
  /** Order the connection by slug. */
  Slug = 'SLUG',
  /** Order the connection by term group. */
  TermGroup = 'TERM_GROUP',
  /** Order the connection by term id. */
  TermId = 'TERM_ID',
  /** Order the connection by term order. */
  TermOrder = 'TERM_ORDER',
}

/** A Gravity Forms   field. */
export type TextAreaField = FormField & {
  __typename?: 'TextAreaField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Indicates whether the field uses the rich text editor interface. */
  hasRichTextEditor?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** A Gravity Forms   field. */
export type TextField = FormField & {
  __typename?: 'TextField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Whether the field has an input mask. */
  hasInputMask?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The pattern used for the input mask. */
  inputMaskValue?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if a text field input tag should be created with a &quot;password&quot; type. */
  isPasswordInput?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** A theme object */
export type Theme = Node & {
  __typename?: 'Theme'
  /** Name of the theme author(s), could also be a company name. This field is equivalent to WP_Theme-&gt;get( &quot;Author&quot; ). */
  author?: Maybe<Scalars['String']['output']>
  /** URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;AuthorURI&quot; ). */
  authorUri?: Maybe<Scalars['String']['output']>
  /** The description of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Description&quot; ). */
  description?: Maybe<Scalars['String']['output']>
  /** The globally unique identifier of the theme object. */
  id: Scalars['ID']['output']
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>
  /** Display name of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Name&quot; ). */
  name?: Maybe<Scalars['String']['output']>
  /** The URL of the screenshot for the theme. The screenshot is intended to give an overview of what the theme looks like. This field is equivalent to WP_Theme-&gt;get_screenshot(). */
  screenshot?: Maybe<Scalars['String']['output']>
  /** The theme slug is used to internally match themes. Theme slugs can have subdirectories like: my-theme/sub-theme. This field is equivalent to WP_Theme-&gt;get_stylesheet(). */
  slug?: Maybe<Scalars['String']['output']>
  /** URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;Tags&quot; ). */
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** A URI if the theme has a website associated with it. The Theme URI is handy for directing users to a theme site for support etc. This field is equivalent to WP_Theme-&gt;get( &quot;ThemeURI&quot; ). */
  themeUri?: Maybe<Scalars['String']['output']>
  /** The current version of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Version&quot; ). */
  version?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type TimeField = FormField & {
  __typename?: 'TimeField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** An array of the available properties for each input of the time field. */
  inputs?: Maybe<Array<Maybe<TimeInputProperty>>>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>
  /** Determines how the time is displayed. */
  timeFormat?: Maybe<TimeFieldFormatEnum>
  /** Time field value. */
  timeValues?: Maybe<TimeFieldValue>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** How the time is displayed. */
export enum TimeFieldFormatEnum {
  /** 12-hour time format. */
  H12 = 'H12',
  /** 24-hour time format. */
  H24 = 'H24',
}

/** The individual properties for each element of the Time value field. */
export type TimeFieldValue = {
  __typename?: 'TimeFieldValue'
  /** AM or PM. */
  amPm?: Maybe<AmPmEnum>
  /** The full display value in 12-hour format. Example: &quot;08:25 am&quot;. */
  displayValue?: Maybe<Scalars['String']['output']>
  /** The hours, in this format: hh. */
  hours?: Maybe<Scalars['String']['output']>
  /** The minutes, in this format: mm. */
  minutes?: Maybe<Scalars['String']['output']>
}

/** Time input values. */
export type TimeInputProperty = {
  __typename?: 'TimeInputProperty'
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
}

/** The ToolboxTalk type */
export type ToolboxTalk = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithContentEditor &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'ToolboxTalk'
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>
    /** Connection between the ToolboxTalk type and the Company type */
    companies?: Maybe<ToolboxTalkToCompanyConnection>
    /** The content of the post. */
    content?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output']
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>
    /** Connection between the ToolboxTalk type and the Division type */
    divisions?: Maybe<ToolboxTalkToDivisionConnection>
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier of the toolbox_talk object. */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Polylang language */
    language?: Maybe<Language>
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>
    /** Connection between the ToolboxTalk type and the ToolboxTalk type */
    preview?: Maybe<ToolboxTalkToPreviewConnectionEdge>
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>
    /** Connection between the ToolboxTalk type and the TermNode type */
    terms?: Maybe<ToolboxTalkToTermNodeConnection>
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>
    /** Connection between the ToolboxTalk type and the ToolboxTalkCategory type */
    toolboxTalkCategories?: Maybe<ToolboxTalkToToolboxTalkCategoryConnection>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    toolboxTalkId: Scalars['Int']['output']
    /** Get specific translation version of this object */
    translation?: Maybe<ToolboxTalk>
    /** List all translated versions of this post */
    translations?: Maybe<Array<Maybe<ToolboxTalk>>>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The ToolboxTalk type */
export type ToolboxTalkCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ToolboxTalkToCompanyConnectionWhereArgs>
}

/** The ToolboxTalk type */
export type ToolboxTalkContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The ToolboxTalk type */
export type ToolboxTalkDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ToolboxTalkToDivisionConnectionWhereArgs>
}

/** The ToolboxTalk type */
export type ToolboxTalkEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The ToolboxTalk type */
export type ToolboxTalkEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The ToolboxTalk type */
export type ToolboxTalkTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ToolboxTalkToTermNodeConnectionWhereArgs>
}

/** The ToolboxTalk type */
export type ToolboxTalkTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>
}

/** The ToolboxTalk type */
export type ToolboxTalkToolboxTalkCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ToolboxTalkToToolboxTalkCategoryConnectionWhereArgs>
}

/** The ToolboxTalk type */
export type ToolboxTalkTranslationArgs = {
  language: LanguageCodeEnum
}

/** The ToolboxTalkCategory type */
export type ToolboxTalkCategory = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'ToolboxTalkCategory'
    /** Connection between the ToolboxTalkCategory type and the ContentNode type */
    contentNodes?: Maybe<ToolboxTalkCategoryToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** List available translations for this post */
    language?: Maybe<Language>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the ToolboxTalkCategory type and the Taxonomy type */
    taxonomy?: Maybe<ToolboxTalkCategoryToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    toolboxTalkCategoryId?: Maybe<Scalars['Int']['output']>
    /** Connection between the ToolboxTalkCategory type and the ToolboxTalk type */
    toolboxTalks?: Maybe<ToolboxTalkCategoryToToolboxTalkConnection>
    /** Get specific translation version of this object */
    translation?: Maybe<ToolboxTalkCategory>
    /** List all translated versions of this term */
    translations?: Maybe<Array<Maybe<ToolboxTalkCategory>>>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The ToolboxTalkCategory type */
export type ToolboxTalkCategoryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ToolboxTalkCategoryToContentNodeConnectionWhereArgs>
}

/** The ToolboxTalkCategory type */
export type ToolboxTalkCategoryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The ToolboxTalkCategory type */
export type ToolboxTalkCategoryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The ToolboxTalkCategory type */
export type ToolboxTalkCategoryToolboxTalksArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ToolboxTalkCategoryToToolboxTalkConnectionWhereArgs>
}

/** The ToolboxTalkCategory type */
export type ToolboxTalkCategoryTranslationArgs = {
  language: LanguageCodeEnum
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ToolboxTalkCategoryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the ToolboxTalkCategory type and the ContentNode type */
export type ToolboxTalkCategoryToContentNodeConnection = {
  __typename?: 'ToolboxTalkCategoryToContentNodeConnection'
  /** Edges for the ToolboxTalkCategoryToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<ToolboxTalkCategoryToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ToolboxTalkCategoryToContentNodeConnectionEdge = {
  __typename?: 'ToolboxTalkCategoryToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the ToolboxTalkCategoryToContentNodeConnection connection */
export type ToolboxTalkCategoryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfToolboxTalkCategoryEnum>>
  >
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ToolboxTalkCategoryToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ToolboxTalkCategoryToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ToolboxTalkCategoryToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ToolboxTalkCategoryToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ToolboxTalkCategoryToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ToolboxTalkCategoryToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ToolboxTalkCategoryToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<
      InputMaybe<ToolboxTalkCategoryToContentNodeConnectionWhereArgsMetaArray>
    >
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ToolboxTalkCategoryToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ToolboxTalkCategoryToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ToolboxTalkCategoryToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ToolboxTalkCategoryToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ToolboxTalkCategoryToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<
      InputMaybe<ToolboxTalkCategoryToContentNodeConnectionWhereArgsTaxArray>
    >
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ToolboxTalkCategoryToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ToolboxTalkCategoryToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the ToolboxTalkCategory type and the Taxonomy type */
export type ToolboxTalkCategoryToTaxonomyConnectionEdge = {
  __typename?: 'ToolboxTalkCategoryToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Connection between the ToolboxTalkCategory type and the ToolboxTalk type */
export type ToolboxTalkCategoryToToolboxTalkConnection = {
  __typename?: 'ToolboxTalkCategoryToToolboxTalkConnection'
  /** Edges for the ToolboxTalkCategoryToToolboxTalkConnection connection */
  edges?: Maybe<Array<Maybe<ToolboxTalkCategoryToToolboxTalkConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ToolboxTalk>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ToolboxTalkCategoryToToolboxTalkConnectionEdge = {
  __typename?: 'ToolboxTalkCategoryToToolboxTalkConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ToolboxTalk>
}

/** Arguments for filtering the ToolboxTalkCategoryToToolboxTalkConnection connection */
export type ToolboxTalkCategoryToToolboxTalkConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<
      InputMaybe<ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsMetaArray>
    >
  >
  relation?: InputMaybe<RelationEnum>
}

export enum ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsTaxArray = {
  field?: InputMaybe<ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<
      InputMaybe<ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsTaxArray>
    >
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum ToolboxTalkCategoryToToolboxTalkConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Set relationships between the ToolboxTalk to Companies */
export type ToolboxTalkCompaniesInput = {
  /** If true, this will append the Company to existing related Companies. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ToolboxTalkCompaniesNodeInput>>>
}

/** List of Companies to connect the ToolboxTalk to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ToolboxTalkCompaniesNodeInput = {
  /** The description of the Company. This field is used to set a description of the Company if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Company. If present, this will be used to connect to the ToolboxTalk. If no existing Company exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Company. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Company. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Set relationships between the ToolboxTalk to Divisions */
export type ToolboxTalkDivisionsInput = {
  /** If true, this will append the Division to existing related Divisions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ToolboxTalkDivisionsNodeInput>>>
}

/** List of Divisions to connect the ToolboxTalk to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ToolboxTalkDivisionsNodeInput = {
  /** The description of the Division. This field is used to set a description of the Division if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Division. If present, this will be used to connect to the ToolboxTalk. If no existing Division exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the Division. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the Division. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ToolboxTalkIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Connection between the ToolboxTalk type and the Company type */
export type ToolboxTalkToCompanyConnection = {
  __typename?: 'ToolboxTalkToCompanyConnection'
  /** Edges for the ToolboxTalkToCompanyConnection connection */
  edges?: Maybe<Array<Maybe<ToolboxTalkToCompanyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Company>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ToolboxTalkToCompanyConnectionEdge = {
  __typename?: 'ToolboxTalkToCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Company>
}

/** Arguments for filtering the ToolboxTalkToCompanyConnection connection */
export type ToolboxTalkToCompanyConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the ToolboxTalk type and the Division type */
export type ToolboxTalkToDivisionConnection = {
  __typename?: 'ToolboxTalkToDivisionConnection'
  /** Edges for the ToolboxTalkToDivisionConnection connection */
  edges?: Maybe<Array<Maybe<ToolboxTalkToDivisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Division>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ToolboxTalkToDivisionConnectionEdge = {
  __typename?: 'ToolboxTalkToDivisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Division>
}

/** Arguments for filtering the ToolboxTalkToDivisionConnection connection */
export type ToolboxTalkToDivisionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the ToolboxTalk type and the ToolboxTalk type */
export type ToolboxTalkToPreviewConnectionEdge = {
  __typename?: 'ToolboxTalkToPreviewConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<ToolboxTalk>
}

/** Connection between the ToolboxTalk type and the TermNode type */
export type ToolboxTalkToTermNodeConnection = {
  __typename?: 'ToolboxTalkToTermNodeConnection'
  /** Edges for the ToolboxTalkToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<ToolboxTalkToTermNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ToolboxTalkToTermNodeConnectionEdge = {
  __typename?: 'ToolboxTalkToTermNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<TermNode>
}

/** Arguments for filtering the ToolboxTalkToTermNodeConnection connection */
export type ToolboxTalkToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Connection between the ToolboxTalk type and the ToolboxTalkCategory type */
export type ToolboxTalkToToolboxTalkCategoryConnection = {
  __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
  /** Edges for the ToolboxTalkToToolboxTalkCategoryConnection connection */
  edges?: Maybe<Array<Maybe<ToolboxTalkToToolboxTalkCategoryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ToolboxTalkCategory>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type ToolboxTalkToToolboxTalkCategoryConnectionEdge = {
  __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ToolboxTalkCategory>
}

/** Arguments for filtering the ToolboxTalkToToolboxTalkCategoryConnection connection */
export type ToolboxTalkToToolboxTalkCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>
}

/** Set relationships between the ToolboxTalk to ToolboxTalkCategories */
export type ToolboxTalkToolboxTalkCategoriesInput = {
  /** If true, this will append the ToolboxTalkCategory to existing related ToolboxTalkCategories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>
  /** The input list of items to set. */
  nodes?: InputMaybe<
    Array<InputMaybe<ToolboxTalkToolboxTalkCategoriesNodeInput>>
  >
}

/** List of ToolboxTalkCategories to connect the ToolboxTalk to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ToolboxTalkToolboxTalkCategoriesNodeInput = {
  /** The description of the ToolboxTalkCategory. This field is used to set a description of the ToolboxTalkCategory if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the ToolboxTalkCategory. If present, this will be used to connect to the ToolboxTalk. If no existing ToolboxTalkCategory exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** The name of the ToolboxTalkCategory. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The slug of the ToolboxTalkCategory. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The TrainingType type */
export type TrainingType = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'TrainingType'
    /** Connection between the TrainingType type and the Certification type */
    certifications?: Maybe<TrainingTypeToCertificationConnection>
    /** Connection between the TrainingType type and the ContentNode type */
    contentNodes?: Maybe<TrainingTypeToContentNodeConnection>
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** Connection between the TrainingType type and the Lesson type */
    lessons?: Maybe<TrainingTypeToLessonConnection>
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the TrainingType type and the Taxonomy type */
    taxonomy?: Maybe<TrainingTypeToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    trainingTypeId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
  }

/** The TrainingType type */
export type TrainingTypeCertificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TrainingTypeToCertificationConnectionWhereArgs>
}

/** The TrainingType type */
export type TrainingTypeContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TrainingTypeToContentNodeConnectionWhereArgs>
}

/** The TrainingType type */
export type TrainingTypeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The TrainingType type */
export type TrainingTypeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The TrainingType type */
export type TrainingTypeLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TrainingTypeToLessonConnectionWhereArgs>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TrainingTypeIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the TrainingType type and the Certification type */
export type TrainingTypeToCertificationConnection = {
  __typename?: 'TrainingTypeToCertificationConnection'
  /** Edges for the TrainingTypeToCertificationConnection connection */
  edges?: Maybe<Array<Maybe<TrainingTypeToCertificationConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Certification>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type TrainingTypeToCertificationConnectionEdge = {
  __typename?: 'TrainingTypeToCertificationConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Certification>
}

/** Arguments for filtering the TrainingTypeToCertificationConnection connection */
export type TrainingTypeToCertificationConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<TrainingTypeToCertificationConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<TrainingTypeToCertificationConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TrainingTypeToCertificationConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<TrainingTypeToCertificationConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<TrainingTypeToCertificationConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum TrainingTypeToCertificationConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type TrainingTypeToCertificationConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<TrainingTypeToCertificationConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum TrainingTypeToCertificationConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type TrainingTypeToCertificationConnectionWhereArgsTaxArray = {
  field?: InputMaybe<TrainingTypeToCertificationConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<TrainingTypeToCertificationConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type TrainingTypeToCertificationConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<TrainingTypeToCertificationConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum TrainingTypeToCertificationConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum TrainingTypeToCertificationConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the TrainingType type and the ContentNode type */
export type TrainingTypeToContentNodeConnection = {
  __typename?: 'TrainingTypeToContentNodeConnection'
  /** Edges for the TrainingTypeToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<TrainingTypeToContentNodeConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type TrainingTypeToContentNodeConnectionEdge = {
  __typename?: 'TrainingTypeToContentNodeConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>
}

/** Arguments for filtering the TrainingTypeToContentNodeConnection connection */
export type TrainingTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfTrainingTypeEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<TrainingTypeToContentNodeConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<TrainingTypeToContentNodeConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TrainingTypeToContentNodeConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<TrainingTypeToContentNodeConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<TrainingTypeToContentNodeConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum TrainingTypeToContentNodeConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type TrainingTypeToContentNodeConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<TrainingTypeToContentNodeConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum TrainingTypeToContentNodeConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type TrainingTypeToContentNodeConnectionWhereArgsTaxArray = {
  field?: InputMaybe<TrainingTypeToContentNodeConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<TrainingTypeToContentNodeConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type TrainingTypeToContentNodeConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<TrainingTypeToContentNodeConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum TrainingTypeToContentNodeConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum TrainingTypeToContentNodeConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the TrainingType type and the Lesson type */
export type TrainingTypeToLessonConnection = {
  __typename?: 'TrainingTypeToLessonConnection'
  /** Edges for the TrainingTypeToLessonConnection connection */
  edges?: Maybe<Array<Maybe<TrainingTypeToLessonConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Lesson>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type TrainingTypeToLessonConnectionEdge = {
  __typename?: 'TrainingTypeToLessonConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Lesson>
}

/** Arguments for filtering the TrainingTypeToLessonConnection connection */
export type TrainingTypeToLessonConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<TrainingTypeToLessonConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<TrainingTypeToLessonConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TrainingTypeToLessonConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<TrainingTypeToLessonConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<TrainingTypeToLessonConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum TrainingTypeToLessonConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type TrainingTypeToLessonConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<TrainingTypeToLessonConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum TrainingTypeToLessonConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type TrainingTypeToLessonConnectionWhereArgsTaxArray = {
  field?: InputMaybe<TrainingTypeToLessonConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<TrainingTypeToLessonConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type TrainingTypeToLessonConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<TrainingTypeToLessonConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum TrainingTypeToLessonConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum TrainingTypeToLessonConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the TrainingType type and the Taxonomy type */
export type TrainingTypeToTaxonomyConnectionEdge = {
  __typename?: 'TrainingTypeToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Any node that has a URI */
export type UniformResourceIdentifiable = {
  /** The unique resource identifier path */
  id: Scalars['ID']['output']
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output']
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output']
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>
}

/** Input for the updateActionMonitorAction mutation */
export type UpdateActionMonitorActionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** The ID of the ActionMonitorAction object */
  id: Scalars['ID']['input']
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the updateActionMonitorAction mutation */
export type UpdateActionMonitorActionPayload = {
  __typename?: 'UpdateActionMonitorActionPayload'
  /** The Post object mutation type. */
  actionMonitorAction?: Maybe<ActionMonitorAction>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
}

/** Input for the UpdateCategory mutation */
export type UpdateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the category object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the category object to update */
  id: Scalars['ID']['input']
  language?: InputMaybe<LanguageCodeEnum>
  /** The name of the category object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateCategory mutation */
export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload'
  /** The created category */
  category?: Maybe<Category>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
}

/** Input for the updateCertificationExpiryWarning mutation */
export type UpdateCertificationExpiryWarningInput = {
  /** The number of days before the expiry date to send the warning. */
  alertWindow?: InputMaybe<Scalars['Int']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The slugs of the companies to include in the expiry warning. */
  companies?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The external contacts to notify. */
  externalContacts?: InputMaybe<Array<InputMaybe<ExternalContactInput>>>
  /** The database ID of the certification expiry warning rule to update. */
  id: Scalars['ID']['input']
  /** The database IDs of the Users to contact. */
  internalContacts?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

/** The payload for the updateCertificationExpiryWarning mutation */
export type UpdateCertificationExpiryWarningPayload = {
  __typename?: 'UpdateCertificationExpiryWarningPayload'
  /** The created certification expiry warning rule. */
  certificationExpiryWarningRule?: Maybe<CertificationExpiryWarningRule>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
}

/** Input for the updateCertification mutation */
export type UpdateCertificationInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Completion date in yyyy-mm-dd format. */
  completedDate: Scalars['String']['input']
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Expiration date in yyyy-mm-dd format. */
  expirationDate?: InputMaybe<Scalars['String']['input']>
  /** Wallet Card photos or other attachments to the certification. */
  fileAttachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
  /** The ID of the Certification object */
  id: Scalars['ID']['input']
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<
    Array<InputMaybe<PostToPostConnectionsMutate>>
  >
  /** Set connections between the Certification and Providers */
  providers?: InputMaybe<CertificationProvidersInput>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Certification and TrainingTypes */
  trainingTypes?: InputMaybe<CertificationTrainingTypesInput>
}

/** The payload for the updateCertification mutation */
export type UpdateCertificationPayload = {
  __typename?: 'UpdateCertificationPayload'
  /** The Post object mutation type. */
  certification?: Maybe<Certification>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
}

/** Input for the updateComment mutation */
export type UpdateCommentInput = {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars['String']['input']>
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars['String']['input']>
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars['String']['input']>
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars['Int']['input']>
  /** Content of the comment. */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** The ID of the comment being updated. */
  id: Scalars['ID']['input']
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Type of comment. */
  type?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the updateComment mutation */
export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The comment that was created */
  comment?: Maybe<Comment>
  /** Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache */
  success?: Maybe<Scalars['Boolean']['output']>
}

/** Input for the UpdateCompany mutation */
export type UpdateCompanyInput = {
  /** The slug that the company will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the company object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Company object to update */
  id: Scalars['ID']['input']
  /** The name of the company object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateCompany mutation */
export type UpdateCompanyPayload = {
  __typename?: 'UpdateCompanyPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created company */
  company?: Maybe<Company>
}

/** Input for the updateCorrectiveAction mutation */
export type UpdateCorrectiveActionInput = {
  /** Corrective action taken. Which is the deficiency the worker needs to fix. */
  actionTaken?: InputMaybe<Scalars['String']['input']>
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the CorrectiveAction and Companies */
  companies?: InputMaybe<CorrectiveActionCompaniesInput>
  /** DO NOT USE. Need to have so the CS status Enum gets to graphql */
  correctiveActionStatus?: InputMaybe<CorrectiveActionStatusEnum>
  /** Set connections between the CorrectiveAction and CorrectiveActionStatuses */
  correctiveActionStatuses?: InputMaybe<CorrectiveActionCorrectiveActionStatusesInput>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Date completed in yyyy-mm-dd format. */
  dateCompleted?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the CorrectiveAction and DeficiencyTypes */
  deficiencyTypes?: InputMaybe<CorrectiveActionDeficiencyTypesInput>
  /** Set connections between the CorrectiveAction and Divisions */
  divisions?: InputMaybe<CorrectiveActionDivisionsInput>
  /** Due date in yyyy-mm-dd format. */
  dueDate?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the CorrectiveAction and EntryIds */
  entryIds?: InputMaybe<CorrectiveActionEntryIdsInput>
  /** Set connections between the CorrectiveAction and FieldIds */
  fieldIds?: InputMaybe<CorrectiveActionFieldIdsInput>
  /** Set connections between the CorrectiveAction and FormIds */
  formIds?: InputMaybe<CorrectiveActionFormIdsInput>
  /** The ID of the CorrectiveAction object */
  id: Scalars['ID']['input']
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<
    Array<InputMaybe<PostToPostConnectionsMutate>>
  >
  /** Set connections between the CorrectiveAction and Severities */
  severities?: InputMaybe<CorrectiveActionSeveritiesInput>
  /** DO NOT USE. Need to have so the Enum gets to graphql */
  severity?: InputMaybe<SeverityEnum>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the updateCorrectiveAction mutation */
export type UpdateCorrectiveActionPayload = {
  __typename?: 'UpdateCorrectiveActionPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  correctiveAction?: Maybe<CorrectiveAction>
}

/** Input for the UpdateCorrectiveActionStatus mutation */
export type UpdateCorrectiveActionStatusInput = {
  /** The slug that the corrective_action_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the corrective_action_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the CorrectiveActionStatus object to update */
  id: Scalars['ID']['input']
  /** The name of the corrective_action_status object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateCorrectiveActionStatus mutation */
export type UpdateCorrectiveActionStatusPayload = {
  __typename?: 'UpdateCorrectiveActionStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created corrective_action_status */
  correctiveActionStatus?: Maybe<CorrectiveActionStatus>
}

/** Input for the UpdateDeficiencyType mutation */
export type UpdateDeficiencyTypeInput = {
  /** The slug that the deficiency_type will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the deficiency_type object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the DeficiencyType object to update */
  id: Scalars['ID']['input']
  /** The name of the deficiency_type object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateDeficiencyType mutation */
export type UpdateDeficiencyTypePayload = {
  __typename?: 'UpdateDeficiencyTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created deficiency_type */
  deficiencyType?: Maybe<DeficiencyType>
}

/** Input for the UpdateDivision mutation */
export type UpdateDivisionInput = {
  /** The slug that the division will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the division object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Division object to update */
  id: Scalars['ID']['input']
  /** The name of the division object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateDivision mutation */
export type UpdateDivisionPayload = {
  __typename?: 'UpdateDivisionPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created division */
  division?: Maybe<Division>
}

/** Input for the updateDocument mutation */
export type UpdateDocumentInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Document and Companies */
  companies?: InputMaybe<DocumentCompaniesInput>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Document and Divisions */
  divisions?: InputMaybe<DocumentDivisionsInput>
  /** Set connections between the Document and DocumentTags */
  documentTags?: InputMaybe<DocumentDocumentTagsInput>
  /** Equipment CPT post_ids */
  equipmentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** The file upload of the new Document */
  fileAttachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
  /** The ID of the Document object */
  id: Scalars['ID']['input']
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<
    Array<InputMaybe<PostToPostConnectionsMutate>>
  >
  /** Project CPT post_ids */
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the updateDocument mutation */
export type UpdateDocumentPayload = {
  __typename?: 'UpdateDocumentPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  document?: Maybe<Document>
}

/** Input for the UpdateDocumentTag mutation */
export type UpdateDocumentTagInput = {
  /** The slug that the document_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the document_tag object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the DocumentTag object to update */
  id: Scalars['ID']['input']
  /** The name of the document_tag object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateDocumentTag mutation */
export type UpdateDocumentTagPayload = {
  __typename?: 'UpdateDocumentTagPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created document_tag */
  documentTag?: Maybe<DocumentTag>
}

/** Entry meta input fields for updating draft Gravity Forms entries. */
export type UpdateDraftEntryMetaInput = {
  /** ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: InputMaybe<Scalars['Int']['input']>
  /** The UTC date the entry was created, in `Y-m-d H:i:s` format. */
  dateCreatedGmt?: InputMaybe<Scalars['String']['input']>
  /** Client IP of user who submitted the form. */
  ip?: InputMaybe<Scalars['String']['input']>
  /** Used to overwrite the sourceUrl the form was submitted from. */
  sourceUrl?: InputMaybe<Scalars['String']['input']>
  /** The name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: InputMaybe<Scalars['String']['input']>
}

/** Input for the UpdateEntryId mutation */
export type UpdateEntryIdInput = {
  /** The slug that the entry_id will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the entry_id object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the EntryId object to update */
  id: Scalars['ID']['input']
  /** The name of the entry_id object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateEntryId mutation */
export type UpdateEntryIdPayload = {
  __typename?: 'UpdateEntryIdPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created entry_id */
  entryId?: Maybe<EntryId>
}

/** Entry meta input fields for updating Gravity Forms entries. */
export type UpdateEntryMetaInput = {
  /** ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: InputMaybe<Scalars['Int']['input']>
  /** The UTC date the entry was created, in `Y-m-d H:i:s` format. */
  dateCreatedGmt?: InputMaybe<Scalars['String']['input']>
  /** Client IP of user who submitted the form. */
  ip?: InputMaybe<Scalars['String']['input']>
  /** Whether the entry has been read. */
  isRead?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether the entry has been starred (i.e marked with a star). */
  isStarred?: InputMaybe<Scalars['Boolean']['input']>
  /** Used to overwrite the sourceUrl the form was submitted from. */
  sourceUrl?: InputMaybe<Scalars['String']['input']>
  /** The current status of the entry. */
  status?: InputMaybe<EntryStatusEnum>
  /** The name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: InputMaybe<Scalars['String']['input']>
}

/** Input for the updateEquipment mutation */
export type UpdateEquipmentInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Equipment and Companies */
  companies?: InputMaybe<EquipmentCompaniesInput>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Equipment and Divisions */
  divisions?: InputMaybe<EquipmentDivisionsInput>
  /** Assigned document databaseIds */
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** Assigned equipment (truck) databaseIds */
  equipmentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** Set connections between the Equipment and EquipmentStatuses */
  equipmentStatuses?: InputMaybe<EquipmentEquipmentStatusesInput>
  /** Set connections between the Equipment and EquipmentTags */
  equipmentTags?: InputMaybe<EquipmentEquipmentTagsInput>
  /** Any photos of the piece of equipment. */
  fileAttachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
  /** The ID of the Equipment object */
  id: Scalars['ID']['input']
  /** In service date in yyyy-mm-dd format. */
  inServiceDate?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Equipment and InspectionIntervals */
  inspectionIntervals?: InputMaybe<EquipmentInspectionIntervalsInput>
  /** Last inspection date in yyyy-mm-dd format. */
  lastInspectionDate?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Equipment and Manufacturers */
  manufacturers?: InputMaybe<EquipmentManufacturersInput>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** Equipment model number */
  modelNumber?: InputMaybe<Scalars['String']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<
    Array<InputMaybe<PostToPostConnectionsMutate>>
  >
  /** Assigned to project databaseId */
  projectId?: InputMaybe<Scalars['Int']['input']>
  /** Equipment serial number */
  serialNumber?: InputMaybe<Scalars['String']['input']>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** Assigned to worker databaseId */
  userId?: InputMaybe<Scalars['Int']['input']>
}

/** The payload for the updateEquipment mutation */
export type UpdateEquipmentPayload = {
  __typename?: 'UpdateEquipmentPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  equipment?: Maybe<Equipment>
}

/** Input for the UpdateEquipmentStatus mutation */
export type UpdateEquipmentStatusInput = {
  /** The slug that the equipment_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the equipment_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the EquipmentStatus object to update */
  id: Scalars['ID']['input']
  /** The name of the equipment_status object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateEquipmentStatus mutation */
export type UpdateEquipmentStatusPayload = {
  __typename?: 'UpdateEquipmentStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created equipment_status */
  equipmentStatus?: Maybe<EquipmentStatus>
}

/** Input for the UpdateEquipmentTag mutation */
export type UpdateEquipmentTagInput = {
  /** The slug that the equipment_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the equipment_tag object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the EquipmentTag object to update */
  id: Scalars['ID']['input']
  /** The name of the equipment_tag object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateEquipmentTag mutation */
export type UpdateEquipmentTagPayload = {
  __typename?: 'UpdateEquipmentTagPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created equipment_tag */
  equipmentTag?: Maybe<EquipmentTag>
}

/** Input for the UpdateFieldId mutation */
export type UpdateFieldIdInput = {
  /** The slug that the field_id will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the field_id object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the FieldId object to update */
  id: Scalars['ID']['input']
  /** The name of the field_id object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateFieldId mutation */
export type UpdateFieldIdPayload = {
  __typename?: 'UpdateFieldIdPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created field_id */
  fieldId?: Maybe<FieldId>
}

/** Input for the UpdateFormId mutation */
export type UpdateFormIdInput = {
  /** The slug that the form_id will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the form_id object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the FormId object to update */
  id: Scalars['ID']['input']
  /** The name of the form_id object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateFormId mutation */
export type UpdateFormIdPayload = {
  __typename?: 'UpdateFormIdPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created form_id */
  formId?: Maybe<FormId>
}

/** Input for the updateGfDraftEntry mutation */
export type UpdateGfDraftEntryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The entry meta values to update. */
  entryMeta?: InputMaybe<UpdateDraftEntryMetaInput>
  /** The field ids and their values. */
  fieldValues?: InputMaybe<Array<InputMaybe<FormFieldValuesInput>>>
  /** Either the global ID of the draft entry, or its resume token. */
  id: Scalars['ID']['input']
  /** The ID type for the draft entry. Defaults to `ID` . */
  idType?: InputMaybe<DraftEntryIdTypeEnum>
  /** Whether the field values should be validated on submission. Defaults to false. */
  shouldValidate?: InputMaybe<Scalars['Boolean']['input']>
}

/** The payload for the updateGfDraftEntry mutation */
export type UpdateGfDraftEntryPayload = {
  __typename?: 'UpdateGfDraftEntryPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The draft entry after the update mutation has been applied. If a validation error occurred, the draft entry will NOT have been updated with the invalid value provided. */
  draftEntry?: Maybe<GfDraftEntry>
  /** Field validation errors. */
  errors?: Maybe<Array<Maybe<FieldError>>>
  /** Draft resume URL. If the &quot;Referer&quot; header is not included in the request, this will be an empty string. */
  resumeUrl?: Maybe<Scalars['String']['output']>
}

/** Input for the updateGfEntry mutation */
export type UpdateGfEntryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The entry meta values to update. */
  entryMeta?: InputMaybe<UpdateEntryMetaInput>
  /** The field ids and their values to update. */
  fieldValues?: InputMaybe<Array<InputMaybe<FormFieldValuesInput>>>
  /** ID of the entry to update, either a global or database ID. */
  id: Scalars['ID']['input']
  /** Whether the field values should be validated on submission. Defaults to false. */
  shouldValidate?: InputMaybe<Scalars['Boolean']['input']>
}

/** The payload for the updateGfEntry mutation */
export type UpdateGfEntryPayload = {
  __typename?: 'UpdateGfEntryPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The entry that was created. */
  entry?: Maybe<GfSubmittedEntry>
  /** Field errors. */
  errors?: Maybe<Array<Maybe<FieldError>>>
}

/** Input for the UpdateIncidentReportClassification mutation */
export type UpdateIncidentReportClassificationInput = {
  /** The slug that the ir_classification will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the ir_classification object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the IncidentReportClassification object to update */
  id: Scalars['ID']['input']
  /** The name of the ir_classification object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateIncidentReportClassification mutation */
export type UpdateIncidentReportClassificationPayload = {
  __typename?: 'UpdateIncidentReportClassificationPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created ir_classification */
  incidentReportClassification?: Maybe<IncidentReportClassification>
}

/** Input for the updateIncidentReport mutation */
export type UpdateIncidentReportInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the IncidentReport and Companies */
  companies?: InputMaybe<IncidentReportCompaniesInput>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Days away from work */
  daysAwayWork?: InputMaybe<Scalars['Int']['input']>
  /** Days on Job Transfer / restriction */
  daysJobTransfer?: InputMaybe<Scalars['Int']['input']>
  /** (Initial Report) - Incident Report description */
  description?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the IncidentReport and Divisions */
  divisions?: InputMaybe<IncidentReportDivisionsInput>
  /** Employee Address */
  employeeAddress?: InputMaybe<Scalars['String']['input']>
  /** Date of Birth */
  employeeBirthDate?: InputMaybe<Scalars['String']['input']>
  /** Date of Death */
  employeeDeathDate?: InputMaybe<Scalars['String']['input']>
  /** Employee Gender */
  employeeGender?: InputMaybe<Scalars['String']['input']>
  /** Date Hired */
  employeeHireDate?: InputMaybe<Scalars['String']['input']>
  /** Worker involved job title */
  employeeJobTitle?: InputMaybe<Scalars['String']['input']>
  /** Time Employee began work on day of Incident */
  employeeStartTime?: InputMaybe<Scalars['String']['input']>
  /** Media library stored items attached to this CPT */
  fileAttachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
  /** Did the worker receive emergency treatment */
  hasEmergencyTreatment?: InputMaybe<Scalars['Boolean']['input']>
  /** Did the worker stay in the hospital overnight */
  hasHospitalOvernight?: InputMaybe<Scalars['Boolean']['input']>
  /** Description of the hazard */
  hazardDescription?: InputMaybe<Scalars['String']['input']>
  /** Address of the hospital */
  hospitalAddress?: InputMaybe<Scalars['String']['input']>
  /** Name of the hospital */
  hospitalName?: InputMaybe<Scalars['String']['input']>
  /** The ID of the IncidentReport object */
  id: Scalars['ID']['input']
  /** (Initial Report) - Date Incident happened */
  incidentDate?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the IncidentReport and IncidentReportClassifications */
  incidentReportClassifications?: InputMaybe<IncidentReportIncidentReportClassificationsInput>
  /** Set connections between the IncidentReport and IncidentReportStatuses */
  incidentReportStatuses?: InputMaybe<IncidentReportIncidentReportStatusesInput>
  /** Time of Incident */
  incidentTime?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the IncidentReport and IncidentTypes */
  incidentTypes?: InputMaybe<IncidentReportIncidentTypesInput>
  /** Set connections between the IncidentReport and InjuryAreas */
  injuryAreas?: InputMaybe<IncidentReportInjuryAreasInput>
  /** Cause of the injury */
  injuryCause?: InputMaybe<Scalars['String']['input']>
  /** Description of the injury */
  injuryDescription?: InputMaybe<Scalars['String']['input']>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Name of the physician */
  physicianName?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<
    Array<InputMaybe<PostToPostConnectionsMutate>>
  >
  /** Prior actions taken before the incident */
  priorActions?: InputMaybe<Scalars['String']['input']>
  /** Project Address or Non project location */
  projectAddress?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the IncidentReport and RootCauses */
  rootCauses?: InputMaybe<IncidentReportRootCausesInput>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** The witnesses to the incident */
  witnesses?: InputMaybe<Array<InputMaybe<IncidentReportWitnessInput>>>
}

/** The payload for the updateIncidentReport mutation */
export type UpdateIncidentReportPayload = {
  __typename?: 'UpdateIncidentReportPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  incidentReport?: Maybe<IncidentReport>
}

/** Input for the UpdateIncidentReportStatus mutation */
export type UpdateIncidentReportStatusInput = {
  /** The slug that the ir_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the ir_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the IncidentReportStatus object to update */
  id: Scalars['ID']['input']
  /** The name of the ir_status object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateIncidentReportStatus mutation */
export type UpdateIncidentReportStatusPayload = {
  __typename?: 'UpdateIncidentReportStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created ir_status */
  incidentReportStatus?: Maybe<IncidentReportStatus>
}

/** Input for the UpdateIncidentType mutation */
export type UpdateIncidentTypeInput = {
  /** The slug that the ir_type will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the ir_type object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the IncidentType object to update */
  id: Scalars['ID']['input']
  /** The name of the ir_type object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateIncidentType mutation */
export type UpdateIncidentTypePayload = {
  __typename?: 'UpdateIncidentTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created ir_type */
  incidentType?: Maybe<IncidentType>
}

/** Input for the UpdateInjuryArea mutation */
export type UpdateInjuryAreaInput = {
  /** The slug that the ir_injury_area will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the ir_injury_area object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the InjuryArea object to update */
  id: Scalars['ID']['input']
  /** The name of the ir_injury_area object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateInjuryArea mutation */
export type UpdateInjuryAreaPayload = {
  __typename?: 'UpdateInjuryAreaPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created ir_injury_area */
  injuryArea?: Maybe<InjuryArea>
}

/** Input for the UpdateInspectionInterval mutation */
export type UpdateInspectionIntervalInput = {
  /** The slug that the inspection_interval will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the inspection_interval object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the InspectionInterval object to update */
  id: Scalars['ID']['input']
  /** The name of the inspection_interval object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateInspectionInterval mutation */
export type UpdateInspectionIntervalPayload = {
  __typename?: 'UpdateInspectionIntervalPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created inspection_interval */
  inspectionInterval?: Maybe<InspectionInterval>
}

/** Input for the UpdateLessonCategory mutation */
export type UpdateLessonCategoryInput = {
  /** The slug that the lesson_categories will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the lesson_categories object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the lessonCategory object to update */
  id: Scalars['ID']['input']
  /** The name of the lesson_categories object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateLessonCategory mutation */
export type UpdateLessonCategoryPayload = {
  __typename?: 'UpdateLessonCategoryPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created lesson_categories */
  lessonCategory?: Maybe<LessonCategory>
}

/** Input for the UpdateLessonDifficulty mutation */
export type UpdateLessonDifficultyInput = {
  /** The slug that the lesson_difficulty will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the lesson_difficulty object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the lessonDifficulty object to update */
  id: Scalars['ID']['input']
  /** The name of the lesson_difficulty object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateLessonDifficulty mutation */
export type UpdateLessonDifficultyPayload = {
  __typename?: 'UpdateLessonDifficultyPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created lesson_difficulty */
  lessonDifficulty?: Maybe<LessonDifficulty>
}

/** Input for the updateLesson mutation */
export type UpdateLessonInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Lesson and Companies */
  companies?: InputMaybe<LessonCompaniesInput>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Lesson and Divisions */
  divisions?: InputMaybe<LessonDivisionsInput>
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Lesson object */
  id: Scalars['ID']['input']
  /** Set connections between the Lesson and lessonCategories */
  lessonCategories?: InputMaybe<LessonLessonCategoriesInput>
  /** Set connections between the Lesson and lessonDifficulties */
  lessonDifficulties?: InputMaybe<LessonLessonDifficultiesInput>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<
    Array<InputMaybe<PostToPostConnectionsMutate>>
  >
  /** Set connections between the Lesson and Providers */
  providers?: InputMaybe<LessonProvidersInput>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Lesson and TrainingTypes */
  trainingTypes?: InputMaybe<LessonTrainingTypesInput>
}

/** The payload for the updateLesson mutation */
export type UpdateLessonPayload = {
  __typename?: 'UpdateLessonPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  lesson?: Maybe<Lesson>
}

/** Input for the UpdateManufacturer mutation */
export type UpdateManufacturerInput = {
  /** The slug that the manufacturer will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the manufacturer object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Manufacturer object to update */
  id: Scalars['ID']['input']
  /** The name of the manufacturer object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateManufacturer mutation */
export type UpdateManufacturerPayload = {
  __typename?: 'UpdateManufacturerPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created manufacturer */
  manufacturer?: Maybe<Manufacturer>
}

/** Input for the updateMediaItem mutation */
export type UpdateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars['String']['input']>
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars['String']['input']>
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars['String']['input']>
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars['String']['input']>
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars['String']['input']>
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars['String']['input']>
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>
  /** The ID of the mediaItem object */
  id: Scalars['ID']['input']
  language?: InputMaybe<LanguageCodeEnum>
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars['String']['input']>
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the updateMediaItem mutation */
export type UpdateMediaItemPayload = {
  __typename?: 'UpdateMediaItemPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The MediaItem object mutation type. */
  mediaItem?: Maybe<MediaItem>
}

/** Input for the updatePage mutation */
export type UpdatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']['input']>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** The ID of the page object */
  id: Scalars['ID']['input']
  language?: InputMaybe<LanguageCodeEnum>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the updatePage mutation */
export type UpdatePagePayload = {
  __typename?: 'UpdatePagePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  page?: Maybe<Page>
}

/** Input for the updatePhoto mutation */
export type UpdatePhotoInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Photo and EntryIds */
  entryIds?: InputMaybe<PhotoEntryIdsInput>
  /** Set connections between the Photo and FieldIds */
  fieldIds?: InputMaybe<PhotoFieldIdsInput>
  /** Set connections between the Photo and FormIds */
  formIds?: InputMaybe<PhotoFormIdsInput>
  /** The ID of the Photo object */
  id: Scalars['ID']['input']
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<
    Array<InputMaybe<PostToPostConnectionsMutate>>
  >
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the updatePhoto mutation */
export type UpdatePhotoPayload = {
  __typename?: 'UpdatePhotoPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  photo?: Maybe<Photo>
}

/** Input for the UpdatePostFormat mutation */
export type UpdatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the post_format object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the postFormat object to update */
  id: Scalars['ID']['input']
  /** The name of the post_format object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdatePostFormat mutation */
export type UpdatePostFormatPayload = {
  __typename?: 'UpdatePostFormatPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created post_format */
  postFormat?: Maybe<PostFormat>
}

/** Input for the updatePost mutation */
export type UpdatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']['input']>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']['input']>
  /** The ID of the post object */
  id: Scalars['ID']['input']
  language?: InputMaybe<LanguageCodeEnum>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars['String']['input']>
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** The payload for the updatePost mutation */
export type UpdatePostPayload = {
  __typename?: 'UpdatePostPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  post?: Maybe<Post>
}

/** Input for the updateProject mutation */
export type UpdateProjectInput = {
  /** Street address */
  address?: InputMaybe<Scalars['String']['input']>
  /** Address, line two */
  address2?: InputMaybe<Scalars['String']['input']>
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** City */
  city?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Project and Companies */
  companies?: InputMaybe<ProjectCompaniesInput>
  /** Country */
  country?: InputMaybe<Scalars['String']['input']>
  /** Name of the customer */
  customer?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the Project and Divisions */
  divisions?: InputMaybe<ProjectDivisionsInput>
  /** End date in yyyy-mm-dd format. */
  endDate?: InputMaybe<Scalars['String']['input']>
  /** Hospital Street address */
  hospitalAddress?: InputMaybe<Scalars['String']['input']>
  /** Hospital City */
  hospitalCity?: InputMaybe<Scalars['String']['input']>
  /** Hospital Name */
  hospitalName?: InputMaybe<Scalars['String']['input']>
  /** Hospital State */
  hospitalState?: InputMaybe<Scalars['String']['input']>
  /** Hospital ZIP code */
  hospitalZip?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Project object */
  id: Scalars['ID']['input']
  /** Key personnel user IDs */
  keyPersonnel?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** Project number */
  number?: InputMaybe<Scalars['String']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<
    Array<InputMaybe<PostToPostConnectionsMutate>>
  >
  /** Set connections between the Project and ProjectStatuses */
  projectStatuses?: InputMaybe<ProjectProjectStatusesInput>
  /** Set connections between the Project and ProjectTypes */
  projectTypes?: InputMaybe<ProjectProjectTypesInput>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** Start date in yyyy-mm-dd format. */
  startDate?: InputMaybe<Scalars['String']['input']>
  /** State or province */
  state?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** ZIP code / postal code */
  zip?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the updateProject mutation */
export type UpdateProjectPayload = {
  __typename?: 'UpdateProjectPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  project?: Maybe<Project>
}

/** Input for the UpdateProjectStatus mutation */
export type UpdateProjectStatusInput = {
  /** The slug that the project_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the project_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the ProjectStatus object to update */
  id: Scalars['ID']['input']
  /** The name of the project_status object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateProjectStatus mutation */
export type UpdateProjectStatusPayload = {
  __typename?: 'UpdateProjectStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created project_status */
  projectStatus?: Maybe<ProjectStatus>
}

/** Input for the UpdateProjectType mutation */
export type UpdateProjectTypeInput = {
  /** The slug that the project_type will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the project_type object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the ProjectType object to update */
  id: Scalars['ID']['input']
  /** The name of the project_type object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateProjectType mutation */
export type UpdateProjectTypePayload = {
  __typename?: 'UpdateProjectTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created project_type */
  projectType?: Maybe<ProjectType>
}

/** Input for the UpdateProvider mutation */
export type UpdateProviderInput = {
  /** The slug that the provider_tax will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the provider_tax object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Provider object to update */
  id: Scalars['ID']['input']
  /** The name of the provider_tax object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateProvider mutation */
export type UpdateProviderPayload = {
  __typename?: 'UpdateProviderPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created provider_tax */
  provider?: Maybe<Provider>
}

/** Input for the UpdateRootCause mutation */
export type UpdateRootCauseInput = {
  /** The slug that the ir_root_cause will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the ir_root_cause object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the RootCause object to update */
  id: Scalars['ID']['input']
  /** The name of the ir_root_cause object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateRootCause mutation */
export type UpdateRootCausePayload = {
  __typename?: 'UpdateRootCausePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created ir_root_cause */
  rootCause?: Maybe<RootCause>
}

/** Input for the updateSettings mutation */
export type UpdateSettingsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Allow people to submit comments on new posts. */
  discussionSettingsDefaultCommentStatus?: InputMaybe<
    Scalars['String']['input']
  >
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  discussionSettingsDefaultPingStatus?: InputMaybe<Scalars['String']['input']>
  /** A date format for all date strings. */
  generalSettingsDateFormat?: InputMaybe<Scalars['String']['input']>
  /** Site tagline. */
  generalSettingsDescription?: InputMaybe<Scalars['String']['input']>
  /** WordPress locale code. */
  generalSettingsLanguage?: InputMaybe<Scalars['String']['input']>
  /** A day number of the week that the week should start on. */
  generalSettingsStartOfWeek?: InputMaybe<Scalars['Int']['input']>
  /** A time format for all time strings. */
  generalSettingsTimeFormat?: InputMaybe<Scalars['String']['input']>
  /** A city in the same timezone as you. */
  generalSettingsTimezone?: InputMaybe<Scalars['String']['input']>
  /** Site title. */
  generalSettingsTitle?: InputMaybe<Scalars['String']['input']>
  /** Blog pages show at most. */
  readingSettingsPostsPerPage?: InputMaybe<Scalars['Int']['input']>
  /** Default post category. */
  writingSettingsDefaultCategory?: InputMaybe<Scalars['Int']['input']>
  /** Default post format. */
  writingSettingsDefaultPostFormat?: InputMaybe<Scalars['String']['input']>
  /** Convert emoticons like :-) and :-P to graphics on display. */
  writingSettingsUseSmilies?: InputMaybe<Scalars['Boolean']['input']>
}

/** The payload for the updateSettings mutation */
export type UpdateSettingsPayload = {
  __typename?: 'UpdateSettingsPayload'
  /** Update all settings. */
  allSettings?: Maybe<Settings>
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** Update the DiscussionSettings setting. */
  discussionSettings?: Maybe<DiscussionSettings>
  /** Update the GeneralSettings setting. */
  generalSettings?: Maybe<GeneralSettings>
  /** Update the ReadingSettings setting. */
  readingSettings?: Maybe<ReadingSettings>
  /** Update the WritingSettings setting. */
  writingSettings?: Maybe<WritingSettings>
}

/** Input for the UpdateSeverity mutation */
export type UpdateSeverityInput = {
  /** The slug that the severity will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the severity object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the Severity object to update */
  id: Scalars['ID']['input']
  /** The name of the severity object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateSeverity mutation */
export type UpdateSeverityPayload = {
  __typename?: 'UpdateSeverityPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created severity */
  severity?: Maybe<Severity>
}

/** Input for the UpdateTag mutation */
export type UpdateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the tag object to update */
  id: Scalars['ID']['input']
  language?: InputMaybe<LanguageCodeEnum>
  /** The name of the post_tag object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateTag mutation */
export type UpdateTagPayload = {
  __typename?: 'UpdateTagPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created post_tag */
  tag?: Maybe<Tag>
}

/** Input for the UpdateToolboxTalkCategory mutation */
export type UpdateToolboxTalkCategoryInput = {
  /** The slug that the toolbox_talk_category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the toolbox_talk_category object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the ToolboxTalkCategory object to update */
  id: Scalars['ID']['input']
  language?: InputMaybe<LanguageCodeEnum>
  /** The name of the toolbox_talk_category object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateToolboxTalkCategory mutation */
export type UpdateToolboxTalkCategoryPayload = {
  __typename?: 'UpdateToolboxTalkCategoryPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created toolbox_talk_category */
  toolboxTalkCategory?: Maybe<ToolboxTalkCategory>
}

/** Input for the updateToolboxTalk mutation */
export type UpdateToolboxTalkInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the ToolboxTalk and Companies */
  companies?: InputMaybe<ToolboxTalkCompaniesInput>
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the ToolboxTalk and Divisions */
  divisions?: InputMaybe<ToolboxTalkDivisionsInput>
  /** The file uploads for the Toolbox Talk */
  fileAttachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
  /** The ID of the ToolboxTalk object */
  id: Scalars['ID']['input']
  language?: InputMaybe<LanguageCodeEnum>
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>
  /** Set connections between the ToolboxTalk and ToolboxTalkCategories */
  toolboxTalkCategories?: InputMaybe<ToolboxTalkToolboxTalkCategoriesInput>
  /** An array of Toolbox Talk Post DatabaseIds to connect a language group with the created / updated post */
  translationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

/** The payload for the updateToolboxTalk mutation */
export type UpdateToolboxTalkPayload = {
  __typename?: 'UpdateToolboxTalkPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The Post object mutation type. */
  toolboxTalk?: Maybe<ToolboxTalk>
}

/** Input for the UpdateTrainingType mutation */
export type UpdateTrainingTypeInput = {
  /** The slug that the training_type will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the training_type object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the TrainingType object to update */
  id: Scalars['ID']['input']
  /** The name of the training_type object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateTrainingType mutation */
export type UpdateTrainingTypePayload = {
  __typename?: 'UpdateTrainingTypePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created training_type */
  trainingType?: Maybe<TrainingType>
}

/** Input for the UpdateUserCompany mutation */
export type UpdateUserCompanyInput = {
  /** The slug that the user_company will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the user_company object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the UserCompany object to update */
  id: Scalars['ID']['input']
  /** The name of the user_company object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateUserCompany mutation */
export type UpdateUserCompanyPayload = {
  __typename?: 'UpdateUserCompanyPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created user_company */
  userCompany?: Maybe<UserCompany>
}

/** Input for the UpdateUserDivision mutation */
export type UpdateUserDivisionInput = {
  /** The slug that the user_division will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the user_division object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the UserDivision object to update */
  id: Scalars['ID']['input']
  /** The name of the user_division object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateUserDivision mutation */
export type UpdateUserDivisionPayload = {
  __typename?: 'UpdateUserDivisionPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created user_division */
  userDivision?: Maybe<UserDivision>
}

/** Input for the updateUser mutation */
export type UpdateUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Companies taxonomy. Will accept the name or slug of the taxonomy. */
  companies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']['input']>
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']['input']>
  /** Divisions Taxonomy. Will accept the name or slug of the taxonomy. */
  divisions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']['input']>
  emergencyContactName?: InputMaybe<Scalars['String']['input']>
  emergencyContactNumber?: InputMaybe<Scalars['String']['input']>
  emergencyContactRelationship?: InputMaybe<Scalars['String']['input']>
  /** Employee ID number. */
  employeeId?: InputMaybe<Scalars['String']['input']>
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']['input']>
  /** The ID of the user */
  id: Scalars['ID']['input']
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']['input']>
  /** User's preferred language. */
  language?: InputMaybe<UserLanguageEnum>
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']['input']>
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']['input']>
  loginCountIncrease?: InputMaybe<Scalars['Boolean']['input']>
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']['input']>
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']['input']>
  /** Offline Mode Setting. When active, upon user login the frontend application will sync the selected data to be saved on the user's device. */
  offlineMode?: InputMaybe<Scalars['Boolean']['input']>
  /** List of strings which represent the data collections to save to the users's device when offlineMode is active. */
  offlineModeData?: InputMaybe<
    Array<InputMaybe<OfflineModeDataCollectionNameEnum>>
  >
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']['input']>
  /** Id */
  postToPostConnections?: InputMaybe<
    Array<InputMaybe<PostToPostConnectionsMutate>>
  >
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']['input']>
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']['input']>
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sdsDownloadCountIncrease?: InputMaybe<Scalars['Boolean']['input']>
  /** Status. */
  status?: InputMaybe<UserStatusEnum>
  /** Worker Vaccine Status. */
  vaccineStatus?: InputMaybe<UserVaccineStatusEnum>
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']['input']>
}

/** Input for the UpdateUserLanguage mutation */
export type UpdateUserLanguageInput = {
  /** The slug that the userLanguage will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the userLanguage object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the UserLanguage object to update */
  id: Scalars['ID']['input']
  /** The name of the userLanguage object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateUserLanguage mutation */
export type UpdateUserLanguagePayload = {
  __typename?: 'UpdateUserLanguagePayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created userLanguage */
  userLanguage?: Maybe<UserLanguage>
}

/** The payload for the updateUser mutation */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The User object mutation type. */
  user?: Maybe<User>
}

/** Input for the UpdateUserStatus mutation */
export type UpdateUserStatusInput = {
  /** The slug that the user_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the user_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the UserStatus object to update */
  id: Scalars['ID']['input']
  /** The name of the user_status object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateUserStatus mutation */
export type UpdateUserStatusPayload = {
  __typename?: 'UpdateUserStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created user_status */
  userStatus?: Maybe<UserStatus>
}

/** Input for the UpdateUserVaccineStatus mutation */
export type UpdateUserVaccineStatusInput = {
  /** The slug that the user_vaccine_status will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The description of the user_vaccine_status object */
  description?: InputMaybe<Scalars['String']['input']>
  /** The ID of the UserVaccineStatus object to update */
  id: Scalars['ID']['input']
  /** The name of the user_vaccine_status object to mutate */
  name?: InputMaybe<Scalars['String']['input']>
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the UpdateUserVaccineStatus mutation */
export type UpdateUserVaccineStatusPayload = {
  __typename?: 'UpdateUserVaccineStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** The created user_vaccine_status */
  userVaccineStatus?: Maybe<UserVaccineStatus>
}

/** A User object */
export type User = Commenter &
  DatabaseIdentifier &
  Node &
  UniformResourceIdentifiable & {
    __typename?: 'User'
    /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
    avatar?: Maybe<Avatar>
    /** User metadata option name. Usually it will be &quot;wp_capabilities&quot;. */
    capKey?: Maybe<Scalars['String']['output']>
    /** A list of capabilities (permissions) granted to the user */
    capabilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Connection between the User type and the Certification type */
    certificationUserConnection?: Maybe<UserToCertificationUserConnectionConnection>
    /** Connection between the User type and the Certification type */
    certifications?: Maybe<UserToCertificationConnection>
    /** Connection between the User type and the Comment type */
    comments?: Maybe<UserToCommentConnection>
    /** Connection between the User type and the UserCompany type */
    companies?: Maybe<UserToUserCompanyConnection>
    /** Connection between the User type and the CorrectiveAction type */
    correctiveActionAssignedWorkersConnection?: Maybe<UserToCorrectiveActionAssignedWorkersConnectionConnection>
    /** Connection between the User type and the CorrectiveAction type */
    correctiveActionDeficiencyOwnersConnection?: Maybe<UserToCorrectiveActionDeficiencyOwnersConnectionConnection>
    /** Connection between the User type and the CorrectiveAction type */
    correctiveActions?: Maybe<UserToCorrectiveActionConnection>
    /** Identifies the primary key from the database. */
    databaseId: Scalars['Int']['output']
    /** Description of the user. */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the UserDivision type */
    divisions?: Maybe<UserToUserDivisionConnection>
    /** Connection between the User type and the Document type */
    documents?: Maybe<UserToDocumentConnection>
    /** Email address of the user. This is equivalent to the WP_User-&gt;user_email property. */
    email?: Maybe<Scalars['String']['output']>
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Emergency Contact Info&quot; was set to Show in GraphQL. */
    emergencyContactInfo?: Maybe<User_Emergencycontactinfo>
    /** EmployeeId that is set while editin a user. Created by Carbon fields. */
    employeeId?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<UserToEnqueuedScriptConnection>
    /** Connection between the User type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<UserToEnqueuedStylesheetConnection>
    /** Connection between the User type and the GfEntry type */
    entries?: Maybe<UserToGfEntryConnection>
    /** Connection between the User type and the Equipment type */
    equipment?: Maybe<UserToEquipmentConnection>
    /** Connection between the User type and the Equipment type */
    equipmentAssignedWorkerConnection?: Maybe<UserToEquipmentAssignedWorkerConnectionConnection>
    /** A complete list of capabilities including capabilities inherited from a role. This is equivalent to the array keys of WP_User-&gt;allcaps. */
    extraCapabilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** First name of the user. This is equivalent to the WP_User-&gt;user_first_name property. */
    firstName?: Maybe<Scalars['String']['output']>
    /** The globally unique identifier for the user object. */
    id: Scalars['ID']['output']
    /** Connection between the User type and the IncidentReport type */
    incidentReportWorkerConnection?: Maybe<UserToIncidentReportWorkerConnectionConnection>
    /** Connection between the User type and the IncidentReport type */
    incidentReports?: Maybe<UserToIncidentReportConnection>
    /** Integration source for the user. Must request databaseId at the same time. Otherwise returns null */
    integrationSource?: Maybe<Scalars['String']['output']>
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the JWT User secret has been revoked. If the secret has been revoked, auth tokens will not be issued until an admin, or user with proper capabilities re-issues a secret for the user. */
    isJwtAuthSecretRevoked: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether user is a super admin. */
    isSuperAdmin?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The expiration for the JWT Token for the user. If not set custom for the user, it will use the default sitewide expiration setting */
    jwtAuthExpiration?: Maybe<Scalars['String']['output']>
    /** A JWT token that can be used in future requests for authentication/authorization */
    jwtAuthToken?: Maybe<Scalars['String']['output']>
    /** A JWT token that can be used in future requests to get a refreshed jwtAuthToken. If the refresh token used in a request is revoked or otherwise invalid, a valid Auth token will NOT be issued in the response headers. */
    jwtRefreshToken?: Maybe<Scalars['String']['output']>
    /** A unique secret tied to the users JWT token that can be revoked or refreshed. Revoking the secret prevents JWT tokens from being issued to the user. Refreshing the token invalidates previously issued tokens, but allows new tokens to be issued. */
    jwtUserSecret?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the UserLanguage type */
    languages?: Maybe<UserToUserLanguageConnection>
    /** Last name of the user. This is equivalent to the WP_User-&gt;user_last_name property. */
    lastName?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the Lesson type */
    lessons?: Maybe<UserToLessonConnection>
    /** The preferred language locale set for the user. Value derived from get_user_locale(). */
    locale?: Maybe<Scalars['String']['output']>
    /** Magicbell HMAC authentication powered email. */
    magicBellHmacEmail?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the mediaItem type */
    mediaItems?: Maybe<UserToMediaItemConnection>
    /** Display name of the user. This is equivalent to the WP_User-&gt;dispaly_name property. */
    name?: Maybe<Scalars['String']['output']>
    /** The nicename for the user. This field is equivalent to WP_User-&gt;user_nicename */
    nicename?: Maybe<Scalars['String']['output']>
    /** Nickname of the user. */
    nickname?: Maybe<Scalars['String']['output']>
    /** Offline mode setting for the user. Must request databaseId at the same time. Otherwise returns null */
    offlineMode?: Maybe<Scalars['Boolean']['output']>
    /** Offline mode data to save on the user&#039;s device. Must request databaseId at the same time. Otherwise returns null */
    offlineModeData?: Maybe<Array<Maybe<OfflineModeDataCollectionNameEnum>>>
    /** Connection between the User type and the page type */
    pages?: Maybe<UserToPageConnection>
    /** Phone number that is set while editing a user. Created by Carbon fields. */
    phoneNumber?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the Photo type */
    photos?: Maybe<UserToPhotoConnection>
    /** Connection between the User type and the post type */
    posts?: Maybe<UserToPostConnection>
    /** Connection between the User type and the Project type */
    projectKeyPersonnelConnection?: Maybe<UserToProjectKeyPersonnelConnectionConnection>
    /** Connection between the User type and the Project type */
    projects?: Maybe<UserToProjectConnection>
    /** The date the user registered or was created. The field follows a full ISO8601 date string format. */
    registeredDate?: Maybe<Scalars['String']['output']>
    /** Connection between the User and Revisions authored by the user */
    revisions?: Maybe<UserToContentRevisionUnionConnection>
    /** Connection between the User type and the UserRole type */
    roles?: Maybe<UserToUserRoleConnection>
    /** The slug for the user. This field is equivalent to WP_User-&gt;user_nicename */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the UserStatus type */
    status?: Maybe<UserToUserStatusConnection>
    /** Connection between the User type and the ToolboxTalk type */
    toolboxTalks?: Maybe<UserToToolboxTalkConnection>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
    /** A website url that is associated with the user. */
    url?: Maybe<Scalars['String']['output']>
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Usage Counts&quot; was set to Show in GraphQL. */
    usageCounts?: Maybe<User_Usagecounts>
    /**
     * The Id of the user. Equivalent to WP_User-&gt;ID
     * @deprecated Deprecated in favor of the databaseId field
     */
    userId?: Maybe<Scalars['Int']['output']>
    /** Username for the user. This field is equivalent to WP_User-&gt;user_login. */
    username?: Maybe<Scalars['String']['output']>
    /** Connection between the User type and the UserVaccineStatus type */
    vaccineStatus?: Maybe<UserToUserVaccineStatusConnectionEdge>
  }

/** A User object */
export type UserAvatarArgs = {
  forceDefault?: InputMaybe<Scalars['Boolean']['input']>
  rating?: InputMaybe<AvatarRatingEnum>
  size?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserCertificationUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserCertificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToCertificationConnectionWhereArgs>
}

/** A User object */
export type UserCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToCommentConnectionWhereArgs>
}

/** A User object */
export type UserCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserCorrectiveActionAssignedWorkersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserCorrectiveActionDeficiencyOwnersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserCorrectiveActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToCorrectiveActionConnectionWhereArgs>
}

/** A User object */
export type UserDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToDocumentConnectionWhereArgs>
}

/** A User object */
export type UserEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToGfEntryConnectionWhereArgs>
}

/** A User object */
export type UserEquipmentArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToEquipmentConnectionWhereArgs>
}

/** A User object */
export type UserEquipmentAssignedWorkerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserIncidentReportWorkerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserIncidentReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToIncidentReportConnectionWhereArgs>
}

/** A User object */
export type UserLanguagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToLessonConnectionWhereArgs>
}

/** A User object */
export type UserMediaItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToMediaItemConnectionWhereArgs>
}

/** A User object */
export type UserPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToPageConnectionWhereArgs>
}

/** A User object */
export type UserPhotosArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToPhotoConnectionWhereArgs>
}

/** A User object */
export type UserPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToPostConnectionWhereArgs>
}

/** A User object */
export type UserProjectKeyPersonnelConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToProjectConnectionWhereArgs>
}

/** A User object */
export type UserRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToContentRevisionUnionConnectionWhereArgs>
}

/** A User object */
export type UserRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserStatusArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A User object */
export type UserToolboxTalksArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserToToolboxTalkConnectionWhereArgs>
}

/** The UserCompany type */
export type UserCompany = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'UserCompany'
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the UserCompany type and the Taxonomy type */
    taxonomy?: Maybe<UserCompanyToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    userCompanyId?: Maybe<Scalars['Int']['output']>
  }

/** The UserCompany type */
export type UserCompanyEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The UserCompany type */
export type UserCompanyEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum UserCompanyIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the UserCompany type and the Taxonomy type */
export type UserCompanyToTaxonomyConnectionEdge = {
  __typename?: 'UserCompanyToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Filter the users by submission counts */
export type UserComplainceFilterInput = {
  /** The logical operator for the filter */
  operator?: InputMaybe<UserComplianceFilterEnum>
  /** The value to filter by */
  value?: InputMaybe<Scalars['Int']['input']>
}

/** User compliance details */
export type UserComplianceDetails = {
  __typename?: 'UserComplianceDetails'
  /** The number of entries expected during the given reporting period. */
  actualCount?: Maybe<Scalars['Int']['output']>
  /** An array of dates on which the form was submitted by the user. */
  datesSubmitted?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The number of entries expected during the given reporting period. */
  expectedCount?: Maybe<Scalars['Int']['output']>
  /** Connection between the UserComplianceDetails type and the User type */
  user?: Maybe<UserComplianceDetailsToUserConnectionEdge>
}

/** Connection between the UserComplianceDetails type and the User type */
export type UserComplianceDetailsToUserConnectionEdge = {
  __typename?: 'UserComplianceDetailsToUserConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<User>
}

/** Options for filtering the connection */
export enum UserComplianceFilterEnum {
  /** Filter by submission counts less than the number */
  Equals = 'EQUALS',
  /** Filter by submission counts greater than the number */
  GreaterThan = 'GREATER_THAN',
  /** Filter by submission counts less than the number */
  LessThan = 'LESS_THAN',
}

/** Options for filtering the connection */
export enum UserComplianceOrderByEnum {
  /** Filter by submission counts less than the number */
  FirstName = 'FIRST_NAME',
  /** Filter by submission counts less than the number */
  LastName = 'LAST_NAME',
  /** Sort users by submission count. */
  SubmissionCount = 'SUBMISSION_COUNT',
}

/** The UserDivision type */
export type UserDivision = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'UserDivision'
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the UserDivision type and the Taxonomy type */
    taxonomy?: Maybe<UserDivisionToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    userDivisionId?: Maybe<Scalars['Int']['output']>
  }

/** The UserDivision type */
export type UserDivisionEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The UserDivision type */
export type UserDivisionEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum UserDivisionIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the UserDivision type and the Taxonomy type */
export type UserDivisionToTaxonomyConnectionEdge = {
  __typename?: 'UserDivisionToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** The UserLanguage type */
export type UserLanguage = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'UserLanguage'
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the UserLanguage type and the Taxonomy type */
    taxonomy?: Maybe<UserLanguageToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    userLanguageId?: Maybe<Scalars['Int']['output']>
  }

/** The UserLanguage type */
export type UserLanguageEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The UserLanguage type */
export type UserLanguageEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** User's preferred language. */
export enum UserLanguageEnum {
  /** U.S. English */
  EnUs = 'EN_US',
  /** Mexican Spanish */
  EsMx = 'ES_MX',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum UserLanguageIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the UserLanguage type and the Taxonomy type */
export type UserLanguageToTaxonomyConnectionEdge = {
  __typename?: 'UserLanguageToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** The Type of Identifier used to fetch a single User node. To be used along with the "id" field. Default is "ID". */
export enum UserNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The Email of the User */
  Email = 'EMAIL',
  /** The hashed Global ID */
  Id = 'ID',
  /** The slug of the User */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
  /** The username the User uses to login with */
  Username = 'USERNAME',
}

/** A user role object */
export type UserRole = Node & {
  __typename?: 'UserRole'
  /** The capabilities that belong to this role */
  capabilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The display name of the role */
  displayName?: Maybe<Scalars['String']['output']>
  /** The globally unique identifier for the user role object. */
  id: Scalars['ID']['output']
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>
  /** The registered name of the role */
  name?: Maybe<Scalars['String']['output']>
}

/** Names of available user roles */
export enum UserRoleEnum {
  /** User role with specific capabilities */
  Administrator = 'ADMINISTRATOR',
  /** User role with specific capabilities */
  CompanyAdmin = 'COMPANY_ADMIN',
  /** User role with specific capabilities */
  CompanyManager = 'COMPANY_MANAGER',
  /** User role with specific capabilities */
  CompanyWorker = 'COMPANY_WORKER',
  /** User role with specific capabilities */
  CrewLeader = 'CREW_LEADER',
  /** User role with specific capabilities */
  DivisionWorker = 'DIVISION_WORKER',
  /** User role with specific capabilities */
  Foreman = 'FOREMAN',
  /** User role with specific capabilities */
  OfficeWorker = 'OFFICE_WORKER',
  /** User role with specific capabilities */
  Operator = 'OPERATOR',
  /** User role with specific capabilities */
  ProjectManager = 'PROJECT_MANAGER',
  /** User role with specific capabilities */
  SafetyManager = 'SAFETY_MANAGER',
  /** User role with specific capabilities */
  Tradesperson = 'TRADESPERSON',
}

/** The UserStatus type */
export type UserStatus = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'UserStatus'
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the UserStatus type and the Taxonomy type */
    taxonomy?: Maybe<UserStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    userStatusId?: Maybe<Scalars['Int']['output']>
  }

/** The UserStatus type */
export type UserStatusEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The UserStatus type */
export type UserStatusEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** User status. */
export enum UserStatusEnum {
  /** Active */
  Active = 'ACTIVE',
  /** Inactive */
  Inactive = 'INACTIVE',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum UserStatusIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the UserStatus type and the Taxonomy type */
export type UserStatusToTaxonomyConnectionEdge = {
  __typename?: 'UserStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Connection between the User type and the Certification type */
export type UserToCertificationConnection = {
  __typename?: 'UserToCertificationConnection'
  /** Edges for the UserToCertificationConnection connection */
  edges?: Maybe<Array<Maybe<UserToCertificationConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Certification>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToCertificationConnectionEdge = {
  __typename?: 'UserToCertificationConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Certification>
}

/** Arguments for filtering the UserToCertificationConnection connection */
export type UserToCertificationConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToCertificationConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToCertificationConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToCertificationConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToCertificationConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToCertificationConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToCertificationConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToCertificationConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToCertificationConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToCertificationConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToCertificationConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToCertificationConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToCertificationConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToCertificationConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToCertificationConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToCertificationConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToCertificationConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the Certification type */
export type UserToCertificationUserConnectionConnection = {
  __typename?: 'UserToCertificationUserConnectionConnection'
  /** Edges for the UserToCertificationUserConnectionConnection connection */
  edges?: Maybe<Array<Maybe<UserToCertificationUserConnectionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Certification>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToCertificationUserConnectionConnectionEdge = {
  __typename?: 'UserToCertificationUserConnectionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Certification>
}

/** Connection between the User type and the Comment type */
export type UserToCommentConnection = {
  __typename?: 'UserToCommentConnection'
  /** Edges for the UserToCommentConnection connection */
  edges?: Maybe<Array<Maybe<UserToCommentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Comment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToCommentConnectionEdge = {
  __typename?: 'UserToCommentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Comment>
}

/** Arguments for filtering the UserToCommentConnection connection */
export type UserToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>
}

/** Connection between the User type and the ContentRevisionUnion type */
export type UserToContentRevisionUnionConnection = {
  __typename?: 'UserToContentRevisionUnionConnection'
  /** Edges for the UserToContentRevisionUnionConnection connection */
  edges?: Maybe<Array<Maybe<UserToContentRevisionUnionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentRevisionUnion>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToContentRevisionUnionConnectionEdge = {
  __typename?: 'UserToContentRevisionUnionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ContentRevisionUnion>
}

/** Arguments for filtering the UserToContentRevisionUnionConnection connection */
export type UserToContentRevisionUnionConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToContentRevisionUnionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToContentRevisionUnionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToContentRevisionUnionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToContentRevisionUnionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToContentRevisionUnionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToContentRevisionUnionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToContentRevisionUnionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToContentRevisionUnionConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToContentRevisionUnionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToContentRevisionUnionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToContentRevisionUnionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToContentRevisionUnionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToContentRevisionUnionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToContentRevisionUnionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToContentRevisionUnionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToContentRevisionUnionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the CorrectiveAction type */
export type UserToCorrectiveActionAssignedWorkersConnectionConnection = {
  __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnection'
  /** Edges for the UserToCorrectiveActionAssignedWorkersConnectionConnection connection */
  edges?: Maybe<
    Array<Maybe<UserToCorrectiveActionAssignedWorkersConnectionConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToCorrectiveActionAssignedWorkersConnectionConnectionEdge = {
  __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Connection between the User type and the CorrectiveAction type */
export type UserToCorrectiveActionConnection = {
  __typename?: 'UserToCorrectiveActionConnection'
  /** Edges for the UserToCorrectiveActionConnection connection */
  edges?: Maybe<Array<Maybe<UserToCorrectiveActionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToCorrectiveActionConnectionEdge = {
  __typename?: 'UserToCorrectiveActionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Arguments for filtering the UserToCorrectiveActionConnection connection */
export type UserToCorrectiveActionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToCorrectiveActionConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToCorrectiveActionConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToCorrectiveActionConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToCorrectiveActionConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToCorrectiveActionConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToCorrectiveActionConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToCorrectiveActionConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToCorrectiveActionConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToCorrectiveActionConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToCorrectiveActionConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToCorrectiveActionConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToCorrectiveActionConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToCorrectiveActionConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToCorrectiveActionConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToCorrectiveActionConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToCorrectiveActionConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the CorrectiveAction type */
export type UserToCorrectiveActionDeficiencyOwnersConnectionConnection = {
  __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnection'
  /** Edges for the UserToCorrectiveActionDeficiencyOwnersConnectionConnection connection */
  edges?: Maybe<
    Array<Maybe<UserToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<CorrectiveAction>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge = {
  __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<CorrectiveAction>
}

/** Connection between the User type and the Document type */
export type UserToDocumentConnection = {
  __typename?: 'UserToDocumentConnection'
  /** Edges for the UserToDocumentConnection connection */
  edges?: Maybe<Array<Maybe<UserToDocumentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Document>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToDocumentConnectionEdge = {
  __typename?: 'UserToDocumentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Document>
}

/** Arguments for filtering the UserToDocumentConnection connection */
export type UserToDocumentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToDocumentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToDocumentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToDocumentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToDocumentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToDocumentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToDocumentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToDocumentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToDocumentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToDocumentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToDocumentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToDocumentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToDocumentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToDocumentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToDocumentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToDocumentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToDocumentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the EnqueuedScript type */
export type UserToEnqueuedScriptConnection = {
  __typename?: 'UserToEnqueuedScriptConnection'
  /** Edges for the UserToEnqueuedScriptConnection connection */
  edges?: Maybe<Array<Maybe<UserToEnqueuedScriptConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToEnqueuedScriptConnectionEdge = {
  __typename?: 'UserToEnqueuedScriptConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedScript>
}

/** Connection between the User type and the EnqueuedStylesheet type */
export type UserToEnqueuedStylesheetConnection = {
  __typename?: 'UserToEnqueuedStylesheetConnection'
  /** Edges for the UserToEnqueuedStylesheetConnection connection */
  edges?: Maybe<Array<Maybe<UserToEnqueuedStylesheetConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToEnqueuedStylesheetConnectionEdge = {
  __typename?: 'UserToEnqueuedStylesheetConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedStylesheet>
}

/** Connection between the User type and the Equipment type */
export type UserToEquipmentAssignedWorkerConnectionConnection = {
  __typename?: 'UserToEquipmentAssignedWorkerConnectionConnection'
  /** Edges for the UserToEquipmentAssignedWorkerConnectionConnection connection */
  edges?: Maybe<
    Array<Maybe<UserToEquipmentAssignedWorkerConnectionConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToEquipmentAssignedWorkerConnectionConnectionEdge = {
  __typename?: 'UserToEquipmentAssignedWorkerConnectionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Connection between the User type and the Equipment type */
export type UserToEquipmentConnection = {
  __typename?: 'UserToEquipmentConnection'
  /** Edges for the UserToEquipmentConnection connection */
  edges?: Maybe<Array<Maybe<UserToEquipmentConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Equipment>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToEquipmentConnectionEdge = {
  __typename?: 'UserToEquipmentConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Equipment>
}

/** Arguments for filtering the UserToEquipmentConnection connection */
export type UserToEquipmentConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToEquipmentConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToEquipmentConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToEquipmentConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToEquipmentConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToEquipmentConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToEquipmentConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToEquipmentConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToEquipmentConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToEquipmentConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToEquipmentConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToEquipmentConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToEquipmentConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToEquipmentConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToEquipmentConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToEquipmentConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToEquipmentConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the GfEntry type */
export type UserToGfEntryConnection = {
  __typename?: 'UserToGfEntryConnection'
  /** Edges for the UserToGfEntryConnection connection */
  edges?: Maybe<Array<Maybe<UserToGfEntryConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<GfEntry>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToGfEntryConnectionEdge = {
  __typename?: 'UserToGfEntryConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<GfEntry>
}

/** Arguments for filtering the UserToGfEntryConnection connection */
export type UserToGfEntryConnectionWhereArgs = {
  /** Filter the entries by their ebd date */
  endDate?: InputMaybe<Scalars['String']['input']>
  /** The ids of the forms to filter the entries by */
  formIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the entries by their form slug */
  formSlugs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Filter the entries by their start date */
  startDate?: InputMaybe<Scalars['String']['input']>
}

/** Connection between the User type and the IncidentReport type */
export type UserToIncidentReportConnection = {
  __typename?: 'UserToIncidentReportConnection'
  /** Edges for the UserToIncidentReportConnection connection */
  edges?: Maybe<Array<Maybe<UserToIncidentReportConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReport>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToIncidentReportConnectionEdge = {
  __typename?: 'UserToIncidentReportConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReport>
}

/** Arguments for filtering the UserToIncidentReportConnection connection */
export type UserToIncidentReportConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToIncidentReportConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToIncidentReportConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToIncidentReportConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToIncidentReportConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToIncidentReportConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToIncidentReportConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToIncidentReportConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToIncidentReportConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToIncidentReportConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToIncidentReportConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToIncidentReportConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToIncidentReportConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToIncidentReportConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToIncidentReportConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToIncidentReportConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToIncidentReportConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the IncidentReport type */
export type UserToIncidentReportWorkerConnectionConnection = {
  __typename?: 'UserToIncidentReportWorkerConnectionConnection'
  /** Edges for the UserToIncidentReportWorkerConnectionConnection connection */
  edges?: Maybe<
    Array<Maybe<UserToIncidentReportWorkerConnectionConnectionEdge>>
  >
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<IncidentReport>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToIncidentReportWorkerConnectionConnectionEdge = {
  __typename?: 'UserToIncidentReportWorkerConnectionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<IncidentReport>
}

/** Connection between the User type and the Lesson type */
export type UserToLessonConnection = {
  __typename?: 'UserToLessonConnection'
  /** Edges for the UserToLessonConnection connection */
  edges?: Maybe<Array<Maybe<UserToLessonConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Lesson>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToLessonConnectionEdge = {
  __typename?: 'UserToLessonConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Lesson>
}

/** Arguments for filtering the UserToLessonConnection connection */
export type UserToLessonConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToLessonConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToLessonConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToLessonConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToLessonConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToLessonConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToLessonConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToLessonConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToLessonConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToLessonConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToLessonConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToLessonConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToLessonConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToLessonConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToLessonConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToLessonConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToLessonConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the mediaItem type */
export type UserToMediaItemConnection = {
  __typename?: 'UserToMediaItemConnection'
  /** Edges for the UserToMediaItemConnection connection */
  edges?: Maybe<Array<Maybe<UserToMediaItemConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MediaItem>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToMediaItemConnectionEdge = {
  __typename?: 'UserToMediaItemConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<MediaItem>
}

/** Arguments for filtering the UserToMediaItemConnection connection */
export type UserToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToMediaItemConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToMediaItemConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToMediaItemConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToMediaItemConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToMediaItemConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToMediaItemConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToMediaItemConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToMediaItemConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToMediaItemConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToMediaItemConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToMediaItemConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToMediaItemConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToMediaItemConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToMediaItemConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToMediaItemConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToMediaItemConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the page type */
export type UserToPageConnection = {
  __typename?: 'UserToPageConnection'
  /** Edges for the UserToPageConnection connection */
  edges?: Maybe<Array<Maybe<UserToPageConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Page>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToPageConnectionEdge = {
  __typename?: 'UserToPageConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Page>
}

/** Arguments for filtering the UserToPageConnection connection */
export type UserToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToPageConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToPageConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToPageConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToPageConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToPageConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToPageConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToPageConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToPageConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToPageConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToPageConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToPageConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToPageConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToPageConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToPageConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToPageConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToPageConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the Photo type */
export type UserToPhotoConnection = {
  __typename?: 'UserToPhotoConnection'
  /** Edges for the UserToPhotoConnection connection */
  edges?: Maybe<Array<Maybe<UserToPhotoConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Photo>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToPhotoConnectionEdge = {
  __typename?: 'UserToPhotoConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Photo>
}

/** Arguments for filtering the UserToPhotoConnection connection */
export type UserToPhotoConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToPhotoConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToPhotoConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToPhotoConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToPhotoConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToPhotoConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToPhotoConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToPhotoConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToPhotoConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToPhotoConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToPhotoConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToPhotoConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToPhotoConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToPhotoConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToPhotoConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToPhotoConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToPhotoConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the post type */
export type UserToPostConnection = {
  __typename?: 'UserToPostConnection'
  /** Edges for the UserToPostConnection connection */
  edges?: Maybe<Array<Maybe<UserToPostConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToPostConnectionEdge = {
  __typename?: 'UserToPostConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Post>
}

/** Arguments for filtering the UserToPostConnection connection */
export type UserToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToPostConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  taxQuery?: InputMaybe<UserToPostConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToPostConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToPostConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToPostConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToPostConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToPostConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToPostConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToPostConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToPostConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToPostConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToPostConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToPostConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToPostConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToPostConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToPostConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the Project type */
export type UserToProjectConnection = {
  __typename?: 'UserToProjectConnection'
  /** Edges for the UserToProjectConnection connection */
  edges?: Maybe<Array<Maybe<UserToProjectConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToProjectConnectionEdge = {
  __typename?: 'UserToProjectConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Arguments for filtering the UserToProjectConnection connection */
export type UserToProjectConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToProjectConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToProjectConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToProjectConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToProjectConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToProjectConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToProjectConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToProjectConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToProjectConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToProjectConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToProjectConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToProjectConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToProjectConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToProjectConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToProjectConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToProjectConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToProjectConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the Project type */
export type UserToProjectKeyPersonnelConnectionConnection = {
  __typename?: 'UserToProjectKeyPersonnelConnectionConnection'
  /** Edges for the UserToProjectKeyPersonnelConnectionConnection connection */
  edges?: Maybe<Array<Maybe<UserToProjectKeyPersonnelConnectionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Project>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToProjectKeyPersonnelConnectionConnectionEdge = {
  __typename?: 'UserToProjectKeyPersonnelConnectionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<Project>
}

/** Connection between the User type and the ToolboxTalk type */
export type UserToToolboxTalkConnection = {
  __typename?: 'UserToToolboxTalkConnection'
  /** Edges for the UserToToolboxTalkConnection connection */
  edges?: Maybe<Array<Maybe<UserToToolboxTalkConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ToolboxTalk>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToToolboxTalkConnectionEdge = {
  __typename?: 'UserToToolboxTalkConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<ToolboxTalk>
}

/** Arguments for filtering the UserToToolboxTalkConnection connection */
export type UserToToolboxTalkConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  metaQuery?: InputMaybe<UserToToolboxTalkConnectionWhereArgsMetaQuery>
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>
  taxQuery?: InputMaybe<UserToToolboxTalkConnectionWhereArgsTaxQuery>
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserToToolboxTalkConnectionWhereArgsMetaArray = {
  /** Custom field value */
  compare?: InputMaybe<UserToToolboxTalkConnectionWhereArgsMetaCompareEnum>
  /** Custom field key */
  key?: InputMaybe<Scalars['String']['input']>
  /** Custom field value */
  type?: InputMaybe<UserToToolboxTalkConnectionWhereArgsMetaTypeEnum>
  /** Custom field value */
  value?: InputMaybe<Scalars['String']['input']>
  /** Custom field values */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum UserToToolboxTalkConnectionWhereArgsMetaCompareEnum {
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqualTo = 'NOT_EQUAL_TO',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
}

export type UserToToolboxTalkConnectionWhereArgsMetaQuery = {
  metaArray?: InputMaybe<
    Array<InputMaybe<UserToToolboxTalkConnectionWhereArgsMetaArray>>
  >
  relation?: InputMaybe<RelationEnum>
}

export enum UserToToolboxTalkConnectionWhereArgsMetaTypeEnum {
  Binary = 'BINARY',
  Char = 'CHAR',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Signed = 'SIGNED',
  Time = 'TIME',
  Unsigned = 'UNSIGNED',
}

export type UserToToolboxTalkConnectionWhereArgsTaxArray = {
  field?: InputMaybe<UserToToolboxTalkConnectionWhereArgsTaxQueryField>
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<UserToToolboxTalkConnectionWhereArgsTaxQueryOperator>
  taxonomy?: InputMaybe<TaxonomyEnum>
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Query objects based on taxonomy parameters */
export type UserToToolboxTalkConnectionWhereArgsTaxQuery = {
  relation?: InputMaybe<RelationEnum>
  taxArray?: InputMaybe<
    Array<InputMaybe<UserToToolboxTalkConnectionWhereArgsTaxArray>>
  >
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToToolboxTalkConnectionWhereArgsTaxQueryField {
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  TaxonomyId = 'TAXONOMY_ID',
}

export enum UserToToolboxTalkConnectionWhereArgsTaxQueryOperator {
  And = 'AND',
  Exists = 'EXISTS',
  In = 'IN',
  NotExists = 'NOT_EXISTS',
  NotIn = 'NOT_IN',
}

/** Connection between the User type and the UserCompany type */
export type UserToUserCompanyConnection = {
  __typename?: 'UserToUserCompanyConnection'
  /** Edges for the UserToUserCompanyConnection connection */
  edges?: Maybe<Array<Maybe<UserToUserCompanyConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserCompany>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToUserCompanyConnectionEdge = {
  __typename?: 'UserToUserCompanyConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserCompany>
}

/** Connection between the User type and the UserDivision type */
export type UserToUserDivisionConnection = {
  __typename?: 'UserToUserDivisionConnection'
  /** Edges for the UserToUserDivisionConnection connection */
  edges?: Maybe<Array<Maybe<UserToUserDivisionConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserDivision>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToUserDivisionConnectionEdge = {
  __typename?: 'UserToUserDivisionConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserDivision>
}

/** Connection between the User type and the UserLanguage type */
export type UserToUserLanguageConnection = {
  __typename?: 'UserToUserLanguageConnection'
  /** Edges for the UserToUserLanguageConnection connection */
  edges?: Maybe<Array<Maybe<UserToUserLanguageConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserLanguage>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToUserLanguageConnectionEdge = {
  __typename?: 'UserToUserLanguageConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserLanguage>
}

/** Connection between the User type and the UserRole type */
export type UserToUserRoleConnection = {
  __typename?: 'UserToUserRoleConnection'
  /** Edges for the UserToUserRoleConnection connection */
  edges?: Maybe<Array<Maybe<UserToUserRoleConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserRole>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToUserRoleConnectionEdge = {
  __typename?: 'UserToUserRoleConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserRole>
}

/** Connection between the User type and the UserStatus type */
export type UserToUserStatusConnection = {
  __typename?: 'UserToUserStatusConnection'
  /** Edges for the UserToUserStatusConnection connection */
  edges?: Maybe<Array<Maybe<UserToUserStatusConnectionEdge>>>
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserStatus>>>
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>
}

/** An edge in a connection */
export type UserToUserStatusConnectionEdge = {
  __typename?: 'UserToUserStatusConnectionEdge'
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge */
  node?: Maybe<UserStatus>
}

/** Connection between the User type and the UserVaccineStatus type */
export type UserToUserVaccineStatusConnectionEdge = {
  __typename?: 'UserToUserVaccineStatusConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<UserVaccineStatus>
}

/** The UserVaccineStatus type */
export type UserVaccineStatus = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'UserVaccineStatus'
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    databaseId: Scalars['Int']['output']
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>
    /** The unique resource identifier path */
    id: Scalars['ID']['output']
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output']
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output']
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>
    /** Connection between the UserVaccineStatus type and the Taxonomy type */
    taxonomy?: Maybe<UserVaccineStatusToTaxonomyConnectionEdge>
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    userVaccineStatusId?: Maybe<Scalars['Int']['output']>
  }

/** The UserVaccineStatus type */
export type UserVaccineStatusEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The UserVaccineStatus type */
export type UserVaccineStatusEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** User Vaccine status. */
export enum UserVaccineStatusEnum {
  /** Exempt status slug */
  Exempt = 'EXEMPT',
  /** Fully Vaccinated status slug */
  FullyVaccinated = 'FULLY_VACCINATED',
  /** Missing vaccine status taxonomy */
  MissingStatus = 'MISSING_STATUS',
  /** Partially Vaccinated statu slug */
  PartiallyVaccianted = 'PARTIALLY_VACCIANTED',
  /** Unvaccinated status slug */
  Unvaccinated = 'UNVACCINATED',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum UserVaccineStatusIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the UserVaccineStatus type and the Taxonomy type */
export type UserVaccineStatusToTaxonomyConnectionEdge = {
  __typename?: 'UserVaccineStatusToTaxonomyConnectionEdge'
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>
}

/** Field Group */
export type User_Emergencycontactinfo = AcfFieldGroup & {
  __typename?: 'User_Emergencycontactinfo'
  emergencyContactName?: Maybe<Scalars['String']['output']>
  emergencyContactNumber?: Maybe<Scalars['String']['output']>
  emergencyContactRelationship?: Maybe<Scalars['String']['output']>
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
}

/** Field Group */
export type User_Usagecounts = AcfFieldGroup & {
  __typename?: 'User_Usagecounts'
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>
  lastSeen?: Maybe<Scalars['String']['output']>
  loginCount?: Maybe<Scalars['Float']['output']>
  sdsDownloadCount?: Maybe<Scalars['Float']['output']>
}

/** A Gravity Forms   field. */
export type UsernameField = FormField & {
  __typename?: 'UsernameField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']['output']>
  /** Whether the field has an input mask. */
  hasInputMask?: Maybe<Scalars['Boolean']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** The pattern used for the input mask. */
  inputMaskValue?: Maybe<Scalars['String']['output']>
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if a text field input tag should be created with a &quot;password&quot; type. */
  isPasswordInput?: Maybe<Scalars['Boolean']['output']>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Field to order the connection by */
export enum UsersConnectionOrderbyEnum {
  /** Order by display name */
  DisplayName = 'DISPLAY_NAME',
  /** Order by email address */
  Email = 'EMAIL',
  /** Order by login */
  Login = 'LOGIN',
  /** Preserve the login order given in the LOGIN_IN array */
  LoginIn = 'LOGIN_IN',
  /** Order by nice name */
  NiceName = 'NICE_NAME',
  /** Preserve the nice name order given in the NICE_NAME_IN array */
  NiceNameIn = 'NICE_NAME_IN',
  /** Order by registration date */
  Registered = 'REGISTERED',
  /** Order by URL */
  Url = 'URL',
}

/** Options for ordering the connection */
export type UsersConnectionOrderbyInput = {
  /** The field name used to sort the results. */
  field: UsersConnectionOrderbyEnum
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>
}

/** Column used for searching for users. */
export enum UsersConnectionSearchColumnEnum {
  /** The user's email address. */
  Email = 'EMAIL',
  /** The globally unique ID. */
  Id = 'ID',
  /** The username the User uses to login with. */
  Login = 'LOGIN',
  /** A URL-friendly name for the user. The default is the user's username. */
  Nicename = 'NICENAME',
  /** The URL of the user\s website. */
  Url = 'URL',
}

/** Information needed by gatsby-source-wordpress. */
export type WpGatsby = {
  __typename?: 'WPGatsby'
  /** Returns wether or not pretty permalinks are enabled. */
  arePrettyPermalinksEnabled?: Maybe<Scalars['Boolean']['output']>
  /** The current status of a Gatsby Preview. */
  gatsbyPreviewStatus?: Maybe<WpGatsbyPreviewStatus>
  /** Wether or not the Preview frontend URL is online. */
  isPreviewFrontendOnline?: Maybe<Scalars['Boolean']['output']>
}

/** Information needed by gatsby-source-wordpress. */
export type WpGatsbyGatsbyPreviewStatusArgs = {
  nodeId: Scalars['Float']['input']
}

/** Check compatibility with a given version of gatsby-source-wordpress and the WordPress source site. */
export type WpGatsbyCompatibility = {
  __typename?: 'WPGatsbyCompatibility'
  satisfies?: Maybe<WpGatsbySatisfies>
}

/** A previewed Gatsby page node. */
export type WpGatsbyPageNode = {
  __typename?: 'WPGatsbyPageNode'
  path?: Maybe<Scalars['String']['output']>
}

/** Check compatibility with a given version of gatsby-source-wordpress and the WordPress source site. */
export type WpGatsbyPreviewStatus = {
  __typename?: 'WPGatsbyPreviewStatus'
  modifiedLocal?: Maybe<Scalars['String']['output']>
  modifiedRemote?: Maybe<Scalars['String']['output']>
  pageNode?: Maybe<WpGatsbyPageNode>
  remoteStatus?: Maybe<WpGatsbyRemotePreviewStatusEnum>
  statusContext?: Maybe<Scalars['String']['output']>
  statusType?: Maybe<WpGatsbyWpPreviewedNodeStatus>
}

/** The different statuses a Gatsby Preview can be in for a single node. */
export enum WpGatsbyRemotePreviewStatusEnum {
  GatsbyPreviewProcessError = 'GATSBY_PREVIEW_PROCESS_ERROR',
  NoPageCreatedForPreviewedNode = 'NO_PAGE_CREATED_FOR_PREVIEWED_NODE',
  PreviewSuccess = 'PREVIEW_SUCCESS',
  ReceivedPreviewDataFromWrongUrl = 'RECEIVED_PREVIEW_DATA_FROM_WRONG_URL',
}

/** Check compatibility with WPGatsby and WPGraphQL. */
export type WpGatsbySatisfies = {
  __typename?: 'WPGatsbySatisfies'
  /** Whether the provided version range requirement for WPGraphQL is met by this WP instance. */
  wpGQL?: Maybe<Scalars['Boolean']['output']>
  /** Whether the provided version range requirement for WPGatsby is met by this WP instance. */
  wpGatsby?: Maybe<Scalars['Boolean']['output']>
}

/** The different statuses a Gatsby Preview can be in for a single node. */
export enum WpGatsbyWpPreviewedNodeStatus {
  NoNodeFound = 'NO_NODE_FOUND',
  NoPreviewPathFound = 'NO_PREVIEW_PATH_FOUND',
  PreviewPageUpdatedButNotYetDeployed = 'PREVIEW_PAGE_UPDATED_BUT_NOT_YET_DEPLOYED',
  PreviewReady = 'PREVIEW_READY',
  ReceivedPreviewDataFromWrongUrl = 'RECEIVED_PREVIEW_DATA_FROM_WRONG_URL',
  RemoteNodeNotYetUpdated = 'REMOTE_NODE_NOT_YET_UPDATED',
}

/** Information about pagination in a connection. */
export type WpPageInfo = {
  __typename?: 'WPPageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

/** A Gravity Forms   field. */
export type WebsiteField = FormField & {
  __typename?: 'WebsiteField'
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']['output']>
  /** Field Level Translation on the Label */
  basicTranslations?: Maybe<Array<Maybe<BasicTranslation>>>
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']['output']>
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']['output']>
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']['output']>
  /** Field description. */
  description?: Maybe<Scalars['String']['output']>
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']['output']>
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']['output']>
  /** Field ID. */
  id: Scalars['Int']['output']
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']['output']>
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']['output']>
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']['output']>
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']['output']>
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']['output']>
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']['output']>
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']['output']>
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>
}

/** Input for the wpGatsbyRemotePreviewStatus mutation */
export type WpGatsbyRemotePreviewStatusInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The modified date of the latest revision for this preview. */
  modified?: InputMaybe<Scalars['String']['input']>
  /** The Gatsby page path for this preview. */
  pagePath?: InputMaybe<Scalars['String']['input']>
  /** The previewed revisions post parent id */
  parentDatabaseId?: InputMaybe<Scalars['Float']['input']>
  /** The remote status of the previewed node */
  status: WpGatsbyRemotePreviewStatusEnum
  /** Additional context about the preview status */
  statusContext?: InputMaybe<Scalars['String']['input']>
}

/** The payload for the wpGatsbyRemotePreviewStatus mutation */
export type WpGatsbyRemotePreviewStatusPayload = {
  __typename?: 'WpGatsbyRemotePreviewStatusPayload'
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** Wether or not the revision mutation was successful */
  success?: Maybe<Scalars['Boolean']['output']>
}

/** The writing setting type */
export type WritingSettings = {
  __typename?: 'WritingSettings'
  /** Default post category. */
  defaultCategory?: Maybe<Scalars['Int']['output']>
  /** Default post format. */
  defaultPostFormat?: Maybe<Scalars['String']['output']>
  /** Convert emoticons like :-) and :-P to graphics on display. */
  useSmilies?: Maybe<Scalars['Boolean']['output']>
}

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type LoginMutation = {
  __typename?: 'RootMutation'
  login?: {
    __typename?: 'LoginPayload'
    authToken?: string | null
    refreshToken?: string | null
    user?: {
      __typename?: 'User'
      username?: string | null
      id: string
      databaseId: number
      firstName?: string | null
      lastName?: string | null
      name?: string | null
      email?: string | null
      magicBellHmacEmail?: string | null
      capabilities?: Array<string | null> | null
      offlineMode?: boolean | null
      offlineModeData?: Array<OfflineModeDataCollectionNameEnum | null> | null
      isSuperAdmin?: boolean | null
      avatar?: { __typename?: 'Avatar'; url?: string | null } | null
      languages?: {
        __typename?: 'UserToUserLanguageConnection'
        edges?: Array<{
          __typename?: 'UserToUserLanguageConnectionEdge'
          node?: {
            __typename?: 'UserLanguage'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'UserToUserCompanyConnection'
        edges?: Array<{
          __typename?: 'UserToUserCompanyConnectionEdge'
          node?: {
            __typename?: 'UserCompany'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'UserToUserDivisionConnection'
        edges?: Array<{
          __typename?: 'UserToUserDivisionConnectionEdge'
          node?: {
            __typename?: 'UserDivision'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      roles?: {
        __typename?: 'UserToUserRoleConnection'
        edges?: Array<{
          __typename?: 'UserToUserRoleConnectionEdge'
          node?: {
            __typename?: 'UserRole'
            name?: string | null
            displayName?: string | null
          } | null
        } | null> | null
      } | null
      correctiveActionAssignedWorkersConnection?: {
        __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnection'
        edges?: Array<{
          __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnectionEdge'
          node?: {
            __typename?: 'CorrectiveAction'
            id: string
            databaseId: number
            title?: string | null
            actionTaken?: string | null
            date?: string | null
            dueDate?: string | null
            dateCompleted?: string | null
            projectCorrectiveActionConnection?: {
              __typename?: 'CorrectiveActionToProjectConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToProjectConnectionEdge'
                node?: {
                  __typename?: 'Project'
                  number?: string | null
                  title?: string | null
                  databaseId: number
                } | null
              } | null> | null
            } | null
            correctiveActionStatuses?: {
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
                node?: {
                  __typename?: 'CorrectiveActionStatus'
                  slug?: string | null
                  name?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          endCursor?: string | null
        } | null
      } | null
      correctiveActionDeficiencyOwnersConnection?: {
        __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnection'
        edges?: Array<{
          __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
          node?: {
            __typename?: 'CorrectiveAction'
            id: string
            databaseId: number
            title?: string | null
            actionTaken?: string | null
            date?: string | null
            dueDate?: string | null
            dateCompleted?: string | null
            projectCorrectiveActionConnection?: {
              __typename?: 'CorrectiveActionToProjectConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToProjectConnectionEdge'
                node?: {
                  __typename?: 'Project'
                  number?: string | null
                  title?: string | null
                  databaseId: number
                } | null
              } | null> | null
            } | null
            correctiveActionStatuses?: {
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
                node?: {
                  __typename?: 'CorrectiveActionStatus'
                  slug?: string | null
                  name?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          endCursor?: string | null
        } | null
      } | null
      projectKeyPersonnelConnection?: {
        __typename?: 'UserToProjectKeyPersonnelConnectionConnection'
        edges?: Array<{
          __typename?: 'UserToProjectKeyPersonnelConnectionConnectionEdge'
          node?: {
            __typename?: 'Project'
            id: string
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          endCursor?: string | null
        } | null
      } | null
      emergencyContactInfo?: {
        __typename?: 'User_Emergencycontactinfo'
        emergencyContactName?: string | null
        emergencyContactNumber?: string | null
        emergencyContactRelationship?: string | null
      } | null
      usageCounts?: {
        __typename?: 'User_Usagecounts'
        sdsDownloadCount?: number | null
      } | null
    } | null
  } | null
}

export type PasswordResetMutationVariables = Exact<{
  login: Scalars['String']['input']
  password: Scalars['String']['input']
  key: Scalars['String']['input']
}>

export type PasswordResetMutation = {
  __typename?: 'RootMutation'
  resetUserPassword?: {
    __typename?: 'ResetUserPasswordPayload'
    user?: {
      __typename?: 'User'
      email?: string | null
      username?: string | null
    } | null
  } | null
}

export type PasswordResetRequestMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type PasswordResetRequestMutation = {
  __typename?: 'RootMutation'
  sendPasswordResetEmail?: {
    __typename?: 'SendPasswordResetEmailPayload'
    user?: {
      __typename?: 'User'
      email?: string | null
      username?: string | null
    } | null
  } | null
}

export type RefreshAuthTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']['input']
}>

export type RefreshAuthTokenMutation = {
  __typename?: 'RootMutation'
  refreshJwtAuthToken?: {
    __typename?: 'RefreshJwtAuthTokenPayload'
    authToken?: string | null
  } | null
}

export type Certification_FragFragment = {
  __typename?: 'Certification'
  id: string
  databaseId: number
  title?: string | null
  content?: string | null
  certificationDetails?: {
    __typename?: 'Certification_Certificationdetails'
    completedDate?: string | null
    expirationDate?: string | null
  } | null
  author?: {
    __typename?: 'NodeWithAuthorToUserConnectionEdge'
    node?: { __typename?: 'User'; databaseId: number } | null
  } | null
  certificationUserConnection?: {
    __typename?: 'CertificationToUserConnection'
    edges?: Array<{
      __typename?: 'CertificationToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        name?: string | null
        databaseId: number
      } | null
    } | null> | null
  } | null
  trainingTypes?: {
    __typename?: 'CertificationToTrainingTypeConnection'
    edges?: Array<{
      __typename?: 'CertificationToTrainingTypeConnectionEdge'
      node?: {
        __typename?: 'TrainingType'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  providers?: {
    __typename?: 'CertificationToProviderConnection'
    edges?: Array<{
      __typename?: 'CertificationToProviderConnectionEdge'
      node?: {
        __typename?: 'Provider'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  fileAttachments?: {
    __typename?: 'CertificationToMediaItemConnection'
    edges?: Array<{
      __typename?: 'CertificationToMediaItemConnectionEdge'
      node?: {
        __typename?: 'MediaItem'
        id: string
        title?: string | null
        description?: string | null
        parentDatabaseId?: number | null
        mediaItemUrl?: string | null
        sizes?: string | null
        databaseId: number
        sourceUrl?: string | null
        mediaType?: string | null
      } | null
    } | null> | null
  } | null
}

export type Certifications_FragFragment = {
  __typename?: 'Certification'
  id: string
  databaseId: number
  title?: string | null
  certificationDetails?: {
    __typename?: 'Certification_Certificationdetails'
    completedDate?: string | null
    expirationDate?: string | null
  } | null
  certificationUserConnection?: {
    __typename?: 'CertificationToUserConnection'
    edges?: Array<{
      __typename?: 'CertificationToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        name?: string | null
        databaseId: number
      } | null
    } | null> | null
  } | null
  trainingTypes?: {
    __typename?: 'CertificationToTrainingTypeConnection'
    edges?: Array<{
      __typename?: 'CertificationToTrainingTypeConnectionEdge'
      node?: {
        __typename?: 'TrainingType'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  fileAttachments?: {
    __typename?: 'CertificationToMediaItemConnection'
    edges?: Array<{
      __typename?: 'CertificationToMediaItemConnectionEdge'
      node?: { __typename?: 'MediaItem'; id: string } | null
    } | null> | null
  } | null
}

export type CreateCertificationMutationVariables = Exact<{
  input: CreateCertificationInput
}>

export type CreateCertificationMutation = {
  __typename?: 'RootMutation'
  createCertification?: {
    __typename?: 'CreateCertificationPayload'
    certification?: {
      __typename?: 'Certification'
      id: string
      databaseId: number
      title?: string | null
      content?: string | null
      certificationDetails?: {
        __typename?: 'Certification_Certificationdetails'
        completedDate?: string | null
        expirationDate?: string | null
      } | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: { __typename?: 'User'; databaseId: number } | null
      } | null
      certificationUserConnection?: {
        __typename?: 'CertificationToUserConnection'
        edges?: Array<{
          __typename?: 'CertificationToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            name?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      trainingTypes?: {
        __typename?: 'CertificationToTrainingTypeConnection'
        edges?: Array<{
          __typename?: 'CertificationToTrainingTypeConnectionEdge'
          node?: {
            __typename?: 'TrainingType'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      providers?: {
        __typename?: 'CertificationToProviderConnection'
        edges?: Array<{
          __typename?: 'CertificationToProviderConnectionEdge'
          node?: {
            __typename?: 'Provider'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'CertificationToMediaItemConnection'
        edges?: Array<{
          __typename?: 'CertificationToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            parentDatabaseId?: number | null
            mediaItemUrl?: string | null
            sizes?: string | null
            databaseId: number
            sourceUrl?: string | null
            mediaType?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type DeleteCertificaitonMutationVariables = Exact<{
  input: DeleteCertificationInput
}>

export type DeleteCertificaitonMutation = {
  __typename?: 'RootMutation'
  deleteCertification?: {
    __typename?: 'DeleteCertificationPayload'
    deletedId?: string | null
    certification?: {
      __typename?: 'Certification'
      id: string
      databaseId: number
      title?: string | null
      content?: string | null
      certificationDetails?: {
        __typename?: 'Certification_Certificationdetails'
        completedDate?: string | null
        expirationDate?: string | null
      } | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: { __typename?: 'User'; databaseId: number } | null
      } | null
      certificationUserConnection?: {
        __typename?: 'CertificationToUserConnection'
        edges?: Array<{
          __typename?: 'CertificationToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            name?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      trainingTypes?: {
        __typename?: 'CertificationToTrainingTypeConnection'
        edges?: Array<{
          __typename?: 'CertificationToTrainingTypeConnectionEdge'
          node?: {
            __typename?: 'TrainingType'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      providers?: {
        __typename?: 'CertificationToProviderConnection'
        edges?: Array<{
          __typename?: 'CertificationToProviderConnectionEdge'
          node?: {
            __typename?: 'Provider'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'CertificationToMediaItemConnection'
        edges?: Array<{
          __typename?: 'CertificationToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            parentDatabaseId?: number | null
            mediaItemUrl?: string | null
            sizes?: string | null
            databaseId: number
            sourceUrl?: string | null
            mediaType?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type CreateCertificationExpiryWarningMutationVariables = Exact<{
  input: CreateCertificationExpiryWarningInput
}>

export type CreateCertificationExpiryWarningMutation = {
  __typename?: 'RootMutation'
  createCertificationExpiryWarning?: {
    __typename?: 'CreateCertificationExpiryWarningPayload'
    certificationExpiryWarningRule?: {
      __typename?: 'CertificationExpiryWarningRule'
      databaseId?: number | null
    } | null
  } | null
}

export type DeleteCertificationExpiryWarningMutationVariables = Exact<{
  input: DeleteCertificationExpiryWarningInput
}>

export type DeleteCertificationExpiryWarningMutation = {
  __typename?: 'RootMutation'
  deleteCertificationExpiryWarning?: {
    __typename?: 'DeleteCertificationExpiryWarningPayload'
    success?: boolean | null
  } | null
}

export type UpdateCertificationExpiryWarningMutationVariables = Exact<{
  input: UpdateCertificationExpiryWarningInput
}>

export type UpdateCertificationExpiryWarningMutation = {
  __typename?: 'RootMutation'
  updateCertificationExpiryWarning?: {
    __typename?: 'UpdateCertificationExpiryWarningPayload'
    certificationExpiryWarningRule?: {
      __typename?: 'CertificationExpiryWarningRule'
      databaseId?: number | null
    } | null
  } | null
}

export type UpdateCertificationMutationVariables = Exact<{
  input: UpdateCertificationInput
}>

export type UpdateCertificationMutation = {
  __typename?: 'RootMutation'
  updateCertification?: {
    __typename?: 'UpdateCertificationPayload'
    certification?: {
      __typename?: 'Certification'
      id: string
      databaseId: number
      title?: string | null
      content?: string | null
      certificationDetails?: {
        __typename?: 'Certification_Certificationdetails'
        completedDate?: string | null
        expirationDate?: string | null
      } | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: { __typename?: 'User'; databaseId: number } | null
      } | null
      certificationUserConnection?: {
        __typename?: 'CertificationToUserConnection'
        edges?: Array<{
          __typename?: 'CertificationToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            name?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      trainingTypes?: {
        __typename?: 'CertificationToTrainingTypeConnection'
        edges?: Array<{
          __typename?: 'CertificationToTrainingTypeConnectionEdge'
          node?: {
            __typename?: 'TrainingType'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      providers?: {
        __typename?: 'CertificationToProviderConnection'
        edges?: Array<{
          __typename?: 'CertificationToProviderConnectionEdge'
          node?: {
            __typename?: 'Provider'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'CertificationToMediaItemConnection'
        edges?: Array<{
          __typename?: 'CertificationToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            parentDatabaseId?: number | null
            mediaItemUrl?: string | null
            sizes?: string | null
            databaseId: number
            sourceUrl?: string | null
            mediaType?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type AllCertificationsQueryVariables = Exact<{
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToCertificationConnectionWhereArgs>
}>

export type AllCertificationsQuery = {
  __typename?: 'RootQuery'
  certifications?: {
    __typename?: 'RootQueryToCertificationConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToCertificationConnectionEdge'
      cursor?: string | null
      node?: {
        __typename?: 'Certification'
        id: string
        databaseId: number
        title?: string | null
        content?: string | null
        certificationDetails?: {
          __typename?: 'Certification_Certificationdetails'
          completedDate?: string | null
          expirationDate?: string | null
        } | null
        author?: {
          __typename?: 'NodeWithAuthorToUserConnectionEdge'
          node?: { __typename?: 'User'; databaseId: number } | null
        } | null
        certificationUserConnection?: {
          __typename?: 'CertificationToUserConnection'
          edges?: Array<{
            __typename?: 'CertificationToUserConnectionEdge'
            node?: {
              __typename?: 'User'
              name?: string | null
              databaseId: number
            } | null
          } | null> | null
        } | null
        trainingTypes?: {
          __typename?: 'CertificationToTrainingTypeConnection'
          edges?: Array<{
            __typename?: 'CertificationToTrainingTypeConnectionEdge'
            node?: {
              __typename?: 'TrainingType'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        providers?: {
          __typename?: 'CertificationToProviderConnection'
          edges?: Array<{
            __typename?: 'CertificationToProviderConnectionEdge'
            node?: {
              __typename?: 'Provider'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        fileAttachments?: {
          __typename?: 'CertificationToMediaItemConnection'
          edges?: Array<{
            __typename?: 'CertificationToMediaItemConnectionEdge'
            node?: {
              __typename?: 'MediaItem'
              id: string
              title?: string | null
              description?: string | null
              parentDatabaseId?: number | null
              mediaItemUrl?: string | null
              sizes?: string | null
              databaseId: number
              sourceUrl?: string | null
              mediaType?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type AllExpirationWarningsQueryVariables = Exact<{
  [key: string]: never
}>

export type AllExpirationWarningsQuery = {
  __typename?: 'RootQuery'
  certificationExpiryWarnings?: Array<{
    __typename?: 'CertificationExpiryWarningRule'
    databaseId?: number | null
    dateModified?: string | null
    dateCreated?: string | null
    alertWindow?: number | null
    lastEditedBy?: {
      __typename?: 'CertificationExpiryWarningRuleToLastEditedByConnectionEdge'
      node?: {
        __typename?: 'User'
        name?: string | null
        email?: string | null
        databaseId: number
      } | null
    } | null
    companies?: {
      __typename?: 'CertificationExpiryWarningRuleToCompanyConnection'
      edges?: Array<{
        __typename?: 'CertificationExpiryWarningRuleToCompanyConnectionEdge'
        node?: {
          __typename?: 'Company'
          termTaxonomyId?: number | null
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    externalContacts?: Array<{
      __typename?: 'ExternalContact'
      name?: string | null
      email?: string | null
    } | null> | null
    internalContacts?: {
      __typename?: 'CertificationExpiryWarningRuleToUserConnection'
      edges?: Array<{
        __typename?: 'CertificationExpiryWarningRuleToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
          email?: string | null
        } | null
      } | null> | null
    } | null
  } | null> | null
}

export type GetCertificationQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
}>

export type GetCertificationQuery = {
  __typename?: 'RootQuery'
  certification?: {
    __typename?: 'Certification'
    id: string
    databaseId: number
    title?: string | null
    content?: string | null
    certificationDetails?: {
      __typename?: 'Certification_Certificationdetails'
      completedDate?: string | null
      expirationDate?: string | null
    } | null
    author?: {
      __typename?: 'NodeWithAuthorToUserConnectionEdge'
      node?: { __typename?: 'User'; databaseId: number } | null
    } | null
    certificationUserConnection?: {
      __typename?: 'CertificationToUserConnection'
      edges?: Array<{
        __typename?: 'CertificationToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          name?: string | null
          databaseId: number
        } | null
      } | null> | null
    } | null
    trainingTypes?: {
      __typename?: 'CertificationToTrainingTypeConnection'
      edges?: Array<{
        __typename?: 'CertificationToTrainingTypeConnectionEdge'
        node?: {
          __typename?: 'TrainingType'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    providers?: {
      __typename?: 'CertificationToProviderConnection'
      edges?: Array<{
        __typename?: 'CertificationToProviderConnectionEdge'
        node?: {
          __typename?: 'Provider'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    fileAttachments?: {
      __typename?: 'CertificationToMediaItemConnection'
      edges?: Array<{
        __typename?: 'CertificationToMediaItemConnectionEdge'
        node?: {
          __typename?: 'MediaItem'
          id: string
          title?: string | null
          description?: string | null
          parentDatabaseId?: number | null
          mediaItemUrl?: string | null
          sizes?: string | null
          databaseId: number
          sourceUrl?: string | null
          mediaType?: string | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type Corrective_Action_FragFragment = {
  __typename?: 'CorrectiveAction'
  id: string
  databaseId: number
  title?: string | null
  date?: string | null
  dueDate?: string | null
  dateCompleted?: string | null
  actionTaken?: string | null
  deficiencyFieldLabels?: Array<string | null> | null
  link?: string | null
  author?: {
    __typename?: 'NodeWithAuthorToUserConnectionEdge'
    node?: {
      __typename?: 'User'
      name?: string | null
      databaseId: number
      email?: string | null
    } | null
  } | null
  companies?: {
    __typename?: 'CorrectiveActionToCompanyConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToCompanyConnectionEdge'
      node?: { __typename?: 'Company'; name?: string | null } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'CorrectiveActionToDivisionConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToDivisionConnectionEdge'
      node?: { __typename?: 'Division'; name?: string | null } | null
    } | null> | null
  } | null
  correctiveActionPhotoConnection?: {
    __typename?: 'CorrectiveActionToPhotoConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToPhotoConnectionEdge'
      node?: {
        __typename?: 'Photo'
        id: string
        title?: string | null
        databaseId: number
        featuredImage?: {
          __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            sourceUrl?: string | null
            mediaDetails?: {
              __typename?: 'MediaDetails'
              sizes?: Array<{
                __typename?: 'MediaSize'
                sourceUrl?: string | null
                width?: string | null
              } | null> | null
            } | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
  projectCorrectiveActionConnection?: {
    __typename?: 'CorrectiveActionToProjectConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToProjectConnectionEdge'
      node?: {
        __typename?: 'Project'
        databaseId: number
        title?: string | null
        number?: string | null
      } | null
    } | null> | null
  } | null
  correctiveActionAssignedWorkersConnection?: {
    __typename?: 'CorrectiveActionToUserConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        databaseId: number
        name?: string | null
      } | null
    } | null> | null
  } | null
  correctiveActionDeficiencyOwnersConnection?: {
    __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
      node?: {
        __typename?: 'User'
        databaseId: number
        name?: string | null
      } | null
    } | null> | null
  } | null
  entryIds?: {
    __typename?: 'CorrectiveActionToEntryIdConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToEntryIdConnectionEdge'
      node?: { __typename?: 'EntryId'; slug?: string | null } | null
    } | null> | null
  } | null
  formIds?: {
    __typename?: 'CorrectiveActionToFormIdConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToFormIdConnectionEdge'
      node?: {
        __typename?: 'FormId'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  form?: {
    __typename?: 'GfForm'
    slug?: string | null
    title?: string | null
    databaseId: number
    id: string
  } | null
  entry?:
    | { __typename?: 'GfDraftEntry'; dateCreated?: string | null }
    | {
        __typename?: 'GfSubmittedEntry'
        databaseId: number
        dateCreated?: string | null
      }
    | null
  fieldIds?: {
    __typename?: 'CorrectiveActionToFieldIdConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToFieldIdConnectionEdge'
      node?: { __typename?: 'FieldId'; slug?: string | null } | null
    } | null> | null
  } | null
  correctiveActionStatuses?: {
    __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
      node?: {
        __typename?: 'CorrectiveActionStatus'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  severities?: {
    __typename?: 'CorrectiveActionToSeverityConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToSeverityConnectionEdge'
      node?: {
        __typename?: 'Severity'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  deficiencyTypes?: {
    __typename?: 'CorrectiveActionToDeficiencyTypeConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToDeficiencyTypeConnectionEdge'
      node?: {
        __typename?: 'DeficiencyType'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
}

export type Corrective_Actions_Report_FragFragment = {
  __typename?: 'CorrectiveAction'
  id: string
  databaseId: number
  date?: string | null
  companies?: {
    __typename?: 'CorrectiveActionToCompanyConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToCompanyConnectionEdge'
      node?: { __typename?: 'Company'; name?: string | null } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'CorrectiveActionToDivisionConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToDivisionConnectionEdge'
      node?: { __typename?: 'Division'; name?: string | null } | null
    } | null> | null
  } | null
  projectCorrectiveActionConnection?: {
    __typename?: 'CorrectiveActionToProjectConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToProjectConnectionEdge'
      node?: {
        __typename?: 'Project'
        databaseId: number
        title?: string | null
        number?: string | null
      } | null
    } | null> | null
  } | null
  correctiveActionStatuses?: {
    __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
      node?: {
        __typename?: 'CorrectiveActionStatus'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  severities?: {
    __typename?: 'CorrectiveActionToSeverityConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToSeverityConnectionEdge'
      node?: {
        __typename?: 'Severity'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  deficiencyTypes?: {
    __typename?: 'CorrectiveActionToDeficiencyTypeConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToDeficiencyTypeConnectionEdge'
      node?: {
        __typename?: 'DeficiencyType'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
}

export type Corrective_Actions_FragFragment = {
  __typename?: 'CorrectiveAction'
  id: string
  databaseId: number
  title?: string | null
  date?: string | null
  dueDate?: string | null
  dateCompleted?: string | null
  actionTaken?: string | null
  link?: string | null
  projectCorrectiveActionConnection?: {
    __typename?: 'CorrectiveActionToProjectConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToProjectConnectionEdge'
      node?: {
        __typename?: 'Project'
        number?: string | null
        id: string
        databaseId: number
      } | null
    } | null> | null
  } | null
  correctiveActionStatuses?: {
    __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
      node?: {
        __typename?: 'CorrectiveActionStatus'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  severities?: {
    __typename?: 'CorrectiveActionToSeverityConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToSeverityConnectionEdge'
      node?: {
        __typename?: 'Severity'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  deficiencyTypes?: {
    __typename?: 'CorrectiveActionToDeficiencyTypeConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToDeficiencyTypeConnectionEdge'
      node?: {
        __typename?: 'DeficiencyType'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  correctiveActionAssignedWorkersConnection?: {
    __typename?: 'CorrectiveActionToUserConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        databaseId: number
        name?: string | null
      } | null
    } | null> | null
  } | null
  correctiveActionDeficiencyOwnersConnection?: {
    __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection'
    edges?: Array<{
      __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
      node?: {
        __typename?: 'User'
        databaseId: number
        name?: string | null
      } | null
    } | null> | null
  } | null
}

export type CreateCorrectiveActionPdfsMutationVariables = Exact<{
  databaseIds:
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
}>

export type CreateCorrectiveActionPdfsMutation = {
  __typename?: 'RootMutation'
  createCorrectiveActionPdfs?: {
    __typename?: 'CreateCorrectiveActionPdfsPayload'
    pdfs: Array<{
      __typename?: 'Pdf'
      pdfUrl?: string | null
      dateCreated?: string | null
    } | null>
  } | null
}

export type DeleteCorrectiveActionMutationVariables = Exact<{
  input: DeleteCorrectiveActionInput
}>

export type DeleteCorrectiveActionMutation = {
  __typename?: 'RootMutation'
  deleteCorrectiveAction?: {
    __typename?: 'DeleteCorrectiveActionPayload'
    deletedId?: string | null
    correctiveAction?: {
      __typename?: 'CorrectiveAction'
      id: string
      databaseId: number
      title?: string | null
      date?: string | null
      dueDate?: string | null
      dateCompleted?: string | null
      actionTaken?: string | null
      deficiencyFieldLabels?: Array<string | null> | null
      link?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          name?: string | null
          databaseId: number
          email?: string | null
        } | null
      } | null
      companies?: {
        __typename?: 'CorrectiveActionToCompanyConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToCompanyConnectionEdge'
          node?: { __typename?: 'Company'; name?: string | null } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'CorrectiveActionToDivisionConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToDivisionConnectionEdge'
          node?: { __typename?: 'Division'; name?: string | null } | null
        } | null> | null
      } | null
      correctiveActionPhotoConnection?: {
        __typename?: 'CorrectiveActionToPhotoConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToPhotoConnectionEdge'
          node?: {
            __typename?: 'Photo'
            id: string
            title?: string | null
            databaseId: number
            featuredImage?: {
              __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
              node?: {
                __typename?: 'MediaItem'
                sourceUrl?: string | null
                mediaDetails?: {
                  __typename?: 'MediaDetails'
                  sizes?: Array<{
                    __typename?: 'MediaSize'
                    sourceUrl?: string | null
                    width?: string | null
                  } | null> | null
                } | null
              } | null
            } | null
          } | null
        } | null> | null
      } | null
      projectCorrectiveActionConnection?: {
        __typename?: 'CorrectiveActionToProjectConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToProjectConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      correctiveActionAssignedWorkersConnection?: {
        __typename?: 'CorrectiveActionToUserConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      correctiveActionDeficiencyOwnersConnection?: {
        __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
          node?: {
            __typename?: 'User'
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      entryIds?: {
        __typename?: 'CorrectiveActionToEntryIdConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToEntryIdConnectionEdge'
          node?: { __typename?: 'EntryId'; slug?: string | null } | null
        } | null> | null
      } | null
      formIds?: {
        __typename?: 'CorrectiveActionToFormIdConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToFormIdConnectionEdge'
          node?: {
            __typename?: 'FormId'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      form?: {
        __typename?: 'GfForm'
        slug?: string | null
        title?: string | null
        databaseId: number
        id: string
      } | null
      entry?:
        | { __typename?: 'GfDraftEntry'; dateCreated?: string | null }
        | {
            __typename?: 'GfSubmittedEntry'
            databaseId: number
            dateCreated?: string | null
          }
        | null
      fieldIds?: {
        __typename?: 'CorrectiveActionToFieldIdConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToFieldIdConnectionEdge'
          node?: { __typename?: 'FieldId'; slug?: string | null } | null
        } | null> | null
      } | null
      correctiveActionStatuses?: {
        __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
          node?: {
            __typename?: 'CorrectiveActionStatus'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      severities?: {
        __typename?: 'CorrectiveActionToSeverityConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToSeverityConnectionEdge'
          node?: {
            __typename?: 'Severity'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      deficiencyTypes?: {
        __typename?: 'CorrectiveActionToDeficiencyTypeConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToDeficiencyTypeConnectionEdge'
          node?: {
            __typename?: 'DeficiencyType'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type UpdateCorrectiveActionMutationVariables = Exact<{
  input: UpdateCorrectiveActionInput
}>

export type UpdateCorrectiveActionMutation = {
  __typename?: 'RootMutation'
  updateCorrectiveAction?: {
    __typename?: 'UpdateCorrectiveActionPayload'
    correctiveAction?: {
      __typename?: 'CorrectiveAction'
      id: string
      databaseId: number
      title?: string | null
      date?: string | null
      dueDate?: string | null
      dateCompleted?: string | null
      actionTaken?: string | null
      deficiencyFieldLabels?: Array<string | null> | null
      link?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          name?: string | null
          databaseId: number
          email?: string | null
        } | null
      } | null
      companies?: {
        __typename?: 'CorrectiveActionToCompanyConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToCompanyConnectionEdge'
          node?: { __typename?: 'Company'; name?: string | null } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'CorrectiveActionToDivisionConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToDivisionConnectionEdge'
          node?: { __typename?: 'Division'; name?: string | null } | null
        } | null> | null
      } | null
      correctiveActionPhotoConnection?: {
        __typename?: 'CorrectiveActionToPhotoConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToPhotoConnectionEdge'
          node?: {
            __typename?: 'Photo'
            id: string
            title?: string | null
            databaseId: number
            featuredImage?: {
              __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
              node?: {
                __typename?: 'MediaItem'
                sourceUrl?: string | null
                mediaDetails?: {
                  __typename?: 'MediaDetails'
                  sizes?: Array<{
                    __typename?: 'MediaSize'
                    sourceUrl?: string | null
                    width?: string | null
                  } | null> | null
                } | null
              } | null
            } | null
          } | null
        } | null> | null
      } | null
      projectCorrectiveActionConnection?: {
        __typename?: 'CorrectiveActionToProjectConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToProjectConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      correctiveActionAssignedWorkersConnection?: {
        __typename?: 'CorrectiveActionToUserConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      correctiveActionDeficiencyOwnersConnection?: {
        __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
          node?: {
            __typename?: 'User'
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      entryIds?: {
        __typename?: 'CorrectiveActionToEntryIdConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToEntryIdConnectionEdge'
          node?: { __typename?: 'EntryId'; slug?: string | null } | null
        } | null> | null
      } | null
      formIds?: {
        __typename?: 'CorrectiveActionToFormIdConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToFormIdConnectionEdge'
          node?: {
            __typename?: 'FormId'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      form?: {
        __typename?: 'GfForm'
        slug?: string | null
        title?: string | null
        databaseId: number
        id: string
      } | null
      entry?:
        | { __typename?: 'GfDraftEntry'; dateCreated?: string | null }
        | {
            __typename?: 'GfSubmittedEntry'
            databaseId: number
            dateCreated?: string | null
          }
        | null
      fieldIds?: {
        __typename?: 'CorrectiveActionToFieldIdConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToFieldIdConnectionEdge'
          node?: { __typename?: 'FieldId'; slug?: string | null } | null
        } | null> | null
      } | null
      correctiveActionStatuses?: {
        __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
          node?: {
            __typename?: 'CorrectiveActionStatus'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      severities?: {
        __typename?: 'CorrectiveActionToSeverityConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToSeverityConnectionEdge'
          node?: {
            __typename?: 'Severity'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      deficiencyTypes?: {
        __typename?: 'CorrectiveActionToDeficiencyTypeConnection'
        edges?: Array<{
          __typename?: 'CorrectiveActionToDeficiencyTypeConnectionEdge'
          node?: {
            __typename?: 'DeficiencyType'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type AllCorrectiveActionsQueryVariables = Exact<{
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToCorrectiveActionConnectionWhereArgs>
}>

export type AllCorrectiveActionsQuery = {
  __typename?: 'RootQuery'
  correctiveActions?: {
    __typename?: 'RootQueryToCorrectiveActionConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToCorrectiveActionConnectionEdge'
      cursor?: string | null
      node?: {
        __typename?: 'CorrectiveAction'
        id: string
        databaseId: number
        title?: string | null
        date?: string | null
        dueDate?: string | null
        dateCompleted?: string | null
        actionTaken?: string | null
        link?: string | null
        projectCorrectiveActionConnection?: {
          __typename?: 'CorrectiveActionToProjectConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToProjectConnectionEdge'
            node?: {
              __typename?: 'Project'
              number?: string | null
              id: string
              databaseId: number
            } | null
          } | null> | null
        } | null
        correctiveActionStatuses?: {
          __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
            node?: {
              __typename?: 'CorrectiveActionStatus'
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
        severities?: {
          __typename?: 'CorrectiveActionToSeverityConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToSeverityConnectionEdge'
            node?: {
              __typename?: 'Severity'
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
        deficiencyTypes?: {
          __typename?: 'CorrectiveActionToDeficiencyTypeConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToDeficiencyTypeConnectionEdge'
            node?: {
              __typename?: 'DeficiencyType'
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
        correctiveActionAssignedWorkersConnection?: {
          __typename?: 'CorrectiveActionToUserConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToUserConnectionEdge'
            node?: {
              __typename?: 'User'
              databaseId: number
              name?: string | null
            } | null
          } | null> | null
        } | null
        correctiveActionDeficiencyOwnersConnection?: {
          __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
            node?: {
              __typename?: 'User'
              databaseId: number
              name?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type GetCorrectiveActionQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
}>

export type GetCorrectiveActionQuery = {
  __typename?: 'RootQuery'
  correctiveAction?: {
    __typename?: 'CorrectiveAction'
    id: string
    databaseId: number
    title?: string | null
    date?: string | null
    dueDate?: string | null
    dateCompleted?: string | null
    actionTaken?: string | null
    deficiencyFieldLabels?: Array<string | null> | null
    link?: string | null
    author?: {
      __typename?: 'NodeWithAuthorToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        name?: string | null
        databaseId: number
        email?: string | null
      } | null
    } | null
    companies?: {
      __typename?: 'CorrectiveActionToCompanyConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToCompanyConnectionEdge'
        node?: { __typename?: 'Company'; name?: string | null } | null
      } | null> | null
    } | null
    divisions?: {
      __typename?: 'CorrectiveActionToDivisionConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToDivisionConnectionEdge'
        node?: { __typename?: 'Division'; name?: string | null } | null
      } | null> | null
    } | null
    correctiveActionPhotoConnection?: {
      __typename?: 'CorrectiveActionToPhotoConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToPhotoConnectionEdge'
        node?: {
          __typename?: 'Photo'
          id: string
          title?: string | null
          databaseId: number
          featuredImage?: {
            __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
            node?: {
              __typename?: 'MediaItem'
              sourceUrl?: string | null
              mediaDetails?: {
                __typename?: 'MediaDetails'
                sizes?: Array<{
                  __typename?: 'MediaSize'
                  sourceUrl?: string | null
                  width?: string | null
                } | null> | null
              } | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
    projectCorrectiveActionConnection?: {
      __typename?: 'CorrectiveActionToProjectConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToProjectConnectionEdge'
        node?: {
          __typename?: 'Project'
          databaseId: number
          title?: string | null
          number?: string | null
        } | null
      } | null> | null
    } | null
    correctiveActionAssignedWorkersConnection?: {
      __typename?: 'CorrectiveActionToUserConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null> | null
    } | null
    correctiveActionDeficiencyOwnersConnection?: {
      __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null> | null
    } | null
    entryIds?: {
      __typename?: 'CorrectiveActionToEntryIdConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToEntryIdConnectionEdge'
        node?: { __typename?: 'EntryId'; slug?: string | null } | null
      } | null> | null
    } | null
    formIds?: {
      __typename?: 'CorrectiveActionToFormIdConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToFormIdConnectionEdge'
        node?: {
          __typename?: 'FormId'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    form?: {
      __typename?: 'GfForm'
      slug?: string | null
      title?: string | null
      databaseId: number
      id: string
    } | null
    entry?:
      | { __typename?: 'GfDraftEntry'; dateCreated?: string | null }
      | {
          __typename?: 'GfSubmittedEntry'
          databaseId: number
          dateCreated?: string | null
        }
      | null
    fieldIds?: {
      __typename?: 'CorrectiveActionToFieldIdConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToFieldIdConnectionEdge'
        node?: { __typename?: 'FieldId'; slug?: string | null } | null
      } | null> | null
    } | null
    correctiveActionStatuses?: {
      __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
        node?: {
          __typename?: 'CorrectiveActionStatus'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    severities?: {
      __typename?: 'CorrectiveActionToSeverityConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToSeverityConnectionEdge'
        node?: {
          __typename?: 'Severity'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    deficiencyTypes?: {
      __typename?: 'CorrectiveActionToDeficiencyTypeConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToDeficiencyTypeConnectionEdge'
        node?: {
          __typename?: 'DeficiencyType'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type GetCorrectiveActionBasicQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
}>

export type GetCorrectiveActionBasicQuery = {
  __typename?: 'RootQuery'
  correctiveAction?: {
    __typename?: 'CorrectiveAction'
    id: string
    databaseId: number
    title?: string | null
    date?: string | null
    dueDate?: string | null
    dateCompleted?: string | null
    actionTaken?: string | null
    link?: string | null
    projectCorrectiveActionConnection?: {
      __typename?: 'CorrectiveActionToProjectConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToProjectConnectionEdge'
        node?: {
          __typename?: 'Project'
          number?: string | null
          id: string
          databaseId: number
        } | null
      } | null> | null
    } | null
    correctiveActionStatuses?: {
      __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
        node?: {
          __typename?: 'CorrectiveActionStatus'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    severities?: {
      __typename?: 'CorrectiveActionToSeverityConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToSeverityConnectionEdge'
        node?: {
          __typename?: 'Severity'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    deficiencyTypes?: {
      __typename?: 'CorrectiveActionToDeficiencyTypeConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToDeficiencyTypeConnectionEdge'
        node?: {
          __typename?: 'DeficiencyType'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    correctiveActionAssignedWorkersConnection?: {
      __typename?: 'CorrectiveActionToUserConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null> | null
    } | null
    correctiveActionDeficiencyOwnersConnection?: {
      __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type GetCorrectiveActionPdfFilesQueryVariables = Exact<{
  correctiveActionDatabaseIds:
    | Array<Scalars['ID']['input']>
    | Scalars['ID']['input']
}>

export type GetCorrectiveActionPdfFilesQuery = {
  __typename?: 'RootQuery'
  mediaItems?: {
    __typename?: 'RootQueryToMediaItemConnection'
    nodes?: Array<{
      __typename?: 'MediaItem'
      title?: string | null
      mediaItemUrl?: string | null
      mimeType?: string | null
      fileSize?: number | null
      date?: string | null
      databaseId: number
    } | null> | null
  } | null
}

export type GetCorrectiveActionWithPdfQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
}>

export type GetCorrectiveActionWithPdfQuery = {
  __typename?: 'RootQuery'
  correctiveAction?: {
    __typename?: 'CorrectiveAction'
    id: string
    databaseId: number
    title?: string | null
    date?: string | null
    dueDate?: string | null
    dateCompleted?: string | null
    actionTaken?: string | null
    deficiencyFieldLabels?: Array<string | null> | null
    link?: string | null
    author?: {
      __typename?: 'NodeWithAuthorToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        name?: string | null
        databaseId: number
        email?: string | null
      } | null
    } | null
    companies?: {
      __typename?: 'CorrectiveActionToCompanyConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToCompanyConnectionEdge'
        node?: { __typename?: 'Company'; name?: string | null } | null
      } | null> | null
    } | null
    divisions?: {
      __typename?: 'CorrectiveActionToDivisionConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToDivisionConnectionEdge'
        node?: { __typename?: 'Division'; name?: string | null } | null
      } | null> | null
    } | null
    correctiveActionPhotoConnection?: {
      __typename?: 'CorrectiveActionToPhotoConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToPhotoConnectionEdge'
        node?: {
          __typename?: 'Photo'
          id: string
          title?: string | null
          databaseId: number
          featuredImage?: {
            __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
            node?: {
              __typename?: 'MediaItem'
              sourceUrl?: string | null
              mediaDetails?: {
                __typename?: 'MediaDetails'
                sizes?: Array<{
                  __typename?: 'MediaSize'
                  sourceUrl?: string | null
                  width?: string | null
                } | null> | null
              } | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
    projectCorrectiveActionConnection?: {
      __typename?: 'CorrectiveActionToProjectConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToProjectConnectionEdge'
        node?: {
          __typename?: 'Project'
          databaseId: number
          title?: string | null
          number?: string | null
        } | null
      } | null> | null
    } | null
    correctiveActionAssignedWorkersConnection?: {
      __typename?: 'CorrectiveActionToUserConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null> | null
    } | null
    correctiveActionDeficiencyOwnersConnection?: {
      __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null> | null
    } | null
    entryIds?: {
      __typename?: 'CorrectiveActionToEntryIdConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToEntryIdConnectionEdge'
        node?: { __typename?: 'EntryId'; slug?: string | null } | null
      } | null> | null
    } | null
    formIds?: {
      __typename?: 'CorrectiveActionToFormIdConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToFormIdConnectionEdge'
        node?: {
          __typename?: 'FormId'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    form?: {
      __typename?: 'GfForm'
      slug?: string | null
      title?: string | null
      databaseId: number
      id: string
    } | null
    entry?:
      | { __typename?: 'GfDraftEntry'; dateCreated?: string | null }
      | {
          __typename?: 'GfSubmittedEntry'
          databaseId: number
          dateCreated?: string | null
        }
      | null
    fieldIds?: {
      __typename?: 'CorrectiveActionToFieldIdConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToFieldIdConnectionEdge'
        node?: { __typename?: 'FieldId'; slug?: string | null } | null
      } | null> | null
    } | null
    correctiveActionStatuses?: {
      __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
        node?: {
          __typename?: 'CorrectiveActionStatus'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    severities?: {
      __typename?: 'CorrectiveActionToSeverityConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToSeverityConnectionEdge'
        node?: {
          __typename?: 'Severity'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    deficiencyTypes?: {
      __typename?: 'CorrectiveActionToDeficiencyTypeConnection'
      edges?: Array<{
        __typename?: 'CorrectiveActionToDeficiencyTypeConnectionEdge'
        node?: {
          __typename?: 'DeficiencyType'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
  } | null
  pdfFiles?: {
    __typename?: 'RootQueryToMediaItemConnection'
    nodes?: Array<{
      __typename?: 'MediaItem'
      title?: string | null
      mediaItemUrl?: string | null
      mimeType?: string | null
      fileSize?: number | null
      date?: string | null
      databaseId: number
    } | null> | null
  } | null
}

export type CorrectiveActionsForReportingQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToCorrectiveActionConnectionWhereArgs>
}>

export type CorrectiveActionsForReportingQuery = {
  __typename?: 'RootQuery'
  correctiveActions?: {
    __typename?: 'RootQueryToCorrectiveActionConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToCorrectiveActionConnectionEdge'
      cursor?: string | null
      node?: {
        __typename?: 'CorrectiveAction'
        id: string
        databaseId: number
        date?: string | null
        companies?: {
          __typename?: 'CorrectiveActionToCompanyConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToCompanyConnectionEdge'
            node?: { __typename?: 'Company'; name?: string | null } | null
          } | null> | null
        } | null
        divisions?: {
          __typename?: 'CorrectiveActionToDivisionConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToDivisionConnectionEdge'
            node?: { __typename?: 'Division'; name?: string | null } | null
          } | null> | null
        } | null
        projectCorrectiveActionConnection?: {
          __typename?: 'CorrectiveActionToProjectConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToProjectConnectionEdge'
            node?: {
              __typename?: 'Project'
              databaseId: number
              title?: string | null
              number?: string | null
            } | null
          } | null> | null
        } | null
        correctiveActionStatuses?: {
          __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
            node?: {
              __typename?: 'CorrectiveActionStatus'
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
        severities?: {
          __typename?: 'CorrectiveActionToSeverityConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToSeverityConnectionEdge'
            node?: {
              __typename?: 'Severity'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        deficiencyTypes?: {
          __typename?: 'CorrectiveActionToDeficiencyTypeConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToDeficiencyTypeConnectionEdge'
            node?: {
              __typename?: 'DeficiencyType'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type Document_FragFragment = {
  __typename?: 'Document'
  databaseId: number
  id: string
  title?: string | null
  modified?: string | null
  content?: string | null
  author?: {
    __typename?: 'NodeWithAuthorToUserConnectionEdge'
    node?: {
      __typename?: 'User'
      databaseId: number
      name?: string | null
    } | null
  } | null
  companies?: {
    __typename?: 'DocumentToCompanyConnection'
    edges?: Array<{
      __typename?: 'DocumentToCompanyConnectionEdge'
      node?: {
        __typename?: 'Company'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'DocumentToDivisionConnection'
    edges?: Array<{
      __typename?: 'DocumentToDivisionConnectionEdge'
      node?: {
        __typename?: 'Division'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  documentTags?: {
    __typename?: 'DocumentToDocumentTagConnection'
    edges?: Array<{
      __typename?: 'DocumentToDocumentTagConnectionEdge'
      node?: {
        __typename?: 'DocumentTag'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  documentToProjectConnection?: {
    __typename?: 'DocumentToProjectConnection'
    edges?: Array<{
      __typename?: 'DocumentToProjectConnectionEdge'
      node?: {
        __typename?: 'Project'
        databaseId: number
        title?: string | null
        number?: string | null
      } | null
    } | null> | null
  } | null
  equipmentToDocumentConnection?: {
    __typename?: 'DocumentToEquipmentConnection'
    edges?: Array<{
      __typename?: 'DocumentToEquipmentConnectionEdge'
      node?: {
        __typename?: 'Equipment'
        databaseId: number
        title?: string | null
      } | null
    } | null> | null
  } | null
  fileAttachments?: {
    __typename?: 'DocumentToMediaItemConnection'
    edges?: Array<{
      __typename?: 'DocumentToMediaItemConnectionEdge'
      node?: {
        __typename?: 'MediaItem'
        id: string
        title?: string | null
        description?: string | null
        parentDatabaseId?: number | null
        mediaItemUrl?: string | null
      } | null
    } | null> | null
  } | null
}

export type Documents_FragFragment = {
  __typename?: 'Document'
  databaseId: number
  id: string
  title?: string | null
  documentTags?: {
    __typename?: 'DocumentToDocumentTagConnection'
    edges?: Array<{
      __typename?: 'DocumentToDocumentTagConnectionEdge'
      node?: {
        __typename?: 'DocumentTag'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
}

export type CreateDocumentMutationVariables = Exact<{
  input: CreateDocumentInput
}>

export type CreateDocumentMutation = {
  __typename?: 'RootMutation'
  createDocument?: {
    __typename?: 'CreateDocumentPayload'
    document?: {
      __typename?: 'Document'
      databaseId: number
      id: string
      title?: string | null
      modified?: string | null
      content?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      companies?: {
        __typename?: 'DocumentToCompanyConnection'
        edges?: Array<{
          __typename?: 'DocumentToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'DocumentToDivisionConnection'
        edges?: Array<{
          __typename?: 'DocumentToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      documentTags?: {
        __typename?: 'DocumentToDocumentTagConnection'
        edges?: Array<{
          __typename?: 'DocumentToDocumentTagConnectionEdge'
          node?: {
            __typename?: 'DocumentTag'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      documentToProjectConnection?: {
        __typename?: 'DocumentToProjectConnection'
        edges?: Array<{
          __typename?: 'DocumentToProjectConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      equipmentToDocumentConnection?: {
        __typename?: 'DocumentToEquipmentConnection'
        edges?: Array<{
          __typename?: 'DocumentToEquipmentConnectionEdge'
          node?: {
            __typename?: 'Equipment'
            databaseId: number
            title?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'DocumentToMediaItemConnection'
        edges?: Array<{
          __typename?: 'DocumentToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            parentDatabaseId?: number | null
            mediaItemUrl?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type DeleteDocumentMutationVariables = Exact<{
  input: DeleteDocumentInput
}>

export type DeleteDocumentMutation = {
  __typename?: 'RootMutation'
  deleteDocument?: {
    __typename?: 'DeleteDocumentPayload'
    deletedId?: string | null
    document?: {
      __typename?: 'Document'
      databaseId: number
      id: string
      title?: string | null
      modified?: string | null
      content?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      companies?: {
        __typename?: 'DocumentToCompanyConnection'
        edges?: Array<{
          __typename?: 'DocumentToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'DocumentToDivisionConnection'
        edges?: Array<{
          __typename?: 'DocumentToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      documentTags?: {
        __typename?: 'DocumentToDocumentTagConnection'
        edges?: Array<{
          __typename?: 'DocumentToDocumentTagConnectionEdge'
          node?: {
            __typename?: 'DocumentTag'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      documentToProjectConnection?: {
        __typename?: 'DocumentToProjectConnection'
        edges?: Array<{
          __typename?: 'DocumentToProjectConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      equipmentToDocumentConnection?: {
        __typename?: 'DocumentToEquipmentConnection'
        edges?: Array<{
          __typename?: 'DocumentToEquipmentConnectionEdge'
          node?: {
            __typename?: 'Equipment'
            databaseId: number
            title?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'DocumentToMediaItemConnection'
        edges?: Array<{
          __typename?: 'DocumentToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            parentDatabaseId?: number | null
            mediaItemUrl?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type UpdateDocumentMutationVariables = Exact<{
  input: UpdateDocumentInput
}>

export type UpdateDocumentMutation = {
  __typename?: 'RootMutation'
  updateDocument?: {
    __typename?: 'UpdateDocumentPayload'
    document?: {
      __typename?: 'Document'
      databaseId: number
      id: string
      title?: string | null
      modified?: string | null
      content?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      companies?: {
        __typename?: 'DocumentToCompanyConnection'
        edges?: Array<{
          __typename?: 'DocumentToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'DocumentToDivisionConnection'
        edges?: Array<{
          __typename?: 'DocumentToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      documentTags?: {
        __typename?: 'DocumentToDocumentTagConnection'
        edges?: Array<{
          __typename?: 'DocumentToDocumentTagConnectionEdge'
          node?: {
            __typename?: 'DocumentTag'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      documentToProjectConnection?: {
        __typename?: 'DocumentToProjectConnection'
        edges?: Array<{
          __typename?: 'DocumentToProjectConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      equipmentToDocumentConnection?: {
        __typename?: 'DocumentToEquipmentConnection'
        edges?: Array<{
          __typename?: 'DocumentToEquipmentConnectionEdge'
          node?: {
            __typename?: 'Equipment'
            databaseId: number
            title?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'DocumentToMediaItemConnection'
        edges?: Array<{
          __typename?: 'DocumentToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            parentDatabaseId?: number | null
            mediaItemUrl?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type AllDocumentsQueryVariables = Exact<{
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToDocumentConnectionWhereArgs>
}>

export type AllDocumentsQuery = {
  __typename?: 'RootQuery'
  documents?: {
    __typename?: 'RootQueryToDocumentConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToDocumentConnectionEdge'
      cursor?: string | null
      node?: {
        __typename?: 'Document'
        databaseId: number
        id: string
        title?: string | null
        modified?: string | null
        content?: string | null
        author?: {
          __typename?: 'NodeWithAuthorToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            databaseId: number
            name?: string | null
          } | null
        } | null
        companies?: {
          __typename?: 'DocumentToCompanyConnection'
          edges?: Array<{
            __typename?: 'DocumentToCompanyConnectionEdge'
            node?: {
              __typename?: 'Company'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        divisions?: {
          __typename?: 'DocumentToDivisionConnection'
          edges?: Array<{
            __typename?: 'DocumentToDivisionConnectionEdge'
            node?: {
              __typename?: 'Division'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        documentTags?: {
          __typename?: 'DocumentToDocumentTagConnection'
          edges?: Array<{
            __typename?: 'DocumentToDocumentTagConnectionEdge'
            node?: {
              __typename?: 'DocumentTag'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        documentToProjectConnection?: {
          __typename?: 'DocumentToProjectConnection'
          edges?: Array<{
            __typename?: 'DocumentToProjectConnectionEdge'
            node?: {
              __typename?: 'Project'
              databaseId: number
              title?: string | null
              number?: string | null
            } | null
          } | null> | null
        } | null
        equipmentToDocumentConnection?: {
          __typename?: 'DocumentToEquipmentConnection'
          edges?: Array<{
            __typename?: 'DocumentToEquipmentConnectionEdge'
            node?: {
              __typename?: 'Equipment'
              databaseId: number
              title?: string | null
            } | null
          } | null> | null
        } | null
        fileAttachments?: {
          __typename?: 'DocumentToMediaItemConnection'
          edges?: Array<{
            __typename?: 'DocumentToMediaItemConnectionEdge'
            node?: {
              __typename?: 'MediaItem'
              id: string
              title?: string | null
              description?: string | null
              parentDatabaseId?: number | null
              mediaItemUrl?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type GetDocumentQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
}>

export type GetDocumentQuery = {
  __typename?: 'RootQuery'
  document?: {
    __typename?: 'Document'
    databaseId: number
    id: string
    title?: string | null
    modified?: string | null
    content?: string | null
    author?: {
      __typename?: 'NodeWithAuthorToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        databaseId: number
        name?: string | null
      } | null
    } | null
    companies?: {
      __typename?: 'DocumentToCompanyConnection'
      edges?: Array<{
        __typename?: 'DocumentToCompanyConnectionEdge'
        node?: {
          __typename?: 'Company'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    divisions?: {
      __typename?: 'DocumentToDivisionConnection'
      edges?: Array<{
        __typename?: 'DocumentToDivisionConnectionEdge'
        node?: {
          __typename?: 'Division'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    documentTags?: {
      __typename?: 'DocumentToDocumentTagConnection'
      edges?: Array<{
        __typename?: 'DocumentToDocumentTagConnectionEdge'
        node?: {
          __typename?: 'DocumentTag'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    documentToProjectConnection?: {
      __typename?: 'DocumentToProjectConnection'
      edges?: Array<{
        __typename?: 'DocumentToProjectConnectionEdge'
        node?: {
          __typename?: 'Project'
          databaseId: number
          title?: string | null
          number?: string | null
        } | null
      } | null> | null
    } | null
    equipmentToDocumentConnection?: {
      __typename?: 'DocumentToEquipmentConnection'
      edges?: Array<{
        __typename?: 'DocumentToEquipmentConnectionEdge'
        node?: {
          __typename?: 'Equipment'
          databaseId: number
          title?: string | null
        } | null
      } | null> | null
    } | null
    fileAttachments?: {
      __typename?: 'DocumentToMediaItemConnection'
      edges?: Array<{
        __typename?: 'DocumentToMediaItemConnectionEdge'
        node?: {
          __typename?: 'MediaItem'
          id: string
          title?: string | null
          description?: string | null
          parentDatabaseId?: number | null
          mediaItemUrl?: string | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type Equipment_FragFragment = {
  __typename?: 'Equipment'
  databaseId: number
  id: string
  title?: string | null
  serialNumber?: string | null
  modelNumber?: string | null
  inServiceDate?: string | null
  lastInspectedDate?: string | null
  nextInspectionDate?: string | null
  integrationSource?: string | null
  author?: {
    __typename?: 'NodeWithAuthorToUserConnectionEdge'
    node?: {
      __typename?: 'User'
      databaseId: number
      name?: string | null
    } | null
  } | null
  equipmentStatuses?: {
    __typename?: 'EquipmentToEquipmentStatusConnection'
    edges?: Array<{
      __typename?: 'EquipmentToEquipmentStatusConnectionEdge'
      node?: {
        __typename?: 'EquipmentStatus'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  manufacturers?: {
    __typename?: 'EquipmentToManufacturerConnection'
    edges?: Array<{
      __typename?: 'EquipmentToManufacturerConnectionEdge'
      node?: {
        __typename?: 'Manufacturer'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  equipmentTags?: {
    __typename?: 'EquipmentToEquipmentTagConnection'
    edges?: Array<{
      __typename?: 'EquipmentToEquipmentTagConnectionEdge'
      node?: {
        __typename?: 'EquipmentTag'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  companies?: {
    __typename?: 'EquipmentToCompanyConnection'
    edges?: Array<{
      __typename?: 'EquipmentToCompanyConnectionEdge'
      node?: {
        __typename?: 'Company'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'EquipmentToDivisionConnection'
    edges?: Array<{
      __typename?: 'EquipmentToDivisionConnectionEdge'
      node?: {
        __typename?: 'Division'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  equipmentAssignedWorkerConnection?: {
    __typename?: 'EquipmentToUserConnection'
    edges?: Array<{
      __typename?: 'EquipmentToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        name?: string | null
        databaseId: number
      } | null
    } | null> | null
  } | null
  equipmentToDocumentConnection?: {
    __typename?: 'EquipmentToDocumentConnection'
    edges?: Array<{
      __typename?: 'EquipmentToDocumentConnectionEdge'
      node?: {
        __typename?: 'Document'
        title?: string | null
        databaseId: number
      } | null
    } | null> | null
  } | null
  equipmentToProjectConnection?: {
    __typename?: 'EquipmentToProjectConnection'
    edges?: Array<{
      __typename?: 'EquipmentToProjectConnectionEdge'
      node?: {
        __typename?: 'Project'
        databaseId: number
        title?: string | null
        number?: string | null
      } | null
    } | null> | null
  } | null
  equipmentToEquipmentConnection?: {
    __typename?: 'EquipmentToEquipmentConnection'
    edges?: Array<{
      __typename?: 'EquipmentToEquipmentConnectionEdge'
      node?: {
        __typename?: 'Equipment'
        title?: string | null
        databaseId: number
      } | null
    } | null> | null
  } | null
  inspectionIntervals?: {
    __typename?: 'EquipmentToInspectionIntervalConnection'
    edges?: Array<{
      __typename?: 'EquipmentToInspectionIntervalConnectionEdge'
      node?: {
        __typename?: 'InspectionInterval'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  fileAttachments?: {
    __typename?: 'EquipmentToMediaItemConnection'
    edges?: Array<{
      __typename?: 'EquipmentToMediaItemConnectionEdge'
      node?: {
        __typename?: 'MediaItem'
        id: string
        title?: string | null
        description?: string | null
        mediaItemUrl?: string | null
        sizes?: string | null
        databaseId: number
        sourceUrl?: string | null
      } | null
    } | null> | null
  } | null
}

export type CreateEquipmentMutationVariables = Exact<{
  input: CreateEquipmentInput
}>

export type CreateEquipmentMutation = {
  __typename?: 'RootMutation'
  createEquipment?: {
    __typename?: 'CreateEquipmentPayload'
    equipment?: {
      __typename?: 'Equipment'
      databaseId: number
      id: string
      title?: string | null
      serialNumber?: string | null
      modelNumber?: string | null
      inServiceDate?: string | null
      lastInspectedDate?: string | null
      nextInspectionDate?: string | null
      integrationSource?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      equipmentStatuses?: {
        __typename?: 'EquipmentToEquipmentStatusConnection'
        edges?: Array<{
          __typename?: 'EquipmentToEquipmentStatusConnectionEdge'
          node?: {
            __typename?: 'EquipmentStatus'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      manufacturers?: {
        __typename?: 'EquipmentToManufacturerConnection'
        edges?: Array<{
          __typename?: 'EquipmentToManufacturerConnectionEdge'
          node?: {
            __typename?: 'Manufacturer'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      equipmentTags?: {
        __typename?: 'EquipmentToEquipmentTagConnection'
        edges?: Array<{
          __typename?: 'EquipmentToEquipmentTagConnectionEdge'
          node?: {
            __typename?: 'EquipmentTag'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'EquipmentToCompanyConnection'
        edges?: Array<{
          __typename?: 'EquipmentToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'EquipmentToDivisionConnection'
        edges?: Array<{
          __typename?: 'EquipmentToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      equipmentAssignedWorkerConnection?: {
        __typename?: 'EquipmentToUserConnection'
        edges?: Array<{
          __typename?: 'EquipmentToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            name?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      equipmentToDocumentConnection?: {
        __typename?: 'EquipmentToDocumentConnection'
        edges?: Array<{
          __typename?: 'EquipmentToDocumentConnectionEdge'
          node?: {
            __typename?: 'Document'
            title?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      equipmentToProjectConnection?: {
        __typename?: 'EquipmentToProjectConnection'
        edges?: Array<{
          __typename?: 'EquipmentToProjectConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      equipmentToEquipmentConnection?: {
        __typename?: 'EquipmentToEquipmentConnection'
        edges?: Array<{
          __typename?: 'EquipmentToEquipmentConnectionEdge'
          node?: {
            __typename?: 'Equipment'
            title?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      inspectionIntervals?: {
        __typename?: 'EquipmentToInspectionIntervalConnection'
        edges?: Array<{
          __typename?: 'EquipmentToInspectionIntervalConnectionEdge'
          node?: {
            __typename?: 'InspectionInterval'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'EquipmentToMediaItemConnection'
        edges?: Array<{
          __typename?: 'EquipmentToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            mediaItemUrl?: string | null
            sizes?: string | null
            databaseId: number
            sourceUrl?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type DeleteEquipmentMutationVariables = Exact<{
  input: DeleteEquipmentInput
}>

export type DeleteEquipmentMutation = {
  __typename?: 'RootMutation'
  deleteEquipment?: {
    __typename?: 'DeleteEquipmentPayload'
    deletedId?: string | null
    equipment?: {
      __typename?: 'Equipment'
      databaseId: number
      id: string
      title?: string | null
      serialNumber?: string | null
      modelNumber?: string | null
      inServiceDate?: string | null
      lastInspectedDate?: string | null
      nextInspectionDate?: string | null
      integrationSource?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      equipmentStatuses?: {
        __typename?: 'EquipmentToEquipmentStatusConnection'
        edges?: Array<{
          __typename?: 'EquipmentToEquipmentStatusConnectionEdge'
          node?: {
            __typename?: 'EquipmentStatus'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      manufacturers?: {
        __typename?: 'EquipmentToManufacturerConnection'
        edges?: Array<{
          __typename?: 'EquipmentToManufacturerConnectionEdge'
          node?: {
            __typename?: 'Manufacturer'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      equipmentTags?: {
        __typename?: 'EquipmentToEquipmentTagConnection'
        edges?: Array<{
          __typename?: 'EquipmentToEquipmentTagConnectionEdge'
          node?: {
            __typename?: 'EquipmentTag'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'EquipmentToCompanyConnection'
        edges?: Array<{
          __typename?: 'EquipmentToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'EquipmentToDivisionConnection'
        edges?: Array<{
          __typename?: 'EquipmentToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      equipmentAssignedWorkerConnection?: {
        __typename?: 'EquipmentToUserConnection'
        edges?: Array<{
          __typename?: 'EquipmentToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            name?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      equipmentToDocumentConnection?: {
        __typename?: 'EquipmentToDocumentConnection'
        edges?: Array<{
          __typename?: 'EquipmentToDocumentConnectionEdge'
          node?: {
            __typename?: 'Document'
            title?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      equipmentToProjectConnection?: {
        __typename?: 'EquipmentToProjectConnection'
        edges?: Array<{
          __typename?: 'EquipmentToProjectConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      equipmentToEquipmentConnection?: {
        __typename?: 'EquipmentToEquipmentConnection'
        edges?: Array<{
          __typename?: 'EquipmentToEquipmentConnectionEdge'
          node?: {
            __typename?: 'Equipment'
            title?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      inspectionIntervals?: {
        __typename?: 'EquipmentToInspectionIntervalConnection'
        edges?: Array<{
          __typename?: 'EquipmentToInspectionIntervalConnectionEdge'
          node?: {
            __typename?: 'InspectionInterval'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'EquipmentToMediaItemConnection'
        edges?: Array<{
          __typename?: 'EquipmentToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            mediaItemUrl?: string | null
            sizes?: string | null
            databaseId: number
            sourceUrl?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type UpdateEquipmentMutationVariables = Exact<{
  input: UpdateEquipmentInput
}>

export type UpdateEquipmentMutation = {
  __typename?: 'RootMutation'
  updateEquipment?: {
    __typename?: 'UpdateEquipmentPayload'
    equipment?: {
      __typename?: 'Equipment'
      databaseId: number
      id: string
      title?: string | null
      serialNumber?: string | null
      modelNumber?: string | null
      inServiceDate?: string | null
      lastInspectedDate?: string | null
      nextInspectionDate?: string | null
      integrationSource?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      equipmentStatuses?: {
        __typename?: 'EquipmentToEquipmentStatusConnection'
        edges?: Array<{
          __typename?: 'EquipmentToEquipmentStatusConnectionEdge'
          node?: {
            __typename?: 'EquipmentStatus'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      manufacturers?: {
        __typename?: 'EquipmentToManufacturerConnection'
        edges?: Array<{
          __typename?: 'EquipmentToManufacturerConnectionEdge'
          node?: {
            __typename?: 'Manufacturer'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      equipmentTags?: {
        __typename?: 'EquipmentToEquipmentTagConnection'
        edges?: Array<{
          __typename?: 'EquipmentToEquipmentTagConnectionEdge'
          node?: {
            __typename?: 'EquipmentTag'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'EquipmentToCompanyConnection'
        edges?: Array<{
          __typename?: 'EquipmentToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'EquipmentToDivisionConnection'
        edges?: Array<{
          __typename?: 'EquipmentToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      equipmentAssignedWorkerConnection?: {
        __typename?: 'EquipmentToUserConnection'
        edges?: Array<{
          __typename?: 'EquipmentToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            name?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      equipmentToDocumentConnection?: {
        __typename?: 'EquipmentToDocumentConnection'
        edges?: Array<{
          __typename?: 'EquipmentToDocumentConnectionEdge'
          node?: {
            __typename?: 'Document'
            title?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      equipmentToProjectConnection?: {
        __typename?: 'EquipmentToProjectConnection'
        edges?: Array<{
          __typename?: 'EquipmentToProjectConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      equipmentToEquipmentConnection?: {
        __typename?: 'EquipmentToEquipmentConnection'
        edges?: Array<{
          __typename?: 'EquipmentToEquipmentConnectionEdge'
          node?: {
            __typename?: 'Equipment'
            title?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      inspectionIntervals?: {
        __typename?: 'EquipmentToInspectionIntervalConnection'
        edges?: Array<{
          __typename?: 'EquipmentToInspectionIntervalConnectionEdge'
          node?: {
            __typename?: 'InspectionInterval'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'EquipmentToMediaItemConnection'
        edges?: Array<{
          __typename?: 'EquipmentToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            mediaItemUrl?: string | null
            sizes?: string | null
            databaseId: number
            sourceUrl?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type AllEquipmentQueryVariables = Exact<{
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToEquipmentConnectionWhereArgs>
}>

export type AllEquipmentQuery = {
  __typename?: 'RootQuery'
  allEquipment?: {
    __typename?: 'RootQueryToEquipmentConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToEquipmentConnectionEdge'
      cursor?: string | null
      node?: {
        __typename?: 'Equipment'
        databaseId: number
        id: string
        title?: string | null
        serialNumber?: string | null
        modelNumber?: string | null
        inServiceDate?: string | null
        lastInspectedDate?: string | null
        nextInspectionDate?: string | null
        integrationSource?: string | null
        author?: {
          __typename?: 'NodeWithAuthorToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            databaseId: number
            name?: string | null
          } | null
        } | null
        equipmentStatuses?: {
          __typename?: 'EquipmentToEquipmentStatusConnection'
          edges?: Array<{
            __typename?: 'EquipmentToEquipmentStatusConnectionEdge'
            node?: {
              __typename?: 'EquipmentStatus'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        manufacturers?: {
          __typename?: 'EquipmentToManufacturerConnection'
          edges?: Array<{
            __typename?: 'EquipmentToManufacturerConnectionEdge'
            node?: {
              __typename?: 'Manufacturer'
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
        equipmentTags?: {
          __typename?: 'EquipmentToEquipmentTagConnection'
          edges?: Array<{
            __typename?: 'EquipmentToEquipmentTagConnectionEdge'
            node?: {
              __typename?: 'EquipmentTag'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        companies?: {
          __typename?: 'EquipmentToCompanyConnection'
          edges?: Array<{
            __typename?: 'EquipmentToCompanyConnectionEdge'
            node?: {
              __typename?: 'Company'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        divisions?: {
          __typename?: 'EquipmentToDivisionConnection'
          edges?: Array<{
            __typename?: 'EquipmentToDivisionConnectionEdge'
            node?: {
              __typename?: 'Division'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        equipmentAssignedWorkerConnection?: {
          __typename?: 'EquipmentToUserConnection'
          edges?: Array<{
            __typename?: 'EquipmentToUserConnectionEdge'
            node?: {
              __typename?: 'User'
              name?: string | null
              databaseId: number
            } | null
          } | null> | null
        } | null
        equipmentToDocumentConnection?: {
          __typename?: 'EquipmentToDocumentConnection'
          edges?: Array<{
            __typename?: 'EquipmentToDocumentConnectionEdge'
            node?: {
              __typename?: 'Document'
              title?: string | null
              databaseId: number
            } | null
          } | null> | null
        } | null
        equipmentToProjectConnection?: {
          __typename?: 'EquipmentToProjectConnection'
          edges?: Array<{
            __typename?: 'EquipmentToProjectConnectionEdge'
            node?: {
              __typename?: 'Project'
              databaseId: number
              title?: string | null
              number?: string | null
            } | null
          } | null> | null
        } | null
        equipmentToEquipmentConnection?: {
          __typename?: 'EquipmentToEquipmentConnection'
          edges?: Array<{
            __typename?: 'EquipmentToEquipmentConnectionEdge'
            node?: {
              __typename?: 'Equipment'
              title?: string | null
              databaseId: number
            } | null
          } | null> | null
        } | null
        inspectionIntervals?: {
          __typename?: 'EquipmentToInspectionIntervalConnection'
          edges?: Array<{
            __typename?: 'EquipmentToInspectionIntervalConnectionEdge'
            node?: {
              __typename?: 'InspectionInterval'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        fileAttachments?: {
          __typename?: 'EquipmentToMediaItemConnection'
          edges?: Array<{
            __typename?: 'EquipmentToMediaItemConnectionEdge'
            node?: {
              __typename?: 'MediaItem'
              id: string
              title?: string | null
              description?: string | null
              mediaItemUrl?: string | null
              sizes?: string | null
              databaseId: number
              sourceUrl?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type GetEquipmentQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
}>

export type GetEquipmentQuery = {
  __typename?: 'RootQuery'
  equipment?: {
    __typename?: 'Equipment'
    databaseId: number
    id: string
    title?: string | null
    serialNumber?: string | null
    modelNumber?: string | null
    inServiceDate?: string | null
    lastInspectedDate?: string | null
    nextInspectionDate?: string | null
    integrationSource?: string | null
    author?: {
      __typename?: 'NodeWithAuthorToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        databaseId: number
        name?: string | null
      } | null
    } | null
    equipmentStatuses?: {
      __typename?: 'EquipmentToEquipmentStatusConnection'
      edges?: Array<{
        __typename?: 'EquipmentToEquipmentStatusConnectionEdge'
        node?: {
          __typename?: 'EquipmentStatus'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    manufacturers?: {
      __typename?: 'EquipmentToManufacturerConnection'
      edges?: Array<{
        __typename?: 'EquipmentToManufacturerConnectionEdge'
        node?: {
          __typename?: 'Manufacturer'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    equipmentTags?: {
      __typename?: 'EquipmentToEquipmentTagConnection'
      edges?: Array<{
        __typename?: 'EquipmentToEquipmentTagConnectionEdge'
        node?: {
          __typename?: 'EquipmentTag'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    companies?: {
      __typename?: 'EquipmentToCompanyConnection'
      edges?: Array<{
        __typename?: 'EquipmentToCompanyConnectionEdge'
        node?: {
          __typename?: 'Company'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    divisions?: {
      __typename?: 'EquipmentToDivisionConnection'
      edges?: Array<{
        __typename?: 'EquipmentToDivisionConnectionEdge'
        node?: {
          __typename?: 'Division'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    equipmentAssignedWorkerConnection?: {
      __typename?: 'EquipmentToUserConnection'
      edges?: Array<{
        __typename?: 'EquipmentToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          name?: string | null
          databaseId: number
        } | null
      } | null> | null
    } | null
    equipmentToDocumentConnection?: {
      __typename?: 'EquipmentToDocumentConnection'
      edges?: Array<{
        __typename?: 'EquipmentToDocumentConnectionEdge'
        node?: {
          __typename?: 'Document'
          title?: string | null
          databaseId: number
        } | null
      } | null> | null
    } | null
    equipmentToProjectConnection?: {
      __typename?: 'EquipmentToProjectConnection'
      edges?: Array<{
        __typename?: 'EquipmentToProjectConnectionEdge'
        node?: {
          __typename?: 'Project'
          databaseId: number
          title?: string | null
          number?: string | null
        } | null
      } | null> | null
    } | null
    equipmentToEquipmentConnection?: {
      __typename?: 'EquipmentToEquipmentConnection'
      edges?: Array<{
        __typename?: 'EquipmentToEquipmentConnectionEdge'
        node?: {
          __typename?: 'Equipment'
          title?: string | null
          databaseId: number
        } | null
      } | null> | null
    } | null
    inspectionIntervals?: {
      __typename?: 'EquipmentToInspectionIntervalConnection'
      edges?: Array<{
        __typename?: 'EquipmentToInspectionIntervalConnectionEdge'
        node?: {
          __typename?: 'InspectionInterval'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    fileAttachments?: {
      __typename?: 'EquipmentToMediaItemConnection'
      edges?: Array<{
        __typename?: 'EquipmentToMediaItemConnectionEdge'
        node?: {
          __typename?: 'MediaItem'
          id: string
          title?: string | null
          description?: string | null
          mediaItemUrl?: string | null
          sizes?: string | null
          databaseId: number
          sourceUrl?: string | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type ConditionalLogicFragFragment = {
  __typename?: 'ConditionalLogic'
  actionType?: ConditionalLogicActionTypeEnum | null
  logicType?: ConditionalLogicLogicTypeEnum | null
  rules?: Array<{
    __typename?: 'ConditionalLogicRule'
    fieldId?: number | null
    operator?: FormRuleOperatorEnum | null
    value?: string | null
  } | null> | null
}

export type CorrectiveActionSettingsFragFragment = {
  __typename?: 'CorrectiveActionSettings'
  actionTaken?: string | null
  deficiency?: string | null
  deficiencyType?: Array<string | null> | null
  severity?: string | null
}

export type FormButtonFragFragment = {
  __typename?: 'FormButton'
  text?: string | null
  type?: FormButtonTypeEnum | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type AddressFieldFragFragment = {
  __typename?: 'AddressField'
  label?: string | null
  description?: string | null
  isRequired?: boolean | null
  addressType?: AddressFieldTypeEnum | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  copyValuesOptionLabel?: string | null
  copyValuesOptionFieldId?: number | null
  cssClass?: string | null
  defaultCountry?: AddressFieldCountryEnum | null
  defaultState?: string | null
  defaultProvince?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  inputName?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldCopyValuesOption?: boolean | null
  subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
  inputs?: Array<{
    __typename?: 'AddressInputProperty'
    key?: string | null
    label?: string | null
    placeholder?: string | null
    defaultValue?: string | null
    customLabel?: string | null
    autocompleteAttribute?: string | null
    id?: number | null
    isHidden?: boolean | null
    name?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  addressTranslations?: Array<{
    __typename?: 'AddressTranlation'
    name?: string | null
    slug?: string | null
    label?: string | null
    description?: string | null
    address?: string | null
    address2?: string | null
    city?: string | null
    state?: string | null
    country?: string | null
    zip?: string | null
  } | null> | null
}

export type CaptchaFieldFragFragment = {
  __typename?: 'CaptchaField'
  captchaBadgePosition?: CaptchaFieldBadgePositionEnum | null
  captchaLanguage?: string | null
  captchaTheme?: CaptchaFieldThemeEnum | null
  captchaType?: CaptchaFieldTypeEnum | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  label?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  simpleCaptchaBackgroundColor?: string | null
  simpleCaptchaFontColor?: string | null
  simpleCaptchaSize?: FormFieldSizeEnum | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type CheckboxFieldFragFragment = {
  __typename?: 'CheckboxField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasSelectAll?: boolean | null
  inputName?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  choices?: Array<{
    __typename?: 'CheckboxFieldChoice'
    text?: string | null
    value?: string | null
    isSelected?: boolean | null
  } | null> | null
  inputs?: Array<{
    __typename?: 'CheckboxInputProperty'
    id?: number | null
    label?: string | null
    name?: string | null
  } | null> | null
  choiceTranslations?: Array<{
    __typename?: 'ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type ComboSignatureFieldFragFragment = {
  __typename?: 'ComboSignatureField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  backgroundColor?: string | null
  borderColor?: string | null
  borderStyle?: SignatureFieldBorderStyleEnum | null
  borderWidth?: SignatureFieldBorderWidthEnum | null
  boxWidth?: number | null
  penSize?: number | null
  penColor?: string | null
  minSignatures?: number | null
  maxSignatures?: number | null
  adminLabel?: string | null
  autocompleteAttribute?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  defaultValue?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  hasChoiceValue?: boolean | null
  hasEnhancedUI?: boolean | null
  inputName?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  placeholder?: string | null
  shouldAllowDuplicates?: boolean | null
  size?: FormFieldSizeEnum | null
  choices?: Array<{
    __typename?: 'ComboSignatureFieldChoice'
    text?: string | null
    value?: string | null
    isSelected?: boolean | null
  } | null> | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type ConsentFieldFragFragment = {
  __typename?: 'ConsentField'
  description?: string | null
  checkboxLabel?: string | null
  label?: string | null
  isRequired?: boolean | null
  adminLabel?: string | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  consentTranslations?: Array<{
    __typename?: 'ConsentTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    checkboxLabel?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type DateFieldFragFragment = {
  __typename?: 'DateField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  placeholder?: string | null
  defaultValue?: string | null
  calendarIconType?: FormFieldCalendarIconTypeEnum | null
  inputName?: string | null
  dateFormat?: DateFieldFormatEnum | null
  adminLabel?: string | null
  calendarIconUrl?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  inputs?: Array<{
    __typename?: 'DateInputProperty'
    autocompleteAttribute?: string | null
    customLabel?: string | null
    defaultValue?: string | null
    id?: number | null
    label?: string | null
    placeholder?: string | null
  } | null> | null
}

export type EmailFieldFragFragment = {
  __typename?: 'EmailField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  placeholder?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  hasEmailConfirmation?: boolean | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  size?: FormFieldSizeEnum | null
  subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  inputs?: Array<{
    __typename?: 'EmailInputProperty'
    autocompleteAttribute?: string | null
    customLabel?: string | null
    defaultValue?: string | null
    id?: number | null
    label?: string | null
    name?: string | null
    placeholder?: string | null
  } | null> | null
}

export type FileUploadFieldFragFragment = {
  __typename?: 'FileUploadField'
  label?: string | null
  isRequired?: boolean | null
  maxFileSize?: number | null
  maxFiles?: number | null
  adminLabel?: string | null
  allowedExtensions?: Array<string | null> | null
  canAcceptMultipleFiles?: boolean | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type FollowUpFieldFragFragment = {
  __typename?: 'FollowUpField'
  label?: string | null
  content?: string | null
  cssClass?: string | null
  followUpFormId?: number | null
  hasMargins?: boolean | null
  followUpForm?: {
    __typename?: 'GfForm'
    slug?: string | null
    title?: string | null
  } | null
  correctiveActionSettings?: Array<{
    __typename?: 'CorrectiveActionSettings'
    actionTaken?: string | null
    deficiency?: string | null
    deficiencyType?: Array<string | null> | null
    severity?: string | null
  } | null> | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

type FormFieldFrag_AddressField_Fragment = {
  __typename: 'AddressField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_CaptchaField_Fragment = {
  __typename: 'CaptchaField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_CheckboxField_Fragment = {
  __typename: 'CheckboxField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_ComboSignatureField_Fragment = {
  __typename: 'ComboSignatureField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_ConsentField_Fragment = {
  __typename: 'ConsentField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_DateField_Fragment = {
  __typename: 'DateField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_EmailField_Fragment = {
  __typename: 'EmailField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_FileUploadField_Fragment = {
  __typename: 'FileUploadField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_FollowUpField_Fragment = {
  __typename: 'FollowUpField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_HiddenField_Fragment = {
  __typename: 'HiddenField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_HtmlField_Fragment = {
  __typename: 'HtmlField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_ListField_Fragment = {
  __typename: 'ListField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_MultiSelectField_Fragment = {
  __typename: 'MultiSelectField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_NameField_Fragment = {
  __typename: 'NameField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_NumberField_Fragment = {
  __typename: 'NumberField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PageField_Fragment = {
  __typename: 'PageField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PasswordField_Fragment = {
  __typename: 'PasswordField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PhoneField_Fragment = {
  __typename: 'PhoneField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PhotoField_Fragment = {
  __typename: 'PhotoField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCategoryCheckboxField_Fragment = {
  __typename: 'PostCategoryCheckboxField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCategoryMultiSelectField_Fragment = {
  __typename: 'PostCategoryMultiSelectField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCategoryRadioField_Fragment = {
  __typename: 'PostCategoryRadioField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCategorySelectField_Fragment = {
  __typename: 'PostCategorySelectField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostContentField_Fragment = {
  __typename: 'PostContentField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomCheckboxField_Fragment = {
  __typename: 'PostCustomCheckboxField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomDateField_Fragment = {
  __typename: 'PostCustomDateField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomEmailField_Fragment = {
  __typename: 'PostCustomEmailField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomFileuploadField_Fragment = {
  __typename: 'PostCustomFileuploadField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomHiddenField_Fragment = {
  __typename: 'PostCustomHiddenField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomListField_Fragment = {
  __typename: 'PostCustomListField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomMultiSelectField_Fragment = {
  __typename: 'PostCustomMultiSelectField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomNumberField_Fragment = {
  __typename: 'PostCustomNumberField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomPhoneField_Fragment = {
  __typename: 'PostCustomPhoneField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomRadioField_Fragment = {
  __typename: 'PostCustomRadioField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomSelectField_Fragment = {
  __typename: 'PostCustomSelectField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomTextAreaField_Fragment = {
  __typename: 'PostCustomTextAreaField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomTextField_Fragment = {
  __typename: 'PostCustomTextField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomTimeField_Fragment = {
  __typename: 'PostCustomTimeField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostCustomWebsiteField_Fragment = {
  __typename: 'PostCustomWebsiteField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostExcerptField_Fragment = {
  __typename: 'PostExcerptField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostImageField_Fragment = {
  __typename: 'PostImageField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostTagsCheckboxField_Fragment = {
  __typename: 'PostTagsCheckboxField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostTagsMultiSelectField_Fragment = {
  __typename: 'PostTagsMultiSelectField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostTagsRadioField_Fragment = {
  __typename: 'PostTagsRadioField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostTagsSelectField_Fragment = {
  __typename: 'PostTagsSelectField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostTagsTextField_Fragment = {
  __typename: 'PostTagsTextField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_PostTitleField_Fragment = {
  __typename: 'PostTitleField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_QuizCheckboxField_Fragment = {
  __typename: 'QuizCheckboxField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_QuizRadioField_Fragment = {
  __typename: 'QuizRadioField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_QuizSelectField_Fragment = {
  __typename: 'QuizSelectField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_RadioField_Fragment = {
  __typename: 'RadioField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_RememberMeField_Fragment = {
  __typename: 'RememberMeField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_SectionField_Fragment = {
  __typename: 'SectionField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_SelectField_Fragment = {
  __typename: 'SelectField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_SignatureField_Fragment = {
  __typename: 'SignatureField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_SubmitField_Fragment = {
  __typename: 'SubmitField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_TextAreaField_Fragment = {
  __typename: 'TextAreaField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_TextField_Fragment = {
  __typename: 'TextField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_TimeField_Fragment = {
  __typename: 'TimeField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_UsernameField_Fragment = {
  __typename: 'UsernameField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

type FormFieldFrag_WebsiteField_Fragment = {
  __typename: 'WebsiteField'
  id: number
  type?: FormFieldTypeEnum | null
  inputType?: FormFieldTypeEnum | null
  pageNumber?: number | null
  visibility?: FormFieldVisibilityEnum | null
  displayOnly?: boolean | null
}

export type FormFieldFragFragment =
  | FormFieldFrag_AddressField_Fragment
  | FormFieldFrag_CaptchaField_Fragment
  | FormFieldFrag_CheckboxField_Fragment
  | FormFieldFrag_ComboSignatureField_Fragment
  | FormFieldFrag_ConsentField_Fragment
  | FormFieldFrag_DateField_Fragment
  | FormFieldFrag_EmailField_Fragment
  | FormFieldFrag_FileUploadField_Fragment
  | FormFieldFrag_FollowUpField_Fragment
  | FormFieldFrag_HiddenField_Fragment
  | FormFieldFrag_HtmlField_Fragment
  | FormFieldFrag_ListField_Fragment
  | FormFieldFrag_MultiSelectField_Fragment
  | FormFieldFrag_NameField_Fragment
  | FormFieldFrag_NumberField_Fragment
  | FormFieldFrag_PageField_Fragment
  | FormFieldFrag_PasswordField_Fragment
  | FormFieldFrag_PhoneField_Fragment
  | FormFieldFrag_PhotoField_Fragment
  | FormFieldFrag_PostCategoryCheckboxField_Fragment
  | FormFieldFrag_PostCategoryMultiSelectField_Fragment
  | FormFieldFrag_PostCategoryRadioField_Fragment
  | FormFieldFrag_PostCategorySelectField_Fragment
  | FormFieldFrag_PostContentField_Fragment
  | FormFieldFrag_PostCustomCheckboxField_Fragment
  | FormFieldFrag_PostCustomDateField_Fragment
  | FormFieldFrag_PostCustomEmailField_Fragment
  | FormFieldFrag_PostCustomFileuploadField_Fragment
  | FormFieldFrag_PostCustomHiddenField_Fragment
  | FormFieldFrag_PostCustomListField_Fragment
  | FormFieldFrag_PostCustomMultiSelectField_Fragment
  | FormFieldFrag_PostCustomNumberField_Fragment
  | FormFieldFrag_PostCustomPhoneField_Fragment
  | FormFieldFrag_PostCustomRadioField_Fragment
  | FormFieldFrag_PostCustomSelectField_Fragment
  | FormFieldFrag_PostCustomTextAreaField_Fragment
  | FormFieldFrag_PostCustomTextField_Fragment
  | FormFieldFrag_PostCustomTimeField_Fragment
  | FormFieldFrag_PostCustomWebsiteField_Fragment
  | FormFieldFrag_PostExcerptField_Fragment
  | FormFieldFrag_PostImageField_Fragment
  | FormFieldFrag_PostTagsCheckboxField_Fragment
  | FormFieldFrag_PostTagsMultiSelectField_Fragment
  | FormFieldFrag_PostTagsRadioField_Fragment
  | FormFieldFrag_PostTagsSelectField_Fragment
  | FormFieldFrag_PostTagsTextField_Fragment
  | FormFieldFrag_PostTitleField_Fragment
  | FormFieldFrag_QuizCheckboxField_Fragment
  | FormFieldFrag_QuizRadioField_Fragment
  | FormFieldFrag_QuizSelectField_Fragment
  | FormFieldFrag_RadioField_Fragment
  | FormFieldFrag_RememberMeField_Fragment
  | FormFieldFrag_SectionField_Fragment
  | FormFieldFrag_SelectField_Fragment
  | FormFieldFrag_SignatureField_Fragment
  | FormFieldFrag_SubmitField_Fragment
  | FormFieldFrag_TextAreaField_Fragment
  | FormFieldFrag_TextField_Fragment
  | FormFieldFrag_TimeField_Fragment
  | FormFieldFrag_UsernameField_Fragment
  | FormFieldFrag_WebsiteField_Fragment

export type HiddenFieldFragFragment = {
  __typename?: 'HiddenField'
  defaultValue?: string | null
  label?: string | null
  canPrepopulate?: boolean | null
  inputName?: string | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
  } | null> | null
}

export type HtmlFieldFragFragment = {
  __typename?: 'HtmlField'
  content?: string | null
  label?: string | null
  cssClass?: string | null
  hasMargins?: boolean | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type ListFieldFragFragment = {
  __typename?: 'ListField'
  description?: string | null
  isRequired?: boolean | null
  inputName?: string | null
  label?: string | null
  maxRows?: number | null
  addIconUrl?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  deleteIconUrl?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasColumns?: boolean | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  choices?: Array<{
    __typename?: 'ListFieldChoice'
    text?: string | null
    value?: string | null
  } | null> | null
  choiceTranslations?: Array<{
    __typename?: 'ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type MultiSelectFieldFragFragment = {
  __typename?: 'MultiSelectField'
  label?: string | null
  description?: string | null
  isRequired?: boolean | null
  allowCustomOption?: boolean | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasEnhancedUI?: boolean | null
  inputName?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  size?: FormFieldSizeEnum | null
  choices?: Array<{
    __typename?: 'MultiSelectFieldChoice'
    text?: string | null
    value?: string | null
    isSelected?: boolean | null
  } | null> | null
  choiceTranslations?: Array<{
    __typename?: 'ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type NameFieldFragFragment = {
  __typename?: 'NameField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  inputName?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
  inputs?: Array<{
    __typename?: 'NameInputProperty'
    key?: string | null
    label?: string | null
    placeholder?: string | null
    isHidden?: boolean | null
    defaultValue?: string | null
    customLabel?: string | null
    autocompleteAttribute?: string | null
    hasChoiceValue?: boolean | null
    id?: number | null
    name?: string | null
    choices?: Array<{
      __typename?: 'NameFieldChoice'
      text?: string | null
      value?: string | null
      isSelected?: boolean | null
    } | null> | null
  } | null> | null
  nameTranslations?: Array<{
    __typename?: 'NameTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    first?: string | null
    last?: string | null
    middle?: string | null
    prefix?: string | null
    suffix?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type NumberFieldFragFragment = {
  __typename?: 'NumberField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  rangeMin?: number | null
  rangeMax?: number | null
  numberFormat?: NumberFieldFormatEnum | null
  placeholder?: string | null
  defaultValue?: string | null
  calculationFormula?: string | null
  calculationRounding?: number | null
  errorMessage?: string | null
  adminLabel?: string | null
  autocompleteAttribute?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  hasAutocomplete?: boolean | null
  inputName?: string | null
  isCalculation?: boolean | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  size?: FormFieldSizeEnum | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type PageFieldFragFragment = {
  __typename?: 'PageField'
  cssClass?: string | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  previousButton?: {
    __typename?: 'FormButton'
    text?: string | null
    type?: FormButtonTypeEnum | null
    conditionalLogic?: {
      __typename?: 'ConditionalLogic'
      actionType?: ConditionalLogicActionTypeEnum | null
      logicType?: ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'ConditionalLogicRule'
        fieldId?: number | null
        operator?: FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null
  nextButton?: {
    __typename?: 'FormButton'
    text?: string | null
    type?: FormButtonTypeEnum | null
    conditionalLogic?: {
      __typename?: 'ConditionalLogic'
      actionType?: ConditionalLogicActionTypeEnum | null
      logicType?: ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'ConditionalLogicRule'
        fieldId?: number | null
        operator?: FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
  } | null> | null
}

export type PasswordFieldFragFragment = {
  __typename?: 'PasswordField'
  adminLabel?: string | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasPasswordStrengthIndicator?: boolean | null
  hasPasswordVisibilityToggle?: boolean | null
  isRequired?: boolean | null
  label?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  minPasswordStrength?: PasswordFieldMinStrengthEnum | null
  size?: FormFieldSizeEnum | null
  subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  inputs?: Array<{
    __typename?: 'PasswordInputProperty'
    customLabel?: string | null
    id?: number | null
    isHidden?: boolean | null
    label?: string | null
    placeholder?: string | null
  } | null> | null
}

export type PhoneFieldFragFragment = {
  __typename?: 'PhoneField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  placeholder?: string | null
  adminLabel?: string | null
  autocompleteAttribute?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  defaultValue?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  inputName?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  phoneFormat?: PhoneFieldFormatEnum | null
  shouldAllowDuplicates?: boolean | null
  size?: FormFieldSizeEnum | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type PhotoFieldFragFragment = {
  __typename?: 'PhotoField'
  label?: string | null
  isRequired?: boolean | null
  visibility?: FormFieldVisibilityEnum | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  inputName?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

type QuizFieldFrag_QuizCheckboxField_Fragment = {
  __typename?: 'QuizCheckboxField'
  hasSelectAll?: boolean | null
  adminLabel?: string | null
  answerExplanation?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasWeightedScore?: boolean | null
  inputName?: string | null
  isRequired?: boolean | null
  label?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldRandomizeQuizChoices?: boolean | null
  shouldShowAnswerExplanation?: boolean | null
  inputs?: Array<{
    __typename?: 'QuizInputProperty'
    label?: string | null
    id?: number | null
    name?: string | null
  } | null> | null
  choices?: Array<{
    __typename?: 'QuizFieldChoice'
    isCorrect?: boolean | null
    isOtherChoice?: boolean | null
    isSelected?: boolean | null
    text?: string | null
    value?: string | null
    weight?: number | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  choiceTranslations?: Array<{
    __typename?: 'ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
}

type QuizFieldFrag_QuizRadioField_Fragment = {
  __typename?: 'QuizRadioField'
  shouldAllowDuplicates?: boolean | null
  adminLabel?: string | null
  answerExplanation?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasWeightedScore?: boolean | null
  inputName?: string | null
  isRequired?: boolean | null
  label?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldRandomizeQuizChoices?: boolean | null
  shouldShowAnswerExplanation?: boolean | null
  choices?: Array<{
    __typename?: 'QuizFieldChoice'
    isCorrect?: boolean | null
    isOtherChoice?: boolean | null
    isSelected?: boolean | null
    text?: string | null
    value?: string | null
    weight?: number | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  choiceTranslations?: Array<{
    __typename?: 'ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
}

type QuizFieldFrag_QuizSelectField_Fragment = {
  __typename?: 'QuizSelectField'
  defaultValue?: string | null
  placeholder?: string | null
  autocompleteAttribute?: string | null
  hasAutocomplete?: boolean | null
  hasEnhancedUI?: boolean | null
  adminLabel?: string | null
  answerExplanation?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  description?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasWeightedScore?: boolean | null
  inputName?: string | null
  isRequired?: boolean | null
  label?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldRandomizeQuizChoices?: boolean | null
  shouldShowAnswerExplanation?: boolean | null
  choices?: Array<{
    __typename?: 'QuizFieldChoice'
    isCorrect?: boolean | null
    isOtherChoice?: boolean | null
    isSelected?: boolean | null
    text?: string | null
    value?: string | null
    weight?: number | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  choiceTranslations?: Array<{
    __typename?: 'ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
}

export type QuizFieldFragFragment =
  | QuizFieldFrag_QuizCheckboxField_Fragment
  | QuizFieldFrag_QuizRadioField_Fragment
  | QuizFieldFrag_QuizSelectField_Fragment

export type RadioFieldFragFragment = {
  __typename?: 'RadioField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasChoiceValue?: boolean | null
  hasOtherChoice?: boolean | null
  inputName?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  choices?: Array<{
    __typename?: 'RadioFieldChoice'
    text?: string | null
    value?: string | null
    isSelected?: boolean | null
    isOtherChoice?: boolean | null
  } | null> | null
  choiceTranslations?: Array<{
    __typename?: 'ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type SectionFieldFragFragment = {
  __typename?: 'SectionField'
  label?: string | null
  description?: string | null
  cssClass?: string | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
}

export type SelectFieldFragFragment = {
  __typename?: 'SelectField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  defaultValue?: string | null
  allowCustomOption?: boolean | null
  adminLabel?: string | null
  autocompleteAttribute?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  hasChoiceValue?: boolean | null
  hasEnhancedUI?: boolean | null
  inputName?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  placeholder?: string | null
  shouldAllowDuplicates?: boolean | null
  size?: FormFieldSizeEnum | null
  choices?: Array<{
    __typename?: 'SelectFieldChoice'
    text?: string | null
    value?: string | null
    isSelected?: boolean | null
  } | null> | null
  choiceTranslations?: Array<{
    __typename?: 'ChoiceTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
    choices?: Array<string | null> | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type SignatureFieldFragFragment = {
  __typename?: 'SignatureField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  penColor?: string | null
  penSize?: number | null
  backgroundColor?: string | null
  borderWidth?: SignatureFieldBorderWidthEnum | null
  borderStyle?: SignatureFieldBorderStyleEnum | null
  borderColor?: string | null
  adminLabel?: string | null
  boxWidth?: number | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type TextAreaFieldFragFragment = {
  __typename?: 'TextAreaField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  maxLength?: number | null
  placeholder?: string | null
  defaultValue?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasRichTextEditor?: boolean | null
  inputName?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  size?: FormFieldSizeEnum | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type TextFieldFragFragment = {
  __typename?: 'TextField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  maxLength?: number | null
  placeholder?: string | null
  defaultValue?: string | null
  adminLabel?: string | null
  autocompleteAttribute?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  hasAutocomplete?: boolean | null
  hasInputMask?: boolean | null
  inputMaskValue?: string | null
  isPasswordInput?: boolean | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  size?: FormFieldSizeEnum | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type TimeFieldFragFragment = {
  __typename?: 'TimeField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  timeFormat?: TimeFieldFormatEnum | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
  inputs?: Array<{
    __typename?: 'TimeInputProperty'
    autocompleteAttribute?: string | null
    customLabel?: string | null
    defaultValue?: string | null
    id?: number | null
    label?: string | null
    placeholder?: string | null
  } | null> | null
}

export type WebsiteFieldFragFragment = {
  __typename?: 'WebsiteField'
  label?: string | null
  isRequired?: boolean | null
  description?: string | null
  placeholder?: string | null
  defaultValue?: string | null
  adminLabel?: string | null
  canPrepopulate?: boolean | null
  cssClass?: string | null
  descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
  errorMessage?: string | null
  inputName?: string | null
  labelPlacement?: FormFieldLabelPlacementEnum | null
  shouldAllowDuplicates?: boolean | null
  basicTranslations?: Array<{
    __typename?: 'BasicTranslation'
    slug?: string | null
    name?: string | null
    label?: string | null
    description?: string | null
  } | null> | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type FormFragFragment = {
  __typename?: 'GfForm'
  cssClass?: string | null
  description?: string | null
  disableEditing?: boolean | null
  hideJumpToSection?: boolean | null
  id: string
  reloadOnSubmit?: boolean | null
  restrictEntriesToUserCreated?: Array<string | null> | null
  restrictFormSubmissionByRole?: Array<string | null> | null
  slug?: string | null
  title?: string | null
  postTypeToCreate?: string | null
  isCAForm?: boolean | null
  isPrimary?: boolean | null
  isQuizForm?: boolean | null
  group?: string | null
  databaseId: number
  isActive?: boolean | null
  version?: string | null
  formFields?: {
    __typename?: 'GfFormToFormFieldConnection'
    nodes?: Array<
      | {
          __typename: 'AddressField'
          label?: string | null
          description?: string | null
          isRequired?: boolean | null
          addressType?: AddressFieldTypeEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          copyValuesOptionLabel?: string | null
          copyValuesOptionFieldId?: number | null
          cssClass?: string | null
          defaultCountry?: AddressFieldCountryEnum | null
          defaultState?: string | null
          defaultProvince?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldCopyValuesOption?: boolean | null
          subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'AddressInputProperty'
            key?: string | null
            label?: string | null
            placeholder?: string | null
            defaultValue?: string | null
            customLabel?: string | null
            autocompleteAttribute?: string | null
            id?: number | null
            isHidden?: boolean | null
            name?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          addressTranslations?: Array<{
            __typename?: 'AddressTranlation'
            name?: string | null
            slug?: string | null
            label?: string | null
            description?: string | null
            address?: string | null
            address2?: string | null
            city?: string | null
            state?: string | null
            country?: string | null
            zip?: string | null
          } | null> | null
        }
      | {
          __typename: 'CaptchaField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'CheckboxField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasSelectAll?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'CheckboxFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          inputs?: Array<{
            __typename?: 'CheckboxInputProperty'
            id?: number | null
            label?: string | null
            name?: string | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'ComboSignatureField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          backgroundColor?: string | null
          borderColor?: string | null
          borderStyle?: SignatureFieldBorderStyleEnum | null
          borderWidth?: SignatureFieldBorderWidthEnum | null
          boxWidth?: number | null
          penSize?: number | null
          penColor?: string | null
          minSignatures?: number | null
          maxSignatures?: number | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          defaultValue?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          placeholder?: string | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'ComboSignatureFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'ConsentField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'DateField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          defaultValue?: string | null
          calendarIconType?: FormFieldCalendarIconTypeEnum | null
          inputName?: string | null
          dateFormat?: DateFieldFormatEnum | null
          adminLabel?: string | null
          calendarIconUrl?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'DateInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'EmailField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasEmailConfirmation?: boolean | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'EmailInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            name?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'FileUploadField'
          label?: string | null
          isRequired?: boolean | null
          maxFileSize?: number | null
          maxFiles?: number | null
          adminLabel?: string | null
          allowedExtensions?: Array<string | null> | null
          canAcceptMultipleFiles?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'FollowUpField'
          label?: string | null
          content?: string | null
          cssClass?: string | null
          followUpFormId?: number | null
          hasMargins?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          followUpForm?: {
            __typename?: 'GfForm'
            slug?: string | null
            title?: string | null
          } | null
          correctiveActionSettings?: Array<{
            __typename?: 'CorrectiveActionSettings'
            actionTaken?: string | null
            deficiency?: string | null
            deficiencyType?: Array<string | null> | null
            severity?: string | null
          } | null> | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'HiddenField'
          defaultValue?: string | null
          label?: string | null
          canPrepopulate?: boolean | null
          inputName?: string | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
        }
      | {
          __typename: 'HtmlField'
          content?: string | null
          label?: string | null
          cssClass?: string | null
          hasMargins?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'ListField'
          description?: string | null
          isRequired?: boolean | null
          inputName?: string | null
          label?: string | null
          maxRows?: number | null
          addIconUrl?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          deleteIconUrl?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasColumns?: boolean | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'ListFieldChoice'
            text?: string | null
            value?: string | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'MultiSelectField'
          label?: string | null
          description?: string | null
          isRequired?: boolean | null
          allowCustomOption?: boolean | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'MultiSelectFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'NameField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'NameInputProperty'
            key?: string | null
            label?: string | null
            placeholder?: string | null
            isHidden?: boolean | null
            defaultValue?: string | null
            customLabel?: string | null
            autocompleteAttribute?: string | null
            hasChoiceValue?: boolean | null
            id?: number | null
            name?: string | null
            choices?: Array<{
              __typename?: 'NameFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
          } | null> | null
          nameTranslations?: Array<{
            __typename?: 'NameTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            first?: string | null
            last?: string | null
            middle?: string | null
            prefix?: string | null
            suffix?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'NumberField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          rangeMin?: number | null
          rangeMax?: number | null
          numberFormat?: NumberFieldFormatEnum | null
          placeholder?: string | null
          defaultValue?: string | null
          calculationFormula?: string | null
          calculationRounding?: number | null
          errorMessage?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          isCalculation?: boolean | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'PageField'
          cssClass?: string | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          previousButton?: {
            __typename?: 'FormButton'
            text?: string | null
            type?: FormButtonTypeEnum | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          } | null
          nextButton?: {
            __typename?: 'FormButton'
            text?: string | null
            type?: FormButtonTypeEnum | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          } | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
        }
      | {
          __typename: 'PasswordField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PhoneField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          defaultValue?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          phoneFormat?: PhoneFieldFormatEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'PhotoField'
          label?: string | null
          isRequired?: boolean | null
          visibility?: FormFieldVisibilityEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'PostCategoryCheckboxField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCategoryMultiSelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCategoryRadioField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCategorySelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostContentField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomCheckboxField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomDateField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomEmailField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomFileuploadField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomHiddenField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomListField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomMultiSelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomNumberField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomPhoneField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomRadioField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomSelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomTextAreaField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomTextField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomTimeField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomWebsiteField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostExcerptField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostImageField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTagsCheckboxField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTagsMultiSelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTagsRadioField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTagsSelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTagsTextField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTitleField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'QuizCheckboxField'
          hasSelectAll?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'QuizInputProperty'
            label?: string | null
            id?: number | null
            name?: string | null
          } | null> | null
          choices?: Array<{
            __typename?: 'QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'QuizRadioField'
          shouldAllowDuplicates?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'QuizSelectField'
          defaultValue?: string | null
          placeholder?: string | null
          autocompleteAttribute?: string | null
          hasAutocomplete?: boolean | null
          hasEnhancedUI?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'RadioField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasOtherChoice?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'RadioFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
            isOtherChoice?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'RememberMeField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'SectionField'
          label?: string | null
          description?: string | null
          cssClass?: string | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
        }
      | {
          __typename: 'SelectField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          defaultValue?: string | null
          allowCustomOption?: boolean | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          placeholder?: string | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'SelectFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'SignatureField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          penColor?: string | null
          penSize?: number | null
          backgroundColor?: string | null
          borderWidth?: SignatureFieldBorderWidthEnum | null
          borderStyle?: SignatureFieldBorderStyleEnum | null
          borderColor?: string | null
          adminLabel?: string | null
          boxWidth?: number | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'SubmitField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'TextAreaField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          maxLength?: number | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasRichTextEditor?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'TextField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          maxLength?: number | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasInputMask?: boolean | null
          inputMaskValue?: string | null
          isPasswordInput?: boolean | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'TimeField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          timeFormat?: TimeFieldFormatEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'TimeInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'UsernameField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'WebsiteField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | null
    > | null
  } | null
  confirmations?: Array<{
    __typename?: 'FormConfirmation'
    id?: string | null
    isActive?: boolean | null
    isDefault?: boolean | null
    message?: string | null
    name?: string | null
    pageId?: number | null
    queryString?: string | null
    type?: FormConfirmationTypeEnum | null
    url?: string | null
    conditionalLogic?: {
      __typename?: 'ConditionalLogic'
      actionType?: ConditionalLogicActionTypeEnum | null
      logicType?: ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'ConditionalLogicRule'
        fieldId?: number | null
        operator?: FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null> | null
  pagination?: {
    __typename?: 'FormPagination'
    pageNames?: Array<string | null> | null
    lastPageButton?: {
      __typename?: 'FormLastPageButton'
      text?: string | null
      type?: FormButtonTypeEnum | null
    } | null
  } | null
  submitButton?: {
    __typename?: 'FormSubmitButton'
    text?: string | null
    type?: FormButtonTypeEnum | null
    conditionalLogic?: {
      __typename?: 'ConditionalLogic'
      actionType?: ConditionalLogicActionTypeEnum | null
      logicType?: ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'ConditionalLogicRule'
        fieldId?: number | null
        operator?: FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null
  saveAndContinue?: {
    __typename?: 'FormSaveAndContinue'
    hasSaveAndContinue?: boolean | null
    buttonText?: string | null
  } | null
  translations?: Array<{
    __typename?: 'FormTranslation'
    slug?: string | null
    name?: string | null
    active?: boolean | null
    title?: string | null
    description?: string | null
    submitButtonText?: string | null
    nextButtonText?: string | null
    clearButtonText?: string | null
    previousButtonText?: string | null
    cardDisplay?: {
      __typename?: 'CardDisplayTranslation'
      detail1?: string | null
      detail2?: string | null
      detail3?: string | null
    } | null
  } | null> | null
}

export type FormBasicFragFragment = {
  __typename?: 'GfForm'
  cssClass?: string | null
  description?: string | null
  disableEditing?: boolean | null
  hideJumpToSection?: boolean | null
  id: string
  reloadOnSubmit?: boolean | null
  restrictEntriesToUserCreated?: Array<string | null> | null
  restrictFormSubmissionByRole?: Array<string | null> | null
  slug?: string | null
  title?: string | null
  postTypeToCreate?: string | null
  isCAForm?: boolean | null
  isPrimary?: boolean | null
  isQuizForm?: boolean | null
  group?: string | null
  databaseId: number
  isActive?: boolean | null
  version?: string | null
  confirmations?: Array<{
    __typename?: 'FormConfirmation'
    id?: string | null
    isActive?: boolean | null
    isDefault?: boolean | null
    message?: string | null
    name?: string | null
    pageId?: number | null
    queryString?: string | null
    type?: FormConfirmationTypeEnum | null
    url?: string | null
    conditionalLogic?: {
      __typename?: 'ConditionalLogic'
      actionType?: ConditionalLogicActionTypeEnum | null
      logicType?: ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'ConditionalLogicRule'
        fieldId?: number | null
        operator?: FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null> | null
  pagination?: {
    __typename?: 'FormPagination'
    pageNames?: Array<string | null> | null
    lastPageButton?: {
      __typename?: 'FormLastPageButton'
      text?: string | null
      type?: FormButtonTypeEnum | null
    } | null
  } | null
  submitButton?: {
    __typename?: 'FormSubmitButton'
    text?: string | null
    type?: FormButtonTypeEnum | null
    conditionalLogic?: {
      __typename?: 'ConditionalLogic'
      actionType?: ConditionalLogicActionTypeEnum | null
      logicType?: ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'ConditionalLogicRule'
        fieldId?: number | null
        operator?: FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null
  saveAndContinue?: {
    __typename?: 'FormSaveAndContinue'
    hasSaveAndContinue?: boolean | null
    buttonText?: string | null
  } | null
  translations?: Array<{
    __typename?: 'FormTranslation'
    slug?: string | null
    name?: string | null
    active?: boolean | null
    title?: string | null
    description?: string | null
    submitButtonText?: string | null
    nextButtonText?: string | null
    clearButtonText?: string | null
    previousButtonText?: string | null
    cardDisplay?: {
      __typename?: 'CardDisplayTranslation'
      detail1?: string | null
      detail2?: string | null
      detail3?: string | null
    } | null
  } | null> | null
}

export type FormConfirmationFragFragment = {
  __typename?: 'FormConfirmation'
  id?: string | null
  isActive?: boolean | null
  isDefault?: boolean | null
  message?: string | null
  name?: string | null
  pageId?: number | null
  queryString?: string | null
  type?: FormConfirmationTypeEnum | null
  url?: string | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type FormEntryLimitFragFragment = {
  __typename?: 'FormEntryLimits'
  hasLimit?: boolean | null
  limitReachedMessage?: string | null
  limitationPeriod?: FormLimitEntriesPeriodEnum | null
  maxEntries?: number | null
}

export type FormForCoreNavListFragFragment = {
  __typename?: 'GfForm'
  title?: string | null
  slug?: string | null
  databaseId: number
  postTypeToCreate?: string | null
  isPrimary?: boolean | null
  hideInNav?: boolean | null
  isCAForm?: boolean | null
  isQuizForm?: boolean | null
  group?: string | null
  translations?: Array<{
    __typename?: 'FormTranslation'
    name?: string | null
    slug?: string | null
    active?: boolean | null
    title?: string | null
  } | null> | null
}

export type FormLastPageButtonFragFragment = {
  __typename?: 'FormLastPageButton'
  text?: string | null
  type?: FormButtonTypeEnum | null
}

export type FormPaginationFragFragment = {
  __typename?: 'FormPagination'
  pageNames?: Array<string | null> | null
  lastPageButton?: {
    __typename?: 'FormLastPageButton'
    text?: string | null
    type?: FormButtonTypeEnum | null
  } | null
}

export type FormQuizFragFragment = {
  __typename?: 'FormQuiz'
  gradingType?: QuizFieldGradingTypeEnum | null
  hasInstantFeedback?: boolean | null
  hasLetterConfirmationMessage?: boolean | null
  hasPassFailConfirmationMessage?: boolean | null
  isShuffleFieldsEnabled?: boolean | null
  maxScore?: number | null
  passPercent?: number | null
  failConfirmation?: {
    __typename?: 'FormQuizConfirmation'
    isAutoformatted?: boolean | null
    message?: string | null
  } | null
  letterConfirmation?: {
    __typename?: 'FormQuizConfirmation'
    isAutoformatted?: boolean | null
    message?: string | null
  } | null
  passConfirmation?: {
    __typename?: 'FormQuizConfirmation'
    isAutoformatted?: boolean | null
    message?: string | null
  } | null
  grades?: Array<{
    __typename?: 'FormQuizGrades'
    text?: string | null
    value?: number | null
  } | null> | null
}

export type FormQuizConfirmationFragFragment = {
  __typename?: 'FormQuizConfirmation'
  isAutoformatted?: boolean | null
  message?: string | null
}

export type FormSaveAndContinueFragFragment = {
  __typename?: 'FormSaveAndContinue'
  hasSaveAndContinue?: boolean | null
  buttonText?: string | null
}

export type FormSubmitButtonFragFragment = {
  __typename?: 'FormSubmitButton'
  text?: string | null
  type?: FormButtonTypeEnum | null
  conditionalLogic?: {
    __typename?: 'ConditionalLogic'
    actionType?: ConditionalLogicActionTypeEnum | null
    logicType?: ConditionalLogicLogicTypeEnum | null
    rules?: Array<{
      __typename?: 'ConditionalLogicRule'
      fieldId?: number | null
      operator?: FormRuleOperatorEnum | null
      value?: string | null
    } | null> | null
  } | null
}

export type FormTranslationFragFragment = {
  __typename?: 'FormTranslation'
  slug?: string | null
  name?: string | null
  active?: boolean | null
  title?: string | null
  description?: string | null
  submitButtonText?: string | null
  nextButtonText?: string | null
  clearButtonText?: string | null
  previousButtonText?: string | null
  cardDisplay?: {
    __typename?: 'CardDisplayTranslation'
    detail1?: string | null
    detail2?: string | null
    detail3?: string | null
  } | null
}

export type FormWithQuizFragFragment = {
  __typename?: 'GfForm'
  isQuizForm?: boolean | null
  cssClass?: string | null
  description?: string | null
  disableEditing?: boolean | null
  hideJumpToSection?: boolean | null
  id: string
  reloadOnSubmit?: boolean | null
  restrictEntriesToUserCreated?: Array<string | null> | null
  restrictFormSubmissionByRole?: Array<string | null> | null
  slug?: string | null
  title?: string | null
  postTypeToCreate?: string | null
  isCAForm?: boolean | null
  isPrimary?: boolean | null
  group?: string | null
  databaseId: number
  isActive?: boolean | null
  version?: string | null
  quiz?: {
    __typename?: 'FormQuiz'
    gradingType?: QuizFieldGradingTypeEnum | null
    hasInstantFeedback?: boolean | null
    hasLetterConfirmationMessage?: boolean | null
    hasPassFailConfirmationMessage?: boolean | null
    isShuffleFieldsEnabled?: boolean | null
    maxScore?: number | null
    passPercent?: number | null
    failConfirmation?: {
      __typename?: 'FormQuizConfirmation'
      isAutoformatted?: boolean | null
      message?: string | null
    } | null
    letterConfirmation?: {
      __typename?: 'FormQuizConfirmation'
      isAutoformatted?: boolean | null
      message?: string | null
    } | null
    passConfirmation?: {
      __typename?: 'FormQuizConfirmation'
      isAutoformatted?: boolean | null
      message?: string | null
    } | null
    grades?: Array<{
      __typename?: 'FormQuizGrades'
      text?: string | null
      value?: number | null
    } | null> | null
  } | null
  formFields?: {
    __typename?: 'GfFormToFormFieldConnection'
    nodes?: Array<
      | {
          __typename: 'AddressField'
          label?: string | null
          description?: string | null
          isRequired?: boolean | null
          addressType?: AddressFieldTypeEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          copyValuesOptionLabel?: string | null
          copyValuesOptionFieldId?: number | null
          cssClass?: string | null
          defaultCountry?: AddressFieldCountryEnum | null
          defaultState?: string | null
          defaultProvince?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldCopyValuesOption?: boolean | null
          subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'AddressInputProperty'
            key?: string | null
            label?: string | null
            placeholder?: string | null
            defaultValue?: string | null
            customLabel?: string | null
            autocompleteAttribute?: string | null
            id?: number | null
            isHidden?: boolean | null
            name?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          addressTranslations?: Array<{
            __typename?: 'AddressTranlation'
            name?: string | null
            slug?: string | null
            label?: string | null
            description?: string | null
            address?: string | null
            address2?: string | null
            city?: string | null
            state?: string | null
            country?: string | null
            zip?: string | null
          } | null> | null
        }
      | {
          __typename: 'CaptchaField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'CheckboxField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasSelectAll?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'CheckboxFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          inputs?: Array<{
            __typename?: 'CheckboxInputProperty'
            id?: number | null
            label?: string | null
            name?: string | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'ComboSignatureField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          backgroundColor?: string | null
          borderColor?: string | null
          borderStyle?: SignatureFieldBorderStyleEnum | null
          borderWidth?: SignatureFieldBorderWidthEnum | null
          boxWidth?: number | null
          penSize?: number | null
          penColor?: string | null
          minSignatures?: number | null
          maxSignatures?: number | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          defaultValue?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          placeholder?: string | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'ComboSignatureFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'ConsentField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'DateField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          defaultValue?: string | null
          calendarIconType?: FormFieldCalendarIconTypeEnum | null
          inputName?: string | null
          dateFormat?: DateFieldFormatEnum | null
          adminLabel?: string | null
          calendarIconUrl?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'DateInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'EmailField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasEmailConfirmation?: boolean | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'EmailInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            name?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'FileUploadField'
          label?: string | null
          isRequired?: boolean | null
          maxFileSize?: number | null
          maxFiles?: number | null
          adminLabel?: string | null
          allowedExtensions?: Array<string | null> | null
          canAcceptMultipleFiles?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'FollowUpField'
          label?: string | null
          content?: string | null
          cssClass?: string | null
          followUpFormId?: number | null
          hasMargins?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          followUpForm?: {
            __typename?: 'GfForm'
            slug?: string | null
            title?: string | null
          } | null
          correctiveActionSettings?: Array<{
            __typename?: 'CorrectiveActionSettings'
            actionTaken?: string | null
            deficiency?: string | null
            deficiencyType?: Array<string | null> | null
            severity?: string | null
          } | null> | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'HiddenField'
          defaultValue?: string | null
          label?: string | null
          canPrepopulate?: boolean | null
          inputName?: string | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
        }
      | {
          __typename: 'HtmlField'
          content?: string | null
          label?: string | null
          cssClass?: string | null
          hasMargins?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'ListField'
          description?: string | null
          isRequired?: boolean | null
          inputName?: string | null
          label?: string | null
          maxRows?: number | null
          addIconUrl?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          deleteIconUrl?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasColumns?: boolean | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'ListFieldChoice'
            text?: string | null
            value?: string | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'MultiSelectField'
          label?: string | null
          description?: string | null
          isRequired?: boolean | null
          allowCustomOption?: boolean | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'MultiSelectFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'NameField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'NameInputProperty'
            key?: string | null
            label?: string | null
            placeholder?: string | null
            isHidden?: boolean | null
            defaultValue?: string | null
            customLabel?: string | null
            autocompleteAttribute?: string | null
            hasChoiceValue?: boolean | null
            id?: number | null
            name?: string | null
            choices?: Array<{
              __typename?: 'NameFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
          } | null> | null
          nameTranslations?: Array<{
            __typename?: 'NameTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            first?: string | null
            last?: string | null
            middle?: string | null
            prefix?: string | null
            suffix?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'NumberField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          rangeMin?: number | null
          rangeMax?: number | null
          numberFormat?: NumberFieldFormatEnum | null
          placeholder?: string | null
          defaultValue?: string | null
          calculationFormula?: string | null
          calculationRounding?: number | null
          errorMessage?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          isCalculation?: boolean | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'PageField'
          cssClass?: string | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          previousButton?: {
            __typename?: 'FormButton'
            text?: string | null
            type?: FormButtonTypeEnum | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          } | null
          nextButton?: {
            __typename?: 'FormButton'
            text?: string | null
            type?: FormButtonTypeEnum | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          } | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
          } | null> | null
        }
      | {
          __typename: 'PasswordField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PhoneField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          defaultValue?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          phoneFormat?: PhoneFieldFormatEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'PhotoField'
          label?: string | null
          isRequired?: boolean | null
          visibility?: FormFieldVisibilityEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'PostCategoryCheckboxField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCategoryMultiSelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCategoryRadioField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCategorySelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostContentField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomCheckboxField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomDateField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomEmailField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomFileuploadField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomHiddenField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomListField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomMultiSelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomNumberField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomPhoneField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomRadioField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomSelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomTextAreaField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomTextField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomTimeField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostCustomWebsiteField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostExcerptField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostImageField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTagsCheckboxField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTagsMultiSelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTagsRadioField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTagsSelectField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTagsTextField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'PostTitleField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'QuizCheckboxField'
          hasSelectAll?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          inputs?: Array<{
            __typename?: 'QuizInputProperty'
            label?: string | null
            id?: number | null
            name?: string | null
          } | null> | null
          choices?: Array<{
            __typename?: 'QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'QuizRadioField'
          shouldAllowDuplicates?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'QuizSelectField'
          defaultValue?: string | null
          placeholder?: string | null
          autocompleteAttribute?: string | null
          hasAutocomplete?: boolean | null
          hasEnhancedUI?: boolean | null
          adminLabel?: string | null
          answerExplanation?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          description?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasWeightedScore?: boolean | null
          inputName?: string | null
          isRequired?: boolean | null
          label?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldRandomizeQuizChoices?: boolean | null
          shouldShowAnswerExplanation?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'QuizFieldChoice'
            isCorrect?: boolean | null
            isOtherChoice?: boolean | null
            isSelected?: boolean | null
            text?: string | null
            value?: string | null
            weight?: number | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
        }
      | {
          __typename: 'RadioField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasChoiceValue?: boolean | null
          hasOtherChoice?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'RadioFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
            isOtherChoice?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'RememberMeField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'SectionField'
          label?: string | null
          description?: string | null
          cssClass?: string | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
        }
      | {
          __typename: 'SelectField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          defaultValue?: string | null
          allowCustomOption?: boolean | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasChoiceValue?: boolean | null
          hasEnhancedUI?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          placeholder?: string | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          choices?: Array<{
            __typename?: 'SelectFieldChoice'
            text?: string | null
            value?: string | null
            isSelected?: boolean | null
          } | null> | null
          choiceTranslations?: Array<{
            __typename?: 'ChoiceTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
            choices?: Array<string | null> | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'SignatureField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          penColor?: string | null
          penSize?: number | null
          backgroundColor?: string | null
          borderWidth?: SignatureFieldBorderWidthEnum | null
          borderStyle?: SignatureFieldBorderStyleEnum | null
          borderColor?: string | null
          adminLabel?: string | null
          boxWidth?: number | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'SubmitField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'TextAreaField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          maxLength?: number | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasRichTextEditor?: boolean | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'TextField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          maxLength?: number | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          autocompleteAttribute?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          hasAutocomplete?: boolean | null
          hasInputMask?: boolean | null
          inputMaskValue?: string | null
          isPasswordInput?: boolean | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          size?: FormFieldSizeEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename: 'TimeField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          timeFormat?: TimeFieldFormatEnum | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
          inputs?: Array<{
            __typename?: 'TimeInputProperty'
            autocompleteAttribute?: string | null
            customLabel?: string | null
            defaultValue?: string | null
            id?: number | null
            label?: string | null
            placeholder?: string | null
          } | null> | null
        }
      | {
          __typename: 'UsernameField'
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
        }
      | {
          __typename: 'WebsiteField'
          label?: string | null
          isRequired?: boolean | null
          description?: string | null
          placeholder?: string | null
          defaultValue?: string | null
          adminLabel?: string | null
          canPrepopulate?: boolean | null
          cssClass?: string | null
          descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
          errorMessage?: string | null
          inputName?: string | null
          labelPlacement?: FormFieldLabelPlacementEnum | null
          shouldAllowDuplicates?: boolean | null
          id: number
          type?: FormFieldTypeEnum | null
          inputType?: FormFieldTypeEnum | null
          pageNumber?: number | null
          visibility?: FormFieldVisibilityEnum | null
          displayOnly?: boolean | null
          basicTranslations?: Array<{
            __typename?: 'BasicTranslation'
            slug?: string | null
            name?: string | null
            label?: string | null
            description?: string | null
          } | null> | null
          conditionalLogic?: {
            __typename?: 'ConditionalLogic'
            actionType?: ConditionalLogicActionTypeEnum | null
            logicType?: ConditionalLogicLogicTypeEnum | null
            rules?: Array<{
              __typename?: 'ConditionalLogicRule'
              fieldId?: number | null
              operator?: FormRuleOperatorEnum | null
              value?: string | null
            } | null> | null
          } | null
        }
      | null
    > | null
  } | null
  confirmations?: Array<{
    __typename?: 'FormConfirmation'
    id?: string | null
    isActive?: boolean | null
    isDefault?: boolean | null
    message?: string | null
    name?: string | null
    pageId?: number | null
    queryString?: string | null
    type?: FormConfirmationTypeEnum | null
    url?: string | null
    conditionalLogic?: {
      __typename?: 'ConditionalLogic'
      actionType?: ConditionalLogicActionTypeEnum | null
      logicType?: ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'ConditionalLogicRule'
        fieldId?: number | null
        operator?: FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null> | null
  pagination?: {
    __typename?: 'FormPagination'
    pageNames?: Array<string | null> | null
    lastPageButton?: {
      __typename?: 'FormLastPageButton'
      text?: string | null
      type?: FormButtonTypeEnum | null
    } | null
  } | null
  submitButton?: {
    __typename?: 'FormSubmitButton'
    text?: string | null
    type?: FormButtonTypeEnum | null
    conditionalLogic?: {
      __typename?: 'ConditionalLogic'
      actionType?: ConditionalLogicActionTypeEnum | null
      logicType?: ConditionalLogicLogicTypeEnum | null
      rules?: Array<{
        __typename?: 'ConditionalLogicRule'
        fieldId?: number | null
        operator?: FormRuleOperatorEnum | null
        value?: string | null
      } | null> | null
    } | null
  } | null
  saveAndContinue?: {
    __typename?: 'FormSaveAndContinue'
    hasSaveAndContinue?: boolean | null
    buttonText?: string | null
  } | null
  translations?: Array<{
    __typename?: 'FormTranslation'
    slug?: string | null
    name?: string | null
    active?: boolean | null
    title?: string | null
    description?: string | null
    submitButtonText?: string | null
    nextButtonText?: string | null
    clearButtonText?: string | null
    previousButtonText?: string | null
    cardDisplay?: {
      __typename?: 'CardDisplayTranslation'
      detail1?: string | null
      detail2?: string | null
      detail3?: string | null
    } | null
  } | null> | null
}

export type Entries_FragFragment = {
  __typename?: 'GfSubmittedEntry'
  databaseId: number
  status?: EntryStatusEnum | null
  id: string
  dateCreated?: string | null
  formId?: string | null
  formDatabaseId?: number | null
  createdBy?: {
    __typename?: 'User'
    databaseId: number
    name?: string | null
  } | null
  gravityPdfTemplates?: Array<{
    __typename?: 'GravityPdfEntryTemplate'
    id?: string | null
    name?: string | null
  } | null> | null
  cardDetails?: {
    __typename?: 'CardDetails'
    heading?: string | null
    subheading?: string | null
    detail1?: {
      __typename?: 'CardDetailsDetail'
      label?: string | null
      value?: string | null
    } | null
    detail2?: {
      __typename?: 'CardDetailsDetail'
      label?: string | null
      value?: string | null
    } | null
    detail3?: {
      __typename?: 'CardDetailsDetail'
      label?: string | null
      value?: string | null
    } | null
  } | null
}

export type Entry_FragFragment = {
  __typename?: 'GfSubmittedEntry'
  databaseId: number
  status?: EntryStatusEnum | null
  formId?: string | null
  formDatabaseId?: number | null
  isDraft?: boolean | null
  dateCreated?: string | null
  createdBy?: {
    __typename?: 'User'
    name?: string | null
    databaseId: number
  } | null
  cardDetails?: {
    __typename?: 'CardDetails'
    heading?: string | null
    subheading?: string | null
    detail1?: {
      __typename?: 'CardDetailsDetail'
      label?: string | null
      value?: string | null
    } | null
    detail2?: {
      __typename?: 'CardDetailsDetail'
      label?: string | null
      value?: string | null
    } | null
    detail3?: {
      __typename?: 'CardDetailsDetail'
      label?: string | null
      value?: string | null
    } | null
  } | null
  gravityPdfTemplates?: Array<{
    __typename?: 'GravityPdfEntryTemplate'
    id?: string | null
    name?: string | null
  } | null> | null
  formFields?: {
    __typename?: 'GfEntryToFormFieldConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      endCursor?: string | null
      hasPreviousPage: boolean
    } | null
    edges?: Array<{
      __typename?: 'GfEntryToFormFieldConnectionEdge'
      node?:
        | {
            __typename: 'AddressField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            addressValues?: {
              __typename?: 'AddressFieldValue'
              street?: string | null
              city?: string | null
              country?: AddressFieldCountryEnum | null
              lineTwo?: string | null
              state?: string | null
              zip?: string | null
            } | null
          }
        | {
            __typename: 'CaptchaField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'CheckboxField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            checkboxValues?: Array<{
              __typename?: 'CheckboxFieldValue'
              inputId?: number | null
              value?: string | null
              text?: string | null
            } | null> | null
          }
        | {
            __typename: 'ComboSignatureField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            signatureValues?: Array<{
              __typename?: 'ComboSignatureFieldValue'
              signature?: string | null
              user?: {
                __typename?: 'User'
                databaseId: number
                name?: string | null
              } | null
            } | null> | null
          }
        | {
            __typename: 'ConsentField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'DateField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'EmailField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'FileUploadField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            fileUploadValues?: Array<{
              __typename?: 'FileUploadFieldValue'
              basePath?: string | null
              baseUrl?: string | null
              filename?: string | null
              url?: string | null
            } | null> | null
          }
        | {
            __typename: 'FollowUpField'
            cssClass?: string | null
            followUpFormId?: number | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            followUpForm?: {
              __typename?: 'GfForm'
              isCAForm?: boolean | null
            } | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename: 'HiddenField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'HtmlField'
            cssClass?: string | null
            content?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'ListField'
            cssClass?: string | null
            label?: string | null
            hasColumns?: boolean | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            choices?: Array<{
              __typename?: 'ListFieldChoice'
              text?: string | null
              value?: string | null
            } | null> | null
            listValues?: Array<{
              __typename?: 'ListFieldValue'
              values?: Array<string | null> | null
            } | null> | null
          }
        | {
            __typename: 'MultiSelectField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            values?: Array<string | null> | null
            valuesText?: Array<string | null> | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'NameField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            nameValues?: {
              __typename?: 'NameFieldValue'
              first?: string | null
              last?: string | null
              middle?: string | null
              prefix?: string | null
              suffix?: string | null
            } | null
          }
        | {
            __typename: 'NumberField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PageField'
            cssClass?: string | null
            pageNumber?: number | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PasswordField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PhoneField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PhotoField'
            cssClass?: string | null
            label?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            photoValues?: Array<{
              __typename?: 'Photo'
              id: string
              databaseId: number
              uri?: string | null
              featuredImage?: {
                __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
                node?: {
                  __typename?: 'MediaItem'
                  sourceUrl?: string | null
                  mediaDetails?: {
                    __typename?: 'MediaDetails'
                    sizes?: Array<{
                      __typename?: 'MediaSize'
                      width?: string | null
                      sourceUrl?: string | null
                    } | null> | null
                  } | null
                } | null
              } | null
            } | null> | null
          }
        | {
            __typename: 'PostCategoryCheckboxField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCategoryMultiSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCategoryRadioField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCategorySelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostContentField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomCheckboxField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomDateField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomEmailField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomFileuploadField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomHiddenField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomListField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomMultiSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomNumberField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomPhoneField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomRadioField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomTextAreaField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomTextField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomTimeField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostCustomWebsiteField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostExcerptField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostImageField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostTagsCheckboxField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostTagsMultiSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostTagsRadioField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostTagsSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostTagsTextField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'PostTitleField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'QuizCheckboxField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'QuizRadioField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'QuizSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'RadioField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            valueText?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'RememberMeField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'SectionField'
            cssClass?: string | null
            label?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'SelectField'
            cssClass?: string | null
            value?: string | null
            label?: string | null
            valueText?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'SignatureField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'SubmitField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'TextAreaField'
            cssClass?: string | null
            value?: string | null
            label?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'TextField'
            cssClass?: string | null
            value?: string | null
            label?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'TimeField'
            cssClass?: string | null
            label?: string | null
            timeFormat?: TimeFieldFormatEnum | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            timeValues?: {
              __typename?: 'TimeFieldValue'
              displayValue?: string | null
            } | null
          }
        | {
            __typename: 'UsernameField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | {
            __typename: 'WebsiteField'
            cssClass?: string | null
            label?: string | null
            value?: string | null
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
          }
        | null
    } | null> | null
  } | null
}

export type GfDeleteEntryMutationVariables = Exact<{
  input: DeleteGfEntryInput
}>

export type GfDeleteEntryMutation = {
  __typename?: 'RootMutation'
  deleteGfEntry?: {
    __typename?: 'DeleteGfEntryPayload'
    deletedId?: string | null
    entry?: {
      __typename?: 'GfSubmittedEntry'
      databaseId: number
      formId?: string | null
      formDatabaseId?: number | null
    } | null
  } | null
}

export type GfSubmitFormMutationVariables = Exact<{
  input: SubmitGfFormInput
}>

export type GfSubmitFormMutation = {
  __typename?: 'RootMutation'
  submitGfForm?: {
    __typename?: 'SubmitGfFormPayload'
    resumeUrl?: string | null
    errors?: Array<{
      __typename?: 'FieldError'
      id?: number | null
      message?: string | null
    } | null> | null
    entry?:
      | { __typename?: 'GfDraftEntry' }
      | {
          __typename?: 'GfSubmittedEntry'
          databaseId: number
          status?: EntryStatusEnum | null
          formId?: string | null
          formDatabaseId?: number | null
          isDraft?: boolean | null
          dateCreated?: string | null
          createdBy?: {
            __typename?: 'User'
            name?: string | null
            databaseId: number
          } | null
          cardDetails?: {
            __typename?: 'CardDetails'
            heading?: string | null
            subheading?: string | null
            detail1?: {
              __typename?: 'CardDetailsDetail'
              label?: string | null
              value?: string | null
            } | null
            detail2?: {
              __typename?: 'CardDetailsDetail'
              label?: string | null
              value?: string | null
            } | null
            detail3?: {
              __typename?: 'CardDetailsDetail'
              label?: string | null
              value?: string | null
            } | null
          } | null
          gravityPdfTemplates?: Array<{
            __typename?: 'GravityPdfEntryTemplate'
            id?: string | null
            name?: string | null
          } | null> | null
          formFields?: {
            __typename?: 'GfEntryToFormFieldConnection'
            pageInfo?: {
              __typename?: 'WPPageInfo'
              hasNextPage: boolean
              endCursor?: string | null
              hasPreviousPage: boolean
            } | null
            edges?: Array<{
              __typename?: 'GfEntryToFormFieldConnectionEdge'
              node?:
                | {
                    __typename: 'AddressField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                    addressValues?: {
                      __typename?: 'AddressFieldValue'
                      street?: string | null
                      city?: string | null
                      country?: AddressFieldCountryEnum | null
                      lineTwo?: string | null
                      state?: string | null
                      zip?: string | null
                    } | null
                  }
                | {
                    __typename: 'CaptchaField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'CheckboxField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                    checkboxValues?: Array<{
                      __typename?: 'CheckboxFieldValue'
                      inputId?: number | null
                      value?: string | null
                      text?: string | null
                    } | null> | null
                  }
                | {
                    __typename: 'ComboSignatureField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                    signatureValues?: Array<{
                      __typename?: 'ComboSignatureFieldValue'
                      signature?: string | null
                      user?: {
                        __typename?: 'User'
                        databaseId: number
                        name?: string | null
                      } | null
                    } | null> | null
                  }
                | {
                    __typename: 'ConsentField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'DateField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'EmailField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'FileUploadField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                    fileUploadValues?: Array<{
                      __typename?: 'FileUploadFieldValue'
                      basePath?: string | null
                      baseUrl?: string | null
                      filename?: string | null
                      url?: string | null
                    } | null> | null
                  }
                | {
                    __typename: 'FollowUpField'
                    cssClass?: string | null
                    followUpFormId?: number | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                    followUpForm?: {
                      __typename?: 'GfForm'
                      isCAForm?: boolean | null
                    } | null
                    conditionalLogic?: {
                      __typename?: 'ConditionalLogic'
                      actionType?: ConditionalLogicActionTypeEnum | null
                      logicType?: ConditionalLogicLogicTypeEnum | null
                      rules?: Array<{
                        __typename?: 'ConditionalLogicRule'
                        fieldId?: number | null
                        operator?: FormRuleOperatorEnum | null
                        value?: string | null
                      } | null> | null
                    } | null
                  }
                | {
                    __typename: 'HiddenField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'HtmlField'
                    cssClass?: string | null
                    content?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'ListField'
                    cssClass?: string | null
                    label?: string | null
                    hasColumns?: boolean | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                    choices?: Array<{
                      __typename?: 'ListFieldChoice'
                      text?: string | null
                      value?: string | null
                    } | null> | null
                    listValues?: Array<{
                      __typename?: 'ListFieldValue'
                      values?: Array<string | null> | null
                    } | null> | null
                  }
                | {
                    __typename: 'MultiSelectField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    values?: Array<string | null> | null
                    valuesText?: Array<string | null> | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'NameField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                    nameValues?: {
                      __typename?: 'NameFieldValue'
                      first?: string | null
                      last?: string | null
                      middle?: string | null
                      prefix?: string | null
                      suffix?: string | null
                    } | null
                  }
                | {
                    __typename: 'NumberField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PageField'
                    cssClass?: string | null
                    pageNumber?: number | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PasswordField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PhoneField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PhotoField'
                    cssClass?: string | null
                    label?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                    photoValues?: Array<{
                      __typename?: 'Photo'
                      id: string
                      databaseId: number
                      uri?: string | null
                      featuredImage?: {
                        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
                        node?: {
                          __typename?: 'MediaItem'
                          sourceUrl?: string | null
                          mediaDetails?: {
                            __typename?: 'MediaDetails'
                            sizes?: Array<{
                              __typename?: 'MediaSize'
                              width?: string | null
                              sourceUrl?: string | null
                            } | null> | null
                          } | null
                        } | null
                      } | null
                    } | null> | null
                  }
                | {
                    __typename: 'PostCategoryCheckboxField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCategoryMultiSelectField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCategoryRadioField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCategorySelectField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostContentField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomCheckboxField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomDateField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomEmailField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomFileuploadField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomHiddenField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomListField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomMultiSelectField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomNumberField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomPhoneField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomRadioField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomSelectField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomTextAreaField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomTextField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomTimeField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostCustomWebsiteField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostExcerptField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostImageField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostTagsCheckboxField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostTagsMultiSelectField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostTagsRadioField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostTagsSelectField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostTagsTextField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'PostTitleField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'QuizCheckboxField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'QuizRadioField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'QuizSelectField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'RadioField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    valueText?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'RememberMeField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'SectionField'
                    cssClass?: string | null
                    label?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'SelectField'
                    cssClass?: string | null
                    value?: string | null
                    label?: string | null
                    valueText?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'SignatureField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'SubmitField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'TextAreaField'
                    cssClass?: string | null
                    value?: string | null
                    label?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'TextField'
                    cssClass?: string | null
                    value?: string | null
                    label?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'TimeField'
                    cssClass?: string | null
                    label?: string | null
                    timeFormat?: TimeFieldFormatEnum | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                    timeValues?: {
                      __typename?: 'TimeFieldValue'
                      displayValue?: string | null
                    } | null
                  }
                | {
                    __typename: 'UsernameField'
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | {
                    __typename: 'WebsiteField'
                    cssClass?: string | null
                    label?: string | null
                    value?: string | null
                    id: number
                    type?: FormFieldTypeEnum | null
                    inputType?: FormFieldTypeEnum | null
                  }
                | null
            } | null> | null
          } | null
        }
      | null
  } | null
}

export type GfUpdateEntryMutationVariables = Exact<{
  input: UpdateGfEntryInput
}>

export type GfUpdateEntryMutation = {
  __typename?: 'RootMutation'
  updateGfEntry?: {
    __typename?: 'UpdateGfEntryPayload'
    errors?: Array<{
      __typename?: 'FieldError'
      id?: number | null
      message?: string | null
    } | null> | null
    entry?: {
      __typename?: 'GfSubmittedEntry'
      databaseId: number
      status?: EntryStatusEnum | null
      formId?: string | null
      formDatabaseId?: number | null
      isDraft?: boolean | null
      dateCreated?: string | null
      createdBy?: {
        __typename?: 'User'
        name?: string | null
        databaseId: number
      } | null
      cardDetails?: {
        __typename?: 'CardDetails'
        heading?: string | null
        subheading?: string | null
        detail1?: {
          __typename?: 'CardDetailsDetail'
          label?: string | null
          value?: string | null
        } | null
        detail2?: {
          __typename?: 'CardDetailsDetail'
          label?: string | null
          value?: string | null
        } | null
        detail3?: {
          __typename?: 'CardDetailsDetail'
          label?: string | null
          value?: string | null
        } | null
      } | null
      gravityPdfTemplates?: Array<{
        __typename?: 'GravityPdfEntryTemplate'
        id?: string | null
        name?: string | null
      } | null> | null
      formFields?: {
        __typename?: 'GfEntryToFormFieldConnection'
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          endCursor?: string | null
          hasPreviousPage: boolean
        } | null
        edges?: Array<{
          __typename?: 'GfEntryToFormFieldConnectionEdge'
          node?:
            | {
                __typename: 'AddressField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
                addressValues?: {
                  __typename?: 'AddressFieldValue'
                  street?: string | null
                  city?: string | null
                  country?: AddressFieldCountryEnum | null
                  lineTwo?: string | null
                  state?: string | null
                  zip?: string | null
                } | null
              }
            | {
                __typename: 'CaptchaField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'CheckboxField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
                checkboxValues?: Array<{
                  __typename?: 'CheckboxFieldValue'
                  inputId?: number | null
                  value?: string | null
                  text?: string | null
                } | null> | null
              }
            | {
                __typename: 'ComboSignatureField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
                signatureValues?: Array<{
                  __typename?: 'ComboSignatureFieldValue'
                  signature?: string | null
                  user?: {
                    __typename?: 'User'
                    databaseId: number
                    name?: string | null
                  } | null
                } | null> | null
              }
            | {
                __typename: 'ConsentField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'DateField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'EmailField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'FileUploadField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
                fileUploadValues?: Array<{
                  __typename?: 'FileUploadFieldValue'
                  basePath?: string | null
                  baseUrl?: string | null
                  filename?: string | null
                  url?: string | null
                } | null> | null
              }
            | {
                __typename: 'FollowUpField'
                cssClass?: string | null
                followUpFormId?: number | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
                followUpForm?: {
                  __typename?: 'GfForm'
                  isCAForm?: boolean | null
                } | null
                conditionalLogic?: {
                  __typename?: 'ConditionalLogic'
                  actionType?: ConditionalLogicActionTypeEnum | null
                  logicType?: ConditionalLogicLogicTypeEnum | null
                  rules?: Array<{
                    __typename?: 'ConditionalLogicRule'
                    fieldId?: number | null
                    operator?: FormRuleOperatorEnum | null
                    value?: string | null
                  } | null> | null
                } | null
              }
            | {
                __typename: 'HiddenField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'HtmlField'
                cssClass?: string | null
                content?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'ListField'
                cssClass?: string | null
                label?: string | null
                hasColumns?: boolean | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
                choices?: Array<{
                  __typename?: 'ListFieldChoice'
                  text?: string | null
                  value?: string | null
                } | null> | null
                listValues?: Array<{
                  __typename?: 'ListFieldValue'
                  values?: Array<string | null> | null
                } | null> | null
              }
            | {
                __typename: 'MultiSelectField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                values?: Array<string | null> | null
                valuesText?: Array<string | null> | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'NameField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
                nameValues?: {
                  __typename?: 'NameFieldValue'
                  first?: string | null
                  last?: string | null
                  middle?: string | null
                  prefix?: string | null
                  suffix?: string | null
                } | null
              }
            | {
                __typename: 'NumberField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PageField'
                cssClass?: string | null
                pageNumber?: number | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PasswordField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PhoneField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PhotoField'
                cssClass?: string | null
                label?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
                photoValues?: Array<{
                  __typename?: 'Photo'
                  id: string
                  databaseId: number
                  uri?: string | null
                  featuredImage?: {
                    __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
                    node?: {
                      __typename?: 'MediaItem'
                      sourceUrl?: string | null
                      mediaDetails?: {
                        __typename?: 'MediaDetails'
                        sizes?: Array<{
                          __typename?: 'MediaSize'
                          width?: string | null
                          sourceUrl?: string | null
                        } | null> | null
                      } | null
                    } | null
                  } | null
                } | null> | null
              }
            | {
                __typename: 'PostCategoryCheckboxField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCategoryMultiSelectField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCategoryRadioField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCategorySelectField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostContentField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomCheckboxField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomDateField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomEmailField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomFileuploadField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomHiddenField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomListField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomMultiSelectField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomNumberField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomPhoneField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomRadioField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomSelectField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomTextAreaField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomTextField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomTimeField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostCustomWebsiteField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostExcerptField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostImageField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostTagsCheckboxField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostTagsMultiSelectField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostTagsRadioField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostTagsSelectField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostTagsTextField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'PostTitleField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'QuizCheckboxField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'QuizRadioField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'QuizSelectField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'RadioField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                valueText?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'RememberMeField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'SectionField'
                cssClass?: string | null
                label?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'SelectField'
                cssClass?: string | null
                value?: string | null
                label?: string | null
                valueText?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'SignatureField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'SubmitField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'TextAreaField'
                cssClass?: string | null
                value?: string | null
                label?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'TextField'
                cssClass?: string | null
                value?: string | null
                label?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'TimeField'
                cssClass?: string | null
                label?: string | null
                timeFormat?: TimeFieldFormatEnum | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
                timeValues?: {
                  __typename?: 'TimeFieldValue'
                  displayValue?: string | null
                } | null
              }
            | {
                __typename: 'UsernameField'
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | {
                __typename: 'WebsiteField'
                cssClass?: string | null
                label?: string | null
                value?: string | null
                id: number
                type?: FormFieldTypeEnum | null
                inputType?: FormFieldTypeEnum | null
              }
            | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type GetComplianceReportingQueryVariables = Exact<{
  endDate: Scalars['String']['input']
  startDate: Scalars['String']['input']
  formId: Scalars['ID']['input']
  role: UserRoleEnum
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  companyIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >
  divisionIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >
  projectIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >
  orderBy?: InputMaybe<UserComplianceOrderByEnum>
  order?: InputMaybe<OrderEnum>
}>

export type GetComplianceReportingQuery = {
  __typename?: 'RootQuery'
  complianceDetails?: {
    __typename?: 'ComplianceDetails'
    actualCount?: number | null
    expectedCount?: number | null
    userDetails?: {
      __typename?: 'ComplianceDetailsToUserComplianceDetailsConnection'
      pageInfo?: {
        __typename?: 'WPPageInfo'
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
      } | null
      edges?: Array<{
        __typename?: 'ComplianceDetailsToUserComplianceDetailsConnectionEdge'
        node?: {
          __typename?: 'UserComplianceDetails'
          actualCount?: number | null
          expectedCount?: number | null
          datesSubmitted?: Array<string | null> | null
          user?: {
            __typename?: 'UserComplianceDetailsToUserConnectionEdge'
            node?: {
              __typename?: 'User'
              id: string
              name?: string | null
              email?: string | null
              username?: string | null
              databaseId: number
            } | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type GetFormQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
}>

export type GetFormQuery = {
  __typename?: 'RootQuery'
  gfForm?: {
    __typename?: 'GfForm'
    formFields?: {
      __typename?: 'GfFormToFormFieldConnection'
      nodes?: Array<
        | {
            __typename?: 'AddressField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            description?: string | null
            isRequired?: boolean | null
            addressType?: AddressFieldTypeEnum | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            copyValuesOptionLabel?: string | null
            copyValuesOptionFieldId?: number | null
            cssClass?: string | null
            defaultCountry?: AddressFieldCountryEnum | null
            defaultState?: string | null
            defaultProvince?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldCopyValuesOption?: boolean | null
            subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
            inputs?: Array<{
              __typename?: 'AddressInputProperty'
              key?: string | null
              label?: string | null
              placeholder?: string | null
              defaultValue?: string | null
              customLabel?: string | null
              autocompleteAttribute?: string | null
              id?: number | null
              isHidden?: boolean | null
              name?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            addressTranslations?: Array<{
              __typename?: 'AddressTranlation'
              name?: string | null
              slug?: string | null
              label?: string | null
              description?: string | null
              address?: string | null
              address2?: string | null
              city?: string | null
              state?: string | null
              country?: string | null
              zip?: string | null
            } | null> | null
          }
        | {
            __typename?: 'CaptchaField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'CheckboxField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasSelectAll?: boolean | null
            inputName?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            choices?: Array<{
              __typename?: 'CheckboxFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            inputs?: Array<{
              __typename?: 'CheckboxInputProperty'
              id?: number | null
              label?: string | null
              name?: string | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'ComboSignatureField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            backgroundColor?: string | null
            borderColor?: string | null
            borderStyle?: SignatureFieldBorderStyleEnum | null
            borderWidth?: SignatureFieldBorderWidthEnum | null
            boxWidth?: number | null
            penSize?: number | null
            penColor?: string | null
            minSignatures?: number | null
            maxSignatures?: number | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            defaultValue?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasChoiceValue?: boolean | null
            hasEnhancedUI?: boolean | null
            inputName?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            placeholder?: string | null
            shouldAllowDuplicates?: boolean | null
            size?: FormFieldSizeEnum | null
            choices?: Array<{
              __typename?: 'ComboSignatureFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'ConsentField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'DateField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            defaultValue?: string | null
            calendarIconType?: FormFieldCalendarIconTypeEnum | null
            inputName?: string | null
            dateFormat?: DateFieldFormatEnum | null
            adminLabel?: string | null
            calendarIconUrl?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            inputs?: Array<{
              __typename?: 'DateInputProperty'
              autocompleteAttribute?: string | null
              customLabel?: string | null
              defaultValue?: string | null
              id?: number | null
              label?: string | null
              placeholder?: string | null
            } | null> | null
          }
        | {
            __typename?: 'EmailField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasEmailConfirmation?: boolean | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: FormFieldSizeEnum | null
            subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            inputs?: Array<{
              __typename?: 'EmailInputProperty'
              autocompleteAttribute?: string | null
              customLabel?: string | null
              defaultValue?: string | null
              id?: number | null
              label?: string | null
              name?: string | null
              placeholder?: string | null
            } | null> | null
          }
        | {
            __typename?: 'FileUploadField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            maxFileSize?: number | null
            maxFiles?: number | null
            adminLabel?: string | null
            allowedExtensions?: Array<string | null> | null
            canAcceptMultipleFiles?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'FollowUpField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            content?: string | null
            cssClass?: string | null
            followUpFormId?: number | null
            hasMargins?: boolean | null
            followUpForm?: {
              __typename?: 'GfForm'
              slug?: string | null
              title?: string | null
            } | null
            correctiveActionSettings?: Array<{
              __typename?: 'CorrectiveActionSettings'
              actionTaken?: string | null
              deficiency?: string | null
              deficiencyType?: Array<string | null> | null
              severity?: string | null
            } | null> | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'HiddenField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            defaultValue?: string | null
            label?: string | null
            canPrepopulate?: boolean | null
            inputName?: string | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
          }
        | {
            __typename?: 'HtmlField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            content?: string | null
            label?: string | null
            cssClass?: string | null
            hasMargins?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'ListField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            description?: string | null
            isRequired?: boolean | null
            inputName?: string | null
            label?: string | null
            maxRows?: number | null
            addIconUrl?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            deleteIconUrl?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasColumns?: boolean | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            choices?: Array<{
              __typename?: 'ListFieldChoice'
              text?: string | null
              value?: string | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'MultiSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            description?: string | null
            isRequired?: boolean | null
            allowCustomOption?: boolean | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasEnhancedUI?: boolean | null
            inputName?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            size?: FormFieldSizeEnum | null
            choices?: Array<{
              __typename?: 'MultiSelectFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'NameField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
            inputs?: Array<{
              __typename?: 'NameInputProperty'
              key?: string | null
              label?: string | null
              placeholder?: string | null
              isHidden?: boolean | null
              defaultValue?: string | null
              customLabel?: string | null
              autocompleteAttribute?: string | null
              hasChoiceValue?: boolean | null
              id?: number | null
              name?: string | null
              choices?: Array<{
                __typename?: 'NameFieldChoice'
                text?: string | null
                value?: string | null
                isSelected?: boolean | null
              } | null> | null
            } | null> | null
            nameTranslations?: Array<{
              __typename?: 'NameTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              first?: string | null
              last?: string | null
              middle?: string | null
              prefix?: string | null
              suffix?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'NumberField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            rangeMin?: number | null
            rangeMax?: number | null
            numberFormat?: NumberFieldFormatEnum | null
            placeholder?: string | null
            defaultValue?: string | null
            calculationFormula?: string | null
            calculationRounding?: number | null
            errorMessage?: string | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            isCalculation?: boolean | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: FormFieldSizeEnum | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'PageField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            cssClass?: string | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            previousButton?: {
              __typename?: 'FormButton'
              text?: string | null
              type?: FormButtonTypeEnum | null
              conditionalLogic?: {
                __typename?: 'ConditionalLogic'
                actionType?: ConditionalLogicActionTypeEnum | null
                logicType?: ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            } | null
            nextButton?: {
              __typename?: 'FormButton'
              text?: string | null
              type?: FormButtonTypeEnum | null
              conditionalLogic?: {
                __typename?: 'ConditionalLogic'
                actionType?: ConditionalLogicActionTypeEnum | null
                logicType?: ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            } | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
            } | null> | null
          }
        | {
            __typename?: 'PasswordField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PhoneField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            defaultValue?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            inputName?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            phoneFormat?: PhoneFieldFormatEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: FormFieldSizeEnum | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'PhotoField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            inputName?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'PostCategoryCheckboxField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCategoryMultiSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCategoryRadioField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCategorySelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostContentField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomCheckboxField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomDateField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomEmailField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomFileuploadField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomHiddenField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomListField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomMultiSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomNumberField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomPhoneField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomRadioField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomTextAreaField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomTextField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomTimeField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostCustomWebsiteField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostExcerptField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostImageField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostTagsCheckboxField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostTagsMultiSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostTagsRadioField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostTagsSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostTagsTextField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'PostTitleField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'QuizCheckboxField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            hasSelectAll?: boolean | null
            adminLabel?: string | null
            answerExplanation?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasWeightedScore?: boolean | null
            inputName?: string | null
            isRequired?: boolean | null
            label?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldRandomizeQuizChoices?: boolean | null
            shouldShowAnswerExplanation?: boolean | null
            inputs?: Array<{
              __typename?: 'QuizInputProperty'
              label?: string | null
              id?: number | null
              name?: string | null
            } | null> | null
            choices?: Array<{
              __typename?: 'QuizFieldChoice'
              isCorrect?: boolean | null
              isOtherChoice?: boolean | null
              isSelected?: boolean | null
              text?: string | null
              value?: string | null
              weight?: number | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            choiceTranslations?: Array<{
              __typename?: 'ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
          }
        | {
            __typename?: 'QuizRadioField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            shouldAllowDuplicates?: boolean | null
            adminLabel?: string | null
            answerExplanation?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasWeightedScore?: boolean | null
            inputName?: string | null
            isRequired?: boolean | null
            label?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldRandomizeQuizChoices?: boolean | null
            shouldShowAnswerExplanation?: boolean | null
            choices?: Array<{
              __typename?: 'QuizFieldChoice'
              isCorrect?: boolean | null
              isOtherChoice?: boolean | null
              isSelected?: boolean | null
              text?: string | null
              value?: string | null
              weight?: number | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            choiceTranslations?: Array<{
              __typename?: 'ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
          }
        | {
            __typename?: 'QuizSelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            defaultValue?: string | null
            placeholder?: string | null
            autocompleteAttribute?: string | null
            hasAutocomplete?: boolean | null
            hasEnhancedUI?: boolean | null
            adminLabel?: string | null
            answerExplanation?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            description?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasWeightedScore?: boolean | null
            inputName?: string | null
            isRequired?: boolean | null
            label?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldRandomizeQuizChoices?: boolean | null
            shouldShowAnswerExplanation?: boolean | null
            choices?: Array<{
              __typename?: 'QuizFieldChoice'
              isCorrect?: boolean | null
              isOtherChoice?: boolean | null
              isSelected?: boolean | null
              text?: string | null
              value?: string | null
              weight?: number | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            choiceTranslations?: Array<{
              __typename?: 'ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
          }
        | {
            __typename?: 'RadioField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasChoiceValue?: boolean | null
            hasOtherChoice?: boolean | null
            inputName?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            choices?: Array<{
              __typename?: 'RadioFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
              isOtherChoice?: boolean | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'RememberMeField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'SectionField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            description?: string | null
            cssClass?: string | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
          }
        | {
            __typename?: 'SelectField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            defaultValue?: string | null
            allowCustomOption?: boolean | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasChoiceValue?: boolean | null
            hasEnhancedUI?: boolean | null
            inputName?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            placeholder?: string | null
            shouldAllowDuplicates?: boolean | null
            size?: FormFieldSizeEnum | null
            choices?: Array<{
              __typename?: 'SelectFieldChoice'
              text?: string | null
              value?: string | null
              isSelected?: boolean | null
            } | null> | null
            choiceTranslations?: Array<{
              __typename?: 'ChoiceTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
              choices?: Array<string | null> | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'SignatureField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            penColor?: string | null
            penSize?: number | null
            backgroundColor?: string | null
            borderWidth?: SignatureFieldBorderWidthEnum | null
            borderStyle?: SignatureFieldBorderStyleEnum | null
            borderColor?: string | null
            adminLabel?: string | null
            boxWidth?: number | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'SubmitField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'TextAreaField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            maxLength?: number | null
            placeholder?: string | null
            defaultValue?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasRichTextEditor?: boolean | null
            inputName?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: FormFieldSizeEnum | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'TextField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            maxLength?: number | null
            placeholder?: string | null
            defaultValue?: string | null
            adminLabel?: string | null
            autocompleteAttribute?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            hasAutocomplete?: boolean | null
            hasInputMask?: boolean | null
            inputMaskValue?: string | null
            isPasswordInput?: boolean | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            size?: FormFieldSizeEnum | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | {
            __typename?: 'TimeField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            timeFormat?: TimeFieldFormatEnum | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            subLabelPlacement?: FormFieldSubLabelPlacementEnum | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
            inputs?: Array<{
              __typename?: 'TimeInputProperty'
              autocompleteAttribute?: string | null
              customLabel?: string | null
              defaultValue?: string | null
              id?: number | null
              label?: string | null
              placeholder?: string | null
            } | null> | null
          }
        | {
            __typename?: 'UsernameField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
          }
        | {
            __typename?: 'WebsiteField'
            id: number
            type?: FormFieldTypeEnum | null
            inputType?: FormFieldTypeEnum | null
            pageNumber?: number | null
            visibility?: FormFieldVisibilityEnum | null
            displayOnly?: boolean | null
            label?: string | null
            isRequired?: boolean | null
            description?: string | null
            placeholder?: string | null
            defaultValue?: string | null
            adminLabel?: string | null
            canPrepopulate?: boolean | null
            cssClass?: string | null
            descriptionPlacement?: FormFieldDescriptionPlacementEnum | null
            errorMessage?: string | null
            inputName?: string | null
            labelPlacement?: FormFieldLabelPlacementEnum | null
            shouldAllowDuplicates?: boolean | null
            basicTranslations?: Array<{
              __typename?: 'BasicTranslation'
              slug?: string | null
              name?: string | null
              label?: string | null
              description?: string | null
            } | null> | null
            conditionalLogic?: {
              __typename?: 'ConditionalLogic'
              actionType?: ConditionalLogicActionTypeEnum | null
              logicType?: ConditionalLogicLogicTypeEnum | null
              rules?: Array<{
                __typename?: 'ConditionalLogicRule'
                fieldId?: number | null
                operator?: FormRuleOperatorEnum | null
                value?: string | null
              } | null> | null
            } | null
          }
        | null
      > | null
    } | null
  } | null
}

export type GfAllSubmittedEntriesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToGfSubmittedEntryConnectionWhereArgs>
}>

export type GfAllSubmittedEntriesQuery = {
  __typename?: 'RootQuery'
  gfSubmittedEntries?: {
    __typename?: 'RootQueryToGfSubmittedEntryConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      endCursor?: string | null
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToGfSubmittedEntryConnectionEdge'
      node?: {
        __typename?: 'GfSubmittedEntry'
        databaseId: number
        status?: EntryStatusEnum | null
        id: string
        dateCreated?: string | null
        formId?: string | null
        formDatabaseId?: number | null
        createdBy?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
        gravityPdfTemplates?: Array<{
          __typename?: 'GravityPdfEntryTemplate'
          id?: string | null
          name?: string | null
        } | null> | null
        cardDetails?: {
          __typename?: 'CardDetails'
          heading?: string | null
          subheading?: string | null
          detail1?: {
            __typename?: 'CardDetailsDetail'
            label?: string | null
            value?: string | null
          } | null
          detail2?: {
            __typename?: 'CardDetailsDetail'
            label?: string | null
            value?: string | null
          } | null
          detail3?: {
            __typename?: 'CardDetailsDetail'
            label?: string | null
            value?: string | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type GfGetSubmissionRulesQueryVariables = Exact<{
  formId: Scalars['ID']['input']
}>

export type GfGetSubmissionRulesQuery = {
  __typename?: 'RootQuery'
  gfForm?: {
    __typename?: 'GfForm'
    submissionRules?: Array<{
      __typename?: 'SubmissionRule'
      entryCount?: number | null
      frequency?: string | null
      role?: string | null
    } | null> | null
  } | null
}

export type GfGetSubmittedEntryQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
}>

export type GfGetSubmittedEntryQuery = {
  __typename?: 'RootQuery'
  gfSubmittedEntry?: {
    __typename?: 'GfSubmittedEntry'
    databaseId: number
    status?: EntryStatusEnum | null
    formId?: string | null
    formDatabaseId?: number | null
    isDraft?: boolean | null
    dateCreated?: string | null
    createdBy?: {
      __typename?: 'User'
      name?: string | null
      databaseId: number
    } | null
    cardDetails?: {
      __typename?: 'CardDetails'
      heading?: string | null
      subheading?: string | null
      detail1?: {
        __typename?: 'CardDetailsDetail'
        label?: string | null
        value?: string | null
      } | null
      detail2?: {
        __typename?: 'CardDetailsDetail'
        label?: string | null
        value?: string | null
      } | null
      detail3?: {
        __typename?: 'CardDetailsDetail'
        label?: string | null
        value?: string | null
      } | null
    } | null
    gravityPdfTemplates?: Array<{
      __typename?: 'GravityPdfEntryTemplate'
      id?: string | null
      name?: string | null
    } | null> | null
    formFields?: {
      __typename?: 'GfEntryToFormFieldConnection'
      pageInfo?: {
        __typename?: 'WPPageInfo'
        hasNextPage: boolean
        endCursor?: string | null
        hasPreviousPage: boolean
      } | null
      edges?: Array<{
        __typename?: 'GfEntryToFormFieldConnectionEdge'
        node?:
          | {
              __typename: 'AddressField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
              addressValues?: {
                __typename?: 'AddressFieldValue'
                street?: string | null
                city?: string | null
                country?: AddressFieldCountryEnum | null
                lineTwo?: string | null
                state?: string | null
                zip?: string | null
              } | null
            }
          | {
              __typename: 'CaptchaField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'CheckboxField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
              checkboxValues?: Array<{
                __typename?: 'CheckboxFieldValue'
                inputId?: number | null
                value?: string | null
                text?: string | null
              } | null> | null
            }
          | {
              __typename: 'ComboSignatureField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
              signatureValues?: Array<{
                __typename?: 'ComboSignatureFieldValue'
                signature?: string | null
                user?: {
                  __typename?: 'User'
                  databaseId: number
                  name?: string | null
                } | null
              } | null> | null
            }
          | {
              __typename: 'ConsentField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'DateField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'EmailField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'FileUploadField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
              fileUploadValues?: Array<{
                __typename?: 'FileUploadFieldValue'
                basePath?: string | null
                baseUrl?: string | null
                filename?: string | null
                url?: string | null
              } | null> | null
            }
          | {
              __typename: 'FollowUpField'
              cssClass?: string | null
              followUpFormId?: number | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
              followUpForm?: {
                __typename?: 'GfForm'
                isCAForm?: boolean | null
              } | null
              conditionalLogic?: {
                __typename?: 'ConditionalLogic'
                actionType?: ConditionalLogicActionTypeEnum | null
                logicType?: ConditionalLogicLogicTypeEnum | null
                rules?: Array<{
                  __typename?: 'ConditionalLogicRule'
                  fieldId?: number | null
                  operator?: FormRuleOperatorEnum | null
                  value?: string | null
                } | null> | null
              } | null
            }
          | {
              __typename: 'HiddenField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'HtmlField'
              cssClass?: string | null
              content?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'ListField'
              cssClass?: string | null
              label?: string | null
              hasColumns?: boolean | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
              choices?: Array<{
                __typename?: 'ListFieldChoice'
                text?: string | null
                value?: string | null
              } | null> | null
              listValues?: Array<{
                __typename?: 'ListFieldValue'
                values?: Array<string | null> | null
              } | null> | null
            }
          | {
              __typename: 'MultiSelectField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              values?: Array<string | null> | null
              valuesText?: Array<string | null> | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'NameField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
              nameValues?: {
                __typename?: 'NameFieldValue'
                first?: string | null
                last?: string | null
                middle?: string | null
                prefix?: string | null
                suffix?: string | null
              } | null
            }
          | {
              __typename: 'NumberField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PageField'
              cssClass?: string | null
              pageNumber?: number | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PasswordField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PhoneField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PhotoField'
              cssClass?: string | null
              label?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
              photoValues?: Array<{
                __typename?: 'Photo'
                id: string
                databaseId: number
                uri?: string | null
                featuredImage?: {
                  __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
                  node?: {
                    __typename?: 'MediaItem'
                    sourceUrl?: string | null
                    mediaDetails?: {
                      __typename?: 'MediaDetails'
                      sizes?: Array<{
                        __typename?: 'MediaSize'
                        width?: string | null
                        sourceUrl?: string | null
                      } | null> | null
                    } | null
                  } | null
                } | null
              } | null> | null
            }
          | {
              __typename: 'PostCategoryCheckboxField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCategoryMultiSelectField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCategoryRadioField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCategorySelectField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostContentField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomCheckboxField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomDateField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomEmailField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomFileuploadField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomHiddenField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomListField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomMultiSelectField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomNumberField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomPhoneField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomRadioField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomSelectField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomTextAreaField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomTextField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomTimeField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostCustomWebsiteField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostExcerptField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostImageField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostTagsCheckboxField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostTagsMultiSelectField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostTagsRadioField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostTagsSelectField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostTagsTextField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'PostTitleField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'QuizCheckboxField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'QuizRadioField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'QuizSelectField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'RadioField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              valueText?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'RememberMeField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'SectionField'
              cssClass?: string | null
              label?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'SelectField'
              cssClass?: string | null
              value?: string | null
              label?: string | null
              valueText?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'SignatureField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'SubmitField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'TextAreaField'
              cssClass?: string | null
              value?: string | null
              label?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'TextField'
              cssClass?: string | null
              value?: string | null
              label?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'TimeField'
              cssClass?: string | null
              label?: string | null
              timeFormat?: TimeFieldFormatEnum | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
              timeValues?: {
                __typename?: 'TimeFieldValue'
                displayValue?: string | null
              } | null
            }
          | {
              __typename: 'UsernameField'
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | {
              __typename: 'WebsiteField'
              cssClass?: string | null
              label?: string | null
              value?: string | null
              id: number
              type?: FormFieldTypeEnum | null
              inputType?: FormFieldTypeEnum | null
            }
          | null
      } | null> | null
    } | null
  } | null
}

export type Incident_Report_FragFragment = {
  __typename?: 'IncidentReport'
  id: string
  databaseId: number
  title?: string | null
  date?: string | null
  modified?: string | null
  modifiedGmt?: string | null
  lastEditedBy?: {
    __typename?: 'ContentNodeToEditLastConnectionEdge'
    node?: {
      __typename?: 'User'
      name?: string | null
      databaseId: number
    } | null
  } | null
  initialReport?: {
    __typename?: 'IncidentReport_Initialreport'
    employeeJobTitle?: string | null
    projectAddress?: string | null
    description?: string | null
  } | null
  additionalInformation?: {
    __typename?: 'IncidentReport_Additionalinformation'
    daysAwayWork?: number | null
    daysJobTransfer?: number | null
    hospitalName?: string | null
    physicianName?: string | null
    hospitalAddress?: string | null
    employeeAddress?: string | null
    employeeBirthDate?: string | null
    employeeDeathDate?: string | null
    employeeGender?: string | null
    employeeHireDate?: string | null
    employeeStartTime?: string | null
    hazardDescription?: string | null
    hasEmergencyTreatment?: boolean | null
    hasHospitalOvernight?: boolean | null
    incidentDate?: string | null
    incidentTime?: string | null
    injuryCause?: string | null
    injuryDescription?: string | null
    priorActions?: string | null
    witnesses?: Array<{
      __typename?: 'IncidentReport_Additionalinformation_witnesses'
      name?: string | null
      phone?: string | null
    } | null> | null
  } | null
  author?: {
    __typename?: 'NodeWithAuthorToUserConnectionEdge'
    node?: {
      __typename?: 'User'
      databaseId: number
      name?: string | null
    } | null
  } | null
  companies?: {
    __typename?: 'IncidentReportToCompanyConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToCompanyConnectionEdge'
      node?: {
        __typename?: 'Company'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'IncidentReportToDivisionConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToDivisionConnectionEdge'
      node?: {
        __typename?: 'Division'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  injuryAreas?: {
    __typename?: 'IncidentReportToInjuryAreaConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToInjuryAreaConnectionEdge'
      node?: {
        __typename?: 'InjuryArea'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  incidentReportClassifications?: {
    __typename?: 'IncidentReportToIncidentReportClassificationConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToIncidentReportClassificationConnectionEdge'
      node?: {
        __typename?: 'IncidentReportClassification'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  incidentTypes?: {
    __typename?: 'IncidentReportToIncidentTypeConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToIncidentTypeConnectionEdge'
      node?: {
        __typename?: 'IncidentType'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  incidentReportWorkerConnection?: {
    __typename?: 'IncidentReportToUserConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        databaseId: number
        name?: string | null
        email?: string | null
        firstName?: string | null
        lastName?: string | null
        employeeId?: string | null
        languages?: {
          __typename?: 'UserToUserLanguageConnection'
          edges?: Array<{
            __typename?: 'UserToUserLanguageConnectionEdge'
            node?: {
              __typename?: 'UserLanguage'
              id: string
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
  incidentReportProjectConnection?: {
    __typename?: 'IncidentReportToProjectConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToProjectConnectionEdge'
      node?: {
        __typename?: 'Project'
        databaseId: number
        title?: string | null
        number?: string | null
      } | null
    } | null> | null
  } | null
  incidentReportStatuses?: {
    __typename?: 'IncidentReportToIncidentReportStatusConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToIncidentReportStatusConnectionEdge'
      node?: {
        __typename?: 'IncidentReportStatus'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  fileAttachments?: {
    __typename?: 'IncidentReportToMediaItemConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToMediaItemConnectionEdge'
      node?: {
        __typename?: 'MediaItem'
        id: string
        title?: string | null
        description?: string | null
        databaseId: number
        parentDatabaseId?: number | null
        parentId?: string | null
        mediaItemUrl?: string | null
        fileSize?: number | null
        sourceUrl?: string | null
        altText?: string | null
        date?: string | null
        dateGmt?: string | null
        mediaType?: string | null
        mimeType?: string | null
        mediaDetails?: {
          __typename?: 'MediaDetails'
          sizes?: Array<{
            __typename?: 'MediaSize'
            sourceUrl?: string | null
            width?: string | null
            height?: string | null
            file?: string | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
  rootCauses?: {
    __typename?: 'IncidentReportToRootCauseConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToRootCauseConnectionEdge'
      node?: {
        __typename?: 'RootCause'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
}

export type Incident_Reports_FragFragment = {
  __typename?: 'IncidentReport'
  id: string
  databaseId: number
  title?: string | null
  date?: string | null
  modified?: string | null
  lastEditedBy?: {
    __typename?: 'ContentNodeToEditLastConnectionEdge'
    node?: {
      __typename?: 'User'
      name?: string | null
      databaseId: number
    } | null
  } | null
  author?: {
    __typename?: 'NodeWithAuthorToUserConnectionEdge'
    node?: {
      __typename?: 'User'
      databaseId: number
      name?: string | null
    } | null
  } | null
  fileAttachments?: {
    __typename?: 'IncidentReportToMediaItemConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToMediaItemConnectionEdge'
      node?: { __typename?: 'MediaItem'; databaseId: number; id: string } | null
    } | null> | null
  } | null
  incidentReportClassifications?: {
    __typename?: 'IncidentReportToIncidentReportClassificationConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToIncidentReportClassificationConnectionEdge'
      node?: {
        __typename?: 'IncidentReportClassification'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  incidentTypes?: {
    __typename?: 'IncidentReportToIncidentTypeConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToIncidentTypeConnectionEdge'
      node?: {
        __typename?: 'IncidentType'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  incidentReportStatuses?: {
    __typename?: 'IncidentReportToIncidentReportStatusConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToIncidentReportStatusConnectionEdge'
      node?: {
        __typename?: 'IncidentReportStatus'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  incidentReportProjectConnection?: {
    __typename?: 'IncidentReportToProjectConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToProjectConnectionEdge'
      node?: {
        __typename?: 'Project'
        databaseId: number
        title?: string | null
        number?: string | null
      } | null
    } | null> | null
  } | null
  incidentReportWorkerConnection?: {
    __typename?: 'IncidentReportToUserConnection'
    edges?: Array<{
      __typename?: 'IncidentReportToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        databaseId: number
        name?: string | null
      } | null
    } | null> | null
  } | null
}

export type CreateIncidentReportMutationVariables = Exact<{
  input: CreateIncidentReportInput
}>

export type CreateIncidentReportMutation = {
  __typename?: 'RootMutation'
  createIncidentReport?: {
    __typename?: 'CreateIncidentReportPayload'
    incidentReport?: {
      __typename?: 'IncidentReport'
      id: string
      databaseId: number
      title?: string | null
      date?: string | null
      modified?: string | null
      modifiedGmt?: string | null
      lastEditedBy?: {
        __typename?: 'ContentNodeToEditLastConnectionEdge'
        node?: {
          __typename?: 'User'
          name?: string | null
          databaseId: number
        } | null
      } | null
      initialReport?: {
        __typename?: 'IncidentReport_Initialreport'
        employeeJobTitle?: string | null
        projectAddress?: string | null
        description?: string | null
      } | null
      additionalInformation?: {
        __typename?: 'IncidentReport_Additionalinformation'
        daysAwayWork?: number | null
        daysJobTransfer?: number | null
        hospitalName?: string | null
        physicianName?: string | null
        hospitalAddress?: string | null
        employeeAddress?: string | null
        employeeBirthDate?: string | null
        employeeDeathDate?: string | null
        employeeGender?: string | null
        employeeHireDate?: string | null
        employeeStartTime?: string | null
        hazardDescription?: string | null
        hasEmergencyTreatment?: boolean | null
        hasHospitalOvernight?: boolean | null
        incidentDate?: string | null
        incidentTime?: string | null
        injuryCause?: string | null
        injuryDescription?: string | null
        priorActions?: string | null
        witnesses?: Array<{
          __typename?: 'IncidentReport_Additionalinformation_witnesses'
          name?: string | null
          phone?: string | null
        } | null> | null
      } | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      companies?: {
        __typename?: 'IncidentReportToCompanyConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'IncidentReportToDivisionConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      injuryAreas?: {
        __typename?: 'IncidentReportToInjuryAreaConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToInjuryAreaConnectionEdge'
          node?: {
            __typename?: 'InjuryArea'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      incidentReportClassifications?: {
        __typename?: 'IncidentReportToIncidentReportClassificationConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToIncidentReportClassificationConnectionEdge'
          node?: {
            __typename?: 'IncidentReportClassification'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      incidentTypes?: {
        __typename?: 'IncidentReportToIncidentTypeConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToIncidentTypeConnectionEdge'
          node?: {
            __typename?: 'IncidentType'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      incidentReportWorkerConnection?: {
        __typename?: 'IncidentReportToUserConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            databaseId: number
            name?: string | null
            email?: string | null
            firstName?: string | null
            lastName?: string | null
            employeeId?: string | null
            languages?: {
              __typename?: 'UserToUserLanguageConnection'
              edges?: Array<{
                __typename?: 'UserToUserLanguageConnectionEdge'
                node?: {
                  __typename?: 'UserLanguage'
                  id: string
                  name?: string | null
                  slug?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      incidentReportProjectConnection?: {
        __typename?: 'IncidentReportToProjectConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToProjectConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      incidentReportStatuses?: {
        __typename?: 'IncidentReportToIncidentReportStatusConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToIncidentReportStatusConnectionEdge'
          node?: {
            __typename?: 'IncidentReportStatus'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'IncidentReportToMediaItemConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            databaseId: number
            parentDatabaseId?: number | null
            parentId?: string | null
            mediaItemUrl?: string | null
            fileSize?: number | null
            sourceUrl?: string | null
            altText?: string | null
            date?: string | null
            dateGmt?: string | null
            mediaType?: string | null
            mimeType?: string | null
            mediaDetails?: {
              __typename?: 'MediaDetails'
              sizes?: Array<{
                __typename?: 'MediaSize'
                sourceUrl?: string | null
                width?: string | null
                height?: string | null
                file?: string | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      rootCauses?: {
        __typename?: 'IncidentReportToRootCauseConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToRootCauseConnectionEdge'
          node?: {
            __typename?: 'RootCause'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type DeleteIncidentReportMutationVariables = Exact<{
  input: DeleteIncidentReportInput
}>

export type DeleteIncidentReportMutation = {
  __typename?: 'RootMutation'
  deleteIncidentReport?: {
    __typename?: 'DeleteIncidentReportPayload'
    deletedId?: string | null
    incidentReport?: {
      __typename?: 'IncidentReport'
      id: string
      databaseId: number
      title?: string | null
      date?: string | null
      modified?: string | null
      modifiedGmt?: string | null
      lastEditedBy?: {
        __typename?: 'ContentNodeToEditLastConnectionEdge'
        node?: {
          __typename?: 'User'
          name?: string | null
          databaseId: number
        } | null
      } | null
      initialReport?: {
        __typename?: 'IncidentReport_Initialreport'
        employeeJobTitle?: string | null
        projectAddress?: string | null
        description?: string | null
      } | null
      additionalInformation?: {
        __typename?: 'IncidentReport_Additionalinformation'
        daysAwayWork?: number | null
        daysJobTransfer?: number | null
        hospitalName?: string | null
        physicianName?: string | null
        hospitalAddress?: string | null
        employeeAddress?: string | null
        employeeBirthDate?: string | null
        employeeDeathDate?: string | null
        employeeGender?: string | null
        employeeHireDate?: string | null
        employeeStartTime?: string | null
        hazardDescription?: string | null
        hasEmergencyTreatment?: boolean | null
        hasHospitalOvernight?: boolean | null
        incidentDate?: string | null
        incidentTime?: string | null
        injuryCause?: string | null
        injuryDescription?: string | null
        priorActions?: string | null
        witnesses?: Array<{
          __typename?: 'IncidentReport_Additionalinformation_witnesses'
          name?: string | null
          phone?: string | null
        } | null> | null
      } | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      companies?: {
        __typename?: 'IncidentReportToCompanyConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'IncidentReportToDivisionConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      injuryAreas?: {
        __typename?: 'IncidentReportToInjuryAreaConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToInjuryAreaConnectionEdge'
          node?: {
            __typename?: 'InjuryArea'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      incidentReportClassifications?: {
        __typename?: 'IncidentReportToIncidentReportClassificationConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToIncidentReportClassificationConnectionEdge'
          node?: {
            __typename?: 'IncidentReportClassification'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      incidentTypes?: {
        __typename?: 'IncidentReportToIncidentTypeConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToIncidentTypeConnectionEdge'
          node?: {
            __typename?: 'IncidentType'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      incidentReportWorkerConnection?: {
        __typename?: 'IncidentReportToUserConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            databaseId: number
            name?: string | null
            email?: string | null
            firstName?: string | null
            lastName?: string | null
            employeeId?: string | null
            languages?: {
              __typename?: 'UserToUserLanguageConnection'
              edges?: Array<{
                __typename?: 'UserToUserLanguageConnectionEdge'
                node?: {
                  __typename?: 'UserLanguage'
                  id: string
                  name?: string | null
                  slug?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      incidentReportProjectConnection?: {
        __typename?: 'IncidentReportToProjectConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToProjectConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      incidentReportStatuses?: {
        __typename?: 'IncidentReportToIncidentReportStatusConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToIncidentReportStatusConnectionEdge'
          node?: {
            __typename?: 'IncidentReportStatus'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'IncidentReportToMediaItemConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            databaseId: number
            parentDatabaseId?: number | null
            parentId?: string | null
            mediaItemUrl?: string | null
            fileSize?: number | null
            sourceUrl?: string | null
            altText?: string | null
            date?: string | null
            dateGmt?: string | null
            mediaType?: string | null
            mimeType?: string | null
            mediaDetails?: {
              __typename?: 'MediaDetails'
              sizes?: Array<{
                __typename?: 'MediaSize'
                sourceUrl?: string | null
                width?: string | null
                height?: string | null
                file?: string | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      rootCauses?: {
        __typename?: 'IncidentReportToRootCauseConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToRootCauseConnectionEdge'
          node?: {
            __typename?: 'RootCause'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type UpdateIncidentReportMutationVariables = Exact<{
  input: UpdateIncidentReportInput
}>

export type UpdateIncidentReportMutation = {
  __typename?: 'RootMutation'
  updateIncidentReport?: {
    __typename?: 'UpdateIncidentReportPayload'
    incidentReport?: {
      __typename?: 'IncidentReport'
      id: string
      databaseId: number
      title?: string | null
      date?: string | null
      modified?: string | null
      modifiedGmt?: string | null
      lastEditedBy?: {
        __typename?: 'ContentNodeToEditLastConnectionEdge'
        node?: {
          __typename?: 'User'
          name?: string | null
          databaseId: number
        } | null
      } | null
      initialReport?: {
        __typename?: 'IncidentReport_Initialreport'
        employeeJobTitle?: string | null
        projectAddress?: string | null
        description?: string | null
      } | null
      additionalInformation?: {
        __typename?: 'IncidentReport_Additionalinformation'
        daysAwayWork?: number | null
        daysJobTransfer?: number | null
        hospitalName?: string | null
        physicianName?: string | null
        hospitalAddress?: string | null
        employeeAddress?: string | null
        employeeBirthDate?: string | null
        employeeDeathDate?: string | null
        employeeGender?: string | null
        employeeHireDate?: string | null
        employeeStartTime?: string | null
        hazardDescription?: string | null
        hasEmergencyTreatment?: boolean | null
        hasHospitalOvernight?: boolean | null
        incidentDate?: string | null
        incidentTime?: string | null
        injuryCause?: string | null
        injuryDescription?: string | null
        priorActions?: string | null
        witnesses?: Array<{
          __typename?: 'IncidentReport_Additionalinformation_witnesses'
          name?: string | null
          phone?: string | null
        } | null> | null
      } | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      companies?: {
        __typename?: 'IncidentReportToCompanyConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'IncidentReportToDivisionConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      injuryAreas?: {
        __typename?: 'IncidentReportToInjuryAreaConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToInjuryAreaConnectionEdge'
          node?: {
            __typename?: 'InjuryArea'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      incidentReportClassifications?: {
        __typename?: 'IncidentReportToIncidentReportClassificationConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToIncidentReportClassificationConnectionEdge'
          node?: {
            __typename?: 'IncidentReportClassification'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      incidentTypes?: {
        __typename?: 'IncidentReportToIncidentTypeConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToIncidentTypeConnectionEdge'
          node?: {
            __typename?: 'IncidentType'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      incidentReportWorkerConnection?: {
        __typename?: 'IncidentReportToUserConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            databaseId: number
            name?: string | null
            email?: string | null
            firstName?: string | null
            lastName?: string | null
            employeeId?: string | null
            languages?: {
              __typename?: 'UserToUserLanguageConnection'
              edges?: Array<{
                __typename?: 'UserToUserLanguageConnectionEdge'
                node?: {
                  __typename?: 'UserLanguage'
                  id: string
                  name?: string | null
                  slug?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      incidentReportProjectConnection?: {
        __typename?: 'IncidentReportToProjectConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToProjectConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      incidentReportStatuses?: {
        __typename?: 'IncidentReportToIncidentReportStatusConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToIncidentReportStatusConnectionEdge'
          node?: {
            __typename?: 'IncidentReportStatus'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      fileAttachments?: {
        __typename?: 'IncidentReportToMediaItemConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            databaseId: number
            parentDatabaseId?: number | null
            parentId?: string | null
            mediaItemUrl?: string | null
            fileSize?: number | null
            sourceUrl?: string | null
            altText?: string | null
            date?: string | null
            dateGmt?: string | null
            mediaType?: string | null
            mimeType?: string | null
            mediaDetails?: {
              __typename?: 'MediaDetails'
              sizes?: Array<{
                __typename?: 'MediaSize'
                sourceUrl?: string | null
                width?: string | null
                height?: string | null
                file?: string | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      rootCauses?: {
        __typename?: 'IncidentReportToRootCauseConnection'
        edges?: Array<{
          __typename?: 'IncidentReportToRootCauseConnectionEdge'
          node?: {
            __typename?: 'RootCause'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type AllIncidentReportsQueryVariables = Exact<{
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToIncidentReportConnectionWhereArgs>
}>

export type AllIncidentReportsQuery = {
  __typename?: 'RootQuery'
  incidentReports?: {
    __typename?: 'RootQueryToIncidentReportConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToIncidentReportConnectionEdge'
      cursor?: string | null
      node?: {
        __typename?: 'IncidentReport'
        id: string
        databaseId: number
        title?: string | null
        date?: string | null
        modified?: string | null
        modifiedGmt?: string | null
        lastEditedBy?: {
          __typename?: 'ContentNodeToEditLastConnectionEdge'
          node?: {
            __typename?: 'User'
            name?: string | null
            databaseId: number
          } | null
        } | null
        initialReport?: {
          __typename?: 'IncidentReport_Initialreport'
          employeeJobTitle?: string | null
          projectAddress?: string | null
          description?: string | null
        } | null
        additionalInformation?: {
          __typename?: 'IncidentReport_Additionalinformation'
          daysAwayWork?: number | null
          daysJobTransfer?: number | null
          hospitalName?: string | null
          physicianName?: string | null
          hospitalAddress?: string | null
          employeeAddress?: string | null
          employeeBirthDate?: string | null
          employeeDeathDate?: string | null
          employeeGender?: string | null
          employeeHireDate?: string | null
          employeeStartTime?: string | null
          hazardDescription?: string | null
          hasEmergencyTreatment?: boolean | null
          hasHospitalOvernight?: boolean | null
          incidentDate?: string | null
          incidentTime?: string | null
          injuryCause?: string | null
          injuryDescription?: string | null
          priorActions?: string | null
          witnesses?: Array<{
            __typename?: 'IncidentReport_Additionalinformation_witnesses'
            name?: string | null
            phone?: string | null
          } | null> | null
        } | null
        author?: {
          __typename?: 'NodeWithAuthorToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            databaseId: number
            name?: string | null
          } | null
        } | null
        companies?: {
          __typename?: 'IncidentReportToCompanyConnection'
          edges?: Array<{
            __typename?: 'IncidentReportToCompanyConnectionEdge'
            node?: {
              __typename?: 'Company'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        divisions?: {
          __typename?: 'IncidentReportToDivisionConnection'
          edges?: Array<{
            __typename?: 'IncidentReportToDivisionConnectionEdge'
            node?: {
              __typename?: 'Division'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        injuryAreas?: {
          __typename?: 'IncidentReportToInjuryAreaConnection'
          edges?: Array<{
            __typename?: 'IncidentReportToInjuryAreaConnectionEdge'
            node?: {
              __typename?: 'InjuryArea'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        incidentReportClassifications?: {
          __typename?: 'IncidentReportToIncidentReportClassificationConnection'
          edges?: Array<{
            __typename?: 'IncidentReportToIncidentReportClassificationConnectionEdge'
            node?: {
              __typename?: 'IncidentReportClassification'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        incidentTypes?: {
          __typename?: 'IncidentReportToIncidentTypeConnection'
          edges?: Array<{
            __typename?: 'IncidentReportToIncidentTypeConnectionEdge'
            node?: {
              __typename?: 'IncidentType'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        incidentReportWorkerConnection?: {
          __typename?: 'IncidentReportToUserConnection'
          edges?: Array<{
            __typename?: 'IncidentReportToUserConnectionEdge'
            node?: {
              __typename?: 'User'
              databaseId: number
              name?: string | null
              email?: string | null
              firstName?: string | null
              lastName?: string | null
              employeeId?: string | null
              languages?: {
                __typename?: 'UserToUserLanguageConnection'
                edges?: Array<{
                  __typename?: 'UserToUserLanguageConnectionEdge'
                  node?: {
                    __typename?: 'UserLanguage'
                    id: string
                    name?: string | null
                    slug?: string | null
                  } | null
                } | null> | null
              } | null
            } | null
          } | null> | null
        } | null
        incidentReportProjectConnection?: {
          __typename?: 'IncidentReportToProjectConnection'
          edges?: Array<{
            __typename?: 'IncidentReportToProjectConnectionEdge'
            node?: {
              __typename?: 'Project'
              databaseId: number
              title?: string | null
              number?: string | null
            } | null
          } | null> | null
        } | null
        incidentReportStatuses?: {
          __typename?: 'IncidentReportToIncidentReportStatusConnection'
          edges?: Array<{
            __typename?: 'IncidentReportToIncidentReportStatusConnectionEdge'
            node?: {
              __typename?: 'IncidentReportStatus'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        fileAttachments?: {
          __typename?: 'IncidentReportToMediaItemConnection'
          edges?: Array<{
            __typename?: 'IncidentReportToMediaItemConnectionEdge'
            node?: {
              __typename?: 'MediaItem'
              id: string
              title?: string | null
              description?: string | null
              databaseId: number
              parentDatabaseId?: number | null
              parentId?: string | null
              mediaItemUrl?: string | null
              fileSize?: number | null
              sourceUrl?: string | null
              altText?: string | null
              date?: string | null
              dateGmt?: string | null
              mediaType?: string | null
              mimeType?: string | null
              mediaDetails?: {
                __typename?: 'MediaDetails'
                sizes?: Array<{
                  __typename?: 'MediaSize'
                  sourceUrl?: string | null
                  width?: string | null
                  height?: string | null
                  file?: string | null
                } | null> | null
              } | null
            } | null
          } | null> | null
        } | null
        rootCauses?: {
          __typename?: 'IncidentReportToRootCauseConnection'
          edges?: Array<{
            __typename?: 'IncidentReportToRootCauseConnectionEdge'
            node?: {
              __typename?: 'RootCause'
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type GetIncidentReportQueryVariables = Exact<{
  id: Scalars['ID']['input']
  idType?: InputMaybe<IncidentReportIdType>
}>

export type GetIncidentReportQuery = {
  __typename?: 'RootQuery'
  incidentReport?: {
    __typename?: 'IncidentReport'
    id: string
    databaseId: number
    title?: string | null
    date?: string | null
    modified?: string | null
    modifiedGmt?: string | null
    lastEditedBy?: {
      __typename?: 'ContentNodeToEditLastConnectionEdge'
      node?: {
        __typename?: 'User'
        name?: string | null
        databaseId: number
      } | null
    } | null
    initialReport?: {
      __typename?: 'IncidentReport_Initialreport'
      employeeJobTitle?: string | null
      projectAddress?: string | null
      description?: string | null
    } | null
    additionalInformation?: {
      __typename?: 'IncidentReport_Additionalinformation'
      daysAwayWork?: number | null
      daysJobTransfer?: number | null
      hospitalName?: string | null
      physicianName?: string | null
      hospitalAddress?: string | null
      employeeAddress?: string | null
      employeeBirthDate?: string | null
      employeeDeathDate?: string | null
      employeeGender?: string | null
      employeeHireDate?: string | null
      employeeStartTime?: string | null
      hazardDescription?: string | null
      hasEmergencyTreatment?: boolean | null
      hasHospitalOvernight?: boolean | null
      incidentDate?: string | null
      incidentTime?: string | null
      injuryCause?: string | null
      injuryDescription?: string | null
      priorActions?: string | null
      witnesses?: Array<{
        __typename?: 'IncidentReport_Additionalinformation_witnesses'
        name?: string | null
        phone?: string | null
      } | null> | null
    } | null
    author?: {
      __typename?: 'NodeWithAuthorToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        databaseId: number
        name?: string | null
      } | null
    } | null
    companies?: {
      __typename?: 'IncidentReportToCompanyConnection'
      edges?: Array<{
        __typename?: 'IncidentReportToCompanyConnectionEdge'
        node?: {
          __typename?: 'Company'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    divisions?: {
      __typename?: 'IncidentReportToDivisionConnection'
      edges?: Array<{
        __typename?: 'IncidentReportToDivisionConnectionEdge'
        node?: {
          __typename?: 'Division'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    injuryAreas?: {
      __typename?: 'IncidentReportToInjuryAreaConnection'
      edges?: Array<{
        __typename?: 'IncidentReportToInjuryAreaConnectionEdge'
        node?: {
          __typename?: 'InjuryArea'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    incidentReportClassifications?: {
      __typename?: 'IncidentReportToIncidentReportClassificationConnection'
      edges?: Array<{
        __typename?: 'IncidentReportToIncidentReportClassificationConnectionEdge'
        node?: {
          __typename?: 'IncidentReportClassification'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    incidentTypes?: {
      __typename?: 'IncidentReportToIncidentTypeConnection'
      edges?: Array<{
        __typename?: 'IncidentReportToIncidentTypeConnectionEdge'
        node?: {
          __typename?: 'IncidentType'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    incidentReportWorkerConnection?: {
      __typename?: 'IncidentReportToUserConnection'
      edges?: Array<{
        __typename?: 'IncidentReportToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
          email?: string | null
          firstName?: string | null
          lastName?: string | null
          employeeId?: string | null
          languages?: {
            __typename?: 'UserToUserLanguageConnection'
            edges?: Array<{
              __typename?: 'UserToUserLanguageConnectionEdge'
              node?: {
                __typename?: 'UserLanguage'
                id: string
                name?: string | null
                slug?: string | null
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
    incidentReportProjectConnection?: {
      __typename?: 'IncidentReportToProjectConnection'
      edges?: Array<{
        __typename?: 'IncidentReportToProjectConnectionEdge'
        node?: {
          __typename?: 'Project'
          databaseId: number
          title?: string | null
          number?: string | null
        } | null
      } | null> | null
    } | null
    incidentReportStatuses?: {
      __typename?: 'IncidentReportToIncidentReportStatusConnection'
      edges?: Array<{
        __typename?: 'IncidentReportToIncidentReportStatusConnectionEdge'
        node?: {
          __typename?: 'IncidentReportStatus'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    fileAttachments?: {
      __typename?: 'IncidentReportToMediaItemConnection'
      edges?: Array<{
        __typename?: 'IncidentReportToMediaItemConnectionEdge'
        node?: {
          __typename?: 'MediaItem'
          id: string
          title?: string | null
          description?: string | null
          databaseId: number
          parentDatabaseId?: number | null
          parentId?: string | null
          mediaItemUrl?: string | null
          fileSize?: number | null
          sourceUrl?: string | null
          altText?: string | null
          date?: string | null
          dateGmt?: string | null
          mediaType?: string | null
          mimeType?: string | null
          mediaDetails?: {
            __typename?: 'MediaDetails'
            sizes?: Array<{
              __typename?: 'MediaSize'
              sourceUrl?: string | null
              width?: string | null
              height?: string | null
              file?: string | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
    rootCauses?: {
      __typename?: 'IncidentReportToRootCauseConnection'
      edges?: Array<{
        __typename?: 'IncidentReportToRootCauseConnectionEdge'
        node?: {
          __typename?: 'RootCause'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type GetOsha300ReportQueryVariables = Exact<{
  start?: InputMaybe<Scalars['String']['input']>
  end?: InputMaybe<Scalars['String']['input']>
}>

export type GetOsha300ReportQuery = {
  __typename?: 'RootQuery'
  incidentReportData?: {
    __typename?: 'IncidentReportData'
    casesWithDeaths?: number | null
    casesWithDaysAwayFromWork?: number | null
    casesWithJobRestrictions?: number | null
    otherRecordableCases?: number | null
    daysAwayFromWork?: number | null
    daysWithRestrictions?: number | null
    injuries?: number | null
    skinDisorders?: number | null
    respiratoryConditions?: number | null
    poisonings?: number | null
    hearingLoss?: number | null
    otherIllnesses?: number | null
  } | null
}

export type IntegrationConfigQueryVariables = Exact<{ [key: string]: never }>

export type IntegrationConfigQuery = {
  __typename?: 'RootQuery'
  integrationConfig?: {
    __typename?: 'IntegrationConfig'
    isSyncDisabled?: boolean | null
    isSyncInProgress?: boolean | null
    lastSyncDate?: string | null
    source?: IntegrationSourceEnum | null
    activeIntegrations?: Array<{
      __typename?: 'ActiveIntegration'
      sourcedFields?: Array<string | null> | null
      destinationType?: IntegrationWpDataTypeEnum | null
      sourceType?: IntegrationSourceDataTypeEnum | null
    } | null> | null
  } | null
}

export type ToggleIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type ToggleIntegrationMutation = {
  __typename?: 'RootMutation'
  resyncIntegrationData?: {
    __typename?: 'ResyncIntegrationDataPayload'
    success?: boolean | null
  } | null
}

export type Lesson_FragFragment = {
  __typename?: 'Lesson'
  id: string
  databaseId: number
  slug?: string | null
  title?: string | null
  excerpt?: string | null
  lessonDetails?: {
    __typename?: 'Lesson_Lessondetails'
    lessonDescription?: string | null
  } | null
  trainingTypes?: {
    __typename?: 'LessonToTrainingTypeConnection'
    edges?: Array<{
      __typename?: 'LessonToTrainingTypeConnectionEdge'
      node?: {
        __typename?: 'TrainingType'
        id: string
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  lessonCategories?: {
    __typename?: 'LessonToLessonCategoryConnection'
    edges?: Array<{
      __typename?: 'LessonToLessonCategoryConnectionEdge'
      node?: {
        __typename?: 'LessonCategory'
        id: string
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  providers?: {
    __typename?: 'LessonToProviderConnection'
    edges?: Array<{
      __typename?: 'LessonToProviderConnectionEdge'
      node?: {
        __typename?: 'Provider'
        id: string
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  featuredImage?: {
    __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
    node?: {
      __typename?: 'MediaItem'
      id: string
      title?: string | null
      description?: string | null
      mediaItemUrl?: string | null
      sizes?: string | null
      databaseId: number
      sourceUrl?: string | null
    } | null
  } | null
  lessonDifficulties?: {
    __typename?: 'LessonToLessonDifficultyConnection'
    edges?: Array<{
      __typename?: 'LessonToLessonDifficultyConnectionEdge'
      node?: {
        __typename?: 'LessonDifficulty'
        id: string
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  lessonDocumentConnectionConnection?: {
    __typename?: 'LessonToDocumentConnection'
    edges?: Array<{
      __typename?: 'LessonToDocumentConnectionEdge'
      node?: {
        __typename?: 'Document'
        id: string
        databaseId: number
        title?: string | null
      } | null
    } | null> | null
  } | null
}

export type AllLessonsQueryVariables = Exact<{
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToLessonConnectionWhereArgs>
}>

export type AllLessonsQuery = {
  __typename?: 'RootQuery'
  lessons?: {
    __typename?: 'RootQueryToLessonConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToLessonConnectionEdge'
      cursor?: string | null
      node?: {
        __typename?: 'Lesson'
        id: string
        databaseId: number
        slug?: string | null
        title?: string | null
        excerpt?: string | null
        lessonDetails?: {
          __typename?: 'Lesson_Lessondetails'
          lessonDescription?: string | null
        } | null
        trainingTypes?: {
          __typename?: 'LessonToTrainingTypeConnection'
          edges?: Array<{
            __typename?: 'LessonToTrainingTypeConnectionEdge'
            node?: {
              __typename?: 'TrainingType'
              id: string
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        lessonCategories?: {
          __typename?: 'LessonToLessonCategoryConnection'
          edges?: Array<{
            __typename?: 'LessonToLessonCategoryConnectionEdge'
            node?: {
              __typename?: 'LessonCategory'
              id: string
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        providers?: {
          __typename?: 'LessonToProviderConnection'
          edges?: Array<{
            __typename?: 'LessonToProviderConnectionEdge'
            node?: {
              __typename?: 'Provider'
              id: string
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        featuredImage?: {
          __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            title?: string | null
            description?: string | null
            mediaItemUrl?: string | null
            sizes?: string | null
            databaseId: number
            sourceUrl?: string | null
          } | null
        } | null
        lessonDifficulties?: {
          __typename?: 'LessonToLessonDifficultyConnection'
          edges?: Array<{
            __typename?: 'LessonToLessonDifficultyConnectionEdge'
            node?: {
              __typename?: 'LessonDifficulty'
              id: string
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        lessonDocumentConnectionConnection?: {
          __typename?: 'LessonToDocumentConnection'
          edges?: Array<{
            __typename?: 'LessonToDocumentConnectionEdge'
            node?: {
              __typename?: 'Document'
              id: string
              databaseId: number
              title?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type Media_Item_FragFragment = {
  __typename?: 'MediaItem'
  id: string
  title?: string | null
  description?: string | null
  databaseId: number
  parentDatabaseId?: number | null
  parentId?: string | null
  mediaItemUrl?: string | null
  fileSize?: number | null
  sourceUrl?: string | null
  altText?: string | null
  date?: string | null
  dateGmt?: string | null
  mediaType?: string | null
  mimeType?: string | null
  mediaDetails?: {
    __typename?: 'MediaDetails'
    sizes?: Array<{
      __typename?: 'MediaSize'
      sourceUrl?: string | null
      width?: string | null
      height?: string | null
      file?: string | null
    } | null> | null
  } | null
}

export type DeleteMediaItemMutationVariables = Exact<{
  input: DeleteMediaItemInput
}>

export type DeleteMediaItemMutation = {
  __typename?: 'RootMutation'
  deleteMediaItem?: {
    __typename?: 'DeleteMediaItemPayload'
    deletedId?: string | null
    mediaItem?: {
      __typename?: 'MediaItem'
      id: string
      title?: string | null
      description?: string | null
      databaseId: number
      parentDatabaseId?: number | null
      parentId?: string | null
      mediaItemUrl?: string | null
      fileSize?: number | null
      sourceUrl?: string | null
      altText?: string | null
      date?: string | null
      dateGmt?: string | null
      mediaType?: string | null
      mimeType?: string | null
      mediaDetails?: {
        __typename?: 'MediaDetails'
        sizes?: Array<{
          __typename?: 'MediaSize'
          sourceUrl?: string | null
          width?: string | null
          height?: string | null
          file?: string | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type Pdf_FragFragment = {
  __typename?: 'MediaItem'
  title?: string | null
  mediaItemUrl?: string | null
  mimeType?: string | null
  fileSize?: number | null
  date?: string | null
  databaseId: number
}

export type CreateEntryPdfMutationVariables = Exact<{
  entryId: Scalars['Int']['input']
  templateId: Scalars['ID']['input']
}>

export type CreateEntryPdfMutation = {
  __typename?: 'RootMutation'
  gravityPdfCreateEntryPdf?: {
    __typename?: 'GravityPdfCreateEntryPdfPayload'
    pdfUrl?: string | null
  } | null
}

export type CreateEntryPdfsMutationVariables = Exact<{
  entryIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  templateId: Scalars['ID']['input']
}>

export type CreateEntryPdfsMutation = {
  __typename?: 'RootMutation'
  gravityPdfCreateEntryPdfs?: {
    __typename?: 'GravityPdfCreateEntryPdfsPayload'
    pdfUrls?: Array<string | null> | null
  } | null
}

export type Photo_FragFragment = {
  __typename?: 'Photo'
  id: string
  databaseId: number
  date?: string | null
  dateTaken?: string | null
  correctiveActionIds?: Array<number | null> | null
  author?: {
    __typename?: 'NodeWithAuthorToUserConnectionEdge'
    node?: {
      __typename?: 'User'
      id: string
      databaseId: number
      name?: string | null
    } | null
  } | null
  featuredImage?: {
    __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
    node?: {
      __typename?: 'MediaItem'
      id: string
      databaseId: number
      sourceUrl?: string | null
      mediaDetails?: {
        __typename?: 'MediaDetails'
        sizes?: Array<{
          __typename?: 'MediaSize'
          width?: string | null
          height?: string | null
          sourceUrl?: string | null
        } | null> | null
      } | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          id: string
          databaseId: number
          name?: string | null
        } | null
      } | null
    } | null
  } | null
  geolocation?: {
    __typename?: 'Geolocation'
    lat?: number | null
    lng?: number | null
  } | null
  correctiveActions?: Array<{
    __typename?: 'Post'
    id: string
    databaseId: number
    title?: string | null
  } | null> | null
  entryIds?: {
    __typename?: 'PhotoToEntryIdConnection'
    edges?: Array<{
      __typename?: 'PhotoToEntryIdConnectionEdge'
      node?: {
        __typename?: 'EntryId'
        id: string
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  entry?:
    | {
        __typename?: 'GfDraftEntry'
        dateCreated?: string | null
        form?: {
          __typename?: 'GfForm'
          id: string
          databaseId: number
          title?: string | null
          slug?: string | null
        } | null
      }
    | {
        __typename?: 'GfSubmittedEntry'
        databaseId: number
        dateCreated?: string | null
        form?: {
          __typename?: 'GfForm'
          id: string
          databaseId: number
          title?: string | null
          slug?: string | null
        } | null
      }
    | null
  formIds?: {
    __typename?: 'PhotoToFormIdConnection'
    edges?: Array<{
      __typename?: 'PhotoToFormIdConnectionEdge'
      node?: {
        __typename?: 'FormId'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  fieldIds?: {
    __typename?: 'PhotoToFieldIdConnection'
    edges?: Array<{
      __typename?: 'PhotoToFieldIdConnectionEdge'
      node?: {
        __typename?: 'FieldId'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  projectPhotoConnection?: {
    __typename?: 'PhotoToProjectConnection'
    edges?: Array<{
      __typename?: 'PhotoToProjectConnectionEdge'
      node?: {
        __typename?: 'Project'
        id: string
        databaseId: number
        title?: string | null
        number?: string | null
      } | null
    } | null> | null
  } | null
}

export type Photos_FragFragment = {
  __typename?: 'Photo'
  title?: string | null
  id: string
  databaseId: number
  formIds?: {
    __typename?: 'PhotoToFormIdConnection'
    edges?: Array<{
      __typename?: 'PhotoToFormIdConnectionEdge'
      node?: {
        __typename?: 'FormId'
        id: string
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  featuredImage?: {
    __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
    node?: {
      __typename?: 'MediaItem'
      id: string
      sourceUrl?: string | null
      mediaDetails?: {
        __typename?: 'MediaDetails'
        sizes?: Array<{
          __typename?: 'MediaSize'
          width?: string | null
          height?: string | null
          sourceUrl?: string | null
        } | null> | null
      } | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          id: string
          databaseId: number
          name?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type DeletePhotoMutationVariables = Exact<{
  input: DeletePhotoInput
}>

export type DeletePhotoMutation = {
  __typename?: 'RootMutation'
  deletePhoto?: {
    __typename?: 'DeletePhotoPayload'
    deletedId?: string | null
    photo?: {
      __typename?: 'Photo'
      title?: string | null
      id: string
      databaseId: number
      formIds?: {
        __typename?: 'PhotoToFormIdConnection'
        edges?: Array<{
          __typename?: 'PhotoToFormIdConnectionEdge'
          node?: {
            __typename?: 'FormId'
            id: string
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
        node?: {
          __typename?: 'MediaItem'
          id: string
          sourceUrl?: string | null
          mediaDetails?: {
            __typename?: 'MediaDetails'
            sizes?: Array<{
              __typename?: 'MediaSize'
              width?: string | null
              height?: string | null
              sourceUrl?: string | null
            } | null> | null
          } | null
          author?: {
            __typename?: 'NodeWithAuthorToUserConnectionEdge'
            node?: {
              __typename?: 'User'
              id: string
              databaseId: number
              name?: string | null
            } | null
          } | null
        } | null
      } | null
    } | null
  } | null
}

export type AllPhotosQueryVariables = Exact<{
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToPhotoConnectionWhereArgs>
}>

export type AllPhotosQuery = {
  __typename?: 'RootQuery'
  photos?: {
    __typename?: 'RootQueryToPhotoConnection'
    edges?: Array<{
      __typename?: 'RootQueryToPhotoConnectionEdge'
      node?: {
        __typename?: 'Photo'
        title?: string | null
        id: string
        databaseId: number
        formIds?: {
          __typename?: 'PhotoToFormIdConnection'
          edges?: Array<{
            __typename?: 'PhotoToFormIdConnectionEdge'
            node?: {
              __typename?: 'FormId'
              id: string
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        featuredImage?: {
          __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            sourceUrl?: string | null
            mediaDetails?: {
              __typename?: 'MediaDetails'
              sizes?: Array<{
                __typename?: 'MediaSize'
                width?: string | null
                height?: string | null
                sourceUrl?: string | null
              } | null> | null
            } | null
            author?: {
              __typename?: 'NodeWithAuthorToUserConnectionEdge'
              node?: {
                __typename?: 'User'
                id: string
                databaseId: number
                name?: string | null
              } | null
            } | null
          } | null
        } | null
      } | null
    } | null> | null
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
      endCursor?: string | null
    } | null
  } | null
}

export type GetPhotoQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
}>

export type GetPhotoQuery = {
  __typename?: 'RootQuery'
  photo?: {
    __typename?: 'Photo'
    id: string
    databaseId: number
    date?: string | null
    dateTaken?: string | null
    correctiveActionIds?: Array<number | null> | null
    author?: {
      __typename?: 'NodeWithAuthorToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        id: string
        databaseId: number
        name?: string | null
      } | null
    } | null
    featuredImage?: {
      __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
      node?: {
        __typename?: 'MediaItem'
        id: string
        databaseId: number
        sourceUrl?: string | null
        mediaDetails?: {
          __typename?: 'MediaDetails'
          sizes?: Array<{
            __typename?: 'MediaSize'
            width?: string | null
            height?: string | null
            sourceUrl?: string | null
          } | null> | null
        } | null
        author?: {
          __typename?: 'NodeWithAuthorToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null
      } | null
    } | null
    geolocation?: {
      __typename?: 'Geolocation'
      lat?: number | null
      lng?: number | null
    } | null
    correctiveActions?: Array<{
      __typename?: 'Post'
      id: string
      databaseId: number
      title?: string | null
    } | null> | null
    entryIds?: {
      __typename?: 'PhotoToEntryIdConnection'
      edges?: Array<{
        __typename?: 'PhotoToEntryIdConnectionEdge'
        node?: {
          __typename?: 'EntryId'
          id: string
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    entry?:
      | {
          __typename?: 'GfDraftEntry'
          dateCreated?: string | null
          form?: {
            __typename?: 'GfForm'
            id: string
            databaseId: number
            title?: string | null
            slug?: string | null
          } | null
        }
      | {
          __typename?: 'GfSubmittedEntry'
          databaseId: number
          dateCreated?: string | null
          form?: {
            __typename?: 'GfForm'
            id: string
            databaseId: number
            title?: string | null
            slug?: string | null
          } | null
        }
      | null
    formIds?: {
      __typename?: 'PhotoToFormIdConnection'
      edges?: Array<{
        __typename?: 'PhotoToFormIdConnectionEdge'
        node?: {
          __typename?: 'FormId'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    fieldIds?: {
      __typename?: 'PhotoToFieldIdConnection'
      edges?: Array<{
        __typename?: 'PhotoToFieldIdConnectionEdge'
        node?: {
          __typename?: 'FieldId'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    projectPhotoConnection?: {
      __typename?: 'PhotoToProjectConnection'
      edges?: Array<{
        __typename?: 'PhotoToProjectConnectionEdge'
        node?: {
          __typename?: 'Project'
          id: string
          databaseId: number
          title?: string | null
          number?: string | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type Project_FragFragment = {
  __typename?: 'Project'
  id: string
  databaseId: number
  title?: string | null
  number?: string | null
  address?: string | null
  address2?: string | null
  customer?: string | null
  city?: string | null
  state?: string | null
  country?: string | null
  zip?: string | null
  startDate?: string | null
  endDate?: string | null
  hospitalAddress?: string | null
  hospitalCity?: string | null
  hospitalName?: string | null
  hospitalState?: string | null
  hospitalZip?: string | null
  integrationSource?: string | null
  authorDatabaseId?: number | null
  modified?: string | null
  author?: {
    __typename?: 'NodeWithAuthorToUserConnectionEdge'
    node?: {
      __typename?: 'User'
      databaseId: number
      name?: string | null
    } | null
  } | null
  companies?: {
    __typename?: 'ProjectToCompanyConnection'
    edges?: Array<{
      __typename?: 'ProjectToCompanyConnectionEdge'
      node?: { __typename?: 'Company'; name?: string | null } | null
    } | null> | null
  } | null
  projectKeyPersonnelConnection?: {
    __typename?: 'ProjectToUserConnection'
    edges?: Array<{
      __typename?: 'ProjectToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        name?: string | null
        email?: string | null
        employeeId?: string | null
        databaseId: number
      } | null
    } | null> | null
  } | null
  documentToProjectConnection?: {
    __typename?: 'ProjectToDocumentConnection'
    edges?: Array<{
      __typename?: 'ProjectToDocumentConnectionEdge'
      node?: {
        __typename?: 'Document'
        title?: string | null
        databaseId: number
      } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'ProjectToDivisionConnection'
    edges?: Array<{
      __typename?: 'ProjectToDivisionConnectionEdge'
      node?: {
        __typename?: 'Division'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  projectStatuses?: {
    __typename?: 'ProjectToProjectStatusConnection'
    edges?: Array<{
      __typename?: 'ProjectToProjectStatusConnectionEdge'
      node?: {
        __typename?: 'ProjectStatus'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  projectTypes?: {
    __typename?: 'ProjectToProjectTypeConnection'
    edges?: Array<{
      __typename?: 'ProjectToProjectTypeConnectionEdge'
      node?: {
        __typename?: 'ProjectType'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  projectCorrectiveActionConnection?: {
    __typename?: 'ProjectToCorrectiveActionConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      endCursor?: string | null
    } | null
    edges?: Array<{
      __typename?: 'ProjectToCorrectiveActionConnectionEdge'
      node?: {
        __typename?: 'CorrectiveAction'
        databaseId: number
        title?: string | null
        actionTaken?: string | null
        date?: string | null
        correctiveActionStatuses?: {
          __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
            node?: {
              __typename?: 'CorrectiveActionStatus'
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type Projects_FragFragment = {
  __typename?: 'Project'
  databaseId: number
  title?: string | null
  number?: string | null
  customer?: string | null
  projectKeyPersonnelConnection?: {
    __typename?: 'ProjectToUserConnection'
    edges?: Array<{
      __typename?: 'ProjectToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        id: string
        databaseId: number
        name?: string | null
        email?: string | null
        employeeId?: string | null
      } | null
    } | null> | null
  } | null
  projectStatuses?: {
    __typename?: 'ProjectToProjectStatusConnection'
    edges?: Array<{
      __typename?: 'ProjectToProjectStatusConnectionEdge'
      node?: {
        __typename?: 'ProjectStatus'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  projectTypes?: {
    __typename?: 'ProjectToProjectTypeConnection'
    edges?: Array<{
      __typename?: 'ProjectToProjectTypeConnectionEdge'
      node?: {
        __typename?: 'ProjectType'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  companies?: {
    __typename?: 'ProjectToCompanyConnection'
    edges?: Array<{
      __typename?: 'ProjectToCompanyConnectionEdge'
      node?: {
        __typename?: 'Company'
        id: string
        databaseId: number
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'ProjectToDivisionConnection'
    edges?: Array<{
      __typename?: 'ProjectToDivisionConnectionEdge'
      node?: {
        __typename?: 'Division'
        id: string
        databaseId: number
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
}

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput
  firstCorrectiveAction?: InputMaybe<Scalars['Int']['input']>
  afterCorrectiveAction?: InputMaybe<Scalars['String']['input']>
}>

export type CreateProjectMutation = {
  __typename?: 'RootMutation'
  createProject?: {
    __typename?: 'CreateProjectPayload'
    project?: {
      __typename?: 'Project'
      id: string
      databaseId: number
      title?: string | null
      number?: string | null
      address?: string | null
      address2?: string | null
      customer?: string | null
      city?: string | null
      state?: string | null
      country?: string | null
      zip?: string | null
      startDate?: string | null
      endDate?: string | null
      hospitalAddress?: string | null
      hospitalCity?: string | null
      hospitalName?: string | null
      hospitalState?: string | null
      hospitalZip?: string | null
      integrationSource?: string | null
      authorDatabaseId?: number | null
      modified?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      companies?: {
        __typename?: 'ProjectToCompanyConnection'
        edges?: Array<{
          __typename?: 'ProjectToCompanyConnectionEdge'
          node?: { __typename?: 'Company'; name?: string | null } | null
        } | null> | null
      } | null
      projectKeyPersonnelConnection?: {
        __typename?: 'ProjectToUserConnection'
        edges?: Array<{
          __typename?: 'ProjectToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            name?: string | null
            email?: string | null
            employeeId?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      documentToProjectConnection?: {
        __typename?: 'ProjectToDocumentConnection'
        edges?: Array<{
          __typename?: 'ProjectToDocumentConnectionEdge'
          node?: {
            __typename?: 'Document'
            title?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'ProjectToDivisionConnection'
        edges?: Array<{
          __typename?: 'ProjectToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      projectStatuses?: {
        __typename?: 'ProjectToProjectStatusConnection'
        edges?: Array<{
          __typename?: 'ProjectToProjectStatusConnectionEdge'
          node?: {
            __typename?: 'ProjectStatus'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      projectTypes?: {
        __typename?: 'ProjectToProjectTypeConnection'
        edges?: Array<{
          __typename?: 'ProjectToProjectTypeConnectionEdge'
          node?: {
            __typename?: 'ProjectType'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      projectCorrectiveActionConnection?: {
        __typename?: 'ProjectToCorrectiveActionConnection'
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          hasPreviousPage: boolean
          endCursor?: string | null
        } | null
        edges?: Array<{
          __typename?: 'ProjectToCorrectiveActionConnectionEdge'
          node?: {
            __typename?: 'CorrectiveAction'
            databaseId: number
            title?: string | null
            actionTaken?: string | null
            date?: string | null
            correctiveActionStatuses?: {
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
                node?: {
                  __typename?: 'CorrectiveActionStatus'
                  slug?: string | null
                  name?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type DeleteProjectMutationVariables = Exact<{
  input: DeleteProjectInput
  firstCorrectiveAction?: InputMaybe<Scalars['Int']['input']>
  afterCorrectiveAction?: InputMaybe<Scalars['String']['input']>
}>

export type DeleteProjectMutation = {
  __typename?: 'RootMutation'
  deleteProject?: {
    __typename?: 'DeleteProjectPayload'
    deletedId?: string | null
    project?: {
      __typename?: 'Project'
      id: string
      databaseId: number
      title?: string | null
      number?: string | null
      address?: string | null
      address2?: string | null
      customer?: string | null
      city?: string | null
      state?: string | null
      country?: string | null
      zip?: string | null
      startDate?: string | null
      endDate?: string | null
      hospitalAddress?: string | null
      hospitalCity?: string | null
      hospitalName?: string | null
      hospitalState?: string | null
      hospitalZip?: string | null
      integrationSource?: string | null
      authorDatabaseId?: number | null
      modified?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      companies?: {
        __typename?: 'ProjectToCompanyConnection'
        edges?: Array<{
          __typename?: 'ProjectToCompanyConnectionEdge'
          node?: { __typename?: 'Company'; name?: string | null } | null
        } | null> | null
      } | null
      projectKeyPersonnelConnection?: {
        __typename?: 'ProjectToUserConnection'
        edges?: Array<{
          __typename?: 'ProjectToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            name?: string | null
            email?: string | null
            employeeId?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      documentToProjectConnection?: {
        __typename?: 'ProjectToDocumentConnection'
        edges?: Array<{
          __typename?: 'ProjectToDocumentConnectionEdge'
          node?: {
            __typename?: 'Document'
            title?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'ProjectToDivisionConnection'
        edges?: Array<{
          __typename?: 'ProjectToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      projectStatuses?: {
        __typename?: 'ProjectToProjectStatusConnection'
        edges?: Array<{
          __typename?: 'ProjectToProjectStatusConnectionEdge'
          node?: {
            __typename?: 'ProjectStatus'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      projectTypes?: {
        __typename?: 'ProjectToProjectTypeConnection'
        edges?: Array<{
          __typename?: 'ProjectToProjectTypeConnectionEdge'
          node?: {
            __typename?: 'ProjectType'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      projectCorrectiveActionConnection?: {
        __typename?: 'ProjectToCorrectiveActionConnection'
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          hasPreviousPage: boolean
          endCursor?: string | null
        } | null
        edges?: Array<{
          __typename?: 'ProjectToCorrectiveActionConnectionEdge'
          node?: {
            __typename?: 'CorrectiveAction'
            databaseId: number
            title?: string | null
            actionTaken?: string | null
            date?: string | null
            correctiveActionStatuses?: {
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
                node?: {
                  __typename?: 'CorrectiveActionStatus'
                  slug?: string | null
                  name?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput
  firstCorrectiveAction?: InputMaybe<Scalars['Int']['input']>
  afterCorrectiveAction?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateProjectMutation = {
  __typename?: 'RootMutation'
  updateProject?: {
    __typename?: 'UpdateProjectPayload'
    project?: {
      __typename?: 'Project'
      id: string
      databaseId: number
      title?: string | null
      number?: string | null
      address?: string | null
      address2?: string | null
      customer?: string | null
      city?: string | null
      state?: string | null
      country?: string | null
      zip?: string | null
      startDate?: string | null
      endDate?: string | null
      hospitalAddress?: string | null
      hospitalCity?: string | null
      hospitalName?: string | null
      hospitalState?: string | null
      hospitalZip?: string | null
      integrationSource?: string | null
      authorDatabaseId?: number | null
      modified?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          databaseId: number
          name?: string | null
        } | null
      } | null
      companies?: {
        __typename?: 'ProjectToCompanyConnection'
        edges?: Array<{
          __typename?: 'ProjectToCompanyConnectionEdge'
          node?: { __typename?: 'Company'; name?: string | null } | null
        } | null> | null
      } | null
      projectKeyPersonnelConnection?: {
        __typename?: 'ProjectToUserConnection'
        edges?: Array<{
          __typename?: 'ProjectToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            name?: string | null
            email?: string | null
            employeeId?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      documentToProjectConnection?: {
        __typename?: 'ProjectToDocumentConnection'
        edges?: Array<{
          __typename?: 'ProjectToDocumentConnectionEdge'
          node?: {
            __typename?: 'Document'
            title?: string | null
            databaseId: number
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'ProjectToDivisionConnection'
        edges?: Array<{
          __typename?: 'ProjectToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      projectStatuses?: {
        __typename?: 'ProjectToProjectStatusConnection'
        edges?: Array<{
          __typename?: 'ProjectToProjectStatusConnectionEdge'
          node?: {
            __typename?: 'ProjectStatus'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      projectTypes?: {
        __typename?: 'ProjectToProjectTypeConnection'
        edges?: Array<{
          __typename?: 'ProjectToProjectTypeConnectionEdge'
          node?: {
            __typename?: 'ProjectType'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      projectCorrectiveActionConnection?: {
        __typename?: 'ProjectToCorrectiveActionConnection'
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          hasPreviousPage: boolean
          endCursor?: string | null
        } | null
        edges?: Array<{
          __typename?: 'ProjectToCorrectiveActionConnectionEdge'
          node?: {
            __typename?: 'CorrectiveAction'
            databaseId: number
            title?: string | null
            actionTaken?: string | null
            date?: string | null
            correctiveActionStatuses?: {
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
                node?: {
                  __typename?: 'CorrectiveActionStatus'
                  slug?: string | null
                  name?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type AllProjectsQueryVariables = Exact<{
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToProjectConnectionWhereArgs>
}>

export type AllProjectsQuery = {
  __typename?: 'RootQuery'
  projects?: {
    __typename?: 'RootQueryToProjectConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToProjectConnectionEdge'
      cursor?: string | null
      node?: {
        __typename?: 'Project'
        databaseId: number
        title?: string | null
        number?: string | null
        customer?: string | null
        projectKeyPersonnelConnection?: {
          __typename?: 'ProjectToUserConnection'
          edges?: Array<{
            __typename?: 'ProjectToUserConnectionEdge'
            node?: {
              __typename?: 'User'
              id: string
              databaseId: number
              name?: string | null
              email?: string | null
              employeeId?: string | null
            } | null
          } | null> | null
        } | null
        projectStatuses?: {
          __typename?: 'ProjectToProjectStatusConnection'
          edges?: Array<{
            __typename?: 'ProjectToProjectStatusConnectionEdge'
            node?: {
              __typename?: 'ProjectStatus'
              id: string
              databaseId: number
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        projectTypes?: {
          __typename?: 'ProjectToProjectTypeConnection'
          edges?: Array<{
            __typename?: 'ProjectToProjectTypeConnectionEdge'
            node?: {
              __typename?: 'ProjectType'
              id: string
              databaseId: number
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        companies?: {
          __typename?: 'ProjectToCompanyConnection'
          edges?: Array<{
            __typename?: 'ProjectToCompanyConnectionEdge'
            node?: {
              __typename?: 'Company'
              id: string
              databaseId: number
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        divisions?: {
          __typename?: 'ProjectToDivisionConnection'
          edges?: Array<{
            __typename?: 'ProjectToDivisionConnectionEdge'
            node?: {
              __typename?: 'Division'
              id: string
              databaseId: number
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type GetProjectQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
  firstCorrectiveAction?: InputMaybe<Scalars['Int']['input']>
  afterCorrectiveAction?: InputMaybe<Scalars['String']['input']>
}>

export type GetProjectQuery = {
  __typename?: 'RootQuery'
  project?: {
    __typename?: 'Project'
    id: string
    databaseId: number
    title?: string | null
    number?: string | null
    address?: string | null
    address2?: string | null
    customer?: string | null
    city?: string | null
    state?: string | null
    country?: string | null
    zip?: string | null
    startDate?: string | null
    endDate?: string | null
    hospitalAddress?: string | null
    hospitalCity?: string | null
    hospitalName?: string | null
    hospitalState?: string | null
    hospitalZip?: string | null
    integrationSource?: string | null
    authorDatabaseId?: number | null
    modified?: string | null
    author?: {
      __typename?: 'NodeWithAuthorToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        databaseId: number
        name?: string | null
      } | null
    } | null
    companies?: {
      __typename?: 'ProjectToCompanyConnection'
      edges?: Array<{
        __typename?: 'ProjectToCompanyConnectionEdge'
        node?: { __typename?: 'Company'; name?: string | null } | null
      } | null> | null
    } | null
    projectKeyPersonnelConnection?: {
      __typename?: 'ProjectToUserConnection'
      edges?: Array<{
        __typename?: 'ProjectToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          name?: string | null
          email?: string | null
          employeeId?: string | null
          databaseId: number
        } | null
      } | null> | null
    } | null
    documentToProjectConnection?: {
      __typename?: 'ProjectToDocumentConnection'
      edges?: Array<{
        __typename?: 'ProjectToDocumentConnectionEdge'
        node?: {
          __typename?: 'Document'
          title?: string | null
          databaseId: number
        } | null
      } | null> | null
    } | null
    divisions?: {
      __typename?: 'ProjectToDivisionConnection'
      edges?: Array<{
        __typename?: 'ProjectToDivisionConnectionEdge'
        node?: {
          __typename?: 'Division'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    projectStatuses?: {
      __typename?: 'ProjectToProjectStatusConnection'
      edges?: Array<{
        __typename?: 'ProjectToProjectStatusConnectionEdge'
        node?: {
          __typename?: 'ProjectStatus'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    projectTypes?: {
      __typename?: 'ProjectToProjectTypeConnection'
      edges?: Array<{
        __typename?: 'ProjectToProjectTypeConnectionEdge'
        node?: {
          __typename?: 'ProjectType'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    projectCorrectiveActionConnection?: {
      __typename?: 'ProjectToCorrectiveActionConnection'
      pageInfo?: {
        __typename?: 'WPPageInfo'
        hasNextPage: boolean
        hasPreviousPage: boolean
        endCursor?: string | null
      } | null
      edges?: Array<{
        __typename?: 'ProjectToCorrectiveActionConnectionEdge'
        node?: {
          __typename?: 'CorrectiveAction'
          databaseId: number
          title?: string | null
          actionTaken?: string | null
          date?: string | null
          correctiveActionStatuses?: {
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
            edges?: Array<{
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
              node?: {
                __typename?: 'CorrectiveActionStatus'
                slug?: string | null
                name?: string | null
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type AllCompaniesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToCompanyConnectionWhereArgs>
}>

export type AllCompaniesQuery = {
  __typename?: 'RootQuery'
  companies?: {
    __typename?: 'RootQueryToCompanyConnection'
    edges?: Array<{
      __typename?: 'RootQueryToCompanyConnectionEdge'
      node?: {
        __typename?: 'Company'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
}

export type AllDivisionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToDivisionConnectionWhereArgs>
}>

export type AllDivisionsQuery = {
  __typename?: 'RootQuery'
  divisions?: {
    __typename?: 'RootQueryToDivisionConnection'
    edges?: Array<{
      __typename?: 'RootQueryToDivisionConnectionEdge'
      node?: {
        __typename?: 'Division'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
}

export type AllEquipmentTagsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToEquipmentTagConnectionWhereArgs>
}>

export type AllEquipmentTagsQuery = {
  __typename?: 'RootQuery'
  equipmentTags?: {
    __typename?: 'RootQueryToEquipmentTagConnection'
    edges?: Array<{
      __typename?: 'RootQueryToEquipmentTagConnectionEdge'
      node?: {
        __typename?: 'EquipmentTag'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
}

export type AllManufacturersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToManufacturerConnectionWhereArgs>
}>

export type AllManufacturersQuery = {
  __typename?: 'RootQuery'
  manufacturers?: {
    __typename?: 'RootQueryToManufacturerConnection'
    edges?: Array<{
      __typename?: 'RootQueryToManufacturerConnectionEdge'
      node?: {
        __typename?: 'Manufacturer'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
}

export type AllProvidersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RootQueryToProviderConnectionWhereArgs>
}>

export type AllProvidersQuery = {
  __typename?: 'RootQuery'
  providers?: {
    __typename?: 'RootQueryToProviderConnection'
    edges?: Array<{
      __typename?: 'RootQueryToProviderConnectionEdge'
      node?: {
        __typename?: 'Provider'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
}

export type Toolbox_Talk_Basic_FragFragment = {
  __typename?: 'ToolboxTalk'
  id: string
  databaseId: number
  title?: string | null
  content?: string | null
  author?: {
    __typename?: 'NodeWithAuthorToUserConnectionEdge'
    node?: {
      __typename?: 'User'
      id: string
      databaseId: number
      name?: string | null
    } | null
  } | null
  language?: {
    __typename?: 'Language'
    id: string
    name?: string | null
    slug?: string | null
    locale?: string | null
  } | null
  toolboxTalkCategories?: {
    __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
    edges?: Array<{
      __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
      node?: {
        __typename?: 'ToolboxTalkCategory'
        id: string
        databaseId: number
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  companies?: {
    __typename?: 'ToolboxTalkToCompanyConnection'
    edges?: Array<{
      __typename?: 'ToolboxTalkToCompanyConnectionEdge'
      node?: {
        __typename?: 'Company'
        id: string
        databaseId: number
        name?: string | null
      } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'ToolboxTalkToDivisionConnection'
    edges?: Array<{
      __typename?: 'ToolboxTalkToDivisionConnectionEdge'
      node?: {
        __typename?: 'Division'
        id: string
        databaseId: number
        name?: string | null
      } | null
    } | null> | null
  } | null
  featuredImage?: {
    __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
    node?: {
      __typename?: 'MediaItem'
      id: string
      databaseId: number
      mediaItemUrl?: string | null
    } | null
  } | null
}

export type Toolbox_Talk_FragFragment = {
  __typename?: 'ToolboxTalk'
  id: string
  databaseId: number
  title?: string | null
  content?: string | null
  translations?: Array<{
    __typename?: 'ToolboxTalk'
    id: string
    databaseId: number
    title?: string | null
    content?: string | null
    author?: {
      __typename?: 'NodeWithAuthorToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        id: string
        databaseId: number
        name?: string | null
      } | null
    } | null
    language?: {
      __typename?: 'Language'
      id: string
      name?: string | null
      slug?: string | null
      locale?: string | null
    } | null
    toolboxTalkCategories?: {
      __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
      edges?: Array<{
        __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
        node?: {
          __typename?: 'ToolboxTalkCategory'
          id: string
          databaseId: number
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    companies?: {
      __typename?: 'ToolboxTalkToCompanyConnection'
      edges?: Array<{
        __typename?: 'ToolboxTalkToCompanyConnectionEdge'
        node?: {
          __typename?: 'Company'
          id: string
          databaseId: number
          name?: string | null
        } | null
      } | null> | null
    } | null
    divisions?: {
      __typename?: 'ToolboxTalkToDivisionConnection'
      edges?: Array<{
        __typename?: 'ToolboxTalkToDivisionConnectionEdge'
        node?: {
          __typename?: 'Division'
          id: string
          databaseId: number
          name?: string | null
        } | null
      } | null> | null
    } | null
    featuredImage?: {
      __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
      node?: {
        __typename?: 'MediaItem'
        id: string
        databaseId: number
        mediaItemUrl?: string | null
      } | null
    } | null
  } | null> | null
  author?: {
    __typename?: 'NodeWithAuthorToUserConnectionEdge'
    node?: {
      __typename?: 'User'
      id: string
      databaseId: number
      name?: string | null
    } | null
  } | null
  language?: {
    __typename?: 'Language'
    id: string
    name?: string | null
    slug?: string | null
    locale?: string | null
  } | null
  toolboxTalkCategories?: {
    __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
    edges?: Array<{
      __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
      node?: {
        __typename?: 'ToolboxTalkCategory'
        id: string
        databaseId: number
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  companies?: {
    __typename?: 'ToolboxTalkToCompanyConnection'
    edges?: Array<{
      __typename?: 'ToolboxTalkToCompanyConnectionEdge'
      node?: {
        __typename?: 'Company'
        id: string
        databaseId: number
        name?: string | null
      } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'ToolboxTalkToDivisionConnection'
    edges?: Array<{
      __typename?: 'ToolboxTalkToDivisionConnectionEdge'
      node?: {
        __typename?: 'Division'
        id: string
        databaseId: number
        name?: string | null
      } | null
    } | null> | null
  } | null
  featuredImage?: {
    __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
    node?: {
      __typename?: 'MediaItem'
      id: string
      databaseId: number
      mediaItemUrl?: string | null
    } | null
  } | null
}

export type Toolbox_Talks_FragFragment = {
  __typename?: 'ToolboxTalk'
  databaseId: number
  id: string
  title?: string | null
  translations?: Array<{
    __typename?: 'ToolboxTalk'
    databaseId: number
  } | null> | null
  toolboxTalkCategories?: {
    __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
    edges?: Array<{
      __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
      node?: {
        __typename?: 'ToolboxTalkCategory'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
}

export type CreateToolboxTalkMutationVariables = Exact<{
  input: CreateToolboxTalkInput
}>

export type CreateToolboxTalkMutation = {
  __typename?: 'RootMutation'
  createToolboxTalk?: {
    __typename?: 'CreateToolboxTalkPayload'
    toolboxTalk?: {
      __typename?: 'ToolboxTalk'
      id: string
      databaseId: number
      title?: string | null
      content?: string | null
      translations?: Array<{
        __typename?: 'ToolboxTalk'
        id: string
        databaseId: number
        title?: string | null
        content?: string | null
        author?: {
          __typename?: 'NodeWithAuthorToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null
        language?: {
          __typename?: 'Language'
          id: string
          name?: string | null
          slug?: string | null
          locale?: string | null
        } | null
        toolboxTalkCategories?: {
          __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
            node?: {
              __typename?: 'ToolboxTalkCategory'
              id: string
              databaseId: number
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
        companies?: {
          __typename?: 'ToolboxTalkToCompanyConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToCompanyConnectionEdge'
            node?: {
              __typename?: 'Company'
              id: string
              databaseId: number
              name?: string | null
            } | null
          } | null> | null
        } | null
        divisions?: {
          __typename?: 'ToolboxTalkToDivisionConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToDivisionConnectionEdge'
            node?: {
              __typename?: 'Division'
              id: string
              databaseId: number
              name?: string | null
            } | null
          } | null> | null
        } | null
        featuredImage?: {
          __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            databaseId: number
            mediaItemUrl?: string | null
          } | null
        } | null
      } | null> | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          id: string
          databaseId: number
          name?: string | null
        } | null
      } | null
      language?: {
        __typename?: 'Language'
        id: string
        name?: string | null
        slug?: string | null
        locale?: string | null
      } | null
      toolboxTalkCategories?: {
        __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
          node?: {
            __typename?: 'ToolboxTalkCategory'
            id: string
            databaseId: number
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'ToolboxTalkToCompanyConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'ToolboxTalkToDivisionConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
        node?: {
          __typename?: 'MediaItem'
          id: string
          databaseId: number
          mediaItemUrl?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type DeleteToolboxTalkMutationVariables = Exact<{
  input: DeleteToolboxTalkInput
}>

export type DeleteToolboxTalkMutation = {
  __typename?: 'RootMutation'
  deleteToolboxTalk?: {
    __typename?: 'DeleteToolboxTalkPayload'
    deletedId?: string | null
    toolboxTalk?: {
      __typename?: 'ToolboxTalk'
      id: string
      databaseId: number
      title?: string | null
      content?: string | null
      translations?: Array<{
        __typename?: 'ToolboxTalk'
        id: string
        databaseId: number
        title?: string | null
        content?: string | null
        author?: {
          __typename?: 'NodeWithAuthorToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null
        language?: {
          __typename?: 'Language'
          id: string
          name?: string | null
          slug?: string | null
          locale?: string | null
        } | null
        toolboxTalkCategories?: {
          __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
            node?: {
              __typename?: 'ToolboxTalkCategory'
              id: string
              databaseId: number
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
        companies?: {
          __typename?: 'ToolboxTalkToCompanyConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToCompanyConnectionEdge'
            node?: {
              __typename?: 'Company'
              id: string
              databaseId: number
              name?: string | null
            } | null
          } | null> | null
        } | null
        divisions?: {
          __typename?: 'ToolboxTalkToDivisionConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToDivisionConnectionEdge'
            node?: {
              __typename?: 'Division'
              id: string
              databaseId: number
              name?: string | null
            } | null
          } | null> | null
        } | null
        featuredImage?: {
          __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            databaseId: number
            mediaItemUrl?: string | null
          } | null
        } | null
      } | null> | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          id: string
          databaseId: number
          name?: string | null
        } | null
      } | null
      language?: {
        __typename?: 'Language'
        id: string
        name?: string | null
        slug?: string | null
        locale?: string | null
      } | null
      toolboxTalkCategories?: {
        __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
          node?: {
            __typename?: 'ToolboxTalkCategory'
            id: string
            databaseId: number
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'ToolboxTalkToCompanyConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'ToolboxTalkToDivisionConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
        node?: {
          __typename?: 'MediaItem'
          id: string
          databaseId: number
          mediaItemUrl?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type UpdateToolboxTalkMutationVariables = Exact<{
  input: UpdateToolboxTalkInput
}>

export type UpdateToolboxTalkMutation = {
  __typename?: 'RootMutation'
  updateToolboxTalk?: {
    __typename?: 'UpdateToolboxTalkPayload'
    toolboxTalk?: {
      __typename?: 'ToolboxTalk'
      id: string
      databaseId: number
      title?: string | null
      content?: string | null
      translations?: Array<{
        __typename?: 'ToolboxTalk'
        id: string
        databaseId: number
        title?: string | null
        content?: string | null
        author?: {
          __typename?: 'NodeWithAuthorToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null
        language?: {
          __typename?: 'Language'
          id: string
          name?: string | null
          slug?: string | null
          locale?: string | null
        } | null
        toolboxTalkCategories?: {
          __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
            node?: {
              __typename?: 'ToolboxTalkCategory'
              id: string
              databaseId: number
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
        companies?: {
          __typename?: 'ToolboxTalkToCompanyConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToCompanyConnectionEdge'
            node?: {
              __typename?: 'Company'
              id: string
              databaseId: number
              name?: string | null
            } | null
          } | null> | null
        } | null
        divisions?: {
          __typename?: 'ToolboxTalkToDivisionConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToDivisionConnectionEdge'
            node?: {
              __typename?: 'Division'
              id: string
              databaseId: number
              name?: string | null
            } | null
          } | null> | null
        } | null
        featuredImage?: {
          __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            databaseId: number
            mediaItemUrl?: string | null
          } | null
        } | null
      } | null> | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          id: string
          databaseId: number
          name?: string | null
        } | null
      } | null
      language?: {
        __typename?: 'Language'
        id: string
        name?: string | null
        slug?: string | null
        locale?: string | null
      } | null
      toolboxTalkCategories?: {
        __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
          node?: {
            __typename?: 'ToolboxTalkCategory'
            id: string
            databaseId: number
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'ToolboxTalkToCompanyConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'ToolboxTalkToDivisionConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
        node?: {
          __typename?: 'MediaItem'
          id: string
          databaseId: number
          mediaItemUrl?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type AllToolboxTalksQueryVariables = Exact<{
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToToolboxTalkConnectionWhereArgs>
}>

export type AllToolboxTalksQuery = {
  __typename?: 'RootQuery'
  toolboxTalks?: {
    __typename?: 'RootQueryToToolboxTalkConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToToolboxTalkConnectionEdge'
      cursor?: string | null
      node?: {
        __typename?: 'ToolboxTalk'
        id: string
        databaseId: number
        title?: string | null
        content?: string | null
        translations?: Array<{
          __typename?: 'ToolboxTalk'
          id: string
          databaseId: number
          title?: string | null
          content?: string | null
          author?: {
            __typename?: 'NodeWithAuthorToUserConnectionEdge'
            node?: {
              __typename?: 'User'
              id: string
              databaseId: number
              name?: string | null
            } | null
          } | null
          language?: {
            __typename?: 'Language'
            id: string
            name?: string | null
            slug?: string | null
            locale?: string | null
          } | null
          toolboxTalkCategories?: {
            __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
            edges?: Array<{
              __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
              node?: {
                __typename?: 'ToolboxTalkCategory'
                id: string
                databaseId: number
                slug?: string | null
                name?: string | null
              } | null
            } | null> | null
          } | null
          companies?: {
            __typename?: 'ToolboxTalkToCompanyConnection'
            edges?: Array<{
              __typename?: 'ToolboxTalkToCompanyConnectionEdge'
              node?: {
                __typename?: 'Company'
                id: string
                databaseId: number
                name?: string | null
              } | null
            } | null> | null
          } | null
          divisions?: {
            __typename?: 'ToolboxTalkToDivisionConnection'
            edges?: Array<{
              __typename?: 'ToolboxTalkToDivisionConnectionEdge'
              node?: {
                __typename?: 'Division'
                id: string
                databaseId: number
                name?: string | null
              } | null
            } | null> | null
          } | null
          featuredImage?: {
            __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
            node?: {
              __typename?: 'MediaItem'
              id: string
              databaseId: number
              mediaItemUrl?: string | null
            } | null
          } | null
        } | null> | null
        author?: {
          __typename?: 'NodeWithAuthorToUserConnectionEdge'
          node?: {
            __typename?: 'User'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null
        language?: {
          __typename?: 'Language'
          id: string
          name?: string | null
          slug?: string | null
          locale?: string | null
        } | null
        toolboxTalkCategories?: {
          __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
            node?: {
              __typename?: 'ToolboxTalkCategory'
              id: string
              databaseId: number
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
        companies?: {
          __typename?: 'ToolboxTalkToCompanyConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToCompanyConnectionEdge'
            node?: {
              __typename?: 'Company'
              id: string
              databaseId: number
              name?: string | null
            } | null
          } | null> | null
        } | null
        divisions?: {
          __typename?: 'ToolboxTalkToDivisionConnection'
          edges?: Array<{
            __typename?: 'ToolboxTalkToDivisionConnectionEdge'
            node?: {
              __typename?: 'Division'
              id: string
              databaseId: number
              name?: string | null
            } | null
          } | null> | null
        } | null
        featuredImage?: {
          __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
          node?: {
            __typename?: 'MediaItem'
            id: string
            databaseId: number
            mediaItemUrl?: string | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type GetToolboxTalkQueryVariables = Exact<{
  id: Scalars['ID']['input']
  idType?: InputMaybe<ToolboxTalkIdType>
}>

export type GetToolboxTalkQuery = {
  __typename?: 'RootQuery'
  toolboxTalk?: {
    __typename?: 'ToolboxTalk'
    id: string
    databaseId: number
    title?: string | null
    content?: string | null
    translations?: Array<{
      __typename?: 'ToolboxTalk'
      id: string
      databaseId: number
      title?: string | null
      content?: string | null
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge'
        node?: {
          __typename?: 'User'
          id: string
          databaseId: number
          name?: string | null
        } | null
      } | null
      language?: {
        __typename?: 'Language'
        id: string
        name?: string | null
        slug?: string | null
        locale?: string | null
      } | null
      toolboxTalkCategories?: {
        __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
          node?: {
            __typename?: 'ToolboxTalkCategory'
            id: string
            databaseId: number
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'ToolboxTalkToCompanyConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToCompanyConnectionEdge'
          node?: {
            __typename?: 'Company'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'ToolboxTalkToDivisionConnection'
        edges?: Array<{
          __typename?: 'ToolboxTalkToDivisionConnectionEdge'
          node?: {
            __typename?: 'Division'
            id: string
            databaseId: number
            name?: string | null
          } | null
        } | null> | null
      } | null
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
        node?: {
          __typename?: 'MediaItem'
          id: string
          databaseId: number
          mediaItemUrl?: string | null
        } | null
      } | null
    } | null> | null
    author?: {
      __typename?: 'NodeWithAuthorToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        id: string
        databaseId: number
        name?: string | null
      } | null
    } | null
    language?: {
      __typename?: 'Language'
      id: string
      name?: string | null
      slug?: string | null
      locale?: string | null
    } | null
    toolboxTalkCategories?: {
      __typename?: 'ToolboxTalkToToolboxTalkCategoryConnection'
      edges?: Array<{
        __typename?: 'ToolboxTalkToToolboxTalkCategoryConnectionEdge'
        node?: {
          __typename?: 'ToolboxTalkCategory'
          id: string
          databaseId: number
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    companies?: {
      __typename?: 'ToolboxTalkToCompanyConnection'
      edges?: Array<{
        __typename?: 'ToolboxTalkToCompanyConnectionEdge'
        node?: {
          __typename?: 'Company'
          id: string
          databaseId: number
          name?: string | null
        } | null
      } | null> | null
    } | null
    divisions?: {
      __typename?: 'ToolboxTalkToDivisionConnection'
      edges?: Array<{
        __typename?: 'ToolboxTalkToDivisionConnectionEdge'
        node?: {
          __typename?: 'Division'
          id: string
          databaseId: number
          name?: string | null
        } | null
      } | null> | null
    } | null
    featuredImage?: {
      __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge'
      node?: {
        __typename?: 'MediaItem'
        id: string
        databaseId: number
        mediaItemUrl?: string | null
      } | null
    } | null
  } | null
}

export type Me_FragFragment = {
  __typename?: 'User'
  username?: string | null
  id: string
  databaseId: number
  firstName?: string | null
  lastName?: string | null
  name?: string | null
  email?: string | null
  magicBellHmacEmail?: string | null
  capabilities?: Array<string | null> | null
  offlineMode?: boolean | null
  offlineModeData?: Array<OfflineModeDataCollectionNameEnum | null> | null
  isSuperAdmin?: boolean | null
  avatar?: { __typename?: 'Avatar'; url?: string | null } | null
  languages?: {
    __typename?: 'UserToUserLanguageConnection'
    edges?: Array<{
      __typename?: 'UserToUserLanguageConnectionEdge'
      node?: {
        __typename?: 'UserLanguage'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  companies?: {
    __typename?: 'UserToUserCompanyConnection'
    edges?: Array<{
      __typename?: 'UserToUserCompanyConnectionEdge'
      node?: {
        __typename?: 'UserCompany'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'UserToUserDivisionConnection'
    edges?: Array<{
      __typename?: 'UserToUserDivisionConnectionEdge'
      node?: {
        __typename?: 'UserDivision'
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  roles?: {
    __typename?: 'UserToUserRoleConnection'
    edges?: Array<{
      __typename?: 'UserToUserRoleConnectionEdge'
      node?: {
        __typename?: 'UserRole'
        name?: string | null
        displayName?: string | null
      } | null
    } | null> | null
  } | null
  correctiveActionAssignedWorkersConnection?: {
    __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnection'
    edges?: Array<{
      __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnectionEdge'
      node?: {
        __typename?: 'CorrectiveAction'
        id: string
        databaseId: number
        title?: string | null
        actionTaken?: string | null
        date?: string | null
        dueDate?: string | null
        dateCompleted?: string | null
        projectCorrectiveActionConnection?: {
          __typename?: 'CorrectiveActionToProjectConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToProjectConnectionEdge'
            node?: {
              __typename?: 'Project'
              number?: string | null
              title?: string | null
              databaseId: number
            } | null
          } | null> | null
        } | null
        correctiveActionStatuses?: {
          __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
            node?: {
              __typename?: 'CorrectiveActionStatus'
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    } | null
  } | null
  correctiveActionDeficiencyOwnersConnection?: {
    __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnection'
    edges?: Array<{
      __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
      node?: {
        __typename?: 'CorrectiveAction'
        id: string
        databaseId: number
        title?: string | null
        actionTaken?: string | null
        date?: string | null
        dueDate?: string | null
        dateCompleted?: string | null
        projectCorrectiveActionConnection?: {
          __typename?: 'CorrectiveActionToProjectConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToProjectConnectionEdge'
            node?: {
              __typename?: 'Project'
              number?: string | null
              title?: string | null
              databaseId: number
            } | null
          } | null> | null
        } | null
        correctiveActionStatuses?: {
          __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
            node?: {
              __typename?: 'CorrectiveActionStatus'
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    } | null
  } | null
  projectKeyPersonnelConnection?: {
    __typename?: 'UserToProjectKeyPersonnelConnectionConnection'
    edges?: Array<{
      __typename?: 'UserToProjectKeyPersonnelConnectionConnectionEdge'
      node?: {
        __typename?: 'Project'
        id: string
        databaseId: number
        title?: string | null
        number?: string | null
      } | null
    } | null> | null
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    } | null
  } | null
  emergencyContactInfo?: {
    __typename?: 'User_Emergencycontactinfo'
    emergencyContactName?: string | null
    emergencyContactNumber?: string | null
    emergencyContactRelationship?: string | null
  } | null
  usageCounts?: {
    __typename?: 'User_Usagecounts'
    sdsDownloadCount?: number | null
  } | null
}

export type User_FragFragment = {
  __typename?: 'User'
  id: string
  databaseId: number
  firstName?: string | null
  lastName?: string | null
  name?: string | null
  email?: string | null
  username?: string | null
  phoneNumber?: string | null
  employeeId?: string | null
  integrationSource?: string | null
  languages?: {
    __typename?: 'UserToUserLanguageConnection'
    edges?: Array<{
      __typename?: 'UserToUserLanguageConnectionEdge'
      node?: {
        __typename?: 'UserLanguage'
        id: string
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  status?: {
    __typename?: 'UserToUserStatusConnection'
    edges?: Array<{
      __typename?: 'UserToUserStatusConnectionEdge'
      node?: {
        __typename?: 'UserStatus'
        id: string
        name?: string | null
      } | null
    } | null> | null
  } | null
  vaccineStatus?: {
    __typename?: 'UserToUserVaccineStatusConnectionEdge'
    node?: {
      __typename?: 'UserVaccineStatus'
      name?: string | null
      slug?: string | null
    } | null
  } | null
  roles?: {
    __typename?: 'UserToUserRoleConnection'
    edges?: Array<{
      __typename?: 'UserToUserRoleConnectionEdge'
      node?: {
        __typename?: 'UserRole'
        name?: string | null
        displayName?: string | null
      } | null
    } | null> | null
  } | null
  companies?: {
    __typename?: 'UserToUserCompanyConnection'
    edges?: Array<{
      __typename?: 'UserToUserCompanyConnectionEdge'
      node?: {
        __typename?: 'UserCompany'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'UserToUserDivisionConnection'
    edges?: Array<{
      __typename?: 'UserToUserDivisionConnectionEdge'
      node?: {
        __typename?: 'UserDivision'
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  projectKeyPersonnelConnection?: {
    __typename?: 'UserToProjectKeyPersonnelConnectionConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      endCursor?: string | null
    } | null
    edges?: Array<{
      __typename?: 'UserToProjectKeyPersonnelConnectionConnectionEdge'
      node?: {
        __typename?: 'Project'
        databaseId: number
        title?: string | null
        number?: string | null
      } | null
    } | null> | null
  } | null
  correctiveActionAssignedWorkersConnection?: {
    __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      endCursor?: string | null
    } | null
    edges?: Array<{
      __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnectionEdge'
      node?: {
        __typename?: 'CorrectiveAction'
        databaseId: number
        title?: string | null
        correctiveActionStatuses?: {
          __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
            node?: {
              __typename?: 'CorrectiveActionStatus'
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
  correctiveActionDeficiencyOwnersConnection?: {
    __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      endCursor?: string | null
    } | null
    edges?: Array<{
      __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
      node?: {
        __typename?: 'CorrectiveAction'
        databaseId: number
        title?: string | null
        correctiveActionStatuses?: {
          __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
          edges?: Array<{
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
            node?: {
              __typename?: 'CorrectiveActionStatus'
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
  certificationUserConnection?: {
    __typename?: 'UserToCertificationUserConnectionConnection'
    edges?: Array<{
      __typename?: 'UserToCertificationUserConnectionConnectionEdge'
      node?: {
        __typename?: 'Certification'
        title?: string | null
        id: string
        databaseId: number
        certificationDetails?: {
          __typename?: 'Certification_Certificationdetails'
          completedDate?: string | null
          expirationDate?: string | null
        } | null
        trainingTypes?: {
          __typename?: 'CertificationToTrainingTypeConnection'
          edges?: Array<{
            __typename?: 'CertificationToTrainingTypeConnectionEdge'
            node?: {
              __typename?: 'TrainingType'
              databaseId: number
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        providers?: {
          __typename?: 'CertificationToProviderConnection'
          edges?: Array<{
            __typename?: 'CertificationToProviderConnectionEdge'
            node?: {
              __typename?: 'Provider'
              slug?: string | null
              name?: string | null
              databaseId: number
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
  emergencyContactInfo?: {
    __typename?: 'User_Emergencycontactinfo'
    emergencyContactName?: string | null
    emergencyContactNumber?: string | null
    emergencyContactRelationship?: string | null
  } | null
  usageCounts?: {
    __typename?: 'User_Usagecounts'
    sdsDownloadCount?: number | null
  } | null
}

export type Users_FragFragment = {
  __typename?: 'User'
  id: string
  databaseId: number
  name?: string | null
  email?: string | null
  username?: string | null
  employeeId?: string | null
  avatar?: { __typename?: 'Avatar'; url?: string | null } | null
  languages?: {
    __typename?: 'UserToUserLanguageConnection'
    edges?: Array<{
      __typename?: 'UserToUserLanguageConnectionEdge'
      node?: {
        __typename?: 'UserLanguage'
        id: string
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  status?: {
    __typename?: 'UserToUserStatusConnection'
    edges?: Array<{
      __typename?: 'UserToUserStatusConnectionEdge'
      node?: {
        __typename?: 'UserStatus'
        id: string
        name?: string | null
        slug?: string | null
      } | null
    } | null> | null
  } | null
  roles?: {
    __typename?: 'UserToUserRoleConnection'
    edges?: Array<{
      __typename?: 'UserToUserRoleConnectionEdge'
      node?: {
        __typename?: 'UserRole'
        id: string
        name?: string | null
        displayName?: string | null
      } | null
    } | null> | null
  } | null
  companies?: {
    __typename?: 'UserToUserCompanyConnection'
    edges?: Array<{
      __typename?: 'UserToUserCompanyConnectionEdge'
      node?: {
        __typename?: 'UserCompany'
        id: string
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
  divisions?: {
    __typename?: 'UserToUserDivisionConnection'
    edges?: Array<{
      __typename?: 'UserToUserDivisionConnectionEdge'
      node?: {
        __typename?: 'UserDivision'
        id: string
        slug?: string | null
        name?: string | null
      } | null
    } | null> | null
  } | null
}

export type CreateWorkerMutationVariables = Exact<{
  input: CreateUserInput
}>

export type CreateWorkerMutation = {
  __typename?: 'RootMutation'
  createUser?: {
    __typename?: 'CreateUserPayload'
    user?: {
      __typename?: 'User'
      id: string
      databaseId: number
      name?: string | null
      email?: string | null
      username?: string | null
      employeeId?: string | null
      avatar?: { __typename?: 'Avatar'; url?: string | null } | null
      languages?: {
        __typename?: 'UserToUserLanguageConnection'
        edges?: Array<{
          __typename?: 'UserToUserLanguageConnectionEdge'
          node?: {
            __typename?: 'UserLanguage'
            id: string
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      status?: {
        __typename?: 'UserToUserStatusConnection'
        edges?: Array<{
          __typename?: 'UserToUserStatusConnectionEdge'
          node?: {
            __typename?: 'UserStatus'
            id: string
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      roles?: {
        __typename?: 'UserToUserRoleConnection'
        edges?: Array<{
          __typename?: 'UserToUserRoleConnectionEdge'
          node?: {
            __typename?: 'UserRole'
            id: string
            name?: string | null
            displayName?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'UserToUserCompanyConnection'
        edges?: Array<{
          __typename?: 'UserToUserCompanyConnectionEdge'
          node?: {
            __typename?: 'UserCompany'
            id: string
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'UserToUserDivisionConnection'
        edges?: Array<{
          __typename?: 'UserToUserDivisionConnectionEdge'
          node?: {
            __typename?: 'UserDivision'
            id: string
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type DeleteWorkerMutationVariables = Exact<{
  input: DeleteUserInput
  firstProject?: InputMaybe<Scalars['Int']['input']>
  afterProject?: InputMaybe<Scalars['String']['input']>
  firstAssigned?: InputMaybe<Scalars['Int']['input']>
  afterAssigned?: InputMaybe<Scalars['String']['input']>
  firstOwnwer?: InputMaybe<Scalars['Int']['input']>
  afterOwner?: InputMaybe<Scalars['String']['input']>
}>

export type DeleteWorkerMutation = {
  __typename?: 'RootMutation'
  deleteUser?: {
    __typename?: 'DeleteUserPayload'
    deletedId?: string | null
    user?: {
      __typename?: 'User'
      id: string
      databaseId: number
      firstName?: string | null
      lastName?: string | null
      name?: string | null
      email?: string | null
      username?: string | null
      phoneNumber?: string | null
      employeeId?: string | null
      integrationSource?: string | null
      languages?: {
        __typename?: 'UserToUserLanguageConnection'
        edges?: Array<{
          __typename?: 'UserToUserLanguageConnectionEdge'
          node?: {
            __typename?: 'UserLanguage'
            id: string
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      status?: {
        __typename?: 'UserToUserStatusConnection'
        edges?: Array<{
          __typename?: 'UserToUserStatusConnectionEdge'
          node?: {
            __typename?: 'UserStatus'
            id: string
            name?: string | null
          } | null
        } | null> | null
      } | null
      vaccineStatus?: {
        __typename?: 'UserToUserVaccineStatusConnectionEdge'
        node?: {
          __typename?: 'UserVaccineStatus'
          name?: string | null
          slug?: string | null
        } | null
      } | null
      roles?: {
        __typename?: 'UserToUserRoleConnection'
        edges?: Array<{
          __typename?: 'UserToUserRoleConnectionEdge'
          node?: {
            __typename?: 'UserRole'
            name?: string | null
            displayName?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'UserToUserCompanyConnection'
        edges?: Array<{
          __typename?: 'UserToUserCompanyConnectionEdge'
          node?: {
            __typename?: 'UserCompany'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'UserToUserDivisionConnection'
        edges?: Array<{
          __typename?: 'UserToUserDivisionConnectionEdge'
          node?: {
            __typename?: 'UserDivision'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      projectKeyPersonnelConnection?: {
        __typename?: 'UserToProjectKeyPersonnelConnectionConnection'
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          hasPreviousPage: boolean
          endCursor?: string | null
        } | null
        edges?: Array<{
          __typename?: 'UserToProjectKeyPersonnelConnectionConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      correctiveActionAssignedWorkersConnection?: {
        __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnection'
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          hasPreviousPage: boolean
          endCursor?: string | null
        } | null
        edges?: Array<{
          __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnectionEdge'
          node?: {
            __typename?: 'CorrectiveAction'
            databaseId: number
            title?: string | null
            correctiveActionStatuses?: {
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
                node?: {
                  __typename?: 'CorrectiveActionStatus'
                  slug?: string | null
                  name?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      correctiveActionDeficiencyOwnersConnection?: {
        __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnection'
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          hasPreviousPage: boolean
          endCursor?: string | null
        } | null
        edges?: Array<{
          __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
          node?: {
            __typename?: 'CorrectiveAction'
            databaseId: number
            title?: string | null
            correctiveActionStatuses?: {
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
                node?: {
                  __typename?: 'CorrectiveActionStatus'
                  slug?: string | null
                  name?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      certificationUserConnection?: {
        __typename?: 'UserToCertificationUserConnectionConnection'
        edges?: Array<{
          __typename?: 'UserToCertificationUserConnectionConnectionEdge'
          node?: {
            __typename?: 'Certification'
            title?: string | null
            id: string
            databaseId: number
            certificationDetails?: {
              __typename?: 'Certification_Certificationdetails'
              completedDate?: string | null
              expirationDate?: string | null
            } | null
            trainingTypes?: {
              __typename?: 'CertificationToTrainingTypeConnection'
              edges?: Array<{
                __typename?: 'CertificationToTrainingTypeConnectionEdge'
                node?: {
                  __typename?: 'TrainingType'
                  databaseId: number
                  name?: string | null
                  slug?: string | null
                } | null
              } | null> | null
            } | null
            providers?: {
              __typename?: 'CertificationToProviderConnection'
              edges?: Array<{
                __typename?: 'CertificationToProviderConnectionEdge'
                node?: {
                  __typename?: 'Provider'
                  slug?: string | null
                  name?: string | null
                  databaseId: number
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      emergencyContactInfo?: {
        __typename?: 'User_Emergencycontactinfo'
        emergencyContactName?: string | null
        emergencyContactNumber?: string | null
        emergencyContactRelationship?: string | null
      } | null
      usageCounts?: {
        __typename?: 'User_Usagecounts'
        sdsDownloadCount?: number | null
      } | null
    } | null
  } | null
}

export type UpdateWorkerMutationVariables = Exact<{
  input: UpdateUserInput
  firstProject?: InputMaybe<Scalars['Int']['input']>
  afterProject?: InputMaybe<Scalars['String']['input']>
  firstAssigned?: InputMaybe<Scalars['Int']['input']>
  afterAssigned?: InputMaybe<Scalars['String']['input']>
  firstOwnwer?: InputMaybe<Scalars['Int']['input']>
  afterOwner?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateWorkerMutation = {
  __typename?: 'RootMutation'
  updateUser?: {
    __typename?: 'UpdateUserPayload'
    user?: {
      __typename?: 'User'
      id: string
      databaseId: number
      firstName?: string | null
      lastName?: string | null
      name?: string | null
      email?: string | null
      username?: string | null
      phoneNumber?: string | null
      employeeId?: string | null
      integrationSource?: string | null
      languages?: {
        __typename?: 'UserToUserLanguageConnection'
        edges?: Array<{
          __typename?: 'UserToUserLanguageConnectionEdge'
          node?: {
            __typename?: 'UserLanguage'
            id: string
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      status?: {
        __typename?: 'UserToUserStatusConnection'
        edges?: Array<{
          __typename?: 'UserToUserStatusConnectionEdge'
          node?: {
            __typename?: 'UserStatus'
            id: string
            name?: string | null
          } | null
        } | null> | null
      } | null
      vaccineStatus?: {
        __typename?: 'UserToUserVaccineStatusConnectionEdge'
        node?: {
          __typename?: 'UserVaccineStatus'
          name?: string | null
          slug?: string | null
        } | null
      } | null
      roles?: {
        __typename?: 'UserToUserRoleConnection'
        edges?: Array<{
          __typename?: 'UserToUserRoleConnectionEdge'
          node?: {
            __typename?: 'UserRole'
            name?: string | null
            displayName?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'UserToUserCompanyConnection'
        edges?: Array<{
          __typename?: 'UserToUserCompanyConnectionEdge'
          node?: {
            __typename?: 'UserCompany'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'UserToUserDivisionConnection'
        edges?: Array<{
          __typename?: 'UserToUserDivisionConnectionEdge'
          node?: {
            __typename?: 'UserDivision'
            slug?: string | null
            name?: string | null
          } | null
        } | null> | null
      } | null
      projectKeyPersonnelConnection?: {
        __typename?: 'UserToProjectKeyPersonnelConnectionConnection'
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          hasPreviousPage: boolean
          endCursor?: string | null
        } | null
        edges?: Array<{
          __typename?: 'UserToProjectKeyPersonnelConnectionConnectionEdge'
          node?: {
            __typename?: 'Project'
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
      } | null
      correctiveActionAssignedWorkersConnection?: {
        __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnection'
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          hasPreviousPage: boolean
          endCursor?: string | null
        } | null
        edges?: Array<{
          __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnectionEdge'
          node?: {
            __typename?: 'CorrectiveAction'
            databaseId: number
            title?: string | null
            correctiveActionStatuses?: {
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
                node?: {
                  __typename?: 'CorrectiveActionStatus'
                  slug?: string | null
                  name?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      correctiveActionDeficiencyOwnersConnection?: {
        __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnection'
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          hasPreviousPage: boolean
          endCursor?: string | null
        } | null
        edges?: Array<{
          __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
          node?: {
            __typename?: 'CorrectiveAction'
            databaseId: number
            title?: string | null
            correctiveActionStatuses?: {
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
                node?: {
                  __typename?: 'CorrectiveActionStatus'
                  slug?: string | null
                  name?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      certificationUserConnection?: {
        __typename?: 'UserToCertificationUserConnectionConnection'
        edges?: Array<{
          __typename?: 'UserToCertificationUserConnectionConnectionEdge'
          node?: {
            __typename?: 'Certification'
            title?: string | null
            id: string
            databaseId: number
            certificationDetails?: {
              __typename?: 'Certification_Certificationdetails'
              completedDate?: string | null
              expirationDate?: string | null
            } | null
            trainingTypes?: {
              __typename?: 'CertificationToTrainingTypeConnection'
              edges?: Array<{
                __typename?: 'CertificationToTrainingTypeConnectionEdge'
                node?: {
                  __typename?: 'TrainingType'
                  databaseId: number
                  name?: string | null
                  slug?: string | null
                } | null
              } | null> | null
            } | null
            providers?: {
              __typename?: 'CertificationToProviderConnection'
              edges?: Array<{
                __typename?: 'CertificationToProviderConnectionEdge'
                node?: {
                  __typename?: 'Provider'
                  slug?: string | null
                  name?: string | null
                  databaseId: number
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
      } | null
      emergencyContactInfo?: {
        __typename?: 'User_Emergencycontactinfo'
        emergencyContactName?: string | null
        emergencyContactNumber?: string | null
        emergencyContactRelationship?: string | null
      } | null
      usageCounts?: {
        __typename?: 'User_Usagecounts'
        sdsDownloadCount?: number | null
      } | null
    } | null
  } | null
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'RootMutation'
  updateUser?: {
    __typename?: 'UpdateUserPayload'
    user?: {
      __typename?: 'User'
      username?: string | null
      id: string
      databaseId: number
      firstName?: string | null
      lastName?: string | null
      name?: string | null
      email?: string | null
      magicBellHmacEmail?: string | null
      capabilities?: Array<string | null> | null
      offlineMode?: boolean | null
      offlineModeData?: Array<OfflineModeDataCollectionNameEnum | null> | null
      isSuperAdmin?: boolean | null
      avatar?: { __typename?: 'Avatar'; url?: string | null } | null
      languages?: {
        __typename?: 'UserToUserLanguageConnection'
        edges?: Array<{
          __typename?: 'UserToUserLanguageConnectionEdge'
          node?: {
            __typename?: 'UserLanguage'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      companies?: {
        __typename?: 'UserToUserCompanyConnection'
        edges?: Array<{
          __typename?: 'UserToUserCompanyConnectionEdge'
          node?: {
            __typename?: 'UserCompany'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      divisions?: {
        __typename?: 'UserToUserDivisionConnection'
        edges?: Array<{
          __typename?: 'UserToUserDivisionConnectionEdge'
          node?: {
            __typename?: 'UserDivision'
            name?: string | null
            slug?: string | null
          } | null
        } | null> | null
      } | null
      roles?: {
        __typename?: 'UserToUserRoleConnection'
        edges?: Array<{
          __typename?: 'UserToUserRoleConnectionEdge'
          node?: {
            __typename?: 'UserRole'
            name?: string | null
            displayName?: string | null
          } | null
        } | null> | null
      } | null
      correctiveActionAssignedWorkersConnection?: {
        __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnection'
        edges?: Array<{
          __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnectionEdge'
          node?: {
            __typename?: 'CorrectiveAction'
            id: string
            databaseId: number
            title?: string | null
            actionTaken?: string | null
            date?: string | null
            dueDate?: string | null
            dateCompleted?: string | null
            projectCorrectiveActionConnection?: {
              __typename?: 'CorrectiveActionToProjectConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToProjectConnectionEdge'
                node?: {
                  __typename?: 'Project'
                  number?: string | null
                  title?: string | null
                  databaseId: number
                } | null
              } | null> | null
            } | null
            correctiveActionStatuses?: {
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
                node?: {
                  __typename?: 'CorrectiveActionStatus'
                  slug?: string | null
                  name?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          endCursor?: string | null
        } | null
      } | null
      correctiveActionDeficiencyOwnersConnection?: {
        __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnection'
        edges?: Array<{
          __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
          node?: {
            __typename?: 'CorrectiveAction'
            id: string
            databaseId: number
            title?: string | null
            actionTaken?: string | null
            date?: string | null
            dueDate?: string | null
            dateCompleted?: string | null
            projectCorrectiveActionConnection?: {
              __typename?: 'CorrectiveActionToProjectConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToProjectConnectionEdge'
                node?: {
                  __typename?: 'Project'
                  number?: string | null
                  title?: string | null
                  databaseId: number
                } | null
              } | null> | null
            } | null
            correctiveActionStatuses?: {
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
              edges?: Array<{
                __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
                node?: {
                  __typename?: 'CorrectiveActionStatus'
                  slug?: string | null
                  name?: string | null
                } | null
              } | null> | null
            } | null
          } | null
        } | null> | null
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          endCursor?: string | null
        } | null
      } | null
      projectKeyPersonnelConnection?: {
        __typename?: 'UserToProjectKeyPersonnelConnectionConnection'
        edges?: Array<{
          __typename?: 'UserToProjectKeyPersonnelConnectionConnectionEdge'
          node?: {
            __typename?: 'Project'
            id: string
            databaseId: number
            title?: string | null
            number?: string | null
          } | null
        } | null> | null
        pageInfo?: {
          __typename?: 'WPPageInfo'
          hasNextPage: boolean
          endCursor?: string | null
        } | null
      } | null
      emergencyContactInfo?: {
        __typename?: 'User_Emergencycontactinfo'
        emergencyContactName?: string | null
        emergencyContactNumber?: string | null
        emergencyContactRelationship?: string | null
      } | null
      usageCounts?: {
        __typename?: 'User_Usagecounts'
        sdsDownloadCount?: number | null
      } | null
    } | null
  } | null
}

export type AllUserCompaniesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type AllUserCompaniesQuery = {
  __typename?: 'RootQuery'
  userCompanies?: {
    __typename?: 'RootQueryToUserCompanyConnection'
    edges?: Array<{
      __typename?: 'RootQueryToUserCompanyConnectionEdge'
      node?: {
        __typename?: 'UserCompany'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
}

export type AllUserDivisionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type AllUserDivisionsQuery = {
  __typename?: 'RootQuery'
  userDivisions?: {
    __typename?: 'RootQueryToUserDivisionConnection'
    edges?: Array<{
      __typename?: 'RootQueryToUserDivisionConnectionEdge'
      node?: {
        __typename?: 'UserDivision'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
}

export type AllUserRolesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type AllUserRolesQuery = {
  __typename?: 'RootQuery'
  userRoles?: {
    __typename?: 'RootQueryToUserRoleConnection'
    edges?: Array<{
      __typename?: 'RootQueryToUserRoleConnectionEdge'
      node?: {
        __typename?: 'UserRole'
        name?: string | null
        displayName?: string | null
        id: string
      } | null
    } | null> | null
  } | null
}

export type AllUserStatusesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type AllUserStatusesQuery = {
  __typename?: 'RootQuery'
  userStatuses?: {
    __typename?: 'RootQueryToUserStatusConnection'
    edges?: Array<{
      __typename?: 'RootQueryToUserStatusConnectionEdge'
      node?: {
        __typename?: 'UserStatus'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
}

export type AllWorkersQueryVariables = Exact<{
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToUserConnectionWhereArgs>
}>

export type AllWorkersQuery = {
  __typename?: 'RootQuery'
  workers?: {
    __typename?: 'RootQueryToUserConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      endCursor?: string | null
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        id: string
        databaseId: number
        name?: string | null
        email?: string | null
        username?: string | null
        employeeId?: string | null
        avatar?: { __typename?: 'Avatar'; url?: string | null } | null
        languages?: {
          __typename?: 'UserToUserLanguageConnection'
          edges?: Array<{
            __typename?: 'UserToUserLanguageConnectionEdge'
            node?: {
              __typename?: 'UserLanguage'
              id: string
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        status?: {
          __typename?: 'UserToUserStatusConnection'
          edges?: Array<{
            __typename?: 'UserToUserStatusConnectionEdge'
            node?: {
              __typename?: 'UserStatus'
              id: string
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        roles?: {
          __typename?: 'UserToUserRoleConnection'
          edges?: Array<{
            __typename?: 'UserToUserRoleConnectionEdge'
            node?: {
              __typename?: 'UserRole'
              id: string
              name?: string | null
              displayName?: string | null
            } | null
          } | null> | null
        } | null
        companies?: {
          __typename?: 'UserToUserCompanyConnection'
          edges?: Array<{
            __typename?: 'UserToUserCompanyConnectionEdge'
            node?: {
              __typename?: 'UserCompany'
              id: string
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
        divisions?: {
          __typename?: 'UserToUserDivisionConnection'
          edges?: Array<{
            __typename?: 'UserToUserDivisionConnectionEdge'
            node?: {
              __typename?: 'UserDivision'
              id: string
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type AllWorkersWithVaccineStatusQueryVariables = Exact<{
  first: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  where?: InputMaybe<RootQueryToUserConnectionWhereArgs>
}>

export type AllWorkersWithVaccineStatusQuery = {
  __typename?: 'RootQuery'
  workers?: {
    __typename?: 'RootQueryToUserConnection'
    pageInfo?: {
      __typename?: 'WPPageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      endCursor?: string | null
    } | null
    edges?: Array<{
      __typename?: 'RootQueryToUserConnectionEdge'
      node?: {
        __typename?: 'User'
        id: string
        databaseId: number
        name?: string | null
        email?: string | null
        username?: string | null
        employeeId?: string | null
        vaccineStatus?: {
          __typename?: 'UserToUserVaccineStatusConnectionEdge'
          node?: {
            __typename?: 'UserVaccineStatus'
            name?: string | null
            slug?: string | null
          } | null
        } | null
        avatar?: { __typename?: 'Avatar'; url?: string | null } | null
        languages?: {
          __typename?: 'UserToUserLanguageConnection'
          edges?: Array<{
            __typename?: 'UserToUserLanguageConnectionEdge'
            node?: {
              __typename?: 'UserLanguage'
              id: string
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        status?: {
          __typename?: 'UserToUserStatusConnection'
          edges?: Array<{
            __typename?: 'UserToUserStatusConnectionEdge'
            node?: {
              __typename?: 'UserStatus'
              id: string
              name?: string | null
              slug?: string | null
            } | null
          } | null> | null
        } | null
        roles?: {
          __typename?: 'UserToUserRoleConnection'
          edges?: Array<{
            __typename?: 'UserToUserRoleConnectionEdge'
            node?: {
              __typename?: 'UserRole'
              id: string
              name?: string | null
              displayName?: string | null
            } | null
          } | null> | null
        } | null
        companies?: {
          __typename?: 'UserToUserCompanyConnection'
          edges?: Array<{
            __typename?: 'UserToUserCompanyConnectionEdge'
            node?: {
              __typename?: 'UserCompany'
              id: string
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
        divisions?: {
          __typename?: 'UserToUserDivisionConnection'
          edges?: Array<{
            __typename?: 'UserToUserDivisionConnectionEdge'
            node?: {
              __typename?: 'UserDivision'
              id: string
              slug?: string | null
              name?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type GetWorkerQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
  firstProject?: InputMaybe<Scalars['Int']['input']>
  afterProject?: InputMaybe<Scalars['String']['input']>
  firstAssigned?: InputMaybe<Scalars['Int']['input']>
  afterAssigned?: InputMaybe<Scalars['String']['input']>
  firstOwnwer?: InputMaybe<Scalars['Int']['input']>
  afterOwner?: InputMaybe<Scalars['String']['input']>
}>

export type GetWorkerQuery = {
  __typename?: 'RootQuery'
  user?: {
    __typename?: 'User'
    id: string
    databaseId: number
    firstName?: string | null
    lastName?: string | null
    name?: string | null
    email?: string | null
    username?: string | null
    phoneNumber?: string | null
    employeeId?: string | null
    integrationSource?: string | null
    languages?: {
      __typename?: 'UserToUserLanguageConnection'
      edges?: Array<{
        __typename?: 'UserToUserLanguageConnectionEdge'
        node?: {
          __typename?: 'UserLanguage'
          id: string
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    status?: {
      __typename?: 'UserToUserStatusConnection'
      edges?: Array<{
        __typename?: 'UserToUserStatusConnectionEdge'
        node?: {
          __typename?: 'UserStatus'
          id: string
          name?: string | null
        } | null
      } | null> | null
    } | null
    vaccineStatus?: {
      __typename?: 'UserToUserVaccineStatusConnectionEdge'
      node?: {
        __typename?: 'UserVaccineStatus'
        name?: string | null
        slug?: string | null
      } | null
    } | null
    roles?: {
      __typename?: 'UserToUserRoleConnection'
      edges?: Array<{
        __typename?: 'UserToUserRoleConnectionEdge'
        node?: {
          __typename?: 'UserRole'
          name?: string | null
          displayName?: string | null
        } | null
      } | null> | null
    } | null
    companies?: {
      __typename?: 'UserToUserCompanyConnection'
      edges?: Array<{
        __typename?: 'UserToUserCompanyConnectionEdge'
        node?: {
          __typename?: 'UserCompany'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    divisions?: {
      __typename?: 'UserToUserDivisionConnection'
      edges?: Array<{
        __typename?: 'UserToUserDivisionConnectionEdge'
        node?: {
          __typename?: 'UserDivision'
          slug?: string | null
          name?: string | null
        } | null
      } | null> | null
    } | null
    projectKeyPersonnelConnection?: {
      __typename?: 'UserToProjectKeyPersonnelConnectionConnection'
      pageInfo?: {
        __typename?: 'WPPageInfo'
        hasNextPage: boolean
        hasPreviousPage: boolean
        endCursor?: string | null
      } | null
      edges?: Array<{
        __typename?: 'UserToProjectKeyPersonnelConnectionConnectionEdge'
        node?: {
          __typename?: 'Project'
          databaseId: number
          title?: string | null
          number?: string | null
        } | null
      } | null> | null
    } | null
    correctiveActionAssignedWorkersConnection?: {
      __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnection'
      pageInfo?: {
        __typename?: 'WPPageInfo'
        hasNextPage: boolean
        hasPreviousPage: boolean
        endCursor?: string | null
      } | null
      edges?: Array<{
        __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnectionEdge'
        node?: {
          __typename?: 'CorrectiveAction'
          databaseId: number
          title?: string | null
          correctiveActionStatuses?: {
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
            edges?: Array<{
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
              node?: {
                __typename?: 'CorrectiveActionStatus'
                slug?: string | null
                name?: string | null
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
    correctiveActionDeficiencyOwnersConnection?: {
      __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnection'
      pageInfo?: {
        __typename?: 'WPPageInfo'
        hasNextPage: boolean
        hasPreviousPage: boolean
        endCursor?: string | null
      } | null
      edges?: Array<{
        __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
        node?: {
          __typename?: 'CorrectiveAction'
          databaseId: number
          title?: string | null
          correctiveActionStatuses?: {
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
            edges?: Array<{
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
              node?: {
                __typename?: 'CorrectiveActionStatus'
                slug?: string | null
                name?: string | null
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
    certificationUserConnection?: {
      __typename?: 'UserToCertificationUserConnectionConnection'
      edges?: Array<{
        __typename?: 'UserToCertificationUserConnectionConnectionEdge'
        node?: {
          __typename?: 'Certification'
          title?: string | null
          id: string
          databaseId: number
          certificationDetails?: {
            __typename?: 'Certification_Certificationdetails'
            completedDate?: string | null
            expirationDate?: string | null
          } | null
          trainingTypes?: {
            __typename?: 'CertificationToTrainingTypeConnection'
            edges?: Array<{
              __typename?: 'CertificationToTrainingTypeConnectionEdge'
              node?: {
                __typename?: 'TrainingType'
                databaseId: number
                name?: string | null
                slug?: string | null
              } | null
            } | null> | null
          } | null
          providers?: {
            __typename?: 'CertificationToProviderConnection'
            edges?: Array<{
              __typename?: 'CertificationToProviderConnectionEdge'
              node?: {
                __typename?: 'Provider'
                slug?: string | null
                name?: string | null
                databaseId: number
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
    emergencyContactInfo?: {
      __typename?: 'User_Emergencycontactinfo'
      emergencyContactName?: string | null
      emergencyContactNumber?: string | null
      emergencyContactRelationship?: string | null
    } | null
    usageCounts?: {
      __typename?: 'User_Usagecounts'
      sdsDownloadCount?: number | null
    } | null
  } | null
}

export type GetWorkerComplianceQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
  first: Scalars['Int']['input']
  where?: InputMaybe<UserToGfEntryConnectionWhereArgs>
}>

export type GetWorkerComplianceQuery = {
  __typename?: 'RootQuery'
  worker?: {
    __typename?: 'User'
    entries?: {
      __typename?: 'UserToGfEntryConnection'
      edges?: Array<{
        __typename?: 'UserToGfEntryConnectionEdge'
        node?:
          | { __typename?: 'GfDraftEntry'; dateCreated?: string | null }
          | {
              __typename?: 'GfSubmittedEntry'
              databaseId: number
              dateCreated?: string | null
            }
          | null
      } | null> | null
    } | null
  } | null
}

export type MeQueryVariables = Exact<{
  databaseId: Scalars['ID']['input']
}>

export type MeQuery = {
  __typename?: 'RootQuery'
  user?: {
    __typename?: 'User'
    username?: string | null
    id: string
    databaseId: number
    firstName?: string | null
    lastName?: string | null
    name?: string | null
    email?: string | null
    magicBellHmacEmail?: string | null
    capabilities?: Array<string | null> | null
    offlineMode?: boolean | null
    offlineModeData?: Array<OfflineModeDataCollectionNameEnum | null> | null
    isSuperAdmin?: boolean | null
    avatar?: { __typename?: 'Avatar'; url?: string | null } | null
    languages?: {
      __typename?: 'UserToUserLanguageConnection'
      edges?: Array<{
        __typename?: 'UserToUserLanguageConnectionEdge'
        node?: {
          __typename?: 'UserLanguage'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    companies?: {
      __typename?: 'UserToUserCompanyConnection'
      edges?: Array<{
        __typename?: 'UserToUserCompanyConnectionEdge'
        node?: {
          __typename?: 'UserCompany'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    divisions?: {
      __typename?: 'UserToUserDivisionConnection'
      edges?: Array<{
        __typename?: 'UserToUserDivisionConnectionEdge'
        node?: {
          __typename?: 'UserDivision'
          name?: string | null
          slug?: string | null
        } | null
      } | null> | null
    } | null
    roles?: {
      __typename?: 'UserToUserRoleConnection'
      edges?: Array<{
        __typename?: 'UserToUserRoleConnectionEdge'
        node?: {
          __typename?: 'UserRole'
          name?: string | null
          displayName?: string | null
        } | null
      } | null> | null
    } | null
    correctiveActionAssignedWorkersConnection?: {
      __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnection'
      edges?: Array<{
        __typename?: 'UserToCorrectiveActionAssignedWorkersConnectionConnectionEdge'
        node?: {
          __typename?: 'CorrectiveAction'
          id: string
          databaseId: number
          title?: string | null
          actionTaken?: string | null
          date?: string | null
          dueDate?: string | null
          dateCompleted?: string | null
          projectCorrectiveActionConnection?: {
            __typename?: 'CorrectiveActionToProjectConnection'
            edges?: Array<{
              __typename?: 'CorrectiveActionToProjectConnectionEdge'
              node?: {
                __typename?: 'Project'
                number?: string | null
                title?: string | null
                databaseId: number
              } | null
            } | null> | null
          } | null
          correctiveActionStatuses?: {
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
            edges?: Array<{
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
              node?: {
                __typename?: 'CorrectiveActionStatus'
                slug?: string | null
                name?: string | null
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
      pageInfo?: {
        __typename?: 'WPPageInfo'
        hasNextPage: boolean
        endCursor?: string | null
      } | null
    } | null
    correctiveActionDeficiencyOwnersConnection?: {
      __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnection'
      edges?: Array<{
        __typename?: 'UserToCorrectiveActionDeficiencyOwnersConnectionConnectionEdge'
        node?: {
          __typename?: 'CorrectiveAction'
          id: string
          databaseId: number
          title?: string | null
          actionTaken?: string | null
          date?: string | null
          dueDate?: string | null
          dateCompleted?: string | null
          projectCorrectiveActionConnection?: {
            __typename?: 'CorrectiveActionToProjectConnection'
            edges?: Array<{
              __typename?: 'CorrectiveActionToProjectConnectionEdge'
              node?: {
                __typename?: 'Project'
                number?: string | null
                title?: string | null
                databaseId: number
              } | null
            } | null> | null
          } | null
          correctiveActionStatuses?: {
            __typename?: 'CorrectiveActionToCorrectiveActionStatusConnection'
            edges?: Array<{
              __typename?: 'CorrectiveActionToCorrectiveActionStatusConnectionEdge'
              node?: {
                __typename?: 'CorrectiveActionStatus'
                slug?: string | null
                name?: string | null
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
      pageInfo?: {
        __typename?: 'WPPageInfo'
        hasNextPage: boolean
        endCursor?: string | null
      } | null
    } | null
    projectKeyPersonnelConnection?: {
      __typename?: 'UserToProjectKeyPersonnelConnectionConnection'
      edges?: Array<{
        __typename?: 'UserToProjectKeyPersonnelConnectionConnectionEdge'
        node?: {
          __typename?: 'Project'
          id: string
          databaseId: number
          title?: string | null
          number?: string | null
        } | null
      } | null> | null
      pageInfo?: {
        __typename?: 'WPPageInfo'
        hasNextPage: boolean
        endCursor?: string | null
      } | null
    } | null
    emergencyContactInfo?: {
      __typename?: 'User_Emergencycontactinfo'
      emergencyContactName?: string | null
      emergencyContactNumber?: string | null
      emergencyContactRelationship?: string | null
    } | null
    usageCounts?: {
      __typename?: 'User_Usagecounts'
      sdsDownloadCount?: number | null
    } | null
  } | null
}

export type NewWorkerFormDataQueryVariables = Exact<{ [key: string]: never }>

export type NewWorkerFormDataQuery = {
  __typename?: 'RootQuery'
  userCompanies?: {
    __typename?: 'RootQueryToUserCompanyConnection'
    edges?: Array<{
      __typename?: 'RootQueryToUserCompanyConnectionEdge'
      node?: {
        __typename?: 'UserCompany'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
  userStatuses?: {
    __typename?: 'RootQueryToUserStatusConnection'
    edges?: Array<{
      __typename?: 'RootQueryToUserStatusConnectionEdge'
      node?: {
        __typename?: 'UserStatus'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
  userDivisions?: {
    __typename?: 'RootQueryToUserDivisionConnection'
    edges?: Array<{
      __typename?: 'RootQueryToUserDivisionConnectionEdge'
      node?: {
        __typename?: 'UserDivision'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
  userRoles?: {
    __typename?: 'RootQueryToUserRoleConnection'
    edges?: Array<{
      __typename?: 'RootQueryToUserRoleConnectionEdge'
      node?: {
        __typename?: 'UserRole'
        name?: string | null
        displayName?: string | null
        id: string
      } | null
    } | null> | null
  } | null
  userLanguages?: {
    __typename?: 'RootQueryToUserLanguageConnection'
    edges?: Array<{
      __typename?: 'RootQueryToUserLanguageConnectionEdge'
      node?: {
        __typename?: 'UserLanguage'
        name?: string | null
        slug?: string | null
        databaseId: number
        id: string
      } | null
    } | null> | null
  } | null
}

export type CreateSuggestedUsernameMutationVariables = Exact<{
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
}>

export type CreateSuggestedUsernameMutation = {
  __typename?: 'RootMutation'
  suggestedUsername?: string | null
}

export const Certification_FragFragmentDoc = gql`
  fragment CERTIFICATION_FRAG on Certification {
    id
    databaseId
    title
    content
    certificationDetails {
      completedDate
      expirationDate
    }
    author {
      node {
        databaseId
      }
    }
    certificationUserConnection {
      edges {
        node {
          name
          databaseId
        }
      }
    }
    trainingTypes(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
    providers(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
    fileAttachments(first: 5) {
      edges {
        node {
          id
          title
          description
          parentDatabaseId
          mediaItemUrl
          sizes
          databaseId
          sourceUrl
          mediaType
        }
      }
    }
  }
`
export const Certifications_FragFragmentDoc = gql`
  fragment CERTIFICATIONS_FRAG on Certification {
    id
    databaseId
    title
    certificationDetails {
      completedDate
      expirationDate
    }
    certificationUserConnection(first: 1) {
      edges {
        node {
          name
          databaseId
        }
      }
    }
    trainingTypes(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
    fileAttachments(first: 6) {
      edges {
        node {
          id
        }
      }
    }
  }
`
export const Corrective_Action_FragFragmentDoc = gql`
  fragment CORRECTIVE_ACTION_FRAG on CorrectiveAction {
    id
    databaseId
    title
    date
    dueDate
    dateCompleted
    actionTaken
    deficiencyFieldLabels
    author {
      node {
        name
        databaseId
        email
      }
    }
    companies {
      edges {
        node {
          name
        }
      }
    }
    divisions {
      edges {
        node {
          name
        }
      }
    }
    correctiveActionPhotoConnection(first: 10) {
      edges {
        node {
          id
          title
          databaseId
          featuredImage {
            node {
              sourceUrl
              mediaDetails {
                sizes {
                  sourceUrl
                  width
                }
              }
            }
          }
        }
      }
    }
    projectCorrectiveActionConnection(first: 1) {
      edges {
        node {
          databaseId
          title
          number
        }
      }
    }
    correctiveActionAssignedWorkersConnection(first: 10) {
      edges {
        node {
          databaseId
          name
        }
      }
    }
    correctiveActionDeficiencyOwnersConnection(first: 10) {
      edges {
        node {
          databaseId
          name
        }
      }
    }
    entryIds {
      edges {
        node {
          slug
        }
      }
    }
    formIds(first: 1) {
      edges {
        node {
          slug
          name
        }
      }
    }
    form {
      slug
      title
      databaseId
      id
    }
    entry {
      ... on GfSubmittedEntry {
        databaseId
      }
      dateCreated
    }
    fieldIds {
      edges {
        node {
          slug
        }
      }
    }
    link
    correctiveActionStatuses {
      edges {
        node {
          slug
          name
        }
      }
    }
    severities {
      edges {
        node {
          name
          slug
        }
      }
    }
    deficiencyTypes {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
export const Corrective_Actions_Report_FragFragmentDoc = gql`
  fragment CORRECTIVE_ACTIONS_REPORT_FRAG on CorrectiveAction {
    id
    databaseId
    date
    companies(first: 5) {
      edges {
        node {
          name
        }
      }
    }
    divisions(first: 10) {
      edges {
        node {
          name
        }
      }
    }
    projectCorrectiveActionConnection(first: 1) {
      edges {
        node {
          databaseId
          title
          number
        }
      }
    }
    correctiveActionStatuses {
      edges {
        node {
          slug
          name
        }
      }
    }
    severities {
      edges {
        node {
          name
          slug
        }
      }
    }
    deficiencyTypes {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
export const Corrective_Actions_FragFragmentDoc = gql`
  fragment CORRECTIVE_ACTIONS_FRAG on CorrectiveAction {
    id
    databaseId
    title
    date
    dueDate
    dateCompleted
    actionTaken
    projectCorrectiveActionConnection(first: 1) {
      edges {
        node {
          number
          id
          databaseId
        }
      }
    }
    link
    correctiveActionStatuses {
      edges {
        node {
          slug
          name
        }
      }
    }
    severities {
      edges {
        node {
          slug
          name
        }
      }
    }
    deficiencyTypes {
      edges {
        node {
          slug
          name
        }
      }
    }
    correctiveActionAssignedWorkersConnection(first: 10) {
      edges {
        node {
          databaseId
          name
        }
      }
    }
    correctiveActionDeficiencyOwnersConnection(first: 10) {
      edges {
        node {
          databaseId
          name
        }
      }
    }
  }
`
export const Document_FragFragmentDoc = gql`
  fragment DOCUMENT_FRAG on Document {
    databaseId
    id
    title
    modified
    content(format: RAW)
    author {
      node {
        databaseId
        name
      }
    }
    companies(first: 10) {
      edges {
        node {
          name
          slug
        }
      }
    }
    divisions(first: 10) {
      edges {
        node {
          name
          slug
        }
      }
    }
    documentTags(first: 10) {
      edges {
        node {
          name
          slug
        }
      }
    }
    documentToProjectConnection {
      edges {
        node {
          databaseId
          title
          number
        }
      }
    }
    equipmentToDocumentConnection {
      edges {
        node {
          databaseId
          title
        }
      }
    }
    fileAttachments(first: 5) {
      edges {
        node {
          id
          title
          description
          parentDatabaseId
          mediaItemUrl
        }
      }
    }
  }
`
export const Documents_FragFragmentDoc = gql`
  fragment DOCUMENTS_FRAG on Document {
    databaseId
    id
    title
    documentTags(first: 10) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
export const Equipment_FragFragmentDoc = gql`
  fragment EQUIPMENT_FRAG on Equipment {
    databaseId
    id
    title
    serialNumber
    modelNumber
    inServiceDate
    lastInspectedDate
    nextInspectionDate
    integrationSource
    author {
      node {
        databaseId
        name
      }
    }
    equipmentStatuses(first: 10) {
      edges {
        node {
          name
          slug
        }
      }
    }
    manufacturers(first: 10) {
      edges {
        node {
          slug
          name
        }
      }
    }
    equipmentTags(first: 10) {
      edges {
        node {
          name
          slug
        }
      }
    }
    companies(first: 10) {
      edges {
        node {
          name
          slug
        }
      }
    }
    divisions(first: 10) {
      edges {
        node {
          name
          slug
        }
      }
    }
    equipmentAssignedWorkerConnection(first: 5) {
      edges {
        node {
          name
          databaseId
        }
      }
    }
    equipmentToDocumentConnection {
      edges {
        node {
          title
          databaseId
        }
      }
    }
    equipmentToProjectConnection {
      edges {
        node {
          databaseId
          title
          number
        }
      }
    }
    equipmentToEquipmentConnection {
      edges {
        node {
          title
          databaseId
        }
      }
    }
    inspectionIntervals {
      edges {
        node {
          name
          slug
        }
      }
    }
    fileAttachments(first: 5) {
      edges {
        node {
          id
          title
          description
          mediaItemUrl
          sizes
          databaseId
          sourceUrl
        }
      }
    }
  }
`
export const ConditionalLogicFragFragmentDoc = gql`
  fragment ConditionalLogicFrag on ConditionalLogic {
    actionType
    logicType
    rules {
      fieldId
      operator
      value
    }
  }
`
export const CaptchaFieldFragFragmentDoc = gql`
  fragment CaptchaFieldFrag on CaptchaField {
    captchaBadgePosition
    captchaLanguage
    captchaTheme
    captchaType
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    description
    descriptionPlacement
    errorMessage
    label
    labelPlacement
    simpleCaptchaBackgroundColor
    simpleCaptchaFontColor
    simpleCaptchaSize
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const ConsentFieldFragFragmentDoc = gql`
  fragment ConsentFieldFrag on ConsentField {
    description
    checkboxLabel
    label
    isRequired
    consentTranslations {
      slug
      name
      label
      description
      checkboxLabel
    }
    adminLabel
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    description
    errorMessage
    labelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const PasswordFieldFragFragmentDoc = gql`
  fragment PasswordFieldFrag on PasswordField {
    adminLabel
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    description
    descriptionPlacement
    errorMessage
    hasPasswordStrengthIndicator
    hasPasswordVisibilityToggle
    inputs {
      customLabel
      id
      isHidden
      label
      placeholder
    }
    isRequired
    label
    labelPlacement
    minPasswordStrength
    size
    subLabelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const FormEntryLimitFragFragmentDoc = gql`
  fragment FormEntryLimitFrag on FormEntryLimits {
    hasLimit
    limitReachedMessage
    limitationPeriod
    maxEntries
  }
`
export const FormForCoreNavListFragFragmentDoc = gql`
  fragment FormForCoreNavListFrag on GfForm {
    title
    slug
    databaseId
    postTypeToCreate
    isPrimary
    hideInNav
    isCAForm
    isQuizForm
    group
    translations {
      name
      slug
      active
      title
    }
  }
`
export const FormConfirmationFragFragmentDoc = gql`
  fragment FormConfirmationFrag on FormConfirmation {
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    id
    isActive
    isDefault
    message
    name
    pageId
    queryString
    type
    url
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const FormLastPageButtonFragFragmentDoc = gql`
  fragment FormLastPageButtonFrag on FormLastPageButton {
    text
    type
  }
`
export const FormPaginationFragFragmentDoc = gql`
  fragment FormPaginationFrag on FormPagination {
    lastPageButton {
      ...FormLastPageButtonFrag
    }
    pageNames
  }
  ${FormLastPageButtonFragFragmentDoc}
`
export const FormSubmitButtonFragFragmentDoc = gql`
  fragment FormSubmitButtonFrag on FormSubmitButton {
    text
    type
    conditionalLogic {
      ...ConditionalLogicFrag
    }
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const FormSaveAndContinueFragFragmentDoc = gql`
  fragment FormSaveAndContinueFrag on FormSaveAndContinue {
    hasSaveAndContinue
    buttonText
  }
`
export const FormTranslationFragFragmentDoc = gql`
  fragment FormTranslationFrag on FormTranslation {
    slug
    name
    active
    title
    description
    submitButtonText
    nextButtonText
    clearButtonText
    previousButtonText
    cardDisplay {
      detail1
      detail2
      detail3
    }
  }
`
export const FormBasicFragFragmentDoc = gql`
  fragment FormBasicFrag on GfForm {
    confirmations {
      ...FormConfirmationFrag
    }
    cssClass
    description
    disableEditing
    hideJumpToSection
    id
    reloadOnSubmit
    restrictEntriesToUserCreated
    restrictFormSubmissionByRole
    slug
    title
    postTypeToCreate
    isCAForm
    isPrimary
    isQuizForm
    group
    pagination {
      ...FormPaginationFrag
    }
    databaseId
    isActive
    version
    submitButton {
      ...FormSubmitButtonFrag
    }
    saveAndContinue {
      ...FormSaveAndContinueFrag
    }
    translations {
      ...FormTranslationFrag
    }
  }
  ${FormConfirmationFragFragmentDoc}
  ${FormPaginationFragFragmentDoc}
  ${FormSubmitButtonFragFragmentDoc}
  ${FormSaveAndContinueFragFragmentDoc}
  ${FormTranslationFragFragmentDoc}
`
export const FormFieldFragFragmentDoc = gql`
  fragment FormFieldFrag on FormField {
    __typename
    id
    type
    inputType
    pageNumber
    visibility
    displayOnly
  }
`
export const AddressFieldFragFragmentDoc = gql`
  fragment AddressFieldFrag on AddressField {
    label
    description
    isRequired
    inputs {
      key
      label
      placeholder
      defaultValue
      customLabel
      autocompleteAttribute
      id
      isHidden
      name
    }
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    addressTranslations {
      name
      slug
      label
      description
      address
      address2
      city
      state
      country
      zip
    }
    addressType
    adminLabel
    canPrepopulate
    copyValuesOptionLabel
    copyValuesOptionFieldId
    cssClass
    defaultCountry
    defaultState
    defaultProvince
    descriptionPlacement
    errorMessage
    hasAutocomplete
    inputName
    labelPlacement
    shouldCopyValuesOption
    subLabelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const CheckboxFieldFragFragmentDoc = gql`
  fragment CheckboxFieldFrag on CheckboxField {
    label
    isRequired
    description
    choices {
      text
      value
      isSelected
    }
    inputs {
      id
    }
    choiceTranslations {
      slug
      name
      label
      description
      choices
    }
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    adminLabel
    canPrepopulate
    cssClass
    descriptionPlacement
    errorMessage
    hasChoiceValue
    hasSelectAll
    inputName
    inputs {
      id
      label
      name
    }
    labelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const ComboSignatureFieldFragFragmentDoc = gql`
  fragment ComboSignatureFieldFrag on ComboSignatureField {
    label
    isRequired
    description
    backgroundColor
    borderColor
    borderStyle
    borderWidth
    boxWidth
    choices {
      text
      value
      isSelected
    }
    penSize
    penColor
    minSignatures
    maxSignatures
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    autocompleteAttribute
    backgroundColor
    canPrepopulate
    cssClass
    defaultValue
    descriptionPlacement
    errorMessage
    hasAutocomplete
    hasChoiceValue
    hasEnhancedUI
    inputName
    labelPlacement
    placeholder
    shouldAllowDuplicates
    size
    conditionalLogic {
      ...ConditionalLogicFrag
    }
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const DateFieldFragFragmentDoc = gql`
  fragment DateFieldFrag on DateField {
    label
    isRequired
    description
    placeholder
    defaultValue
    calendarIconType
    inputName
    dateFormat
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    calendarIconUrl
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    dateFormat
    descriptionPlacement
    errorMessage
    inputs {
      autocompleteAttribute
      customLabel
      defaultValue
      id
      label
      placeholder
    }
    labelPlacement
    shouldAllowDuplicates
    subLabelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const EmailFieldFragFragmentDoc = gql`
  fragment EmailFieldFrag on EmailField {
    label
    isRequired
    description
    placeholder
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    errorMessage
    hasAutocomplete
    hasEmailConfirmation
    inputs {
      autocompleteAttribute
      customLabel
      defaultValue
      id
      label
      name
      placeholder
    }
    labelPlacement
    shouldAllowDuplicates
    size
    subLabelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const FileUploadFieldFragFragmentDoc = gql`
  fragment FileUploadFieldFrag on FileUploadField {
    label
    isRequired
    maxFileSize
    maxFiles
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    allowedExtensions
    canAcceptMultipleFiles
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    description
    descriptionPlacement
    errorMessage
    labelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const CorrectiveActionSettingsFragFragmentDoc = gql`
  fragment CorrectiveActionSettingsFrag on CorrectiveActionSettings {
    actionTaken
    deficiency
    deficiencyType
    severity
  }
`
export const FollowUpFieldFragFragmentDoc = gql`
  fragment FollowUpFieldFrag on FollowUpField {
    label
    content
    followUpForm {
      slug
      title
    }
    correctiveActionSettings {
      ...CorrectiveActionSettingsFrag
    }
    basicTranslations {
      slug
      name
      label
    }
    cssClass
    followUpFormId
    hasMargins
    conditionalLogic {
      ...ConditionalLogicFrag
    }
  }
  ${CorrectiveActionSettingsFragFragmentDoc}
  ${ConditionalLogicFragFragmentDoc}
`
export const HiddenFieldFragFragmentDoc = gql`
  fragment HiddenFieldFrag on HiddenField {
    defaultValue
    label
    basicTranslations {
      slug
      name
      label
    }
    canPrepopulate
    inputName
  }
`
export const HtmlFieldFragFragmentDoc = gql`
  fragment HtmlFieldFrag on HtmlField {
    content
    label
    basicTranslations {
      slug
      name
      label
    }
    cssClass
    hasMargins
    conditionalLogic {
      ...ConditionalLogicFrag
    }
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const ListFieldFragFragmentDoc = gql`
  fragment ListFieldFrag on ListField {
    choices {
      text
      value
    }
    description
    isRequired
    inputName
    label
    maxRows
    choiceTranslations {
      slug
      name
      label
      description
      choices
    }
    addIconUrl
    adminLabel
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    deleteIconUrl
    descriptionPlacement
    errorMessage
    hasColumns
    labelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const MultiSelectFieldFragFragmentDoc = gql`
  fragment MultiSelectFieldFrag on MultiSelectField {
    label
    description
    isRequired
    allowCustomOption
    choices {
      text
      value
      isSelected
    }
    choiceTranslations {
      slug
      name
      label
      description
      choices
    }
    adminLabel
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    errorMessage
    hasChoiceValue
    hasEnhancedUI
    inputName
    labelPlacement
    size
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const NameFieldFragFragmentDoc = gql`
  fragment NameFieldFrag on NameField {
    label
    isRequired
    description
    inputs {
      key
      label
      placeholder
      isHidden
      defaultValue
      customLabel
      choices {
        text
        value
        isSelected
      }
      autocompleteAttribute
      hasChoiceValue
      id
      name
    }
    nameTranslations {
      slug
      name
      label
      description
      first
      last
      middle
      prefix
      suffix
    }
    adminLabel
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    errorMessage
    hasAutocomplete
    inputName
    labelPlacement
    subLabelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const NumberFieldFragFragmentDoc = gql`
  fragment NumberFieldFrag on NumberField {
    label
    isRequired
    description
    rangeMin
    rangeMax
    numberFormat
    placeholder
    defaultValue
    calculationFormula
    calculationRounding
    errorMessage
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    autocompleteAttribute
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    hasAutocomplete
    inputName
    isCalculation
    labelPlacement
    shouldAllowDuplicates
    size
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const FormButtonFragFragmentDoc = gql`
  fragment FormButtonFrag on FormButton {
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    text
    type
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const PageFieldFragFragmentDoc = gql`
  fragment PageFieldFrag on PageField {
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    previousButton {
      ...FormButtonFrag
    }
    nextButton {
      ...FormButtonFrag
    }
    basicTranslations {
      slug
      name
      label
    }
  }
  ${ConditionalLogicFragFragmentDoc}
  ${FormButtonFragFragmentDoc}
`
export const PhoneFieldFragFragmentDoc = gql`
  fragment PhoneFieldFrag on PhoneField {
    label
    isRequired
    description
    placeholder
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    autocompleteAttribute
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    defaultValue
    descriptionPlacement
    errorMessage
    hasAutocomplete
    inputName
    labelPlacement
    phoneFormat
    shouldAllowDuplicates
    size
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const PhotoFieldFragFragmentDoc = gql`
  fragment PhotoFieldFrag on PhotoField {
    label
    isRequired
    visibility
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    description
    descriptionPlacement
    errorMessage
    inputName
    labelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const QuizFieldFragFragmentDoc = gql`
  fragment QuizFieldFrag on QuizField {
    adminLabel
    answerExplanation
    canPrepopulate
    choices {
      isCorrect
      isOtherChoice
      isSelected
      text
      value
      weight
    }
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    description
    descriptionPlacement
    errorMessage
    hasChoiceValue
    hasWeightedScore
    inputName
    isRequired
    label
    labelPlacement
    shouldRandomizeQuizChoices
    shouldShowAnswerExplanation
    ... on QuizCheckboxField {
      inputs {
        label
        id
        name
      }
      hasSelectAll
    }
    ... on QuizSelectField {
      defaultValue
      placeholder
      autocompleteAttribute
      hasAutocomplete
      hasEnhancedUI
    }
    ... on QuizRadioField {
      shouldAllowDuplicates
    }
    choiceTranslations {
      slug
      name
      label
      description
      choices
    }
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const RadioFieldFragFragmentDoc = gql`
  fragment RadioFieldFrag on RadioField {
    label
    isRequired
    description
    choices {
      text
      value
      isSelected
      isOtherChoice
    }
    choiceTranslations {
      slug
      name
      label
      description
      choices
    }
    adminLabel
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    errorMessage
    hasChoiceValue
    hasOtherChoice
    inputName
    labelPlacement
    shouldAllowDuplicates
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const SectionFieldFragFragmentDoc = gql`
  fragment SectionFieldFrag on SectionField {
    label
    description
    cssClass
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    basicTranslations {
      slug
      name
      label
      description
    }
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const SelectFieldFragFragmentDoc = gql`
  fragment SelectFieldFrag on SelectField {
    label
    isRequired
    description
    defaultValue
    allowCustomOption
    choices {
      text
      value
      isSelected
    }
    choiceTranslations {
      slug
      name
      label
      description
      choices
    }
    adminLabel
    autocompleteAttribute
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    errorMessage
    hasAutocomplete
    hasChoiceValue
    hasEnhancedUI
    inputName
    labelPlacement
    placeholder
    shouldAllowDuplicates
    size
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const SignatureFieldFragFragmentDoc = gql`
  fragment SignatureFieldFrag on SignatureField {
    label
    isRequired
    description
    penColor
    penSize
    backgroundColor
    borderWidth
    borderStyle
    borderColor
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    boxWidth
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    errorMessage
    labelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const TextAreaFieldFragFragmentDoc = gql`
  fragment TextAreaFieldFrag on TextAreaField {
    label
    isRequired
    description
    maxLength
    placeholder
    defaultValue
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    errorMessage
    hasRichTextEditor
    inputName
    labelPlacement
    shouldAllowDuplicates
    size
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const TextFieldFragFragmentDoc = gql`
  fragment TextFieldFrag on TextField {
    label
    isRequired
    description
    maxLength
    placeholder
    defaultValue
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    autocompleteAttribute
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    errorMessage
    hasAutocomplete
    hasInputMask
    inputMaskValue
    isPasswordInput
    labelPlacement
    shouldAllowDuplicates
    size
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const TimeFieldFragFragmentDoc = gql`
  fragment TimeFieldFrag on TimeField {
    label
    isRequired
    description
    timeFormat
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    errorMessage
    inputs {
      autocompleteAttribute
      customLabel
      defaultValue
      id
      label
      placeholder
    }
    labelPlacement
    shouldAllowDuplicates
    subLabelPlacement
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const WebsiteFieldFragFragmentDoc = gql`
  fragment WebsiteFieldFrag on WebsiteField {
    label
    isRequired
    description
    placeholder
    defaultValue
    basicTranslations {
      slug
      name
      label
      description
    }
    adminLabel
    canPrepopulate
    conditionalLogic {
      ...ConditionalLogicFrag
    }
    cssClass
    descriptionPlacement
    errorMessage
    inputName
    labelPlacement
    shouldAllowDuplicates
  }
  ${ConditionalLogicFragFragmentDoc}
`
export const FormFragFragmentDoc = gql`
  fragment FormFrag on GfForm {
    ...FormBasicFrag
    formFields {
      nodes {
        ...FormFieldFrag
        ... on AddressField {
          ...AddressFieldFrag
        }
        ... on CheckboxField {
          ...CheckboxFieldFrag
        }
        ... on ComboSignatureField {
          ...ComboSignatureFieldFrag
        }
        ... on DateField {
          ...DateFieldFrag
        }
        ... on EmailField {
          ...EmailFieldFrag
        }
        ... on FileUploadField {
          ...FileUploadFieldFrag
        }
        ... on FollowUpField {
          ...FollowUpFieldFrag
        }
        ... on HiddenField {
          ...HiddenFieldFrag
        }
        ... on HtmlField {
          ...HtmlFieldFrag
        }
        ... on ListField {
          ...ListFieldFrag
        }
        ... on MultiSelectField {
          ...MultiSelectFieldFrag
        }
        ... on NameField {
          ...NameFieldFrag
        }
        ... on NumberField {
          ...NumberFieldFrag
        }
        ... on PageField {
          ...PageFieldFrag
        }
        ... on PhoneField {
          ...PhoneFieldFrag
        }
        ... on PhotoField {
          ...PhotoFieldFrag
        }
        ... on QuizField {
          ...QuizFieldFrag
        }
        ... on RadioField {
          ...RadioFieldFrag
        }
        ... on SectionField {
          ...SectionFieldFrag
        }
        ... on SelectField {
          ...SelectFieldFrag
        }
        ... on SignatureField {
          ...SignatureFieldFrag
        }
        ... on TextAreaField {
          ...TextAreaFieldFrag
        }
        ... on TextField {
          ...TextFieldFrag
        }
        ... on TimeField {
          ...TimeFieldFrag
        }
        ... on WebsiteField {
          ...WebsiteFieldFrag
        }
      }
    }
  }
  ${FormBasicFragFragmentDoc}
  ${FormFieldFragFragmentDoc}
  ${AddressFieldFragFragmentDoc}
  ${CheckboxFieldFragFragmentDoc}
  ${ComboSignatureFieldFragFragmentDoc}
  ${DateFieldFragFragmentDoc}
  ${EmailFieldFragFragmentDoc}
  ${FileUploadFieldFragFragmentDoc}
  ${FollowUpFieldFragFragmentDoc}
  ${HiddenFieldFragFragmentDoc}
  ${HtmlFieldFragFragmentDoc}
  ${ListFieldFragFragmentDoc}
  ${MultiSelectFieldFragFragmentDoc}
  ${NameFieldFragFragmentDoc}
  ${NumberFieldFragFragmentDoc}
  ${PageFieldFragFragmentDoc}
  ${PhoneFieldFragFragmentDoc}
  ${PhotoFieldFragFragmentDoc}
  ${QuizFieldFragFragmentDoc}
  ${RadioFieldFragFragmentDoc}
  ${SectionFieldFragFragmentDoc}
  ${SelectFieldFragFragmentDoc}
  ${SignatureFieldFragFragmentDoc}
  ${TextAreaFieldFragFragmentDoc}
  ${TextFieldFragFragmentDoc}
  ${TimeFieldFragFragmentDoc}
  ${WebsiteFieldFragFragmentDoc}
`
export const FormQuizConfirmationFragFragmentDoc = gql`
  fragment FormQuizConfirmationFrag on FormQuizConfirmation {
    isAutoformatted
    message
  }
`
export const FormQuizFragFragmentDoc = gql`
  fragment FormQuizFrag on FormQuiz {
    failConfirmation {
      ...FormQuizConfirmationFrag
    }
    letterConfirmation {
      ...FormQuizConfirmationFrag
    }
    passConfirmation {
      ...FormQuizConfirmationFrag
    }
    grades {
      text
      value
    }
    gradingType
    hasInstantFeedback
    hasLetterConfirmationMessage
    hasPassFailConfirmationMessage
    isShuffleFieldsEnabled
    maxScore
    passPercent
  }
  ${FormQuizConfirmationFragFragmentDoc}
`
export const FormWithQuizFragFragmentDoc = gql`
  fragment FormWithQuizFrag on GfForm {
    ...FormFrag
    isQuizForm
    quiz {
      ...FormQuizFrag
    }
  }
  ${FormFragFragmentDoc}
  ${FormQuizFragFragmentDoc}
`
export const Entries_FragFragmentDoc = gql`
  fragment ENTRIES_FRAG on GfSubmittedEntry {
    id
    dateCreated
    formId
    formDatabaseId
    createdBy {
      databaseId
      name
    }
    gravityPdfTemplates {
      id
      name
    }
    cardDetails {
      detail1 {
        label
        value
      }
      detail2 {
        label
        value
      }
      detail3 {
        label
        value
      }
      heading
      subheading
    }
    ... on GfSubmittedEntry {
      databaseId
      status
    }
  }
`
export const Entry_FragFragmentDoc = gql`
  fragment ENTRY_FRAG on GfSubmittedEntry {
    ... on GfSubmittedEntry {
      databaseId
      status
    }
    formId
    formDatabaseId
    isDraft
    dateCreated
    createdBy {
      name
      databaseId
    }
    cardDetails {
      detail1 {
        label
        value
      }
      detail2 {
        label
        value
      }
      detail3 {
        label
        value
      }
      heading
      subheading
    }
    gravityPdfTemplates {
      id
      name
    }
    formFields(first: 500) {
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          __typename
          id
          type
          inputType
          ... on AddressField {
            cssClass
            label
            value
            addressValues {
              street
              city
              country
              lineTwo
              state
              zip
            }
          }
          ... on CheckboxField {
            cssClass
            label
            value
            checkboxValues {
              inputId
              value
              text
            }
          }
          ... on ConsentField {
            cssClass
            label
            value
          }
          ... on ComboSignatureField {
            cssClass
            label
            value
            signatureValues {
              signature
              user {
                databaseId
                name
              }
            }
          }
          ... on DateField {
            cssClass
            label
            value
          }
          ... on EmailField {
            cssClass
            label
            value
          }
          ... on FileUploadField {
            cssClass
            label
            fileUploadValues {
              basePath
              baseUrl
              filename
              url
            }
            value
          }
          ... on FollowUpField {
            cssClass
            followUpForm {
              isCAForm
            }
            followUpFormId
            conditionalLogic {
              actionType
              logicType
              rules {
                fieldId
                operator
                value
              }
            }
          }
          ... on HtmlField {
            cssClass
            content
          }
          ... on ListField {
            cssClass
            label
            hasColumns
            choices {
              text
              value
            }
            listValues {
              values
            }
            value
          }
          ... on MultiSelectField {
            cssClass
            label
            value
            values
            valuesText
          }
          ... on NameField {
            cssClass
            label
            value
            nameValues {
              first
              last
              middle
              prefix
              suffix
            }
          }
          ... on NumberField {
            cssClass
            label
            value
          }
          ... on PageField {
            cssClass
            pageNumber
          }
          ... on PhoneField {
            cssClass
            label
            value
          }
          ... on PhotoField {
            cssClass
            label
            photoValues {
              id
              databaseId
              uri
              featuredImage {
                node {
                  sourceUrl
                  mediaDetails {
                    sizes {
                      width
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
          ... on RadioField {
            cssClass
            label
            value
            valueText
          }
          ... on SectionField {
            cssClass
            label
          }
          ... on SelectField {
            cssClass
            value
            label
            value
            valueText
          }
          ... on SignatureField {
            cssClass
            label
            value
          }
          ... on TextAreaField {
            cssClass
            value
            label
          }
          ... on TextField {
            cssClass
            value
            label
          }
          ... on TimeField {
            cssClass
            label
            timeFormat
            timeValues {
              displayValue
            }
            value
          }
          ... on WebsiteField {
            cssClass
            label
            value
          }
        }
      }
    }
  }
`
export const Media_Item_FragFragmentDoc = gql`
  fragment MEDIA_ITEM_FRAG on MediaItem {
    id
    title
    description
    databaseId
    parentDatabaseId
    parentId
    mediaItemUrl
    fileSize
    sourceUrl
    altText
    date
    dateGmt
    mediaType
    mimeType
    mediaDetails {
      sizes {
        sourceUrl
        width
        height
        file
      }
    }
  }
`
export const Incident_Report_FragFragmentDoc = gql`
  fragment INCIDENT_REPORT_FRAG on IncidentReport {
    id
    databaseId
    title
    date
    modified
    modifiedGmt
    lastEditedBy {
      node {
        name
        databaseId
      }
    }
    initialReport {
      employeeJobTitle
      projectAddress
      description
    }
    additionalInformation {
      daysAwayWork
      daysJobTransfer
      hospitalName
      physicianName
      hospitalAddress
      employeeAddress
      employeeBirthDate
      employeeDeathDate
      employeeGender
      employeeHireDate
      employeeStartTime
      hazardDescription
      hasEmergencyTreatment
      hasHospitalOvernight
      hospitalAddress
      incidentDate
      incidentTime
      injuryCause
      injuryDescription
      priorActions
      witnesses {
        name
        phone
      }
    }
    author {
      node {
        databaseId
        name
      }
    }
    companies(first: 10) {
      edges {
        node {
          name
          slug
        }
      }
    }
    divisions(first: 10) {
      edges {
        node {
          name
          slug
        }
      }
    }
    injuryAreas(first: 40) {
      edges {
        node {
          name
          slug
        }
      }
    }
    incidentReportClassifications(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
    incidentTypes(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
    incidentReportWorkerConnection(first: 1) {
      edges {
        node {
          databaseId
          name
          email
          firstName
          lastName
          employeeId
          languages(first: 1) {
            edges {
              node {
                id
                name
                slug
              }
            }
          }
        }
      }
    }
    incidentReportProjectConnection(first: 1) {
      edges {
        node {
          databaseId
          title
          number
        }
      }
    }
    incidentReportStatuses(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
    fileAttachments(first: 6) {
      edges {
        node {
          ...MEDIA_ITEM_FRAG
        }
      }
    }
    rootCauses(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
  ${Media_Item_FragFragmentDoc}
`
export const Incident_Reports_FragFragmentDoc = gql`
  fragment INCIDENT_REPORTS_FRAG on IncidentReport {
    id
    databaseId
    title
    date
    modified
    lastEditedBy {
      node {
        name
        databaseId
      }
    }
    author {
      node {
        databaseId
        name
      }
    }
    fileAttachments(first: 6) {
      edges {
        node {
          databaseId
          id
        }
      }
    }
    incidentReportClassifications(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
    incidentTypes(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
    incidentReportStatuses(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
    incidentReportProjectConnection(first: 1) {
      edges {
        node {
          databaseId
          title
          number
        }
      }
    }
    incidentReportWorkerConnection(first: 1) {
      edges {
        node {
          databaseId
          name
        }
      }
    }
  }
`
export const Lesson_FragFragmentDoc = gql`
  fragment LESSON_FRAG on Lesson {
    id
    databaseId
    slug
    title
    excerpt
    lessonDetails {
      lessonDescription
    }
    trainingTypes {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    lessonCategories {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    providers {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    featuredImage {
      node {
        id
        title
        description
        mediaItemUrl
        sizes
        databaseId
        sourceUrl
      }
    }
    lessonDifficulties(first: 1) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    lessonDocumentConnectionConnection {
      edges {
        node {
          id
          databaseId
          title
        }
      }
    }
  }
`
export const Pdf_FragFragmentDoc = gql`
  fragment PDF_FRAG on MediaItem {
    title
    mediaItemUrl
    mimeType
    fileSize
    date
    databaseId
  }
`
export const Photo_FragFragmentDoc = gql`
  fragment PHOTO_FRAG on Photo {
    id
    databaseId
    date
    author {
      node {
        id
        databaseId
        name
      }
    }
    featuredImage {
      node {
        id
        databaseId
        sourceUrl
        mediaDetails {
          sizes {
            width
            height
            sourceUrl
          }
        }
        author {
          node {
            id
            databaseId
            name
          }
        }
      }
    }
    dateTaken
    geolocation {
      lat
      lng
    }
    correctiveActionIds
    correctiveActions {
      id
      databaseId
      title
    }
    entryIds {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
    entry {
      form {
        id
        databaseId
        title
        slug
      }
      ... on GfSubmittedEntry {
        databaseId
      }
      dateCreated
    }
    formIds {
      edges {
        node {
          slug
          name
        }
      }
    }
    fieldIds {
      edges {
        node {
          slug
          name
        }
      }
    }
    projectPhotoConnection(first: 1) {
      edges {
        node {
          id
          databaseId
          title
          number
        }
      }
    }
  }
`
export const Photos_FragFragmentDoc = gql`
  fragment PHOTOS_FRAG on Photo {
    title
    id
    databaseId
    formIds {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    featuredImage {
      node {
        id
        sourceUrl
        mediaDetails {
          sizes {
            width
            height
            sourceUrl
          }
        }
        author {
          node {
            id
            databaseId
            name
          }
        }
      }
    }
  }
`
export const Project_FragFragmentDoc = gql`
  fragment PROJECT_FRAG on Project {
    id
    databaseId
    title
    number
    address
    address2
    customer
    city
    state
    country
    zip
    startDate
    endDate
    hospitalAddress
    hospitalCity
    hospitalName
    hospitalState
    hospitalZip
    integrationSource
    authorDatabaseId
    author {
      node {
        databaseId
        name
      }
    }
    companies {
      edges {
        node {
          name
        }
      }
    }
    projectKeyPersonnelConnection(first: 5) {
      edges {
        node {
          name
          email
          employeeId
          databaseId
        }
      }
    }
    documentToProjectConnection(first: 30) {
      edges {
        node {
          title
          databaseId
        }
      }
    }
    divisions(first: 20) {
      edges {
        node {
          slug
          name
        }
      }
    }
    projectStatuses(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
    projectTypes(first: 1) {
      edges {
        node {
          name
          slug
        }
      }
    }
    projectCorrectiveActionConnection(
      first: $firstCorrectiveAction
      after: $afterCorrectiveAction
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        node {
          databaseId
          title
          correctiveActionStatuses(first: 1) {
            edges {
              node {
                slug
                name
              }
            }
          }
          actionTaken
          date
        }
      }
    }
    modified
  }
`
export const Projects_FragFragmentDoc = gql`
  fragment PROJECTS_FRAG on Project {
    databaseId
    title
    number
    customer
    projectKeyPersonnelConnection(first: 5) {
      edges {
        node {
          id
          databaseId
          name
          email
          employeeId
          databaseId
        }
      }
    }
    projectStatuses(first: 1) {
      edges {
        node {
          id
          databaseId
          name
          slug
        }
      }
    }
    projectTypes(first: 1) {
      edges {
        node {
          id
          databaseId
          name
          slug
        }
      }
    }
    companies(first: 5) {
      edges {
        node {
          id
          databaseId
          name
          slug
        }
      }
    }
    divisions(first: 5) {
      edges {
        node {
          id
          databaseId
          slug
          name
        }
      }
    }
  }
`
export const Toolbox_Talk_Basic_FragFragmentDoc = gql`
  fragment TOOLBOX_TALK_BASIC_FRAG on ToolboxTalk {
    id
    databaseId
    author {
      node {
        id
        databaseId
        name
      }
    }
    title
    content
    language {
      id
      name
      slug
      locale
    }
    toolboxTalkCategories(first: 10) {
      edges {
        node {
          id
          databaseId
          slug
          name
        }
      }
    }
    companies(first: 10) {
      edges {
        node {
          id
          databaseId
          name
        }
      }
    }
    divisions(first: 20) {
      edges {
        node {
          id
          databaseId
          name
        }
      }
    }
    featuredImage {
      node {
        id
        databaseId
        mediaItemUrl
      }
    }
  }
`
export const Toolbox_Talk_FragFragmentDoc = gql`
  fragment TOOLBOX_TALK_FRAG on ToolboxTalk {
    ...TOOLBOX_TALK_BASIC_FRAG
    translations {
      ...TOOLBOX_TALK_BASIC_FRAG
    }
  }
  ${Toolbox_Talk_Basic_FragFragmentDoc}
`
export const Toolbox_Talks_FragFragmentDoc = gql`
  fragment TOOLBOX_TALKS_FRAG on ToolboxTalk {
    databaseId
    id
    title
    translations {
      databaseId
    }
    toolboxTalkCategories(first: 10) {
      edges {
        node {
          slug
          name
        }
      }
    }
  }
`
export const Me_FragFragmentDoc = gql`
  fragment ME_FRAG on User {
    avatar {
      url
    }
    username
    id
    databaseId
    firstName
    lastName
    name
    email
    magicBellHmacEmail
    capabilities
    offlineMode
    offlineModeData
    languages(first: 2) {
      edges {
        node {
          name
          slug
        }
      }
    }
    companies(first: 10) {
      edges {
        node {
          name
          slug
        }
      }
    }
    divisions(first: 20) {
      edges {
        node {
          name
          slug
        }
      }
    }
    isSuperAdmin
    roles(first: 3) {
      edges {
        node {
          name
          displayName
        }
      }
    }
    correctiveActionAssignedWorkersConnection(first: 5) {
      edges {
        node {
          id
          databaseId
          title
          projectCorrectiveActionConnection {
            edges {
              node {
                number
                title
                databaseId
              }
            }
          }
          actionTaken
          date
          dueDate
          dateCompleted
          correctiveActionStatuses {
            edges {
              node {
                slug
                name
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    correctiveActionDeficiencyOwnersConnection(first: 5) {
      edges {
        node {
          id
          databaseId
          title
          projectCorrectiveActionConnection {
            edges {
              node {
                number
                title
                databaseId
              }
            }
          }
          actionTaken
          date
          dueDate
          dateCompleted
          correctiveActionStatuses {
            edges {
              node {
                slug
                name
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    projectKeyPersonnelConnection(first: 5) {
      edges {
        node {
          id
          databaseId
          title
          number
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    emergencyContactInfo {
      emergencyContactName
      emergencyContactNumber
      emergencyContactRelationship
    }
    usageCounts {
      sdsDownloadCount
    }
  }
`
export const User_FragFragmentDoc = gql`
  fragment USER_FRAG on User {
    id
    databaseId
    firstName
    lastName
    name
    email
    username
    phoneNumber
    employeeId
    integrationSource
    languages(first: 3) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    status {
      edges {
        node {
          id
          name
        }
      }
    }
    vaccineStatus {
      node {
        name
        slug
      }
    }
    roles(first: 1) {
      edges {
        node {
          name
          displayName
        }
      }
    }
    companies(first: 10) {
      edges {
        node {
          slug
          name
        }
      }
    }
    divisions(first: 20) {
      edges {
        node {
          slug
          name
        }
      }
    }
    projectKeyPersonnelConnection(first: $firstProject, after: $afterProject) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        node {
          databaseId
          title
          number
        }
      }
    }
    correctiveActionAssignedWorkersConnection(
      first: $firstAssigned
      after: $afterAssigned
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        node {
          databaseId
          title
          correctiveActionStatuses(first: 1) {
            edges {
              node {
                slug
                name
              }
            }
          }
        }
      }
    }
    correctiveActionDeficiencyOwnersConnection(
      first: $firstOwnwer
      after: $afterOwner
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        node {
          databaseId
          title
          correctiveActionStatuses(first: 1) {
            edges {
              node {
                slug
                name
              }
            }
          }
        }
      }
    }
    certificationUserConnection {
      edges {
        node {
          title
          id
          databaseId
          certificationDetails {
            completedDate
            expirationDate
          }
          trainingTypes {
            edges {
              node {
                databaseId
                name
                slug
              }
            }
          }
          providers(first: 1) {
            edges {
              node {
                slug
                name
                databaseId
              }
            }
          }
        }
      }
    }
    emergencyContactInfo {
      emergencyContactName
      emergencyContactNumber
      emergencyContactRelationship
    }
    usageCounts {
      sdsDownloadCount
    }
  }
`
export const Users_FragFragmentDoc = gql`
  fragment USERS_FRAG on User {
    id
    databaseId
    name
    email
    username
    employeeId
    avatar {
      url
    }
    languages(first: 3) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    status(first: 1) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    roles(first: 1) {
      edges {
        node {
          id
          name
          displayName
        }
      }
    }
    companies(first: 3) {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
    divisions(first: 10) {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
  }
`
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { username: $email, password: $password }) {
      authToken
      refreshToken
      user {
        ...ME_FRAG
      }
    }
  }
  ${Me_FragFragmentDoc}
`
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>
export const PasswordResetDocument = gql`
  mutation PasswordReset($login: String!, $password: String!, $key: String!) {
    resetUserPassword(
      input: { login: $login, password: $password, key: $key }
    ) {
      user {
        email
        username
      }
    }
  }
`
export type PasswordResetMutationFn = Apollo.MutationFunction<
  PasswordResetMutation,
  PasswordResetMutationVariables
>

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      login: // value for 'login'
 *      password: // value for 'password'
 *      key: // value for 'key'
 *   },
 * });
 */
export function usePasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordResetMutation,
    PasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PasswordResetMutation,
    PasswordResetMutationVariables
  >(PasswordResetDocument, options)
}
export type PasswordResetMutationHookResult = ReturnType<
  typeof usePasswordResetMutation
>
export type PasswordResetMutationResult =
  Apollo.MutationResult<PasswordResetMutation>
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetMutation,
  PasswordResetMutationVariables
>
export const PasswordResetRequestDocument = gql`
  mutation PasswordResetRequest($email: String!) {
    sendPasswordResetEmail(input: { username: $email }) {
      user {
        email
        username
      }
    }
  }
`
export type PasswordResetRequestMutationFn = Apollo.MutationFunction<
  PasswordResetRequestMutation,
  PasswordResetRequestMutationVariables
>

/**
 * __usePasswordResetRequestMutation__
 *
 * To run a mutation, you first call `usePasswordResetRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetRequestMutation, { data, loading, error }] = usePasswordResetRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordResetRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordResetRequestMutation,
    PasswordResetRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PasswordResetRequestMutation,
    PasswordResetRequestMutationVariables
  >(PasswordResetRequestDocument, options)
}
export type PasswordResetRequestMutationHookResult = ReturnType<
  typeof usePasswordResetRequestMutation
>
export type PasswordResetRequestMutationResult =
  Apollo.MutationResult<PasswordResetRequestMutation>
export type PasswordResetRequestMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetRequestMutation,
  PasswordResetRequestMutationVariables
>
export const RefreshAuthTokenDocument = gql`
  mutation RefreshAuthToken($refreshToken: String!) {
    refreshJwtAuthToken(input: { jwtRefreshToken: $refreshToken }) {
      authToken
    }
  }
`
export type RefreshAuthTokenMutationFn = Apollo.MutationFunction<
  RefreshAuthTokenMutation,
  RefreshAuthTokenMutationVariables
>

/**
 * __useRefreshAuthTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAuthTokenMutation, { data, loading, error }] = useRefreshAuthTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshAuthTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshAuthTokenMutation,
    RefreshAuthTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RefreshAuthTokenMutation,
    RefreshAuthTokenMutationVariables
  >(RefreshAuthTokenDocument, options)
}
export type RefreshAuthTokenMutationHookResult = ReturnType<
  typeof useRefreshAuthTokenMutation
>
export type RefreshAuthTokenMutationResult =
  Apollo.MutationResult<RefreshAuthTokenMutation>
export type RefreshAuthTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshAuthTokenMutation,
  RefreshAuthTokenMutationVariables
>
export const CreateCertificationDocument = gql`
  mutation CreateCertification($input: CreateCertificationInput!) {
    createCertification(input: $input) {
      certification {
        ...CERTIFICATION_FRAG
      }
    }
  }
  ${Certification_FragFragmentDoc}
`
export type CreateCertificationMutationFn = Apollo.MutationFunction<
  CreateCertificationMutation,
  CreateCertificationMutationVariables
>

/**
 * __useCreateCertificationMutation__
 *
 * To run a mutation, you first call `useCreateCertificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCertificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCertificationMutation, { data, loading, error }] = useCreateCertificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCertificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCertificationMutation,
    CreateCertificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCertificationMutation,
    CreateCertificationMutationVariables
  >(CreateCertificationDocument, options)
}
export type CreateCertificationMutationHookResult = ReturnType<
  typeof useCreateCertificationMutation
>
export type CreateCertificationMutationResult =
  Apollo.MutationResult<CreateCertificationMutation>
export type CreateCertificationMutationOptions = Apollo.BaseMutationOptions<
  CreateCertificationMutation,
  CreateCertificationMutationVariables
>
export const DeleteCertificaitonDocument = gql`
  mutation DeleteCertificaiton($input: DeleteCertificationInput!) {
    deleteCertification(input: $input) {
      deletedId
      certification {
        ...CERTIFICATION_FRAG
      }
    }
  }
  ${Certification_FragFragmentDoc}
`
export type DeleteCertificaitonMutationFn = Apollo.MutationFunction<
  DeleteCertificaitonMutation,
  DeleteCertificaitonMutationVariables
>

/**
 * __useDeleteCertificaitonMutation__
 *
 * To run a mutation, you first call `useDeleteCertificaitonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificaitonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificaitonMutation, { data, loading, error }] = useDeleteCertificaitonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCertificaitonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCertificaitonMutation,
    DeleteCertificaitonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCertificaitonMutation,
    DeleteCertificaitonMutationVariables
  >(DeleteCertificaitonDocument, options)
}
export type DeleteCertificaitonMutationHookResult = ReturnType<
  typeof useDeleteCertificaitonMutation
>
export type DeleteCertificaitonMutationResult =
  Apollo.MutationResult<DeleteCertificaitonMutation>
export type DeleteCertificaitonMutationOptions = Apollo.BaseMutationOptions<
  DeleteCertificaitonMutation,
  DeleteCertificaitonMutationVariables
>
export const CreateCertificationExpiryWarningDocument = gql`
  mutation createCertificationExpiryWarning(
    $input: CreateCertificationExpiryWarningInput!
  ) {
    createCertificationExpiryWarning(input: $input) {
      certificationExpiryWarningRule {
        databaseId
      }
    }
  }
`
export type CreateCertificationExpiryWarningMutationFn =
  Apollo.MutationFunction<
    CreateCertificationExpiryWarningMutation,
    CreateCertificationExpiryWarningMutationVariables
  >

/**
 * __useCreateCertificationExpiryWarningMutation__
 *
 * To run a mutation, you first call `useCreateCertificationExpiryWarningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCertificationExpiryWarningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCertificationExpiryWarningMutation, { data, loading, error }] = useCreateCertificationExpiryWarningMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCertificationExpiryWarningMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCertificationExpiryWarningMutation,
    CreateCertificationExpiryWarningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCertificationExpiryWarningMutation,
    CreateCertificationExpiryWarningMutationVariables
  >(CreateCertificationExpiryWarningDocument, options)
}
export type CreateCertificationExpiryWarningMutationHookResult = ReturnType<
  typeof useCreateCertificationExpiryWarningMutation
>
export type CreateCertificationExpiryWarningMutationResult =
  Apollo.MutationResult<CreateCertificationExpiryWarningMutation>
export type CreateCertificationExpiryWarningMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCertificationExpiryWarningMutation,
    CreateCertificationExpiryWarningMutationVariables
  >
export const DeleteCertificationExpiryWarningDocument = gql`
  mutation DeleteCertificationExpiryWarning(
    $input: DeleteCertificationExpiryWarningInput!
  ) {
    deleteCertificationExpiryWarning(input: $input) {
      success
    }
  }
`
export type DeleteCertificationExpiryWarningMutationFn =
  Apollo.MutationFunction<
    DeleteCertificationExpiryWarningMutation,
    DeleteCertificationExpiryWarningMutationVariables
  >

/**
 * __useDeleteCertificationExpiryWarningMutation__
 *
 * To run a mutation, you first call `useDeleteCertificationExpiryWarningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificationExpiryWarningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificationExpiryWarningMutation, { data, loading, error }] = useDeleteCertificationExpiryWarningMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCertificationExpiryWarningMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCertificationExpiryWarningMutation,
    DeleteCertificationExpiryWarningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCertificationExpiryWarningMutation,
    DeleteCertificationExpiryWarningMutationVariables
  >(DeleteCertificationExpiryWarningDocument, options)
}
export type DeleteCertificationExpiryWarningMutationHookResult = ReturnType<
  typeof useDeleteCertificationExpiryWarningMutation
>
export type DeleteCertificationExpiryWarningMutationResult =
  Apollo.MutationResult<DeleteCertificationExpiryWarningMutation>
export type DeleteCertificationExpiryWarningMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCertificationExpiryWarningMutation,
    DeleteCertificationExpiryWarningMutationVariables
  >
export const UpdateCertificationExpiryWarningDocument = gql`
  mutation UpdateCertificationExpiryWarning(
    $input: UpdateCertificationExpiryWarningInput!
  ) {
    updateCertificationExpiryWarning(input: $input) {
      certificationExpiryWarningRule {
        databaseId
      }
    }
  }
`
export type UpdateCertificationExpiryWarningMutationFn =
  Apollo.MutationFunction<
    UpdateCertificationExpiryWarningMutation,
    UpdateCertificationExpiryWarningMutationVariables
  >

/**
 * __useUpdateCertificationExpiryWarningMutation__
 *
 * To run a mutation, you first call `useUpdateCertificationExpiryWarningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCertificationExpiryWarningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCertificationExpiryWarningMutation, { data, loading, error }] = useUpdateCertificationExpiryWarningMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCertificationExpiryWarningMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCertificationExpiryWarningMutation,
    UpdateCertificationExpiryWarningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCertificationExpiryWarningMutation,
    UpdateCertificationExpiryWarningMutationVariables
  >(UpdateCertificationExpiryWarningDocument, options)
}
export type UpdateCertificationExpiryWarningMutationHookResult = ReturnType<
  typeof useUpdateCertificationExpiryWarningMutation
>
export type UpdateCertificationExpiryWarningMutationResult =
  Apollo.MutationResult<UpdateCertificationExpiryWarningMutation>
export type UpdateCertificationExpiryWarningMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCertificationExpiryWarningMutation,
    UpdateCertificationExpiryWarningMutationVariables
  >
export const UpdateCertificationDocument = gql`
  mutation UpdateCertification($input: UpdateCertificationInput!) {
    updateCertification(input: $input) {
      certification {
        ...CERTIFICATION_FRAG
      }
    }
  }
  ${Certification_FragFragmentDoc}
`
export type UpdateCertificationMutationFn = Apollo.MutationFunction<
  UpdateCertificationMutation,
  UpdateCertificationMutationVariables
>

/**
 * __useUpdateCertificationMutation__
 *
 * To run a mutation, you first call `useUpdateCertificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCertificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCertificationMutation, { data, loading, error }] = useUpdateCertificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCertificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCertificationMutation,
    UpdateCertificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCertificationMutation,
    UpdateCertificationMutationVariables
  >(UpdateCertificationDocument, options)
}
export type UpdateCertificationMutationHookResult = ReturnType<
  typeof useUpdateCertificationMutation
>
export type UpdateCertificationMutationResult =
  Apollo.MutationResult<UpdateCertificationMutation>
export type UpdateCertificationMutationOptions = Apollo.BaseMutationOptions<
  UpdateCertificationMutation,
  UpdateCertificationMutationVariables
>
export const AllCertificationsDocument = gql`
  query AllCertifications(
    $first: Int!
    $after: String
    $where: RootQueryToCertificationConnectionWhereArgs
  ) {
    certifications(first: $first, after: $after, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...CERTIFICATION_FRAG
        }
      }
    }
  }
  ${Certification_FragFragmentDoc}
`

/**
 * __useAllCertificationsQuery__
 *
 * To run a query within a React component, call `useAllCertificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCertificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCertificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllCertificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllCertificationsQuery,
    AllCertificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AllCertificationsQuery,
    AllCertificationsQueryVariables
  >(AllCertificationsDocument, options)
}
export function useAllCertificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCertificationsQuery,
    AllCertificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllCertificationsQuery,
    AllCertificationsQueryVariables
  >(AllCertificationsDocument, options)
}
export type AllCertificationsQueryHookResult = ReturnType<
  typeof useAllCertificationsQuery
>
export type AllCertificationsLazyQueryHookResult = ReturnType<
  typeof useAllCertificationsLazyQuery
>
export type AllCertificationsQueryResult = Apollo.QueryResult<
  AllCertificationsQuery,
  AllCertificationsQueryVariables
>
export const AllExpirationWarningsDocument = gql`
  query AllExpirationWarnings {
    certificationExpiryWarnings {
      databaseId
      dateModified
      dateCreated
      lastEditedBy {
        node {
          name
          email
          databaseId
        }
      }
      alertWindow
      companies(first: 50) {
        edges {
          node {
            termTaxonomyId
            slug
            name
          }
        }
      }
      externalContacts {
        name
        email
      }
      internalContacts(first: 20) {
        edges {
          node {
            databaseId
            name
            email
          }
        }
      }
    }
  }
`

/**
 * __useAllExpirationWarningsQuery__
 *
 * To run a query within a React component, call `useAllExpirationWarningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllExpirationWarningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllExpirationWarningsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllExpirationWarningsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllExpirationWarningsQuery,
    AllExpirationWarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AllExpirationWarningsQuery,
    AllExpirationWarningsQueryVariables
  >(AllExpirationWarningsDocument, options)
}
export function useAllExpirationWarningsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllExpirationWarningsQuery,
    AllExpirationWarningsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllExpirationWarningsQuery,
    AllExpirationWarningsQueryVariables
  >(AllExpirationWarningsDocument, options)
}
export type AllExpirationWarningsQueryHookResult = ReturnType<
  typeof useAllExpirationWarningsQuery
>
export type AllExpirationWarningsLazyQueryHookResult = ReturnType<
  typeof useAllExpirationWarningsLazyQuery
>
export type AllExpirationWarningsQueryResult = Apollo.QueryResult<
  AllExpirationWarningsQuery,
  AllExpirationWarningsQueryVariables
>
export const GetCertificationDocument = gql`
  query GetCertification($databaseId: ID!) {
    certification(id: $databaseId, idType: DATABASE_ID) {
      ...CERTIFICATION_FRAG
    }
  }
  ${Certification_FragFragmentDoc}
`

/**
 * __useGetCertificationQuery__
 *
 * To run a query within a React component, call `useGetCertificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificationQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useGetCertificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCertificationQuery,
    GetCertificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCertificationQuery, GetCertificationQueryVariables>(
    GetCertificationDocument,
    options
  )
}
export function useGetCertificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCertificationQuery,
    GetCertificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCertificationQuery,
    GetCertificationQueryVariables
  >(GetCertificationDocument, options)
}
export type GetCertificationQueryHookResult = ReturnType<
  typeof useGetCertificationQuery
>
export type GetCertificationLazyQueryHookResult = ReturnType<
  typeof useGetCertificationLazyQuery
>
export type GetCertificationQueryResult = Apollo.QueryResult<
  GetCertificationQuery,
  GetCertificationQueryVariables
>
export const CreateCorrectiveActionPdfsDocument = gql`
  mutation CreateCorrectiveActionPdfs($databaseIds: [ID]!) {
    createCorrectiveActionPdfs(input: { databaseIds: $databaseIds }) {
      pdfs {
        pdfUrl
        dateCreated
      }
    }
  }
`
export type CreateCorrectiveActionPdfsMutationFn = Apollo.MutationFunction<
  CreateCorrectiveActionPdfsMutation,
  CreateCorrectiveActionPdfsMutationVariables
>

/**
 * __useCreateCorrectiveActionPdfsMutation__
 *
 * To run a mutation, you first call `useCreateCorrectiveActionPdfsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCorrectiveActionPdfsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCorrectiveActionPdfsMutation, { data, loading, error }] = useCreateCorrectiveActionPdfsMutation({
 *   variables: {
 *      databaseIds: // value for 'databaseIds'
 *   },
 * });
 */
export function useCreateCorrectiveActionPdfsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCorrectiveActionPdfsMutation,
    CreateCorrectiveActionPdfsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCorrectiveActionPdfsMutation,
    CreateCorrectiveActionPdfsMutationVariables
  >(CreateCorrectiveActionPdfsDocument, options)
}
export type CreateCorrectiveActionPdfsMutationHookResult = ReturnType<
  typeof useCreateCorrectiveActionPdfsMutation
>
export type CreateCorrectiveActionPdfsMutationResult =
  Apollo.MutationResult<CreateCorrectiveActionPdfsMutation>
export type CreateCorrectiveActionPdfsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCorrectiveActionPdfsMutation,
    CreateCorrectiveActionPdfsMutationVariables
  >
export const DeleteCorrectiveActionDocument = gql`
  mutation DeleteCorrectiveAction($input: DeleteCorrectiveActionInput!) {
    deleteCorrectiveAction(input: $input) {
      deletedId
      correctiveAction {
        ...CORRECTIVE_ACTION_FRAG
      }
    }
  }
  ${Corrective_Action_FragFragmentDoc}
`
export type DeleteCorrectiveActionMutationFn = Apollo.MutationFunction<
  DeleteCorrectiveActionMutation,
  DeleteCorrectiveActionMutationVariables
>

/**
 * __useDeleteCorrectiveActionMutation__
 *
 * To run a mutation, you first call `useDeleteCorrectiveActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCorrectiveActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCorrectiveActionMutation, { data, loading, error }] = useDeleteCorrectiveActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCorrectiveActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCorrectiveActionMutation,
    DeleteCorrectiveActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCorrectiveActionMutation,
    DeleteCorrectiveActionMutationVariables
  >(DeleteCorrectiveActionDocument, options)
}
export type DeleteCorrectiveActionMutationHookResult = ReturnType<
  typeof useDeleteCorrectiveActionMutation
>
export type DeleteCorrectiveActionMutationResult =
  Apollo.MutationResult<DeleteCorrectiveActionMutation>
export type DeleteCorrectiveActionMutationOptions = Apollo.BaseMutationOptions<
  DeleteCorrectiveActionMutation,
  DeleteCorrectiveActionMutationVariables
>
export const UpdateCorrectiveActionDocument = gql`
  mutation UpdateCorrectiveAction($input: UpdateCorrectiveActionInput!) {
    updateCorrectiveAction(input: $input) {
      correctiveAction {
        ...CORRECTIVE_ACTION_FRAG
      }
    }
  }
  ${Corrective_Action_FragFragmentDoc}
`
export type UpdateCorrectiveActionMutationFn = Apollo.MutationFunction<
  UpdateCorrectiveActionMutation,
  UpdateCorrectiveActionMutationVariables
>

/**
 * __useUpdateCorrectiveActionMutation__
 *
 * To run a mutation, you first call `useUpdateCorrectiveActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCorrectiveActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCorrectiveActionMutation, { data, loading, error }] = useUpdateCorrectiveActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCorrectiveActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCorrectiveActionMutation,
    UpdateCorrectiveActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCorrectiveActionMutation,
    UpdateCorrectiveActionMutationVariables
  >(UpdateCorrectiveActionDocument, options)
}
export type UpdateCorrectiveActionMutationHookResult = ReturnType<
  typeof useUpdateCorrectiveActionMutation
>
export type UpdateCorrectiveActionMutationResult =
  Apollo.MutationResult<UpdateCorrectiveActionMutation>
export type UpdateCorrectiveActionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCorrectiveActionMutation,
  UpdateCorrectiveActionMutationVariables
>
export const AllCorrectiveActionsDocument = gql`
  query AllCorrectiveActions(
    $first: Int!
    $after: String
    $where: RootQueryToCorrectiveActionConnectionWhereArgs
  ) {
    correctiveActions(first: $first, after: $after, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...CORRECTIVE_ACTIONS_FRAG
        }
      }
    }
  }
  ${Corrective_Actions_FragFragmentDoc}
`

/**
 * __useAllCorrectiveActionsQuery__
 *
 * To run a query within a React component, call `useAllCorrectiveActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCorrectiveActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCorrectiveActionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllCorrectiveActionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllCorrectiveActionsQuery,
    AllCorrectiveActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AllCorrectiveActionsQuery,
    AllCorrectiveActionsQueryVariables
  >(AllCorrectiveActionsDocument, options)
}
export function useAllCorrectiveActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCorrectiveActionsQuery,
    AllCorrectiveActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllCorrectiveActionsQuery,
    AllCorrectiveActionsQueryVariables
  >(AllCorrectiveActionsDocument, options)
}
export type AllCorrectiveActionsQueryHookResult = ReturnType<
  typeof useAllCorrectiveActionsQuery
>
export type AllCorrectiveActionsLazyQueryHookResult = ReturnType<
  typeof useAllCorrectiveActionsLazyQuery
>
export type AllCorrectiveActionsQueryResult = Apollo.QueryResult<
  AllCorrectiveActionsQuery,
  AllCorrectiveActionsQueryVariables
>
export const GetCorrectiveActionDocument = gql`
  query GetCorrectiveAction($databaseId: ID!) {
    correctiveAction(id: $databaseId, idType: DATABASE_ID) {
      ...CORRECTIVE_ACTION_FRAG
    }
  }
  ${Corrective_Action_FragFragmentDoc}
`

/**
 * __useGetCorrectiveActionQuery__
 *
 * To run a query within a React component, call `useGetCorrectiveActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorrectiveActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorrectiveActionQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useGetCorrectiveActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCorrectiveActionQuery,
    GetCorrectiveActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCorrectiveActionQuery,
    GetCorrectiveActionQueryVariables
  >(GetCorrectiveActionDocument, options)
}
export function useGetCorrectiveActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCorrectiveActionQuery,
    GetCorrectiveActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCorrectiveActionQuery,
    GetCorrectiveActionQueryVariables
  >(GetCorrectiveActionDocument, options)
}
export type GetCorrectiveActionQueryHookResult = ReturnType<
  typeof useGetCorrectiveActionQuery
>
export type GetCorrectiveActionLazyQueryHookResult = ReturnType<
  typeof useGetCorrectiveActionLazyQuery
>
export type GetCorrectiveActionQueryResult = Apollo.QueryResult<
  GetCorrectiveActionQuery,
  GetCorrectiveActionQueryVariables
>
export const GetCorrectiveActionBasicDocument = gql`
  query GetCorrectiveActionBasic($databaseId: ID!) {
    correctiveAction(id: $databaseId, idType: DATABASE_ID) {
      ...CORRECTIVE_ACTIONS_FRAG
    }
  }
  ${Corrective_Actions_FragFragmentDoc}
`

/**
 * __useGetCorrectiveActionBasicQuery__
 *
 * To run a query within a React component, call `useGetCorrectiveActionBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorrectiveActionBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorrectiveActionBasicQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useGetCorrectiveActionBasicQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCorrectiveActionBasicQuery,
    GetCorrectiveActionBasicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCorrectiveActionBasicQuery,
    GetCorrectiveActionBasicQueryVariables
  >(GetCorrectiveActionBasicDocument, options)
}
export function useGetCorrectiveActionBasicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCorrectiveActionBasicQuery,
    GetCorrectiveActionBasicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCorrectiveActionBasicQuery,
    GetCorrectiveActionBasicQueryVariables
  >(GetCorrectiveActionBasicDocument, options)
}
export type GetCorrectiveActionBasicQueryHookResult = ReturnType<
  typeof useGetCorrectiveActionBasicQuery
>
export type GetCorrectiveActionBasicLazyQueryHookResult = ReturnType<
  typeof useGetCorrectiveActionBasicLazyQuery
>
export type GetCorrectiveActionBasicQueryResult = Apollo.QueryResult<
  GetCorrectiveActionBasicQuery,
  GetCorrectiveActionBasicQueryVariables
>
export const GetCorrectiveActionPdfFilesDocument = gql`
  query GetCorrectiveActionPdfFiles($correctiveActionDatabaseIds: [ID!]!) {
    mediaItems(
      where: {
        parentIn: $correctiveActionDatabaseIds
        mimeType: APPLICATION_PDF
      }
    ) {
      nodes {
        ...PDF_FRAG
      }
    }
  }
  ${Pdf_FragFragmentDoc}
`

/**
 * __useGetCorrectiveActionPdfFilesQuery__
 *
 * To run a query within a React component, call `useGetCorrectiveActionPdfFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorrectiveActionPdfFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorrectiveActionPdfFilesQuery({
 *   variables: {
 *      correctiveActionDatabaseIds: // value for 'correctiveActionDatabaseIds'
 *   },
 * });
 */
export function useGetCorrectiveActionPdfFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCorrectiveActionPdfFilesQuery,
    GetCorrectiveActionPdfFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCorrectiveActionPdfFilesQuery,
    GetCorrectiveActionPdfFilesQueryVariables
  >(GetCorrectiveActionPdfFilesDocument, options)
}
export function useGetCorrectiveActionPdfFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCorrectiveActionPdfFilesQuery,
    GetCorrectiveActionPdfFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCorrectiveActionPdfFilesQuery,
    GetCorrectiveActionPdfFilesQueryVariables
  >(GetCorrectiveActionPdfFilesDocument, options)
}
export type GetCorrectiveActionPdfFilesQueryHookResult = ReturnType<
  typeof useGetCorrectiveActionPdfFilesQuery
>
export type GetCorrectiveActionPdfFilesLazyQueryHookResult = ReturnType<
  typeof useGetCorrectiveActionPdfFilesLazyQuery
>
export type GetCorrectiveActionPdfFilesQueryResult = Apollo.QueryResult<
  GetCorrectiveActionPdfFilesQuery,
  GetCorrectiveActionPdfFilesQueryVariables
>
export const GetCorrectiveActionWithPdfDocument = gql`
  query GetCorrectiveActionWithPdf($databaseId: ID!) {
    correctiveAction(id: $databaseId, idType: DATABASE_ID) {
      ...CORRECTIVE_ACTION_FRAG
    }
    pdfFiles: mediaItems(
      where: { parent: $databaseId, mimeType: APPLICATION_PDF }
    ) {
      nodes {
        ...PDF_FRAG
      }
    }
  }
  ${Corrective_Action_FragFragmentDoc}
  ${Pdf_FragFragmentDoc}
`

/**
 * __useGetCorrectiveActionWithPdfQuery__
 *
 * To run a query within a React component, call `useGetCorrectiveActionWithPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorrectiveActionWithPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorrectiveActionWithPdfQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useGetCorrectiveActionWithPdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCorrectiveActionWithPdfQuery,
    GetCorrectiveActionWithPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCorrectiveActionWithPdfQuery,
    GetCorrectiveActionWithPdfQueryVariables
  >(GetCorrectiveActionWithPdfDocument, options)
}
export function useGetCorrectiveActionWithPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCorrectiveActionWithPdfQuery,
    GetCorrectiveActionWithPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCorrectiveActionWithPdfQuery,
    GetCorrectiveActionWithPdfQueryVariables
  >(GetCorrectiveActionWithPdfDocument, options)
}
export type GetCorrectiveActionWithPdfQueryHookResult = ReturnType<
  typeof useGetCorrectiveActionWithPdfQuery
>
export type GetCorrectiveActionWithPdfLazyQueryHookResult = ReturnType<
  typeof useGetCorrectiveActionWithPdfLazyQuery
>
export type GetCorrectiveActionWithPdfQueryResult = Apollo.QueryResult<
  GetCorrectiveActionWithPdfQuery,
  GetCorrectiveActionWithPdfQueryVariables
>
export const CorrectiveActionsForReportingDocument = gql`
  query CorrectiveActionsForReporting(
    $first: Int
    $after: String
    $where: RootQueryToCorrectiveActionConnectionWhereArgs
  ) {
    correctiveActions(first: $first, after: $after, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...CORRECTIVE_ACTIONS_REPORT_FRAG
        }
      }
    }
  }
  ${Corrective_Actions_Report_FragFragmentDoc}
`

/**
 * __useCorrectiveActionsForReportingQuery__
 *
 * To run a query within a React component, call `useCorrectiveActionsForReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorrectiveActionsForReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorrectiveActionsForReportingQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCorrectiveActionsForReportingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CorrectiveActionsForReportingQuery,
    CorrectiveActionsForReportingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CorrectiveActionsForReportingQuery,
    CorrectiveActionsForReportingQueryVariables
  >(CorrectiveActionsForReportingDocument, options)
}
export function useCorrectiveActionsForReportingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CorrectiveActionsForReportingQuery,
    CorrectiveActionsForReportingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CorrectiveActionsForReportingQuery,
    CorrectiveActionsForReportingQueryVariables
  >(CorrectiveActionsForReportingDocument, options)
}
export type CorrectiveActionsForReportingQueryHookResult = ReturnType<
  typeof useCorrectiveActionsForReportingQuery
>
export type CorrectiveActionsForReportingLazyQueryHookResult = ReturnType<
  typeof useCorrectiveActionsForReportingLazyQuery
>
export type CorrectiveActionsForReportingQueryResult = Apollo.QueryResult<
  CorrectiveActionsForReportingQuery,
  CorrectiveActionsForReportingQueryVariables
>
export const CreateDocumentDocument = gql`
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      document {
        ...DOCUMENT_FRAG
      }
    }
  }
  ${Document_FragFragmentDoc}
`
export type CreateDocumentMutationFn = Apollo.MutationFunction<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDocumentMutation,
    CreateDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDocumentMutation,
    CreateDocumentMutationVariables
  >(CreateDocumentDocument, options)
}
export type CreateDocumentMutationHookResult = ReturnType<
  typeof useCreateDocumentMutation
>
export type CreateDocumentMutationResult =
  Apollo.MutationResult<CreateDocumentMutation>
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>
export const DeleteDocumentDocument = gql`
  mutation DeleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      deletedId
      document {
        ...DOCUMENT_FRAG
      }
    }
  }
  ${Document_FragFragmentDoc}
`
export type DeleteDocumentMutationFn = Apollo.MutationFunction<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(DeleteDocumentDocument, options)
}
export type DeleteDocumentMutationHookResult = ReturnType<
  typeof useDeleteDocumentMutation
>
export type DeleteDocumentMutationResult =
  Apollo.MutationResult<DeleteDocumentMutation>
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>
export const UpdateDocumentDocument = gql`
  mutation UpdateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      document {
        ...DOCUMENT_FRAG
      }
    }
  }
  ${Document_FragFragmentDoc}
`
export type UpdateDocumentMutationFn = Apollo.MutationFunction<
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables
>

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDocumentMutation,
    UpdateDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDocumentMutation,
    UpdateDocumentMutationVariables
  >(UpdateDocumentDocument, options)
}
export type UpdateDocumentMutationHookResult = ReturnType<
  typeof useUpdateDocumentMutation
>
export type UpdateDocumentMutationResult =
  Apollo.MutationResult<UpdateDocumentMutation>
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables
>
export const AllDocumentsDocument = gql`
  query AllDocuments(
    $first: Int!
    $after: String
    $where: RootQueryToDocumentConnectionWhereArgs
  ) {
    documents(first: $first, after: $after, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...DOCUMENT_FRAG
        }
      }
    }
  }
  ${Document_FragFragmentDoc}
`

/**
 * __useAllDocumentsQuery__
 *
 * To run a query within a React component, call `useAllDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDocumentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllDocumentsQuery,
    AllDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllDocumentsQuery, AllDocumentsQueryVariables>(
    AllDocumentsDocument,
    options
  )
}
export function useAllDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllDocumentsQuery,
    AllDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllDocumentsQuery, AllDocumentsQueryVariables>(
    AllDocumentsDocument,
    options
  )
}
export type AllDocumentsQueryHookResult = ReturnType<
  typeof useAllDocumentsQuery
>
export type AllDocumentsLazyQueryHookResult = ReturnType<
  typeof useAllDocumentsLazyQuery
>
export type AllDocumentsQueryResult = Apollo.QueryResult<
  AllDocumentsQuery,
  AllDocumentsQueryVariables
>
export const GetDocumentDocument = gql`
  query GetDocument($databaseId: ID!) {
    document(id: $databaseId, idType: DATABASE_ID) {
      ...DOCUMENT_FRAG
    }
  }
  ${Document_FragFragmentDoc}
`

/**
 * __useGetDocumentQuery__
 *
 * To run a query within a React component, call `useGetDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useGetDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocumentQuery,
    GetDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDocumentQuery, GetDocumentQueryVariables>(
    GetDocumentDocument,
    options
  )
}
export function useGetDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentQuery,
    GetDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDocumentQuery, GetDocumentQueryVariables>(
    GetDocumentDocument,
    options
  )
}
export type GetDocumentQueryHookResult = ReturnType<typeof useGetDocumentQuery>
export type GetDocumentLazyQueryHookResult = ReturnType<
  typeof useGetDocumentLazyQuery
>
export type GetDocumentQueryResult = Apollo.QueryResult<
  GetDocumentQuery,
  GetDocumentQueryVariables
>
export const CreateEquipmentDocument = gql`
  mutation CreateEquipment($input: CreateEquipmentInput!) {
    createEquipment(input: $input) {
      equipment {
        ...EQUIPMENT_FRAG
      }
    }
  }
  ${Equipment_FragFragmentDoc}
`
export type CreateEquipmentMutationFn = Apollo.MutationFunction<
  CreateEquipmentMutation,
  CreateEquipmentMutationVariables
>

/**
 * __useCreateEquipmentMutation__
 *
 * To run a mutation, you first call `useCreateEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquipmentMutation, { data, loading, error }] = useCreateEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEquipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEquipmentMutation,
    CreateEquipmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateEquipmentMutation,
    CreateEquipmentMutationVariables
  >(CreateEquipmentDocument, options)
}
export type CreateEquipmentMutationHookResult = ReturnType<
  typeof useCreateEquipmentMutation
>
export type CreateEquipmentMutationResult =
  Apollo.MutationResult<CreateEquipmentMutation>
export type CreateEquipmentMutationOptions = Apollo.BaseMutationOptions<
  CreateEquipmentMutation,
  CreateEquipmentMutationVariables
>
export const DeleteEquipmentDocument = gql`
  mutation DeleteEquipment($input: DeleteEquipmentInput!) {
    deleteEquipment(input: $input) {
      deletedId
      equipment {
        ...EQUIPMENT_FRAG
      }
    }
  }
  ${Equipment_FragFragmentDoc}
`
export type DeleteEquipmentMutationFn = Apollo.MutationFunction<
  DeleteEquipmentMutation,
  DeleteEquipmentMutationVariables
>

/**
 * __useDeleteEquipmentMutation__
 *
 * To run a mutation, you first call `useDeleteEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEquipmentMutation, { data, loading, error }] = useDeleteEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEquipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEquipmentMutation,
    DeleteEquipmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteEquipmentMutation,
    DeleteEquipmentMutationVariables
  >(DeleteEquipmentDocument, options)
}
export type DeleteEquipmentMutationHookResult = ReturnType<
  typeof useDeleteEquipmentMutation
>
export type DeleteEquipmentMutationResult =
  Apollo.MutationResult<DeleteEquipmentMutation>
export type DeleteEquipmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteEquipmentMutation,
  DeleteEquipmentMutationVariables
>
export const UpdateEquipmentDocument = gql`
  mutation UpdateEquipment($input: UpdateEquipmentInput!) {
    updateEquipment(input: $input) {
      equipment {
        ...EQUIPMENT_FRAG
      }
    }
  }
  ${Equipment_FragFragmentDoc}
`
export type UpdateEquipmentMutationFn = Apollo.MutationFunction<
  UpdateEquipmentMutation,
  UpdateEquipmentMutationVariables
>

/**
 * __useUpdateEquipmentMutation__
 *
 * To run a mutation, you first call `useUpdateEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquipmentMutation, { data, loading, error }] = useUpdateEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEquipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEquipmentMutation,
    UpdateEquipmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateEquipmentMutation,
    UpdateEquipmentMutationVariables
  >(UpdateEquipmentDocument, options)
}
export type UpdateEquipmentMutationHookResult = ReturnType<
  typeof useUpdateEquipmentMutation
>
export type UpdateEquipmentMutationResult =
  Apollo.MutationResult<UpdateEquipmentMutation>
export type UpdateEquipmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateEquipmentMutation,
  UpdateEquipmentMutationVariables
>
export const AllEquipmentDocument = gql`
  query AllEquipment(
    $first: Int!
    $after: String
    $where: RootQueryToEquipmentConnectionWhereArgs
  ) {
    allEquipment(first: $first, after: $after, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...EQUIPMENT_FRAG
        }
      }
    }
  }
  ${Equipment_FragFragmentDoc}
`

/**
 * __useAllEquipmentQuery__
 *
 * To run a query within a React component, call `useAllEquipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEquipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEquipmentQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllEquipmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllEquipmentQuery,
    AllEquipmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllEquipmentQuery, AllEquipmentQueryVariables>(
    AllEquipmentDocument,
    options
  )
}
export function useAllEquipmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllEquipmentQuery,
    AllEquipmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllEquipmentQuery, AllEquipmentQueryVariables>(
    AllEquipmentDocument,
    options
  )
}
export type AllEquipmentQueryHookResult = ReturnType<
  typeof useAllEquipmentQuery
>
export type AllEquipmentLazyQueryHookResult = ReturnType<
  typeof useAllEquipmentLazyQuery
>
export type AllEquipmentQueryResult = Apollo.QueryResult<
  AllEquipmentQuery,
  AllEquipmentQueryVariables
>
export const GetEquipmentDocument = gql`
  query GetEquipment($databaseId: ID!) {
    equipment(id: $databaseId, idType: DATABASE_ID) {
      ...EQUIPMENT_FRAG
    }
  }
  ${Equipment_FragFragmentDoc}
`

/**
 * __useGetEquipmentQuery__
 *
 * To run a query within a React component, call `useGetEquipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useGetEquipmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEquipmentQuery,
    GetEquipmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEquipmentQuery, GetEquipmentQueryVariables>(
    GetEquipmentDocument,
    options
  )
}
export function useGetEquipmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEquipmentQuery,
    GetEquipmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEquipmentQuery, GetEquipmentQueryVariables>(
    GetEquipmentDocument,
    options
  )
}
export type GetEquipmentQueryHookResult = ReturnType<
  typeof useGetEquipmentQuery
>
export type GetEquipmentLazyQueryHookResult = ReturnType<
  typeof useGetEquipmentLazyQuery
>
export type GetEquipmentQueryResult = Apollo.QueryResult<
  GetEquipmentQuery,
  GetEquipmentQueryVariables
>
export const GfDeleteEntryDocument = gql`
  mutation GfDeleteEntry($input: DeleteGfEntryInput!) {
    deleteGfEntry(input: $input) {
      deletedId
      entry {
        ... on GfSubmittedEntry {
          databaseId
        }
        formId
        formDatabaseId
      }
    }
  }
`
export type GfDeleteEntryMutationFn = Apollo.MutationFunction<
  GfDeleteEntryMutation,
  GfDeleteEntryMutationVariables
>

/**
 * __useGfDeleteEntryMutation__
 *
 * To run a mutation, you first call `useGfDeleteEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGfDeleteEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gfDeleteEntryMutation, { data, loading, error }] = useGfDeleteEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGfDeleteEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GfDeleteEntryMutation,
    GfDeleteEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GfDeleteEntryMutation,
    GfDeleteEntryMutationVariables
  >(GfDeleteEntryDocument, options)
}
export type GfDeleteEntryMutationHookResult = ReturnType<
  typeof useGfDeleteEntryMutation
>
export type GfDeleteEntryMutationResult =
  Apollo.MutationResult<GfDeleteEntryMutation>
export type GfDeleteEntryMutationOptions = Apollo.BaseMutationOptions<
  GfDeleteEntryMutation,
  GfDeleteEntryMutationVariables
>
export const GfSubmitFormDocument = gql`
  mutation GfSubmitForm($input: SubmitGfFormInput!) {
    submitGfForm(input: $input) {
      errors {
        id
        message
      }
      resumeUrl
      entry {
        ...ENTRY_FRAG
      }
    }
  }
  ${Entry_FragFragmentDoc}
`
export type GfSubmitFormMutationFn = Apollo.MutationFunction<
  GfSubmitFormMutation,
  GfSubmitFormMutationVariables
>

/**
 * __useGfSubmitFormMutation__
 *
 * To run a mutation, you first call `useGfSubmitFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGfSubmitFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gfSubmitFormMutation, { data, loading, error }] = useGfSubmitFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGfSubmitFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GfSubmitFormMutation,
    GfSubmitFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GfSubmitFormMutation,
    GfSubmitFormMutationVariables
  >(GfSubmitFormDocument, options)
}
export type GfSubmitFormMutationHookResult = ReturnType<
  typeof useGfSubmitFormMutation
>
export type GfSubmitFormMutationResult =
  Apollo.MutationResult<GfSubmitFormMutation>
export type GfSubmitFormMutationOptions = Apollo.BaseMutationOptions<
  GfSubmitFormMutation,
  GfSubmitFormMutationVariables
>
export const GfUpdateEntryDocument = gql`
  mutation GfUpdateEntry($input: UpdateGfEntryInput!) {
    updateGfEntry(input: $input) {
      errors {
        id
        message
      }
      entry {
        ...ENTRY_FRAG
      }
    }
  }
  ${Entry_FragFragmentDoc}
`
export type GfUpdateEntryMutationFn = Apollo.MutationFunction<
  GfUpdateEntryMutation,
  GfUpdateEntryMutationVariables
>

/**
 * __useGfUpdateEntryMutation__
 *
 * To run a mutation, you first call `useGfUpdateEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGfUpdateEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gfUpdateEntryMutation, { data, loading, error }] = useGfUpdateEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGfUpdateEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GfUpdateEntryMutation,
    GfUpdateEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GfUpdateEntryMutation,
    GfUpdateEntryMutationVariables
  >(GfUpdateEntryDocument, options)
}
export type GfUpdateEntryMutationHookResult = ReturnType<
  typeof useGfUpdateEntryMutation
>
export type GfUpdateEntryMutationResult =
  Apollo.MutationResult<GfUpdateEntryMutation>
export type GfUpdateEntryMutationOptions = Apollo.BaseMutationOptions<
  GfUpdateEntryMutation,
  GfUpdateEntryMutationVariables
>
export const GetComplianceReportingDocument = gql`
  query GetComplianceReporting(
    $endDate: String!
    $startDate: String!
    $formId: ID!
    $role: UserRoleEnum!
    $first: Int
    $after: String
    $companyIds: [String]
    $divisionIds: [String]
    $projectIds: [String]
    $orderBy: UserComplianceOrderByEnum
    $order: OrderEnum
  ) {
    complianceDetails(
      endDate: $endDate
      startDate: $startDate
      formId: $formId
      role: $role
      companyNames: $companyIds
      divisionNames: $divisionIds
      projectIds: $projectIds
    ) {
      actualCount
      expectedCount
      userDetails(
        where: { order: $order, orderby: $orderBy }
        first: $first
        after: $after
      ) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            actualCount
            expectedCount
            datesSubmitted
            user {
              node {
                id
                name
                email
                username
                databaseId
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetComplianceReportingQuery__
 *
 * To run a query within a React component, call `useGetComplianceReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceReportingQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      formId: // value for 'formId'
 *      role: // value for 'role'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      companyIds: // value for 'companyIds'
 *      divisionIds: // value for 'divisionIds'
 *      projectIds: // value for 'projectIds'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetComplianceReportingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetComplianceReportingQuery,
    GetComplianceReportingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetComplianceReportingQuery,
    GetComplianceReportingQueryVariables
  >(GetComplianceReportingDocument, options)
}
export function useGetComplianceReportingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplianceReportingQuery,
    GetComplianceReportingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetComplianceReportingQuery,
    GetComplianceReportingQueryVariables
  >(GetComplianceReportingDocument, options)
}
export type GetComplianceReportingQueryHookResult = ReturnType<
  typeof useGetComplianceReportingQuery
>
export type GetComplianceReportingLazyQueryHookResult = ReturnType<
  typeof useGetComplianceReportingLazyQuery
>
export type GetComplianceReportingQueryResult = Apollo.QueryResult<
  GetComplianceReportingQuery,
  GetComplianceReportingQueryVariables
>
export const GetFormDocument = gql`
  query GetForm($databaseId: ID!) {
    gfForm(id: $databaseId, idType: DATABASE_ID) {
      formFields(first: 500) {
        nodes {
          id
          type
          inputType
          pageNumber
          visibility
          displayOnly
          ... on AddressField {
            ...AddressFieldFrag
          }
          ... on CheckboxField {
            ...CheckboxFieldFrag
          }
          ... on ComboSignatureField {
            ...ComboSignatureFieldFrag
          }
          ... on DateField {
            ...DateFieldFrag
          }
          ... on EmailField {
            ...EmailFieldFrag
          }
          ... on FileUploadField {
            ...FileUploadFieldFrag
          }
          ... on FollowUpField {
            ...FollowUpFieldFrag
          }
          ... on HiddenField {
            ...HiddenFieldFrag
          }
          ... on HtmlField {
            ...HtmlFieldFrag
          }
          ... on ListField {
            ...ListFieldFrag
          }
          ... on MultiSelectField {
            ...MultiSelectFieldFrag
          }
          ... on NameField {
            ...NameFieldFrag
          }
          ... on NumberField {
            ...NumberFieldFrag
          }
          ... on PageField {
            ...PageFieldFrag
          }
          ... on PhoneField {
            ...PhoneFieldFrag
          }
          ... on PhotoField {
            ...PhotoFieldFrag
          }
          ... on QuizField {
            ...QuizFieldFrag
          }
          ... on RadioField {
            ...RadioFieldFrag
          }
          ... on SectionField {
            ...SectionFieldFrag
          }
          ... on SelectField {
            ...SelectFieldFrag
          }
          ... on SignatureField {
            ...SignatureFieldFrag
          }
          ... on TextAreaField {
            ...TextAreaFieldFrag
          }
          ... on TextField {
            ...TextFieldFrag
          }
          ... on TimeField {
            ...TimeFieldFrag
          }
          ... on WebsiteField {
            ...WebsiteFieldFrag
          }
        }
      }
    }
  }
  ${AddressFieldFragFragmentDoc}
  ${CheckboxFieldFragFragmentDoc}
  ${ComboSignatureFieldFragFragmentDoc}
  ${DateFieldFragFragmentDoc}
  ${EmailFieldFragFragmentDoc}
  ${FileUploadFieldFragFragmentDoc}
  ${FollowUpFieldFragFragmentDoc}
  ${HiddenFieldFragFragmentDoc}
  ${HtmlFieldFragFragmentDoc}
  ${ListFieldFragFragmentDoc}
  ${MultiSelectFieldFragFragmentDoc}
  ${NameFieldFragFragmentDoc}
  ${NumberFieldFragFragmentDoc}
  ${PageFieldFragFragmentDoc}
  ${PhoneFieldFragFragmentDoc}
  ${PhotoFieldFragFragmentDoc}
  ${QuizFieldFragFragmentDoc}
  ${RadioFieldFragFragmentDoc}
  ${SectionFieldFragFragmentDoc}
  ${SelectFieldFragFragmentDoc}
  ${SignatureFieldFragFragmentDoc}
  ${TextAreaFieldFragFragmentDoc}
  ${TextFieldFragFragmentDoc}
  ${TimeFieldFragFragmentDoc}
  ${WebsiteFieldFragFragmentDoc}
`

/**
 * __useGetFormQuery__
 *
 * To run a query within a React component, call `useGetFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useGetFormQuery(
  baseOptions: Apollo.QueryHookOptions<GetFormQuery, GetFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFormQuery, GetFormQueryVariables>(
    GetFormDocument,
    options
  )
}
export function useGetFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFormQuery, GetFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFormQuery, GetFormQueryVariables>(
    GetFormDocument,
    options
  )
}
export type GetFormQueryHookResult = ReturnType<typeof useGetFormQuery>
export type GetFormLazyQueryHookResult = ReturnType<typeof useGetFormLazyQuery>
export type GetFormQueryResult = Apollo.QueryResult<
  GetFormQuery,
  GetFormQueryVariables
>
export const GfAllSubmittedEntriesDocument = gql`
  query GfAllSubmittedEntries(
    $first: Int
    $after: String
    $where: RootQueryToGfSubmittedEntryConnectionWhereArgs
  ) {
    gfSubmittedEntries(first: $first, after: $after, where: $where) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        node {
          ...ENTRIES_FRAG
        }
      }
    }
  }
  ${Entries_FragFragmentDoc}
`

/**
 * __useGfAllSubmittedEntriesQuery__
 *
 * To run a query within a React component, call `useGfAllSubmittedEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGfAllSubmittedEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGfAllSubmittedEntriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGfAllSubmittedEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GfAllSubmittedEntriesQuery,
    GfAllSubmittedEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GfAllSubmittedEntriesQuery,
    GfAllSubmittedEntriesQueryVariables
  >(GfAllSubmittedEntriesDocument, options)
}
export function useGfAllSubmittedEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GfAllSubmittedEntriesQuery,
    GfAllSubmittedEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GfAllSubmittedEntriesQuery,
    GfAllSubmittedEntriesQueryVariables
  >(GfAllSubmittedEntriesDocument, options)
}
export type GfAllSubmittedEntriesQueryHookResult = ReturnType<
  typeof useGfAllSubmittedEntriesQuery
>
export type GfAllSubmittedEntriesLazyQueryHookResult = ReturnType<
  typeof useGfAllSubmittedEntriesLazyQuery
>
export type GfAllSubmittedEntriesQueryResult = Apollo.QueryResult<
  GfAllSubmittedEntriesQuery,
  GfAllSubmittedEntriesQueryVariables
>
export const GfGetSubmissionRulesDocument = gql`
  query GfGetSubmissionRules($formId: ID!) {
    gfForm(id: $formId, idType: DATABASE_ID) {
      submissionRules {
        entryCount
        frequency
        role
      }
    }
  }
`

/**
 * __useGfGetSubmissionRulesQuery__
 *
 * To run a query within a React component, call `useGfGetSubmissionRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGfGetSubmissionRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGfGetSubmissionRulesQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useGfGetSubmissionRulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GfGetSubmissionRulesQuery,
    GfGetSubmissionRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GfGetSubmissionRulesQuery,
    GfGetSubmissionRulesQueryVariables
  >(GfGetSubmissionRulesDocument, options)
}
export function useGfGetSubmissionRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GfGetSubmissionRulesQuery,
    GfGetSubmissionRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GfGetSubmissionRulesQuery,
    GfGetSubmissionRulesQueryVariables
  >(GfGetSubmissionRulesDocument, options)
}
export type GfGetSubmissionRulesQueryHookResult = ReturnType<
  typeof useGfGetSubmissionRulesQuery
>
export type GfGetSubmissionRulesLazyQueryHookResult = ReturnType<
  typeof useGfGetSubmissionRulesLazyQuery
>
export type GfGetSubmissionRulesQueryResult = Apollo.QueryResult<
  GfGetSubmissionRulesQuery,
  GfGetSubmissionRulesQueryVariables
>
export const GfGetSubmittedEntryDocument = gql`
  query GfGetSubmittedEntry($databaseId: ID!) {
    gfSubmittedEntry(id: $databaseId, idType: DATABASE_ID) {
      ...ENTRY_FRAG
    }
  }
  ${Entry_FragFragmentDoc}
`

/**
 * __useGfGetSubmittedEntryQuery__
 *
 * To run a query within a React component, call `useGfGetSubmittedEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGfGetSubmittedEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGfGetSubmittedEntryQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useGfGetSubmittedEntryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GfGetSubmittedEntryQuery,
    GfGetSubmittedEntryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GfGetSubmittedEntryQuery,
    GfGetSubmittedEntryQueryVariables
  >(GfGetSubmittedEntryDocument, options)
}
export function useGfGetSubmittedEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GfGetSubmittedEntryQuery,
    GfGetSubmittedEntryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GfGetSubmittedEntryQuery,
    GfGetSubmittedEntryQueryVariables
  >(GfGetSubmittedEntryDocument, options)
}
export type GfGetSubmittedEntryQueryHookResult = ReturnType<
  typeof useGfGetSubmittedEntryQuery
>
export type GfGetSubmittedEntryLazyQueryHookResult = ReturnType<
  typeof useGfGetSubmittedEntryLazyQuery
>
export type GfGetSubmittedEntryQueryResult = Apollo.QueryResult<
  GfGetSubmittedEntryQuery,
  GfGetSubmittedEntryQueryVariables
>
export const CreateIncidentReportDocument = gql`
  mutation CreateIncidentReport($input: CreateIncidentReportInput!) {
    createIncidentReport(input: $input) {
      incidentReport {
        ...INCIDENT_REPORT_FRAG
      }
    }
  }
  ${Incident_Report_FragFragmentDoc}
`
export type CreateIncidentReportMutationFn = Apollo.MutationFunction<
  CreateIncidentReportMutation,
  CreateIncidentReportMutationVariables
>

/**
 * __useCreateIncidentReportMutation__
 *
 * To run a mutation, you first call `useCreateIncidentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentReportMutation, { data, loading, error }] = useCreateIncidentReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIncidentReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIncidentReportMutation,
    CreateIncidentReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateIncidentReportMutation,
    CreateIncidentReportMutationVariables
  >(CreateIncidentReportDocument, options)
}
export type CreateIncidentReportMutationHookResult = ReturnType<
  typeof useCreateIncidentReportMutation
>
export type CreateIncidentReportMutationResult =
  Apollo.MutationResult<CreateIncidentReportMutation>
export type CreateIncidentReportMutationOptions = Apollo.BaseMutationOptions<
  CreateIncidentReportMutation,
  CreateIncidentReportMutationVariables
>
export const DeleteIncidentReportDocument = gql`
  mutation DeleteIncidentReport($input: DeleteIncidentReportInput!) {
    deleteIncidentReport(input: $input) {
      deletedId
      incidentReport {
        ...INCIDENT_REPORT_FRAG
      }
    }
  }
  ${Incident_Report_FragFragmentDoc}
`
export type DeleteIncidentReportMutationFn = Apollo.MutationFunction<
  DeleteIncidentReportMutation,
  DeleteIncidentReportMutationVariables
>

/**
 * __useDeleteIncidentReportMutation__
 *
 * To run a mutation, you first call `useDeleteIncidentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIncidentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncidentReportMutation, { data, loading, error }] = useDeleteIncidentReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteIncidentReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIncidentReportMutation,
    DeleteIncidentReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteIncidentReportMutation,
    DeleteIncidentReportMutationVariables
  >(DeleteIncidentReportDocument, options)
}
export type DeleteIncidentReportMutationHookResult = ReturnType<
  typeof useDeleteIncidentReportMutation
>
export type DeleteIncidentReportMutationResult =
  Apollo.MutationResult<DeleteIncidentReportMutation>
export type DeleteIncidentReportMutationOptions = Apollo.BaseMutationOptions<
  DeleteIncidentReportMutation,
  DeleteIncidentReportMutationVariables
>
export const UpdateIncidentReportDocument = gql`
  mutation UpdateIncidentReport($input: UpdateIncidentReportInput!) {
    updateIncidentReport(input: $input) {
      incidentReport {
        ...INCIDENT_REPORT_FRAG
      }
    }
  }
  ${Incident_Report_FragFragmentDoc}
`
export type UpdateIncidentReportMutationFn = Apollo.MutationFunction<
  UpdateIncidentReportMutation,
  UpdateIncidentReportMutationVariables
>

/**
 * __useUpdateIncidentReportMutation__
 *
 * To run a mutation, you first call `useUpdateIncidentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncidentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncidentReportMutation, { data, loading, error }] = useUpdateIncidentReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIncidentReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIncidentReportMutation,
    UpdateIncidentReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateIncidentReportMutation,
    UpdateIncidentReportMutationVariables
  >(UpdateIncidentReportDocument, options)
}
export type UpdateIncidentReportMutationHookResult = ReturnType<
  typeof useUpdateIncidentReportMutation
>
export type UpdateIncidentReportMutationResult =
  Apollo.MutationResult<UpdateIncidentReportMutation>
export type UpdateIncidentReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateIncidentReportMutation,
  UpdateIncidentReportMutationVariables
>
export const AllIncidentReportsDocument = gql`
  query AllIncidentReports(
    $first: Int!
    $after: String
    $where: RootQueryToIncidentReportConnectionWhereArgs
  ) {
    incidentReports(first: $first, after: $after, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...INCIDENT_REPORT_FRAG
        }
      }
    }
  }
  ${Incident_Report_FragFragmentDoc}
`

/**
 * __useAllIncidentReportsQuery__
 *
 * To run a query within a React component, call `useAllIncidentReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIncidentReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIncidentReportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllIncidentReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllIncidentReportsQuery,
    AllIncidentReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AllIncidentReportsQuery,
    AllIncidentReportsQueryVariables
  >(AllIncidentReportsDocument, options)
}
export function useAllIncidentReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllIncidentReportsQuery,
    AllIncidentReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllIncidentReportsQuery,
    AllIncidentReportsQueryVariables
  >(AllIncidentReportsDocument, options)
}
export type AllIncidentReportsQueryHookResult = ReturnType<
  typeof useAllIncidentReportsQuery
>
export type AllIncidentReportsLazyQueryHookResult = ReturnType<
  typeof useAllIncidentReportsLazyQuery
>
export type AllIncidentReportsQueryResult = Apollo.QueryResult<
  AllIncidentReportsQuery,
  AllIncidentReportsQueryVariables
>
export const GetIncidentReportDocument = gql`
  query GetIncidentReport($id: ID!, $idType: IncidentReportIdType) {
    incidentReport(id: $id, idType: $idType) {
      ...INCIDENT_REPORT_FRAG
    }
  }
  ${Incident_Report_FragFragmentDoc}
`

/**
 * __useGetIncidentReportQuery__
 *
 * To run a query within a React component, call `useGetIncidentReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *      idType: // value for 'idType'
 *   },
 * });
 */
export function useGetIncidentReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIncidentReportQuery,
    GetIncidentReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetIncidentReportQuery,
    GetIncidentReportQueryVariables
  >(GetIncidentReportDocument, options)
}
export function useGetIncidentReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIncidentReportQuery,
    GetIncidentReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetIncidentReportQuery,
    GetIncidentReportQueryVariables
  >(GetIncidentReportDocument, options)
}
export type GetIncidentReportQueryHookResult = ReturnType<
  typeof useGetIncidentReportQuery
>
export type GetIncidentReportLazyQueryHookResult = ReturnType<
  typeof useGetIncidentReportLazyQuery
>
export type GetIncidentReportQueryResult = Apollo.QueryResult<
  GetIncidentReportQuery,
  GetIncidentReportQueryVariables
>
export const GetOsha300ReportDocument = gql`
  query GetOsha300Report($start: String, $end: String) {
    incidentReportData(startDate: $start, endDate: $end) {
      casesWithDeaths
      casesWithDaysAwayFromWork
      casesWithJobRestrictions
      otherRecordableCases
      daysAwayFromWork
      daysWithRestrictions
      injuries
      skinDisorders
      respiratoryConditions
      poisonings
      hearingLoss
      otherIllnesses
    }
  }
`

/**
 * __useGetOsha300ReportQuery__
 *
 * To run a query within a React component, call `useGetOsha300ReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOsha300ReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOsha300ReportQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetOsha300ReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOsha300ReportQuery,
    GetOsha300ReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOsha300ReportQuery, GetOsha300ReportQueryVariables>(
    GetOsha300ReportDocument,
    options
  )
}
export function useGetOsha300ReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOsha300ReportQuery,
    GetOsha300ReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOsha300ReportQuery,
    GetOsha300ReportQueryVariables
  >(GetOsha300ReportDocument, options)
}
export type GetOsha300ReportQueryHookResult = ReturnType<
  typeof useGetOsha300ReportQuery
>
export type GetOsha300ReportLazyQueryHookResult = ReturnType<
  typeof useGetOsha300ReportLazyQuery
>
export type GetOsha300ReportQueryResult = Apollo.QueryResult<
  GetOsha300ReportQuery,
  GetOsha300ReportQueryVariables
>
export const IntegrationConfigDocument = gql`
  query IntegrationConfig {
    integrationConfig {
      isSyncDisabled
      isSyncInProgress
      lastSyncDate
      source
      activeIntegrations {
        sourcedFields
        destinationType
        sourceType
      }
    }
  }
`

/**
 * __useIntegrationConfigQuery__
 *
 * To run a query within a React component, call `useIntegrationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IntegrationConfigQuery,
    IntegrationConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IntegrationConfigQuery,
    IntegrationConfigQueryVariables
  >(IntegrationConfigDocument, options)
}
export function useIntegrationConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationConfigQuery,
    IntegrationConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IntegrationConfigQuery,
    IntegrationConfigQueryVariables
  >(IntegrationConfigDocument, options)
}
export type IntegrationConfigQueryHookResult = ReturnType<
  typeof useIntegrationConfigQuery
>
export type IntegrationConfigLazyQueryHookResult = ReturnType<
  typeof useIntegrationConfigLazyQuery
>
export type IntegrationConfigQueryResult = Apollo.QueryResult<
  IntegrationConfigQuery,
  IntegrationConfigQueryVariables
>
export const ToggleIntegrationDocument = gql`
  mutation ToggleIntegration {
    resyncIntegrationData(input: {}) {
      success
    }
  }
`
export type ToggleIntegrationMutationFn = Apollo.MutationFunction<
  ToggleIntegrationMutation,
  ToggleIntegrationMutationVariables
>

/**
 * __useToggleIntegrationMutation__
 *
 * To run a mutation, you first call `useToggleIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIntegrationMutation, { data, loading, error }] = useToggleIntegrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useToggleIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleIntegrationMutation,
    ToggleIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleIntegrationMutation,
    ToggleIntegrationMutationVariables
  >(ToggleIntegrationDocument, options)
}
export type ToggleIntegrationMutationHookResult = ReturnType<
  typeof useToggleIntegrationMutation
>
export type ToggleIntegrationMutationResult =
  Apollo.MutationResult<ToggleIntegrationMutation>
export type ToggleIntegrationMutationOptions = Apollo.BaseMutationOptions<
  ToggleIntegrationMutation,
  ToggleIntegrationMutationVariables
>
export const AllLessonsDocument = gql`
  query AllLessons(
    $first: Int!
    $after: String
    $where: RootQueryToLessonConnectionWhereArgs
  ) {
    lessons(first: $first, after: $after, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...LESSON_FRAG
        }
      }
    }
  }
  ${Lesson_FragFragmentDoc}
`

/**
 * __useAllLessonsQuery__
 *
 * To run a query within a React component, call `useAllLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLessonsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllLessonsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllLessonsQuery,
    AllLessonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllLessonsQuery, AllLessonsQueryVariables>(
    AllLessonsDocument,
    options
  )
}
export function useAllLessonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllLessonsQuery,
    AllLessonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllLessonsQuery, AllLessonsQueryVariables>(
    AllLessonsDocument,
    options
  )
}
export type AllLessonsQueryHookResult = ReturnType<typeof useAllLessonsQuery>
export type AllLessonsLazyQueryHookResult = ReturnType<
  typeof useAllLessonsLazyQuery
>
export type AllLessonsQueryResult = Apollo.QueryResult<
  AllLessonsQuery,
  AllLessonsQueryVariables
>
export const DeleteMediaItemDocument = gql`
  mutation DeleteMediaItem($input: DeleteMediaItemInput!) {
    deleteMediaItem(input: $input) {
      deletedId
      mediaItem {
        ...MEDIA_ITEM_FRAG
      }
    }
  }
  ${Media_Item_FragFragmentDoc}
`
export type DeleteMediaItemMutationFn = Apollo.MutationFunction<
  DeleteMediaItemMutation,
  DeleteMediaItemMutationVariables
>

/**
 * __useDeleteMediaItemMutation__
 *
 * To run a mutation, you first call `useDeleteMediaItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediaItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediaItemMutation, { data, loading, error }] = useDeleteMediaItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMediaItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMediaItemMutation,
    DeleteMediaItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteMediaItemMutation,
    DeleteMediaItemMutationVariables
  >(DeleteMediaItemDocument, options)
}
export type DeleteMediaItemMutationHookResult = ReturnType<
  typeof useDeleteMediaItemMutation
>
export type DeleteMediaItemMutationResult =
  Apollo.MutationResult<DeleteMediaItemMutation>
export type DeleteMediaItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteMediaItemMutation,
  DeleteMediaItemMutationVariables
>
export const CreateEntryPdfDocument = gql`
  mutation CreateEntryPdf($entryId: Int!, $templateId: ID!) {
    gravityPdfCreateEntryPdf(
      input: { entryId: $entryId, templateId: $templateId }
    ) {
      pdfUrl
    }
  }
`
export type CreateEntryPdfMutationFn = Apollo.MutationFunction<
  CreateEntryPdfMutation,
  CreateEntryPdfMutationVariables
>

/**
 * __useCreateEntryPdfMutation__
 *
 * To run a mutation, you first call `useCreateEntryPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntryPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntryPdfMutation, { data, loading, error }] = useCreateEntryPdfMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useCreateEntryPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEntryPdfMutation,
    CreateEntryPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateEntryPdfMutation,
    CreateEntryPdfMutationVariables
  >(CreateEntryPdfDocument, options)
}
export type CreateEntryPdfMutationHookResult = ReturnType<
  typeof useCreateEntryPdfMutation
>
export type CreateEntryPdfMutationResult =
  Apollo.MutationResult<CreateEntryPdfMutation>
export type CreateEntryPdfMutationOptions = Apollo.BaseMutationOptions<
  CreateEntryPdfMutation,
  CreateEntryPdfMutationVariables
>
export const CreateEntryPdfsDocument = gql`
  mutation CreateEntryPdfs($entryIds: [Int!], $templateId: ID!) {
    gravityPdfCreateEntryPdfs(
      input: { entryIds: $entryIds, templateId: $templateId }
    ) {
      pdfUrls
    }
  }
`
export type CreateEntryPdfsMutationFn = Apollo.MutationFunction<
  CreateEntryPdfsMutation,
  CreateEntryPdfsMutationVariables
>

/**
 * __useCreateEntryPdfsMutation__
 *
 * To run a mutation, you first call `useCreateEntryPdfsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntryPdfsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntryPdfsMutation, { data, loading, error }] = useCreateEntryPdfsMutation({
 *   variables: {
 *      entryIds: // value for 'entryIds'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useCreateEntryPdfsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEntryPdfsMutation,
    CreateEntryPdfsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateEntryPdfsMutation,
    CreateEntryPdfsMutationVariables
  >(CreateEntryPdfsDocument, options)
}
export type CreateEntryPdfsMutationHookResult = ReturnType<
  typeof useCreateEntryPdfsMutation
>
export type CreateEntryPdfsMutationResult =
  Apollo.MutationResult<CreateEntryPdfsMutation>
export type CreateEntryPdfsMutationOptions = Apollo.BaseMutationOptions<
  CreateEntryPdfsMutation,
  CreateEntryPdfsMutationVariables
>
export const DeletePhotoDocument = gql`
  mutation DeletePhoto($input: DeletePhotoInput!) {
    deletePhoto(input: $input) {
      deletedId
      photo {
        ...PHOTOS_FRAG
      }
    }
  }
  ${Photos_FragFragmentDoc}
`
export type DeletePhotoMutationFn = Apollo.MutationFunction<
  DeletePhotoMutation,
  DeletePhotoMutationVariables
>

/**
 * __useDeletePhotoMutation__
 *
 * To run a mutation, you first call `useDeletePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhotoMutation, { data, loading, error }] = useDeletePhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePhotoMutation,
    DeletePhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePhotoMutation, DeletePhotoMutationVariables>(
    DeletePhotoDocument,
    options
  )
}
export type DeletePhotoMutationHookResult = ReturnType<
  typeof useDeletePhotoMutation
>
export type DeletePhotoMutationResult =
  Apollo.MutationResult<DeletePhotoMutation>
export type DeletePhotoMutationOptions = Apollo.BaseMutationOptions<
  DeletePhotoMutation,
  DeletePhotoMutationVariables
>
export const AllPhotosDocument = gql`
  query AllPhotos(
    $first: Int!
    $after: String
    $where: RootQueryToPhotoConnectionWhereArgs
  ) {
    photos(first: $first, after: $after, where: $where) {
      edges {
        node {
          ...PHOTOS_FRAG
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${Photos_FragFragmentDoc}
`

/**
 * __useAllPhotosQuery__
 *
 * To run a query within a React component, call `useAllPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPhotosQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllPhotosQuery(
  baseOptions: Apollo.QueryHookOptions<AllPhotosQuery, AllPhotosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllPhotosQuery, AllPhotosQueryVariables>(
    AllPhotosDocument,
    options
  )
}
export function useAllPhotosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPhotosQuery,
    AllPhotosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllPhotosQuery, AllPhotosQueryVariables>(
    AllPhotosDocument,
    options
  )
}
export type AllPhotosQueryHookResult = ReturnType<typeof useAllPhotosQuery>
export type AllPhotosLazyQueryHookResult = ReturnType<
  typeof useAllPhotosLazyQuery
>
export type AllPhotosQueryResult = Apollo.QueryResult<
  AllPhotosQuery,
  AllPhotosQueryVariables
>
export const GetPhotoDocument = gql`
  query GetPhoto($databaseId: ID!) {
    photo(id: $databaseId, idType: DATABASE_ID) {
      ...PHOTO_FRAG
    }
  }
  ${Photo_FragFragmentDoc}
`

/**
 * __useGetPhotoQuery__
 *
 * To run a query within a React component, call `useGetPhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhotoQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useGetPhotoQuery(
  baseOptions: Apollo.QueryHookOptions<GetPhotoQuery, GetPhotoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPhotoQuery, GetPhotoQueryVariables>(
    GetPhotoDocument,
    options
  )
}
export function useGetPhotoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPhotoQuery,
    GetPhotoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPhotoQuery, GetPhotoQueryVariables>(
    GetPhotoDocument,
    options
  )
}
export type GetPhotoQueryHookResult = ReturnType<typeof useGetPhotoQuery>
export type GetPhotoLazyQueryHookResult = ReturnType<
  typeof useGetPhotoLazyQuery
>
export type GetPhotoQueryResult = Apollo.QueryResult<
  GetPhotoQuery,
  GetPhotoQueryVariables
>
export const CreateProjectDocument = gql`
  mutation CreateProject(
    $input: CreateProjectInput!
    $firstCorrectiveAction: Int
    $afterCorrectiveAction: String
  ) {
    createProject(input: $input) {
      project {
        ...PROJECT_FRAG
      }
    }
  }
  ${Project_FragFragmentDoc}
`
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      firstCorrectiveAction: // value for 'firstCorrectiveAction'
 *      afterCorrectiveAction: // value for 'afterCorrectiveAction'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument, options)
}
export type CreateProjectMutationHookResult = ReturnType<
  typeof useCreateProjectMutation
>
export type CreateProjectMutationResult =
  Apollo.MutationResult<CreateProjectMutation>
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>
export const DeleteProjectDocument = gql`
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $firstCorrectiveAction: Int
    $afterCorrectiveAction: String
  ) {
    deleteProject(input: $input) {
      deletedId
      project {
        ...PROJECT_FRAG
      }
    }
  }
  ${Project_FragFragmentDoc}
`
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      firstCorrectiveAction: // value for 'firstCorrectiveAction'
 *      afterCorrectiveAction: // value for 'afterCorrectiveAction'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, options)
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>
export type DeleteProjectMutationResult =
  Apollo.MutationResult<DeleteProjectMutation>
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>
export const UpdateProjectDocument = gql`
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $firstCorrectiveAction: Int
    $afterCorrectiveAction: String
  ) {
    updateProject(input: $input) {
      project {
        ...PROJECT_FRAG
      }
    }
  }
  ${Project_FragFragmentDoc}
`
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      firstCorrectiveAction: // value for 'firstCorrectiveAction'
 *      afterCorrectiveAction: // value for 'afterCorrectiveAction'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UpdateProjectDocument, options)
}
export type UpdateProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectMutation
>
export type UpdateProjectMutationResult =
  Apollo.MutationResult<UpdateProjectMutation>
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>
export const AllProjectsDocument = gql`
  query AllProjects(
    $first: Int!
    $after: String
    $where: RootQueryToProjectConnectionWhereArgs
  ) {
    projects(first: $first, after: $after, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...PROJECTS_FRAG
        }
      }
    }
  }
  ${Projects_FragFragmentDoc}
`

/**
 * __useAllProjectsQuery__
 *
 * To run a query within a React component, call `useAllProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllProjectsQuery,
    AllProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllProjectsQuery, AllProjectsQueryVariables>(
    AllProjectsDocument,
    options
  )
}
export function useAllProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllProjectsQuery,
    AllProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllProjectsQuery, AllProjectsQueryVariables>(
    AllProjectsDocument,
    options
  )
}
export type AllProjectsQueryHookResult = ReturnType<typeof useAllProjectsQuery>
export type AllProjectsLazyQueryHookResult = ReturnType<
  typeof useAllProjectsLazyQuery
>
export type AllProjectsQueryResult = Apollo.QueryResult<
  AllProjectsQuery,
  AllProjectsQueryVariables
>
export const GetProjectDocument = gql`
  query GetProject(
    $databaseId: ID!
    $firstCorrectiveAction: Int
    $afterCorrectiveAction: String
  ) {
    project(id: $databaseId, idType: DATABASE_ID) {
      ...PROJECT_FRAG
    }
  }
  ${Project_FragFragmentDoc}
`

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      firstCorrectiveAction: // value for 'firstCorrectiveAction'
 *      afterCorrectiveAction: // value for 'afterCorrectiveAction'
 *   },
 * });
 */
export function useGetProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  )
}
export function useGetProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  )
}
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>
export type GetProjectLazyQueryHookResult = ReturnType<
  typeof useGetProjectLazyQuery
>
export type GetProjectQueryResult = Apollo.QueryResult<
  GetProjectQuery,
  GetProjectQueryVariables
>
export const AllCompaniesDocument = gql`
  query AllCompanies(
    $first: Int
    $where: RootQueryToCompanyConnectionWhereArgs
  ) {
    companies(first: $first, where: $where) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
  }
`

/**
 * __useAllCompaniesQuery__
 *
 * To run a query within a React component, call `useAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompaniesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllCompaniesQuery,
    AllCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllCompaniesQuery, AllCompaniesQueryVariables>(
    AllCompaniesDocument,
    options
  )
}
export function useAllCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCompaniesQuery,
    AllCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllCompaniesQuery, AllCompaniesQueryVariables>(
    AllCompaniesDocument,
    options
  )
}
export type AllCompaniesQueryHookResult = ReturnType<
  typeof useAllCompaniesQuery
>
export type AllCompaniesLazyQueryHookResult = ReturnType<
  typeof useAllCompaniesLazyQuery
>
export type AllCompaniesQueryResult = Apollo.QueryResult<
  AllCompaniesQuery,
  AllCompaniesQueryVariables
>
export const AllDivisionsDocument = gql`
  query AllDivisions(
    $first: Int
    $where: RootQueryToDivisionConnectionWhereArgs
  ) {
    divisions(first: $first, where: $where) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
  }
`

/**
 * __useAllDivisionsQuery__
 *
 * To run a query within a React component, call `useAllDivisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDivisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDivisionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllDivisionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllDivisionsQuery,
    AllDivisionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllDivisionsQuery, AllDivisionsQueryVariables>(
    AllDivisionsDocument,
    options
  )
}
export function useAllDivisionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllDivisionsQuery,
    AllDivisionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllDivisionsQuery, AllDivisionsQueryVariables>(
    AllDivisionsDocument,
    options
  )
}
export type AllDivisionsQueryHookResult = ReturnType<
  typeof useAllDivisionsQuery
>
export type AllDivisionsLazyQueryHookResult = ReturnType<
  typeof useAllDivisionsLazyQuery
>
export type AllDivisionsQueryResult = Apollo.QueryResult<
  AllDivisionsQuery,
  AllDivisionsQueryVariables
>
export const AllEquipmentTagsDocument = gql`
  query AllEquipmentTags(
    $first: Int
    $where: RootQueryToEquipmentTagConnectionWhereArgs
  ) {
    equipmentTags(first: $first, where: $where) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
  }
`

/**
 * __useAllEquipmentTagsQuery__
 *
 * To run a query within a React component, call `useAllEquipmentTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEquipmentTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEquipmentTagsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllEquipmentTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllEquipmentTagsQuery,
    AllEquipmentTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllEquipmentTagsQuery, AllEquipmentTagsQueryVariables>(
    AllEquipmentTagsDocument,
    options
  )
}
export function useAllEquipmentTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllEquipmentTagsQuery,
    AllEquipmentTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllEquipmentTagsQuery,
    AllEquipmentTagsQueryVariables
  >(AllEquipmentTagsDocument, options)
}
export type AllEquipmentTagsQueryHookResult = ReturnType<
  typeof useAllEquipmentTagsQuery
>
export type AllEquipmentTagsLazyQueryHookResult = ReturnType<
  typeof useAllEquipmentTagsLazyQuery
>
export type AllEquipmentTagsQueryResult = Apollo.QueryResult<
  AllEquipmentTagsQuery,
  AllEquipmentTagsQueryVariables
>
export const AllManufacturersDocument = gql`
  query AllManufacturers(
    $first: Int
    $where: RootQueryToManufacturerConnectionWhereArgs
  ) {
    manufacturers(first: $first, where: $where) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
  }
`

/**
 * __useAllManufacturersQuery__
 *
 * To run a query within a React component, call `useAllManufacturersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllManufacturersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllManufacturersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllManufacturersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllManufacturersQuery,
    AllManufacturersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllManufacturersQuery, AllManufacturersQueryVariables>(
    AllManufacturersDocument,
    options
  )
}
export function useAllManufacturersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllManufacturersQuery,
    AllManufacturersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllManufacturersQuery,
    AllManufacturersQueryVariables
  >(AllManufacturersDocument, options)
}
export type AllManufacturersQueryHookResult = ReturnType<
  typeof useAllManufacturersQuery
>
export type AllManufacturersLazyQueryHookResult = ReturnType<
  typeof useAllManufacturersLazyQuery
>
export type AllManufacturersQueryResult = Apollo.QueryResult<
  AllManufacturersQuery,
  AllManufacturersQueryVariables
>
export const AllProvidersDocument = gql`
  query AllProviders(
    $first: Int
    $where: RootQueryToProviderConnectionWhereArgs
  ) {
    providers(first: $first, where: $where) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
  }
`

/**
 * __useAllProvidersQuery__
 *
 * To run a query within a React component, call `useAllProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProvidersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllProvidersQuery,
    AllProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllProvidersQuery, AllProvidersQueryVariables>(
    AllProvidersDocument,
    options
  )
}
export function useAllProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllProvidersQuery,
    AllProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllProvidersQuery, AllProvidersQueryVariables>(
    AllProvidersDocument,
    options
  )
}
export type AllProvidersQueryHookResult = ReturnType<
  typeof useAllProvidersQuery
>
export type AllProvidersLazyQueryHookResult = ReturnType<
  typeof useAllProvidersLazyQuery
>
export type AllProvidersQueryResult = Apollo.QueryResult<
  AllProvidersQuery,
  AllProvidersQueryVariables
>
export const CreateToolboxTalkDocument = gql`
  mutation CreateToolboxTalk($input: CreateToolboxTalkInput!) {
    createToolboxTalk(input: $input) {
      toolboxTalk {
        ...TOOLBOX_TALK_FRAG
      }
    }
  }
  ${Toolbox_Talk_FragFragmentDoc}
`
export type CreateToolboxTalkMutationFn = Apollo.MutationFunction<
  CreateToolboxTalkMutation,
  CreateToolboxTalkMutationVariables
>

/**
 * __useCreateToolboxTalkMutation__
 *
 * To run a mutation, you first call `useCreateToolboxTalkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateToolboxTalkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createToolboxTalkMutation, { data, loading, error }] = useCreateToolboxTalkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateToolboxTalkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateToolboxTalkMutation,
    CreateToolboxTalkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateToolboxTalkMutation,
    CreateToolboxTalkMutationVariables
  >(CreateToolboxTalkDocument, options)
}
export type CreateToolboxTalkMutationHookResult = ReturnType<
  typeof useCreateToolboxTalkMutation
>
export type CreateToolboxTalkMutationResult =
  Apollo.MutationResult<CreateToolboxTalkMutation>
export type CreateToolboxTalkMutationOptions = Apollo.BaseMutationOptions<
  CreateToolboxTalkMutation,
  CreateToolboxTalkMutationVariables
>
export const DeleteToolboxTalkDocument = gql`
  mutation DeleteToolboxTalk($input: DeleteToolboxTalkInput!) {
    deleteToolboxTalk(input: $input) {
      deletedId
      toolboxTalk {
        ...TOOLBOX_TALK_FRAG
      }
    }
  }
  ${Toolbox_Talk_FragFragmentDoc}
`
export type DeleteToolboxTalkMutationFn = Apollo.MutationFunction<
  DeleteToolboxTalkMutation,
  DeleteToolboxTalkMutationVariables
>

/**
 * __useDeleteToolboxTalkMutation__
 *
 * To run a mutation, you first call `useDeleteToolboxTalkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteToolboxTalkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteToolboxTalkMutation, { data, loading, error }] = useDeleteToolboxTalkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteToolboxTalkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteToolboxTalkMutation,
    DeleteToolboxTalkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteToolboxTalkMutation,
    DeleteToolboxTalkMutationVariables
  >(DeleteToolboxTalkDocument, options)
}
export type DeleteToolboxTalkMutationHookResult = ReturnType<
  typeof useDeleteToolboxTalkMutation
>
export type DeleteToolboxTalkMutationResult =
  Apollo.MutationResult<DeleteToolboxTalkMutation>
export type DeleteToolboxTalkMutationOptions = Apollo.BaseMutationOptions<
  DeleteToolboxTalkMutation,
  DeleteToolboxTalkMutationVariables
>
export const UpdateToolboxTalkDocument = gql`
  mutation UpdateToolboxTalk($input: UpdateToolboxTalkInput!) {
    updateToolboxTalk(input: $input) {
      toolboxTalk {
        ...TOOLBOX_TALK_FRAG
      }
    }
  }
  ${Toolbox_Talk_FragFragmentDoc}
`
export type UpdateToolboxTalkMutationFn = Apollo.MutationFunction<
  UpdateToolboxTalkMutation,
  UpdateToolboxTalkMutationVariables
>

/**
 * __useUpdateToolboxTalkMutation__
 *
 * To run a mutation, you first call `useUpdateToolboxTalkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateToolboxTalkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateToolboxTalkMutation, { data, loading, error }] = useUpdateToolboxTalkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateToolboxTalkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateToolboxTalkMutation,
    UpdateToolboxTalkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateToolboxTalkMutation,
    UpdateToolboxTalkMutationVariables
  >(UpdateToolboxTalkDocument, options)
}
export type UpdateToolboxTalkMutationHookResult = ReturnType<
  typeof useUpdateToolboxTalkMutation
>
export type UpdateToolboxTalkMutationResult =
  Apollo.MutationResult<UpdateToolboxTalkMutation>
export type UpdateToolboxTalkMutationOptions = Apollo.BaseMutationOptions<
  UpdateToolboxTalkMutation,
  UpdateToolboxTalkMutationVariables
>
export const AllToolboxTalksDocument = gql`
  query AllToolboxTalks(
    $first: Int!
    $after: String
    $where: RootQueryToToolboxTalkConnectionWhereArgs
  ) {
    toolboxTalks(first: $first, after: $after, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...TOOLBOX_TALK_FRAG
        }
      }
    }
  }
  ${Toolbox_Talk_FragFragmentDoc}
`

/**
 * __useAllToolboxTalksQuery__
 *
 * To run a query within a React component, call `useAllToolboxTalksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllToolboxTalksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllToolboxTalksQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllToolboxTalksQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllToolboxTalksQuery,
    AllToolboxTalksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllToolboxTalksQuery, AllToolboxTalksQueryVariables>(
    AllToolboxTalksDocument,
    options
  )
}
export function useAllToolboxTalksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllToolboxTalksQuery,
    AllToolboxTalksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllToolboxTalksQuery,
    AllToolboxTalksQueryVariables
  >(AllToolboxTalksDocument, options)
}
export type AllToolboxTalksQueryHookResult = ReturnType<
  typeof useAllToolboxTalksQuery
>
export type AllToolboxTalksLazyQueryHookResult = ReturnType<
  typeof useAllToolboxTalksLazyQuery
>
export type AllToolboxTalksQueryResult = Apollo.QueryResult<
  AllToolboxTalksQuery,
  AllToolboxTalksQueryVariables
>
export const GetToolboxTalkDocument = gql`
  query GetToolboxTalk($id: ID!, $idType: ToolboxTalkIdType) {
    toolboxTalk(id: $id, idType: $idType) {
      ...TOOLBOX_TALK_FRAG
    }
  }
  ${Toolbox_Talk_FragFragmentDoc}
`

/**
 * __useGetToolboxTalkQuery__
 *
 * To run a query within a React component, call `useGetToolboxTalkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToolboxTalkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToolboxTalkQuery({
 *   variables: {
 *      id: // value for 'id'
 *      idType: // value for 'idType'
 *   },
 * });
 */
export function useGetToolboxTalkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetToolboxTalkQuery,
    GetToolboxTalkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetToolboxTalkQuery, GetToolboxTalkQueryVariables>(
    GetToolboxTalkDocument,
    options
  )
}
export function useGetToolboxTalkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetToolboxTalkQuery,
    GetToolboxTalkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetToolboxTalkQuery, GetToolboxTalkQueryVariables>(
    GetToolboxTalkDocument,
    options
  )
}
export type GetToolboxTalkQueryHookResult = ReturnType<
  typeof useGetToolboxTalkQuery
>
export type GetToolboxTalkLazyQueryHookResult = ReturnType<
  typeof useGetToolboxTalkLazyQuery
>
export type GetToolboxTalkQueryResult = Apollo.QueryResult<
  GetToolboxTalkQuery,
  GetToolboxTalkQueryVariables
>
export const CreateWorkerDocument = gql`
  mutation CreateWorker($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        ...USERS_FRAG
      }
    }
  }
  ${Users_FragFragmentDoc}
`
export type CreateWorkerMutationFn = Apollo.MutationFunction<
  CreateWorkerMutation,
  CreateWorkerMutationVariables
>

/**
 * __useCreateWorkerMutation__
 *
 * To run a mutation, you first call `useCreateWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkerMutation, { data, loading, error }] = useCreateWorkerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkerMutation,
    CreateWorkerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateWorkerMutation,
    CreateWorkerMutationVariables
  >(CreateWorkerDocument, options)
}
export type CreateWorkerMutationHookResult = ReturnType<
  typeof useCreateWorkerMutation
>
export type CreateWorkerMutationResult =
  Apollo.MutationResult<CreateWorkerMutation>
export type CreateWorkerMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkerMutation,
  CreateWorkerMutationVariables
>
export const DeleteWorkerDocument = gql`
  mutation DeleteWorker(
    $input: DeleteUserInput!
    $firstProject: Int
    $afterProject: String
    $firstAssigned: Int
    $afterAssigned: String
    $firstOwnwer: Int
    $afterOwner: String
  ) {
    deleteUser(input: $input) {
      deletedId
      user {
        ...USER_FRAG
      }
    }
  }
  ${User_FragFragmentDoc}
`
export type DeleteWorkerMutationFn = Apollo.MutationFunction<
  DeleteWorkerMutation,
  DeleteWorkerMutationVariables
>

/**
 * __useDeleteWorkerMutation__
 *
 * To run a mutation, you first call `useDeleteWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkerMutation, { data, loading, error }] = useDeleteWorkerMutation({
 *   variables: {
 *      input: // value for 'input'
 *      firstProject: // value for 'firstProject'
 *      afterProject: // value for 'afterProject'
 *      firstAssigned: // value for 'firstAssigned'
 *      afterAssigned: // value for 'afterAssigned'
 *      firstOwnwer: // value for 'firstOwnwer'
 *      afterOwner: // value for 'afterOwner'
 *   },
 * });
 */
export function useDeleteWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorkerMutation,
    DeleteWorkerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteWorkerMutation,
    DeleteWorkerMutationVariables
  >(DeleteWorkerDocument, options)
}
export type DeleteWorkerMutationHookResult = ReturnType<
  typeof useDeleteWorkerMutation
>
export type DeleteWorkerMutationResult =
  Apollo.MutationResult<DeleteWorkerMutation>
export type DeleteWorkerMutationOptions = Apollo.BaseMutationOptions<
  DeleteWorkerMutation,
  DeleteWorkerMutationVariables
>
export const UpdateWorkerDocument = gql`
  mutation UpdateWorker(
    $input: UpdateUserInput!
    $firstProject: Int
    $afterProject: String
    $firstAssigned: Int
    $afterAssigned: String
    $firstOwnwer: Int
    $afterOwner: String
  ) {
    updateUser(input: $input) {
      user {
        ...USER_FRAG
      }
    }
  }
  ${User_FragFragmentDoc}
`
export type UpdateWorkerMutationFn = Apollo.MutationFunction<
  UpdateWorkerMutation,
  UpdateWorkerMutationVariables
>

/**
 * __useUpdateWorkerMutation__
 *
 * To run a mutation, you first call `useUpdateWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkerMutation, { data, loading, error }] = useUpdateWorkerMutation({
 *   variables: {
 *      input: // value for 'input'
 *      firstProject: // value for 'firstProject'
 *      afterProject: // value for 'afterProject'
 *      firstAssigned: // value for 'firstAssigned'
 *      afterAssigned: // value for 'afterAssigned'
 *      firstOwnwer: // value for 'firstOwnwer'
 *      afterOwner: // value for 'afterOwner'
 *   },
 * });
 */
export function useUpdateWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkerMutation,
    UpdateWorkerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateWorkerMutation,
    UpdateWorkerMutationVariables
  >(UpdateWorkerDocument, options)
}
export type UpdateWorkerMutationHookResult = ReturnType<
  typeof useUpdateWorkerMutation
>
export type UpdateWorkerMutationResult =
  Apollo.MutationResult<UpdateWorkerMutation>
export type UpdateWorkerMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkerMutation,
  UpdateWorkerMutationVariables
>
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...ME_FRAG
      }
    }
  }
  ${Me_FragFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const AllUserCompaniesDocument = gql`
  query AllUserCompanies($first: Int) {
    userCompanies(first: $first) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
  }
`

/**
 * __useAllUserCompaniesQuery__
 *
 * To run a query within a React component, call `useAllUserCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserCompaniesQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllUserCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllUserCompaniesQuery,
    AllUserCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllUserCompaniesQuery, AllUserCompaniesQueryVariables>(
    AllUserCompaniesDocument,
    options
  )
}
export function useAllUserCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUserCompaniesQuery,
    AllUserCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllUserCompaniesQuery,
    AllUserCompaniesQueryVariables
  >(AllUserCompaniesDocument, options)
}
export type AllUserCompaniesQueryHookResult = ReturnType<
  typeof useAllUserCompaniesQuery
>
export type AllUserCompaniesLazyQueryHookResult = ReturnType<
  typeof useAllUserCompaniesLazyQuery
>
export type AllUserCompaniesQueryResult = Apollo.QueryResult<
  AllUserCompaniesQuery,
  AllUserCompaniesQueryVariables
>
export const AllUserDivisionsDocument = gql`
  query AllUserDivisions($first: Int) {
    userDivisions(first: $first) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
  }
`

/**
 * __useAllUserDivisionsQuery__
 *
 * To run a query within a React component, call `useAllUserDivisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserDivisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserDivisionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllUserDivisionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllUserDivisionsQuery,
    AllUserDivisionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllUserDivisionsQuery, AllUserDivisionsQueryVariables>(
    AllUserDivisionsDocument,
    options
  )
}
export function useAllUserDivisionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUserDivisionsQuery,
    AllUserDivisionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllUserDivisionsQuery,
    AllUserDivisionsQueryVariables
  >(AllUserDivisionsDocument, options)
}
export type AllUserDivisionsQueryHookResult = ReturnType<
  typeof useAllUserDivisionsQuery
>
export type AllUserDivisionsLazyQueryHookResult = ReturnType<
  typeof useAllUserDivisionsLazyQuery
>
export type AllUserDivisionsQueryResult = Apollo.QueryResult<
  AllUserDivisionsQuery,
  AllUserDivisionsQueryVariables
>
export const AllUserRolesDocument = gql`
  query AllUserRoles($first: Int) {
    userRoles(first: $first) {
      edges {
        node {
          name
          displayName
          id
        }
      }
    }
  }
`

/**
 * __useAllUserRolesQuery__
 *
 * To run a query within a React component, call `useAllUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserRolesQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllUserRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllUserRolesQuery,
    AllUserRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllUserRolesQuery, AllUserRolesQueryVariables>(
    AllUserRolesDocument,
    options
  )
}
export function useAllUserRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUserRolesQuery,
    AllUserRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllUserRolesQuery, AllUserRolesQueryVariables>(
    AllUserRolesDocument,
    options
  )
}
export type AllUserRolesQueryHookResult = ReturnType<
  typeof useAllUserRolesQuery
>
export type AllUserRolesLazyQueryHookResult = ReturnType<
  typeof useAllUserRolesLazyQuery
>
export type AllUserRolesQueryResult = Apollo.QueryResult<
  AllUserRolesQuery,
  AllUserRolesQueryVariables
>
export const AllUserStatusesDocument = gql`
  query AllUserStatuses($first: Int) {
    userStatuses(first: $first) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
  }
`

/**
 * __useAllUserStatusesQuery__
 *
 * To run a query within a React component, call `useAllUserStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserStatusesQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllUserStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllUserStatusesQuery,
    AllUserStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllUserStatusesQuery, AllUserStatusesQueryVariables>(
    AllUserStatusesDocument,
    options
  )
}
export function useAllUserStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUserStatusesQuery,
    AllUserStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllUserStatusesQuery,
    AllUserStatusesQueryVariables
  >(AllUserStatusesDocument, options)
}
export type AllUserStatusesQueryHookResult = ReturnType<
  typeof useAllUserStatusesQuery
>
export type AllUserStatusesLazyQueryHookResult = ReturnType<
  typeof useAllUserStatusesLazyQuery
>
export type AllUserStatusesQueryResult = Apollo.QueryResult<
  AllUserStatusesQuery,
  AllUserStatusesQueryVariables
>
export const AllWorkersDocument = gql`
  query AllWorkers(
    $first: Int!
    $after: String
    $where: RootQueryToUserConnectionWhereArgs
  ) {
    workers: users(first: $first, after: $after, where: $where) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        node {
          ...USERS_FRAG
        }
      }
    }
  }
  ${Users_FragFragmentDoc}
`

/**
 * __useAllWorkersQuery__
 *
 * To run a query within a React component, call `useAllWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWorkersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllWorkersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllWorkersQuery,
    AllWorkersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllWorkersQuery, AllWorkersQueryVariables>(
    AllWorkersDocument,
    options
  )
}
export function useAllWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllWorkersQuery,
    AllWorkersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllWorkersQuery, AllWorkersQueryVariables>(
    AllWorkersDocument,
    options
  )
}
export type AllWorkersQueryHookResult = ReturnType<typeof useAllWorkersQuery>
export type AllWorkersLazyQueryHookResult = ReturnType<
  typeof useAllWorkersLazyQuery
>
export type AllWorkersQueryResult = Apollo.QueryResult<
  AllWorkersQuery,
  AllWorkersQueryVariables
>
export const AllWorkersWithVaccineStatusDocument = gql`
  query AllWorkersWithVaccineStatus(
    $first: Int!
    $after: String
    $where: RootQueryToUserConnectionWhereArgs
  ) {
    workers: users(first: $first, after: $after, where: $where) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        node {
          ...USERS_FRAG
          vaccineStatus {
            node {
              name
              slug
            }
          }
        }
      }
    }
  }
  ${Users_FragFragmentDoc}
`

/**
 * __useAllWorkersWithVaccineStatusQuery__
 *
 * To run a query within a React component, call `useAllWorkersWithVaccineStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWorkersWithVaccineStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWorkersWithVaccineStatusQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllWorkersWithVaccineStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllWorkersWithVaccineStatusQuery,
    AllWorkersWithVaccineStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AllWorkersWithVaccineStatusQuery,
    AllWorkersWithVaccineStatusQueryVariables
  >(AllWorkersWithVaccineStatusDocument, options)
}
export function useAllWorkersWithVaccineStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllWorkersWithVaccineStatusQuery,
    AllWorkersWithVaccineStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllWorkersWithVaccineStatusQuery,
    AllWorkersWithVaccineStatusQueryVariables
  >(AllWorkersWithVaccineStatusDocument, options)
}
export type AllWorkersWithVaccineStatusQueryHookResult = ReturnType<
  typeof useAllWorkersWithVaccineStatusQuery
>
export type AllWorkersWithVaccineStatusLazyQueryHookResult = ReturnType<
  typeof useAllWorkersWithVaccineStatusLazyQuery
>
export type AllWorkersWithVaccineStatusQueryResult = Apollo.QueryResult<
  AllWorkersWithVaccineStatusQuery,
  AllWorkersWithVaccineStatusQueryVariables
>
export const GetWorkerDocument = gql`
  query GetWorker(
    $databaseId: ID!
    $firstProject: Int
    $afterProject: String
    $firstAssigned: Int
    $afterAssigned: String
    $firstOwnwer: Int
    $afterOwner: String
  ) {
    user(id: $databaseId, idType: DATABASE_ID) {
      ...USER_FRAG
    }
  }
  ${User_FragFragmentDoc}
`

/**
 * __useGetWorkerQuery__
 *
 * To run a query within a React component, call `useGetWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      firstProject: // value for 'firstProject'
 *      afterProject: // value for 'afterProject'
 *      firstAssigned: // value for 'firstAssigned'
 *      afterAssigned: // value for 'afterAssigned'
 *      firstOwnwer: // value for 'firstOwnwer'
 *      afterOwner: // value for 'afterOwner'
 *   },
 * });
 */
export function useGetWorkerQuery(
  baseOptions: Apollo.QueryHookOptions<GetWorkerQuery, GetWorkerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWorkerQuery, GetWorkerQueryVariables>(
    GetWorkerDocument,
    options
  )
}
export function useGetWorkerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkerQuery,
    GetWorkerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWorkerQuery, GetWorkerQueryVariables>(
    GetWorkerDocument,
    options
  )
}
export type GetWorkerQueryHookResult = ReturnType<typeof useGetWorkerQuery>
export type GetWorkerLazyQueryHookResult = ReturnType<
  typeof useGetWorkerLazyQuery
>
export type GetWorkerQueryResult = Apollo.QueryResult<
  GetWorkerQuery,
  GetWorkerQueryVariables
>
export const GetWorkerComplianceDocument = gql`
  query GetWorkerCompliance(
    $databaseId: ID!
    $first: Int!
    $where: UserToGfEntryConnectionWhereArgs
  ) {
    worker: user(id: $databaseId, idType: DATABASE_ID) {
      entries(first: $first, where: $where) {
        edges {
          node {
            ... on GfSubmittedEntry {
              databaseId
            }
            dateCreated
          }
        }
      }
    }
  }
`

/**
 * __useGetWorkerComplianceQuery__
 *
 * To run a query within a React component, call `useGetWorkerComplianceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerComplianceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerComplianceQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetWorkerComplianceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkerComplianceQuery,
    GetWorkerComplianceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetWorkerComplianceQuery,
    GetWorkerComplianceQueryVariables
  >(GetWorkerComplianceDocument, options)
}
export function useGetWorkerComplianceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkerComplianceQuery,
    GetWorkerComplianceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWorkerComplianceQuery,
    GetWorkerComplianceQueryVariables
  >(GetWorkerComplianceDocument, options)
}
export type GetWorkerComplianceQueryHookResult = ReturnType<
  typeof useGetWorkerComplianceQuery
>
export type GetWorkerComplianceLazyQueryHookResult = ReturnType<
  typeof useGetWorkerComplianceLazyQuery
>
export type GetWorkerComplianceQueryResult = Apollo.QueryResult<
  GetWorkerComplianceQuery,
  GetWorkerComplianceQueryVariables
>
export const MeDocument = gql`
  query Me($databaseId: ID!) {
    user(id: $databaseId, idType: DATABASE_ID) {
      ...ME_FRAG
    }
  }
  ${Me_FragFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useMeQuery(
  baseOptions: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const NewWorkerFormDataDocument = gql`
  query NewWorkerFormData {
    userCompanies(first: 30) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
    userStatuses(first: 30) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
    userDivisions(first: 30) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
    userRoles(first: 30) {
      edges {
        node {
          name
          displayName
          id
        }
      }
    }
    userLanguages(first: 30) {
      edges {
        node {
          name
          slug
          databaseId
          id
        }
      }
    }
  }
`

/**
 * __useNewWorkerFormDataQuery__
 *
 * To run a query within a React component, call `useNewWorkerFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewWorkerFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewWorkerFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewWorkerFormDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NewWorkerFormDataQuery,
    NewWorkerFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    NewWorkerFormDataQuery,
    NewWorkerFormDataQueryVariables
  >(NewWorkerFormDataDocument, options)
}
export function useNewWorkerFormDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewWorkerFormDataQuery,
    NewWorkerFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    NewWorkerFormDataQuery,
    NewWorkerFormDataQueryVariables
  >(NewWorkerFormDataDocument, options)
}
export type NewWorkerFormDataQueryHookResult = ReturnType<
  typeof useNewWorkerFormDataQuery
>
export type NewWorkerFormDataLazyQueryHookResult = ReturnType<
  typeof useNewWorkerFormDataLazyQuery
>
export type NewWorkerFormDataQueryResult = Apollo.QueryResult<
  NewWorkerFormDataQuery,
  NewWorkerFormDataQueryVariables
>
export const CreateSuggestedUsernameDocument = gql`
  mutation createSuggestedUsername($firstName: String!, $lastName: String!) {
    suggestedUsername(firstName: $firstName, lastName: $lastName)
  }
`
export type CreateSuggestedUsernameMutationFn = Apollo.MutationFunction<
  CreateSuggestedUsernameMutation,
  CreateSuggestedUsernameMutationVariables
>

/**
 * __useCreateSuggestedUsernameMutation__
 *
 * To run a mutation, you first call `useCreateSuggestedUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuggestedUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuggestedUsernameMutation, { data, loading, error }] = useCreateSuggestedUsernameMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useCreateSuggestedUsernameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSuggestedUsernameMutation,
    CreateSuggestedUsernameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSuggestedUsernameMutation,
    CreateSuggestedUsernameMutationVariables
  >(CreateSuggestedUsernameDocument, options)
}
export type CreateSuggestedUsernameMutationHookResult = ReturnType<
  typeof useCreateSuggestedUsernameMutation
>
export type CreateSuggestedUsernameMutationResult =
  Apollo.MutationResult<CreateSuggestedUsernameMutation>
export type CreateSuggestedUsernameMutationOptions = Apollo.BaseMutationOptions<
  CreateSuggestedUsernameMutation,
  CreateSuggestedUsernameMutationVariables
>
